import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import omit from 'lodash/omit';
import Error from '../../Error';
import Styled from './styles';
import ICONS from 'shared/constants/ICONS';
import api from 'shared/services/api';
import UploadSources from 'shared/sources/upload';
import Spinner from '../../../Spinner';
import Button from '../../../Button';
import { imageValidation } from 'shared/helpers/validations';

interface FileInputProps {
  meta: any;
  onChange: Function;
  value: string;
  description?: string;
  placeholder?: string;
  children?: any;
  settings?: any;
}

interface FileInputState {
  link?: string;
  isLoading: boolean;
}

class FileInput extends Component<FileInputProps, FileInputState> {
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.state = {
      link: props.value || null,
      isLoading: false,
    };

    this.inputRef = React.createRef();
  }

  handleChange = ({ target }) => {
    if (!target.files.length) return;
    const { onChange } = this.props;
    const [file] = target.files;

    if (imageValidation({ maxSize: false, file, target })) return;

    const formData = new FormData();
    formData.append('image', file);
    this.setState({
      isLoading: true,
    });

    api
      .post(UploadSources.root, formData)
      .then((response) => {
        const { link } = response.data;

        this.setState({
          link,
          isLoading: false,
        });

        onChange(link);
      })
      .catch((error) => {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.clearFile();
          },
        );

        if (error.response.status === 413) {
          return NotificationManager.error('Вы попытались загрузить слишком большой файл', 'Ошибка');
        }

        return NotificationManager.error('Ошибка');
      });
  };

  clearFile = () => {
    this.props.onChange(null);
    this.setState({
      link: null,
    });

    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.value = null;
    }
  };

  render() {
    const {
      meta,
      description = 'Максимальное ограничение на размер файла - 1 мб',
      placeholder = 'Загрузить картинку',
      children,
      settings,
    } = this.props;
    const { isLoading, link } = this.state;
    const mappedSettings = () => {
      if (settings.opacity) {
        return omit(settings, 'opacity');
      }

      return settings;
    };

    return (
      <Styled.Container>
        <Styled.PreviewWrapper>
          <Styled.Preview>
            <Styled.Input
              ref={this.inputRef}
              id="preview"
              type="file"
              size="90"
              onChange={this.handleChange}
              disabled={isLoading}
            />
            <Styled.PreviewContainer
              style={link && mappedSettings()}
              bgOpacity={link && settings && settings.opacity}
              bg={link}
            >
              {isLoading ? (
                <Spinner size={30} />
              ) : (
                !link && (
                  <>
                    <img src={ICONS.UPLOAD_PICTURE} alt="Загрузить картинку" />
                    {placeholder}
                  </>
                )
              )}
            </Styled.PreviewContainer>
            <Error meta={meta} />
          </Styled.Preview>
        </Styled.PreviewWrapper>
        <Styled.Description>
          {link ? (
            <>
              {children}
              <Styled.Buttons>
                <Styled.Upload htmlFor="preview">Загрузить новое</Styled.Upload>
                <Styled.Delete>
                  <Button onClick={this.clearFile} transparentBlue type="button">
                    Удалить
                  </Button>
                </Styled.Delete>
              </Styled.Buttons>
            </>
          ) : (
            <Styled.Text>{description}</Styled.Text>
          )}
        </Styled.Description>
      </Styled.Container>
    );
  }
}

export default FileInput;
