import React, { Component } from 'react';
import { Field } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import { required } from 'shared/helpers/validations';
import { WIDGET_PATTERN_TYPES } from 'shared/constants/WIDGET';
import Form from '../../../Form';
import { Input, RadioGroup } from '../../../FormControls';
import Button from '../../../Button';
import StepModal from '../../../Modals/StepModal';
import WidgetPatternFilter from './WidgetPatternFilter';
import WidgetPatternMarkup from './WidgetPatternMarkup';
import WidgetStyled from '../WidgetForm/styles';
import Styled from './styles';
import { ModalFunctions } from '../../../../../interfaces/modal';

interface ModalProps {
  isEdit: boolean;
  data: any;
  isPreview: boolean;
  closeModal: ModalFunctions['closeModal'];
  editWidgetPattern: (data: any) => any;
  createWidgetPattern: (data: any) => any;
}

interface ModalState {
  initialValues: any;
}

class WidgetPatternsModal extends Component<ModalProps, ModalState> {
  queries: { [x: string]: any };

  constructor(props) {
    super(props);

    this.state = {
      initialValues: props.data || {
        pattern_type: WIDGET_PATTERN_TYPES.MARKUP,
      },
    };

    const markup =
      !isEmpty(props.data) && props.data.pattern_type === WIDGET_PATTERN_TYPES.MARKUP
        ? props.data.pattern
        : null;
    const filter =
      !isEmpty(props.data) && props.data.pattern_type === WIDGET_PATTERN_TYPES.FILTER
        ? props.data.pattern
        : null;

    this.queries = {
      [WIDGET_PATTERN_TYPES.MARKUP]: markup,
      [WIDGET_PATTERN_TYPES.FILTER]: filter,
    };
  }

  submitForm = (data) => {
    const { isEdit, editWidgetPattern, createWidgetPattern, closeModal, isPreview } = this.props;
    const method = isEdit ? editWidgetPattern : createWidgetPattern;

    if (isPreview) return closeModal();

    return method({
      ...data,
      pattern: this.queries[data.pattern_type],
    }).then((action) => {
      if (!action.error) {
        if (isEdit) {
          return closeModal();
        }

        return window.location.reload();
      }

      return action;
    });
  };

  updateFilter = (query) => {
    this.queries[WIDGET_PATTERN_TYPES.FILTER] = query;
  };

  updateMarkup = (query) => {
    this.queries[WIDGET_PATTERN_TYPES.MARKUP] = query;
  };

  render() {
    const { initialValues } = this.state;
    const { isEdit, isPreview } = this.props;

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Form
          initialValues={initialValues}
          onSubmit={this.submitForm}
          render={({ handleSubmit, values }) => (
            <>
              <StepModal.Header>
                <StepModal.Title>
                  {isEdit ? 'Редактирование' : 'Создание'} шаблона{' '}
                  {values.pattern_type === WIDGET_PATTERN_TYPES.FILTER ? 'фильтра' : 'наценки'}
                </StepModal.Title>
              </StepModal.Header>
              <Styled.Body>
                <WidgetStyled.Wrapper>
                  <form onSubmit={handleSubmit}>
                    <Styled.Header>
                      <Styled.RadioContainer>
                        <Field name="pattern_type" validate={required}>
                          {({ input, meta }) => (
                            <RadioGroup
                              {...input}
                              meta={meta}
                              disabled={isPreview}
                              buttons={[
                                { id: 0, value: WIDGET_PATTERN_TYPES.MARKUP, name: 'Наценка' },
                                { id: 1, value: WIDGET_PATTERN_TYPES.FILTER, name: 'Фильтр' },
                              ]}
                            />
                          )}
                        </Field>
                      </Styled.RadioContainer>
                      <Styled.InputContainer>
                        <Field name="title" validate={required}>
                          {({ input, meta }) => (
                            <Input
                              placeholder="Название шаблона"
                              meta={meta}
                              {...input}
                              disabled={isPreview}
                            />
                          )}
                        </Field>
                      </Styled.InputContainer>
                    </Styled.Header>
                    {values.pattern_type === WIDGET_PATTERN_TYPES.FILTER && (
                      <WidgetPatternFilter
                        onChange={this.updateFilter}
                        data={this.queries[WIDGET_PATTERN_TYPES.FILTER]}
                        isPreview={isPreview}
                      />
                    )}
                    {values.pattern_type === WIDGET_PATTERN_TYPES.MARKUP && (
                      <WidgetPatternMarkup
                        onChange={this.updateMarkup}
                        data={this.queries[WIDGET_PATTERN_TYPES.MARKUP]}
                        isPreview={isPreview}
                      />
                    )}
                    <WidgetStyled.ButtonWrapper>
                      <Button type="submit">Сохранить</Button>
                    </WidgetStyled.ButtonWrapper>
                  </form>
                </WidgetStyled.Wrapper>
              </Styled.Body>
            </>
          )}
        />
      </Styled.Container>
    );
  }
}

export default WidgetPatternsModal;
