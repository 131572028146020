import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface RowFieldProps {
  isDatepicker?: boolean;
}

export default {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 62px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      min-height: 0;
    }
  `,

  Row: styled.div`
    display: flex;
    margin: 32px -16px 0 -16px;
  `,

  RowField: styled.div<RowFieldProps>`
    width: 50%;
    padding: 0 16px;

    & label {
      overflow: hidden;
    }

    ${(props) =>
      props.isDatepicker &&
      css`
        .date-picker-container .ant-input {
          width: 100%;
        }
      `}
  `,

  AllDatesCheckbox: styled.div`
    margin-top: 8px;
  `,
};
