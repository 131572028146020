import React from 'react';
import { Field } from 'react-final-form';
import { ModalLayout } from 'widgets/layouts/modal-layout';
import ROLE_MODAL_TYPES from 'shared/constants/ROLE_MODAL_TYPES';
import { required, composeValidators, maxValue } from 'shared/helpers/validations';
import Form from '../../Form';
import { Input, Checkbox } from '../../FormControls';
import Modal from '../../Modal';
import Styled from './styles';

interface FormProps {
  type?: string;
  title?: string;
  permissions: any[];
  withHeader?: boolean;
  onSubmit?: (data: any) => any;
  onSuccess?: (data: any) => any;
  initialValues?: any;
}

const RoleForm: React.FC<FormProps> = ({
  children,
  title,
  permissions,
  type,
  withHeader = true,
  ...rest
}) => (
  <Form
    {...rest}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Styled.Wrapper>
          <Styled.GlobalStyles />
          {withHeader && (
            <Styled.Header>
              <Styled.Title>{title}</Styled.Title>
              {type === ROLE_MODAL_TYPES.USER && (
                <Field name="name" validate={composeValidators(required, maxValue(255))}>
                  {({ input, meta }) => <Input label="Название" {...input} meta={meta} />}
                </Field>
              )}
            </Styled.Header>
          )}
          <ModalLayout.List>
            {permissions.map((permission) => (
              <ModalLayout.ListItem key={permission.id}>
                <ModalLayout.ListTitle disabled={type === ROLE_MODAL_TYPES.USER && permission.disabled}>
                  {permission.name}
                </ModalLayout.ListTitle>
                <ModalLayout.ListCheckbox>
                  <Field name={`id_${permission.id}`} type="checkbox">
                    {({ input, meta }) => (
                      <Checkbox
                        variant="round"
                        meta={meta}
                        {...input}
                        disabled={type === ROLE_MODAL_TYPES.USER && permission.disabled}
                      />
                    )}
                  </Field>
                </ModalLayout.ListCheckbox>
                {type && type !== ROLE_MODAL_TYPES.SOURCES && (
                  <ModalLayout.ListDescription>{permission.description}</ModalLayout.ListDescription>
                )}
              </ModalLayout.ListItem>
            ))}
          </ModalLayout.List>
        </Styled.Wrapper>
        <Modal.Footer>{children}</Modal.Footer>
      </form>
    )}
  />
);

export default RoleForm;
