import styled from 'styled-components';
import { scrollbar } from '../../../app/styles/scrollbar';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface IndicatorProps {
  backgroundColor?: string;
}

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  List: styled.div`
    margin: 0 -40px;
    padding: 24px 40px 0 40px;
    display: flex;
    flex-direction: column;
    max-height: 502px;
    overflow-y: scroll;
    border-top: 1px solid #e1e1e1;
    ${scrollbar}

    & .infinite-scroll-component > div:not(:last-child) {
      margin-bottom: 24px;
    }

    & .infinite-scroll-component > div:last-child {
      .announcement-history-modal__line {
        background: none;

        &:after {
          display: none;
        }
      }
    }
  `,

  Item: styled.div`
    display: flex;
  `,

  Date: styled.div`
    flex-shrink: 0;
    font-size: 12px;
    color: #5c5c5c;

    @media (max-width: ${BREAKPOINTS.XS}) {
      width: 70px;
    }
  `,

  Line: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 20px;
    min-height: 100%;
    width: 2px;
    background-color: #e1e1e1;
    flex-shrink: 0;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 2px;
      height: 24px;
      background-color: #e1e1e1;
      bottom: -24px;
    }
  `,

  Indicator: styled.div<IndicatorProps>`
    position: absolute;
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 2px;
    background-color: ${({ backgroundColor }) => backgroundColor};
  `,

  Info: styled.div`
    max-width: 390px;
    display: flex;
    flex-direction: column;
  `,

  InfoTitle: styled.div`
    margin-top: -4px;
    display: flex;

    & > a {
      margin-left: 4px;
    }
  `,

  InfoUser: styled.div`
    margin-top: 4px;
    display: flex;
    align-items: center;
    color: #333;
    font-size: 12px;

    & > svg {
      margin-right: 4px;
    }
  `,

  InfoText: styled.div`
    margin-top: 4px;
    font-size: 12px;
    color: #5c5c5c;
    display: flex;
    align-items: center;
  `,

  EmptyMessage: styled.div`
    color: #333;
    text-align: center;
  `,
};
