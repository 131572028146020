import React from 'react';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import { FileArrayInput } from '../../../FormControls';
import Styled from './styles';
import PageConstructorFooter from './PageConstructorFooter';

let formValues;

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  step: number;
  data: any;
  blockData: any;
  steps: string[];
}

const GalleryStep: React.FC<StepProps> = ({ onSubmit, updateData, data, step, goBack, steps, blockData }) => {
  const handleOnSubmit = (newData) => {
    const requestData = data.map((item) => {
      if (item.uuid === steps[step]) {
        return {
          ...item,
          gallery: newData.gallery,
        };
      }

      return item;
    });

    return onSubmit({ blocks: requestData, submit_action: newData.submit_action });
  };

  const handleUpdateData = () => {
    const requestData = data.map((item) => {
      if (item.uuid === steps[step]) {
        return {
          ...item,
          gallery: formValues.gallery,
        };
      }

      return item;
    });

    return updateData(requestData);
  };

  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка блока Галерея</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          onSubmit={handleOnSubmit}
          initialValues={blockData}
          render={({ handleSubmit, form, values }) => {
            formValues = values;
            return (
              <form onSubmit={handleSubmit}>
                <Field name="gallery">{({ input, meta }) => <FileArrayInput {...input} meta={meta} />}</Field>

                <PageConstructorFooter
                  goBack={goBack}
                  form={form}
                  updateData={handleUpdateData}
                  withoutNextAction={steps.length - 1 === step}
                />
              </form>
            );
          }}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default GalleryStep;
