import React, { useEffect, useState } from 'react';
import ConfirmModal from '../Modals/ConfirmModal';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';

interface ModalPopoverProps {
  close?: () => void;
  closePopover?: () => void;
}

const ModalPopover: React.FC<ModalPopoverProps> = (props) => {
  const { close, closePopover } = props;
  const [isVisible, toggleVisible] = useState(false);

  useEffect(() => {
    const handleTogglePopover = (visible) => {
      toggleVisible(visible);
    };

    Emmiter.addListener(EVENTS.MODAL_TOGGLE_POPOVER, handleTogglePopover);

    return () => {
      Emmiter.removeListener(EVENTS.MODAL_TOGGLE_POPOVER, handleTogglePopover);
    };
  });

  return isVisible ? (
    <ConfirmModal withPopover closeModal={close} closePopover={closePopover} padding="24px" />
  ) : null;
};

export default ModalPopover;
