import React from 'react';
import { Field } from 'react-final-form';
import {
  required,
  intValidation,
  seatValidation,
  minNumberValue,
  maxNumberValue,
  composeValidators,
} from 'shared/helpers/validations';
import { ANNOUNCEMENTS_TYPES } from 'shared/constants/ANNOUNCEMENT_TYPES';
import { TICKET_TYPES } from 'shared/constants/TICKET_TYPES';
import { InputWithSelect, NumberInput, Input, RadioGroup, Checkbox } from '../../FormControls';
import playgroundsSources from 'shared/sources/playgrounds';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import Form from '../../Form';
import OrderNewTicketsFormSpy from 'shared/lib/OrderNewTicketsFormSpy';
import Styled from './styles';
import { renderPlaygrounds } from 'shared/helpers/optionsRenderers';

interface FormProps {
  index: number;
  event: any;
  forms: any;
  setValues: (id: number, difference: any, isCategories: boolean) => void;
  seatsValidation: any;
  announcement: any;
  removeAnnouncement: (id: number) => any;
}

const OrderNewTicketsForm: React.FC<FormProps> = (props) => {
  const { announcement, event, seatsValidation, forms, setValues, index, removeAnnouncement } = props;

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Form
          key={announcement.id}
          withoutSubmit
          initialValues={{
            id: announcement.id,
            is_categories: 'false',
          }}
          validate={seatsValidation}
          render={({ handleSubmit, values, form, hasValidationErrors, validating }) => {
            forms[announcement.id] = {
              form,
              validating,
              hasValidationErrors,
            };

            const isCategories = values.is_categories === 'true';

            if (!values.row && values.seat && !values.count) {
              form.change('seat', null);
            }

            return (
              <form onSubmit={handleSubmit}>
                <OrderNewTicketsFormSpy
                  id={announcement.id}
                  setValues={setValues}
                  isCategories={isCategories}
                />
                <Field type="hidden" name="id" component="input" />
                <Styled.FieldItem>
                  <Styled.Title>{`${index + 1}-я позиция`}</Styled.Title>
                  <Styled.Table>
                    <Styled.TableRow>
                      <Styled.TableCellTitle>Форма билетов</Styled.TableCellTitle>
                      <Styled.TableCell>
                        <Field name="is_categories" validate={required}>
                          {({ input, meta }) => (
                            <RadioGroup
                              {...input}
                              meta={meta}
                              postfix={announcement.id}
                              buttons={[
                                { id: 0, value: 'false', name: 'Места' },
                                { id: 1, value: 'true', name: 'Категории' },
                              ]}
                            />
                          )}
                        </Field>
                      </Styled.TableCell>
                    </Styled.TableRow>

                    <Styled.TableRow>
                      <Styled.TableCellTitle>
                        Данные билетов
                        <Styled.TableCellTitleSubText>
                          {isCategories ? (
                            'Категория билетов'
                          ) : (
                            <>
                              Информация <br /> о местах
                            </>
                          )}
                        </Styled.TableCellTitleSubText>
                      </Styled.TableCellTitle>
                      <Styled.TableCell>
                        {isCategories ? (
                          <Styled.Categories>
                            <Field name="category" validate={required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  route={playgroundsSources}
                                  query={{
                                    hall_layout: event.hall_layout_id,
                                    fragment_type: FRAGMENT_TYPES.CATEGORY,
                                  }}
                                  optionsRenderer={renderPlaygrounds}
                                  defaultOptions={false}
                                  label="Категория"
                                  defaultValue={false}
                                  withoutPagination
                                  {...input}
                                  meta={meta}
                                />
                              )}
                            </Field>
                            <Field
                              name="count_categories"
                              validate={composeValidators(
                                required,
                                intValidation,
                                minNumberValue(1),
                                maxNumberValue(10000),
                              )}
                            >
                              {({ input, meta }) => (
                                <NumberInput label="Кол-во" type="number" {...input} meta={meta} />
                              )}
                            </Field>
                          </Styled.Categories>
                        ) : (
                          <>
                            <Styled.FieldsRow>
                              <Field name="sector" validate={required}>
                                {({ input, meta }) => (
                                  <InputWithSelect
                                    isAsync
                                    label="Сектор"
                                    route={playgroundsSources}
                                    query={{
                                      hall_layout: event.hall_layout_id,
                                      fragment_type: FRAGMENT_TYPES.SECTOR,
                                    }}
                                    optionsRenderer={renderPlaygrounds}
                                    defaultOptions={false}
                                    withoutPagination
                                    disabled={values.row}
                                    {...input}
                                    meta={meta}
                                  />
                                )}
                              </Field>
                              <Field name="row" validate={values.seat && required}>
                                {({ input, meta }) => (
                                  <Styled.Row>
                                    <InputWithSelect
                                      isAsync
                                      label="Ряд"
                                      route={playgroundsSources}
                                      query={{
                                        hall_layout: event.hall_layout_id,
                                        fragment_type: FRAGMENT_TYPES.ROW,
                                        parent: values.sector && values.sector.value,
                                      }}
                                      optionsRenderer={renderPlaygrounds}
                                      defaultOptions={false}
                                      withoutPagination
                                      disabled={!values.sector}
                                      {...input}
                                      meta={meta}
                                    />
                                  </Styled.Row>
                                )}
                              </Field>
                            </Styled.FieldsRow>
                            <Styled.FieldsRow>
                              <Styled.Tickets>
                                {!values.count && (
                                  <Field name="seat" validate={seatValidation}>
                                    {({ input, meta }) => (
                                      <Input
                                        label="Места"
                                        readOnly={!values.row}
                                        disabled={!values.row}
                                        {...input}
                                        meta={meta}
                                      />
                                    )}
                                  </Field>
                                )}
                                {!values.seat && (
                                  <Field
                                    name="count"
                                    validate={composeValidators(
                                      required,
                                      intValidation,
                                      minNumberValue(1),
                                      maxNumberValue(10000),
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <NumberInput label="Кол-во" type="number" {...input} meta={meta} />
                                    )}
                                  </Field>
                                )}
                              </Styled.Tickets>
                            </Styled.FieldsRow>
                          </>
                        )}
                      </Styled.TableCell>
                    </Styled.TableRow>

                    <Styled.TableRow>
                      <Styled.TableCellTitle>
                        Тип билетов
                        <Styled.TableCellTitleSubText>Признаки билетов</Styled.TableCellTitleSubText>
                      </Styled.TableCellTitle>
                      <Styled.TableCell>
                        <Field name="announcement_type" validate={required}>
                          {({ input, meta }) => (
                            <RadioGroup
                              {...input}
                              meta={meta}
                              postfix={announcement.id}
                              buttons={[
                                { id: 1, value: '1', name: ANNOUNCEMENTS_TYPES[1] },
                                { id: 0, value: '0', name: ANNOUNCEMENTS_TYPES[0] },
                              ]}
                            />
                          )}
                        </Field>
                        <Field name="ticket_type" validate={required}>
                          {({ input, meta }) => (
                            <Styled.TicketSigns>
                              <RadioGroup
                                {...input}
                                meta={meta}
                                postfix={announcement.id}
                                buttons={[
                                  { id: 1, value: '1', name: TICKET_TYPES[1] },
                                  { id: 2, value: '2', name: TICKET_TYPES[2] },
                                  { id: 3, value: '3', name: TICKET_TYPES[3] },
                                ]}
                              />
                            </Styled.TicketSigns>
                          )}
                        </Field>
                        <Styled.Checkboxes>
                          <Field name="personal" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Именной
                              </Checkbox>
                            )}
                          </Field>
                          <Field name="non_returnable" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Невозвратный
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.Checkboxes>
                      </Styled.TableCell>
                    </Styled.TableRow>

                    <Styled.TableRow>
                      <Styled.TableCellTitle>
                        Цены
                        <Styled.TableCellTitleSubText>Цены за одну штуку билета</Styled.TableCellTitleSubText>
                      </Styled.TableCellTitle>
                      <Styled.TableCell>
                        <Styled.Prices>
                          <Field
                            name="price"
                            validate={composeValidators(
                              required,
                              intValidation,
                              minNumberValue(1),
                              maxNumberValue(9999999),
                            )}
                          >
                            {({ input, meta }) => (
                              <NumberInput
                                label="Номинал"
                                placeholder="₽"
                                {...input}
                                meta={meta}
                                type="number"
                              />
                            )}
                          </Field>
                          <Field
                            name="purchase_price"
                            validate={composeValidators(
                              required,
                              intValidation,
                              minNumberValue(1),
                              maxNumberValue(9999999),
                            )}
                          >
                            {({ input, meta }) => (
                              <NumberInput
                                label="Закупочная"
                                placeholder="₽"
                                {...input}
                                meta={meta}
                                type="number"
                              />
                            )}
                          </Field>
                        </Styled.Prices>
                        <Field
                          name="total_price"
                          validate={composeValidators(
                            required,
                            intValidation,
                            minNumberValue(1),
                            maxNumberValue(9999999),
                          )}
                        >
                          {({ input, meta }) => (
                            <Styled.TotalPrice>
                              <NumberInput
                                label="Итоговая"
                                placeholder="₽"
                                {...input}
                                meta={meta}
                                type="number"
                              />
                            </Styled.TotalPrice>
                          )}
                        </Field>
                      </Styled.TableCell>
                    </Styled.TableRow>
                  </Styled.Table>
                  <Styled.RemoveButton type="button" onClick={() => removeAnnouncement(index)}>
                    Удалить
                  </Styled.RemoveButton>
                </Styled.FieldItem>
              </form>
            );
          }}
        />
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default OrderNewTicketsForm;
