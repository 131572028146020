import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openOrder, selectOrderContext } from 'entities/order';
import CaretIcon from '../../../../../static/icons/caret-right.svg';
import Styled from './styles';

export const HeaderOrderContext = () => {
  const orderContext = useSelector(selectOrderContext);
  const dispatch = useDispatch();

  const handleOpenOrder = () => {
    dispatch(openOrder(orderContext.id));
  };

  if (!orderContext) return null;

  return (
    <Styled.OrderContext type="button" onClick={handleOpenOrder}>
      <Styled.CaretIcon>
        <CaretIcon />
      </Styled.CaretIcon>
      <Styled.OrderContextText>
        Продажа {orderContext.id}
        <Styled.Br />
        <Styled.OrderDesc>Добавление билетов</Styled.OrderDesc>
        <Styled.OrderDescMobile>Идет добавление билетов из билетного пула</Styled.OrderDescMobile>
      </Styled.OrderContextText>
      <Styled.MobileCaretIcon>
        <CaretIcon />
      </Styled.MobileCaretIcon>
    </Styled.OrderContext>
  );
};
