import { useUnit } from 'effector-react';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Router, useRouter } from 'next/router';
import { useEffect } from 'react';
import { UseListParams } from './types';

let prevQuery: Router['query'] | null = null;
let prevPath: Router['asPath'] | null = null;

export const useList = <Shape extends UseListParams>(
  unit: Shape,
  defaultQuery: { [key: string]: string | string[] } = {},
) => {
  const { query, pathname } = useRouter();
  const data = useUnit(unit);

  useEffect(() => {
    const omittedQuery = omit({ ...defaultQuery, ...query }, ['modalType', 'modalId']);
    const newQuery = {
      ...defaultQuery,
      ...omittedQuery,
    };

    if (!isEqual(prevQuery, omittedQuery) || !prevQuery || !prevPath || pathname !== prevPath) {
      prevQuery = newQuery;
      prevPath = pathname;

      // @ts-expect-error it's ok
      !data?.loadMoreLoading ? data.mountPage(newQuery) : data?.uploadingDataLoadMore(newQuery);
    }
  }, [query]);

  const loadMore = () => {
    // @ts-expect-error it's ok
    data.loadMore();
  };

  return { data, loadMore };
};
