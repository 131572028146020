import React, { Component } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import last from 'lodash/last';
import head from 'lodash/head';
import 'rc-slider/assets/index.css';
import { Field } from 'react-final-form';
import { Range } from 'rc-slider';
import NumberInput from '../NumberInput';
import Styled from './styles';

const HANDLE_STYLE = {
  borderColor: '#fff',
  height: 24,
  width: 24,
  marginLeft: 0,
  marginTop: -11,
  backgroundColor: '#38d680',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
  outline: 'none',
};
const TRACK_STYLE = { backgroundColor: '#38d680', height: 2 };
const RAIL_STYLE = { backgroundColor: '#f5f5f5', height: 2 };

interface RangeInputProps {
  from: {
    label: string;
    name: string;
  };
  to: {
    label: string;
    name: string;
  };
  initialValues: any;
  sign: string;
  minValue: number;
  maxValue: number;
  getClearValues: (cb: Function) => any;
  icon?: string;
}

interface RangeInputState {
  value: number[];
  minValue: number;
  maxValue: number;
}

class RangeInput extends Component<RangeInputProps, RangeInputState> {
  handleChangeDebounced: any;

  onChangeFrom: any;

  onChangeTo: (event: any) => void;

  constructor(props) {
    super(props);
    const from = get(props.initialValues, `${props.from.name}`, props.minValue);
    const to = get(props.initialValues, `${props.to.name}`, props.maxValue);

    this.handleChangeDebounced = debounce(this.handleChange, 500);

    props.getClearValues(this.clearValues);

    this.state = {
      value: [from, to],
      minValue: props.minValue,
      maxValue: props.maxValue,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const [fromValue, toValue] = state.value;
    const newValue = [fromValue, toValue];

    if (fromValue < props.minValue) {
      newValue[0] = props.minValue;
    }

    if (toValue > props.maxValue) {
      newValue[1] = props.maxValue;
    }

    return {
      value: newValue,
    };
  }

  handleChange = (value) => {
    let [from, to] = value;
    const { minValue, maxValue } = this.props;

    if (+from < minValue) {
      from = minValue;
    }

    if (+to > maxValue) {
      to = maxValue;
    }

    this.onChangeFrom(from);
    this.onChangeTo(to);
    this.setState({
      value: [from, to],
    });
  };

  handleChangeFrom = (value) => {
    const to = last(this.state.value);
    const newValue = [value, to];

    this.handleChangeDebounced(newValue);
  };

  handleChangeTo = (value) => {
    const from = head(this.state.value);

    const newValue = value !== null ? [from, value] : [from, ''];
    this.handleChangeDebounced(newValue);
  };

  onSliderChange = (value) => {
    this.handleChangeDebounced(value);

    this.setState({
      value,
    });
  };

  clearValues = () => {
    this.setState({
      value: [this.props.minValue, this.props.maxValue],
    });
  };

  render() {
    const { from, to, sign, icon } = this.props;
    const { value, minValue, maxValue } = this.state;
    const [fromValue, toValue] = value;

    return (
      <Styled.Container>
        <Styled.Inputs>
          <Styled.Input>
            <Styled.Sign>{icon ? <Styled.Icon src={icon} /> : sign}</Styled.Sign>
            <Field name={from.name}>
              {({ input, meta }) => {
                this.onChangeFrom = input.onChange;

                return (
                  <NumberInput
                    min={0}
                    max={maxValue}
                    label={from.label}
                    name={from.name}
                    type="number"
                    onChange={this.handleChangeFrom}
                    value={fromValue}
                    meta={meta}
                  />
                );
              }}
            </Field>
          </Styled.Input>
          <Styled.Input>
            <Styled.Sign>{icon ? <Styled.Icon src={icon} /> : sign}</Styled.Sign>
            <Field name={to.name}>
              {({ input, meta }) => {
                this.onChangeTo = input.onChange;

                return (
                  <NumberInput
                    min={0}
                    max={maxValue}
                    label={to.label}
                    name={to.name}
                    type="number"
                    onChange={this.handleChangeTo}
                    value={toValue}
                    meta={meta}
                  />
                );
              }}
            </Field>
          </Styled.Input>
        </Styled.Inputs>
        <Styled.Range>
          <Range
            allowCross={false}
            value={value}
            onChange={this.onSliderChange}
            min={minValue}
            max={maxValue}
            trackStyle={[TRACK_STYLE, TRACK_STYLE]}
            handleStyle={[HANDLE_STYLE, HANDLE_STYLE]}
            railStyle={RAIL_STYLE}
          />
        </Styled.Range>
      </Styled.Container>
    );
  }
}

export default RangeInput;
