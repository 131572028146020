import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface IndicatorProps {
  size?: string | number;
  isError?: boolean;
}

export default {
  Container: styled.div`
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px 40px;
    box-sizing: border-box;

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 24px 16px;
    }
  `,

  Title: styled.div`
    font-size: 20px;
    text-align: center;
    color: #000;
  `,

  Bar: styled.div`
    margin-top: 64px;
    height: 8px;
    width: 100%;
    display: flex;
    border-radius: 8px;
    background-color: #f5f5f5;
  `,

  Indicator: styled.div<IndicatorProps>`
    position: relative;
    width: ${({ size }) => `${size}%`};
    border-radius: 8px;
    background-color: #2dc472;
    transition: all 1s ease-in-out;

    /* isError */
    background-color: ${({ isError }) => (isError ? '#EB5757' : null)};
  `,

  IndicatorDetail: styled.div`
    position: absolute;
    right: 0;
    bottom: 100%;
    transform: translateX(50%);
    box-sizing: border-box;
    padding-bottom: 5px;
    color: #5c5c5c;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  `,

  Error: styled.div`
    margin-top: 20px;
    font-size: 12px;
    color: #eb5757;

    & > div:not(:last-child) {
      margin-bottom: 12px;
    }
  `,

  Return: styled.div`
    margin-top: 32px;
  `,
};
