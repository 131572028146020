import React, { useEffect, useState } from 'react';
import { IEventAttachmentTypes } from 'shared/api/market/events';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import attachmentsSource from 'shared/sources/attachments';
import landingEventSources from 'shared/sources/landings/landingsEvents';
import { ModalFunctions } from '../../../../../interfaces/modal';
import withLandingData from '../../helpers/withLandingData';
import EventsCreate from './EventsCreate';
import EventsUpdate from './EventsUpdate';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  data: any;
  modalState: string;
  showDeleteModal: () => void;
  lng: string;
  onChangeLng: any;
  setFormData: (data: any) => any;
  updateData: (translations: any) => any;
  updateTranslations: (translations: any) => any;
  modalTab: number;
}

const LandingsEventModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    forceCloseModal,
    showDeleteModal,
    updateTranslations,
    updateData,
    onChangeLng,
    modalState,
    setFormData,
    data,
    lng,
    modalTab,
    loadData,
  } = props;

  const [attachmentTypes, setAttachmentTypes] = useState<IEventAttachmentTypes>({} as IEventAttachmentTypes);
  useEffect(() => {
    attachmentsSource.getAttachmentsTypes('events').then((response) => {
      setAttachmentTypes(response);
    });
  }, []);

  if (modalState === MODAL_STATES.CREATE) {
    return (
      <EventsCreate
        lng={lng}
        setFormData={setFormData}
        closeModal={closeModal}
        onChangeLng={onChangeLng}
        attachmentTypes={attachmentTypes}
      />
    );
  }

  return (
    <EventsUpdate
      tabPoint={modalTab}
      attachmentTypes={attachmentTypes}
      loadData={loadData}
      forceCloseModal={forceCloseModal}
      closeModal={closeModal}
      data={data}
      lng={lng}
      onChangeLng={onChangeLng}
      updateData={updateData}
      showDeleteModal={showDeleteModal}
      updateTranslations={updateTranslations}
    />
  );
};

export default withLandingData(LandingsEventModal, {
  translation: 'events',
  sources: landingEventSources,
  emitUpdateTableAfterSave: true,
});
