import styled, { createGlobalStyle } from 'styled-components';
import StyledForm from '../../../Form/styles';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__body {
        padding: 0;
      }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    ${StyledForm.FormWrapper},
    ${StyledForm.FormWrapper} form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  Body: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 28px;
  `,

  Header: styled.div`
    box-sizing: border-box;
    display: flex;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
    }
  `,

  RadioContainer: styled.div`
    max-width: 200px;
    width: 100%;
    margin-right: 24px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-width: 100%;
      margin: 0 0 16px;
    }
  `,

  InputContainer: styled.div`
    max-width: 400px;
    width: 100%;

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-width: 100%;
    }
  `,
};
