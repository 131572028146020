import React from 'react';
import Button from '../../Button';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import ExternalLinkIcon from '../../../../static/icons/external-link.svg';
import { useTranslation } from '../../../i18n';
import LOCALES from 'shared/constants/LOCALES';
import PAGES from 'shared/constants/PAGES';
import { ModalFunctions } from '../../../../interfaces/modal';

const LINK_PATTERNS = {
  Landing: '/landings/sites?modalId=[ID]&modalType=SITE_MODAL',
  Event: '/landings/events?modalId=[ID]&modalType=LANDINGS_EVENT_MODAL&modalState=edit',
  News: '/landings/news?modalId=[ID]&modalType=ARTICLE_MODAL&modalState=edit',
  Person: '/landings/personalities?modalId=[ID]&modalType=PERSONALITY_MODAL&modalState=edit',
  Place: '/landings/playgrounds?modalId=[ID]&modalType=PLAYGROUND_MODAL&modalState=edit',
  SeoModel: '/landings/seo?modalId=[ID]&modalType=SEO_MODAL&modalState=edit',
  QnA: '/landings/qna?modalId=[ID]&modalType=LANDINGS_QNA_MODAL&modalState=edit',
  CustomPage: '/landings/custom?modalId=[ID]&modalType=LANDINGS_CUSTOM_PAGE_MODAL&modalState=edit',
};

const PAGE_NAME = {
  [PAGES.LANDING_TAGS]: 'tags',
  [PAGES.LANDING_PERSONALITIES]: 'personality',
};

interface IErrorItem {
  model_name: string;
  model_verbose_name: string;
  model_verbose_name_plural: string;
  relations: { id: string; representation: string }[];
}

interface ModalProps {
  data: {
    errors: IErrorItem[];
    name: string;
    page: string;
  };
  closeModal: ModalFunctions['closeModal'];
}

const linkPattern = (type, id) => LINK_PATTERNS[type].replace('[ID]', id);

const LandingsErrorsModal: React.FC<ModalProps> = ({ data, closeModal }) => {
  const { t } = useTranslation(LOCALES.LANDINGS);
  const SECTIONS = {
    CustomPage: t('tags.errors.sections.custom'),
    QnA: t('tags.errors.sections.qna'),
    Event: t('tags.errors.sections.event'),
    News: t('tags.errors.sections.news'),
    SeoModel: t('tags.errors.sections.seo'),
    Landing: t('tags.errors.sections.site'),
    Place: t('tags.errors.sections.playground'),
    Person: t('tags.errors.sections.personality'),
  };

  const { errors, name, page } = data;
  const pageName = PAGE_NAME[page];

  return (
    <>
      <Styled.GlobalStyle />
      <Styled.Wrapper>
        <Styled.Header>
          <Styled.Title>{t(`${pageName}.errors.title`)}</Styled.Title>
          <Styled.Description>
            {t(`${pageName}.errors.description`).replace('[name]', name)}
          </Styled.Description>
        </Styled.Header>
        <Styled.List>
          {errors.map((err) => (
            <Styled.Section key={err.model_name}>
              <Styled.ListTitle>{SECTIONS[err.model_name]}</Styled.ListTitle>
              <Styled.ErrorsList>
                {err.relations.map((item) => (
                  <Styled.ErrorsItem>
                    <Styled.Name>{item.representation}</Styled.Name>
                    <Styled.Link
                      href={linkPattern(err.model_name, item.id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ExternalLinkIcon />
                    </Styled.Link>
                  </Styled.ErrorsItem>
                ))}
              </Styled.ErrorsList>
            </Styled.Section>
          ))}
        </Styled.List>
      </Styled.Wrapper>
      <Modal.Footer>
        <Button type="button" onClick={closeModal}>
          {t('tags.errors.buttons.confirm')}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default LandingsErrorsModal;
