import { createEvent, createStore, sample } from 'effector';
import {
  fetchPersonFx,
  updatePersonFx,
  deletePersonFx,
  FormSubmitData,
  fetchPersonsFx,
  fetchBasicPersonFx,
  IReferencePerson,
} from 'shared/api/reference/persons';
import COOKIES from 'shared/constants/COOKIES';
import PAGES from 'shared/constants/PAGES';
import { showedErrorNotification, showedSuccesNotification } from 'shared/helpers/notification';
import { getCookie } from 'shared/lib/cookie';
import { createToggler } from 'shared/lib/toggler';
import { $query } from '../../../model';
import { invoke } from '@withease/factories';
import { createUpdateModalStates } from 'shared/factory/modal';
import { IBaseRequestDataParams } from 'shared/api/types';

const { $loading, $modalId, modalClosed, modalOpened, toggler } = invoke(createUpdateModalStates);

const formSubmitted = createEvent<FormSubmitData>();
const deleteSubmitted = createEvent<IBaseRequestDataParams>();
export const errorsModalClosed = createEvent();

const $person = createStore<IReferencePerson | null>(null);
const $parentPerson = createStore<IReferencePerson | null>(null);
const $deleteErrors = createStore<any | null>(null);
const $isSubmitting = createStore(false);

$isSubmitting.on(formSubmitted, () => true).on(toggler.close, () => false);

export const personUpdateModal = {
  toggler,
  modalOpened,
  modalClosed,
  formSubmitted,
  deleteSubmitted,
  $person,
  $loading,
  $modalId,
  $deleteErrors,
  $parentPerson,
  $isSubmitting,
};

//load

sample({
  clock: modalOpened,
  target: fetchPersonFx,
});

sample({
  clock: toggler.open,
  target: $modalId,
});

sample({
  clock: toggler.close,
  fn: () => true,
  target: $loading,
});

sample({
  clock: toggler.close,
  fn: () => null,
  target: $person,
});

sample({
  clock: fetchPersonFx.done,
  filter: ({ params }) => params.type === 'update',
  fn: ({ result }) => {
    return result;
  },
  target: $person,
});

sample({
  source: {
    person: $person,
  },
  filter: ({ person }) => Boolean(person?.parent_id),
  fn: ({ person }) => Number(person!.parent_id),
  target: fetchBasicPersonFx,
});

sample({
  clock: fetchBasicPersonFx.done,
  fn: ({ result }) => {
    return result;
  },
  target: $parentPerson,
});

sample({
  clock: fetchPersonFx.done,
  filter: ({ params }) => params.type === 'update',
  fn: () => false,
  target: $loading,
});

//update

sample({
  source: {
    person: $person,
  },
  clock: formSubmitted,
  fn: ({ person }, formData) => {
    return {
      id: person!.id,
      data: {
        title: formData?.title,
        parent_id: formData.parent_id?.value || null,
        type_id: formData.type_id ? formData?.type_id : person?.type_id,
        company_id: getCookie(COOKIES.COMPANY),
      },
    };
  },
  target: updatePersonFx,
});

sample({
  clock: updatePersonFx.fail,
  fn: ({ error }) => {
    if (error.response?.data?.detail?.includes('Person with this title already exist')) {
      return 'Данная персона уже существует.';
    }

    return 'Ошибка';
  },
  target: showedErrorNotification,
});

sample({
  clock: updatePersonFx.fail,
  fn: () => false,
  target: $isSubmitting,
});

sample({
  clock: updatePersonFx.done,
  filter: ({ result }) => !result.parent_id,
  fn: () => null,
  target: $parentPerson,
});

//delete

sample({
  clock: deleteSubmitted,
  target: deletePersonFx,
});

sample({
  clock: deletePersonFx.fail,
  fn: ({ error, params }) => {
    return {
      errors: error.message,
      name: params.title,
      page: PAGES.REFERENCE_PERSONS,
    };
  },
  target: $deleteErrors,
});

sample({
  clock: deletePersonFx.done,
  target: toggler.close,
});

sample({
  clock: errorsModalClosed,
  fn: () => null,
  target: $deleteErrors,
});

sample({
  source: {
    query: $query,
  },
  clock: [updatePersonFx.done, deletePersonFx.done],
  fn: ({ query }) => query,
  target: [fetchPersonsFx, showedSuccesNotification, toggler.close],
});
