import styled, { createGlobalStyle } from 'styled-components';
import { mediumModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${mediumModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    font-size: 20px;
    color: #000;
    word-break: break-all;
    margin-bottom: 24px;
  `,

  Fields: styled.div`
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 32px;
    }
  `,
};
