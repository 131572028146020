import Tabs from 'components/Tabs';
import { useTranslation } from 'next-i18next';
import React from 'react';
import routes, { routesPatterns } from '../../../helpers/routes';

interface Props {
  children: React.ReactElement;
  loading: boolean;
}

export const ReferenceTabs: React.FC<Props> = (props) => {
  const { children, loading } = props;
  const { t } = useTranslation();

  const tabs = [
    {
      href: routesPatterns.reference,
      as: routes.referencePlaces,
      text: t('references:sections.places'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceHalls,
      text: t('references:sections.halls'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceEventCategories,
      text: t('references:sections.event_categories'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceExpansinons,
      text: t('references:sections.expansions'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceCountries,
      text: t('references:sections.countries'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceLocations,
      text: t('references:sections.locations'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceCurrency,
      text: t('references:sections.currency'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referencePersons,
      text: t('references:sections.persons'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceSubdomains,
      text: t('references:sections.subdomains'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceActual,
      text: t('references:sections.actual'),
    },
    {
      href: routesPatterns.reference,
      as: routes.referenceQnA,
      text: 'QnA',
    },
  ];

  return (
    <Tabs tabs={tabs} disabled={loading}>
      {children}
    </Tabs>
  );
};
