import { useUnit } from 'effector-react';
import React from 'react';
import { Modal, ModalClose } from 'shared/ui/modal/ui';
import { HallLayoutForm } from '../../hall-layout-form';
import { createSchemeModal } from '../model';
import Styled from './styles';

export const CreateSchemeModal = () => {
  const handleSubmit = useUnit(createSchemeModal.formSubmitted);
  return (
    <Modal toggler={createSchemeModal.toggler}>
      {({ closeModal }) => (
        <Styled.Body>
          <ModalClose onClick={closeModal} />
          <HallLayoutForm closeModal={closeModal} onSubmit={handleSubmit} />
        </Styled.Body>
      )}
    </Modal>
  );
};
