import { useUnit } from 'effector-react';
import React from 'react';
import { Modal, ModalClose } from 'shared/ui/modal';
import { HallForm } from '../../form';
import { createHallModal } from '../model';
import Styled from './styles';

export const CreateHallModal = () => {
  const { handleSubmit, isSubmitting } = useUnit({
    handleSubmit: createHallModal.formSubmitted,
    isSubmitting: createHallModal.$isSubmitting,
  });

  return (
    <Modal toggler={createHallModal.toggler}>
      {({ closeModal }) => (
        <Styled.Body>
          <ModalClose onClick={closeModal} />
          <HallForm
            isSubmitting={isSubmitting}
            title="references:halls.create_title"
            onSubmit={handleSubmit}
            closeModal={closeModal}
            stashLanguages
          />
        </Styled.Body>
      )}
    </Modal>
  );
};
