import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { mediumModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${mediumModalStyles}
  `,

  Sections: styled.div``,

  Section: styled.div`
    display: flex;

    &:not(:last-child) {
      margin-bottom: 48px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
    }
  `,

  Rows: styled.div`
    width: 100%;
  `,

  Row: styled.div`
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  `,

  Description: styled.div`
    margin-top: 16px;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.64);
  `,
};
