import React, { Component } from 'react';
import { ModalFunctions } from '../../../../interfaces/modal';
import CancelAltIcon from '../../../../static/icons/cancel-alt.svg';
import Styled from './styles';

interface DrawerModalProps {
  title?: string;
  data?: any;
  closeModal: ModalFunctions['closeModal'] | any;
  options?: any;
  optionData?: any;
  optionClick?: any;
}

interface DrawerModalState {
  withShadow: boolean;
}

class DrawerModal extends Component<DrawerModalProps, DrawerModalState> {
  innerRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.state = {
      withShadow: false,
    };

    this.innerRef = React.createRef();
  }

  handleScroll = () => {
    const newValue = this.innerRef.current.scrollTop !== 0;
    if (newValue !== this.state.withShadow) {
      this.setState({
        withShadow: newValue,
      });
    }
  };

  render() {
    const { title, data, options, optionClick, optionData } = this.props.data.modalData;
    const { withShadow } = this.state;

    const renderData = () => {
      if (options) {
        return (
          <Styled.Options withHeader={title}>
            {options.map((option) => (
              <Styled.OptionsItem
                key={option.value}
                disabled={option.disabled}
                divider={option.divider}
                onClick={(event) => {
                  this.props.closeModal(event);
                  optionClick(option.value, optionData, event);
                }}
              >
                <Styled.Option danger={option.danger} disabled={option.disabled} selected={option.selected}>
                  {option.icon && (
                    <Styled.IconWrapper disabled={option.disabled}>{option.icon}</Styled.IconWrapper>
                  )}
                  {option.label}
                </Styled.Option>
              </Styled.OptionsItem>
            ))}
          </Styled.Options>
        );
      }

      return data;
    };

    return (
      <>
        <Styled.GlobalStyles />
        {title && (
          <Styled.Header withShadow={withShadow}>
            {title}
            <Styled.Close onClick={this.props.closeModal}>
              <CancelAltIcon />
            </Styled.Close>
          </Styled.Header>
        )}
        <Styled.Inner ref={this.innerRef} onScroll={this.handleScroll} withOptions={options}>
          {renderData()}
        </Styled.Inner>
      </>
    );
  }
}

export default DrawerModal;
