import ColumnGrid from 'components/ColumnGrid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openRouterModal } from 'entities/modal';

interface ItemProps {
  modalType?: string;
  data?: any;
  id?: number | string;
  children: React.ReactElement | string | undefined;
  onClick?: (id: number | string) => void;
}

export const ReferenceItem: React.FC<ItemProps> = ({ data, id, children, modalType, onClick }) => {
  const dispatch = useDispatch();
  if (!modalType && !onClick) return <ColumnGrid.Button noHover>{children}</ColumnGrid.Button>;

  const openModal = () => {
    if (onClick && id) return onClick(id);
    if (modalType) dispatch(openRouterModal({ modalType, data }));
  };

  return (
    <ColumnGrid.Button onClick={openModal} data-selenium={children}>
      {children}
    </ColumnGrid.Button>
  );
};
