import React from 'react';
import { NotificationManager } from 'react-notifications';
import { Field } from 'react-final-form';
import get from 'lodash/get';
import tables from 'config/tables';
import Styled from './styles';
import Form from '../../Form';
import Button from '../../Button';
import Modal from '../../Modal/Modal';
import { required, composeValidators, maxNumberValue, minNumberValue } from 'shared/helpers/validations';
import { NumberInput } from '../../FormControls';
import { getIdsError } from 'shared/helpers/tables';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  updateAnnouncementsPrice: ({ ids, price }: { ids: number[] | string[]; price: number }) => any;
  setRowsErrors: ({ name, errors }: { name: string; errors: any }) => any;
}

const AnnouncementPriceEditModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    updateAnnouncementsPrice,
    setRowsErrors,
    data: { ids: selectedRows, modalData, name, page },
  } = props;
  const { errorText } = tables[page];

  const onSubmit = ({ price }) =>
    updateAnnouncementsPrice({
      ids: selectedRows,
      price,
    }).then((response) => {
      if (response.error) {
        const ids = get(response, 'error.response.data.error');

        setRowsErrors({
          name,
          errors: getIdsError({ ids, page }),
        });

        return NotificationManager.error(errorText, 'Ошибка');
      }

      closeModal();

      modalData.onModalSubmit();

      return response;
    });

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title isSmall>Редактирование цены</Modal.Title>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values: formValues, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="price"
              validate={composeValidators(required, minNumberValue(1), maxNumberValue(9999999))}
            >
              {({ input, meta: inputMeta }) => (
                <NumberInput label="Итоговая" {...input} placeholder="₽" max={9999999} meta={inputMeta} />
              )}
            </Field>
            <Modal.Footer mobileRow className="modal__footer">
              <Button type="button" transparent onClick={closeModal}>
                Отмена
              </Button>
              <Button disabled={!formValues.price || pristine}>Готово</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default AnnouncementPriceEditModal;
