import React from 'react';
import { Field } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import Styled from './styles';
import Button from '../../Button';
import Form from '../../Form';
import {
  required,
  validDate,
  composeValidators,
  minNumberValue,
  maxNumberValue,
  validSpecialChars,
} from 'shared/helpers/validations';
import { RadioGroup, DateTimePicker, DiscountInput } from '../../FormControls';
import { DESTINATION_TYPES, PRICE_CHANGE_TYPES } from 'shared/constants/ORDER';
import { calcNewPrice, getMaxPrice } from 'shared/helpers/order';
import WhenFieldChanges from 'shared/lib/FormWhenFieldChanges';
import ArrowGreenUpIcon from '../../../../static/icons/arrow-green-up.svg';
import ArrowRedDownIcon from '../../../../static/icons/arrow-red-down.svg';
import TrashIcon from '../../../../static/icons/trash.svg';
import SplittedNumber from '../../SplittedNumber';

const ICONS = {
  [DESTINATION_TYPES.DISCOUNT]: ArrowRedDownIcon,
  [DESTINATION_TYPES.MARKUP]: ArrowGreenUpIcon,
};

interface Props {
  price: number;
  createSchedule: (data: any) => any;
  hideForm: () => void;
  isEdit: any;
  currency: any;
}

const AnnouncementPlanningPriceForm: React.FC<Props> = ({
  price,
  currency,
  createSchedule,
  hideForm,
  isEdit,
}) => (
  <Styled.Form>
    <Form
      onSubmit={createSchedule}
      initialValues={{
        amount: 0,
        destination: DESTINATION_TYPES.MARKUP.toString(),
        discount_type: PRICE_CHANGE_TYPES.NUMBER.toString(),
        ...isEdit,
      }}
      render={({ handleSubmit, form, values }) => {
        const max = getMaxPrice({ values, destination: Number(values.destination), price });
        const newPrice = calcNewPrice({
          price,
          discountType: Number(values.discount_type),
          amount: Number(values.amount),
          destination: Number(values.destination),
        }).toFixed();

        return (
          <form onSubmit={handleSubmit}>
            <WhenFieldChanges
              field="discount_type"
              becomes={PRICE_CHANGE_TYPES.NUMBER.toString()}
              set="amount"
              to={0}
            />
            <WhenFieldChanges
              field="discount_type"
              becomes={PRICE_CHANGE_TYPES.PERCENTAGE.toString()}
              set="amount"
              to={0}
            />
            <WhenFieldChanges
              field="destination"
              becomes={DESTINATION_TYPES.MARKUP.toString()}
              set="amount"
              to={0}
            />
            <WhenFieldChanges
              field="destination"
              becomes={DESTINATION_TYPES.DISCOUNT.toString()}
              set="amount"
              to={0}
            />
            <Styled.Row>
              <Styled.Sidebar>
                <Styled.SidebarTitle>Дата</Styled.SidebarTitle>
              </Styled.Sidebar>

              <Field name="change_date" validate={validDate}>
                {({ input, meta: inputMeta }) => (
                  <DateTimePicker
                    label="Дата и время применения"
                    name="change_date"
                    handleForm={form}
                    input={input}
                    meta={inputMeta}
                    outdatedToggler
                    fixedError
                    outdated
                    minuteStep={5}
                  />
                )}
              </Field>
            </Styled.Row>
            <Styled.Row>
              <Styled.Sidebar>
                <Styled.SidebarTitle>Цена</Styled.SidebarTitle>
                <Styled.SidebarDescription>Изменение цены</Styled.SidebarDescription>
              </Styled.Sidebar>
              <Field name="destination" validate={required}>
                {({ input, meta: inputMeta }) => (
                  <RadioGroup
                    label="Изменение цены"
                    {...input}
                    meta={inputMeta}
                    buttons={[
                      { id: 0, value: DESTINATION_TYPES.MARKUP.toString(), name: 'Повышение' },
                      { id: 1, value: DESTINATION_TYPES.DISCOUNT.toString(), name: 'Понижение' },
                    ]}
                  />
                )}
              </Field>
            </Styled.Row>
            <Styled.Row>
              <Styled.Sidebar />
              <Field
                name="amount"
                validate={composeValidators(
                  required,
                  minNumberValue(1),
                  maxNumberValue(max),
                  validSpecialChars(),
                )}
              >
                {({ input, meta: inputMeta }) => (
                  <DiscountInput
                    input={input}
                    meta={inputMeta}
                    destination={values.destination}
                    max={max}
                    fixedError
                    customIcons={ICONS}
                    currency={currency.char_code}
                  />
                )}
              </Field>
            </Styled.Row>
            <Styled.Row>
              <Styled.NewPrice>
                {'Новая цена за шт: '}
                <div>
                  <SplittedNumber withSuffix icon={currency.icon}>
                    {newPrice}
                  </SplittedNumber>
                </div>
              </Styled.NewPrice>
            </Styled.Row>
            <Styled.FooterWrapper>
              <Styled.Footer>
                {!isEmpty(isEdit) && (
                  <Styled.Delete>
                    <Button
                      opacity={0.4}
                      onlySmallIcon
                      transparent
                      data={isEdit.item}
                      onClick={isEdit.delete}
                    >
                      <TrashIcon />
                    </Button>
                  </Styled.Delete>
                )}
                <Button transparent type="button" onClick={hideForm}>
                  Отмена
                </Button>
                <Button>{isEmpty(isEdit) ? 'Готово' : 'Сохранить'}</Button>
              </Styled.Footer>
            </Styled.FooterWrapper>
          </form>
        );
      }}
    />
  </Styled.Form>
);

export default AnnouncementPlanningPriceForm;
