import styled, { css } from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import { getBorderOnError } from 'shared/helpers/input';
import { layoutScrollbar } from '../../../app/styles/scrollbar';

interface OptionStylesProps {
  isFocused?: boolean;
}

const OptionStyles = css<OptionStylesProps>`
  padding: 7px 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  word-break: break-all;

  background-color: ${({ isFocused }) => isFocused && '#f5f5f5'};

  @media ${MEDIA_QUERIES.DESKTOP} {
    &:hover {
      background-color: #f5f5f5;
      & div:last-child {
        opacity: 1;
      }
    }
  }
`;

export default {
  Container: styled.div`
    position: relative;
    width: 100%;
  `,

  FieldContainer: styled.div<any>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .react-select--is-disabled {
      cursor: not-allowed;
      pointer-events: all;
    }

    .react-select__control {
      padding: 1px;
      min-height: ${({ isSmall }: any) => (isSmall ? '32px' : '40px')};
      border: 1px solid #e1e1e1;
      background-color: #fff;
      box-shadow: none;
      border-radius: 8px;
      transition: all 0.2s ease-in-out;
      flex-wrap: nowrap;
      ${getBorderOnError}

      &:hover {
        border-color: #5c5c5c;

        &:not(.react-select__control--is-focused) .react-select__indicators svg {
          fill: #000;
        }
      }
    }

    .react-select__control--is-focused {
      border: 2px solid #2f80ed !important;
      padding: 0 !important;
    }

    .react-select__control--is-disabled {
      pointer-events: none;

      & .react-select__single-value {
        color: #999;
        font-size: ${({ isSmall }: any) => isSmall && '14px'};
      }
    }

    .react-select__value-container {
      height: 100%;
      min-height: ${({ isSmall }: any) => (isSmall ? '28px' : '36px')};
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: 3px 0px 1px 11px;
      box-sizing: border-box;
      cursor: text;
      width: 80%;
      overflow: visible;

      & > div {
        width: ${({ isMulti }: any) => (!isMulti ? '100%' : null)};
        word-break: break-all;
        padding-right: 4px;
      }

      & .react-select-multi-value-for-event__placeholder {
        word-break: break-all;
      }
    }

    .react-select__value-container.react-select__value-container--is-multi {
      & > div {
        margin: 2px;
      }
    }

    .react-select__input {
      max-width: 100%;
      width: 100%;

      input {
        max-width: 100%;
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .react-select__multi-value {
      margin: 0 0 3px 0;
      background-color: #f4f4f4;
      box-sizing: border-box;
    }

    .react-select__multi-value__label {
      box-sizing: border-box;
      min-height: 24px;
      font-size: 12px;
      color: #000;
      padding: 0 2px 0 8px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      overflow: auto;
      white-space: normal;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__loading-indicator {
      display: none;
    }

    .react-select__clear-indicator {
      padding: 0;

      & svg {
        fill: #999;
        transition: all 0.2s ease-in-out;
      }
    }

    .react-select__placeholder {
      margin: 0;
      color: #5c5c5c;
      font-size: ${({ isSmall }: any) => (isSmall ? '14px' : '16px')};
    }

    .react-select__single-value {
      margin: 0;
      color: #000;

      ${({ isSmall }: any) =>
        isSmall &&
        css`
          font-size: 14px;

          & + div {
            border: none;
            margin: 0;
          }
        `}
    }

    .react-select__menu {
      z-index: 101;
      margin-top: 0;
      border: 0;
      border-radius: 8px;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
    }

    .react-select__menu-list {
      padding: 8px 0;
      ${layoutScrollbar};
    }

    .react-select__group {
      padding: 0;
    }

    .react-select__menu-list > .react-select__group:not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
    }

    .react-select__menu-list > .react-select__group:not(:last-child) {
      padding: 0 0 4px 0;
      margin-bottom: 4px;
    }

    .react-select__group-heading {
      min-height: 32px;
      margin: 0;
      padding: 8px 12px;
      color: #5c5c5c;
      font-size: 12px;
    }

    .react-select__menu-notice--no-options {
      color: #5c5c5c;
      font-size: 14px;
    }
  `,

  Option: styled.div<OptionStylesProps>`
    ${OptionStyles}
  `,

  OptionLabel: styled.label<OptionStylesProps>`
    ${OptionStyles}
  `,

  OptionCheckbox: styled.div`
    margin-right: 12px;
  `,

  OptionName: styled.div`
    font-weight: ${({ isPreview }: any) => isPreview && '700'};
  `,

  OptionAllExceptCurrent: styled.div`
    margin-left: auto;
    padding-left: 8px;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    color: #2f80ed;
    transition: all 0.2s ease-in-out;
    opacity: 0;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: none;
    }
  `,

  DropdownIndicator: styled.div<any>`
    padding: 0 10px 0 8px;
    height: 100%;
    display: flex;
    align-items: center;
    transform: ${({ isFocused }: any) => isFocused && 'rotate(180deg)'};
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    cursor: pointer;

    & svg {
      transition: all 0.2s ease-in-out;
    }
  `,

  Spinner: styled.div`
    display: flex;
    justify-content: center;
  `,

  PlaceInfo: styled.div`
    display: flex;
    flex-direction: column;
  `,

  PlaceInfoAddress: styled.div`
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.64);
  `,

  RemoveMultiValue: styled.div`
    min-width: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    & svg {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      background-color: #eb5757;

      & svg {
        transition: all 0.2s ease-in-out;
        filter: brightness(0) invert(1);
        opacity: 1;
      }
    }
  `,

  ClearIndicator: styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      transition: all 0.2s ease-in-out;
    }

    &:hover svg {
      opacity: 1;
    }
  `,

  MultiValueForEvent: styled.div<any>`
    .react-select-multi-value-for-event__control {
      border-radius: 6px;
      border: 0;
      box-shadow: none;
      min-height: 24px;
      background-color: #f4f4f4;
      padding: 2px 4px 2px 8px;
      cursor: pointer;
      box-sizing: border-box;
      line-height: 20px;

      & svg {
        opacity: 0.24;
      }

      & svg:hover {
        opacity: 1;
      }

      input {
        position: absolute;
      }
    }

    .react-select-multi-value-for-event__placeholder {
      position: relative;
      font-size: 12px;
      color: #000;
      margin: 0;
      transform: none;
      top: auto;
    }

    .react-select-multi-value-for-event__indicator-separator {
      display: none;
    }

    .react-select-multi-value-for-event__dropdown-indicator {
      display: none;
    }

    .react-select-multi-value-for-event__clear-indicator {
      padding: 0;
    }

    .react-select-multi-value-for-event__menu {
      width: auto;
      z-index: 3;
    }

    .react-select-multi-value-for-event__menu-list > div {
      white-space: nowrap;
    }

    ${({ isChildrensNotSelected }: any) =>
      !isChildrensNotSelected &&
      css`
        .react-select-multi-value-for-event__menu-list > div:first-child span {
          background-color: #2dc472;
          background-image: url('/static/Checkbox/multi.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      `}

    .react-select-multi-value-for-event__value-container {
      padding: 0;
    }
  `,

  MultiValueForEventPlaceholder: styled.div`
    & > span:first-child {
      margin-right: 6px;
    }

    & > span:last-child {
      white-space: nowrap;
      color: #2f80ed;
    }
  `,

  MultiValueForEventPlaceholderRed: styled.span`
    color: #eb5757;
  `,

  Tag: styled.div`
    border-radius: 6px;
    border: 0;
    box-shadow: none;
    min-height: 24px;
    max-width: 100%;
    background-color: #f4f4f4;
    padding: 2px 4px 2px 8px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    line-height: 20px;

    & svg {
      opacity: 0.24;
    }

    & svg:hover {
      opacity: 1;
    }
  `,

  EventCategoryInfo: styled.div`
    display: flex;
    flex-direction: column;
  `,

  EventCategoryInfoPath: styled.div`
    margin: 0 -4px;
    display: flex;
    color: #5c5c5c;
    white-space: nowrap;
    flex-wrap: wrap;

    & > div {
      display: flex;
      align-items: center;
    }

    & svg {
      transform: rotate(-90deg);
      fill: #999;
      margin: 0 4px;
    }
  `,

  ValueContainer: styled.div`
    display: flex;
    flex-grow: 1;
  `,

  LanguageWrapper: styled.div`
    margin-left: auto;
    padding-left: 8px;
  `,

  Language: styled.img`
    height: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  `,

  Client: styled.div``,

  ClientName: styled.p`
    font-size: 14px;
    line-height: 18px;
  `,

  ClientInfo: styled.p`
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    opacity: 0.64;

    span:not(:last-child):after {
      position: relative;
      content: '•';
      margin: 0 4px;
    }
  `,
};
