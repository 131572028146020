import React from 'react';
import { prepareAnnouncementsData } from 'shared/helpers/announcement';
import api from 'shared/services/api';
import announcementsSource from 'shared/sources/announcements';
import Button from '../../../Button';

interface SubmitProps {
  selectedEventId: number | string;
  announcements: any[];
  seatsIds?: any;
  disabled: boolean;
  showProgress: (value: any) => any;
  setErrors: (data: any) => any;
}

const AnnouncementsSubmit: React.FC<SubmitProps> = (props) => {
  const { announcements, showProgress, selectedEventId, setErrors, seatsIds = {}, disabled } = props;

  const createEvent = () => {
    showProgress(announcements.length);

    const data = prepareAnnouncementsData({
      announcements,
      seatsIds,
      eventId: selectedEventId,
    });

    api
      .post(announcementsSource.bulkCreate, { announcements: data })
      .then(() => {
        window.location.reload();
      })
      .catch((response) => {
        setErrors(response.response.data.error);
      });
  };

  return (
    <Button onClick={createEvent} disabled={disabled} data-selenium="announcement-create">
      Готово
    </Button>
  );
};

export default AnnouncementsSubmit;
