import React from 'react';
import Script from 'react-load-script';
import moment from 'moment';
import Styled from '../styled';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';

let pWidget;

const renderWidget = (event) => {
  if (pWidget) {
    pWidget.unmount();
    document.getElementById('widget').innerHTML = '';
  }

  const widgetDates = event.event.dates
    .filter((child) => {
      return !moment(child.finished_at, DATE_FORMATS.ISO).isBefore(moment());
    })
    .map((child) => {
      const [dateStart, timeStart] = child.started_at.split('T');

      return {
        id: child.id,
        date: `${dateStart} ${timeStart}`,
      };
    });
  pWidget = new window.PWidget({
    node: 'widget',
    onSelectTicket() {},
    settings: {
      landingPreivewMode: true,
      theme: 'common',
      codeId: process.env.WIDGET_CODE_ID,
      token: process.env.WIDGET_CAPTCHA_TOKEN,
      eventHallLayoutId: event.event.hall_layout_id,
      eventSourceId: event.id,
      eventDateId: widgetDates[0].id,
      dates: widgetDates,
      eventData: {
        place: {
          id: 0,
          name: '',
          address: '',
          location: {
            id: '',
            name: '',
            slug: '',
          },
          country: {
            name: '',
          },
        },
        title: event.event.title,
      },
      hintText: event.widgetHint,
      hintIsVisible: event.widgetHintIsVisible,
    },
  });
};

interface WidgetProps {
  event: any;
  children: any;
}

const LandingsEventWidget: React.FC<WidgetProps> = (props) => {
  const onLoadWidget = () => {
    renderWidget(props.event);
  };

  return (
    <>
      <Styled.WidgetPreviewContainer>
        <Styled.WidgetPreviewTabs>
          <Styled.WidgetPreviewBody>
            <div id="widget" />
          </Styled.WidgetPreviewBody>
        </Styled.WidgetPreviewTabs>
      </Styled.WidgetPreviewContainer>

      <Script
        url={`${process.env.WIDGET_SRC}${process.env.WIDGET_VERSION}/widget.js?v=4.0.2`}
        onLoad={onLoadWidget}
      />
      {props.children}
    </>
  );
};

export default LandingsEventWidget;
