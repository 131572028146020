import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { LangForm } from '../../../../Form';
import {
  required,
  composeValidators,
  minNumberValue,
  maxNumberValue,
  eventWithHall,
  maxValue,
} from 'shared/helpers/validations';
import { getDefaultMarkup } from 'shared/helpers/widgets';
import {
  Textarea,
  WidgetEventSelector,
  RadioGroup,
  NumberInput,
  Checkbox,
  Input,
} from '../../../../FormControls';
import Button from '../../../../Button';
import Modal from '../../../../Modal';
import TrashIcon from '../../../../../../static/icons/trash-alt.svg';
import ConfirmModal from '../../../../Modals/ConfirmModal';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import api from 'shared/services/api';
import { getPeriodicalChildrenData } from 'shared/helpers/events';
import { eventsSources } from 'entities/events';
import { ModalFunctions } from '../../../../../../interfaces/modal';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import Spinner from '../../../../Spinner';
import { WIDGET_DISPLAY_TYPES_OPTIONS } from 'shared/constants/WIDGET_V2';
import { createWidget, deleteWidget, editWidget } from '../../../../../features/widgets';
import Styled from './styles';

let form;

interface StepProps {
  isMobile: boolean;
  onSubmit: (data: any) => any;
  updateState: any;
  closeModal: ModalFunctions['closeModal'];
  data: any;
  isEdit: boolean;
  isPreview: boolean;
  onChangeLng: (lng: string) => void;
  isChangingData: boolean;
}

const WidgetFirstStep: React.FC<StepProps> = ({
  onSubmit,
  updateState,
  data,
  isEdit,
  isPreview,
  isMobile,
  closeModal,
  onChangeLng,
  isChangingData,
}) => {
  const [isDelete, toggleDeleteState] = useState(false);
  const dispatch = useDispatch();
  const setCreateAction = () => {
    form.change('submit_action', SUBMIT_ACTIONS.CREATE);
  };

  const submitFirstStep = async (submitData) => {
    const formData = submitData[submitData.lng];

    const onUpdate = async (action) => {
      if (submitData.submit_action === SUBMIT_ACTIONS.CREATE) {
        NotificationManager.success('Успешно');
        return closeModal();
      }
      let dates = {};

      if (get(formData, 'event_id.data.is_periodical', data.event && data.event.is_periodical)) {
        const event = await api.get(eventsSources.detail(formData.event_id.value), {
          params: { outdated: true },
        });

        dates = getPeriodicalChildrenData(event.data);
      }

      if (action.error) {
        return NotificationManager.error('Ошибка');
      }

      const updatedData: any = {
        ...action.payload,
        ...dates,
        event_id: formData.event_id,
        event: formData.event_id?.data,
      };

      if (isEdit) {
        return onSubmit({ ...updatedData, lng: 'ru' });
      }

      return updateState({
        isEdit: true,
        step: 1,
        data: {
          ru: updatedData,
        },
        lng: 'ru',
      });
    };

    if (isEdit) {
      const d = {
        ...omit({ ...formData, event_id: formData.event_id?.value }),
        description: formData.description || '',
        is_table: formData.is_table || false,
        lang: submitData.lng,
      };

      const action = await dispatch(editWidget(d));

      return onUpdate(action);
    }

    const action = await dispatch(
      createWidget({
        ...omit({ ...formData, event_id: formData.event_id?.value }, [
          'id',
          'item_id',
          'markup',
          'scope',
          'event',
          'parent_id',
        ]),
        description: formData.description || '',
        is_table: formData.is_table || false,
        markup: getDefaultMarkup(),
      }),
    );

    return onUpdate(action);
  };

  // @ts-ignore
  const handleDelete = () => dispatch(deleteWidget(data.id)).then(closeModal);

  return (
    <>
      <Styled.Wrapper>
        <LangForm
          fluid
          stashLanguages={!isEdit}
          initialValues={data}
          onSubmit={submitFirstStep}
          onChangeLng={onChangeLng}
          render={({ handleSubmit, form: finalForm, values, switcher, i18n }) => {
            form = finalForm;
            const fieldAvailable = i18n.language === LANGUAGES.RU;

            if (isChangingData) {
              return <Spinner center />;
            }

            return (
              <form onSubmit={handleSubmit} key={data}>
                <Styled.Row>{switcher}</Styled.Row>
                {fieldAvailable && (
                  <>
                    <Styled.Row>
                      <Styled.Name>
                        Мероприятие
                        <Styled.Desc>Мероприятия и дата, под которое создается виджет</Styled.Desc>
                      </Styled.Name>
                      <Styled.Field>
                        <Field
                          name={`${i18n.language}.event_id`}
                          validate={composeValidators(required, eventWithHall)}
                        >
                          {({ input, meta }) => (
                            <WidgetEventSelector
                              canSelectParent={!isEdit}
                              dateRenderCount={3}
                              disabled={isEdit || isPreview}
                              input={{
                                ...input,
                                value: {
                                  ...(input.value || data.event_id),
                                  // @ts-ignore
                                  data: data.event || input.data || values.ru?.event_id?.data,
                                },
                              }}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </Styled.Field>
                    </Styled.Row>
                    <Styled.Row>
                      <Styled.Name>
                        Форма продажи
                        <Styled.Desc>Продажа билетов по местам или по категориям </Styled.Desc>
                      </Styled.Name>
                      <Styled.DisplayType>
                        <Styled.DisplayTypeField>
                          <Field name={`${i18n.language}.display_type`} validate={required}>
                            {({ input, meta }) => (
                              <RadioGroup
                                {...input}
                                value={input.value}
                                meta={meta}
                                buttons={WIDGET_DISPLAY_TYPES_OPTIONS}
                              />
                            )}
                          </Field>
                        </Styled.DisplayTypeField>
                        <Styled.Field>
                          <Field name={`${i18n.language}.is_table`} type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Таблица
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.Field>
                      </Styled.DisplayType>
                    </Styled.Row>
                    <Styled.Row>
                      <Styled.Name>
                        Комментарий
                        <Styled.Desc>Дополнительное описание к виджету</Styled.Desc>
                      </Styled.Name>
                      <Styled.Field>
                        <Field name={`${i18n.language}.description`}>
                          {({ input, meta }) => (
                            <Textarea label="Комментарий" {...input} disabled={isPreview} meta={meta} />
                          )}
                        </Field>
                      </Styled.Field>
                    </Styled.Row>
                    <Styled.Row>
                      <Styled.Name>
                        Остатки
                        <Styled.Desc>При каком % билетов выделять сектор</Styled.Desc>
                      </Styled.Name>
                      <Styled.Field>
                        <Field
                          name={`${i18n.language}.leftover_threshold`}
                          validate={composeValidators(required, minNumberValue(0), maxNumberValue(100))}
                        >
                          {({ input, meta: inputMeta }) => (
                            <NumberInput {...input} min={0} max={100} meta={inputMeta} />
                          )}
                        </Field>
                      </Styled.Field>
                    </Styled.Row>
                  </>
                )}
                <Styled.Row>
                  <Styled.Name>Подсказки</Styled.Name>
                  <div>
                    {fieldAvailable && (
                      <>
                        <Styled.Field>
                          <Field name={`${i18n.language}.is_pushkin`} type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Пушкинская карта
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.Field>
                        <Styled.Field>
                          <Field name={`${i18n.language}.is_show_hint`} type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Показать подсказку
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.Field>
                      </>
                    )}
                    <Styled.Field>
                      <Field name={`${i18n.language}.hint_text`} validate={maxValue(160)}>
                        {({ input, meta }) => (
                          <Input
                            label="Текст подсказки"
                            {...input}
                            meta={meta}
                            disabled={fieldAvailable && !values[i18n.language]?.is_show_hint}
                          />
                        )}
                      </Field>
                    </Styled.Field>
                  </div>
                </Styled.Row>
                <Modal.Footer fullSize contentWidth="800px">
                  {isEdit ? (
                    <Button
                      dangerTransparent
                      onlyIcon={isMobile}
                      type="button"
                      onClick={() => toggleDeleteState(!isDelete)}
                    >
                      {isMobile ? <TrashIcon /> : 'Удалить'}
                    </Button>
                  ) : (
                    <Button type="button" transparent onClick={closeModal}>
                      Отмена
                    </Button>
                  )}
                  {isEdit && (
                    <Button type="submit" gray onClick={setCreateAction}>
                      Сохранить
                    </Button>
                  )}
                  <Button type="submit">Продолжить</Button>
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Styled.Wrapper>
      {isDelete && (
        <ConfirmModal
          withPopover
          data={{
            title: 'Удалить виджет?',
            text: 'Данный виджет удалится. Вы уверены, что хотите совершить это действие?',
            onSubmit: () => handleDelete(),
          }}
          closeModal={() => toggleDeleteState(!isDelete)}
          padding="24px"
        />
      )}
    </>
  );
};

export default WidgetFirstStep;
