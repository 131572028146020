import React, { useState } from 'react';
import get from 'lodash/get';
import { useDebouncedCallback } from 'use-debounce';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import playgroundsSources from 'shared/sources/playgroundsv2';
import { renderWidgetPlaygrounds } from 'shared/helpers/optionsRenderers';
import { InputWithAutocomplete, Input } from '../../../../../FormControls';
import { createSeatsValidation, seatValidation } from 'shared/helpers/validations';
import { StyledError } from '../../../../../FormControls/Error';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';
import { seatsStringToArray, convertSeats } from 'shared/helpers/seats';
import Tooltip from '../../../../../Tooltip';
import { DataContext } from '../DataContext';
import WidgetStyled from '../../WidgetForm/styles';

let seats;

const setSeatsIds = (_, seatsIds) => {
  seats = seatsIds;
};

interface SeatProps {
  sector: any;
  row: any;
  field: string;
  title: string;
  disabled: boolean;
  onChangeSector: (data: any) => void;
  onChangeRow: (data: any) => void;
  onChangeSeat: (data: any) => void;
  value: any;
}

const ValueEditorSeat: React.FC<SeatProps> = (props) => {
  let asyncSeatsValidation = null;
  const { sector, row, field, title, disabled, onChangeSector, onChangeRow, onChangeSeat, value } = props;
  const [error, setError] = useState(null);
  const [seatsValue, setSeats] = useState(value ? convertSeats(value.map((seat) => seat.name)) : null);

  const validateAndSetSeats = useDebouncedCallback((values) => {
    asyncSeatsValidation(values).then((errors) => {
      const seatError = get(errors, 'seat');

      if (seatError) {
        setError(seatError);
        Emmiter.emit(EVENTS.WIDGET_QUERY_ERROR, seatError);
        return;
      }

      const seatsArray = seatsStringToArray(values.seat).map((item) => ({
        value: seats[item],
        name: item,
      }));

      onChangeSeat(seatsArray);
    });
  }, 500);

  const handleChange = ({ target: { value: inputValue } }) => {
    const seatsFormatError = seatValidation(inputValue);

    setSeats(inputValue);

    if (seatsFormatError) {
      setError(seatsFormatError);
      Emmiter.emit(EVENTS.WIDGET_QUERY_ERROR, seatsFormatError);
      return;
    }

    if (error) {
      setError(null);
      Emmiter.emit(EVENTS.WIDGET_QUERY_ERROR);
    }

    validateAndSetSeats({
      seat: inputValue,
      row: {
        value: row.value,
      },
    });
  };

  return (
    <DataContext.Consumer>
      {(context) => {
        const event = context && context.event;
        asyncSeatsValidation = createSeatsValidation({
          event,
          setSeatsIds,
        });

        const query = {
          layout_id: event && (event.hall_layout && event.hall_layout.id || event.hall_layout_id),
          fragment_type: FRAGMENT_TYPES.SECTOR,
        };
        const rowQuery = {
          layout_id: event && (event.hall_layout && event.hall_layout.id || event.hall_layout_id),
          fragment_type: FRAGMENT_TYPES.ROW,
          parent: sector && sector.value,
        };

        return (
          <WidgetStyled.Row>
            <WidgetStyled.Column>
              <Tooltip text={sector && sector.name}>
                <div>
                  <InputWithAutocomplete
                    fluid
                    defaultOptions
                    disablePaginate
                    withoutDefaultValue
                    key={`${field}_sector`}
                    value={sector}
                    title={title}
                    query={query}
                    isMulti={false}
                    isDisabled={disabled}
                    route={playgroundsSources}
                    routeName="root"
                    onChange={onChangeSector}
                    optionsRenderer={renderWidgetPlaygrounds}
                  />
                </div>
              </Tooltip>
            </WidgetStyled.Column>
            <WidgetStyled.Column>
              <InputWithAutocomplete
                fluid
                isMulti={false}
                defaultOptions
                disablePaginate
                withoutDefaultValue
                key={`${field}_row`}
                value={row}
                title={title}
                query={rowQuery}
                isDisabled={!sector}
                route={sector && playgroundsSources}
                routeName="root"
                onChange={onChangeRow}
                optionsRenderer={renderWidgetPlaygrounds}
              />
            </WidgetStyled.Column>
            <WidgetStyled.Column>
              <Input
                label="Места"
                readOnly={!row}
                disabled={!row}
                onChange={handleChange}
                value={seatsValue}
              />
              {error && <StyledError>{error}</StyledError>}
            </WidgetStyled.Column>
          </WidgetStyled.Row>
        );
      }}
    </DataContext.Consumer>
  );
};

export default ValueEditorSeat;
