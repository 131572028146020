import React from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import Router from 'next/router';
import routes from 'shared/helpers/routes';
import { openModal } from 'entities/modal';
import { createOrder, addTicketsFromPool, removeContext, closeOrder } from 'entities/order';
import { closeUpdateBookingModal } from 'entities/announcement';
import { closeScheme, openScheme } from 'entities/pool/scheme';
import Button from 'components/Button';
import MODALS from 'shared/constants/MODALS';
import AnnouncementInfo from './AnnouncementInfo';
import Styled from './styles';
import { ModalFunctions } from '../../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  openModal: ModalFunctions['openModal'];
  createOrder: (data: any) => any;
  orderContext?: {
    id?: string;
  };
  addTicketsFromPool: (id: string | number, data: any) => any;
  removeContext: () => void;
  closeOrder: () => void;
  schemeClosedByBookingModal: boolean;
  closeUpdateBookingModal: () => void;
  closeScheme: () => void;
  openScheme: () => void;
  event: any;
}

const BookingUpdatesModal: React.FC<ModalProps> = (props) => {
  const { selectedAnnouncements } = props.data;
  const { checks } = props.data.errorData;

  const createNewOrder = () => {
    const { order, orderContext } = props.data;

    const newOrder = {
      ...order,
      sellers: order.sellers.map((seller) => ({
        ...seller,
        announcements: seller.announcements.map((announcement) => {
          const currentCheck = checks.find((check) => check.announcement === announcement.id);

          if (!currentCheck) return announcement;

          if (Array.isArray(currentCheck.tickets)) {
            const disabledTickets = new Set(currentCheck.tickets.map((ticket) => ticket.id));

            return {
              ...announcement,
              tickets: announcement.tickets.filter((ticket) => !disabledTickets.has(ticket)),
            };
          }

          return {
            ...announcement,
            tickets: currentCheck.tickets,
          };
        }),
      })),
    };

    const callback = (results) => {
      if (!results.error) {
        props.closeUpdateBookingModal();
        props.closeModal();

        if (orderContext) {
          props.closeOrder();
          props.removeContext();
          Router.push(`${routes.sales}?id=${orderContext.id}`).catch((err) => new Error(err));
          return;
        }

        window.location.reload();
      }

      if (results.error) {
        props.openModal(MODALS.BOOKING_UPDATES_MODAL, {
          errorData: results.error.response.data,
          selectedAnnouncements,
          order: newOrder,
        });
      }
    };

    if (orderContext) {
      props.addTicketsFromPool(orderContext.id, omit(newOrder, ['user_customer'])).then(callback);
    }

    props.createOrder(newOrder).then(callback);
  };

  const hasAvailableTicketsFromChecks =
    checks &&
    checks.some((check) => {
      const currentAnnouncement = selectedAnnouncements.find(
        (announcement) => announcement.id === check.announcement,
      );

      if (!Array.isArray(check.tickets)) {
        return check.tickets;
      }

      return currentAnnouncement.selectedTickets.length !== check.tickets.length;
    });

  let availableAnnouncements: any = false;

  if (checks && checks.length !== selectedAnnouncements.length) {
    const checksAnnouncementsIds = new Set(checks.map((check) => check.announcement));
    availableAnnouncements = selectedAnnouncements.filter(
      (announcement) => !checksAnnouncementsIds.has(announcement.id),
    );
  }

  const closeUpdateModal = () => {
    props.closeUpdateBookingModal();

    if (props.schemeClosedByBookingModal) {
      props.openScheme();
      return props.openModal(MODALS.POOL_SCHEME, {
        modalData: { event: props.event, fullSize: true, onModalClose: props.closeScheme },
      });
    }

    return props.closeModal();
  };

  return (
    <>
      <Styled.Title>Билеты были приобретены другим пользователем</Styled.Title>
      {(hasAvailableTicketsFromChecks || Boolean(availableAnnouncements.length)) && (
        <Styled.SubTitle>Доступные билеты:</Styled.SubTitle>
      )}
      {hasAvailableTicketsFromChecks &&
        checks.map((announcementCheck) => {
          const isNumericTicket = !Array.isArray(announcementCheck.tickets);
          const announcement = selectedAnnouncements.find(
            (announcementItem) => announcementItem.id === announcementCheck.announcement,
          );

          if (isNumericTicket && !announcementCheck.tickets) return null;

          if (isNumericTicket) {
            return (
              <AnnouncementInfo
                isNumericTicket
                key={announcement.id}
                announcement={announcement}
                count={announcementCheck.tickets}
              />
            );
          }

          const ticketsSet = new Set(announcementCheck.tickets.map((ticket) => ticket.id));
          const tickets = announcement.selectedTickets.filter((ticket) => !ticketsSet.has(ticket.id));

          if (!tickets.length) return null;

          return <AnnouncementInfo key={announcement.id} announcement={announcement} tickets={tickets} />;
        })}
      {Boolean(availableAnnouncements.length) &&
        availableAnnouncements.map((announcement) => {
          const { selectedTickets } = announcement;

          if (Array.isArray(selectedTickets)) {
            return (
              <AnnouncementInfo key={announcement.id} announcement={announcement} tickets={selectedTickets} />
            );
          }

          return (
            <AnnouncementInfo
              isNumericTicket
              key={announcement.id}
              announcement={announcement}
              count={selectedTickets}
            />
          );
        })}
      <Styled.Buttons>
        {(hasAvailableTicketsFromChecks || Boolean(availableAnnouncements.length)) && (
          <Button type="button" onClick={createNewOrder}>
            Забронировать оставшиеся
          </Button>
        )}
        <Button type="button" onClick={closeUpdateModal}>
          Вернуться
        </Button>
      </Styled.Buttons>
    </>
  );
};

const mapStateToProps = (state) => ({
  orderContext: state.orderContext.data,
  schemeClosedByBookingModal: state.poolScheme.closedByBookingModal,
  event: state.event.data,
});

const mapDispatchToProps = {
  openModal,
  createOrder,
  addTicketsFromPool,
  closeOrder,
  removeContext,
  closeUpdateBookingModal,
  closeScheme,
  openScheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingUpdatesModal);
