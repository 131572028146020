import React from 'react';
import Styled from './styles';
import { renderTickets } from 'shared/helpers/tickets';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalAddTickets: React.FC<IHistoryItem> = ({ data, user }) => (
  <Styled.Info>
    <Styled.InfoTitle>Билеты добавлены</Styled.InfoTitle>
    <AnnouncementHistoryModalName data={user} />
    <Styled.InfoText>{data && data.tickets && renderTickets(data.tickets)}</Styled.InfoText>
  </Styled.Info>
);

export default AnnouncementHistoryModalAddTickets;
