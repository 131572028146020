import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Styled from '../styles';
import GripIcon from '../../../../../../static/icons/grip-vertical.svg';
import TrashIcon from '../../../../../../static/icons/trash-alt.svg';
import PenIcon from '../../../../../../static/icons/pencil-alt.svg';
import CheckIcon from '../../../../../../static/icons/check.svg';
import CancelAltIcon from '../../../../../../static/icons/cancel-alt.svg';
import Button from '../../../../Button';
import { Checkbox, Input } from '../../../../FormControls';
import preventClick from 'shared/helpers/preventClick';

interface ItemProps {
  index: number;
  section: any;
  updateSection: (data: any) => void;
  deleteSection: (_: any, { id, uuid }: { id: number; uuid: string }) => void;
}

const SectionItem: React.FC<ItemProps> = ({ index, section, updateSection, deleteSection }) => {
  const [isTitleEdit, setEdit] = useState(false);
  const [draggable, setDraggable] = useState(section.is_publish);
  const [title, setTitle] = useState(section.title || 'Заголовок');
  const handleUpdateSection = () => {
    updateSection({
      ...section,
      title,
    });
    setEdit(false);
  };

  const handleChangePublish = (event) => {
    updateSection({
      ...section,
      is_publish: event.target.checked,
    });

    setDraggable(event.target.checked);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  return (
    <Draggable draggableId={section.uuid} index={index} isDragDisabled={!draggable}>
      {(provided) => (
        <Styled.SectionsItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Checkbox variant="round" checked={section.is_publish} onChange={handleChangePublish}>
            {section.block_name}
            {!isTitleEdit && (
              <Styled.SectionTitle
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEdit(true);
                }}
              >
                {title}
                <PenIcon />
              </Styled.SectionTitle>
            )}
          </Checkbox>

          <Styled.DeleteSection>
            <Button
              type="button"
              onlyIcon
              dangerTransparent
              onClick={deleteSection}
              data={{
                id: section.id,
                uuid: section.uuid,
              }}
            >
              <TrashIcon opacity={isTitleEdit ? 0.4 : undefined} />
            </Button>
          </Styled.DeleteSection>
          {section.is_publish && (
            <Styled.Grab>
              <GripIcon opacity={isTitleEdit ? 0.4 : undefined} />
            </Styled.Grab>
          )}
          {isTitleEdit && (
            <Styled.TitleEditContainer onClick={preventClick}>
              <Input label="Заголовок" value={title} onChange={handleChangeTitle} />
              <Styled.TitleEditButtons>
                <Button type="button" onlyIcon dangerTransparent onClick={() => setEdit(false)}>
                  <CancelAltIcon />
                </Button>
                <Button onlyIcon type="button" transparent onClick={handleUpdateSection}>
                  <CheckIcon />
                </Button>
              </Styled.TitleEditButtons>
            </Styled.TitleEditContainer>
          )}
        </Styled.SectionsItem>
      )}
    </Draggable>
  );
};

export default SectionItem;
