import Button from 'components/Button';
import { PlainTable } from 'components/Table';
import permissions from 'config/permissions';
import Can from 'containers/Can';
import { i18n } from 'next-i18next';
import React from 'react';
import { PlaceListItem as Place } from 'shared/api/reference/places';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { findTranslation } from 'shared/helpers/translations';
import { useToggler } from 'shared/lib/toggler';
import EyeIcon from '../../../../../../static/icons/eye.svg';
import PencilAltIcon from '../../../../../../static/icons/pencil-alt.svg';
import { placeUpdateModal } from '../update-modal';
import { placeViewModal } from '../view-modal';
import Styled from './styles';

interface ItemProps {
  data: Place;
}

export const PlaceListItem: React.FC<ItemProps> = ({ data }) => {
  const { open: openViewModal } = useToggler(placeViewModal.toggler);
  const { open: openUpdateModal } = useToggler(placeUpdateModal.toggler);
  const locationInfo = findTranslation(data?.location?.info, i18n?.language || LANGUAGES.RU);
  const handleViewModal = () => openViewModal(data.id);
  const handleUpdateModal = () => openUpdateModal(data.id);

  return (
    <PlainTable.Tr mobileFlexRow>
      <PlainTable.Td
        verticalAlign="middle"
        mobileWidth="calc(100% - 80px)"
        tabletAutoHeight
        mobileOrder={1}
        mobileBold
      >
        {data.title}
      </PlainTable.Td>
      <PlainTable.Td
        verticalAlign="middle"
        mobileWidth="100%"
        minWidth="150px"
        mobilePadding="0 24px 5px"
        tabletAutoHeight
        mobileOrder={3}
      >
        {locationInfo?.title}
      </PlainTable.Td>
      <PlainTable.Td
        width="100px"
        verticalAlign="middle"
        mobileWidth="80px"
        mobildeAlignItems="center"
        mobileDisplay="flex"
        tabletAutoHeight
        mobileOrder={2}
        mobilePadding="0 24px 0 0"
      >
        <Styled.ButtonWrapper>
          <Button transparent onlyIcon onClick={handleViewModal} data-selenium={data?.title.concat('-view')}>
            <EyeIcon />
          </Button>
          <Can permission={permissions.reference}>
            <Button transparent onlyIcon onClick={handleUpdateModal} data-selenium={data?.title}>
              <PencilAltIcon />
            </Button>
          </Can>
        </Styled.ButtonWrapper>
      </PlainTable.Td>
    </PlainTable.Tr>
  );
};
