import PreorderModal from 'components/Modals/PreorderModal';
import { connect } from 'react-redux';
import { saleTickets, loadSale } from 'entities/order';
import { loadPreorders, changePreorderExtra, finishPreorder, loadPreorder } from 'entities/preorders';

export default connect(null, {
  loadSale,
  saleTickets,
  loadPreorder,
  loadPreorders,
  finishPreorder,
  changePreorderExtra,
})(PreorderModal);
