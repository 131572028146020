import Button from 'components/Button';
import Modal from 'components/Modal';
import { PlainTable } from 'components/Table';
import flow from 'lodash/flow';
import { withRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { currencySources } from 'entities/currency';
import { openModal } from 'entities/modal';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import MODALS from 'shared/constants/MODALS';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import useListItem from 'shared/lib/useListItem';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Styled from 'shared/ui/reference/modal-styles';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { withTranslation } from '../../../../i18n';

interface ModalProps {
  openModal: ModalFunctions['openModal'];
  modalData: {
    id: string;
    updateData: (data: any) => any;
  };
  t: (value: string, params?: any) => string;
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

const CurrencyHistoryModal: React.FC<ModalProps> = (props) => {
  const { get } = useListItem({ source: currencySources });
  const [state, setState] = useState<any>({
    data: null,
    t: props.t,
  });

  const loadData = (lng?: string) => {
    const {
      modalData: { id },
    } = props;

    if (id) {
      get(id, lng)
        .then((data) => {
          setState({
            data: {
              id,
              ...data,
            },
          });
          return data;
        })
        .catch((err) => new Error(err));

      props.openModal(
        MODALS.REFERENCE_CURRENCY_HISTORY,
        {
          ...props.modalData,
          modalData: {
            mobilePadding: '0',
            fullSize: window.innerWidth < BREAKPOINTS.MD_NUMBER,
          },
        },
        MODAL_STATES.EDIT,
      );
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const { forceCloseModal, t } = props;
  const { data } = state;
  if (!data) return null;

  return (
    <Styled.Content>
      <Modal.Title>{data.name}</Modal.Title>
      <PlainTable.Table>
        <PlainTable.Thead>
          <PlainTable.Tr>
            <PlainTable.Th>{t('references:currency:date')}</PlainTable.Th>
            <PlainTable.Th>{t('references:currency:value')}</PlainTable.Th>
          </PlainTable.Tr>
        </PlainTable.Thead>
        <PlainTable.Tbody>
          {data.history &&
            data.history.map((item: any) => (
              <PlainTable.Tr>
                <PlainTable.Td verticalAlign="middle">{formatFullDateAndTime(item.updated)}</PlainTable.Td>
                <PlainTable.Td verticalAlign="middle">{item.rate}</PlainTable.Td>
              </PlainTable.Tr>
            ))}
        </PlainTable.Tbody>
      </PlainTable.Table>
      <Styled.FooterContainer>
        <Modal.Footer>
          <Button onClick={forceCloseModal}>{t('forms:close')}</Button>
        </Modal.Footer>
      </Styled.FooterContainer>
    </Styled.Content>
  );
};

const mapStateToProps = (state: any) => ({
  modalState: state.modal.state,
  modalData: state.modal.data,
});

const mapDispatchToProps = {
  openModal,
};

const withHoC = flow([
  withRouter,
  withCheckIsMobile,
  withTranslation([LOCALES.FORMS, LOCALES.REFERENCES]),
  connect(mapStateToProps, mapDispatchToProps),
]);

export default withHoC(CurrencyHistoryModal);
