import Button from 'components/Button';
import Form from 'components/Form';
import { Input, NumberInput } from 'components/FormControls';
import Modal from 'components/Modal';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import {
  createEmailConfig,
  editEmailConfig,
  loadCompanyEmailsConfig,
} from 'entities/company/emails-config';
import { required, composeValidators, validEmail } from 'shared/helpers/validations';
import { queryFilter } from 'shared/lib/queryFilter';
import { ModalFunctions } from '../../../../../../../interfaces/modal';
import Styled from './styled';

interface ModalProps {
  data: object;
  closeModal: ModalFunctions['closeModal'];
  editEmailConfig: (id: number, data: object) => void;
  createEmailConfig: (data: object) => void;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadCompanyEmailsConfig: () => void;
}

const EmailConfigModal: React.FC<ModalProps> = (props) => {
  const isEdit = !isEmpty(props.data);

  const onSubmit = (data:{id:number}) => {
    const responseCallback = (response: {error:string}, withoutListLoad?: boolean) => {
      if (response.error) {
        NotificationManager.error('Ошибка');

        return response;
      }

      if (!withoutListLoad) {
        props.loadCompanyEmailsConfig();
        queryFilter({ search: '' });
      }

      return response;
    };

    if (isEdit) {
      return props.editEmailConfig(data.id, data).then((response: object) => responseCallback(response, true));
    }

    return props.createEmailConfig(data).then((response: object) => responseCallback(response));
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title>{`${isEdit ? 'Редактировать' : 'Добавить'}`} настройку почты</Modal.Title>
      <Form
        onSubmit={onSubmit}
        onSuccess={props.forceCloseModal}
        initialValues={props.data}
        render={({ handleSubmit }:{handleSubmit:()=>void}) => (
          <form onSubmit={handleSubmit}>
            <Styled.Fields>
              <Field name="login" validate={composeValidators(required, validEmail)}>
                {({ input, meta }) => <Input label="Логин" {...input} meta={meta} />}
              </Field>
              <Field name="host" validate={required}>
                {({ input, meta }) => <Input label="Адрес почтового сервера" {...input} meta={meta} />}
              </Field>
              <Field name="port" validate={required}>
                {({ input, meta }) => <NumberInput label="Порт почтового сервера" {...input} meta={meta} />}
              </Field>
              <Field name="password" validate={required}>
                {({ input, meta }) => <Input label="Пароль" type="password" {...input} meta={meta} />}
              </Field>
            </Styled.Fields>
            <Modal.Footer>
              <Button type="button" transparent onClick={props.closeModal}>
                Отмена
              </Button>
              <Button type="submit">{isEdit ? 'Сохранить' : 'Добавить'}</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

const mapDispatchToProps = {
  editEmailConfig,
  createEmailConfig,
  loadCompanyEmailsConfig,
};

export const CompanyEmailConfigModal = connect(null, mapDispatchToProps)(EmailConfigModal);
