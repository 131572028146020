import DataPreview from 'components/DataPreview';
import { LangForm } from 'components/Form';
import { Input, FormRow, FileInput, NumberInput } from 'components/FormControls';
import Modal from 'components/Modal';
import React from 'react';
import { Field } from 'react-final-form';
import IMAGE_TYPES from 'shared/constants/IMAGE_TYPES';
import {
  composeValidators,
  required,
  minValue,
  maxValue,
  mustBeNumber,
  minNumberValue,
} from 'shared/helpers/validations';
import Styled from 'shared/ui/reference/modal-styles';

interface FormProps {
  isMobile?: boolean;
  title: string;
  // eslint-disable-next-line no-undef
  children: JSX.Element | JSX.Element[];
  state: any;
  onChangeLng?: (lng: string) => any;
  t: (value: string, params?: any) => string;
  onSubmit?: (data: any) => any;
  onSuccess?: (data: any) => any;
  stashLanguages?: boolean;
  initialValues?: any;
  isEdit?: any;
}

const CurrencyForm: React.FC<FormProps> = ({
  children,
  title,
  isMobile,
  state,
  onChangeLng,
  t,
  isEdit = false,
  ...rest
}) => (
  <DataPreview state={state}>
    <LangForm
      {...rest}
      onChangeLng={onChangeLng}
      render={({ handleSubmit, i18n }) => (
        <form onSubmit={handleSubmit}>
          {isMobile ? (
            <Styled.Header>
              <Styled.Title>{t(title)}</Styled.Title>
            </Styled.Header>
          ) : (
            <Modal.Title>{t(title)}</Modal.Title>
          )}

          <Styled.Content>
            <Field name={`${i18n.language}.id`} component="input" type="hidden" />
            <FormRow>
              <Field
                name={`${i18n.language}.name`}
                validate={composeValidators(required, minValue(3), maxValue(50))}
              >
                {({ input, meta }) => (
                  <Input label={t('references:currency.name')} autoFocus {...input} meta={meta} />
                )}
              </Field>
            </FormRow>

            <FormRow>
              <Field
                name={`${i18n.language}.char_code`}
                validate={composeValidators(required, minValue(3), maxValue(3))}
              >
                {({ input, meta }) => (
                  <Input
                    label={t('references:currency.char_code')}
                    {...input}
                    meta={meta}
                    disabled={isEdit}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name={`${i18n.language}.rate`}
                validate={composeValidators(required, mustBeNumber, minNumberValue(0))}
              >
                {({ input, meta }) => (
                  <NumberInput
                    label={t('references:currency.rate')}
                    placeholder="₽"
                    float
                    {...input}
                    meta={meta}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field name={`${i18n.language}.icon`} validate={required}>
                {({ input, meta }) => (
                  <FileInput
                    placeholder="+"
                    label={t('references:currency.icon')}
                    withPreview
                    imageTypes={[IMAGE_TYPES.PNG, IMAGE_TYPES.JPEG, IMAGE_TYPES.SVG]}
                    {...input}
                    meta={meta}
                    maxSize={1}
                  />
                )}
              </Field>
            </FormRow>

            <Styled.FooterContainer>
              <Modal.Footer>{children}</Modal.Footer>
            </Styled.FooterContainer>
          </Styled.Content>
        </form>
      )}
    />
  </DataPreview>
);

export default CurrencyForm;
