import Button from 'components/Button';
import Form from 'components/Form';
import { ColorPicker, NumberInput, InputWithSelect, Textarea } from 'components/FormControls';
import Spinner from 'components/Spinner';
import { useUnit } from 'effector-react';
import arrayMutators from 'final-form-arrays';
import React, { useEffect } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Editor } from 'entities/editor';
import { IConstructorLandingData } from 'shared/api/landings/constructor';
import { ITriggerFormValue } from 'shared/api/landings/constructor/notifications-step/types';
import { TRIGGER_TYPES, TIMER_VALUES, REPEAT_VALUES, TRIGGERS } from 'shared/constants/LANDINGs_TRIGGERS';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { landingDescriptionValidation, required } from 'shared/helpers/validations';
import ConstructorFooter from '../../ConstructorFooter';
import Styled from '../../styles';
import { generateUniqueId } from '../lib/submit-triggers';
import { notificationsStepModel } from '../model';

interface StepProps {
  updateData: () => void;
  goBack: () => void;
  onSubmit: (data: IConstructorLandingData) => void;
  data: IConstructorLandingData;
  lng: string;
}

export const NotificationsStep: React.FC<StepProps> = ({ updateData, data, onSubmit, goBack, lng }) => {
  const { stepMounted, handleSubmit, deleteSubmitted } = useUnit({
    stepMounted: notificationsStepModel.stepMounted,
    handleSubmit: notificationsStepModel.formSubmitted,
    deleteSubmitted: notificationsStepModel.deleteSubmitted,
  });

  const { triggersData, loading } = useUnit({
    loading: notificationsStepModel.$loading,
    triggersData: notificationsStepModel.$triggersData,
  });

  const { results: triggers } = triggersData;
  const initialValues = {
    color: triggers.length ? triggers[0].color : null,
    rounding_corners: triggers.length ? triggers[0].rounding_corners : 0,
    triggers: triggers.map((item) => ({
      ...item,
      trigger_type: TRIGGER_TYPES.find((type) => type.value === item.trigger_type.code),
      value: TIMER_VALUES.find((type) => type.value === item.value),
      repeat: REPEAT_VALUES.find((type) => type.value === item.repeat),
    })),
  };

  useEffect(() => {
    stepMounted({ landingData: data, lng });
  }, []);

  const handleSubmitData = async (submitData: ITriggerFormValue) => {
    await handleSubmit(submitData);

    if (submitData.submit_action === SUBMIT_ACTIONS.CREATE) {
      await onSubmit({ ...data, submit_action: SUBMIT_ACTIONS.CREATE });
    } else {
      onSubmit({ ...data, submit_action: SUBMIT_ACTIONS.CONTINUE });
    }

    return stepMounted({ landingData: data, lng });
  };

  return loading ? (
    <Spinner center />
  ) : (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка оповещений</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          withoutNotification
          onSubmit={handleSubmitData}
          initialValues={{
            ...initialValues,
            ...data,
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            form,
            form: {
              mutators: { push },
            },
            values,
          }: FormRenderProps) => {
            const handlePush = () => {
              const newId = generateUniqueId();
              push('triggers', { id: newId });
            };

            return (
              <form onSubmit={handleSubmit}>
                <Styled.FormRow withBorder>
                  <Styled.FormDescription>
                    Стиль оповещений
                    <Styled.FormSmallDescription>Цвет обводки, углы</Styled.FormSmallDescription>
                  </Styled.FormDescription>
                  <Styled.FormControls>
                    <Styled.FormControl grid>
                      <Styled.Column>
                        <Field name="color" validate={required}>
                          {({ input, meta }) => <ColorPicker label="Цвет" input={input} meta={meta} />}
                        </Field>
                      </Styled.Column>
                      <Styled.Column>
                        <Styled.SmallInput>
                          <Field name="rounding_corners" validate={required}>
                            {({ input, meta }) => (
                              <NumberInput label="Скругление углов" max={16} {...input} meta={meta} />
                            )}
                          </Field>
                        </Styled.SmallInput>
                      </Styled.Column>
                    </Styled.FormControl>
                  </Styled.FormControls>
                </Styled.FormRow>
                <FieldArray name="triggers">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const handleDeleteItem = async () => {
                        if (values.triggers[index]?.id) {
                          await deleteSubmitted(values.triggers[index].id);
                        }
                        fields.remove(index);
                      };
                      return (
                        <Styled.NotificationContainer>
                          {values.triggers[index] && (
                            <Styled.Notification rounding={values.rounding_corners} color={values.color}>
                              <Styled.NotificationClose type="button">&times;</Styled.NotificationClose>

                              {values.triggers[index].text && (
                                <div dangerouslySetInnerHTML={{ __html: values.triggers[index].text }} />
                              )}
                            </Styled.Notification>
                          )}
                          <Field type="hidden" name="id" component="input" />
                          <Styled.FormRow>
                            <Styled.FormDescription>Текст оповещения</Styled.FormDescription>
                            <Styled.FormControls>
                              <Styled.FormControl>
                                <Field name={`${name}.text`} validate={landingDescriptionValidation}>
                                  {({ input, meta }) => (
                                    <Editor
                                      id={`notification_${values.triggers[index]?.id || ''}`}
                                      placeholder="Текст..."
                                      input={input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                              </Styled.FormControl>
                            </Styled.FormControls>
                          </Styled.FormRow>
                          <Styled.FormRow>
                            <Styled.FormDescription>
                              Страница появления
                              <Styled.FormSmallDescription>Ссылка на страницу</Styled.FormSmallDescription>
                            </Styled.FormDescription>
                            <Styled.FormControls>
                              <Styled.FormControl>
                                <Field name={`${name}.link`} validate={required}>
                                  {({ input, meta }) => (
                                    <Textarea label="Ссылки типа /cart" {...input} meta={meta} />
                                  )}
                                </Field>
                              </Styled.FormControl>
                            </Styled.FormControls>
                          </Styled.FormRow>
                          <Styled.FormRow withBorder>
                            <Styled.FormDescription>
                              Триггер появления
                              <Styled.FormSmallDescription>
                                Действие, при котором появляется оповещение
                              </Styled.FormSmallDescription>
                            </Styled.FormDescription>
                            <Styled.FormControls>
                              <Styled.FormControl>
                                <Field name={`${name}.trigger_type`} validate={required}>
                                  {({ input, meta }) => (
                                    <InputWithSelect
                                      isClearable={false}
                                      label="Выберите вариант"
                                      options={TRIGGER_TYPES}
                                      meta={meta}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </Styled.FormControl>
                              {values?.triggers[index]?.trigger_type?.value === TRIGGERS.TIMER && (
                                <Styled.FormControl grid withOffset>
                                  <Styled.Column>
                                    <Field name={`${name}.value`} validate={required}>
                                      {({ input, meta }) => (
                                        <InputWithSelect
                                          isClearable={false}
                                          label="Выберите вариант"
                                          options={TIMER_VALUES}
                                          meta={meta}
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                  </Styled.Column>
                                  <Styled.Column>
                                    <Field name={`${name}.repeat`} validate={required}>
                                      {({ input, meta }) => (
                                        <InputWithSelect
                                          isClearable={false}
                                          label="Выберите вариант"
                                          options={REPEAT_VALUES}
                                          meta={meta}
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                  </Styled.Column>
                                </Styled.FormControl>
                              )}
                              <Styled.FormControl>
                                <Styled.AddNotification>
                                  <Button dangerTransparent onClick={handleDeleteItem} type="button">
                                    Удалить оповещение
                                  </Button>
                                </Styled.AddNotification>
                              </Styled.FormControl>
                            </Styled.FormControls>
                          </Styled.FormRow>
                        </Styled.NotificationContainer>
                      );
                    })
                  }
                </FieldArray>
                <Styled.AddNotification>
                  <Button transparentBlue onClick={handlePush} type="button">
                    Настроить еще оповещение
                  </Button>
                </Styled.AddNotification>

                <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
              </form>
            );
          }}
        />
      </Styled.Content>
    </Styled.Container>
  );
};
