import { invoke } from '@withease/factories';
import { createStore, sample } from 'effector';
import { createPagesMountStates } from 'shared/factory';
import { fetchHallsFx, PaginatedHalls } from 'shared/api/reference/halls';

export const { pageMounted, $query, $loading } = invoke(createPagesMountStates);

export const $data = createStore<PaginatedHalls>({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});

// load
sample({
  source: $query,
  target: fetchHallsFx,
});

sample({
  source: $query,
  fn: () => true,
  target: $loading,
});

sample({
  clock: fetchHallsFx.done,
  fn: ({ result }) => result,
  target: $data,
});

sample({
  clock: fetchHallsFx.done,
  fn: () => false,
  target: $loading,
});
