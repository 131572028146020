import { widgetSources } from 'entities/widget';
import { actionTypes } from './actionTypes';

const loadWidgetPatterns = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: widgetSources.pattern,
      params,
    },
  },
});

const loadMoreWidgetPatterns = () => ({
  type: actionTypes.LOAD_MORE,
});

const createWidgetPattern = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: widgetSources.pattern,
      data,
    },
  },
});

const editWidgetPattern = (data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: widgetSources.patternDetail(data.id),
      data,
    },
  },
});

const deleteWidgetPattern = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: widgetSources.patternDetail(id),
    },
  },
});

export {
  loadWidgetPatterns,
  loadMoreWidgetPatterns,
  createWidgetPattern,
  deleteWidgetPattern,
  editWidgetPattern,
};
