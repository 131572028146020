import { connect } from 'react-redux';
import { editUserCustomer, loadSale } from 'entities/order';
import EditUserCustomerModal from 'components/Modals/EditUserCustomerModal';

const mapDispatchToProps = {
  loadSale,
  editUserCustomer,
};

export default connect(null, mapDispatchToProps)(EditUserCustomerModal);
