import { isEmpty } from 'lodash';
import config from 'config/config';
import { ICloningLandingEventAttachments } from 'pages/landings/types';
import { IEventAttachments } from '../api/market/events';
import COOKIES from '../constants/COOKIES';
import { getCookie } from '../lib/cookie';
import api from '../services/api';

const companyId = getCookie(COOKIES.COMPANY);

const routeInfo = `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/landings/events`;

const attachmentsSource = {
  async getAttachmentsTypes(entityType: string) {
    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/attachment_types`, {
      params: {
        entity_type: entityType,
      },
    });

    return response.data.results.reduce((acc, item) => {
      acc[item.code] = item;

      return acc;
    }, {});
  },

  async createEventsAttachments({ attachments, id }: { id: number; attachments: IEventAttachments[] }) {
    for (const attachment of attachments) {
      await api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${id}/attachments`, {
        data: attachment.data,
        attachment_type_id: attachment.attachment_type_id,
      });
      if (!isEmpty(attachment?.info)) {
        await api.post(`${routeInfo}/${id}/attachments/${attachment.attachment_type_id}/info`, {
          ...attachment.info,
          language_code: attachment.language_code,
        });
      }
    }
  },

  async cloningEventsAttachments({
    attachments,
    id,
  }: {
    id: number;
    attachments: ICloningLandingEventAttachments[];
  }) {
    for (const attachment of attachments) {
      await api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${id}/attachments`, {
        data: attachment.data,
        attachment_type_id: attachment.attachment_type_id,
      });
      if (!isEmpty(attachment?.info)) {
        for (const info of attachment.info) {
          await api.post(`${routeInfo}/${id}/attachments/${attachment.attachment_type_id}/info`, {
            alt: info.alt,
            title: info.title,
            language_code: info.language_code,
          });
        }
      }
    }
  },

  async deleteEventsAttachments({ attachments, id }: { id: number; attachments: { id: number }[] }) {
    const requests = attachments.map(async (attachment) => {
      const response = api.delete(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${id}/attachments/${attachment?.id}`,
      );

      return response;
    });

    await Promise.all(requests);
  },

  async editEventsAttachments({ attachments, id }: { id: number; attachments: IEventAttachments[] }) {
    for (const attachment of attachments) {
      await api.patch(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${id}/attachments/${attachment.attachment_type_id}`,
        {
          data: attachment.data,
        },
      );

      if (!isEmpty(attachment.info)) {
        if (attachment.info?.is_create) {
          await api.post(`${routeInfo}/${id}/attachments/${attachment.attachment_type_id}/info`, {
            ...attachment.info,
            language_code: attachment.language_code,
          });
        } else {
          await api.patch(
            `${routeInfo}/${id}/attachments/${attachment.attachment_type_id}/info/${attachment.language_code}`,
            attachment.info,
          );
        }
      }
    }
  },

  async createPlacesAttachments(event_id, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${event_id}/attachments`, {
        data: attachment?.data,
        attachment_type_id: attachment?.attachment_type_id,
        alt: attachment?.alt,
        title: attachment?.title,
      }),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  deletePlacesAttachments(event_id: number, attachment_id: number) {
    const response = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${event_id}/attachments/${attachment_id}`,
    );

    return response;
  },

  async editPlacesAttachments(event_id: number, attachment_id: number, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.patch(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${event_id}/attachments/${attachment_id}`,
        {
          data: attachment.data,
          alt: attachment.alt,
          title: attachment.title,
        },
      ),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  async createPersonsAttachments({ event_id, attachmentsData }) {
    const promises = attachmentsData.map((attachment) =>
      api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${event_id}/attachments`, {
        data: attachment?.data,
        attachment_type_id: attachment.attachment_type_id,
        alt: attachment?.alt,
        title: attachment?.title,
      }),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  deletePersonsAttachments(event_id: number, attachment_id: number) {
    const response = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${event_id}/attachments/${attachment_id}`,
    );

    return response;
  },

  async editPersonsAttachments(event_id: number, attachment_id: number, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.patch(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${event_id}/attachments/${attachment_id}`,
        {
          data: attachment.data,
          alt: attachment.alt,
          title: attachment.title,
        },
      ),
    );

    const responses = await Promise.all(promises);
    return responses;
  },
};

export default attachmentsSource;
