import React, { useState, useEffect } from 'react';
import uuid from 'uuid/v4';
import { WIDGET_FILTERS } from 'shared/constants/WIDGET';
import { DataContextProvider } from '../SecondStep/DataContext';
import Styled from '../WidgetForm/styles';
import Button from '../../../Button';
import Box from '../../../Box';
import QueryGroup from '../ThirdStepV2/QueryGroup';

const fields = [
  {
    name: WIDGET_FILTERS.PRICE,
    label: 'Цена за позицию',
  },
  { name: WIDGET_FILTERS.ALL_TICKETS, label: 'Все билеты' },
  {
    name: WIDGET_FILTERS.PARSERS,
    label: 'Парсинг',
  },
  { name: WIDGET_FILTERS.PROVIDER, label: 'Поставщик' },
  { name: WIDGET_FILTERS.COMPANY, label: 'Брокер' },
  { name: WIDGET_FILTERS.ZRITELI, label: 'Зрители' },
];

interface MarkupProps {
  onChange: (data: any) => any;
  isPreview?: boolean;
  data: {
    event: any;
    length: number;
  };
}

const WidgetPatternMarkup: React.FC<MarkupProps> = ({ data, onChange, isPreview }) => {
  const [markup, updateMarkup] = useState<any>(data && data.length ? data : []);

  useEffect(() => {
    onChange(markup);
  }, [markup]);

  const addRule = () => {
    updateMarkup((markupItem: any) => markupItem.concat({ id: uuid(), query: {} }));
  };

  const setQuery = (id, query) => {
    updateMarkup((prevMarkup: any) =>
      prevMarkup.map((markupItem) =>
        markupItem.id === id
          ? {
              ...markupItem,
              query,
            }
          : markupItem,
      ),
    );
  };

  const deleteQuery = (_, id) => {
    updateMarkup((prevMarkup: any) => prevMarkup.filter((markupItem) => markupItem.id !== id));
  };

  return (
    <Styled.Wrapper>
      <DataContextProvider pricesRules={data}>
        {markup.map((markupItem) => (
          <QueryGroup
            key={markupItem.id}
            id={markupItem.id}
            query={markupItem.query}
            onChange={setQuery}
            deleteQuery={deleteQuery}
            fields={fields}
            isPreview={isPreview}
          />
        ))}
        {!isPreview && (
          <Box mt="32px">
            <Button type="button" isLink onClick={addRule}>
              Добавить правило
            </Button>
          </Box>
        )}
      </DataContextProvider>
    </Styled.Wrapper>
  );
};

export default WidgetPatternMarkup;
