import DataPreview from 'components/DataPreview';
import { LangForm } from 'components/Form';
import { Input, FormRow, InputWithSelect, Checkbox, NumberInput } from 'components/FormControls';
import Modal from 'components/Modal';
import React from 'react';
import { Field } from 'react-final-form';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderSubdomains } from 'shared/helpers/optionsRenderers';
import { composeValidators, required, minValue, maxValue, maxNumberValue } from 'shared/helpers/validations';
import subdomains from 'shared/sources/subdomains';
import Styled from 'shared/ui/reference/modal-styles';

interface FormProps {
  isMobile?: boolean;
  title: string;
  state: any;
  onChangeLng?: (lng: string) => any;
  t: (value: string, params?: any) => string;
  onSubmit?: (data: any) => any;
  onSuccess?: (data: any) => any;
  stashLanguages?: boolean;
  initialValues?: any;
  children?: any;
}

const ActualForm: React.FC<FormProps> = ({ children, title, isMobile, state, onChangeLng, t, ...rest }) => (
  <DataPreview state={state}>
    <LangForm
      {...rest}
      onChangeLng={onChangeLng}
      render={({ handleSubmit, i18n, switcher }) => {
        const disabledByLanguage = i18n.language !== LANGUAGES.RU;

        return (
          <form onSubmit={handleSubmit}>
            {isMobile ? (
              <Styled.Header>
                <Styled.Title>{t(title)}</Styled.Title>
              </Styled.Header>
            ) : (
              <Modal.Title>{t(title)}</Modal.Title>
            )}
            <FormRow>{switcher}</FormRow>
            <Styled.Content>
              <Field name={`${i18n.language}.id`} component="input" type="hidden" />
              <FormRow>
                <Field
                  name={`${i18n.language}.label`}
                  validate={composeValidators(required, minValue(2), maxValue(50))}
                >
                  {({ input, meta }) => <Input label={t('references:actual.name')} {...input} meta={meta} />}
                </Field>
              </FormRow>

              {!disabledByLanguage && (
                <>
                  <FormRow>
                    <Field name={`${i18n.language}.url`} validate={required}>
                      {({ input, meta }) => (
                        <Input label={t('references:actual.link')} {...input} meta={meta} />
                      )}
                    </Field>
                  </FormRow>
                  <FormRow>
                    <Field name={`${i18n.language}.weight`} validate={maxNumberValue(10000)}>
                      {({ input, meta }) => (
                        <NumberInput label={t('references:actual.weight')} {...input} meta={meta} min={0} />
                      )}
                    </Field>
                  </FormRow>
                  <FormRow>
                    <Field name={`${i18n.language}.domains`}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          isMulti
                          label={t('references:actual.domains')}
                          route={subdomains}
                          optionsRenderer={renderSubdomains}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </FormRow>
                  <FormRow>
                    <Field name={`${i18n.language}.is_shown_on_main`} type="checkbox">
                      {({ input, meta }) => (
                        <Checkbox variant="round" meta={meta} {...input}>
                          {t('references:actual.is_shown_on_main')}
                        </Checkbox>
                      )}
                    </Field>
                  </FormRow>
                </>
              )}

              <Styled.FooterContainer>
                <Modal.Footer>{children}</Modal.Footer>
              </Styled.FooterContainer>
            </Styled.Content>
          </form>
        );
      }}
    />
  </DataPreview>
);

export default ActualForm;
