import styled, { createGlobalStyle } from 'styled-components';
import { mediumModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${mediumModalStyles}
  `,

  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,

  AddPosition: styled.div`
    margin-top: 16px;
    align-self: center;

    & button {
      height: 24px;
    }
  `,

  Inner: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
  `,

  ProgressModalContainer: styled.div`
    position: fixed;
    z-index: 4;
    top: -32px;
    left: -24px;
    right: -24px;
    bottom: -32px;
    background-color: rgba(0, 0, 0, 0.9);
    box-sizing: border-box;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    & > div {
      width: 100%;
      max-width: 400px;
    }
  `,

  Spinner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
