import styled from 'styled-components';

const Label = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
  color: #000;
`;

export default Label;
