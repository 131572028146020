import { saturate } from 'polished';
import styled, { css } from 'styled-components';
import COLORS from 'shared/constants/COLORS';

interface InfoProps {
  rtl?: boolean;
  fullWidth?: boolean;
}

interface ContainerProps {
  rtl?: boolean;
  selectAll?: boolean;
  disabled?: boolean;
  type?: 'default' | 'select' | 'gray' | 'round';
}

interface CheckmarkProps {
  rtl?: boolean;
  plain?: boolean;
  isMulti?: boolean;
  type?: 'gray' | 'round';
}

interface RoundCheckmarkProps {
  rtl?: boolean;
  plain?: boolean;
  disabled?: boolean;
}

interface SelectProps {
  disabled?: boolean;
}

export default {
  Wrapper: styled.div<{ fullWidth: boolean }>`
    display: flex;
    flex-direction: column;
    user-select: none;
    width: ${(fullWidth) => (fullWidth ? '100%' : 'unset')};
  `,

  Info: styled.div<InfoProps>`
    margin-top: -2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: ${({ rtl }) => (rtl ? '-1' : null)};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};
  `,

  SubText: styled.span`
    margin-top: 4px;
    color: #5c5c5c;
    font-size: 14px;
  `,

  Container: styled.label<ContainerProps>`
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: ${({ rtl }) => (rtl ? 'space-between' : null)};

    & > input {
      display: ${({ selectAll }) => selectAll && 'none'};
      opacity: 0;
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    & > input:checked + span {
      background-color: ${COLORS.GREEN};
      border-color: ${(props) => (props.type === 'gray' ? COLORS.GREEN : null)};

      &:after {
        left: ${(props) => (props.type === 'round' ? '13px' : null)};
      }
    }

    & > input:not(:checked):focus + span {
      background-color: ${saturate(0.1, '#999')};
      background-image: none;
    }

    & > input:checked:focus + span {
      background-color: ${saturate(0.2, COLORS.GREEN)};
    }

    /* disabled */
    cursor: ${({ disabled }) => disabled && 'not-allowed'};
  `,

  Checkmark: styled.span<CheckmarkProps>`
    display: block;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    margin-right: ${(props) => (props.plain ? null : '8px')};
    margin-left: ${({ rtl }) => rtl && '8px'};
    margin-right: ${({ rtl }) => rtl && '0'};
    box-sizing: border-box;
    border-radius: 2px;
    border: 2px solid ${COLORS.GREEN};
    border-color: ${(props) => (props.type === 'gray' ? '#e1e1e1' : null)};
    background-image: url('/static/Checkbox/check.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px 10px;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease-in-out;

    ${({ isMulti }) =>
      isMulti &&
      css`
        background-color: ${COLORS.GREEN};
        border-color: ${COLORS.GREEN};
        background-image: url('/static/Checkbox/multi.svg');
      `}
  `,

  RoundCheckmark: styled.span<RoundCheckmarkProps>`
    position: relative;
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: ${(props) => (props.plain ? null : '8px')};
    margin-left: ${({ rtl }) => rtl && '8px'};
    margin-right: ${({ rtl }) => rtl && '0'};
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    background-color: #999;
    transition: all 0.2s ease-in-out;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 3px;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
    }

    /* disabled */
    opacity: ${({ disabled }) => disabled && 0.4};
    pointer-events: ${({ disabled }) => disabled && 'none'};
  `,
  Select: styled.label<SelectProps>`
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    padding: 7px 30px 6px 8px;

    & > svg {
      position: absolute;
      right: 9px;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      opacity: 0.5;
    }

    & > input {
      display: none;
    }

    &:hover {
      border-color: #000;
    }

    /* disabled */
    opacity: ${({ disabled }) => disabled && 0.4};
    pointer-events: ${({ disabled }) => disabled && 'none'};
  `,
};
