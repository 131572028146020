import React from 'react';
import { Field } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import { validPhone, validEmail, maxValue } from 'shared/helpers/validations';
import { LangForm } from '../../Form';
import Button from '../../Button';
import { Input, PhoneInput } from '../../FormControls';
import Styled from './styles';
import EmailIcon from '../../../../static/icons/email.svg';
import Modal from '../../Modal/Modal';
import useListItem from 'shared/lib/useListItem';
import clientsSource from 'shared/sources/clients';

interface ModalProps {
  data: any;
  closeModal: () => void;
  forceCloseModal: () => void;
}

const ClientsModal: React.FC<ModalProps> = ({ data, ...rest }) => {
  const isMetaClient = Boolean(data.clients);
  const { create, update, emitListReload, onListItemCreate } = useListItem({
    source: clientsSource,
    detail: isMetaClient && clientsSource.metaDetail,
  });
  const isEdit = !isEmpty(data);

  const handleSubmitForm = (submitData: any) => {
    rest.forceCloseModal();
    if (!isEdit) {
      return create(submitData.ru);
    }
    return update(isMetaClient ? { name: submitData.ru.name, id: submitData.ru.id } : submitData.ru);
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <LangForm
        onSubmit={handleSubmitForm}
        onSuccess={isEdit ? emitListReload : onListItemCreate}
        initialValues={data}
        render={({ i18n, handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title>{isEdit ? 'Редактирование' : 'Добавление'} клиента</Modal.Title>
            <Styled.Fields>
              <Field name={`${i18n.language}.name`} validate={maxValue(100)}>
                {({ input, meta }) => <Input label="Имя" {...input} meta={meta} />}
              </Field>
              {!isMetaClient && (
                <>
                  <Field name={`${i18n.language}.email`} validate={validEmail}>
                    {({ input, meta }) => (
                      <Input label="Почта" icon={<EmailIcon opacity={0.4} />} {...input} meta={meta} />
                    )}
                  </Field>
                  <Field name={`${i18n.language}.phone`} validate={validPhone}>
                    {({ input, meta }) => (
                      <PhoneInput label="Телефон" meta={meta} input={input} handleForm={form} />
                    )}
                  </Field>
                </>
              )}
            </Styled.Fields>
            <Modal.Footer>
              <Button transparent type="button" onClick={rest.closeModal}>
                Отмена
              </Button>
              <Button>Сохранить</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default ClientsModal;
