import { AxiosResponse } from 'axios';
import config from 'config/config';
import { createEffect } from 'effector';
import api from 'services/api';
import COOKIES from '../../../../constants/COOKIES';
import { getCookie } from '../../../../lib/cookie';
import { ITrigger, ITriggerChange, ITriggers, ITriggerUpdate } from './types';

const companyId = getCookie(COOKIES.COMPANY);

export const route = `${config.COMMON_API_URL}/admin/v1/${companyId}/triggers`;

export const fetchTriggersFx = createEffect(async (landing_tech_name: string): Promise<ITriggers> => {
  const response = await api.get(`${route}`, { params: { landing_tech_name } });

  return response.data;
});

export const createTriggerFx = createEffect(async (data: ITriggerChange[]): Promise<ITrigger[]> => {
  const promises = data.map((item) => api.post(`${route}`, { ...item }));

  const responses: AxiosResponse[] = await Promise.all(promises);

  return responses.map((response) => response.data);
});

export const updateTriggerFx = createEffect(async (data: ITriggerUpdate[]): Promise<ITrigger[]> => {
  const promises = data.map((item) => api.patch(`${route}/${item.id}`, { ...item }));

  const responses: AxiosResponse[] = await Promise.all(promises);

  return responses.map((response) => response.data);
});

export const deleteTriggerFx = createEffect(async (id: number) => {
  await api.delete(`${route}/${id}`);
});
