import React from 'react';
import { NotificationManager } from 'react-notifications';
import get from 'lodash/get';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import head from 'lodash/head';
import tables from 'config/tables';
import RejectModalForm from '../../Forms/RejectModalForm';
import { REJECT_REASONS } from 'shared/constants/ORDER';
import { orderSources } from 'entities/order';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  // eslint-disable-next-line camelcase
  bulkReject: ({ ids, reject_reason }: { ids: any; reject_reason: any }) => any;
  setRowsErrors: ({ name, errors }: { name: string; errors: any }) => any;
}

const OrdersCancelModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    bulkReject,
    setRowsErrors,
    data: { ids: selectedRows, modalData, name, page },
  } = props;
  const { errorText } = tables[page];

  const onSubmit = ({ reason, message }) =>
    bulkReject({
      ids: selectedRows,
      reject_reason: reason.value,
      ...(reason.value === REJECT_REASONS.OTHER && { message }),
    }).then((response) => {
      if (response.error) {
        const ids = get(response, 'error.response.data.error');

        setRowsErrors({
          name,
          errors: reduce(
            head(values(ids)),
            (acc, curr) => {
              acc[curr.order] = {
                errors: curr.states,
                page,
              };

              return acc;
            },
            {},
          ),
        });

        return NotificationManager.error(errorText, 'Ошибка');
      }

      closeModal();

      modalData.onModalSubmit();

      return response;
    });

  return <RejectModalForm onSubmit={onSubmit} closeModal={closeModal} rejectReasonsRoute={orderSources} />;
};

export default OrdersCancelModal;
