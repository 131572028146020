import styled, { createGlobalStyle } from 'styled-components';
import { scrollbar } from '../../../app/styles/scrollbar';
import { mediumModalStyles } from 'shared/mixins/modal';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  GlobalStyle: createGlobalStyle`
    ${mediumModalStyles}

    @media ${MEDIA_QUERIES.MOBILE} {
      .modal__body {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
      }
    }
  `,
  Wrapper: styled.div`
    margin: 0 -40px;

    @media ${MEDIA_QUERIES.MOBILE} {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      min-height: 0;
      margin: 0 -16px;
    }
  `,

  Header: styled.div`
    padding: 0 40px 16px;
    border-bottom: 1px solid #e1e1e1;
    flex-shrink: 0;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding: 0 16px 16px;
    }
  `,

  List: styled.div`
    max-height: 60vh;
    overflow-y: auto;
    padding: 4px 40px;

    ${scrollbar}

    @media ${MEDIA_QUERIES.MOBILE} {
      padding: 4px 16px;
    }
  `,

  Section: styled.div``,

  ListTitle: styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    margin: 4px 0;
    font-size: 14px;
    color: #5c5c5c;
  `,

  Title: styled.div<any>`
    font-size: 20px;
    word-break: break-all;
    margin-bottom: 16px;
  `,

  Description: styled.div`
    font-size: 14px;
    color: #5c5c5c;
  `,

  ErrorsList: styled.ul``,

  ErrorsItem: styled.li`
    display: flex;
    align-items: center;
  `,

  Name: styled.div`
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media ${MEDIA_QUERIES.MOBILE} {
      max-width: calc(100vw - 80px);
    }
  `,

  Link: styled.a`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-left: auto;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;

    &:hover,
    &:focus,
    &:active {
      background-color: #e6f0fd;
    }
  `,
};
