import React from 'react';
import PoolIcon from '../../../static/Sidebar/Tickets_pool.svg';
import EventIcon from '../../../static/icons/event.svg';
import { formatEventDateTime } from 'shared/helpers/formatters/date';
import routes from 'shared/helpers/routes';
import SearchList from './SearchList';
import SearchStyled from './styles';

interface PoolProps {
  data: any[];
  meta: any;
  search: string;
  saveHistory: () => void;
  hideMenu: () => void;
}

const PoolEvents: React.FC<PoolProps> = ({ data, meta, search, saveHistory, hideMenu }) => {
  if (!data.length) return null;

  return (
    <SearchList
      length={data.length}
      meta={meta}
      title="Билетный пул"
      href={routes.searchPool}
      search={search}
      icon={<PoolIcon />}
      saveHistory={saveHistory}
      hideMenu={hideMenu}
    >
      {data.map((item) => {
        const { date: dateStart, time: timeStart } = formatEventDateTime(item.date_start, item.time_start);

        return (
          <SearchStyled.Result key={item.id}>
            <SearchStyled.Link href={`${routes.poolDetail(item.id)}`} onClick={saveHistory}>
              <SearchStyled.Row>
                <SearchStyled.Column croppedText>
                  <SearchStyled.Icon>
                    <EventIcon />
                  </SearchStyled.Icon>
                  <SearchStyled.Name>{item.title}</SearchStyled.Name>
                  <SearchStyled.Date>
                    {`${dateStart}, ${timeStart}`}
                    {item.place && (
                      <>
                        {' '}
                        | {item.place.name} - {item.place.address}
                      </>
                    )}
                  </SearchStyled.Date>
                </SearchStyled.Column>
              </SearchStyled.Row>
            </SearchStyled.Link>
          </SearchStyled.Result>
        );
      })}
    </SearchList>
  );
};

export default PoolEvents;
