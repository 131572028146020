import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  OrderInfo: styled.div<{ switchToTablet?: boolean }>`
    width: 100%;

    ${({ switchToTablet }) =>
      switchToTablet &&
      css`
        display: flex;
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-wrap: wrap;
    }
  `,

  OrderHeader: styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
  `,

  OrderInfoButtons: styled.div`
    display: flex;
    justify-content: center;
    margin-left: 2px;

    & > button {
      height: 20px;
      width: 20px;
      padding: 0;
      margin: 0 2px;
      border-radius: 4px;
      background: transparent;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: transparent !important;
      }
    }
  `,

  ManagerWrapper: styled.div`
    max-width: 100%;
  `,

  Manager: styled.div<{ switchToTablet?: boolean }>`
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-top: 4px;
    font-size: 12px;
    color: #5c5c5c;

    ${({ switchToTablet }) =>
      switchToTablet &&
      css`
        width: 14vw;
        margin: 0;
        line-height: 16px;
        color: #000;
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      width: 14vw;
      margin: 0;
      line-height: 16px;
      color: #000;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: auto;
    }
  `,
};
