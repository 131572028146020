import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Styled from './styles';
import { ModalFunctions } from '../../../../interfaces/modal';

interface NoticeProps {
  closeModal: ModalFunctions['closeModal'];
}

const RegistrationNoticeModal: React.FC<NoticeProps> = ({ closeModal }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const redirectToMarketplace = () => {
    router.push('https://portalbilet.ru');
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Styled.Title>{t('registration.notice.title')}</Styled.Title>
      <Styled.Text>
        {t('registration.notice.text_1')}{' '}
        <Styled.Link href="https://portalbilet.ru">portalbilet.ru</Styled.Link>{' '}
        {t('registration.notice.text_2')}
      </Styled.Text>
      <Styled.Buttons>
        <Styled.ActionButton onClick={redirectToMarketplace}>portalbilet.ru</Styled.ActionButton>
        <Styled.ActionButton onClick={closeModal}>{t('registration.notice.continue')}</Styled.ActionButton>
      </Styled.Buttons>
    </Styled.Container>
  );
};

export default RegistrationNoticeModal;
