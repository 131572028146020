import React, { Component } from 'react';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import drop from 'lodash/drop';
import take from 'lodash/take';
import compact from 'lodash/compact';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import head from 'lodash/head';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import Styled from './styles';
import Form from '../../Form';
import Modal from '../../Modal';
import Button from '../../Button';
import { ModalTable } from '../../Table';
import GlassTicketsModalTableRow from '../../GlassTicketsModalTableRow';
import TableAllRowsSelector from 'containers/TableAllRowsSelector';
import TABLES from 'shared/constants/TABLES';
import GlassTicketsModalRowsAction from '../../GlassTicketsModalRowsAction';
import GroupedTickets from '../../Search/GroupedTickets';
import { ACTIONS } from 'shared/constants/GLASS_TICKETS_MODAL';
import { ANNOUNCEMENT_EVERYWHERE_STATUS } from 'shared/constants/ANNOUNCEMENT_STATUS';
import { TICKET_STATUSES_VALUES } from 'shared/constants/TICKET_TYPES';
import { groupAnnouncementsTickets } from '../../../selectors/announcements';
import Tooltip from '../../Tooltip';
import api from 'shared/services/api';
import { orderSources } from 'entities/order';
import { groupTickets } from 'shared/helpers/tickets';
import AttentionIcon from '../../../../static/icons/attention.svg';
import { ModalFunctions } from '../../../../interfaces/modal';

const CELLS_PROPS = {
  1: { width: '7%' },
  2: { width: '15%' },
  3: { width: '15%' },
  4: { width: '23%' },
  5: { width: '40%', justifyContent: 'flex-end' },
};

interface ModalProps {
  data: any;
  loadSale: (orderId: number) => any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

interface ModalState {
  data: any;
  announcementsWithDuplicates: any;
  announcementsWithDuplicatesIds: any;
}

class GlassTicketsModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    const { announcements } = props.data;
    const announcementsWithDuplicates = [];
    const announcementsWithDuplicatesIds = new Set();

    const data = groupAnnouncementsTickets(
      announcements.map((announcement) => {
        const withDuplicates = announcement.tickets.reduce((acc, ticket) => {
          if (ticket.duplicate) {
            acc.push({
              ...ticket,
              ...(announcement.count && { count: announcement.count }),
              ...(announcement.row && { row: announcement.row }),
              ...(announcement.sector && { sector: announcement.sector }),
            });

            announcementsWithDuplicatesIds.add(announcement.id);
          }

          return acc;
        }, []);

        const grouped = withDuplicates.length ? groupTickets(withDuplicates).groupedTickets : [];

        if (!isEmpty(grouped)) {
          announcementsWithDuplicates.push(grouped);
        }

        return {
          ...announcement,
          action: ACTIONS.UNSET,
          status: ACTIONS.UNSET,
          count: announcement.tickets.length,
          tickets: announcement.tickets.map((ticket) => ({
            ...ticket,
            order: null,
            status: TICKET_STATUSES_VALUES.NEW,
          })),
        };
      }),
    );

    this.state = {
      data,
      announcementsWithDuplicates,
      announcementsWithDuplicatesIds,
    };

    this.getGroupedData = this.getGroupedData.bind(this);
  }

  handleSuccess = () => {
    const { orderId } = this.props.data;

    return this.props.loadSale(orderId).then(() => this.props.forceCloseModal());
  };

  getGroupedData() {
    return reduce(
      groupBy(this.state.data, 'id'),
      (acc, curr, index) => {
        acc[index] = reduce(
          groupBy(curr, 'action'),
          (actionAcc, actionCurr, actionIndex) => {
            // eslint-disable-next-line no-param-reassign
            actionAcc[actionIndex] = reduce(
              groupBy(actionCurr, 'status'),
              (statusAcc, statusCurr, statusIndex) => {
                // eslint-disable-next-line no-param-reassign
                statusAcc[statusIndex] = groupAnnouncementsTickets(
                  reduce(
                    statusCurr,
                    (itemAcc, itemCurr) => {
                      const newTickets = uniq(compact(concat(head(itemAcc).tickets, itemCurr.tickets)));
                      // eslint-disable-next-line no-param-reassign
                      itemAcc = [
                        {
                          ...head(itemAcc),
                          count: newTickets.length,
                          tickets: newTickets,
                        },
                      ];

                      return itemAcc;
                    },
                    [{ ...head(statusCurr) }],
                  ),
                );

                return statusAcc;
              },
              {},
            );

            return actionAcc;
          },
          {},
        );

        return acc;
      },
      {},
    );
  }

  setRowsAction = (ids, action, callback) => {
    this.setState(
      (prevState) => ({
        data: prevState.data.map((item) => {
          if (includes(ids, `${item.id}_${item.action}_${item.status}`)) {
            if (action === ACTIONS.SALE) {
              return {
                ...item,
                action,
                status: ANNOUNCEMENT_EVERYWHERE_STATUS,
              };
            }

            return {
              ...item,
              action,
              status: ACTIONS.UNSET,
            };
          }

          return item;
        }),
      }),
      () => {
        callback();
      },
    );
  };

  changeAction = ({ id, newAction, oldAction, status }) => {
    this.setState(({ data }) => ({
      data: data.map((item) => {
        if (item.id === id && item.action === oldAction && item.status === status) {
          if (newAction === ACTIONS.SALE) {
            return {
              ...item,
              action: newAction,
              status: ANNOUNCEMENT_EVERYWHERE_STATUS,
            };
          }

          return {
            ...item,
            action: newAction,
            status: ACTIONS.UNSET,
          };
        }

        return item;
      }),
    }));
  };

  changeStatus = ({ id, newStatus, oldStatus, action }) => {
    this.setState(({ data }) => ({
      data: data.map((item) => {
        if (item.id === id && item.status === oldStatus && item.action === action) {
          return {
            ...item,
            status: newStatus,
          };
        }

        return item;
      }),
    }));
  };

  changeDetailAction = ({ tickets, id, newAction, oldAction, status }) => {
    this.setState(({ data }) => ({
      data: groupAnnouncementsTickets(
        reduce(
          data,
          (acc, curr) => {
            if (curr.id === id && curr.action === oldAction && curr.status === status) {
              let newTickets;
              let updatedTickets;
              const isStanding = !head<any>(curr.tickets).seat;

              if (isStanding) {
                newTickets = take(curr.tickets, tickets);
                updatedTickets = drop(curr.tickets, tickets);
              } else {
                const ticketsIds = tickets.map((item) => item.id);

                newTickets = curr.tickets.filter((item) => includes(ticketsIds, item.id));
                updatedTickets = curr.tickets.filter((item) => !includes(ticketsIds, item.id));
              }

              acc.push({
                ...curr,
                tickets: updatedTickets,
                count: isStanding ? curr.count - tickets : updatedTickets.length,
              });

              acc.push({
                ...curr,
                tickets: newTickets,
                count: isStanding ? tickets : newTickets.length,
                action: newAction,
                ...(newAction === ACTIONS.SALE
                  ? { status: ANNOUNCEMENT_EVERYWHERE_STATUS }
                  : { status: ACTIONS.UNSET }),
              });
            } else {
              acc.push(curr);
            }

            return acc;
          },
          [],
        ).filter((item) => item.tickets.length > 0),
      ),
    }));
  };

  submitForm = () => {
    const { orderAction, orderId, orderAmount } = this.props.data;
    const data = this.getGroupedData();

    const saleActions = flatten(
      compact(
        map(data, (item) => {
          const itemData = get(item, `${ACTIONS.SALE}`);

          return map(itemData, (innerItem: any) => {
            const current = head<any>(innerItem);

            return {
              announcement: Number(current.id),
              status: current.status,
              operation: 10,
              tickets: map(current.tickets, (ticket) => ticket.id),
            };
          });
        }),
      ),
    );

    const refundActions = compact(
      map(data, (item, index) => {
        const itemData: any = head(get(item, `${ACTIONS.REFUND}.${ACTIONS.UNSET}`));

        if (itemData === undefined) {
          return null;
        }

        return {
          announcement: Number(index),
          operation: 20,
          tickets: map(itemData.tickets, (ticket) => ticket.id),
        };
      }),
    );

    const deleteActions = compact(
      map(data, (item, index) => {
        const itemData: any = head(get(item, `${ACTIONS.DELETE}.${ACTIONS.UNSET}`));

        if (itemData === undefined) {
          return null;
        }

        return {
          announcement: Number(index),
          operation: 30,
          tickets: map(itemData.tickets, (ticket) => ticket.id),
        };
      }),
    );

    const totalData = concat(saleActions, refundActions, deleteActions);

    if (orderAction === 'reject') {
      return api.post(orderSources.confirmReject(orderId), { confirm: totalData });
    }

    if (orderAction === 'refund') {
      return api.post(orderSources.confirmRefund(orderId), { confirm: totalData, amount: orderAmount });
    }

    return null;
  };

  render() {
    const { data, announcementsWithDuplicates, announcementsWithDuplicatesIds } = this.state;

    const groupedData = this.getGroupedData();

    const allActionsIsSet = data.map((item) => item.action).indexOf(ACTIONS.UNSET) === -1;

    return (
      <Form
        onSubmit={this.submitForm}
        onSuccess={this.handleSuccess}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Styled.Container>
              <Styled.Title>
                <Styled.Exclamation />
                Отмена/возврат заказа
              </Styled.Title>
              <Styled.SubTitle>
                В заказе есть билеты со статусом «Стекло», выберите действие которое хотите сделать с этими
                билетами
              </Styled.SubTitle>
              <ModalTable.Table>
                <ModalTable.Head>
                  <ModalTable.Cell {...CELLS_PROPS[1]}>
                    <TableAllRowsSelector
                      ids={data.map((item) => `${item.id}_${item.action}_${item.status}`)}
                      page={TABLES.GLASS_TICKETS_MODAL}
                    />
                  </ModalTable.Cell>
                  <ModalTable.Cell {...CELLS_PROPS[2]}>
                    <ModalTable.HeadText>Сектор</ModalTable.HeadText>
                  </ModalTable.Cell>
                  <ModalTable.Cell {...CELLS_PROPS[3]}>
                    <ModalTable.HeadText>Ряд</ModalTable.HeadText>
                  </ModalTable.Cell>
                  <ModalTable.Cell {...CELLS_PROPS[4]}>
                    <ModalTable.HeadText>Места</ModalTable.HeadText>
                  </ModalTable.Cell>
                  <ModalTable.Cell {...CELLS_PROPS[5]}>
                    <GlassTicketsModalRowsAction
                      setRowsAction={this.setRowsAction}
                      announcementsWithDuplicatesIds={announcementsWithDuplicatesIds}
                    />
                  </ModalTable.Cell>
                </ModalTable.Head>
                <ModalTable.Body>
                  {map(groupedData, (id) =>
                    map(id, (action: any) =>
                      map(action, (status) =>
                        map(status, (item) => (
                          <GlassTicketsModalTableRow
                            data={data}
                            key={`${item.id}_${item.action}_${item.status}`}
                            announcement={item}
                            cellProps={CELLS_PROPS}
                            changeAction={this.changeAction}
                            changeStatus={this.changeStatus}
                            changeDetailAction={this.changeDetailAction}
                          />
                        )),
                      ),
                    ),
                  )}
                  {Boolean(announcementsWithDuplicates.length) && (
                    <Styled.Attention>
                      <Styled.AttentionIcon>
                        <AttentionIcon />
                      </Styled.AttentionIcon>
                      <div>
                        Возвратные билеты нельзя выставить на продажу:
                        {announcementsWithDuplicates.map((item) => (
                          // @ts-ignore
                          <GroupedTickets groupedTickets={item} plain withoutPrice />
                        ))}
                      </div>
                    </Styled.Attention>
                  )}
                </ModalTable.Body>
              </ModalTable.Table>
              <Modal.Footer>
                <Button type="button" transparent onClick={this.props.closeModal}>
                  Отмена
                </Button>
                <Tooltip
                  text={!allActionsIsSet ? 'Необходимо выбрать действие для каждого объявления' : null}
                >
                  <Button disabled={!allActionsIsSet}>Готово</Button>
                </Tooltip>
              </Modal.Footer>
            </Styled.Container>
          </form>
        )}
      />
    );
  }
}

export default GlassTicketsModal;
