import { useEffect, useRef, useState, type PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps extends PropsWithChildren {
  rootId: string;
}

export const Portal = ({ rootId, children }: PortalProps) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    setMounted(true);
    containerRef.current = document.getElementById(rootId);
    return () => setMounted(false);
  }, []);

  return mounted && Boolean(containerRef.current)
    ? createPortal(children, containerRef.current as HTMLDivElement)
    : null;
};
