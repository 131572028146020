import Form from 'components/Form';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Header: styled(StepModal.Header)`
    padding: 12px 0;
  `,

  Title: styled(StepModal.Title)`
    font-weight: 400;
  `,

  Content: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 24px 16px 0;
    }
  `,

  FooterContainer: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      & > div {
        margin: 0 -24px;
      }
    }
  `,
};
