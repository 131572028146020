import { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { mediumModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${mediumModalStyles}

    @media (max-width: ${BREAKPOINTS.SM}) {
      .modal__wrapper {
        max-width: 100vw;
      }
    }
  `,
};
