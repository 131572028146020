import React, { useState } from 'react';
import Form from '../../../Form';
import Tabs from '../../../Tabs';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { getSelectedTab } from 'shared/helpers/constructor';

const TABS = [
  {
    text: 'Без поиска',
    key: 1,
  },
  {
    text: 'С поиском',
    key: 2,
  },
];

const WITHOUT_SEARCH = [1, 2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20];
const WITH_SEARCH = [4, 5, 6, 15];

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const HeroTypesStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => {
  const [tab, setTab] = useState(getSelectedTab(data.ui_config_slider, WITHOUT_SEARCH));

  return (
    <Styled.Container withTabs>
      <Styled.Header withTabs>
        <Styled.Title>Выберите дизайн Обложки</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Tabs tabs={TABS} onSelect={setTab} selectedTab={tab}>
          <Form
            onSubmit={onSubmit}
            initialValues={data}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Styled.ListWrapper>
                  {tab === 1 && (
                    <Styled.List grid>
                      {WITHOUT_SEARCH.map((item) => (
                        <Styled.Item grid width="50%" key={item} data-selenium={`variant-${item}`}>
                          <Variant name="ui_config_slider" value={item}>
                            <img src={`/static/constructor/cover/${item}.jpg`} alt="" />
                          </Variant>
                        </Styled.Item>
                      ))}
                    </Styled.List>
                  )}
                  {tab === 2 && (
                    <Styled.List grid>
                      {WITH_SEARCH.map((item) => (
                        <Styled.Item grid width="50%" key={item} data-selenium={`variant-${item}`}>
                          <Variant name="ui_config_slider" value={item}>
                            <img src={`/static/constructor/cover/${item}.jpg`} alt="" />
                          </Variant>
                        </Styled.Item>
                      ))}
                    </Styled.List>
                  )}
                </Styled.ListWrapper>
                <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
              </form>
            )}
          />
        </Tabs>
      </Styled.Content>
    </Styled.Container>
  );
};

export default HeroTypesStep;
