import DataPreview from 'components/DataPreview';
import Pagination from 'components/Pagination';
import permissions from 'config/permissions';
import React, { useEffect } from 'react';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { ReferenceCard, ReferenceCardTitle } from 'widgets/reference/reference-card';
import expansions from 'shared/sources/expansion';
import Can from '../../../../../containers/Can';
import Styled from './styles';

interface ListProps {
  data: any;
  modalType: string;
  meta: any;
  state: any;
  setListParams: (data: any) => any;
}

export const ExpansionsList: React.FC<ListProps> = (props) => {
  const { meta, data, modalType, state, setListParams } = props;

  useEffect(() => {
    setListParams({ source: expansions });
  }, []);

  return (
    <ReferenceLayout.Content>
      <ReferenceLayout.List as="ul">
        <DataPreview length={data.length} state={state}>
          {data.map((item) => (
            <Can
              key={item.id}
              permission={permissions.reference}
              fallback={() => (
                <Styled.Item>
                  <ReferenceCard small noHover dataSelenium={item.name}>
                    <ReferenceCardTitle small>{item.name}</ReferenceCardTitle>
                    <ReferenceLayout.Description>{item.description}</ReferenceLayout.Description>
                  </ReferenceCard>
                </Styled.Item>
              )}
            >
              <Styled.Item>
                <ReferenceCard data={item} small modalType={modalType} dataSelenium={item.name}>
                  <ReferenceCardTitle small>{item.name}</ReferenceCardTitle>
                  <ReferenceLayout.Description>{item.description}</ReferenceLayout.Description>
                </ReferenceCard>
              </Styled.Item>
            </Can>
          ))}
        </DataPreview>
      </ReferenceLayout.List>
      <ReferenceLayout.Nav>
        <Pagination limits={[25, 50, 100, 150]} meta={meta} updateQuery />
      </ReferenceLayout.Nav>
    </ReferenceLayout.Content>
  );
};
