import React, { Component } from 'react';
import checkIsNaN from 'lodash/isNaN';
import Styled from './styles';
import MinusIcon from '../../../static/icons/minus.svg';
import PlusIcon from '../../../static/icons/plus.svg';

interface SelectorProps {
  onChange: (data: any, value: number) => void;
  max: number;
  data: any;
  value?: number;
  disabled?: boolean;
  name?: string;
}

interface SelectorState {
  value: number;
}

class NumericSelector extends Component<SelectorProps, SelectorState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    value: 0,
    name: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: Number(this.props.value),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<SelectorProps>): void {
    if (nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleChange = (event) => {
    const { max, data, onChange } = this.props;

    const currentValue = Number(event.target.value);

    if (checkIsNaN(currentValue)) {
      return false;
    }

    if (currentValue > max) {
      this.setState(
        {
          value: max,
        },
        () => onChange(data, max),
      );

      return max;
    }

    this.setState({
      value: currentValue,
    });

    return onChange(data, currentValue);
  };

  addition = () => {
    const { onChange, data } = this.props;

    this.setState(
      (prevState) => ({
        value: prevState.value + 1,
      }),
      () => onChange(data, this.state.value),
    );
  };

  decrease = () => {
    const { onChange, data } = this.props;

    this.setState(
      (prevState) => ({
        value: prevState.value - 1,
      }),
      () => onChange(data, this.state.value),
    );
  };

  render() {
    const { value } = this.state;
    const { disabled, max, name } = this.props;

    return (
      <Styled.CountWrapper>
        <Styled.CountButton
          type="button"
          onClick={this.decrease}
          disabled={disabled || value === 0}
          data-selenium="decrement"
        >
          <MinusIcon />
        </Styled.CountButton>
        <Styled.CountInput
          type="text"
          onChange={this.handleChange}
          value={value}
          disabled={disabled}
          name={name}
        />
        <Styled.CountButton
          type="button"
          onClick={this.addition}
          disabled={disabled || value >= max}
          data-selenium="increment"
        >
          <PlusIcon />
        </Styled.CountButton>
      </Styled.CountWrapper>
    );
  }
}

export default NumericSelector;
