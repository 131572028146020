import styled from 'styled-components';
import { CheckboxCSS as Checkbox } from '../TableSeatCheckbox/styles';

export default {
  Container: styled.div<{ seats?: boolean }>`
    display: flex;
    padding: ${({ seats }) => (seats ? '0 8px 4px' : '4px 8px')};
    align-items: center;
    transition: all 0.2s ease-in-out;
    pointer-events: all;
  `,

  CheckboxContainer: styled.div`
    display: flex;
    flex-wrap: wrap;

    & > label:not(:last-child),
    & > div:not(:last-child) {
      margin-right: 4px;
    }

    & > label,
    & > div {
      margin-top: 4px;
    }
  `,

  CountWrapper: styled.div`
    display: flex;
  `,

  CountButton: styled.button`
    ${Checkbox}
    outline: none;

    & > svg {
      width: 10px;
      height: 10px;
      opacity: 0.64;
      transition: 0.2s ease-in-out;
    }

    &:hover:not(:disabled) > svg {
      opacity: 1;
    }

    &:disabled {
      border-color: #e1e1e1;
      background-color: #fff;
      cursor: not-allowed;
    }
  `,

  CountInput: styled.input`
    box-sizing: border-box;
    width: 64px;
    height: 32px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    margin: 0 8px;
    text-align: center;
    outline: none;
    transition: 0.2s ease-in-out;

    &:hover:not(:disabled) {
      border-color: #000;
    }

    &:focus:not(:disabled),
    &:active:not(:disabled) {
      border-color: #2f80ed;
    }

    &:disabled {
      cursor: not-allowed;
    }
  `,
};
