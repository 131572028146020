import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import reduce from 'lodash/reduce';
import { Textarea } from 'components/FormControls';
import { hasNumericTickets } from 'shared/helpers/events';
import { convertSeats } from 'shared/helpers/seats';
import { deleteSelectedTicket } from 'entities/announcement';
import { selectedAnnouncementCurrency } from '../../../../selectors/announcements';
import SEAT_SELECTOR_TYPES from 'shared/constants/SEAT_SELECTOR_TYPES';
import Styled from './styles';
import TrashIcon from '../../../../../static/icons/trash.svg';
import MailIcon from '../../../../../static/icons/mail.svg';
import MinusIcon from '../../../../../static/icons/minus.svg';
import Button from 'components/Button';
import TextOverflow from 'components/TextOverflow';
import SplittedNumber from 'components/SplittedNumber';

function getCount(announcement) {
  return announcement.ticketsType === SEAT_SELECTOR_TYPES.PLAIN
    ? announcement.selectedTickets.length
    : announcement.selectedTickets;
}

interface RowProps {
  allowMessage?: boolean;
  deleteSelectedTicket: (id: string | number) => any;
  announcement: any[];
  selectedCurrency: any;
}

interface RowState {
  messageVisible: boolean;
}

class BookTicketsModalRow extends Component<RowProps, RowState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    allowMessage: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      messageVisible: false,
    };
  }

  handleDelete = (_, id) => {
    this.props.deleteSelectedTicket(id);
  };

  toggleMessage = () => {
    this.setState((prevState) => ({
      messageVisible: !prevState.messageVisible,
    }));
  };

  render() {
    const { announcement, allowMessage, selectedCurrency } = this.props;
    const { messageVisible } = this.state;

    const seller = announcement[0].create_by.company;
    const isSimple = announcement.length === 1;
    const total = reduce(
      announcement,
      (acc, item) => {
        const price = item.total_price * getCount(item);
        return acc + price;
      },
      0,
    );
    const currency = <Styled.Currency src={selectedCurrency.icon} />;

    return (
      <Styled.Wrapper>
        <Styled.Ticket isSimple={isSimple}>
          <Styled.HeadingRow>
            <Styled.TicketCol>
              <Styled.Label>Сектор / Категория</Styled.Label>
            </Styled.TicketCol>
            <Styled.TicketCol>
              <Styled.Label>Ряд</Styled.Label>
            </Styled.TicketCol>
            <Styled.TicketCol>
              <Styled.Label>Места</Styled.Label>
              <Styled.Value />
            </Styled.TicketCol>
            <Styled.TicketCol>
              <Styled.Label>Кол-во</Styled.Label>
            </Styled.TicketCol>
            <Styled.TicketCol>
              <Styled.Label>Цена за шт, {currency}</Styled.Label>
            </Styled.TicketCol>
          </Styled.HeadingRow>
          {announcement.map((item) => {
            const isNumericTicket = hasNumericTickets(item);
            const totalPrice = item.total_price;
            const count = getCount(item);

            return (
              <Styled.TicketRow isSimple={isSimple} key={item.id}>
                <Styled.TicketCol>
                  <Styled.Value>{item.sector || item.category}</Styled.Value>
                </Styled.TicketCol>
                <Styled.TicketCol>
                  <Styled.Value>{item.row || '-'}</Styled.Value>
                </Styled.TicketCol>
                <Styled.TicketCol>
                  <Styled.Value>
                    {isNumericTicket ? '-' : convertSeats(item.selectedTickets.map((ticket) => ticket.seat))}
                  </Styled.Value>
                </Styled.TicketCol>
                <Styled.TicketCol>
                  <Styled.Value>
                    <SplittedNumber>{count}</SplittedNumber>
                  </Styled.Value>
                </Styled.TicketCol>
                <Styled.TicketCol>
                  <Styled.Value>
                    <SplittedNumber>{totalPrice}</SplittedNumber>
                  </Styled.Value>
                </Styled.TicketCol>
                <Styled.Delete>
                  <Button type="button" onlyIcon dangerTransparent onClick={this.handleDelete} data={item.id}>
                    <TrashIcon />
                  </Button>
                </Styled.Delete>
              </Styled.TicketRow>
            );
          })}
        </Styled.Ticket>
        <Styled.Footer>
          <Button type="button" onlyIcon transparent onClick={this.toggleMessage}>
            {messageVisible ? <MinusIcon /> : <MailIcon />}
          </Button>
          <Styled.SellerName>
            <TextOverflow>{announcement[0].create_by.company.name}</TextOverflow>
          </Styled.SellerName>
          <Styled.TotalPrice>
            <Styled.Label>Итого, {currency}</Styled.Label>
            <Styled.Value>
              <SplittedNumber>{total}</SplittedNumber>
            </Styled.Value>
          </Styled.TotalPrice>
        </Styled.Footer>
        {messageVisible && allowMessage && (
          <Field name={`comment_${seller.id}`}>
            {({ input, meta }) => (
              <Styled.MessageField>
                <Textarea autoFocus label="Сообщение продавцу" {...input} meta={meta} />
              </Styled.MessageField>
            )}
          </Field>
        )}
      </Styled.Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCurrency: selectedAnnouncementCurrency(state),
});

const mapDispatchToProps = {
  deleteSelectedTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookTicketsModalRow);
