import { createEffect } from 'effector';
import config from 'config/config';
import api from '../../services/api';
import { EventValue } from './types';

export default {
  root: `${config.COMMON_API_URL}/admin/v1/documents`,
};

export const createDocumentFx = createEffect(
  async ({
    eventId,
    documentId,
  }: {
    eventId: number;
    documentId: number;
  }): Promise<{ document_id: number }> => {
    const response = await api.post(`${config.COMMON_API_URL}/admin/v1/events/${eventId}/documents`, {
      document_id: documentId,
    });

    return response.data;
  },
);

export const deleteDocumentFx = createEffect(
  async ({ eventId, documentId }: { eventId: number; documentId: number; formValue: EventValue }) => {
    const response = await api.delete(
      `${config.COMMON_API_URL}/admin/v1/events/${eventId}/documents/${documentId}`,
    );

    return response.data;
  },
);
