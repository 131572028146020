import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { toggleSidebarState } from 'entities/layout';
import SidebarIcon from '../../../static/icons/sidebar.svg';

interface ContainerProps {
  sidebarIsOpen: boolean;
  toggleSidebarState: () => void;
}

interface CSSContainerProps {
  sidebarIsOpen?: boolean;
}

const Container = styled.div<CSSContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  cursor: pointer;

  ${(props) =>
    !props.sidebarIsOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const SidebarIconWrapper = (props: ContainerProps) => (
  <Container onClick={props.toggleSidebarState} sidebarIsOpen={props.sidebarIsOpen}>
    <SidebarIcon />
  </Container>
);

const mapStateToProps = (state) => ({
  sidebarIsOpen: state.layout.sidebarIsOpen,
});
const mapDispatchToProps = {
  toggleSidebarState,
};
export default connect(mapStateToProps, mapDispatchToProps)(SidebarIconWrapper);
