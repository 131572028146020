import React from 'react';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import playgroundsSources from 'shared/sources/playgrounds';
import { renderWidgetPlaygrounds } from 'shared/helpers/optionsRenderers';
import { InputWithAutocomplete } from '../../../../FormControls';
import { DataContext } from '../DataContext';
import WidgetStyled from '../../WidgetForm/styles';
import Tooltip from '../../../../Tooltip';
import { isEmpty } from 'lodash';

interface RowProps {
  sector: any;
  field: string;
  title: string;
  disabled: boolean;
  onChangeSector: (data: any) => void;
  onChangeRow: (data: any) => void;
  isMulti: boolean;
  value: any;
  parentId: number | string;
}

const ValueEditorRow: React.FC<RowProps> = (props) => {
  const { sector, field, title, disabled, onChangeSector, onChangeRow, isMulti, value, parentId } = props;

  return (
    <DataContext.Consumer>
      {(context: any) => {
        const event = context && context.event;
        const markup = context?.markup?.find((markupItem) => parentId === markupItem?.id);

        let markupHallLayout;
        if (markup) {
          markupHallLayout = markup?.hallLayouts ? markup?.hallLayouts?.value : markup?.query?.hall_layout_id;
        }

        let filtersHallLayout;
        if (context?.placesFilter) {
          filtersHallLayout =
            context.placesFilter?.hallLayout || context.placesFilter?.hallLayout?.select === 'empty'
              ? context.placesFilter?.hallLayout.value
              : context?.placesFilter?.hall_layout_id;
        }

        const hallLayoutParams = event
          ? (event.hall_layout && event.hall_layout.id) || event.hall_layout_id
          : markupHallLayout || filtersHallLayout;

        const query = {
          hall_layout: hallLayoutParams,
          fragment_type: FRAGMENT_TYPES.SECTOR,
        };
        const rowQuery = {
          hall_layout: hallLayoutParams,
          fragment_type: FRAGMENT_TYPES.ROW,
          parent: sector && sector.value,
        };

        return (
          <WidgetStyled.Row>
            <WidgetStyled.Column>
              <Tooltip text={sector && sector.name}>
                <div>
                  <InputWithAutocomplete
                    fluid
                    defaultOptions={!!hallLayoutParams}
                    disablePaginate
                    withoutDefaultValue
                    key={hallLayoutParams || field}
                    value={sector}
                    title={title}
                    query={query}
                    isMulti={false}
                    isDisabled={!hallLayoutParams}
                    route={playgroundsSources}
                    routeName="root"
                    onChange={onChangeSector}
                    optionsRenderer={renderWidgetPlaygrounds}
                  />
                </div>
              </Tooltip>
            </WidgetStyled.Column>
            <WidgetStyled.Column>
              <InputWithAutocomplete
                fluid
                isMulti={isMulti}
                defaultOptions
                disablePaginate
                withoutDefaultValue
                key={sector || field}
                value={value}
                title={title}
                query={rowQuery}
                route={sector && playgroundsSources}
                routeName="root"
                onChange={onChangeRow}
                isDisabled={!hallLayoutParams || !sector}
                optionsRenderer={renderWidgetPlaygrounds}
              />
            </WidgetStyled.Column>
          </WidgetStyled.Row>
        );
      }}
    </DataContext.Consumer>
  );
};

export default ValueEditorRow;
