import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface FormRowProps {
  grid?: boolean;
  small?: boolean;
}

const FormRow = styled.div<FormRowProps>`
  width: 100%;
  margin-bottom: ${(props) => (props.small ? 8 : 32)}px;

  ${(props) =>
    props.grid &&
    css`
      display: flex;
      margin-bottom: 0;
    `}
`;

interface FormDescriptionProps {
  width?: number;
}

export const FormDescription = styled.div<FormDescriptionProps>`
  width: ${(props) => props.width || 125}px;
  margin-right: 24px;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 21px;
  color: #000000;

  @media (max-width: ${BREAKPOINTS.MD}) {
    width: auto;
    margin: 0 0 24px;
  }
`;

export const SmallFormDescription = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.64);
`;

interface FormControlProps {
  width?: string;
  column?: boolean;
  mobileColumn?: boolean;
}

export const FormControl = styled.div<FormControlProps>`
  display: flex;
  width: 100%;
  max-width: ${({ width }) => width || '384px'};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  @media (max-width: ${BREAKPOINTS.MD}) {
    max-width: 100%;
    flex-direction: ${({ mobileColumn }) => mobileColumn && 'column'};
  }
`;

export default FormRow;
