import { createEvent, createStore, sample } from 'effector';
import { fetchHallsFx } from 'shared/api/reference/halls';
import { fetchPlaceFx, Place } from 'shared/api/reference/places';
import { createToggler } from 'shared/lib/toggler';

export const toggler = createToggler();

export const modalOpened = createEvent<{ id: number; type: string }>();
export const modalClosed = createEvent();

export const $place = createStore<Place | null>(null);
export const $relatedHalls = createStore<any>(null);
export const $loading = createStore(true);
export const $relatedHallsLoading = createStore(true);
export const $modalId = createStore<number | null>(null);

export const placeViewModal = {
  toggler,
  modalOpened,
  modalClosed,
  $place,
  $relatedHalls,
  $loading,
  $relatedHallsLoading,
  $modalId,
};

//load
sample({
  clock: modalOpened,
  target: fetchPlaceFx,
});

sample({
  clock: toggler.open,
  target: $modalId,
});

sample({
  clock: toggler.close,
  fn: () => true,
  target: $loading,
});

sample({
  clock: toggler.close,
  fn: () => null,
  target: $place,
});

sample({
  clock: fetchPlaceFx.done,
  filter: ({ params }) => params.type === 'view',
  fn: ({ result }) => {
    return result;
  },
  target: $place,
});

sample({
  clock: fetchPlaceFx.done,
  filter: ({ params }) => params.type === 'view',
  fn: () => false,
  target: $loading,
});

sample({
  clock: fetchPlaceFx.done,
  filter: ({ params }) => params.type === 'view',
  fn: ({ result }) => {
    return {
      place_id_in: result.id,
      page: 1,
      limit: 9999,
    };
  },
  target: fetchHallsFx,
});

sample({
  clock: fetchHallsFx.done,
  fn: ({ result }) => result,
  target: $relatedHalls,
});

sample({
  clock: fetchHallsFx.done,
  fn: () => false,
  target: $relatedHallsLoading,
});
