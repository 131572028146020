import React from 'react';
import routes from 'shared/helpers/routes';

const REPORTS = {
  DYNAMIC: 'dynamic',
  ORDER: 'order',
  REFUND: 'refund',
  PURCHASE: 'purchase',
  SALES: 'sales',
  DELIVERY: 'delivery',
  EXPIRED: 'expired',
  EVENT: 'event',
  EVENT_FRAGMENT: 'event/fragment',
  REJECT: 'reject',
  PURCHASE_B2C: 'purchase/b2c',
  PRINCIPALS: 'principals',
};

export const REPORTS_ROUTES = (isPbilerReport = false) => {
  const prefix = isPbilerReport ? 'pbReports' : 'reports';
  return {
    [REPORTS.DYNAMIC]: routes[`${prefix}Dynamic`],
    [REPORTS.ORDER]: routes[`${prefix}Orders`],
    [REPORTS.REFUND]: routes[`${prefix}Refunds`],
    [REPORTS.PURCHASE]: routes[`${prefix}Purchases`],
    [REPORTS.SALES]: routes[`${prefix}Sales`],
    [REPORTS.DELIVERY]: routes[`${prefix}Delivery`],
    [REPORTS.EXPIRED]: routes[`${prefix}Expired`],
    [REPORTS.EVENT]: routes[`${prefix}Event`],
    [REPORTS.EVENT_FRAGMENT]: routes[`${prefix}EventFragment`],
    [REPORTS.REJECT]: routes[`${prefix}Rejects`],
    [REPORTS.PURCHASE_B2C]: routes[`${prefix}PurchasesB2C`],
    [REPORTS.PRINCIPALS]: routes[`${prefix}Principals`],
  };
};

export const REPORTS_DATES_FILTER_TYPES = {
  ORDER: 'order_date',
  EVENT: 'event_date',
};

/* eslint-disable */

const EVENT_DESCRIPTION = [
  {
    title: 'Отчет формируется по заказам, которые удовлетворят следующим требованиям:',
    description: (
      <ul>
        <li>1. Произведена оплата.</li>
        <li>2. Все заказы, кроме заказов в статусах «Отмена» и «Возврат».</li>
      </ul>
    ),
  },
  {
    title: 'Отчет представлен в двух видах:',
    description: (
      <ul>
        <li>1. Общий</li>
        <li>2. В разрезе фрагментов площадки</li>
      </ul>
    ),
  },
  {
    title: 'Закупочная цена, ₽',
    description: (
      <>
        <div>Закупочная цена = Сумма закупочных цен билетов в заказе</div>
        <div>Закупочная цена билета:</div>
        <ul>
          <li>1) Закупочная цена из объявления, если билет принадлежит нашей компании.</li>
          <li>2) Цена покупки билета у партнера, если мы купили билет у партнера.</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Номинальная цена, ₽',
    description: 'Номинальная цена = Сумма номинальных цен билетов в заказе',
  },
  {
    title: 'Итоговая цена, ₽',
    description: 'Итоговая цена = Сумма цена заказов с учетом скидок ',
  },
];

export const REPORTS_DESCRIPTIONS: any = {
  [REPORTS.ORDER]: [
    {
      title: 'Отчет формируется по заказам, с учетом всех статусов, в одном из выбранных разрезов:',
      description: (
        <ul>
          <li>1. В разрезе мероприятий.</li>
          <li>2. В разрезе менеджеров.</li>
          <li>3. В разрезе площадок.</li>
        </ul>
      ),
    },
    {
      title: '',
      description:
        'Чтобы получить актуальные данные в отчете дата заказа берется из истории заказа, а не из даты создания заказа. Пример: Заказ создан два месяца назад, а сегодня сделали возврат. Заказ в статусе возврат попадет в отчет за сегодняшнее число.',
    },
    {
      title: 'Всего заказов, шт',
      description: (
        <>
          <div>Кол-во заказов во всех статусах, кроме Отмена с причиной отмены:</div>
          <ul>
            <li>- Тестовый заказ</li>
            <li>- Фейк</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Отмена, шт',
      description: (
        <>
          <div>Кол-во заказов в статусе "Отмена", учитываются все причины отмены, кроме:</div>
          <ul>
            <li>1) Тестовый заказ</li>
            <li>2) Фейк</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Прибыль, ₽',
      description: (
        <ul>
          <li>Формируется по заказам в статусе «Завершено».</li>
          <li>Прибыль = Сумма прибылей по заказам</li>
          <li>
            Прибыль по заказу = Цена заказа с учетом скидок/наценок - Сумма закупочных цен билетов в заказе -
            Цена заказа с учетом скидок/наценок * Операционные расходы
          </li>
        </ul>
      ),
    },
    {
      title: 'Закупочная цена билета:',
      description: (
        <ul>
          <li>1) Закупочная цена из объявления, если билет принадлежит нашей компании.</li>
          <li>2) Цена покупки билета у партнера, если мы купили билет у партнера.</li>
        </ul>
      ),
    },
    {
      title: 'Операционные расходы берутся из конфигуратора Операционных расходов, если не указаны, то:',
      description: (
        <ul>
          <li>- для наличного расчета по-умолчанию = 0%</li>
          <li>- для безналичного расчета по-умолчанию = 10%</li>
        </ul>
      ),
    },
    {
      title: 'Доход по заказу = Цена заказа с учетом скидок - Сумма закупочных цен билетов в заказе',
      description: (
        <>
          <div>Закупочная цена билета:</div>
          <ul>
            <li>1) Закупочная цена из объявления, если билет принадлежит нашей компании.</li>
            <li>2) Цена покупки билета у партнера, если мы купили билет у партнера.</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Возврат, ₽',
      description: (
        <ul>
          <li>Формируется по заказам в статусе «Возврат».</li>
          <li>Возврат = Сумма возвратов</li>
        </ul>
      ),
    },
  ],
  [REPORTS.REFUND]: [
    {
      description: 'Отчет формируется по заказам в статусе «Возврат».',
    },
    {
      description:
        'Чтобы получить актуальные данные в отчете дата заказа берется из истории заказа, а не из даты создания заказа. Пример: Заказ создан два месяца назад, а сегодня сделали возврат. Заказ в статусе возврат попадет в отчет за сегодняшнее число.',
    },
    {
      title: 'Возврат, ₽',
      description: 'Возврат = Сумма возвратов',
    },
    {
      title: 'Выручка, ₽',
      description: (
        <ul>
          <li>Выручка = Сумма выручек после возврата заказов</li>
          <li>Выручка после возврата заказа = Цена заказа с учетом скидок - Сумма возврата</li>
        </ul>
      ),
    },
  ],
  [`${REPORTS.REFUND}_GRAPH`]: (
    <div>
      Возврат, ₽ <br />
      Возврат = Сумма возвратов
    </div>
  ),
  [REPORTS.PURCHASE]: [
    {
      description:
        'Итоговый отчет по мероприятиям, формируется в разрезе поставщиков и является суммой двух отчетов:',
    },
    {
      title: '1. B2C отчет по мероприятиям.',
      description: (
        <>
          <div>
            Отчет формируется по билетам из объявлений компании, которые удовлетворят следующим требованиям:
          </div>
          <ul>
            <li>- Тип объявления «В продаже».</li>
            <li>- В отчет не попадают объявления в статусе «Стекло».</li>
            <li>- В отчет не попадают билеты в статусах «Удален» и «Возвращен».</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Кол-во, шт',
      description: 'Кол-во непроданных билетов / Общее кол-во билетов из объявлений',
    },
    {
      title: 'Номинальная цена, ₽',
      description: 'Номинальная цена = Сумма номинальных цен объявлений',
    },
    {
      title: 'Закупочная цена, ₽',
      description: 'Закупочная цена = Сумма закупочных цен объявлений',
    },
    {
      title: 'Остаток, ₽',
      description: (
        <ul>
          <li>Остаток = Сумма Остатков объявлений</li>
          <li>Остаток объявления = Сумма закупочных цен непроданных билетов</li>
        </ul>
      ),
    },
    {
      title: 'Доход, ₽',
      description: (
        <ul>
          <li>Доход = Сумма Доходов объявлений</li>
          <li>Доход объявления = Сумма цен проданных билетов из объявления с учетом скидок/наценок</li>
        </ul>
      ),
    },
    {
      title: 'Прибыль, ₽',
      description: (
        <>
          <div>Прибыль = Сумма Прибылей объявлений</div>
          <div>
            Прибыль объявления = Доход объявления - Закупочная цена объявления - Доход объявления *
            Операционные расходы
          </div>
        </>
      ),
    },
    {
      title: 'Операционные расходы берутся из конфигуратора Операционных расходов, если не указаны, то:',
      description: (
        <ul>
          <li>- для наличного расчета по-умолчанию = 0%</li>
          <li>- для безналичного расчета по-умолчанию = 10%</li>
        </ul>
      ),
    },
    {
      title: '2. B2B отчет по мероприятиям.',
      description: (
        <>
          <div>Отчет формируется по заказам, которые удовлетворят следующим требованиям:</div>
          <ul>
            <li>– Заказ, где компания продает билеты партнеров.</li>
            <li>- Билет из заказа находится в объявлении в статусе «В продаже».</li>
            <li>- В отчет не попадают билеты из объявлений в статусе «Стекло».</li>
            <li>- В отчет не попадают билеты в статусах «Удален» и «Возвращен».</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Кол-во, шт',
      description: (
        <ul>
          <li>Кол-во непроданных билетов / Общее кол-во билетов</li>
          <li>Билет считается проданным, если заказ между партнерами завершен</li>
        </ul>
      ),
    },
    {
      title: 'Номинальная цена, ₽',
      description: 'Номинальная цена = Сумма номинальных цен билетов в заказе',
    },
    {
      title: 'Закупочная цена, ₽',
      description: (
        <ul>
          <li>Закупочная цена = Сумма закупочных цен билетов в заказе</li>
          <li>Закупочная цена билета = Цена покупки билета у партнера</li>
        </ul>
      ),
    },
    {
      title: 'Остаток, ₽',
      description: (
        <ul>
          <li>Остаток = Сумма Остатков заказов</li>
          <li>Остаток заказа = Сумма цен непроданных билетов</li>
        </ul>
      ),
    },
    {
      title: 'Доход, ₽',
      description: (
        <ul>
          <li>Доход = Сумма Доходов заказов</li>
          <li>Доход заказа = Сумма цен проданных билетов заказа с учетом скидок/наценок</li>
        </ul>
      ),
    },
    {
      title: 'Прибыль, ₽',
      description: (
        <>
          <div>Прибыль = Сумма прибылей заказов</div>
          <div>
            Прибыль заказа = Доход заказа - Сумма закупочных цен билетов заказа - Доход заказа * Операционные
            расходы
          </div>
          <div>Закупочная цена билета = Цена покупки билета у партнера</div>
        </>
      ),
    },
    {
      title: 'Операционные расходы берутся из конфигуратора Операционных расходов, если не указаны, то:',
      description: (
        <ul>
          <li>- для наличного расчета по-умолчанию = 0%</li>
          <li>- для безналичного расчета по-умолчанию = 10%</li>
        </ul>
      ),
    },
  ],
  [REPORTS.DELIVERY]: [
    {
      description: 'Отчет формируется по заказам с выполненной доставкой.',
    },
    {
      title: 'Стоимость доставки, ₽',
      description: 'Стоимость доставки = Сумма доставок заказов',
    },
  ],
  [REPORTS.SALES]: [
    {
      title: 'Отчет формируется по заказам в статусе «Завершено», в одном из выбранных разрезов:',
      description: (
        <ul>
          <li>1. В разрезе мероприятий</li>
          <li>2. В разрезе менеджеров</li>
          <li>3. В разрезе площадок</li>
        </ul>
      ),
    },
    {
      title: 'Кол-во билетов, шт:',
      description: (
        <ul>
          <li>
            Кол-во проданных билетов (Кол-во билетов нашей компании в наличии / Кол-во билетов нашей компании
            в предзаказе / Кол-во билетов с парсеров / Кол-во билетов партнеров)
          </li>
        </ul>
      ),
    },
    {
      title: 'Закупочная цена, ₽',
      description: (
        <>
          <div>Закупочная цена = Сумма закупочных цен билетов в заказе</div>
          <div>Закупочная цена билета:</div>
          <ul>
            <li>1) Закупочная цена из объявления, если билет принадлежит нашей компании.</li>
            <li>2) Цена покупки билета у партнера, если мы купили билет у партнера.</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Продажа (нал), ₽',
      description: (
        <>
          <div>Формируется по заказам с методом оплаты - Наличный расчет</div>
          <div>Продажа (нал) = Сумма Цен заказов с учетом скидок</div>
        </>
      ),
    },
    {
      title: 'Продажа (б/н), ₽',
      description: (
        <>
          <div>Формируется по заказам с методом оплаты - Безналичный расчет</div>
          <div>Продажа (б/н) = Сумма Цен заказов с учетом скидок</div>
        </>
      ),
    },
    {
      title: 'Прибыль, ₽',
      description: (
        <ul>
          <li>Формируется по заказам в статусе «Завершено».</li>
          <li>Прибыль = Сумма прибылей по заказам</li>
          <li>
            Прибыль по заказу = Цена заказа с учетом скидок/наценок - Сумма закупочных цен билетов в заказе -
            Цена заказа с учетом скидок/наценок * Операционные расходы
          </li>
        </ul>
      ),
    },
    {
      title: 'Закупочная цена билета:',
      description: (
        <ul>
          <li>1) Закупочная цена из объявления, если билет принадлежит нашей компании.</li>
          <li>2) Цена покупки билета у партнера, если мы купили билет у партнера.</li>
        </ul>
      ),
    },
    {
      title: 'Операционные расходы берутся из конфигуратора Операционных расходов, если не указаны, то:',
      description: (
        <ul>
          <li>- для наличного расчета по-умолчанию = 0%</li>
          <li>- для безналичного расчета по-умолчанию = 10%</li>
        </ul>
      ),
    },
    {
      title: 'Выручка после возврата, ₽',
      description: (
        <>
          <div>Выручка после возврата = Сумма выручек после возврата заказов</div>
          <div>Выручка после возврата заказа = Цена заказа с учетом скидок - Сумма возврата</div>
        </>
      ),
    },
    {
      title: 'Итого, ₽',
      description: 'Итого = Прибыль + Выручка',
    },
    {
      title: 'Доп. расходы, ₽',
      description: 'Доп. расходы = Сумма "Доп. расходов" заказов',
    },
  ],
  [`${REPORTS.SALES}_GRAPH`]: (
    <div>
      Прибыль, ₽
      <ul>
        <li>Формируется по заказам в статусе «Завершено».</li>
        <li>Прибыль = Сумма прибылей по заказам</li>
        <li>
          Прибыль по заказу = Цена заказа с учетом скидок/наценок - Сумма закупочных цен билетов в заказе -
          Цена заказа с учетом скидок/наценок * Операционные расходы
        </li>
      </ul>
      Закупочная цена билета:
      <ul>
        <li>1) Закупочная цена из объявления, если билет принадлежит нашей компании.</li>
        <li>2) Цена покупки билета у партнера, если мы купили билет у партнера.</li>
      </ul>
      Операционные расходы берутся из конфигуратора Операционных расходов, если не указаны, то:
      <ul>
        <li>- для наличного расчета по-умолчанию = 0%</li>
        <li>- для безналичного расчета по-умолчанию = 10%</li>
      </ul>
    </div>
  ),
  [REPORTS.EXPIRED]: [
    {
      title: 'Отчет формируется по билетам из объявлений, которые удовлетворят следующим требованиям:',
      description: (
        <ul>
          <li>1. Статус билета «Гарь».</li>
          <li>2. Статус объявления «В продаже».</li>
          <li>3. В отчет не попадают объявления в статусе «Стекло».</li>
        </ul>
      ),
    },
    {
      title: 'Номинальная цена, ₽',
      description: 'Номинальная цена = Сумма номинальных цен объявлений',
    },
    {
      title: 'Закупочная цена, ₽',
      description: 'Закупочная цена = Сумма закупочных цен объявлений',
    },
  ],
  [REPORTS.EVENT]: EVENT_DESCRIPTION,
  [REPORTS.EVENT_FRAGMENT]: EVENT_DESCRIPTION,
  [REPORTS.REJECT]: [
    {
      description: 'Отчет формируется по заказам в статусе «Отмена».',
    },
    {
      description:
        'Чтобы получить актуальные данные в отчете дата заказа берется из истории заказа, а не из даты создания заказа. Пример: Заказ создан два месяца назад, а сегодня сделали отмену. Заказ в статусе отмена попадет в отчет за сегодняшнее число.',
    },
    {
      title: 'Кол-во заказов, шт',
      description: (
        <>
          <div>Общее кол-во отмененных заказов, учитываются все причины отмены, кроме:</div>
          <ul>
            <li>1) Тестовый заказ</li>
            <li>2) Фейк</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Отмена, ₽',
      description: 'Отмена = Сумма цен отмененных заказов с учетом скидок/наценок',
    },
  ],
  [`${REPORTS.REJECT}_GRAPH`]: (
    <div>
      Отмена, ₽ <br />
      Отмена = Сумма цен отмененных заказов с учетом скидок/наценок
    </div>
  ),
  [REPORTS.PURCHASE_B2C]: [
    {
      title:
        'Отчет по закупкам  формируется по билетам из объявлений компании, которые удовлетворят следующим требованиям:',
      description: (
        <ul>
          <li>- Тип объявления «В продаже».</li>
          <li>- В отчет не попадают объявления в статусе «Стекло».</li>
          <li>- В отчет не попадают билеты в статусах «Удален» и «Возвращен».</li>
        </ul>
      ),
    },
    {
      title: 'Отчет формируется в разрезе поставщиков за выбранную дату.',
    },
    {
      title: 'Кол-во, шт',
      description: 'Общее кол-во билетов из объявлений',
    },
    {
      title: 'Номинальная цена, ₽',
      description: 'Номинальная цена = Сумма номинальных цен объявлений',
    },
    {
      title: 'Закупочная цена, ₽',
      description: 'Закупочная цена = Сумма закупочных цен объявлений',
    },
  ],
};
/* eslint-enable */

export default REPORTS;
