import { Checkbox } from 'components/FormControls';
import { Table } from 'components/Table';
import Tooltip from 'components/Tooltip';
import includes from 'lodash/includes';
import map from 'lodash/map';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { selectRow, selectAllRows } from 'entities/tables';
import BULK_ERRORS from 'shared/constants/BULK_ERRORS';
import TABLES_ROW_ACTIONS from 'shared/constants/TABLES_ROW_ACTIONS';
import convertToCamelCase, { POSTFIXS } from 'shared/helpers/convertToCamelCase';
import preventClick from 'shared/helpers/preventClick';
import Can from '../Can';

interface SelectorProps {
  cellProps: any;
  permission?: string | string[];
  page: string;
  ids: (string | number)[];
  selectRow: (data: any) => any;
  tablesSelectedRows: any;
  id: string | number;
  plain?: boolean;
  legacyFormat?: boolean;
  useNewLayout?: boolean;
  switchToTablet?: boolean;
  dataSelenium?: string;
}

const TableRowSelector: React.FC<SelectorProps> = (props) => {
  const {
    id: originalId,
    ids: originalIds,
    page,
    tablesSelectedRows,
    cellProps,
    plain,
    legacyFormat,
    permission,
    useNewLayout,
    switchToTablet,
    dataSelenium,
  } = props;

  const name = convertToCamelCase({ str: page, postfix: POSTFIXS.TABLE_SELECTED_ROWS });
  const id = originalId && originalId.toString();
  const ids = originalIds && originalIds.map((item) => item.toString());
  const checkedIds = map(tablesSelectedRows[name].ids, (item) => item.toString());
  const { errors } = tablesSelectedRows[name];
  const errorsIds = map(errors, (_, index) => index.toString());

  const handleChange = (event) => {
    const { value } = event.target;

    props.selectRow({
      value,
      name,
      rowsCount: ids.length,
      rowAction: TABLES_ROW_ACTIONS.SELECT,
    });
  };

  const isError = legacyFormat ? includes(errors, id) : includes(errorsIds, id);
  const isChecked = includes(checkedIds, id);
  const renderErrors = (data) =>
    map(data, (item) => (
      <Fragment key={item}>
        {BULK_ERRORS[page][item]}
        <br />
      </Fragment>
    ));

  if (plain) {
    return (
      <Checkbox
        variant="gray"
        onChange={handleChange}
        plain={useNewLayout}
        value={id}
        checked={isChecked}
        name={dataSelenium}
      />
    );
  }

  const content = (
    <Tooltip text={isError && !legacyFormat && renderErrors(errors[id].errors)} placement="right" isMedium>
      <div>
        <Checkbox
          variant="gray"
          onChange={handleChange}
          plain={useNewLayout}
          value={id}
          checked={isChecked}
          name={dataSelenium}
        />
      </div>
    </Tooltip>
  );

  return useNewLayout ? (
    <Table.CheckboxCell
      {...cellProps}
      isError={isError}
      onClick={preventClick}
      switchToTablet={switchToTablet}
    >
      {permission ? <Can permission={permission}>{content}</Can> : content}
    </Table.CheckboxCell>
  ) : (
    <Table.CheckboxCell
      {...cellProps}
      isError={isError}
      onClick={preventClick}
      switchToTablet={switchToTablet}
    >
      {permission ? <Can permission={permission}>{content}</Can> : content}
    </Table.CheckboxCell>
  );
};

const mapStateToProps = (state) => ({
  tablesSelectedRows: state.tables.selectedRows,
});

const mapDispatchToProps = { selectRow, selectAllRows };

export default connect(mapStateToProps, mapDispatchToProps)(TableRowSelector);
