import { isEmpty } from 'lodash';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import CURRENCY_CHAR_CODES from 'shared/constants/CURRENCY_CHAR_CODES';
import ROUND_RULES from 'shared/constants/ROUND_RULES';
import { SourcesIds } from 'shared/constants/SOURCES';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { WIDGET_DISPLAY_TYPES_OPTIONS } from 'shared/constants/WIDGET';
import { required } from 'shared/helpers/validations';
import pricesSources from 'shared/sources/prices';
import markupSources from 'shared/sources/reference/markup';
import Button from '../Button';
import Form from '../Form';
import { Checkbox, InputWithSelect, NumberInput, RadioGroup, Select } from '../FormControls';
import Modal from '../Modal';
import EventsLayout from '../Pages/Events/EventsLayout';
import Spinner from '../Spinner';
import Tabs from '../Tabs';
import EventPricesDates from './EventPricesDates';
import Styled from './styled';

const sortEventDates = (eventDates) => {
  if (!eventDates) return eventDates;
  return sortBy(eventDates, (item) => moment(item.label, 'DD MMM YYYY, HH:mm', 'ru').toDate());
};

const renderMarkup = (response) => {
  return response.data.results.map((i) => ({
    value: i.id,
    id: i.id,
    label: i.title,
  }));
};

const NEW_TAB_ID = 'NEW_TAB';

interface StepProps {
  eventSourceId: number;
  eventDates: any[];
  goBack: () => void;
  goNext: () => void;
  rubCurrency?: boolean;
  marketMarkupsFilter?: boolean;
}

const EventPrices: React.FC<StepProps> = ({
  eventSourceId,
  eventDates,
  goBack,
  goNext,
  rubCurrency,
  marketMarkupsFilter,
}) => {
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedTab, setSelectedTab] = useState(null);
  const [prices, setPrices] = useState({
    tabs: [],
    data: null,
    loading: true,
  });
  const [roundingsItems, setRoundingsItems] = useState(null);
  const [roundRules, setRoundRules] = useState([]);
  const [datesLoading, setDatesLoading] = useState(true);
  const initialValues: any = useRef();

  const [selectedRules, setSelectedRules] = useState({});

  const handleChangeRoundRules = async (rounding, value) => {
    setRoundRules([...roundRules, { optionValue: value.target.value, rounding }]);

    setSelectedRules((prevState) => ({
      ...prevState,
      [rounding.char_code]: value.target.value,
    }));
  };

  const loadDates = async (priceId, eventPrices = prices.data) => {
    setDatesLoading(true);
    const selectedPrice = eventPrices.find((price) => price.id === priceId);
    const dates = await pricesSources.getEventPriceDates(eventSourceId, selectedPrice.id);
    const priceSelectedDates = dates.reduce(
      (acc, { display_type, is_table, leftover_threshold, event_date_id, use_announcements }) => {
        acc[event_date_id] = true;

        initialValues.current = {
          is_table,
          display_type,
          use_announcements,
          leftover_threshold,
          is_default: selectedPrice.is_default,
          price: {
            value: selectedPrice.id,
            label: selectedPrice.title,
          },
        };
        return acc;
      },
      {},
    );

    setSelectedDates(priceSelectedDates);
    setDatesLoading(false);
  };

  const loadPrices = async () => {
    if (eventSourceId) {
      const eventPrices = await pricesSources.getEventPrices(eventSourceId);

      if (eventPrices.length) {
        const selectedPrice: any = head(eventPrices);

        await loadDates(selectedPrice.id, eventPrices);

        setPrices({
          data: eventPrices,
          tabs: eventPrices.map((price) => ({
            ...price,
            key: price.id,
            text: price.title,
          })),
          loading: false,
        });
        setSelectedTab(selectedPrice.id);
      }
    }
  };

  const loadRoundData = async () => {
    await markupSources
      .roundingsItems({
        sourceID: rubCurrency ? SourcesIds.marketplace : SourcesIds.landings,
        event_source_id: eventSourceId,
      })
      .then((response) => {
        if (rubCurrency) {
          const rubRound = response.rounds.filter((round) => round.char_code === CURRENCY_CHAR_CODES.RUB);

          return setRoundingsItems(rubRound);
        }

        return setRoundingsItems(response.rounds);
      });
  };

  useEffect(() => {
    loadPrices();
    loadRoundData();
  }, [eventSourceId]);

  const addDate = (value) => {
    const { value: id } = value;

    setSelectedDates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const selectAllDates = () => {
    const allEventDates = eventDates.reduce((acc, date) => {
      acc[date.value] = true;

      return acc;
    }, {});

    setSelectedDates(allEventDates);
  };

  const addTab = () => {
    const isExist = prices.tabs.find((tab) => tab.key === NEW_TAB_ID);

    if (isExist) {
      setSelectedTab(NEW_TAB_ID);
      setSelectedDates({});
      return;
    }

    setPrices((prevState) => ({
      ...prevState,
      tabs: [
        ...prevState.tabs,
        {
          text: 'Новая наценка',
          key: NEW_TAB_ID,
        },
      ],
    }));
    setSelectedTab(NEW_TAB_ID);
    setSelectedDates({});
  };

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    if (tab === NEW_TAB_ID) setSelectedDates({});
    if (tab !== NEW_TAB_ID) loadDates(tab);
  };

  const submit = async (submitData) => {
    const submitedDates = Object.keys(selectedDates).map((dateId) => {
      return {
        event_date_id: Number(dateId),
        display_type: submitData.display_type,
        is_table: submitData.is_table,
        use_announcements: submitData.use_announcements,
        leftover_threshold: submitData.leftover_threshold,
      };
    });

    if (submitData.is_default) {
      await pricesSources.setDefaultPrice(eventSourceId, submitData.price.value);
      loadPrices();
    }

    if (!isEmpty(roundRules)) {
      try {
        await Promise.all(
          roundRules.map(async (roundRule) => {
            if (roundRule.rounding.round_scope_type === ROUND_RULES.ROUND_SCOPE_TYPE.EVENT_SOURCE) {
              await markupSources.updateRoundItem({
                char_code: roundRule.rounding.char_code,
                round_type: roundRule.optionValue,
                markup_round_id: roundRule.rounding.id,
              });
            } else {
              await markupSources.createRoundItem({
                source_id: rubCurrency ? SourcesIds.marketplace : SourcesIds.landings,
                char_code: roundRule.rounding.char_code,
                round_type: roundRule.optionValue,
                event_source_id: eventSourceId,
              });
            }

            setRoundRules([]);
          }),
        );
      } catch (error) {
        NotificationManager.error('Ошибка при попытке обновления правил округления.');
        setRoundRules([]);
      }
    }

    await pricesSources.bindPricesToEvent(eventSourceId, submitData.price.value, submitedDates);

    if (selectedTab === NEW_TAB_ID || submitedDates.length === eventDates?.length) {
      loadPrices();
    }
    loadRoundData();
    if (submitData.submit_action === SUBMIT_ACTIONS.CONTINUE) {
      goNext();
    }
  };

  const datesValue = eventDates?.reduce((acc, date) => {
    if (selectedDates[date.value]) {
      acc.push(date);
    }

    return acc;
  }, []);

  if (prices.loading) {
    return (
      <EventsLayout.Wrapper>
        <Styled.Container>
          <Spinner />
        </Styled.Container>
      </EventsLayout.Wrapper>
    );
  }

  return (
    // <EventsLayout.Wrapper>
    <Styled.Container withDates={!!eventDates}>
      <Styled.SelectWrapper>
        {roundingsItems?.map((round) => {
          return (
            <Styled.RoundSelect>
              <Select
                small
                key={round.id}
                label={round.char_code}
                value={selectedRules[round.char_code] || round.round_type}
                options={ROUND_RULES.ROUND_RULES_OPTIONS}
                onChange={(value) => handleChangeRoundRules(round, value)}
              />
            </Styled.RoundSelect>
          );
        })}
      </Styled.SelectWrapper>
      {prices.tabs.length ? (
        <Tabs tabs={prices.tabs} onSelect={handleSelectTab} selectedTab={selectedTab} highlighted>
          {datesLoading ? (
            <Spinner />
          ) : (
            <Form
              onSubmit={submit}
              initialValues={
                selectedTab === NEW_TAB_ID
                  ? {
                      display_type: 10,
                      is_table: false,
                      is_default: false,
                      use_announcements: false,
                      leftover_threshold: 0,
                    }
                  : initialValues.current
              }
              render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit}>
                  {eventDates && eventDates.length > 1 && (
                    <Styled.AddMarkup>
                      <Button type="button" transparent onClick={addTab}>
                        Добавить наценку
                      </Button>
                    </Styled.AddMarkup>
                  )}
                  <Styled.Row>
                    <Styled.Column>
                      <Styled.Select>
                        Наценка
                        <Field name="price" validate={required}>
                          {({ input, meta }) => (
                            <InputWithSelect
                              isAsync
                              route={markupSources}
                              optionsRenderer={renderMarkup}
                              {...input}
                              meta={meta}
                              searchQueryName="search_string"
                            />
                          )}
                        </Field>
                      </Styled.Select>
                      <Field name="is_default" type="checkbox">
                        {({ input, meta }) => (
                          <Styled.IsTableCheckbox>
                            <Checkbox variant="round" meta={meta} {...input}>
                              Дефолтная наценка
                            </Checkbox>
                          </Styled.IsTableCheckbox>
                        )}
                      </Field>
                      <Styled.DisplayType>
                        Форма продажи
                        <Styled.DisplayTypeDescription>
                          Продажа билетов по местам или по категориям
                        </Styled.DisplayTypeDescription>
                        <Field name="display_type">
                          {({ input, meta }) => (
                            <RadioGroup
                              isNumber
                              buttons={WIDGET_DISPLAY_TYPES_OPTIONS}
                              {...input}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </Styled.DisplayType>
                      <Field name="is_table" type="checkbox">
                        {({ input, meta }) => (
                          <Styled.IsTableCheckbox>
                            <Checkbox variant="round" meta={meta} {...input}>
                              Таблица
                            </Checkbox>
                          </Styled.IsTableCheckbox>
                        )}
                      </Field>
                      <Styled.IsTableCheckbox>
                        <Field name="use_announcements" type="checkbox">
                          {({ input, meta }) => (
                            <Checkbox variant="round" meta={meta} {...input}>
                              Показывать меню объявлений
                            </Checkbox>
                          )}
                        </Field>
                      </Styled.IsTableCheckbox>
                      <Styled.DisplayType>
                        Остатки
                        <Styled.DisplayTypeDescription>
                          При каком % билетов выделять сектор
                        </Styled.DisplayTypeDescription>
                        <Styled.LeftoverThreshold>
                          <Field name="leftover_threshold" type="number">
                            {({ input, meta }) => <NumberInput min={0} max={100} meta={meta} {...input} />}
                          </Field>
                        </Styled.LeftoverThreshold>
                      </Styled.DisplayType>
                    </Styled.Column>

                    <Styled.Column>
                      {eventDates && eventDates.length !== 1 && (
                        <>
                          <Styled.DatesSelect>
                            <Styled.Select>
                              Выбор даты
                              <InputWithSelect
                                hideSelectedOptions
                                closeMenuOnSelect={false}
                                controlShouldRenderValue={false}
                                isClearable={false}
                                options={sortEventDates(eventDates)}
                                onChange={addDate}
                                value={datesValue}
                                meta={{}}
                              />
                            </Styled.Select>
                            <Button type="button" transparent onClick={selectAllDates}>
                              Выбрать все даты
                            </Button>
                          </Styled.DatesSelect>
                          <EventPricesDates eventDates={eventDates} selectedDates={selectedDates} />
                        </>
                      )}
                    </Styled.Column>
                  </Styled.Row>
                  <Styled.FooterWrapper marketMarkupsFilter={marketMarkupsFilter}>
                    <Modal.Footer fullSize justify>
                      <Button transparent type="button" onClick={goBack} data-selenium="button-back">
                        Назад
                      </Button>
                      <Button
                        gray
                        type="submit"
                        onClick={() => form.change('submit_action', SUBMIT_ACTIONS.CREATE)}
                        data-selenium="button-save"
                      >
                        Сохранить
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => form.change('submit_action', SUBMIT_ACTIONS.CONTINUE)}
                        data-selenium="button-continue"
                      >
                        Продолжить
                      </Button>
                    </Modal.Footer>
                  </Styled.FooterWrapper>
                </form>
              )}
            />
          )}
        </Tabs>
      ) : (
        <div>Наценки на данное мероприятие не найдено</div>
      )}
    </Styled.Container>
  );
};

export default EventPrices;
