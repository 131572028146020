import styled, { createGlobalStyle, css } from 'styled-components';
import { rgba } from 'polished';
import Button from '../../../Button';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Global: createGlobalStyle`
    .cropper__body {
      padding: 0;
    }
  `,

  Overlay: styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.8);
  `,

  Container: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1002;
    background: #fff;
    border-radius: 8px;
  `,

  Cropper: styled.div`
    position: absolute;
    top: 72px;
    bottom: 72px;
    left: 0;
    right: 0;

    img {
      display: block;
      max-width: 100%;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      top: 89px;
      bottom: 112px;
    }
  `,

  Buttons: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 40px;
    box-sizing: border-box;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding: 16px;
    }
  `,

  Actions: styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    width: 240px;
    transform: translateY(-50%);

    @media ${MEDIA_QUERIES.MOBILE} {
      top: 8px;
      transform: none;
    }
  `,

  SubmitButtons: styled.div`
    display: flex;
    margin-left: auto;

    button:not(:last-child) {
      margin-right: 8px;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      margin: 40px auto 0;
    }
  `,

  RedoButton: styled(Button)`
    svg {
      transform: scaleX(-1);
    }
  `,

  PreviewDefault: styled.label`
    max-width: 350px;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  `,

  Preview: styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
  `,

  PreviewItem: styled.li`
    &:not(:last-child) {
      margin-right: 16px;
    }
  `,

  PreviewType: styled.div`
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.64);
  `,

  PreviewImage: styled.img<any>`
    border-radius: 4px;
    max-width: 81px;

    @media ${MEDIA_QUERIES.MOBILE} {
      max-width: 12vw;
    }

    ${(props) =>
      props.size === 'lg' &&
      css`
        max-width: 284px;

        @media ${MEDIA_QUERIES.MOBILE} {
          max-width: 40vw;
        }
      `}

    ${(props) =>
      props.size === 'md' &&
      css`
        max-width: 149px;

        @media ${MEDIA_QUERIES.MOBILE} {
          max-width: 22vw;
        }
      `}
  `,

  EditButtons: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  `,

  Edit: styled.button`
    padding: 6px 8px;
    margin-right: 8px;
    color: #2f80ed;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: 0;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: ${rgba('#2f80ed', 0.1)};
    }
  `,

  Download: styled.a`
    padding: 6px 8px;
    margin-right: 8px;
    color: #2f80ed;
    font-size: 14px;
    background: transparent;
    text-decoration: none;
    outline: none;
    border: 0;
    border-radius: 8px;

    &:hover {
      background: ${rgba('#2f80ed', 0.1)};
    }
  `,

  Delete: styled.button`
    padding: 6px 8px;
    color: #eb5757;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: 0;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: ${rgba('#eb5757', 0.1)};
    }
  `,
};
