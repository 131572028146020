import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  inTable?: boolean;
}

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,

  Info: styled.div<CSSProps>`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #5c5c5c;

    @media (max-width: ${BREAKPOINTS.XL}) {
      flex-direction: column;
      align-items: flex-start;
      color: #000;

      ${({ inTable }) =>
        inTable &&
        css`
          flex-direction: row;
          align-items: center;
          line-height: 16px;
          opacity: 0.64;
        `}
    }
  `,

  Dot: styled.div<CSSProps>`
    margin: 0 4px;
    width: 3px;
    height: 3px;
    flex-shrink: 0;
    background-color: #5c5c5c;
    border-radius: 50%;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: ${({ inTable }) => !inTable && 'none'};
    }
  `,

  Name: styled.div<CSSProps>`
    @media (max-width: ${BREAKPOINTS.XL}) {
      margin-bottom: 4px;
      font-weight: 700;
      font-size: 18px;

      ${({ inTable }) =>
        inTable &&
        css`
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
        `}
    }
  `,

  Link: styled.a`
    display: flex;
    align-items: center;

    & > span {
      position: relative;
      display: block;
      color: #000;
      max-width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & svg {
      opacity: 0.8;
      width: 11px;
      height: 11px;
      margin: 1px 0 0 8px;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      & svg {
        opacity: 1;
      }
    }
  `,

  Time: styled.div<CSSProps>`
    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
      margin-bottom: ${({ inTable }) => !inTable && '4px'};

      & > div:not(:last-child) {
        margin-right: 5px;

        &:after {
          content: ',';
        }
      }
    }
  `,

  MobileContainer: styled.div`
    display: none;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: block;
    }
  `,

  DesktopContainer: styled.div`
    display: block;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  Icon: styled.div`
    display: flex;
    align-items: center;
  `,

  PeriodicalDates: styled.div`
    display: none;
    margin-left: 4px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: inline-block;
    }
  `,
};
