import styled, { createGlobalStyle } from 'styled-components';
import { smallModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
};
