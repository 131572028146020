import React from 'react';
import omit from 'lodash/omit';
import Styled from './styles';
import Checkbox from '../Checkbox';
import preventClick from 'shared/helpers/preventClick';

const SELECT_TYPES = {
  SINGLE: 'single',
  MULTI: 'multi',
};

interface Props {
  data: any;
  setValue: (value: any) => void;
  isFocused: boolean;
  isSelected: boolean;
  innerProps: any;
  selectProps: any;
  options: any[];
  value: string | number;
  children: any;
}

const OptionWithCheckboxAndTypes: React.FC<Props> = (props) => {
  const { children, innerProps, isSelected, isFocused, options, value, setValue, data } = props;
  const { withExceptOption } = props.selectProps;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const all = options.reduce((acc, option) => {
      if (option.options) {
        const innerOptions = option.options.filter((innerOption) => innerOption.value !== value);

        acc.push(...innerOptions);
      } else if (option.value !== value) {
        acc.push(option);
      }

      return acc;
    }, []);
    setValue(all);
  };

  const handleOptionClick = (event, type) => {
    event.stopPropagation();
    event.preventDefault();

    if (type === SELECT_TYPES.SINGLE && data.handleClick) {
      data.handleClick([{ value, label: children }]);
    } else {
      innerProps.onClick();
    }
  };

  return (
    <Styled.Option isFocused={isFocused} data-selenium={children}>
      <Styled.OptionCheckbox
        onClick={(event) => handleOptionClick(event, SELECT_TYPES.MULTI)}
        onTouchEnd={preventClick}
        {...omit(innerProps, ['onMouseMove', 'onMouseOver', 'onClick'])}
      >
        <Checkbox plain checked={isSelected} readOnly />
      </Styled.OptionCheckbox>
      <Styled.OptionName onClick={(event) => handleOptionClick(event, SELECT_TYPES.SINGLE)}>
        {children}
      </Styled.OptionName>
      {withExceptOption && (
        <Styled.OptionAllExceptCurrent onClick={handleClick}>Все кроме</Styled.OptionAllExceptCurrent>
      )}
    </Styled.Option>
  );
};

export default OptionWithCheckboxAndTypes;
