import React from 'react';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { SmallFormDescription, Checkbox } from '../../../FormControls';

const ITEMS = Array.from({ length: 2 }, (v, i) => i + 1);

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  data: any;
}

const TabsStep: React.FC<StepProps> = ({ data, onSubmit, updateData }) => (
  <Styled.Container>
    <Styled.Header>
      <Styled.Title>Выберите дизайн Табов</Styled.Title>
    </Styled.Header>
    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.List>
              {ITEMS.map((i) => (
                <Styled.Item key={i} data-selenium={`variant-${i}`}>
                  <Variant name="ui_config_tabs" value={i}>
                    <img src={`/static/constructor/tabs/${i}.jpg`} alt={`${i} вариант`} />
                  </Variant>
                </Styled.Item>
              ))}
            </Styled.List>
            <Styled.SubTitle>Настройка блока</Styled.SubTitle>
            <Styled.CheckboxContainer>
              <Field name="ui_config_show_locations_tabs" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox variant="round" meta={meta} {...input}>
                    Показ табов на главной
                  </Checkbox>
                )}
              </Field>
              <SmallFormDescription>Выводить площадки из фильтров в табы на сайте</SmallFormDescription>
            </Styled.CheckboxContainer>
            <ConstructorFooter form={form} updateData={updateData} />
          </form>
        )}
      />
    </Styled.Content>
  </Styled.Container>
);

export default TabsStep;
