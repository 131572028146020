export default {
  NEW_COMMENT: 1,

  ORDER_REPORT_CREATE_SUCCESS: 3,
  ORDER_REPORT_CREATE_FAIL: 4,

  ORDER_UPDATE_STATUS: 6,
  ORDER_UPDATE_MANAGER: 7,
  ADD_NEW_ORDER: 8,
  ORDER_PAYMENT: 13,

  INVOCE_CHANGED: 12,

  BAN: 14,

  NOTIFICATION: 15,

  UNREAD_MESSAGES: 'UNREAD_MESSAGES',
};
