import React, { Component } from 'react';
import map from 'lodash/map';
import includes from 'lodash/includes';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import Styled from './styles';
import Spinner from '../../Spinner';
import Modal from '../../Modal';
import Button from '../../Button';
import { ANNOUNCEMENT_HISTORY_TYPE } from 'shared/constants/ANNOUNCEMENT';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import AnnouncementHistoryModalCreate from './AnnouncementHistoryModalCreate';
import AnnouncementHistoryModalMeta from './AnnouncementHistoryModalMeta';
import AnnouncementHistoryModalPriceChanged from './AnnouncementHistoryModalPriceChanged';
import AnnouncementHistoryModalChangeStatus from './AnnouncementHistoryModalChangeStatus';
import AnnouncementHistoryModalTicketSell from './AnnouncementHistoryModalTicketSell';
import AnnouncementHistoryModalTicketSold from './AnnouncementHistoryModalTicketSold';
import AnnouncementHistoryModalProviderChange from './AnnouncementHistoryModalProviderChange';
import AnnouncementHistoryModalTypeChange from './AnnouncementHistoryModalTypeChange';
import AnnouncementHistoryModalExpansionAdd from './AnnouncementHistoryModalExpansionAdd';
import AnnouncementHistoryModalExpansionDelete from './AnnouncementHistoryModalExpansionDelete';
import AnnouncementHistoryModalRefund from './AnnouncementHistoryModalRefund';
import AnnouncementHistoryModalDeleteTickets from './AnnouncementHistoryModalDeleteTickets';
import AnnouncementHistoryModalAddTickets from './AnnouncementHistoryModalAddTickets';
import AnnouncementHistoryModalTransferTo from './AnnouncementHistoryModalTransferTo';
import AnnouncementHistoryModalTransferFrom from './AnnouncementHistoryModalTransferFrom';
import { ModalFunctions } from '../../../../interfaces/modal';
import { IHistory } from '../../../../interfaces/historyModal';

const ANNOUNCEMENT_HISTORY_COLORS = {
  [ANNOUNCEMENT_HISTORY_TYPE.CREATE]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.META]: '#B186D3',
  [ANNOUNCEMENT_HISTORY_TYPE.PRICE_CHANGED]: '#38D680',
  [ANNOUNCEMENT_HISTORY_TYPE.CHANGE_STATUS]: '#2F80ED',
  [ANNOUNCEMENT_HISTORY_TYPE.TICKET_SELL]: '#F09E00',
  [ANNOUNCEMENT_HISTORY_TYPE.TICKET_SOLD]: '#65BCE1',
  [ANNOUNCEMENT_HISTORY_TYPE.PROVIDER_CHANGE]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.TYPE_CHANGE]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.EXPANSION_ADD]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.EXPANSION_DELETE]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.REFUND]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.DELETE_TICKETS]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.ADD_TICKETS]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.TRANSFER_TO]: '#000',
  [ANNOUNCEMENT_HISTORY_TYPE.TRANSFER_FROM]: '#000',
};

const HistoryComponent = {
  [ANNOUNCEMENT_HISTORY_TYPE.CREATE]: AnnouncementHistoryModalCreate,
  [ANNOUNCEMENT_HISTORY_TYPE.META]: AnnouncementHistoryModalMeta,
  [ANNOUNCEMENT_HISTORY_TYPE.PRICE_CHANGED]: AnnouncementHistoryModalPriceChanged,
  [ANNOUNCEMENT_HISTORY_TYPE.CHANGE_STATUS]: AnnouncementHistoryModalChangeStatus,
  [ANNOUNCEMENT_HISTORY_TYPE.TICKET_SELL]: AnnouncementHistoryModalTicketSell,
  [ANNOUNCEMENT_HISTORY_TYPE.TICKET_SOLD]: AnnouncementHistoryModalTicketSold,
  [ANNOUNCEMENT_HISTORY_TYPE.PROVIDER_CHANGE]: AnnouncementHistoryModalProviderChange,
  [ANNOUNCEMENT_HISTORY_TYPE.TYPE_CHANGE]: AnnouncementHistoryModalTypeChange,
  [ANNOUNCEMENT_HISTORY_TYPE.EXPANSION_ADD]: AnnouncementHistoryModalExpansionAdd,
  [ANNOUNCEMENT_HISTORY_TYPE.EXPANSION_DELETE]: AnnouncementHistoryModalExpansionDelete,
  [ANNOUNCEMENT_HISTORY_TYPE.EXPANSION_DELETE]: AnnouncementHistoryModalExpansionDelete,
  [ANNOUNCEMENT_HISTORY_TYPE.REFUND]: AnnouncementHistoryModalRefund,
  [ANNOUNCEMENT_HISTORY_TYPE.DELETE_TICKETS]: AnnouncementHistoryModalDeleteTickets,
  [ANNOUNCEMENT_HISTORY_TYPE.ADD_TICKETS]: AnnouncementHistoryModalAddTickets,
  [ANNOUNCEMENT_HISTORY_TYPE.TRANSFER_TO]: AnnouncementHistoryModalTransferTo,
  [ANNOUNCEMENT_HISTORY_TYPE.TRANSFER_FROM]: AnnouncementHistoryModalTransferFrom,
};

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  loadHistory: (id: string | number, params?: any) => any;
}

interface ModalState {
  meta: any;
  data: IHistory[];
  isError: boolean;
  isLoading: boolean;
  modalData: any;
}

class AnnouncementHistoryModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      meta: {},
      data: null,
      isError: false,
      isLoading: true,
      modalData: {},
    };
  }

  async componentDidMount() {
    const {
      data: { id },
      loadHistory,
      modalState,
    } = this.props;

    try {
      const data = await loadHistory(id);

      const { results, ...meta } = data.payload.data.data;

      this.setState({
        meta,
        data: results,
        isLoading: false,
        modalData: modalState,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  loadMore = async () => {
    const {
      data: { id },
      loadHistory,
    } = this.props;

    const data = await loadHistory(id, { page: Number(this.state.meta.page) + 1 });

    const { results, ...meta } = data.payload.data.data;

    this.setState((prevState) => ({
      meta,
      data: [...prevState.data, ...results],
    }));
  };

  render() {
    const { isLoading, isError, data, meta, modalData } = this.state;

    if (isLoading || isError) {
      return <Spinner center />;
    }

    if (data.length === 0) return <Styled.EmptyMessage>История отсуствует</Styled.EmptyMessage>;

    return (
      <Styled.Container>
        <Modal.Title>История объявления</Modal.Title>
        <Styled.List id="announcement_history_modal">
          <InfiniteScroll
            next={this.loadMore}
            dataLength={data.length}
            hasMore={meta.page !== meta.last_page}
            scrollableTarget="announcement_history_modal"
            loader={() => null}
          >
            {map(data, (item: IHistory) => {
              const { history_type: historyType, history, user, created } = item;

              if (
                !includes(
                  map(ANNOUNCEMENT_HISTORY_TYPE, (type) => type),
                  historyType,
                )
              )
                return null;

              const HistoryInfoComponent = HistoryComponent[historyType];

              return (
                <Styled.Item key={item.created}>
                  <Styled.Date>{moment.utc(created).format(DATE_FORMATS.DATE_TIME_PREVIEW)}</Styled.Date>
                  <Styled.Line className="announcement-history-modal__line">
                    <Styled.Indicator backgroundColor={ANNOUNCEMENT_HISTORY_COLORS[historyType]} />
                  </Styled.Line>
                  <Styled.Info>
                    <HistoryInfoComponent data={history} user={user} currency={modalData.currency.icon} />
                  </Styled.Info>
                </Styled.Item>
              );
            })}
          </InfiniteScroll>
        </Styled.List>
        <Modal.Footer>
          <Button onClick={this.props.closeModal}>Закрыть</Button>
        </Modal.Footer>
      </Styled.Container>
    );
  }
}

export default AnnouncementHistoryModal;
