import queryString from 'query-string';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import Router from 'next/router';
import reduce from 'lodash/reduce';

const queryFilter = (
  query: any,
  clearRemoved: boolean = false,
  route?: any,
  pathname: string = Router.router.pathname,
) => {
  const parsed = queryString.parse(window.location.search);

  Object.keys(query).forEach((key) => {
    const queryName = key;
    const queryValue = query[key];

    if (!queryValue && queryValue !== 0) {
      parsed[queryName] = undefined;
    } else {
      parsed[queryName] = queryValue;
    }
  });

  if (clearRemoved) {
    const removedQuery = difference(keys(parsed), keys(query));

    removedQuery.forEach((queryName) => {
      parsed[queryName] = undefined;
    });
  }

  let stringified = queryString.stringify(parsed);

  if (stringified !== '') {
    stringified = `?${stringified}`;
  }

  const routeQuery = reduce(
    parsed,
    (acc, value: any, key) => {
      if (value || value === 0) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );

  if (route) {
    Router.push(
      {
        pathname,
        query: routeQuery,
      },
      `${route}${stringified}`,
      { shallow: true },
    ).catch((err) => new Error(err));
  } else {
    Router.push(
      {
        pathname,
        query: routeQuery,
      },
      `${window.location.pathname}${stringified}`,
      { shallow: true },
    ).catch((err) => new Error(err));
  }
};

const replaceFilter = (query = {}, route = Router.route) => {
  let stringified = queryString.stringify(query);

  if (stringified !== '') {
    stringified = `?${stringified}`;
  }
  Router.push(`${route}${stringified}`, undefined, { shallow: true }).catch((err) => new Error(err));
};

const selectCol = ({ query, alternateSortQuery }) => {
  const parsed = queryString.parse(window.location.search);
  const defaultValue = 'asc';
  const secondValue = 'desc';
  const sortByKey = alternateSortQuery ? 'sort_by' : 'sortBy';
  const sortOnKey = alternateSortQuery ? 'sort_on' : 'sortOn';
  const queryName = parsed[sortByKey];
  const queryValue = parsed[sortOnKey];

  if (query !== queryName) {
    queryFilter({ [sortByKey]: query, [sortOnKey]: defaultValue });
  } else if (queryValue === secondValue) {
    queryFilter({ [sortByKey]: query, [sortOnKey]: defaultValue });
  } else if (queryValue === defaultValue) {
    queryFilter({ [sortByKey]: query, [sortOnKey]: secondValue });
  }
};

export { queryFilter, selectCol, replaceFilter };
