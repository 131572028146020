import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);
const route = `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places`;

export default {
  root: `${route}?language_code=ru`,
  detail: (id) => `${route}/${id}`,

  async createTranslation(id, data) {
    const response = await api.post(`${route}/${id}/info`, {
      title: data.title,
      address: data.address,
      description: data.description,
      annotation: data.annotation,
      language_code: data.language_code,
    });

    return response;
  },

  async updateTranslation(id, lang, data) {
    const response = await api.patch(`${route}/${id}/info/${lang}`, {
      title: data.title,
      address: data.address,
      annotation: data.annotation,
      description: data.description,
    });

    return response;
  },

  async createContentGroups(id: number, contentGroups: number[]) {
    const promises = contentGroups.map((contentGroupId) => {
      return api.post(`${route}/${id}/content_groups`, {
        content_group_id: contentGroupId,
      });
    });

    return Promise.all(promises);
  },

  async createQnA(id: number, qnaforUpdate: number[]) {
    const promises = qnaforUpdate.map((qnaforUpdateId) => {
      return api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${id}/qna`, {
        qna_id: qnaforUpdateId,
      });
    });

    return Promise.all(promises);
  },

  async deleteQnA(id: number, qnaforDelete: number[]) {
    const promises = qnaforDelete.map((qnaforDeleteId) => {
      return api.delete(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${id}/qna/${qnaforDeleteId}`,
      );
    });

    return Promise.all(promises);
  },

  async deleteContentGroups(id: number, contentGroups: number[]) {
    const promises = contentGroups.map((contentGroupId) => {
      return api.delete(`${route}/${id}/content_groups/${contentGroupId}`);
    });

    return Promise.all(promises);
  },

  async create(placeID: number) {
    const promises = api.post(`${route}?language_code=ru`, {
      place_id: placeID,
      old_id: placeID,
      slug: '',
      company_id: companyId,
    });

    return promises;
  },
};
