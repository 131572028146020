import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface ContainerProps {
  sidebarIsOpen?: boolean;
}

export default {
  Wrapper: styled.div`
    width: 100%;
    box-sizing: border-box;

    &:not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
    }
  `,

  Container: styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    max-width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
    }

    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
      max-width: ${(props) => (props.sidebarIsOpen ? '170px' : null)};
    }
  `,

  Text: styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  WithoutTickets: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #999;
  `,
};
