import head from 'lodash/head';
import identity from 'lodash/identity';
import includes from 'lodash/includes';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import { withRouter } from 'next/router';
import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { ACTIONS } from 'shared/constants/PREORDER';
import { formatFullDate } from 'shared/helpers/formatters/date';
import { groupTicketsBySectorAndRow } from 'shared/helpers/tickets';
import { ModalFunctions } from '../../../../interfaces/modal';
import CheckGreenIcon from '../../../../static/icons/check-green.svg';
import SyncIcon from '../../../../static/icons/sync.svg';
import TimesIcon from '../../../../static/icons/times.svg';
import Dropdown from '../../Dropdown';
import Modal from '../../Modal';
import Spinner from '../../Spinner';
import TextOverflow from '../../TextOverflow';
import Tooltip from '../../Tooltip';
import PreorderModalCheckbox from './PreorderModalCheckbox';
import PreorderModalForm from './PreorderModalForm';
import PreorderModalStandingSelector from './PreorderModalStandingSelector';
import Styled from './styles';

const getAction = (extra) => {
  if (extra.is_found) {
    return ACTIONS.IS_FOUND;
  }

  if (extra.cancelled_purchase) {
    return ACTIONS.NEED_REPLACE;
  }

  return ACTIONS.DELETE;
};

const getTicketAction = ({ isTransferFromOrder, oldAction, action }) => {
  let ticketAction = action;

  if (isTransferFromOrder) {
    ticketAction = oldAction !== ACTIONS.UNSELECTED ? ACTIONS.UNSELECTED : action;
  }

  return ticketAction;
};

interface ModalProps {
  data: any;
  loadSale: (id: number) => any;
  closeModal: ModalFunctions['closeModal'];
  saleTickets: (id: number, data: any) => any;
  loadPreorder: (id: number) => any;
  finishPreorder: (id: number) => any;
  changePreorderExtra: (id: number, data: any) => any;
}

interface ModalState {
  data: any;
  isLoading: boolean;
  isTransferFromOrder?: boolean;
}

class PreorderModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: true,
    };

    this.setTicket = this.setTicket.bind(this);
    this.setStandingTickets = this.setStandingTickets.bind(this);
  }

  componentDidMount() {
    const {
      loadPreorder,
      data: { id, isTransferFromOrder },
    } = this.props;

    loadPreorder(id).then((response) => {
      if (response.error) {
        return NotificationManager.error('Ошибка');
      }

      const { data } = response.payload;

      return this.setState({
        isLoading: false,
        data: {
          ...data,
          tickets: map(data.tickets, (ticket) => ({
            ...ticket,
            action: isTransferFromOrder ? ACTIONS.UNSELECTED : getAction(ticket.extra),
          })),
        },
        isTransferFromOrder: Boolean(isTransferFromOrder),
      });
    });
  }

  handleStandingChange = (ids, clearTickets, rowIds?: any) => {
    const {
      data: { id },
      isTransferFromOrder,
    } = this.state;
    const { changePreorderExtra } = this.props;

    const action = clearTickets ? ACTIONS.DELETE : ACTIONS.IS_FOUND;

    if (isTransferFromOrder) {
      return this.setStandingTickets(ids, ACTIONS.SELECTED, rowIds);
    }

    return changePreorderExtra(id, { tickets: map(ids, (item) => ({ ticket_id: item, action })) }).then(
      (response) => {
        if (response.error) {
          return NotificationManager.error('Ошибка');
        }

        return this.setStandingTickets(ids, action);
      },
    );
  };

  handleClick = (action, id) => {
    const { id: orderId } = this.state.data;
    const { isTransferFromOrder } = this.state;
    const { loadPreorder, changePreorderExtra } = this.props;

    if (isTransferFromOrder) {
      return this.setTicket(id, action);
    }

    return changePreorderExtra(orderId, { tickets: [{ ticket_id: id, action }] }).then((response) => {
      if (response.error) {
        return NotificationManager.error('Ошибка');
      }

      loadPreorder(orderId);

      return this.setTicket(id, action);
    });
  };

  handleSubmit = (data) => {
    const {
      isTransferFromOrder,
      data: { id, tickets },
    } = this.state;
    const { closeModal, finishPreorder, saleTickets, loadSale } = this.props;

    if (isTransferFromOrder) {
      const selectedTickets = map(
        tickets.filter((ticket) => ticket.action === ACTIONS.SELECTED),
        (ticket) => ticket.id,
      );
      const request = { tickets: selectedTickets, ...pickBy(data, identity), currency: data.currency?.value };

      return saleTickets(id, request).then((response) => {
        if (response.error) {
          return NotificationManager.error('Ошибка');
        }

        return loadSale(id).then(closeModal);
      });
    }

    return finishPreorder(id).then((response) => {
      if (response.error) {
        return NotificationManager.error('Ошибка');
      }

      return closeModal();
    });
  };

  setStandingTickets(ids, action, rowIds?: any) {
    const {
      loadPreorder,
      data: { isTransferFromOrder, id: orderId },
    } = this.props;

    if (isTransferFromOrder) {
      return this.setState(({ data: prevData }: any) => ({
        data: {
          ...prevData,
          tickets: map(prevData.tickets, (ticket) => {
            if (includes(ids, ticket.id)) {
              return {
                ...ticket,
                action: ACTIONS.SELECTED,
              };
            }

            if (!includes(ids, ticket.id) && includes(rowIds, ticket.id)) {
              return {
                ...ticket,
                action: ACTIONS.UNSELECTED,
              };
            }

            return ticket;
          }),
        },
      }));
    }

    return this.setState(
      ({ data: prevData }: any) => ({
        data: {
          ...prevData,
          tickets: map(prevData.tickets, (ticket) => {
            if (includes(ids, ticket.id)) {
              return {
                ...ticket,
                action: getTicketAction({ isTransferFromOrder, oldAction: ticket.action, action }),
              };
            }

            return ticket;
          }),
        },
      }),
      () => {
        loadPreorder(orderId);
      },
    );
  }

  setTicket(id, action) {
    const {
      data: { isTransferFromOrder },
    } = this.props;

    this.setState(({ data: prevData }: any) => ({
      data: {
        ...prevData,
        tickets: map(prevData.tickets, (ticket) => {
          if (ticket.id === id) {
            return {
              ...ticket,
              action: getTicketAction({ isTransferFromOrder, oldAction: ticket.action, action }),
            };
          }

          return ticket;
        }),
      },
    }));
  }

  render() {
    const {
      isLoading,
      isTransferFromOrder,
      data: { event, tickets },
    } = this.state;

    if (isLoading) return <Spinner center />;

    const { title, started_at, place } = event;

    const groupedTickets = groupTicketsBySectorAndRow(tickets);

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Modal.Title>
          {isTransferFromOrder ? 'Перевод билетов в статус продажи' : 'Готовность заказа'}
        </Modal.Title>
        <Styled.EventName>
          <TextOverflow>{title}</TextOverflow>
        </Styled.EventName>
        <Styled.EventInfo>{`${formatFullDate(started_at)} • ${place?.title}`}</Styled.EventInfo>
        <Styled.TicketsTable>
          <Styled.TicketsHeader>
            <Styled.TicketsHeaderCell width="30%">Сектор</Styled.TicketsHeaderCell>
            <Styled.TicketsHeaderCell width="20%">Ряд</Styled.TicketsHeaderCell>
            <Styled.TicketsHeaderCell width="50%">Места</Styled.TicketsHeaderCell>
          </Styled.TicketsHeader>
          <Styled.TicketsBody>
            {map(groupedTickets, (sector) =>
              map(sector, (row: any) => {
                const {
                  sector: sectorName,
                  category: categoryName,
                  row: rowName,
                  seat: firstSeat,
                } = head<any>(row);
                const isStanding = !firstSeat;

                const ticketsSelector = isStanding ? (
                  <>
                    {isTransferFromOrder ? (
                      <PreorderModalStandingSelector
                        row={row}
                        handleStandingChange={this.handleStandingChange}
                      />
                    ) : (
                      <PreorderModalCheckbox
                        row={row}
                        isTransferFromOrder={isTransferFromOrder}
                        handleStandingChange={this.handleStandingChange}
                      />
                    )}
                  </>
                ) : (
                  <Styled.Tickets>
                    {map(orderBy(row, 'seat', 'asc'), ({ seat, id, action }) => (
                      <Styled.Dropdown key={seat}>
                        {isTransferFromOrder ? (
                          <Styled.Ticket
                            action={action}
                            onClick={() => this.handleClick(ACTIONS.SELECTED, id)}
                            data-selenium={`seat_${seat}`}
                          >
                            {seat}
                          </Styled.Ticket>
                        ) : (
                          <Dropdown
                            data={id}
                            onClick={this.handleClick}
                            options={[
                              {
                                value: ACTIONS.IS_FOUND,
                                label: 'Есть билет',
                                disabled: action === ACTIONS.IS_FOUND,
                                icon: <CheckGreenIcon />,
                              },
                              {
                                value: ACTIONS.NEED_REPLACE,
                                label: 'Требуется замена',
                                disabled: action === ACTIONS.NEED_REPLACE,
                                icon: <SyncIcon fill="#EB5757" />,
                              },
                              {
                                value: ACTIONS.DELETE,
                                label: 'Убрать статус',
                                disabled: action === ACTIONS.DELETE,
                                icon: <TimesIcon />,
                              },
                            ]}
                          >
                            <Styled.Ticket data-selenium={`seat_${seat}`} action={action}>
                              {seat}
                            </Styled.Ticket>
                          </Dropdown>
                        )}
                      </Styled.Dropdown>
                    ))}
                  </Styled.Tickets>
                );

                return (
                  <Styled.TicketsRow key={`${sectorName || categoryName}_${rowName}`}>
                    <Styled.TicketsRowCell width="30%">
                      <Tooltip text={sectorName || categoryName}>
                        <TextOverflow>{sectorName || categoryName}</TextOverflow>
                      </Tooltip>
                    </Styled.TicketsRowCell>
                    <Styled.TicketsRowCell width="20%">{rowName}</Styled.TicketsRowCell>
                    <Styled.TicketsRowCell width="50%">{ticketsSelector}</Styled.TicketsRowCell>
                  </Styled.TicketsRow>
                );
              }),
            )}
          </Styled.TicketsBody>
        </Styled.TicketsTable>
        <PreorderModalForm
          tickets={tickets}
          onSubmit={this.handleSubmit}
          closeModal={this.props.closeModal}
          isTransferFromOrder={isTransferFromOrder}
        />
      </Styled.Container>
    );
  }
}

export default withRouter(PreorderModal);
