import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import Modal from '../../Modal/Modal';
import { smallModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}

    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal {
        &__close {
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          background-size: 10px 10px;
        }

        &__table-wrapper {
          vertical-align: bottom;
        }

        &__wrapper {
          max-width: 100%;
        }

        &__body {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          max-height: 100vh;
          overflow: hidden;
          padding: 16px;
          border-radius: 8px 8px 0 0;
          padding-bottom: 8px;
          box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
        }

        &__footer {
          margin: 30px -24px 0 -24px;
        }
      }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    font-size: 20px;
    color: #000;
    word-break: break-all;
    margin-bottom: 24px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-bottom: 16px;
    }
  `,

  Reason: styled.div`
    position: relative;
    z-index: 4;
  `,

  Textarea: styled.div`
    margin-top: 32px;
  `,

  Footer: styled(Modal.Footer)`
    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: row;
      padding: 0 16px;
      margin: 48px -16px -8px;

      & > button {
        width: auto;

        &:not(:first-child) {
          margin-left: 7px;
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  `,
};
