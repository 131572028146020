import styled from 'styled-components';

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    font-size: 20px;
  `,

  TextInfo: styled.div`
    margin-top: 16px;
    color: #5c5c5c;
    font-size: 14px;
  `,

  Tickets: styled.div`
    margin-top: 32px;

    & > ul {
      padding: 0;
      background-color: #fff;
      border: 0;
      margin-bottom: 32px;

      & > li {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `,
};
