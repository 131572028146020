import Spinner from 'components/Spinner';
import { useUnit } from 'effector-react';
import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from 'entities/modal';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import MODAL from 'shared/constants/MODALS';
import { findTranslation } from 'shared/helpers/translations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import { Modal, ModalClose } from 'shared/ui/modal';
import { ModalFunctions } from '../../../../../../../interfaces/modal';
import { LocationsFormSubmitData } from '../../../types';
import { LocationForm } from '../../form';
import * as updateModalModel from '../model/index';
import Styled from './styles';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  modal: {
    id: number;
    name: string;
  };
  isMobile: boolean;
  t: (value: string, params?: object) => string;
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

const LocationModal: React.FC<ModalProps> = (props) => {
  const { isMobile } = props;
  const router = useRouter();

  const [lang, setLang] = useState(LANGUAGES.RU);
  const dispatch = useDispatch();

  const { formSubmitted, handleDelete, modalOpened } = useUnit({
    formSubmitted: updateModalModel.formSubmitted,
    handleDelete: updateModalModel.deleteLocationSubmitted,
    modalOpened: updateModalModel.modalOpened,
  });

  const { data, loading, isOpen, modalId, isSubmitting } = useUnit({
    data: updateModalModel.$location,
    loading: updateModalModel.$locationLoading,
    deleteError: updateModalModel.$deleteLocationErrors,
    isOpen: updateModalModel.toggler.$isOpen,
    modalId: updateModalModel.$modalId,
    isSubmitting: updateModalModel.$isSubmitting,
  });

  useEffect(() => {
    if (isOpen && modalId) {
      modalOpened({ id: modalId });
    } else {
      setLang(LANGUAGES.RU);
      dispatch(closeModal());
    }
  }, [router.query?.modalId]);

  const editLocation = async (data: LocationsFormSubmitData) => {
    await formSubmitted(data);
  };

  const openDeleteForm = () => {
    dispatch(
      openModal(MODAL.CONFIRM_MODAL, {
        onSubmit: () => {
          handleDelete(data!.id);
        },

        title: 'Удалить город?',
        text: 'Данный город удалится. Вы уверены, что хотите совершить это действие?',
        modalData: { size: 400 },
      }),
    );
  };

  let translationTitle;
  if (data?.info) {
    translationTitle = findTranslation(data?.info, lang);
  }

  const formData = data
    ? {
        id: data?.id,
        name: translationTitle?.title,
        slug: data?.slug,
        country_id: data.country
          ? {
              value: data.country.id,
              label: data.country.code,
            }
          : null,
        code: data?.code,
      }
    : {};

  return (
    <Modal toggler={updateModalModel.toggler} name="countries" id={modalId}>
      {({ closeModal }) => {
        return (
          <Styled.Body className="modal__body">
            <ModalClose onClick={closeModal} />

            {loading ? (
              <Spinner center />
            ) : (
              <LocationForm
                title="references:location.edit_title"
                onSubmit={editLocation}
                initialValues={formData}
                isMobile={isMobile}
                onChangeLng={setLang}
                closeModal={closeModal}
                isEdit
                openDeleteForm={openDeleteForm}
                isSubmitting={isSubmitting}
              />
            )}
          </Styled.Body>
        );
      }}
    </Modal>
  );
};

export const LocationUpdateModal = withCheckIsMobile(LocationModal);
