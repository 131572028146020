import styled, { createGlobalStyle } from 'styled-components';

interface ColumnProps {
  noPadding?: boolean;
}

export default {
  GlobalStyles: createGlobalStyle`
    .modal {
      &__wrapper {
        max-width: 544px;
      }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 26px;
  `,

  Row: styled.div`
    display: flex;
  `,

  Controls: styled.div`
    display: flex;
    margin: 0 -8px;
  `,

  Column: styled.div<ColumnProps>`
    width: 50%;
    padding: ${(props) => (props.noPadding ? null : '0 8px')};
    box-sizing: border-box;
  `,

  DateField: styled.div`
    .date-picker-container .ant-input {
      max-width: 100%;
    }
  `,

  Fields: styled.div`
    width: 280px;
    flex-grow: 0;
  `,

  Price: styled.div`
    padding-right: 8px;
  `,
};
