import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Container: styled.div<{ withDates?: boolean }>`
    padding: 10px 16px 16px 16px;
    position: relative;
    height: 100%;

    .horizontal-tabs {
      margin-right: 50px;
      position: static;
      width: '100%';
      z-index: 1;
    }
  `,

  AddFilter: styled.div`
    position: absolute;
    top: 5px;
    right: 195px;
    max-width: 250px;
    z-index: 2;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: static;
      margin-top: 8px;
    }
  `,

  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,

  Column: styled.div`
    width: 50%;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
    }
  `,

  SelectWrapper: styled.div`
    max-width: 250px;
    margin-top: 16px;
  `,

  DatesSelect: styled.div`
    display: flex;
    align-items: flex-end;

    button {
      margin-left: 8px;
    }
  `,

  DeleteFilter: styled.div`
    max-width: 250px;
    margin: 20px 0 0 10px;
  `,

  Select: styled.div`
    display: flex;
    max-width: 300px;
    margin-top: 16px;
    align-items: center;
  `,
  SpinnerWrapper: styled.div`
    margin-top: 150px;
  `,

  FooterWrapper: styled.div<{ marketPlacesFilterPosition?: boolean }>`
    position: ${(marketPlacesFilterPosition) => (marketPlacesFilterPosition ? 'absolute' : 'static')};
    bottom: 0;
    width: 100%;
    left: 0;
    box-sizing: border-box;

    .modal__footer {
      margin: 0;
    }
  `,
};
