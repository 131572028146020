import styled, { css, createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface RowProps {
  flexContainer?: boolean;
  width?: string;
  margin?: string;
  mobileRow?: boolean;
  mobileWidth?: string;
  mobileMargin?: string;
}

interface ColumnProps {
  width?: string;
  padding?: string;
  withMobileMargin?: string;
}

interface FieldProps {
  width?: string;
  padding?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  margin?: string;
  mobileWidth?: string;
  withMobileMargin?: boolean;
}

export default {
  PopoverGlobalStyles: createGlobalStyle`
    .modal__overlay {
      overflow: hidden;
    }
  `,

  GlobalStyles: createGlobalStyle`
    .modal__body {
      padding: 0;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__wrapper {
        max-width: 100vw;
      }

      .modal__body {
        padding: 0;
      }
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      .modal__body {
        padding: 0;
      }
    }
  `,

  Wrapper: styled.div`
    padding: 48px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 0;
    }
  `,

  Row: styled.div<RowProps>`
    margin-bottom: 24px;

    ${({ flexContainer, width, margin, mobileRow, mobileWidth, mobileMargin }) =>
      flexContainer &&
      css`
        display: flex;
        box-sizing: border-box;
        width: ${width || null};
        margin: ${margin || '0 -16px'};

        @media (max-width: ${BREAKPOINTS.MD}) {
          flex-direction: ${mobileRow ? null : 'column'};
          width: ${mobileWidth || null};
          margin: ${mobileMargin};
        }
      `}
  `,

  Column: styled.div<ColumnProps>`
    display: flex;
    box-sizing: border-box;
    width: ${({ width }) => width || '100%'};
    padding: ${({ padding }) => padding || null};

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      margin-bottom: ${({ withMobileMargin }) => (withMobileMargin ? '24px' : null)};
    }
  `,

  Field: styled.div<FieldProps>`
    box-sizing: border-box;
    width: ${({ width }) => width || '100%'};
    padding: ${({ padding }) => padding || null};
    margin-top: ${({ marginTop }) => marginTop || null};
    margin-left: ${({ marginLeft }) => marginLeft || null};
    margin-right: ${({ marginRight }) => marginRight || null};
    margin-bottom: ${({ marginBottom }) => marginBottom || null};
    margin: ${({ margin }) => margin || null};

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: ${({ mobileWidth }) => mobileWidth && mobileWidth};
      margin-bottom: ${({ withMobileMargin }) => (withMobileMargin ? '24px' : null)};
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,

  Form: styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-grow: 1;
    width: 100%;
    max-width: 528px;
    padding: 32px 0 0;
    margin: 0 auto;

    @media (max-width: ${BREAKPOINTS.SM}) {
      box-sizing: border-box;
      padding: 32px 16px 0;
    }
  `,

  SeatsInputWrapper: styled.div`
    position: relative;
  `,

  SeatsSpinner: styled.div`
    position: absolute;
    right: 5px;
    top: 27px;
  `,

  ConfirmModal: styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    @media (max-width: ${BREAKPOINTS.MD}) {
      height: 100vh;
    }
  `,

  TitleWrapper: styled.div`
    padding: 32px;
  `,

  Title: styled.h1<{ mobileNormal?: boolean }>`
    font-size: 20px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.8);

    @media (max-width: ${BREAKPOINTS.SM}) {
      font-weight: ${({ mobileNormal }) => mobileNormal && '400'};
      text-align: ${({ mobileNormal }) => (mobileNormal ? 'left' : 'center')};
    }
  `,

  AddTickets: styled.div`
    padding: 0 32px 42px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: relative;
      margin: auto;

      & > button {
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
      }

      &:before {
        display: inline-block;
        vertical-align: middle;
        content: '+';
        margin-right: 5px;
        font-weight: 700;
        color: #2f80ed;
      }
    }
  `,

  MobileContainer: styled.div<{ bottom?: string }>`
    display: none;
    width: 100%;
    margin-bottom: ${({ bottom }) => bottom};

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: block;
    }
  `,

  DesktopContainer: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  SecondStepTitle: styled.div`
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: bold;
  `,
};
