import React from 'react'; // eslint-disable-line
import styled from 'styled-components';
import ArrowIcon from '../../../../static/icons/long-arrow-alt-down.svg';

export const DiscountIcon = styled(ArrowIcon)`
  margin-left: auto;
  fill: #2dc472;
`;

export const MarkupIcon = styled(ArrowIcon)`
  margin-left: auto;
  transform: rotate(180deg);
  fill: #eb5757;
`;
