import { TimePicker as AntTimePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FieldMetaState } from 'react-final-form';
import Portal from '../../Portal';
import Error from '../Error';
import Label from '../LabelNew';
import Styled from './styles';

interface TimePickerProps {
  format?: string;
  label: string;
  input: {
    onChange: (value: any) => void;
    value: any;
  };
  meta: FieldMetaState<any>;
  iconLeft?: boolean;
  defaultNowTime?: boolean;
}

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const { format = 'HH:mm', label, input, meta } = props;
  const [opened, setOpened] = useState(false);

  const [prevTime, setPrevTime] = useState(null);

  const handleChange = (value) => {
    input.onChange(value);

    if (prevTime && value?.minute() !== prevTime?.minute()) {
      setOpened(false);
    }
    if (prevTime && value?.minute() === prevTime.minute() && value.hour() === prevTime.hour()) {
      setOpened(false);
    }

    setPrevTime(value);
  };

  const handleOpenChange = () => {
    setOpened(true);
  };

  const closePicker = () => {
    setOpened(false);
  };

  return (
    <Styled.Container meta={meta}>
      {opened && (
        <Portal>
          <Styled.Overlay onClick={closePicker} />
        </Portal>
      )}
      {label && (
        <Label isOpen={opened} withIcon={props.iconLeft} meta={meta} value={input.value}>
          {label}
        </Label>
      )}
      <AntTimePicker
        format={format}
        locale={locale}
        open={opened}
        onOpenChange={handleOpenChange}
        placeholder={null}
        value={input.value}
        onCalendarChange={handleChange}
        popupClassName="custom-time-picker-popup"
        defaultOpenValue={props.defaultNowTime ? dayjs() : dayjs('1700', 'hmm')}
        onKeyDown={(e) => {
          if (
            !/[\d/-]/.test(e.key) &&
            e.key !== 'Backspace' &&
            e.key !== 'Delete' &&
            !e.ctrlKey &&
            !e.metaKey
          ) {
            e.preventDefault();
          }
        }}
      />
      <Error meta={meta} />
    </Styled.Container>
  );
};

export default TimePicker;
