import head from 'lodash/head';
import last from 'lodash/last';
import moment from 'moment';
import React from 'react';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import {
  formatDateMonthString,
  formatTimeFromDate,
  formatFullDateAndTime,
} from 'shared/helpers/formatters/date';
import routes from 'shared/helpers/routes';
import CalendarIcon from '../../../static/icons/calendar.svg';
import ExternalLinkIcon from '../../../static/icons/external-link.svg';
import TextOverflow from '../TextOverflow';
import Tooltip from '../Tooltip';
import Styled from './styles';

interface EventInfoProps {
  id?: number;
  name?: string;
  place?: string;
  withoutTooltip?: boolean;
  withMobile?: boolean;
  inTable?: boolean;
  isLink?: boolean;
  withParentIndicator?: boolean;
  withSingleChildren?: boolean;
  children?: any[];
  date?: string;
}

const EventInfo: React.FC<EventInfoProps> = ({
  id,
  name,
  place,
  withoutTooltip,
  withMobile,
  inTable,
  children,
  withParentIndicator,
  withSingleChildren,
  isLink,
  date,
}) => {
  const firstDate =
    date || (children && children.length > 0 && `${children[0].date_start} ${children[0].time_start}`) || '';
  const isValidDate = moment(firstDate, DATE_FORMATS.DATE_TIME).isValid();
  const firstEventDate = isValidDate && formatFullDateAndTime(firstDate);

  const renderDot = () => {
    if (!isValidDate) {
      return null;
    }

    if (!place) {
      return null;
    }

    return <Styled.Dot inTable={inTable} />;
  };

  let dates = '';

  if (children && children.length > 1) {
    const { date_start: dateStart, time_start: timeStart } = head(children);
    const { date_finish: dateFinish, time_finish: timeFinish } = last(children);
    const fromDate = formatFullDateAndTime(`${dateStart} ${timeStart}`);
    const endDate = formatFullDateAndTime(`${dateFinish} ${timeFinish}`);

    dates = `${fromDate} - ${endDate}`;
  }

  const tooltipText = !place ? (
    name
  ) : (
    <div>
      {name} {place && ` • ${place}`}
      <br />
      {dates}
    </div>
  );

  const renderCalendarIcon = () => {
    if (withSingleChildren || (children && children.length > 1)) {
      return (
        <Styled.Icon>
          <CalendarIcon />
          <Styled.Dot inTable={inTable} />
          {dates && <Styled.PeriodicalDates>{dates}</Styled.PeriodicalDates>}
        </Styled.Icon>
      );
    }

    return null;
  };
  const content = () => {
    const eventName = isLink ? (
      <Styled.Link href={routes.announcement(id)} target="_blank" rel="noopener noreferrer">
        <span>{name}</span>
        <ExternalLinkIcon />
      </Styled.Link>
    ) : (
      <span>{name}</span>
    );

    return withMobile ? (
      <Styled.Container>
        <Styled.Name inTable={inTable}>
          <TextOverflow>{eventName}</TextOverflow>
        </Styled.Name>
        <Styled.Info inTable={inTable}>
          <Styled.MobileContainer>
            <Styled.Time inTable={inTable}>
              {isValidDate && (
                <>
                  <div>{formatDateMonthString(firstDate)}</div>
                  <div>{formatTimeFromDate(firstDate)}</div>
                </>
              )}
            </Styled.Time>
          </Styled.MobileContainer>
          <Styled.DesktopContainer>{firstEventDate}</Styled.DesktopContainer>
          {renderDot()}
          <TextOverflow>
            <span>{place}</span>
          </TextOverflow>
        </Styled.Info>
      </Styled.Container>
    ) : (
      <Styled.Container>
        <TextOverflow>{eventName}</TextOverflow>
        <Styled.Info inTable={inTable}>
          {withParentIndicator && renderCalendarIcon()}
          {firstEventDate}
          {renderDot()}
          <TextOverflow>
            <span>{place}</span>
          </TextOverflow>
        </Styled.Info>
      </Styled.Container>
    );
  };

  return withoutTooltip ? (
    content()
  ) : (
    <Tooltip text={tooltipText} placement="topLeft">
      {content()}
    </Tooltip>
  );
};

export default EventInfo;
