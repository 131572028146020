import Button from 'components/Button';
import config from 'config/config';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import COOKIES from 'shared/constants/COOKIES';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import useAttachments from 'shared/helpers/attachments';
import { updateAttachments } from 'shared/lib/attachments';
import { getCookie } from 'shared/lib/cookie';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import api from 'shared/services/api';
import attachmentsSource from 'shared/sources/attachments';
import landingsPersonalitiesSources from 'shared/sources/landings/landingsPersonalities';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import {
  buildPersonsAttachments,
  getDifferenceInContentGroups,
  getTranslations,
} from '../../helpers/landingHelpers';
import { Person } from '../../types/Person';
import PersonsForm from './PersonsForm';

interface PersonsUpdateProps {
  data: Person;
  onSuccess: (data: any) => any;
  onChangeLng: () => any;
  showDeleteModal: () => any;
  isMobile: boolean;
  lng: string;
  closeModal: () => any;
}

const PersonsUpdate: React.FC<PersonsUpdateProps> = (props) => {
  const { onSuccess, onChangeLng, showDeleteModal, closeModal, data, lng, isMobile } = props;
  const { t } = useTranslation();

  const cover = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Cover;
  });
  const gallery = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Gallery;
  });
  const mainImage = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Main_Image;
  });
  const mainImageInfo = mainImage?.info?.find((i) => i.language_code === lng);
  const coverInfo = cover?.info?.find((i) => i.language_code === lng);

  const info = getTranslations(data.info, lng);
  const initialValues = {
    id: data.id,
    title: info?.title,
    description: info?.description,
    contentGroups:
      (data.content_groups &&
        data.content_groups
          .filter((i) => i && i.id)
          .map((item) => ({
            value: item.id,
            label: item.title,
          }))) ||
      [],
    external: data.person && {
      label: data.person.title,
      value: data.person.id,
    },
    gallery: gallery?.data,
    cover: cover?.data,
    main_image: mainImage?.data,
    ...(coverInfo && { cover_title: coverInfo.title, cover_alt: coverInfo.alt }),
    ...(mainImageInfo && { main_image_title: mainImageInfo.title, main_image_alt: mainImageInfo.alt }),
    qna:
      data?.qna?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
  };

  const deletePersonsAttachments = (formData, attachementID) =>
    attachmentsSource.deletePersonsAttachments(formData, attachementID);

  const createPersonsAttachments = (formData, attachementID) => {
    return attachmentsSource.createPersonsAttachments({
      event_id: formData,
      attachmentsData: attachementID,
    });
  };

  const editPersonsAttachments = (formData, attachementID, formAttachment) => {
    return attachmentsSource.editPersonsAttachments(formData, attachementID, formAttachment);
  };

  const { buildMainPageAttachment, buildCoverAttachment, buildGalleryAttachment } = useAttachments({
    deleteMetod: deletePersonsAttachments,
    createMetod: createPersonsAttachments,
    editMetod: editPersonsAttachments,
    data,
  });

  const updateAttachmentsInfo = async ({ id, formData, formLang }) => {
    const person = await api.get(landingsPersonalitiesSources.detail(id)).then((res) => res.data);
    const infoAttachments = updateAttachments(person, { lng: formLang, [formLang]: formData });

    await Promise.all(
      infoAttachments
        .filter((attachment) => !isEmpty(attachment.info))
        .map(async (attachment) => {
          const route = `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/landings/persons`;
          if (attachment.info?.is_create) {
            const response = await api.post(
              `${route}/${id}/attachments/${attachment.attachment_type_id}/info`,
              {
                ...attachment.info,
                language_code: attachment.language_code,
              },
            );

            return response;
          }

          const response = await api.patch(
            `${route}/${id}/attachments/${attachment.attachment_type_id}/info/${attachment.language_code}`,
            attachment.info,
          );

          return response;
        }),
    );
  };

  const handleUpdate = async (submitData) => {
    const formLang = submitData.lng;
    const formData = submitData[lng];

    if (formLang === LANGUAGES.RU && isEqual(formData, initialValues)) {
      return {};
    }

    const translations = getTranslations(data.info, formLang);

    if (translations) {
      await landingsPersonalitiesSources.updateTranslation(data.id, formLang, formData);
    } else {
      await landingsPersonalitiesSources.createTranslation(data.id, { ...formData, language_code: formLang });
    }

    if (formLang !== LANGUAGES.RU) {
      await updateAttachmentsInfo({ id: data.id, formData, formLang });
      return {};
    }

    const { contentGroupsforDelete, contentGroupsforUpdate, qnaforUpdate, qnaforDelete } =
      getDifferenceInContentGroups({
        initialData: initialValues,
        formData,
      });

    if (contentGroupsforDelete.length) {
      landingsPersonalitiesSources.deleteContentGroups(data.id, contentGroupsforDelete);
    }

    if (contentGroupsforUpdate.length) {
      const groupsResponse: any = await landingsPersonalitiesSources
        .createContentGroups(data.id, contentGroupsforUpdate)
        .catch((error) => error);

      if (groupsResponse.response?.data?.detail === 'Already exists') {
        return {
          error: {
            response: {
              data: {
                error: ['Данная персона с таким тегом существует'],
              },
            },
          },
        };
      }
    }

    if (qnaforUpdate.length) {
      landingsPersonalitiesSources.createQnA(data.id, qnaforUpdate);
    }

    if (qnaforDelete.length) {
      landingsPersonalitiesSources.deleteQnA(data.id, qnaforDelete);
    }

    const attachments = await buildPersonsAttachments(formData);

    await buildMainPageAttachment(formData, attachments);
    await buildCoverAttachment(formData, attachments);
    await buildGalleryAttachment(formData, attachments);
    await updateAttachmentsInfo({ id: data.id, formData, formLang });

    return {};
  };

  return (
    <PersonsForm
      key="edit"
      isEdit
      title="landings:personality.edit_title"
      onSubmit={handleUpdate}
      onSuccess={onSuccess}
      onChangeLng={onChangeLng}
      lng={lng}
      initialValues={initialValues}
    >
      <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
        {isMobile ? <TrashIcon /> : t('forms:delete')}
      </Button>
      <Button type="button" transparent onClick={closeModal}>
        {t('forms:cancel')}
      </Button>
      <Button>{t('forms:save')}</Button>
    </PersonsForm>
  );
};

export default withCheckIsMobile(PersonsUpdate);
