import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { required } from 'shared/helpers/validations';
import { LangForm } from '../../Form';
import Button from '../../Button';
import { InputWithSelect } from '../../FormControls';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import useListItem from 'shared/lib/useListItem';
import clientsSource from 'shared/sources/clients';
import api from 'shared/services/api';
import { OptionWithClientInfo } from '../../FormControls/InputWithSelect';
import { renderClients } from 'shared/helpers/optionsRenderers';

interface ModalProps {
  data: any;
  closeModal: () => void;
  forceCloseModal: () => void;
}

const ClientsModal: React.FC<ModalProps> = ({ data, ...rest }) => {
  const isMetaClient = Boolean(data.clients);
  const { emitListReload } = useListItem({
    source: clientsSource,
  });

  const merge = isMetaClient ? clientsSource.metaMerge : clientsSource.merge;

  const handleSubmitForm = (submitData: any) =>
    api
      .put(merge, {
        ids: [data.id, ...submitData.ru.ids.map((i) => i.value)],
      })
      .then((response: any) => {
        rest.forceCloseModal();
        return response;
      })
      .catch((error) => NotificationManager.error(error.response.data.error.ids, 'Ошибка'));

  const selectProps = {
    ...(isMetaClient && {
      isAsync: true,
      route: clientsSource,
      optionsRenderer: (options: any) => renderClients(options, data.id),
    }),
    isMulti: true,
    label: 'Объединить с',
    ...(!isMetaClient && {
      options: data.mergeClients
        .filter((client) => client.id !== data.id)
        .map((client) => ({ value: client.id, label: client.name, data: client })),
    }),
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <LangForm
        onSubmit={handleSubmitForm}
        onSuccess={emitListReload}
        initialValues={data}
        render={({ i18n, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title>Объединение Клиента</Modal.Title>
            <Styled.Client>
              {data.name && <Styled.ClientInfoField>{data.name}</Styled.ClientInfoField>}
              {data.email && <Styled.ClientInfoField>{data.email}</Styled.ClientInfoField>}
              {data.phone && <Styled.ClientInfoField>{data.phone}</Styled.ClientInfoField>}
            </Styled.Client>
            <Styled.Fields>
              <Field name={`${i18n.language}.ids`} validate={required}>
                {({ input, meta }) => (
                  <InputWithSelect
                    {...selectProps}
                    components={{ Option: OptionWithClientInfo }}
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>
            </Styled.Fields>
            <Modal.Footer>
              <Button transparent type="button" onClick={rest.closeModal}>
                Отмена
              </Button>
              <Button>Объединить</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default ClientsModal;
