import React from 'react';
import JsFileDownload from 'js-file-download';
import split from 'lodash/split';
import last from 'lodash/last';
import get from 'lodash/get';
import api from 'shared/services/api';
import config from 'config/config';
import Styled from 'components/ReportsOrdersInfoTable/styles';
import routes from './routes';
import { formatDateFromNow } from './formatters/date';
import TextOverflow from 'components/TextOverflow';
import TableUserInfo from 'components/TableUserInfo';
import OrderStatus from 'components/OrderStatus';
import OrderManager from 'components/Order/OrderManager';
import EventInfo from 'components/EventInfo';

const downloadReport = (relativeLink) => {
  api
    .get(`${config.API_URL}${relativeLink.substring(5)}`, {
      responseType: 'blob',
    })
    .then((action) => {
      JsFileDownload(action.data, action.headers.filename);
    })
    .catch((err) => new Error(err));
};

const downloadFile = (link, fileName) =>
  new Promise((resolve, reject) => {
    api
      .get(link, {
        responseType: 'blob',
      })
      .then((action) => {
        JsFileDownload(action.data, fileName || last(split(link, '/')));
        resolve(null);
      })
      .catch((err) => {
        reject(err);
      });
  });

const getReportsOrdersInfoTableCommonData = ({ order, account }) => {
  const { id, external_id: externalId, created, customer, user_customer: userCustomer } = order;

  const customerId = get(customer, 'id');
  const isBTB = customerId && customerId !== account.company.id;
  const isApi = get(order, 'extra.site_url');
  const isCall = get(order, 'extra.context');
  const eventDateTime = `${get(order, 'event.date_start')} ${get(order, 'event.time_start')}`;

  const orderIds = (
    <div>
      <Styled.Ids>
        <Styled.Link target="_blank" rel="noopener noreferrer" href={routes.sale(id)}>
          {id}
        </Styled.Link>
        {externalId && <Styled.ExternalId>{` / ${externalId}`}</Styled.ExternalId>}
      </Styled.Ids>
    </div>
  );

  const orderCreated = (
    <div>
      <Styled.Date>{formatDateFromNow(created)}</Styled.Date>
    </div>
  );

  const orderUser = (
    <div>
      <Styled.User>
        <TextOverflow>
          <TableUserInfo data={userCustomer} fallback={customer} isBTB={isBTB} />
        </TextOverflow>
      </Styled.User>
    </div>
  );

  const orderEvent = (
    <div>
      <Styled.Event>
        <EventInfo date={eventDateTime} name={order.event.title} place={order.event.place.name} />
      </Styled.Event>
    </div>
  );

  const orderStatus = (
    <div>
      <Styled.Status>
        <OrderStatus order={order} withoutManager />
      </Styled.Status>
    </div>
  );

  const orderManager = (
    <div>
      <Styled.Manager>
        <OrderManager data={order} emptyText="-" />
      </Styled.Manager>
    </div>
  );

  return {
    isApi,
    isBTB,
    isCall,
    orderIds,
    orderCreated,
    orderUser,
    orderEvent,
    orderStatus,
    orderManager,
  };
};

export { downloadReport, downloadFile, getReportsOrdersInfoTableCommonData };
