import React, { Component, createRef } from 'react';
import onClickOutside from 'react-onclickoutside';
import get from 'lodash/get';
import Router from 'next/router';
import TimesIcon from '../../../static/icons/times.svg';
import SearchIcon from '../../../static/icons/search.svg';
import Orders from './Orders';
import Events from './Events';
import Pool from './Pool';
import AnnouncementEvents from './AnnouncementEvents';
import StatePreview from '../StatePreview';
import SearchHistory from './SearchHistory';
import Styled from './styles';
import IDS from 'shared/constants/IDS';
import KEY_CODES from 'shared/constants/KEY_CODES';
import routes from 'shared/helpers/routes';
import { IUser } from '../../entities/account/types';

interface SearchProps {
  onChange: (value: any) => void;
  clearResults: () => void;
  callContextIsNotEmpty: boolean;
  orderContextIsNotEmpty: boolean;
  events: { results: any[]; meta: any };
  pool: { results: any[]; meta: any };
  orders: { results: any[]; meta: any };
  announcements: { results: any[]; meta: any };
  state: any;
  account: IUser;
  sidebarIsOpen: boolean;
  saveHistory: (value: any) => void;
  clearHistory: () => void;
  history: string[];
}

interface SearchState {
  value: string;
  focused: boolean;
  nextRoute?: any;
}

class HeaderSearch extends Component<SearchProps, SearchState> {
  inputEl: React.RefObject<HTMLInputElement>;

  overlayEl: HTMLElement;

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      focused: false,
      nextRoute: null,
    };

    this.inputEl = createRef();

    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { events, pool, orders, announcements } = props;
    const { results: ordersData } = orders;
    const { results: poolData } = pool;
    const { results: eventsData } = events;
    const { results: announcementsData } = announcements;
    const queue = [
      { length: ordersData.length, route: routes.searchOrders },
      { length: poolData.length, route: routes.searchPool },
      { length: eventsData.length, route: routes.searchEvents },
      { length: announcementsData.length, route: routes.searchAnnouncements },
    ];

    const nextRoute = queue.find((data) => data.length);

    if (nextRoute) {
      return {
        nextRoute: `${nextRoute.route}?search=${state.value}`,
      };
    }

    return {
      nextRoute: '',
    };
  }

  componentDidMount() {
    this.overlayEl = document.getElementById(IDS.SEARCH_OVERLAY);

    this.overlayEl.addEventListener('click', this.hideMenu);
  }

  componentWillUnmount() {
    this.overlayEl.removeEventListener('click', this.hideMenu);
  }

  handleChange = (event) => {
    const { onChange, clearResults } = this.props;
    const { value: inputValue } = event.target;
    const trimmedValue = inputValue.trim();
    const valueIsNotEmpty = trimmedValue.length;

    this.setState({
      value: inputValue,
      focused: true,
    });

    if (valueIsNotEmpty) {
      return onChange(trimmedValue);
    }

    return clearResults();
  };

  handleKeyUp = ({ keyCode }) => {
    if (keyCode === KEY_CODES.ENTER) {
      const { saveHistory } = this.props;
      const { nextRoute, value } = this.state;

      if (nextRoute) {
        saveHistory(value);
        this.clear();
        Router.push(nextRoute).catch((err) => new Error(err));
      }
    }
  };

  handleClickOutside() {
    this.setState({
      focused: false,
    });
    this.toggleOverlay(false);
  }

  get isOpened() {
    const { focused, value } = this.state;

    return Boolean(focused && value && value.length);
  }

  showMenu = () => {
    this.setState({
      focused: true,
    });

    this.inputEl.current.focus();

    this.toggleOverlay(true);
  };

  hideMenu = () => {
    this.setState({
      focused: false,
    });
    this.toggleOverlay(false);
  };

  clear = () => {
    const { clearResults } = this.props;

    this.setState({
      value: '',
    });
    this.hideMenu();
    clearResults();
  };

  saveHistory = () => {
    this.props.saveHistory(this.state.value);
  };

  selectHistory = (data) => {
    this.setState({
      value: data,
    });
    this.props.onChange(data);
    this.inputEl.current.focus();
  };

  toggleOverlay(isVisible) {
    this.overlayEl.style.display = isVisible ? 'block' : 'none';

    if (isVisible) {
      return document.addEventListener('keyup', this.handleKeyUp);
    }

    return document.removeEventListener('keyup', this.handleKeyUp);
  }

  render() {
    const {
      callContextIsNotEmpty,
      orderContextIsNotEmpty,
      events,
      pool,
      orders,
      announcements,
      state,
      account,
      sidebarIsOpen,
      history,
      clearHistory,
    } = this.props;
    const { results: eventsData } = events;
    const { results: poolData } = pool;
    const { results: ordersData } = orders;
    const { results: announcementsData } = announcements;
    const searchIsEmpty = [eventsData, poolData, ordersData, announcementsData].every((list) => !list.length);
    const { value, focused, nextRoute } = this.state;
    const canShowEmptyMessage = Boolean(!state.isLoading && searchIsEmpty && focused && value.length);

    return (
      <Styled.Container focused={focused}>
        <Styled.Line focused={focused} />
        <Styled.SearchButton onlyIcon transparent type="button" onClick={this.showMenu} focused={focused}>
          <SearchIcon />
        </Styled.SearchButton>
        <Styled.InputContainer focused={focused}>
          <Styled.Input
            onChange={this.handleChange}
            placeholder="Поиск..."
            value={value}
            callContextIsNotEmpty={callContextIsNotEmpty}
            orderContextIsNotEmpty={orderContextIsNotEmpty}
            ref={this.inputEl}
            disabled={!get(account, 'company') || !get(account, 'company.verified')}
          />
          <Styled.Actions>
            {value && value.length && nextRoute && (
              <Styled.Hint>
                Перейти к результатам <Styled.HintKey href={nextRoute}>Enter</Styled.HintKey>
              </Styled.Hint>
            )}
            <Styled.Close onClick={this.clear}>
              <TimesIcon />
            </Styled.Close>
          </Styled.Actions>
        </Styled.InputContainer>
        {this.isOpened && (
          <Styled.DropMenu>
            <StatePreview state={state} />
            {!state.isLoading && (
              <>
                <Orders
                  data={ordersData}
                  meta={orders.meta}
                  account={account}
                  sidebarIsOpen={sidebarIsOpen}
                  search={value}
                  saveHistory={this.saveHistory}
                  hideMenu={this.hideMenu}
                />
                <Pool
                  data={poolData}
                  meta={pool.meta}
                  search={value}
                  saveHistory={this.saveHistory}
                  hideMenu={this.hideMenu}
                />
                <Events
                  data={eventsData}
                  meta={events.meta}
                  search={value}
                  saveHistory={this.saveHistory}
                  hideMenu={this.hideMenu}
                />
                <AnnouncementEvents
                  data={announcementsData}
                  meta={announcements.meta}
                  search={value}
                  saveHistory={this.saveHistory}
                  hideMenu={this.hideMenu}
                />
              </>
            )}
          </Styled.DropMenu>
        )}
        {!value && focused && Boolean(history.length) && (
          <Styled.DropMenu>
            <SearchHistory
              history={history}
              clearHistory={clearHistory}
              onSelectHistory={this.selectHistory}
            />
          </Styled.DropMenu>
        )}
        {canShowEmptyMessage && !state.isLoading && (
          <Styled.DropMenu>
            <Styled.Empty>Ничего не найдено ¯\_(ツ)_/¯</Styled.Empty>
          </Styled.DropMenu>
        )}
      </Styled.Container>
    );
  }
}

export default onClickOutside(HeaderSearch);
