import Button from 'components/Button';
import ConfirmModal from 'components/Modals/ConfirmModal';
import flow from 'lodash/flow';
import head from 'lodash/head';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { openModal } from 'entities/modal';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import MODALS from 'shared/constants/MODALS';
import PAGES from 'shared/constants/PAGES';
import { saveTranslationData } from 'shared/helpers/form';
import useListItem from 'shared/lib/useListItem';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import expansionSources from 'shared/sources/expansion';
import Styled from 'shared/ui/reference/modal-styles';
import { ModalFunctions } from '../../../../../interfaces/modal';
import TrashIcon from '../../../../../static/icons/trash.svg';
import { withTranslation } from '../../../../i18n';
import ExpansionForm from './form';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  openModal: ModalFunctions['openModal'];
  modalState?: string;
  modalData: {
    id: number;
  };
  isMobile: boolean;
  t: (value: string, params?: any) => string;
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

interface ModalState {
  data: any;
  t: ModalProps['t'];
  state: {
    isLoading: boolean;
    isFail: boolean;
  };
}

const ExpansionModal: React.FC<ModalProps> = (props) => {
  const [state, setState] = useState<ModalState>({
    data: null,
    t: props.t,
    state: {
      isLoading: Boolean(props.modalData.id),
      isFail: false,
    },
  });
  const { modalState, isMobile, closeModal, forceCloseModal, t } = props;

  const { emitListReload, get, remove } = useListItem({ source: expansionSources });

  const loadData = (lng?: string) => {
    const {
      modalData: { id },
    } = props;

    if (id) {
      get(id, lng)
        .then((data) => {
          setState((prevState) => ({
            ...prevState,
            data: {
              id,
              ...data,
            },
            state: {
              isLoading: false,
              isFail: false,
            },
          }));
          return data;
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            state: {
              ...prevState.state,
              isFail: true,
            },
          }));
        });

      props.openModal(
        MODALS.REFERENCE_EXPANSION,
        {
          ...props.modalData,
          modalData: {
            mobilePadding: '0',
            fullSize: window.innerWidth < BREAKPOINTS.MD_NUMBER,
          },
          CloseButton: window.innerWidth < BREAKPOINTS.MD_NUMBER && (
            <Styled.Close>
              <img src="/static/icons/close-red.svg" alt="Отмена" />
            </Styled.Close>
          ),
        },
        MODAL_STATES.EDIT,
      );
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const onChangeLng = (lng) => {
    loadData(lng);
  };

  const openEditForm = () => {
    props.openModal(MODALS.REFERENCE_HALL, props.modalData, MODAL_STATES.EDIT);
  };

  const createExpansion = (data) =>
    saveTranslationData({
      data,
      create: expansionSources.root,
      update: expansionSources.detail,
    }).then((translationData) => {
      if (!translationData.error) {
        emitListReload();
      }

      return translationData;
    });

  const editExpansion = (data) =>
    saveTranslationData({
      data,
      update: expansionSources.detail,
    }).then((translationData) => {
      if (!translationData.error) {
        emitListReload();
      }

      return translationData;
    });

  const handleDelete = (data) =>
    remove({ id: +props.modalData.id }).then((response) => {
      props.forceCloseModal();

      if (response.error) {
        return props.openModal(MODALS.REFERENCE_ERRORS_MODAL, {
          errors: head(response.error),
          name: state.data.name,
          page: PAGES.REFERENCE_EXPANSION,
          expansion: {
            data,
            id: props.modalData.id,
          },
        });
      }
      emitListReload();

      return response;
    });

  switch (modalState) {
    case MODAL_STATES.CREATE: {
      return (
        <ExpansionForm
          onSubmit={createExpansion}
          onSuccess={forceCloseModal}
          title="references:expansion.create_title"
          isMobile={isMobile}
          state={state.state}
          stashLanguages
          t={t}
        >
          <Button transparent type="button" onClick={closeModal}>
            {t('forms:cancel')}
          </Button>
          <Button type="submit">{t('forms:add')}</Button>
        </ExpansionForm>
      );
    }

    case MODAL_STATES.DELETE:
      return (
        <ConfirmModal
          data={{
            title: t('references:expansion.delete_title'),
            text: t('references:expansion.delete_text'),
            onSubmit: handleDelete,
            onReject: openEditForm,
          }}
        />
      );

    default: {
      const onDelete = () => {
        props.openModal(
          MODALS.REFERENCE_EXPANSION,
          {
            ...props.modalData,
            modalData: {
              fullSize: false,
              mobilePadding: null,
            },
            CloseButton: null,
          },
          MODAL_STATES.DELETE,
        );
      };

      return (
        <ExpansionForm
          key="edit"
          title="references:expansion.edit_title"
          onSubmit={editExpansion}
          initialValues={
            state.data
              ? {
                  ...state.data,
                  id: state.data.id || state.data.item_id,
                }
              : {}
          }
          onSuccess={forceCloseModal}
          isMobile={isMobile}
          state={state.state}
          onChangeLng={onChangeLng}
          t={t}
        >
          <Button onlyIcon={isMobile} dangerTransparent onClick={onDelete} type="button">
            {isMobile ? <TrashIcon /> : t('forms:delete')}
          </Button>
          <Button transparent type="button" onClick={closeModal}>
            {t('forms:cancel')}
          </Button>
          <Button type="submit">{t('forms:save')}</Button>
        </ExpansionForm>
      );
    }
  }
};

const mapStateToProps = (state) => ({
  modalState: state.modal.state,
  modalData: state.modal.data,
});

const mapDispatchToProps = {
  openModal,
};

const withHoC = flow([
  withCheckIsMobile,
  withTranslation([LOCALES.FORMS, LOCALES.REFERENCES]),
  connect(mapStateToProps, mapDispatchToProps),
]);

export default withHoC(ExpansionModal);
