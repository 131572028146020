import React, { useEffect, useState } from 'react';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';
import includes from 'lodash/includes';
import compact from 'lodash/compact';
import map from 'lodash/map';
import omit from 'lodash/omit';
import replace from 'lodash/replace';
import Tabs from '../../Tabs';
import Button from '../../Button';
import RoleForm from '../RoleModal/RoleForm';
import api from 'shared/services/api';
import { authSources } from 'entities/auth';
import DataPreview from '../../DataPreview';
import { membersCompaniesSources } from 'entities/members/companies';
import Styled from './styles';
import { ModalFunctions } from '../../../../interfaces/modal';

const TABS = [
  {
    text: 'Доступы компании',
    key: 1,
  },
  {
    text: 'Общие доступы',
    key: 2,
  },
];

interface Props {
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  data: any;
}

const CompanyMemberPermissions: React.FC<Props> = (props) => {
  const {
    closeModal,
    forceCloseModal,
    data: { data },
  } = props;

  const [state, setState] = useState<any>({
    selectedTab: 1,
    data: [],
    companyData: {},
    isLoading: true,
    isFail: false,
  });

  useEffect(() => {
    const loadData = async () => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const companyData = await api.get(membersCompaniesSources.detail(data.id));

      const response = await api.get(
        state.selectedTab === 1 ? authSources.companyPermissions : authSources.userPermissions,
        {
          params: {
            limit: 9999,
          },
        },
      );

      setState((prevState) => ({
        ...prevState,
        data: response.data.data.results,
        companyData: companyData.data,
        isLoading: false,
      }));
    };

    loadData().catch((err) => new Error(err));
  }, [state.selectedTab]);

  const handleSubmit = (submitData) => {
    const results = compact(
      map(omit(submitData, 'name'), (item, index) => item && Number(replace(index, 'id_', ''))),
    );

    if (state.selectedTab === 2) {
      return api.post(membersCompaniesSources.allowedPermissions(data.id), {
        permissions: results,
      });
    }

    return api.post(membersCompaniesSources.permissionsDetail(data.id), {
      permissions: results,
    });
  };

  const setTab = (tab) => {
    setState((prevState) => ({
      ...prevState,
      selectedTab: tab,
    }));
  };

  const initialPermissions = reduce(
    state.data,
    (acc, curr: any) => {
      // @ts-ignore
      const id = isObject(curr) ? curr.id : curr;
      acc[`id_${id}`] = includes(
        state.companyData.permissions.map((item) => item.id),
        id,
      );

      return acc;
    },
    {},
  );

  return (
    <Styled.Container>
      <Styled.Title>Доступы</Styled.Title>
      <Tabs tabs={TABS} onSelect={setTab} selectedTab={state.selectedTab} highlighted>
        <DataPreview state={state} length={state.data.length} centered>
          <RoleForm
            onSubmit={handleSubmit}
            onSuccess={forceCloseModal}
            withHeader={false}
            permissions={state.data}
            initialValues={initialPermissions}
          >
            <Button type="button" transparent onClick={closeModal}>
              Отмена
            </Button>
            <Button>Сохранить</Button>
          </RoleForm>
        </DataPreview>
      </Tabs>
    </Styled.Container>
  );
};

export default CompanyMemberPermissions;
