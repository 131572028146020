import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import ICONS from 'shared/constants/ICONS';
import { imageValidation } from 'shared/helpers/validations';
import api from 'shared/services/api';
import UploadSources from 'shared/sources/upload';
import Button from '../../Button';
import Icon from '../../Icon';
import Spinner from '../../Spinner';
import Error from '../Error';
import Label from '../Label';
import Styled from './styles';

interface FileInputProps {
  meta: any;
  label?: string;
  onChange: any;
  value: string;
  description?: string;
  placeholder?: string;
  withPreview?: boolean;
  maxSize?: number;
  imageTypes?: string[];
  linkMaxWidth?: number;
  expansionIcon?: boolean;
  wysiwygEditor?: boolean;
  getImageSize?: (width: number, height: number) => void;
}

interface FileInputState {
  link?: string;
  isLoading: boolean;
}

class FileInput extends Component<FileInputProps, FileInputState> {
  constructor(props) {
    super(props);
    const { expansionIcon } = this.props;

    if (expansionIcon) {
      this.state = {
        isLoading: false,
      };
    }
    this.state = {
      link: props.value || null,
      isLoading: false,
    };
  }

  handleChange = ({ target }) => {
    if (!target.files.length) return;
    const { onChange, maxSize, imageTypes, expansionIcon, getImageSize, wysiwygEditor } = this.props;
    const [file] = target.files;

    if (wysiwygEditor) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        getImageSize({ width, height });
      };
    }

    if (imageValidation({ maxSize, file, target, imageTypes })) return;

    const formData = new FormData();
    formData.append('image', file);
    this.setState({
      isLoading: true,
    });

    api
      .post(UploadSources.root, formData)
      .then((response) => {
        const { link } = response.data;
        if (expansionIcon) {
          this.setState({
            isLoading: false,
          });

          onChange(file);
        }
        this.setState({
          link,
          isLoading: false,
        });

        onChange(link);
      })
      .catch((error) => {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.clearFile();
          },
        );

        if (error.response.status === 413) {
          return NotificationManager.error('Вы попытались загрузить слишком большой файл', 'Ошибка');
        }

        return NotificationManager.error('Ошибка');
      });
  };

  clearFile = () => {
    this.props.onChange(null);
    this.setState({
      link: null,
    });
  };

  render() {
    const {
      meta,
      label,
      description = 'Максимальное ограничение на размер файла - 1 мб',
      placeholder,
      withPreview,
      linkMaxWidth,
      value,

      expansionIcon,
    } = this.props;
    const { isLoading, link } = this.state;

    return (
      <div>
        <Styled.Row>
          <Styled.Preview>
            {label && <Label>{label}</Label>}
            <Styled.Input type="file" size="80" onChange={this.handleChange} disabled={isLoading} />
            <Styled.PreviewContainer withPreview={withPreview} bg={expansionIcon ? value : link} height={80}>
              {isLoading ? (
                <Spinner size={30} />
              ) : (
                !link ||
                (!value &&
                  (placeholder || (
                    <>
                      <img src={ICONS.UPLOAD_PICTURE} alt="Загрузить картинку" />
                      Загрузить картинку
                    </>
                  )))
              )}
            </Styled.PreviewContainer>
            <Error meta={meta} />
          </Styled.Preview>
          <Styled.Description withLabel={Boolean(label)}>
            <Styled.Text>{description}</Styled.Text>
          </Styled.Description>
        </Styled.Row>
        {link && (
          <Styled.LinkWrapper>
            <Styled.Link maxWidth={linkMaxWidth}>{link}</Styled.Link>
            <Styled.Icon>
              <Button onClick={this.clearFile} plain type="button">
                <Icon src={ICONS.CLOSE_RED} alt="Отмена" />
              </Button>
            </Styled.Icon>
          </Styled.LinkWrapper>
        )}
      </div>
    );
  }
}

export default FileInput;
