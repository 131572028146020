import { useUnit } from 'effector-react';
import React, { useState } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import Button from 'components/Button';
import Input from 'components/FormControls/Input';
import Z_INDEX from 'shared/constants/Z_INDEX';
import { required } from 'shared/helpers/validations';
import { Modal, ModalClose } from 'shared/ui/modal';
import { editorLinkModal } from '../model';
import Styled from './styles';

export const LinkModal = () => {
  const { handleInsertLink } = useUnit({ handleInsertLink: editorLinkModal.insertedLink });
  const [values, setValues] = useState<{ linkUrl: string; displayText: string }>({
    linkUrl: '',
    displayText: '',
  });

  return (
    <Modal zIndex={Z_INDEX.EDITOR_MODAL} toggler={editorLinkModal.toggler}>
      {({ closeModal }) => {
        return (
          <Styled.Body className="modal__body">
            <ModalClose onClick={closeModal} />
            <Styled.Title>Вставить ссылку</Styled.Title>
            <Form
              onSubmit={handleInsertLink}
              initialValues={{ ...values, displayText: values.linkUrl }}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        setValues(values);
                      }}
                    </FormSpy>
                    <Styled.InputWrapper>
                      <Field name="linkUrl" validate={required}>
                        {({ input, meta }) => <Input label="Адрес ссылки" meta={meta} {...input} />}
                      </Field>
                    </Styled.InputWrapper>

                    <Styled.InputWrapper>
                      <Field name="displayText" validate={required}>
                        {({ input, meta }) => <Input label="Отоброжаемый текст" meta={meta} {...input} />}
                      </Field>
                    </Styled.InputWrapper>
                    <Styled.ButtonWrapper>
                      <Button type="button" transparent onClick={closeModal}>
                        Отмена
                      </Button>
                      <Button>Вставить ссылку</Button>
                    </Styled.ButtonWrapper>
                  </form>
                );
              }}
            />
          </Styled.Body>
        );
      }}
    </Modal>
  );
};
