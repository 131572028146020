import React from 'react';
import Styled from './styles';
import AnnouncementHistoryModalPrice from './AnnouncementHistoryModalPrice';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalPriceChanged: React.FC<IHistoryItem> = ({ data, user, currency }) => (
  <Styled.Info>
    <Styled.InfoTitle>Изменение цены</Styled.InfoTitle>
    <AnnouncementHistoryModalName data={user} />
    <AnnouncementHistoryModalPrice data={data} currency={currency} />
  </Styled.Info>
);

export default AnnouncementHistoryModalPriceChanged;
