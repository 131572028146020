import styled, { createGlobalStyle } from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  GlobalStyles: createGlobalStyle`
    @media ${MEDIA_QUERIES.MOBILE} {
      .modal {
      &__table-wrapper {
        vertical-align: bottom;
      }

      &__wrapper {
        max-width: 100%;
      }

      &__body {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        max-height: 100vh;
        overflow: hidden;
        padding: 0 16px;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      }

      &__footer {
        padding: 8px;
        margin: 0 -16px;

        & > div {
          min-height: 0;
          height: auto;
        }
      }
    }
    }
  `,

  Wrapper: styled.div`
    margin: 0 -40px;

    @media ${MEDIA_QUERIES.MOBILE} {
      margin: 0 -16px;
    }
  `,

  Header: styled.div`
    padding: 0 40px 16px;
    border-bottom: 1px solid #e1e1e1;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding: 16px;
    }
  `,

  Title: styled.div`
    font-size: 20px;
    word-break: break-all;
    margin-bottom: 32px;

    @media ${MEDIA_QUERIES.MOBILE} {
      margin-bottom: 0;
    }
  `,
};
