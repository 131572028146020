import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Body: styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 688px;
    height: 100%;
    padding: 32px 40px;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 24px 16px 32px;
      border-radius: 8px 8px 0 0;
    }
  `,

  Title: styled.div`
    font-size: 20px;
    word-break: break-all;
    text-align: left;
    margin-bottom: 32px;
  `,

  Info: styled.div`
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
    }

    & > span {
      font-size: 14px;
      color: #000;
    }

    & > a {
      font-size: 14px;
      color: #2f80ed;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      white-space: initial;
    }
  `,

  Row: styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
  `,

  Hr: styled.div`
    height: 1px;
    margin: 16px 0;
    background-color: #e1e1e1;
  `,

  Description: styled.div`
    font-size: 14px;
    color: #474747;
    overflow: hidden;
    word-break: break-all;
  `,

  SpinnerContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
