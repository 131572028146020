import React, { useState, useEffect, useRef } from 'react';
import uuid from 'uuid/v4';
import { NotificationManager } from 'react-notifications';
import { isEmpty, map, get } from 'lodash';
import StepModal from '../../../Modals/StepModal';
import ProgressBar from '../../../ProgressBar';
import Spinner from '../../../Spinner';
import InitialStep from './InitialStep';
import EditorStep from './EditorStep';
import Styled from './styles';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import {
  PAGE_CONSTRUCTOR_TYPES,
  PAGE_CONSTRUCTOR_NAMES,
  PAGE_CONSTRUCTOR_KEYS,
  PAGE_CONSTRUCTOR_BLOCKS_WITHOUT_SETTINGS,
} from 'shared/constants/PAGE_CONSTRUCTOR_BLOCKS';
import AfishaStep from './AfishaStep';
import GalleryStep from './GalleryStep';
import api from 'shared/services/api';
import localesSource from 'shared/sources/locales';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { Select } from '../../../FormControls';
import landingsCustomSources from 'shared/sources/landings/landingsCustom';
import withLandingData from 'containers/Pages/Landings/withLandingData';
import QnAStep from './QnAStep';

const BLOCK_COMPONENT = {
  initial: InitialStep,
  [PAGE_CONSTRUCTOR_TYPES.HTML]: EditorStep,
  [PAGE_CONSTRUCTOR_TYPES.EVENTS]: AfishaStep,
  [PAGE_CONSTRUCTOR_TYPES.GALLERY]: GalleryStep,
  [PAGE_CONSTRUCTOR_TYPES.QNA]: QnAStep,
};

const initialStep = {
  uuid: uuid(),
  block_type: 'initial',
  ordering: 0,
  block_name: 'Настройка блоков',
  html: '',
  events: [],
};

const getSteps = (blocks) =>
  blocks.reduce(
    (acc, item) => {
      if (PAGE_CONSTRUCTOR_BLOCKS_WITHOUT_SETTINGS.includes(item.block_type)) {
        return acc;
      }

      acc.steps.push(item.uuid);
      acc.stepsPreview[item.uuid] = item.block_name;

      return acc;
    },
    {
      steps: [],
      stepsPreview: {},
    },
  );

const formatBlock = (block) => ({
  ...block,
  block_name: PAGE_CONSTRUCTOR_NAMES[block.block_type],
  uuid: uuid(),
  [PAGE_CONSTRUCTOR_KEYS[block.block_type]]: block[PAGE_CONSTRUCTOR_KEYS[block.block_type]] || '',
});

const PageConstructorModal = (props: any) => {
  const steps = useRef([]);
  const stepsPreview = useRef({});
  const [locales, setLocales] = useState([]);
  const [lng, setLng] = useState(LANGUAGES.RU);
  const [step, setStep] = useState(0);
  const [data, setData] = useState([initialStep]);
  const currentBlockType = steps.current
    ? get(
        data.find((block) => block.uuid === steps.current[step]),
        'block_type',
        'initial',
      )
    : 'initial';

  const StepComponent: any = BLOCK_COMPONENT[currentBlockType];
  const [invalidBlocks, setInvalidBlocks] = useState({});
  const [isLoading, setLoading] = useState(true);
  let updatedTempData = null;

  const updateData = (newData) => {
    const stepsConfig = getSteps(newData);

    steps.current = stepsConfig.steps;
    stepsPreview.current = stepsConfig.stepsPreview;

    setData(newData);
  };

  const loadData = async (lang = 'ru') => {
    await api.get(landingsCustomSources.blocks(props.data.id, lang)).then((response) => {
      const blocksData = response.data.results.map((block) => formatBlock(block));
      const loadedData = [initialStep, ...blocksData];
      const stepsConfig = getSteps(loadedData);

      steps.current = stepsConfig.steps;
      stepsPreview.current = stepsConfig.stepsPreview;

      setData(loadedData);
      setLoading(false);
      updatedTempData = loadedData;
    });
  };

  useEffect(() => {
    api
      .get(localesSource.available)
      .then((response) => {
        const supportedLocales = response.data.map((item) => ({
          value: item.code,
          name: item.label,
        }));
        setLocales([...locales, ...supportedLocales]);
      })
      .catch((err) => new Error(err));

    loadData().catch((err) => new Error(err));
  }, []);

  const onChangeLng = ({ target: { value: newLng } }) => {
    setStep(0);
    setLng(newLng);
    loadData(newLng).catch((err) => new Error(err));
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const updateTempData = (newData) => {
    updatedTempData = newData;
  };

  const showErrors = (errors) => {
    NotificationManager.error(
      `
      Не заполнены: ${map(errors, (value) => `${PAGE_CONSTRUCTOR_NAMES[value.block_type]}`).join(', ')}
    `,
      'Ошибка',
    );
    setInvalidBlocks(errors);
  };

  const addData = (newData) => {
    const { blocks, submit_action: action } = newData;
    const regex = /(?:jpeg|jpg|png|svg)/gi;
    if (step === data.length - 1 || action === SUBMIT_ACTIONS.CREATE) {
      const dataForSubmit = blocks
        .filter((item) => item.block_type !== 'initial')
        .map((item) => {
          const events = item.events ? item.events.map((event) => event.id || event) : [];
          return {
            ...(item.id && {
              id: item.id,
            }),
            ...(item.gallery && {
              gallery_webp: item.gallery && item.gallery.map((img) => img.replace(regex, 'webp')),
            }),
            is_publish: item.is_publish,
            block_type: item.block_type,
            ordering: item.ordering,
            title: item.title || '',
            lang: item.lang || lng,
            [PAGE_CONSTRUCTOR_KEYS[item.block_type]]:
              item.block_type === PAGE_CONSTRUCTOR_TYPES.EVENTS
                ? events
                : item[PAGE_CONSTRUCTOR_KEYS[item.block_type]],
          };
        });

      const errors = dataForSubmit.reduce((acc, block) => {
        if (
          (block.block_type === PAGE_CONSTRUCTOR_TYPES.HTML && !block.html) ||
          (block.block_type === PAGE_CONSTRUCTOR_TYPES.EVENTS && !get(block, 'events.length'))
        ) {
          acc[block.uuid] = block;
        }

        return acc;
      }, {});

      if (!isEmpty(errors)) {
        return showErrors(errors);
      }

      setInvalidBlocks({});
      setData(blocks);

      const promises = dataForSubmit.map((block) => {
        const { id: pageId } = props.data;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const method = block.id ? api.patch : api.post;
        const url = block.id
          ? landingsCustomSources.blocksDetail(pageId, block.id)
          : landingsCustomSources.blocks(pageId);

        return method(url, block);
      });

      return Promise.all(promises).then((response: any) => {
        if (response.error) {
          return NotificationManager.error('Ошибка');
        }

        return loadData(lng).then(() => NotificationManager.success('Успешно'));
      });
    }

    setStep(step + 1);
    return setData(blocks);
  };

  const selectStep = (selectedStep) => {
    if (updatedTempData) {
      setData(updatedTempData);
    }

    setStep(selectedStep);
  };

  const resetErrors = () => {
    setInvalidBlocks({});
  };

  return (
    <>
      <Styled.GlobalStyles />
      <StepModal.Header withMobileSteps>
        <StepModal.Title>
          Конструктор страницы
          <Styled.LangSwitcher>
            <Select small options={locales} value={lng} onChange={onChangeLng} />
          </Styled.LangSwitcher>
        </StepModal.Title>
      </StepModal.Header>
      <Styled.Main>
        {isLoading ? (
          <Spinner size={50} />
        ) : (
          <>
            <Styled.Progress>
              <ProgressBar
                isVertical
                mobileHorizontal
                getKeyFromStep
                steps={steps.current}
                stepsPreview={stepsPreview.current}
                invalidSteps={invalidBlocks}
                currentStep={step}
                onSelect={selectStep}
              />
            </Styled.Progress>

            <StepComponent
              pageId={props.data.id}
              onSubmit={addData}
              goBack={goBack}
              data={data}
              step={step}
              updateData={updateTempData}
              setSteps={updateData}
              onUpdateSteps={resetErrors}
              steps={steps.current}
              blockData={data.find((block) => block.uuid === steps.current[step])}
              showErrors={showErrors}
              lng={lng}
              contentGroups={props.data.content_groups}
            />
          </>
        )}
      </Styled.Main>
    </>
  );
};

export default withLandingData(PageConstructorModal, {
  newDetailApi: true,
  translation: 'common',
  sources: landingsCustomSources,
});
