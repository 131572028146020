import dayjs from 'dayjs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import { NotificationManager } from 'react-notifications';
import { hallLayoutsSources } from 'entities/hall-layouts';
import hallsSources from 'shared/api/reference/halls/halls';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import IMAGE_TYPES from 'shared/constants/IMAGE_TYPES';
import api from 'shared/services/api';
import playgroundsSources from 'shared/sources/playgrounds';
import { seatsStringToArray } from './seats';

/* eslint-disable */
const validSpecialCharsRegExp = new RegExp(/[^а-яА-Яa-zA-Z0-9 - ё Ё]|(^\s+)/);
const validBundleRegExp = /^[a-zA-Zа-яА-ЯёЁ0-9\s-]+$/;
const validSlugCharsRegExp = new RegExp(/^[A-Za-z0-9\-]*$/);
const validSpecialCharsWithDotsRegExp = new RegExp(/[^а-яА-Яa-zA-Z0-9 - ё Ё . ,]|(^\s+)/);
const validWhitespaceOnStartAndEndRegExp = new RegExp(/^[^\s]+(\s+[^\s]+)*$/);
const validNoWhitespaceRegExp = new RegExp(/^\S*$/);
const validEmailRegExp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
const strongPasswordRegExp = new RegExp(/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(.+){8,}/);
const validSubdomainRegExp = new RegExp(/^[A-Za-zА-Яа-яёЁ0-9]+$/);

const required = (value) => (trim(value) ? undefined : 'Поле обязательно для заполнения');

const requiredWithMessage = (message) => (value) => value ? undefined : message;
const minValue =
  (min) =>
  (value = '') =>
    value.length >= min ? undefined : `Недостаточно символов, необходимо минимум ${min}`;
const maxValue = (max) => (value) =>
  (value ? value.length : 0) <= max ? undefined : `Количество символов превышает ${max}`;
const filteredMaxValue = (max, regexp) => (value) =>
  (value ? value.replace(regexp, '').length : 0) <= max ? undefined : `Количество символов превышает ${max}`;
const mustBeNumber = (value) =>
  value && Number.isNaN(Number(value)) ? 'Значение поля не является числом' : undefined;
const composeValidators =
  (...validators: any[]) =>
  (value: any, allValues: any, meta?: any) =>
    validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined);
const validDate = (value) => {
  if (!value) {
    return 'Необходимо выбрать дату и время';
  }

  return undefined;
};

const dateTimeInFuture = (value) => {
  if (!value) {
    return 'Необходимо выбрать дату и время';
  }

  if (!dayjs(value).isAfter(dayjs(), 'minutes')) {
    return 'Должно быть не позднее текущей даты';
  }

  return undefined;
};

const uniqueStartDates = (dates) => {
  const getDate = (item) => {
    if (!item) return item;
    if (!item.date_time_start) return item.date_time_start;

    return item.date_time_start.date_time_start
      ? dayjs(item.date_time_start.date_time_start)
      : item.date_time_start;
  };

  const errors = dates?.map((date, index) => {
    const hasDuplicate = dates.find((item, i) => {
      const first = getDate(date);
      const second = getDate(item);
      return i !== index && first && second && dayjs(first).isSame(second);
    });
    return hasDuplicate ? { date_time_start: 'Дата и время уже существуют' } : undefined;
  });

  return errors;
};

const uniqueStartTime = (times) => {
  const errors = times?.map((time, index) => {
    const hasDuplicate = times?.find((item, i) => {
      const first = time.time_start;
      const second = item.time_start;
      return i !== index && first && second && dayjs(first).isSame(second);
    });
    return hasDuplicate ? { time_start: 'Время уже существует' } : undefined;
  });

  const hasErrors = compact(errors).length > 0;
  return hasErrors ? errors : undefined;
};

const startTimeBeforeEndTime = (times) => {
  const errors = times?.map((item) => {
    const fieldErrors: any = {};
    if (!item.time_start) {
      fieldErrors.time_start = 'Поле обязательно для заполнения';
    }
    if (!item.time_end) {
      fieldErrors.time_end = 'Поле обязательно для заполнения';
    }
    if (dayjs(item.time_start).isAfter(item.time_end)) {
      fieldErrors.time_start = 'Время начала не может быть позже окончания';
    }
    return isEmpty(fieldErrors) ? undefined : fieldErrors;
  });

  const hasErrors = compact(errors).length > 0;
  return hasErrors ? errors : undefined;
};

const validSpecialChars =
  (lng = 'ru') =>
  (value) => {
    if (lng !== 'ru') return undefined;
    return !validSpecialCharsRegExp.test(value) ? undefined : 'Введены недопустимые символы';
  };

const validSLugChars =
  (lng = 'ru') =>
  (value) => {
    if (lng !== 'ru') return undefined;
    return validSlugCharsRegExp.test(value) ? undefined : 'Введены недопустимые символы';
  };

const validSpecialCharsWithDots =
  (lng = 'ru') =>
  (value) => {
    if (lng !== 'ru') return undefined;
    return !validSpecialCharsWithDotsRegExp.test(value) ? undefined : 'Введены недопустимые символы';
  };

const validBundleSlug =
  (lng = 'ru') =>
  (value) => {
    if (lng !== 'ru') return undefined;
    return validBundleRegExp.test(value) ? undefined : 'Введены недопустимые символы';
  };

const validSubdomain = (value) => {
  return validSubdomainRegExp.test(value) ? undefined : 'Введены недопустимые символы';
};

const validWhitespaceOnStartAndEnd = (value) =>
  validWhitespaceOnStartAndEndRegExp.test(value) ? undefined : 'Введены недопустимые символы';

const validNoWhitespace = (value) =>
  validNoWhitespaceRegExp.test(value) ? undefined : 'Введены недопустимые символы';

const minNumberValue = (min) => (value) =>
  value >= min || value === undefined ? undefined : `Недопустимое значение, необходимо минимум ${min}`;
const maxNumberValue = (max) => (value) =>
  value <= max || value === undefined ? undefined : `Недопустимое значение, максимум ${max}`;

const validEmail = (value) => {
  if (!value) return undefined;

  return validEmailRegExp.test(value) ? undefined : 'E-mail адрес введён некорректно';
};

const strongPassword = (value) =>
  strongPasswordRegExp.test(value)
    ? undefined
    : 'Ваш пароль должен содержать строчные и прописные буквы и хотя бы одну цифру';
const validPhone = (value, formValues, meta?: any) => {
  if (value) {
    const phoneNumber = parsePhoneNumberFromString(value, formValues[`${meta.name}_dial_code`]);

    if (phoneNumber && phoneNumber.isValid()) {
      return undefined;
    } else {
      return 'Номер телефона введён некорректно';
    }
  }
};
const seatValidation = (value) => {
  let error;
  const errorMessage = 'Неверный формат. Пример: 1,2,7-11';
  const errorMaxMessage = 'Недопустимое значение, максимум 100000';

  if (value) {
    const values = value.split(',');

    values.forEach((currentValue) => {
      const onlyNumbers = /^\d+$/.test(currentValue);

      if (onlyNumbers && currentValue > 100000) error = errorMaxMessage;

      if (!onlyNumbers) {
        const withDash = /^(\d+(-\d+){1})?$/.test(currentValue.trim());

        if (!withDash) {
          error = errorMessage;
        }

        const [from, to] = currentValue.split('-').map((i) => Number(i));

        if (from > to || from === to) {
          error = errorMessage;
        }

        if (from > 100000 || to > 100000) {
          error = errorMaxMessage;
        }

        if (currentValue.trim() === '') {
          error = errorMessage;
        }
      }
    });
  }

  return error;
};

const validJSON = (str) => {
  if (str) {
    try {
      const json = JSON.parse(str);
      if (typeof json === 'object') {
        return undefined;
      }
    } catch (e) {
      return 'Неверный формат JSON';
    }
  }
};

const intValidation = (value) => (Number(value) !== parseInt(value, 10) ? 'Введите целое число' : undefined);

const createSeatsValidation =
  ({ event, setSeatsIds, fromValues }: any) =>
  (values) =>
    new Promise((resolve) => {
      const errors: { [key: string]: any } = {};

      if (values.seat && values.row && seatValidation(values.seat) === undefined) {
        const ids = seatsStringToArray(values.seat);

        const duplicates = uniq(filter(ids, (val, i, iteratee) => includes(iteratee, val, i + 1)));

        if (duplicates.length) {
          errors.seat = `Есть дубли: ${duplicates.join(', ')}`;

          resolve(errors);
        }

        return api(playgroundsSources.root, {
          params: {
            search: values.seat,
            hall_layout: event.hall_layout_id || event.hall_layout.id,
            fragment_type: FRAGMENT_TYPES.SEAT,
            parent: values.row.value,
          },
        })
          .then((results) => {
            if (results && results.data && results.data.length > 0) {
              const seatsIds = results.data.reduce((acc, curr) => {
                acc[curr.label] = curr.id;
                return acc;
              }, {});

              if (fromValues) {
                setSeatsIds(seatsIds, values.id);
                resolve(null);
              }

              setSeatsIds(values.row.value, seatsIds);
              resolve(null);
            }

            resolve(errors);
          })
          .catch((error) => {
            const responseErrors: Array<string> = error.response.data.error;
            errors.seat = head(responseErrors)
              .replace('Seat(s)', 'Места')
              .replace('is not found', 'не найдены');

            resolve(errors);
          });
      }

      resolve(errors);
    });

const eventWithHall = (value) => {
  if (value && value.data && !value.data.hall_layout_id && !value.data.hall_id) {
    return 'Прикрепите схему к данному мероприятию';
  }
};

export const eventWithHallAnnouncement = (value) => {
  if (!value?.data?.hall_layout_id && !value?.data?.hall_id) {
    return 'Прикрепите схему к данному мероприятию';
  }
};

const eventPlaceValidation = (values) => {
  const errors: { [key: string]: any } = {};

  if (values.place) {
    return new Promise((resolve, reject) => {
      return api(`${hallsSources.root}?place_id_in=${values?.place?.data?.id || values.place.id}`).then(
        (results) => {
          if (results.data.results.length === 0) {
            return reject(new Error('У площадки нет зала'));
          }

          return Promise.all(
            results.data.results.map((hallLayout) =>
              api(`${hallLayoutsSources.root}?hall=${hallLayout.id}`).then(
                (res) => res.data.data.results.length,
              ),
            ),
          ).then((hallLayouts) => {
            if (hallLayouts.some((layout) => layout !== 0)) {
              return resolve(null);
            }

            return reject(new Error('У зала нет схем'));
          });
        },
      );
    })
      .then(() => errors)
      .catch((error) => {
        errors.place = error.message;

        return errors;
      });
  }

  return errors;
};

const imageValidation = ({ maxSize, file, target, imageTypes = [IMAGE_TYPES.PNG, IMAGE_TYPES.JPEG] }) => {
  const sizeInMb = file.size / 1024 / 1024;

  if (maxSize && sizeInMb > maxSize) {
    // eslint-disable-next-line
    target.value = '';
    NotificationManager.error(`Файл не должен быть больше ${maxSize}MB`);

    return true;
  }

  if (!imageTypes.includes(file.type)) {
    // eslint-disable-next-line
    target.value = '';
    NotificationManager.error(
      `Загрузить можно только ${imageTypes.map((type) => type.replace('image/', '')).join(', ')} изображения`,
    );

    return true;
  }
};

const replaceDescription = (description: string) => {
  if (
    description === '<p><br></p>' ||
    description === '<h1><br></h1>' ||
    description === '<h2><br></h2>' ||
    description === '<h5><br></h5>' ||
    description === '<h3><br></h3>'
  ) {
    return '';
  }

  return description;
};

const landingDescriptionValidation = (value: string) => {
  const replacedValue = replaceDescription(value);

  return required(replacedValue);
};

export {
  landingDescriptionValidation,
  validBundleSlug,
  validSLugChars,
  composeValidators,
  required,
  mustBeNumber,
  minValue,
  maxValue,
  filteredMaxValue,
  requiredWithMessage,
  validDate,
  uniqueStartDates,
  uniqueStartTime,
  startTimeBeforeEndTime,
  validSpecialChars,
  seatValidation,
  validEmail,
  validPhone,
  minNumberValue,
  maxNumberValue,
  validJSON,
  strongPassword,
  intValidation,
  createSeatsValidation,
  eventWithHall,
  eventPlaceValidation,
  validWhitespaceOnStartAndEnd,
  validNoWhitespace,
  validSpecialCharsWithDots,
  validSubdomain,
  imageValidation,
  dateTimeInFuture,
};
