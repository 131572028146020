import copyToClipboard from 'copy-to-clipboard';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import BuildingIcon from '../../../../static/icons/building.svg';
import CopyIcon from '../../../../static/icons/copy.svg';
import GoogleIcon from '../../../../static/icons/google.svg';
import YandexIcon from '../../../../static/icons/yandex.svg';
import Button from '../../Button';
import TextOverflow from '../../TextOverflow';
import Tooltip from '../../Tooltip';
import Styled from './styles';

interface OrderManagerProps {
  emptyText?: string;
  placement?: string;
  switchToTablet?: boolean;
  data: any;
  utm?: any;
  children?: any;
}

const renderManagerTooltip = (manager, utm) => {
  const copyCampaignId = () => {
    copyToClipboard(utm.utm_campaign);
    NotificationManager.success('Номер успешно скопирован');
  };

  return (
    <>
      <Styled.Row>
        <Styled.Label>Менеджер</Styled.Label>
        <Styled.Name>
          {manager.first_name} {manager.last_name}
        </Styled.Name>
      </Styled.Row>

      {manager.company && (
        <Styled.Row>
          <Styled.Label>
            <BuildingIcon />
          </Styled.Label>
          <Styled.Name>{manager.company}</Styled.Name>
        </Styled.Row>
      )}

      {utm && utm.utm_campaign && (
        <Styled.Row>
          <Styled.Label>ID компании</Styled.Label>
          <Styled.Name>
            <Styled.NameInner>{utm.utm_campaign}</Styled.NameInner>
            <Button transparent onlySmallIcon onClick={copyCampaignId}>
              <CopyIcon />
            </Button>
          </Styled.Name>
        </Styled.Row>
      )}
      {utm && utm.utm_source && (
        <Styled.Row>
          <Styled.Label>Поисковая система</Styled.Label>
          <Styled.Name>
            {utm.utm_source === 'yandex' && (
              <>
                <Styled.SourceIcon>
                  <YandexIcon />
                </Styled.SourceIcon>
                Yandex
              </>
            )}
            {utm.utm_source === 'google' && (
              <>
                <Styled.SourceIcon>
                  <GoogleIcon />
                </Styled.SourceIcon>
                Google
              </>
            )}
          </Styled.Name>
        </Styled.Row>
      )}
      {utm && utm.utm_term && (
        <Styled.Row>
          <Styled.Label>Запрос</Styled.Label>
          <Styled.Name>{utm.utm_term}</Styled.Name>
        </Styled.Row>
      )}
    </>
  );
};

const OrderManager = ({
  data,
  utm,
  switchToTablet = false,
  emptyText = '',
  children,
  placement = 'left',
}: OrderManagerProps) => {
  const manager = data.event_manager || data.manager;

  if (manager && manager.first_name && manager.last_name) {
    const commonTooltipProps = {
      placement,
      isBig: true,
      isWhite: true,
      text: renderManagerTooltip(manager, utm),
      trigger: 'click',
    };

    if (children) {
      return <Tooltip {...commonTooltipProps}>{children}</Tooltip>;
    }

    return (
      <Tooltip {...commonTooltipProps}>
        <Styled.Manager switchToTablet={switchToTablet}>
          <TextOverflow>{`${manager.first_name} ${manager.last_name}`}</TextOverflow>
        </Styled.Manager>
      </Tooltip>
    );
  }

  if (manager) {
    return <Styled.Manager>Без имени</Styled.Manager>;
  }

  return <>{emptyText || null}</>;
};

export default OrderManager;
