import styled from 'styled-components';

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Warning: styled.div`
    display: flex;
    color: #5c5c5c;

    & > svg {
      margin-top: 4px;
      flex-shrink: 0;
      margin-right: 8px;
    }
  `,

  Tickets: styled.div`
    & > ul {
      padding: 0;
      background-color: #fff;
      border: 0;
      margin-bottom: 32px;
    }
  `,
};
