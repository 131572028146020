import React from 'react';
import Styled from './styles';
import Spinner from '../../Spinner';

const InputWithSelectLoadingMessage = () => (
  <Styled.Spinner>
    <Spinner />
  </Styled.Spinner>
);

export default InputWithSelectLoadingMessage;
