import React from 'react';
import has from 'lodash/has';
import Styled from './styles';
import { TICKET_TYPES } from 'shared/constants/TICKET_TYPES';
import AnnouncementHistoryModalPrice from './AnnouncementHistoryModalPrice';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalMeta: React.FC<IHistoryItem> = ({ data, user, currency }) => {
  const { new: newMeta } = data;

  const renderMeta = () => {
    if (has(newMeta, 'non_returnable')) {
      if (newMeta.non_returnable) {
        return <Styled.InfoText>Объявление получило признак «Невозвратный»</Styled.InfoText>;
      }

      return <Styled.InfoText>Объявление утратило признак «Невозвратный»</Styled.InfoText>;
    }

    if (has(newMeta, 'personal')) {
      if (newMeta.personal) {
        return <Styled.InfoText>Объявление получило признак «Именной»</Styled.InfoText>;
      }

      return <Styled.InfoText>Объявление утратило признак «Именной»</Styled.InfoText>;
    }

    if (has(newMeta, 'ticket_type')) {
      return (
        <Styled.InfoText>
          {`Объявление изменило тип на «${TICKET_TYPES[newMeta.ticket_type]}»`}
        </Styled.InfoText>
      );
    }

    if (has(newMeta, 'purchase_price')) {
      return <AnnouncementHistoryModalPrice currency={currency} data={data} />;
    }

    if (has(newMeta, 'price')) {
      return <AnnouncementHistoryModalPrice currency={currency} data={data} />;
    }

    return null;
  };

  return (
    <Styled.Info>
      <Styled.InfoTitle>Изменение мета информации</Styled.InfoTitle>
      <AnnouncementHistoryModalName data={user} />
      {renderMeta()}
    </Styled.Info>
  );
};

export default AnnouncementHistoryModalMeta;
