import React from 'react';
import styled from 'styled-components';
import gropBy from 'lodash/groupBy';
import map from 'lodash/map';
import head from 'lodash/head';
import Tooltip from '../Tooltip';
import TicketOrderStatusLink from './TicketOrderStatusLink';
import { scrollbar } from '../../app/styles/scrollbar';
import { renderTickets } from 'shared/helpers/tickets';

interface Props {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  order?: any;
  orders?: any[];
}

const StyledTooltip = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbar}

  & a {
    margin-left: 5px;
  }
`;

const StyledTooltipOrder = styled.div`
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  &:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }
`;

const StyledTooltipOrderTitle = styled.div`
  min-width: 94px;
  font-size: 12px;
  padding: 4px 6px;
`;

const StyledTooltipOrderRow = styled.div`
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

const StyledTooltipSeat = styled.div`
  color: #5c5c5c;
`;

const TicketOrderStatus: React.FC<Props> = ({ children, orders, order }) => {
  const orderStatus = (
    <StyledTooltip>
      {orders && orders.length > 1 && <StyledTooltipOrderTitle>Места и заказ</StyledTooltipOrderTitle>}
      {orders && orders.length > 1 ? (
        map(
          gropBy(
            orders.filter((item) => item.order),
            'order.id',
          ),
          (item) => (
            <StyledTooltipOrder key={head(item).order.id}>
              <StyledTooltipOrderRow>
                <StyledTooltipSeat>{renderTickets(item)}</StyledTooltipSeat>
                <TicketOrderStatusLink order={head(item).order} />
              </StyledTooltipOrderRow>
            </StyledTooltipOrder>
          ),
        )
      ) : (
        <div>
          Заказ <TicketOrderStatusLink order={order} />
        </div>
      )}
    </StyledTooltip>
  );

  return (
    <Tooltip text={orderStatus} isWhite delay={0.3} isSmall>
      {children}
    </Tooltip>
  );
};

export default TicketOrderStatus;
