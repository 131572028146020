import styled from 'styled-components';

interface TableSeatsWrapperProps {
  disabled?: boolean;
  isOpen?: boolean;
}

export default {
  Row: styled.div`
    padding: 12px 24px;
    margin: 0 -24px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e1e1e1;
  `,

  StatusDropdown: styled.div`
    min-width: 80px;
    margin-right: 16px;

    .react-select__single-value {
      padding-bottom: 0;
    }
  `,

  TableSeatsWrapper: styled.div<TableSeatsWrapperProps>`
    pointer-events: ${({ disabled }) => disabled && 'none'};
    overflow: hidden;
    max-height: 100%;

    button:after {
      top: 2px;
      right: 15px;
      opacity: ${({ isOpen }) => !isOpen && 0.5};
      width: 8px;
      background-size: contain;
      display: ${({ disabled }) => disabled && 'none'};
    }
  `,

  RowMain: styled.div`
    display: flex;
    justify-content: center;
  `,

  RowFooter: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  SubText: styled.div`
    display: flex;
  `,

  SubTextLabel: styled.div`
    font-size: 14px;
    color: #5c5c5c;
  `,

  SubTextValue: styled.div`
    margin-left: 4px;
    font-size: 14px;
  `,

  Sector: styled.div`
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    box-sizing: border-box;
  `,
};
