import React from 'react';
import { Field } from 'react-final-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { validPhone, validEmail, maxValue, composeValidators, required } from 'shared/helpers/validations';
import Form from '../../Form';
import Button from '../../Button';
import { Input, PhoneInput } from '../../FormControls';
import Styled from './styles';
import EmailIcon from '../../../../static/icons/email.svg';
import Modal from '../../Modal/Modal';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  editUserCustomer: (id: string | number, data: any) => any;
}

const EditUserCustomerModal: React.FC<ModalProps> = (props) => {
  const { data, editUserCustomer, closeModal } = props;
  const { id, user_customer: userCustomer, customer } = data;

  const onSubmit = (formData) => {
    const { name, email, phone } = formData;

    const requestData = {
      name,
      email,
      phone,
    };

    return editUserCustomer(id, pickBy(requestData, identity));
  };

  const phone = get(userCustomer, 'phone') || get(customer, 'phone');
  const isValidPhone = phone && parsePhoneNumberFromString(phone);
  const initialValues = userCustomer || customer;

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Form
        onSubmit={onSubmit}
        onSuccess={closeModal}
        initialValues={{
          ...initialValues,
          phone: isValidPhone ? phone : '',
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title>Редактирование покупателя</Modal.Title>
            <Styled.Fields>
              <Field name="name" validate={maxValue(100)}>
                {({ input, meta }) => <Input label="Имя" {...input} meta={meta} />}
              </Field>
              <Field name="email" validate={composeValidators(validEmail, required)}>
                {({ input, meta }) => (
                  <Input placeholder="Почта" icon={<EmailIcon opacity={0.4} />} {...input} meta={meta} />
                )}
              </Field>
              <Field name="phone" validate={validPhone}>
                {({ input, meta }) => <PhoneInput input={input} handleForm={form} meta={meta} />}
              </Field>
            </Styled.Fields>
            <Modal.Footer>
              <Button transparent type="button" onClick={closeModal}>
                Отмена
              </Button>
              <Button>Сохранить</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default EditUserCustomerModal;
