import tables from 'config/tables';
import get from 'lodash/get';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import { noun } from 'plural-ru';
import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { companySources } from 'entities/company';
import { landingsRoute } from 'shared/api/landings/sites';
import { EVENTS } from 'shared/constants/EVENTS';
import LANDINGS_PAYMENTS_OPTIONS from 'shared/constants/LANDINGS_PAYMENTS_OPTIONS';
import { renderMembers, renderPayments, renderLegalInfo } from 'shared/helpers/optionsRenderers';
import { getIdsError } from 'shared/helpers/tables';
import api from 'shared/services/api';
import Emmiter from 'shared/services/Emmiter';
import { ModalFunctions } from '../../../../interfaces/modal';
import QuestionIcon from '../../../../static/icons/question.svg';
import Button from '../../Button';
import Form from '../../Form';
import { InputWithSelect, Select } from '../../FormControls';
import Modal from '../../Modal/Modal';
import Tooltip from '../../Tooltip';
import Styled from './styles';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  setRowsErrors: ({ name, errors }: { name: string; errors: any }) => any;
}

const LandingsSitesEditInfoModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    setRowsErrors,
    data: { ids: selectedRows, modalData, name, page },
  } = props;
  const { errorText } = tables[page];

  const onSubmit = async (data) => {
    let mappedData: any = data;

    if (mappedData.acquiring_list) {
      mappedData.acquiring_list = mappedData.acquiring_list.map((item) => item.value);
    }

    mappedData = reduce(
      mappedData,
      (acc, item, field) => {
        if (typeof item === 'object' && item.value) {
          acc[field] = item.value;
        } else {
          acc[field] = item;
        }

        return acc;
      },
      {},
    );

    const response = await api.patch(`${landingsRoute}/bulk_update`, {
      tech_names: selectedRows,
      ...mappedData,
    });

    if (response.error) {
      const ids = get(response, 'error.response.data.error');

      setRowsErrors({
        name,
        errors: getIdsError({ ids, page }),
      });

      return NotificationManager.error(errorText, 'Ошибка');
    }

    Emmiter.emit(EVENTS.SUCCESS_BULK_UPDATED);
    closeModal();

    modalData.onModalSubmit();

    return response;
  };

  const selectedSites = modalData.sites.filter((item) => includes(selectedRows, item.id.toString()));

  const renderTooltip = (
    <Styled.List>
      {selectedSites.slice(0, 5).map((item) => (
        <Styled.ListItem key={item.id}>{item.name}</Styled.ListItem>
      ))}
      {selectedSites.length > 5 && <Styled.ListItem>и т.д.</Styled.ListItem>}
    </Styled.List>
  );

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title isSmall className="modal__title">
        Обновление информации
      </Modal.Title>
      <Styled.Selected>
        {noun(selectedSites.length, 'Выбран', 'Выбрано', 'Выбрано')} {selectedSites.length}
        {` ${noun(selectedSites.length, 'сайт', 'сайта', 'сайтов')}`}
        <Tooltip text={renderTooltip} placement="bottom">
          <QuestionIcon />
        </Tooltip>
      </Styled.Selected>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => {
          const notEmptyValues = some(values, (value) => !!value);

          return (
            <form onSubmit={handleSubmit}>
              <Styled.Row>
                <Styled.Description>Ответственный менеджер</Styled.Description>
                <Styled.Fields>
                  <Styled.Field>
                    <Field name="manager_id">
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Выбрать менеджера"
                          route={companySources}
                          routeName="managersAssign"
                          optionsRenderer={renderMembers}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.Field>
                </Styled.Fields>
              </Styled.Row>
              <Styled.Row>
                <Styled.Description>
                  Инфо в конце
                  <Styled.SmallDescription>Экваринг</Styled.SmallDescription>
                </Styled.Description>
                <Styled.Fields>
                  <Styled.Field>
                    <Field name="acquiring_list[0]">
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Эквайринг"
                          route={companySources}
                          routeName="payments"
                          optionsRenderer={renderPayments}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.Field>
                  {get(values, 'acquiring_list[0]') && (
                    <Styled.Field>
                      <Field name="acquiring_list[1]">
                        {({ input, meta }) => (
                          <InputWithSelect
                            isAsync
                            label="Эквайринг 2"
                            route={companySources}
                            routeName="payments"
                            optionsRenderer={renderPayments}
                            meta={meta}
                            {...input}
                          />
                        )}
                      </Field>
                    </Styled.Field>
                  )}
                  <Styled.Field>
                    <Field name="site_payment_config">
                      {({ input, meta }) => (
                        <Select
                          label="Способ оплаты"
                          options={LANDINGS_PAYMENTS_OPTIONS}
                          meta={meta}
                          {...input}
                          withEmpty
                        />
                      )}
                    </Field>
                  </Styled.Field>
                </Styled.Fields>
              </Styled.Row>
              <Styled.Row>
                <Styled.Description>Юридическая информация</Styled.Description>
                <Styled.Fields>
                  <Styled.Field>
                    <Field name="legal_information">
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="Юридическая информация"
                          route={companySources}
                          routeName="legalInfo"
                          optionsRenderer={renderLegalInfo}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.Field>
                </Styled.Fields>
              </Styled.Row>
              <Modal.Footer mobileRow className="modal__footer">
                <Button type="button" transparent onClick={closeModal}>
                  Отмена
                </Button>
                <Button disabled={!notEmptyValues}>Готово</Button>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Styled.Container>
  );
};

export default LandingsSitesEditInfoModal;
