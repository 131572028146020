import reduce from 'lodash/reduce';
import head from 'lodash/head';
import values from 'lodash/values';

export function isOpenUp({ length, index }: { length: number; index: number }) {
  if (length === index + 1 && length > 4) {
    return true;
  }

  if (length - 1 === index + 1 && length > 4) {
    return true;
  }

  return false;
}

export const getIdsError = ({ ids, page }) =>
  reduce(
    head(values(ids)),
    (acc, curr) => {
      acc[curr.order] = {
        errors: curr.states,
        page,
      };

      return acc;
    },
    {},
  );
