import React from 'react';
import map from 'lodash/map';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import Button from '../../Button';
import ExternalOrders from '../../Order/ExternalOrders';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
}

const PartnerTicketsModal: React.FC<ModalProps> = (props) => {
  const { tickets } = props.data;

  const formattedTickets = map(tickets, (item) => ({
    ...item,
    destination: 'purchases',
    manager: {
      firstname: item.company,
      lastname: ' ',
    },
  }));

  return (
    <Styled.Container>
      <Styled.Title>Партнерские билеты</Styled.Title>
      <Styled.TextInfo>В заказе присутствуют партнерские билеты. Ознакомьтесь с ними ниже.</Styled.TextInfo>
      <Styled.Tickets>
        <ExternalOrders data={formattedTickets} isOpen />
      </Styled.Tickets>
      <Modal.Footer>
        <Button type="button" onClick={props.closeModal}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Styled.Container>
  );
};

export default PartnerTicketsModal;
