import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
    .modal__wrapper {
      max-width: 544px;
    }

    .modal__title {
      margin-bottom: 16px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      .modal {
        &__table-wrapper {
          vertical-align: bottom;
        }

        &__wrapper {
          max-width: 100%;
        }

        &__body {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          max-height: 100vh;
          padding: 16px 16px 32px;
          border-radius: 8px 8px 0 0;
          padding-bottom: 8px;
          box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
          overflow-y: auto;
        }

        &__title {
          margin-bottom: 16px;
        }

        &__footer {
          margin-bottom: -8px;
        }
      }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Row: styled.div`
    display: flex;
    margin-bottom: 48px;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      flex-direction: column;
    }
  `,

  Description: styled.div`
    flex-shrink: 0;
    width: 128px;
    margin-right: 32px;
    font-size: 16px;
    line-height: 21px;

    @media (max-width: ${BREAKPOINTS.SM}) {
      width: auto;
      margin: 0 0 24px;
    }
  `,

  SmallDescription: styled.div`
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
    color: #5c5c5c;
  `,

  Fields: styled.div`
    width: 100%;
  `,

  Field: styled.div`
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  `,

  Selected: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 18px;
    color: #5c5c5c;

    svg {
      margin-left: 5px;
      fill: currentColor;
      height: 1em;
      width: 1em;
    }
  `,

  List: styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
  `,

  ListItem: styled.li``,
};
