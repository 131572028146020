import React, { useEffect, useRef, useState } from 'react';
import { queryFilter } from 'shared/lib/queryFilter';
import Button from '../Button';
import Spinner from '../Spinner';

interface Meta {
  page: number;
  // eslint-disable-next-line camelcase
  last_page: number;
}

interface LoadMoreButtonProps {
  loadMore: () => void;
  meta: Meta;
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
  const { meta } = props;
  const [isLoading, setLoading] = useState(false);
  const mounted = useRef<any>(true);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  const loadMore = async () => {
    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/await-thenable
    await props.loadMore();

    queryFilter({ page: meta.page + 1 });

    if (mounted.current) setLoading(false);
  };

  return (
    <>
      {isLoading && (
        /*
        // @ts-ignore */
        <Spinner.Container>
          <Spinner />
          {/*
          // @ts-ignore */}
        </Spinner.Container>
      )}
      {!isLoading && meta.page !== meta.last_page && (
        <Button transparentBlue onClick={loadMore}>
          Загрузить еще
        </Button>
      )}
    </>
  );
};

export default LoadMoreButton;
