import { AxiosError, AxiosResponse } from 'axios';
import config from 'config/config';
import { createEffect } from 'effector';
import {
  CountryData,
  CountryInfo,
  CountryTranslationInfo,
  CountryTranslationsArrayInfo,
  // eslint-disable-next-line @conarti/feature-sliced/layers-slices
} from 'pages/reference/countries/types';
import { PagePaginationQuery } from '../../factory';
import api from '../../services/api';
import { Country, IBaseRequestDataParams } from '../types';

const route = `${config.COMMON_API_URL}/admin/v1/countries`;

export default {
  root: route,
  detail: (id: number | string) => `${route}/${id}`,
};

export const fetchCountriesFx = createEffect(async (query: PagePaginationQuery = {}) => {
  const response = await api.get(route, {
    params: {
      ...query,
      search_string: query.search,
      limit: 500,
    },
  });

  return response.data;
});

export const fetchCountryFx = createEffect(async (id: number) => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const createCountryFx = createEffect<CountryData, Country, Error>(async ({ title }) => {
  const response = await api.post(route, { code: title });

  return response.data;
});

export const updateCountryFx = createEffect(async ({ id, title }: IBaseRequestDataParams) => {
  const response = await api.patch(`${route}/${id}`, { code: title });

  return response.data;
});

export const createCountryInfoFx = createEffect<CountryTranslationInfo, CountryInfo, AxiosError>(
  async ({ id, translation }) => {
    const response = await api.post(`${route}/${id}/info`, translation);

    return response.data;
  },
);

export const createCountryInfosFx = createEffect<
  CountryTranslationsArrayInfo,
  AxiosResponse<CountryInfo>[],
  AxiosError
>(async ({ id, translations }) => {
  const requests = translations.map(async (translation) => {
    const response = await api.post(`${route}/${id}/info`, translation);

    return response;
  });

  return await Promise.all(requests);
});

export const updateCountryInfoFx = createEffect<
  CountryTranslationInfo,
  AxiosResponse<CountryInfo>,
  AxiosError
>(async ({ id, translation }) => {
  const response = await api.patch(`${route}/${id}/info/${translation.language_code}`, translation);

  return response;
});

export const deleteCountryFx = createEffect(async ({ id }: IBaseRequestDataParams) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});
