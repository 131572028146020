import styled, { css, createGlobalStyle } from 'styled-components';
import { ACTIONS } from 'shared/constants/PREORDER';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { smallModalStyles, mobileModalStyles } from 'shared/mixins/modal';

interface CSSProps {
  width?: string;
  action?: number | string;
}

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}

    ${mobileModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    font-size: 20px;
    margin-bottom: 16px;
  `,

  EventName: styled.div`
    margin: 0 0 4px;
    font-size: 14px;
    line-height: 18px;
  `,

  EventInfo: styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #5c5c5c;
  `,

  TicketsTable: styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-top: 24px;
    }
  `,

  TicketsHeader: styled.div`
    display: flex;
    margin-bottom: 8px;
  `,

  TicketsHeaderCell: styled.div<CSSProps>`
    font-size: 12px;
    color: #999;

    width: ${({ width }) => width};
  `,

  TicketsBody: styled.div`
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 16px;
    }
  `,

  TicketsRow: styled.div`
    display: flex;
  `,

  TicketsRowCell: styled.div<CSSProps>`
    display: flex;
    align-items: flex-start;

    width: ${({ width }) => width};
  `,

  Tickets: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    & > div {
      margin: 4px;
    }
  `,

  Dropdown: styled.div`
    & button {
      padding: 0;
      border-radius: 8px;
    }
  `,

  Ticket: styled.div<CSSProps>`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    user-select: none;

    ${({ action }) => {
      if (action === ACTIONS.IS_FOUND) {
        return css`
          background-color: #e5f8ee;
          border-color: #2dc472;
          color: #2dc472;
        `;
      }

      if (action === ACTIONS.NEED_REPLACE) {
        return css`
          background-color: #fdebeb;
          border-color: #eb5757;
          color: #eb5757;
        `;
      }

      if (action === ACTIONS.SELECTED) {
        return css`
          background-color: #ffda00;
          border-color: #ffda00;
          color: #000;
        `;
      }

      return css`
        background-color: #fff;
        border-color: #e1e1e1;

        &:hover {
          background-color: #fff6c2;
          border-color: #ffda00;
        }
      `;
    }}
  `,

  NumericInput: styled.div`
    & > span {
      display: flex;
    }

    & input {
      padding: 0 14px;
      margin: 0 8px;
      height: 32px;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      border: 1px solid #e1e1e1;
      outline: none;
      order: 2;
      width: 95px;
    }

    & b {
      width: 32px;
      height: 32px;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      border: 1px solid #e1e1e1;
      cursor: pointer;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #fff6c2;
        border-color: #ffda00;
      }
    }

    & b:nth-child(2) {
      position: relative;
      order: 3;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        background-color: #000;
      }

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        background-color: #000;
        transform: rotate(90deg);
      }
    }

    & b:nth-child(3) {
      order: 1;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        background-color: #000;
      }
    }
  `,

  FormRow: styled.div<{ full?: boolean; small?: boolean }>`
    margin-top: ${({ small }) => (small ? '16px' : '40px')};
    display: flex;

    ${({ full }) =>
      !full &&
      css`
        & > div {
          width: calc(50% - 4px);
        }

        & > div:first-child {
          margin-right: 8px;
        }
      `}
  `,

  Helper: styled.p`
    margin: 8px 0 0;
    font-size: 14px;
    color: #5c5c5c;
  `,
};
