import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface ManagerProps {
  switchToTablet?: boolean;
}

export default {
  Manager: styled.div<ManagerProps>`
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-top: 4px;
    font-size: 12px;
    color: #5c5c5c;

    ${({ switchToTablet }) =>
      switchToTablet &&
      css`
        width: 14vw;
        margin: 0;
        line-height: 16px;
        color: #000;
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      width: 14vw;
      margin: 0;
      line-height: 16px;
      color: #000;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: auto;
    }
  `,

  Row: styled.div`
    width: 208px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  `,

  Label: styled.div`
    color: #000;
    opacity: 0.64;
    font-size: 12px;
    margin-bottom: 2px;
  `,

  Text: styled.div`
    color: #000;
    font-size: 12px;
  `,

  Name: styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    color: #000;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,

  NameInner: styled.span`
    margin-right: 4px;
  `,

  SourceIcon: styled.span`
    svg {
      height: 13px;
      &:first-child {
        margin-right: 8px;
      }
    }
  `,
};
