import { Language } from '../types';

export enum VIEW_TYPES {
  GALLERY = 'gallery',
  LIST = 'list',
}

export interface BundleFormData {
  id?: number;
  is_edit?: boolean;
  is_top: boolean;
  started_at: string;
  finished_at: string;
  slug: string;
  view_type: VIEW_TYPES;
  title: string;
  description: string;
  weight: number;
  location_group_id?: {
    label: string;
    value: number;
  };
  preview: { [key: string]: string };
  cover: { [key: string]: string };
  seo: {
    title: string;
    description: string;
  };
}

export interface BundleInfo {
  title: string;
  description: string;
  language_code: string;
}

export type FormSubmitData = {
  lng: Language;
} & { [lang in Language]: BundleFormData };

export interface CreateBundleData {
  view_type: VIEW_TYPES;
  slug: string;
  weight?: number;
  started_at: string;
  finished_at: string;
  is_top: boolean;
}

export interface BundleAttachment {
  attachment_type: {
    code: string;
    id: number;
    title: string;
  };
  data: {
    [key: string]: string;
  };
  id: number;
  info: any[];
}

export interface Bundle {
  attachments: BundleAttachment[];
  categories: any[];
  events: { id: number; info: { title: string; language_code: string }[] }[];
  tournaments: { id: number; title: string }[];
  finished_at: string;
  id: number;
  info: {
    description: string;
    language_code: Language;
    title: string;
  }[];
  is_top: boolean;
  location_group_id?: number;
  slug: string;
  started_at: string;
  view_type: VIEW_TYPES;
  weight: number;
  seo: {
    bundle_id: number;
    info: { title: string; description: string; language_code: Language }[];
  };
}

export interface ListBundle {
  id: number;
  view_type: VIEW_TYPES;
  weight: number;
  started_at: string;
  finished_at: string;
  slug: string;
  is_top: boolean;
  info: {
    title: string;
    language_code: Language;
  }[];
}

export interface PaginatedBundles {
  limit: number;
  page: number;
  count: number;
  last_page: number;
  results: ListBundle[];
}

export interface BundleAttachmentTypes {
  cover: { code: string; id: number; title: string };
  preview: { code: string; id: number; title: string };
}

export interface CheckSlugResponse {
  exist_slugs: string[];
  is_valid: boolean;
}

export interface ResponseError extends Error {
  response: {
    data: {
      detail: string;
    };
  };
}
