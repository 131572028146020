import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import FILTERS_CONFIG from '../constants/FILTERS_CONFIG';
import QUERIES from '../constants/QUERIES';
import { WIDGET_FILTERS } from '../constants/WIDGET';

function findEmptyRules(rules) {
  return rules.find((rule) => {
    if (rule.operator === QUERIES.NONE || rule.operator === QUERIES.ALL) return false;
    if (rule.rules) {
      return findEmptyRules(rule.rules);
    }

    if (Array.isArray(rule.value)) {
      if (rule.field === WIDGET_FILTERS.PRICE) {
        return !rule.value[1];
      }

      return !rule.value.length;
    }

    return rule.field !== WIDGET_FILTERS.ALL_TICKETS && (rule.value === null || rule.value === '');
  });
}

function findNotValidRules(rules) {
  const flattenArray = flattenDeep(
    rules.map((rule) => {
      if (rule.rules) {
        return findNotValidRules(rule.rules);
      }

      const { operator, value } = rule;

      if (Array.isArray(rule.value)) {
        const needCheck = QUERIES.BETWEEN === operator || QUERIES.NOT_BETWEEN === operator;

        if (needCheck && value[0] > value[1]) {
          return '"Цена за позицию" первое значение не может быть больше второго';
        }
      }

      const needCheck = operator === QUERIES.LESS || operator === QUERIES.LESS_OR_EQUAL;

      if (needCheck && value < 1) {
        return '"Цена за позицию" не может быть меньше 1';
      }

      return null;
    }),
  );

  return filter(flattenArray, (item) => item !== null);
}

const getFilterValue = (value) => {
  if (isPlainObject(value)) {
    return value.value;
  }

  if (isArray(value)) {
    return value
      .filter((i) => !!i)
      .map((i) => i.value)
      .join(',');
  }

  return value;
};

const getQueryValue = (value) => {
  if (isPlainObject(value)) {
    return value.data.id;
  }
  if (isArray(value)) {
    return value
      .filter((i) => !!i)
      .map((i) => i.data.id)
      .join(',');
  }

  return value;
};

const getQueryByFormValues = ({
  values,
  ignoredQueries,
  router,
  withoutPagination = false,
  mergedQuery,
  filterConfig = FILTERS_CONFIG,
  resetPagination,
}) => {
  let params: any = reduce(
    omit(values, ignoredQueries),
    (acc: any, value, key) => {
      acc[key] = getFilterValue(value);

      if (filterConfig[key]?.props.isEventFilter) {
        const events = value.reduce(
          (acc, item) => {
            if (!item.isChildren) {
              acc.parent.push({
                ...item,
                value: item.value.replace('_parent', ''),
                data: { ...item.data, id: item.data.id.replace('_parent', '') },
              });
            } else {
              acc.children.push({ ...item, data: { id: item.value } });
            }
            return acc;
          },
          { parent: [], children: [] },
        );
        acc[key] = getQueryValue(events.parent);
        acc['date_id'] = getQueryValue(events.children).concat(
          `${acc['date_id'] ? `,${acc['date_id']}` : ''}`,
        );
      }

      return acc;
    },
    {},
  );
  params = reduce(
    params,
    (acc, value, key) => {
      acc[key] = value && value.toString().trim();
      return acc;
    },
    {},
  );

  if (!withoutPagination) {
    params.limit = router.query.limit;
  }

  if (mergedQuery.length) {
    params = {
      ...params,
      ...reduce(
        mergedQuery,
        (acc, key) => {
          acc[key] = router.query[key];
          return acc;
        },
        {},
      ),
    };
  }

  const page = Number(router.query.page);

  if (resetPagination && !Number.isNaN(page) && page !== 1) {
    params.page = 1;
  }

  return params;
};

export { findEmptyRules, findNotValidRules, getQueryByFormValues };
