import { css } from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

const smallModalStyles = () => css`
  .modal {
    &__wrapper {
      max-width: 400px;
    }

    &__body {
      padding: 24px;
    }

    &__footer {
      margin: 48px -24px -24px -24px;
    }
  }
`;

const mediumModalStyles = () => css`
  .modal {
    &__wrapper {
      max-width: unset;
    }
  }
`;

const mobileModalStyles = () => css`
  @media ${MEDIA_QUERIES.MOBILE} {
    .modal {
      &__close {
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-size: 10px 10px;
      }

      &__table-wrapper {
        vertical-align: bottom;
      }

      &__wrapper {
        max-width: 100%;
      }

      &__body {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        max-height: 100vh;
        padding: 16px;
        overflow: auto;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      }

      &__footer {
        padding: 0 8px;
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: -16px;

        & > div {
          height: auto;
          min-height: 0;
          padding: 8px 0;
        }
      }
    }
  }
`;

export { smallModalStyles, mediumModalStyles, mobileModalStyles };
