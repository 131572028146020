import React from 'react';
import { IHistoryItem } from '../../../../interfaces/historyModal';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import Styled from './styles';

const AnnouncementHistoryModalCreate: React.FC<IHistoryItem> = ({ user }) => {
  return (
    <Styled.Info>
      <Styled.InfoTitle>Создано объявление</Styled.InfoTitle>
      <AnnouncementHistoryModalName data={user} />
    </Styled.Info>
  );
};

export default AnnouncementHistoryModalCreate;
