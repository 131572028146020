import { isString } from 'lodash';
import { ITrigger, ITriggerFormValue } from 'shared/api/landings/constructor/notifications-step/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

export const generateUniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36).slice(2);
};

export const submitTriggers = (submitData: ITriggerFormValue) => {
  const { triggers, color, rounding_corners: roundingCorners } = submitData;

  return triggers.reduce(
    (acc, item) => {
      const submitItem = {
        ...item,
        trigger_type: item.trigger_type,
        value: item.value.value,
        repeat: item.repeat?.value,
        color,
        landing: submitData.tech_name,
        lang: LANGUAGES.RU,
        rounding_corners: roundingCorners,
        id: item.id || generateUniqueId(),
      };

      if (submitItem.id && !isString(submitItem.id)) {
        acc.update.push(submitItem);
      } else {
        acc.create.push(submitItem);
      }
      return acc;
    },
    {
      create: <ITrigger[]>[],
      update: <ITrigger[]>[],
    },
  );
};
