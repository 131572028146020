import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import { NotificationManager } from 'react-notifications';

export const showedErrorNotification = createEffect((message?: string | AxiosError) => {
  const text = typeof message === 'string' ? message : undefined;
  NotificationManager.error(text, 'Ошибка');
});
export const showedSuccesNotification = createEffect(() => {
  NotificationManager.success('Успешно');
});
