import PAGES from './PAGES';

export default {
  [PAGES.MY_SALES]: {
    10: 'Заказ не существует',
    20: 'Заказ отклонён',
    30: 'Заказ завершен',
    40: 'В заказе присутствуют места со статусом «Cтекло»',
    50: 'Заказ оплачен',
    60: 'У заказа уже есть менеджер',
    70: 'У заказа отсутствует менеджер',
  },
};
