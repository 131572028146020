import React from 'react';
import { withRouter } from 'next/router';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import landingsPlaygroundsSources from 'shared/sources/landings/landingsPlaygrounds';
import withLandingData from '../../helpers/withLandingData';
import { withTranslation } from '../../../../i18n';
import LOCALES from 'shared/constants/LOCALES';
import { ModalFunctions } from '../../../../../interfaces/modal';
import PlacesCreate from './PlacesCreate';
import PlacesUpdate from './PlacesUpdate';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  lng: string;
  data: any;
  modalState: string;
  onChangeLng: any;
  updateList: (data: any) => any;
  onUpdate: (data: any) => any;
  showDeleteModal: () => any;
}

const PlacesModal: React.FC<ModalProps> = (props) => {
  const { data, onChangeLng, updateList, showDeleteModal, modalState, onUpdate, lng, closeModal } = props;

  if (modalState === MODAL_STATES.CREATE) {
    return <PlacesCreate onSuccess={updateList} closeModal={closeModal} onChangeLng={onChangeLng} />;
  }

  return (
    <PlacesUpdate
      closeModal={closeModal}
      data={data}
      onSuccess={onUpdate}
      onChangeLng={onChangeLng}
      lng={lng}
      showDeleteModal={showDeleteModal}
    />
  );
};

export default // @ts-ignore
withLandingData(withRouter(withTranslation([LOCALES.FORMS, LOCALES.LANDINGS])(PlacesModal)), {
  translation: 'playground',
  sources: landingsPlaygroundsSources,
  emitUpdateTableAfterSave: true,
});
