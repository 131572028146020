import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Script from 'react-load-script';
import type { IBasicEvent } from 'shared/api/events';
import { ERRORS } from 'shared/constants/ERRORS';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import { getToken } from 'shared/lib/auth';
import SchemeContext from './SchemeContext';
import Styled from './styles';

interface ModalProps {
  event: IBasicEvent;
  selectTicketFromScheme: () => void;
  selectedTicketsIds: any[];
}

const SchemePoolModal: React.FC<ModalProps> = (props) => {
  const { event, selectedTicketsIds, selectTicketFromScheme } = props;
  const [error, setError] = useState<string>();
  const router = useRouter();

  useEffect(() => {
    return () => {
      if (window.pwidget) {
        window.pwidget.unmount();
        window.pwidget = null;
      }
    };
  });

  const renderWidget = () => {
    if (!event) {
      return setError(ERRORS.DEFAULT);
    }

    window.pwidget = new window.PWidget({
      node: 'widget',
      settings: {
        withoutFullscreen: true,
        height: '100%',
        token: getToken(),
        selectedTickets: selectedTicketsIds,
      },
      poolSettings: {
        eventId: event.id,
        hallLayoutId: event.hall_layout_id,
      },
      onAddTicket: selectTicketFromScheme,
      onDeleteTicket: selectTicketFromScheme,
    });
  };

  const date = event?.dates?.find((date) => date.id === Number(router.query.event));

  return (
    <Styled.Wrapper>
      <Styled.Widget id="widget">{error && <Styled.Error>{error}</Styled.Error>}</Styled.Widget>
      {event && (
        <Styled.Bar>
          <Styled.EventData>
            {event.title}
            {event.place && (
              <Styled.Description>
                {`${event.place.title}, ${formatFullDateAndTime(`${date?.started_at}`)}`}
              </Styled.Description>
            )}
          </Styled.EventData>
        </Styled.Bar>
      )}
      <SchemeContext event={event} />
      <Script
        url={`${process.env.WIDGET_SRC}${process.env.WIDGET_VERSION}/widget.js?v=4.0.0`}
        onLoad={renderWidget}
      />
    </Styled.Wrapper>
  );
};

export default SchemePoolModal;
