import { createEvent, createStore, sample } from 'effector';
import { isEmpty } from 'lodash';
import {
  createTriggerFx,
  deleteTriggerFx,
  fetchTriggersFx,
  IConstructorLandingData,
  updateTriggerFx,
} from 'shared/api/landings/constructor';
import {
  ITriggerFormValue,
  ITriggers,
  ITriggerUpdate,
} from 'shared/api/landings/constructor/notifications-step/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { showedErrorNotification } from 'shared/helpers/notification';
import { submitTriggers } from '../lib/submit-triggers';

const stepMounted = createEvent<{ landingData: IConstructorLandingData; lng: string }>();
const formSubmitted = createEvent<ITriggerFormValue>();
const deleteSubmitted = createEvent<number>();

const $triggersData = createStore<ITriggers>({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});
const $landingData = createStore<IConstructorLandingData>({} as IConstructorLandingData);
const $loading = createStore<boolean>(true);
const $lang = createStore<string>(LANGUAGES.RU);

export const notificationsStepModel = {
  stepMounted,
  $loading,
  $triggersData,
  formSubmitted,
  deleteSubmitted,
};

$loading.on(fetchTriggersFx, () => true).on(fetchTriggersFx.done, () => false);

sample({
  clock: stepMounted,
  fn: ({ landingData }) => landingData,
  target: $landingData,
});

sample({
  clock: stepMounted,
  fn: ({ lng }) => lng,
  target: $lang,
});

sample({
  clock: stepMounted,
  fn: ({ landingData }) => landingData.tech_name,
  target: fetchTriggersFx,
});

sample({
  clock: fetchTriggersFx.done,
  fn: ({ result }) => result,
  target: $triggersData,
});

sample({
  clock: [createTriggerFx.fail, updateTriggerFx.fail, deleteTriggerFx.fail],
  fn: () => '',
  target: showedErrorNotification,
});

//create

sample({
  clock: formSubmitted,
  filter: (formData) => {
    const createTriggers = submitTriggers(formData).create;

    return !isEmpty(createTriggers);
  },

  fn: (formData) => {
    const createTriggers = submitTriggers(formData).create;
    const data = createTriggers.map((trigger) => {
      return {
        color: trigger.color,
        rounding_corners: trigger.rounding_corners,
        text: trigger.text,
        link: trigger.link,
        trigger_type_id: trigger.trigger_type.id,
        repeat: trigger.repeat,
        value: trigger.value,
        language_code: LANGUAGES.RU,
        landing_tech_name: formData.tech_name,
      };
    });
    return data;
  },
  target: createTriggerFx,
});

//update

sample({
  clock: formSubmitted,
  filter: (formData) => {
    const updateTriggers = submitTriggers(formData).update;

    return !isEmpty(updateTriggers);
  },

  fn: (formData) => {
    const updateTriggers = submitTriggers(formData).update;

    const data: ITriggerUpdate[] = updateTriggers.map((trigger) => {
      return {
        id: trigger.id,
        color: trigger.color,
        rounding_corners: trigger.rounding_corners,
        text: trigger.text,
        link: trigger.link,
        trigger_type_id: trigger.trigger_type.id,
        repeat: trigger.repeat,
        value: trigger.value,
        language_code: LANGUAGES.RU,
        landing_tech_name: formData.tech_name,
      };
    });
    return data;
  },
  target: updateTriggerFx,
});

//delete

sample({
  clock: deleteSubmitted,
  target: deleteTriggerFx,
});
