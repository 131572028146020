import React, { Component } from 'react';
import head from 'lodash/head';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { ModalTable } from '../Table';
import Styled from './styles';
import StatusDropdown from '../StatusDropdown';
import { ANNOUNCEMENT_STATUS_ARRAY } from 'shared/constants/ANNOUNCEMENT_STATUS';
import TableSeatsSelector from '../TableSeatsSelector';
import TableRowSelector from 'containers/TableRowSelector';
import TABLES from 'shared/constants/TABLES';
import { ACTIONS } from 'shared/constants/GLASS_TICKETS_MODAL';
import { selectSeat } from 'shared/helpers/tickets';
import TableSeats from '../TableSeats';
import GlassTicketsModalDropdown from '../GlassTicketsModalDropdown';
import Tooltip from '../Tooltip';

interface RowProps {
  tickets?: any;
  changeStatus: (data: any) => any;
  changeAction: (data: any) => any;
  cellProps: any;
  announcement: any;
  changeDetailAction: (data: any) => any;
  data: any[];
}

interface RowState {
  isOpen: boolean;
  selectedAnnouncements: any[];
}

class GlassTicketsModalTableRow extends Component<RowProps, RowState> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedAnnouncements: [],
    };
  }

  handleSelectSeat = (data) => {
    this.setState(({ selectedAnnouncements }) => ({
      selectedAnnouncements: selectSeat(data, selectedAnnouncements),
    }));
  };

  handleDetailActionChange = (action) => {
    const { selectedTickets } = head(this.state.selectedAnnouncements);
    const { id, action: oldAction, status } = this.props.announcement;

    this.props.changeDetailAction({
      tickets: selectedTickets,
      id,
      newAction: action,
      oldAction,
      status,
    });
    this.setState({
      isOpen: false,
      selectedAnnouncements: [],
    });
  };

  get selectedDuplicateTicket() {
    const { selectedAnnouncements } = this.state;

    const currentTickets =
      find(get(head(selectedAnnouncements), 'selectedTickets'), { duplicate: true }) || {};

    return !isEmpty(currentTickets);
  }

  toggleRow = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  hanldeActionChange = (action) => {
    const { id, action: oldAction, status } = this.props.announcement;

    this.props.changeAction({
      id,
      status,
      oldAction,
      newAction: action,
    });
  };

  hanldeStatusChange = (status) => {
    const { id, action, status: oldStatus } = this.props.announcement;

    this.props.changeStatus({
      id,
      action,
      oldStatus,
      newStatus: status,
    });
  };

  render() {
    const { isOpen, selectedAnnouncements } = this.state;
    const { cellProps, announcement, data } = this.props;
    const { id, action, status, groupedTickets, tickets } = announcement;
    const isStanding = !head<any>(tickets).seat;
    const withDuplicates: any = find(head(groupedTickets), { duplicate: true });
    let selectedTicketsCount;

    if (selectedAnnouncements.length > 0) {
      const currentTickets = head(selectedAnnouncements).selectedTickets;

      if (isStanding) {
        selectedTicketsCount = currentTickets;
      } else {
        selectedTicketsCount = currentTickets.length;
      }
    }

    const renderTooltipText = () => {
      if (!selectedTicketsCount) {
        return 'Необходимо выбрать билеты';
      }

      if (tickets.length === selectedTicketsCount) {
        return 'Нельзя разделить все билеты в объявлении';
      }

      return null;
    };

    const sector = get(announcement, 'sector');
    const row = get(announcement, 'row');

    return (
      <>
        <ModalTable.Row isOpen={isOpen}>
          <ModalTable.Cell {...cellProps[1]}>
            <TableRowSelector
              id={`${id}_${action}_${status}`}
              ids={data.map((item) => `${item.id}_${item.action}_${item.status}`)}
              cellProps={cellProps[1]}
              page={TABLES.GLASS_TICKETS_MODAL}
            />
          </ModalTable.Cell>
          <ModalTable.Cell {...cellProps[2]}>
            <Tooltip text={sector}>
              <Styled.Sector>{sector}</Styled.Sector>
            </Tooltip>
          </ModalTable.Cell>
          <ModalTable.Cell {...cellProps[3]}>{row}</ModalTable.Cell>
          <ModalTable.Cell {...cellProps[4]}>
            <Styled.TableSeatsWrapper
              isOpen={isOpen}
              disabled={tickets.length === 1}
              onClick={tickets.length !== 1 ? this.toggleRow : undefined}
            >
              <TableSeats isOpen={isOpen} groupedTickets={groupedTickets} />
            </Styled.TableSeatsWrapper>
          </ModalTable.Cell>
          <ModalTable.Cell {...cellProps[5]}>
            {action === ACTIONS.SALE && (
              <Styled.StatusDropdown>
                <StatusDropdown
                  onChange={this.hanldeStatusChange}
                  defaultValue={ANNOUNCEMENT_STATUS_ARRAY[status]}
                />
              </Styled.StatusDropdown>
            )}
            <GlassTicketsModalDropdown
              action={action}
              withDuplicates={withDuplicates}
              onClick={this.hanldeActionChange}
            />
          </ModalTable.Cell>
        </ModalTable.Row>
        {isOpen && (
          <Styled.Row>
            <Styled.RowMain>
              <TableSeatsSelector
                maxSeats={tickets.length - 1}
                announcement={announcement}
                selectSeat={this.handleSelectSeat}
                selectedAnnouncements={selectedAnnouncements}
              />
            </Styled.RowMain>
            <Styled.RowFooter>
              <Styled.SubText>
                <Styled.SubTextLabel>
                  {selectedTicketsCount ? 'Выбрано:' : 'Выберите места'}
                </Styled.SubTextLabel>
                {selectedTicketsCount > 0 && (
                  <Styled.SubTextValue>{`${selectedTicketsCount} шт`}</Styled.SubTextValue>
                )}
              </Styled.SubText>
              <Tooltip text={renderTooltipText()} placement="left">
                <div>
                  <GlassTicketsModalDropdown
                    action={ACTIONS.UNSET}
                    onClick={this.handleDetailActionChange}
                    selectedDuplicateTicket={this.selectedDuplicateTicket}
                    disabled={!selectedTicketsCount || tickets.length === selectedTicketsCount}
                  />
                </div>
              </Tooltip>
            </Styled.RowFooter>
          </Styled.Row>
        )}
      </>
    );
  }
}

export default GlassTicketsModalTableRow;
