import styled, { createGlobalStyle } from 'styled-components';
import Modal from 'components/Modal';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
      .modal__wrapper {
        max-width: 400px;
      }
    `,

  PopoverGlobalStyles: createGlobalStyle`
      .modal__overlay {
        overflow: hidden;
      }
    `,

  Popover: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 4;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.MD}) {
      height: 100vh;
    }
  `,

  Container: styled.div`
    max-width: 400px;
    padding: 24px 30px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
  `,

  Top: styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #000;
  `,

  Question: styled.div`
    margin-right: 16px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    background-color: #fce4e4;
    background-image: url('/static/icons/question-red.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
  `,

  Text: styled.div`
    margin-top: 16px;
    color: #5c5c5c;
    font-size: 16px;
    word-wrap: break-word;
  `,

  Buttons: styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    & > button {
      width: calc(50% - 4px);
    }
  `,

  PriceItem: styled.div`
    position: relative;
    z-index: 4;
    margin: 10px 0;
  `,

  Footer: styled(Modal.Footer)`
    margin: auto -30px -30px !important;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: row;
      padding: 0 16px;

      & > button {
        width: auto;

        &:not(:first-child) {
          margin-left: 7px;
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  `,
};
