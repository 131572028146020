// @ts-nocheck
import React from 'react';
import QueryBuilder from 'react-querybuilder';
import QUERIES from 'shared/constants/QUERIES';
import { WIDGET_FILTERS } from 'shared/constants/WIDGET';
import RemoveButton from './RemoveButton';
import ValueSelector from './ValueSelector';
import Action from './Action';
import FieldSelect from './FieldSelect';
import ValueEditor from './ValueEditor';

const fields = [
  { name: WIDGET_FILTERS.PARSERS, label: 'Парсинг' },
  { name: WIDGET_FILTERS.SECTOR, label: 'Сектор' },
  { name: WIDGET_FILTERS.ROW, label: 'Ряд' },
  { name: WIDGET_FILTERS.CATEGORY, label: 'Категория' },
  { name: WIDGET_FILTERS.PROVIDER, label: 'Поставщик' },
  { name: WIDGET_FILTERS.COMPANY, label: 'Брокер' },
  { name: WIDGET_FILTERS.ZRITELI, label: 'Зрители' },
];

const operators = [
  { name: QUERIES.IN, label: 'Соответствует' },
  { name: QUERIES.NOT_IN, label: 'Не соответствует' },
  { name: QUERIES.EQUAL, label: 'Равен' },
  { name: QUERIES.NOT_EQUAL, label: 'Не равен' },
];

interface Props {
  query: any;
  setQuery?: (data: any) => any;
  isPreview: boolean;
}

const WidgetQueryBuilder: React.FC<Props> = ({ query, setQuery, isPreview }) => (
  <QueryBuilder
    query={query}
    fields={fields}
    operators={operators}
    onQueryChange={setQuery}
    controlElements={{
      combinatorSelector: ValueSelector,
      addGroupAction: Action,
      addRuleAction: Action,
      fieldSelector: FieldSelect,
      operatorSelector: FieldSelect,
      removeRuleAction: RemoveButton,
      removeGroupAction: RemoveButton,
      valueEditor: ValueEditor,
    }}
    translations={{
      fields: {
        title: 'Fields',
      },
      operators: {
        title: 'Operators',
      },
      value: {
        title: 'Value',
      },
      removeRule: {
        label: 'x',
        title: 'Удалить критерий',
      },
      removeGroup: {
        label: 'x',
        title: 'Удалить группу',
      },
      addRule: {
        label: 'Добавить критерий',
        title: 'Добавить критерий',
      },
      addGroup: {
        label: 'Добавить группу',
        title: 'Добавить группу',
      },
      combinators: {
        title: 'Combinators',
      },
    }}
    combinators={[
      { name: 'and', label: 'И' },
      { name: 'or', label: 'Или' },
    ]}
    isPreview={isPreview}
  />
);

export default WidgetQueryBuilder;
