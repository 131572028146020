import { CancelToken } from 'axios';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import convertToCamelCase, { POSTFIXS } from '../helpers/convertToCamelCase';
import { getData } from '../helpers/data';
import { updateQuery } from '../helpers/routes';
import api from '../services/api';
import Emitter from '../services/Emmiter';
import { queryFilter } from './queryFilter';
import { i18n } from 'next-i18next';

export const USE_LIST_RELOAD = 'USE_LIST_RELOAD';
export const USE_LIST_ITEM_CREATE = 'USE_LIST_ITEM_CREATE';
export const USE_LIST_ITEM_UPDATE = 'USE_LIST_ITEM_UPDATE';
export const USE_LIST_ITEM_MERGE = 'USE_LIST_ITEM_MERGE';
export const USE_LIST_ITEM_REMOVE = 'USE_LIST_ITEM_REMOVE';

const filterQuery = (q) => omit(q, 'modalId', 'modalType');
export interface ListParams {
  [key: string]: any;
}

interface IState {
  items: any[];
  meta: {
    count?: number;
    // eslint-disable-next-line camelcase
    last_page?: number;
    limit?: number;
    page?: number;
  };
  isLoading: boolean;
  isFail: boolean;
  isLoadingMore: boolean;
  isItemLoading: boolean;
  useNestedRoute?: boolean;
  prevQuery?: {
    [key: string]: string | string[];
  };
}

const getRootPath = () => 'root';

const useList = (
  {
    source,
    getSourcePath = getRootPath,
    useNestedRoute = true,
    createFilter,
    requestWithItems,
    ...params
  }: ListParams,
  page?: string,
) => {
  const router = useRouter();

  const [state, setState] = useState<IState>({
    items: [],
    meta: {},
    isLoading: true,
    isFail: false,
    isLoadingMore: false,
    isItemLoading: false,
    prevQuery: router.query,
  });
  // @ts-ignore
  const sourceRequestToken = CancelToken.source();

  const init = () => {
    setState((prevState) => ({ ...prevState, items: [], meta: {} }));
  };

  const savedQuery = page
    ? useSelector(
        (selectorState) =>
          // @ts-ignore
          selectorState.tables.queries[
            convertToCamelCase({ str: page, postfix: POSTFIXS.PAGE_SAVED_QUERIES })
          ],
      )
    : {};

  const getQueryData = (queries) => {
    const withSavedParams = page && !isEmpty(savedQuery) && isEmpty(queries);
    let currentParams = withSavedParams
      ? { ...queries, ...savedQuery, ...router.query }
      : { ...queries, ...router.query };
    currentParams = omit(currentParams, ['modalId', 'modalType']);

    return {
      params: currentParams,
      withSavedParams,
    };
  };

  const loadUntilLastPage = async (prevPageItems, prevPageMeta) => {
    const response = await api.get(source[getSourcePath()], {
      params: {
        ...params.query,
        ...filterQuery(router.query),
        page: Number(prevPageMeta.page) + 1,
      },
      cancelToken: sourceRequestToken.token,
    });
    const { results, ...rest } = getData(response);
    const combinedItems = [...prevPageItems, ...results];
    setState((prevState) => ({ ...prevState, items: combinedItems, meta: rest, isLoading: false }));

    if (params.loadUntilLastPage && rest.page < rest.last_page) {
      loadUntilLastPage(combinedItems, rest).catch((err) => new Error(err));
    }
  };

  const getList = async (skipCleaning?: boolean) => {
    if (!source) {
      init();
      return;
    }

    if (!state.isLoadingMore) {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isFail: false,
        ...(!skipCleaning && { items: [] }),
      }));
    }

    try {
      const { params: currentParams } = getQueryData(router.query);
      const routerQuery = filterQuery(router.query);

      if (!isEqual(currentParams, routerQuery)) {
        queryFilter(currentParams);
        return;
      }

      let requestParams = {
        ...params.query,
        ...currentParams,
        ...(params.languageCode && { language_code: params.languageCode }),
      };

      if (createFilter && !isEmpty(requestParams)) {
        const filter = await createFilter(requestParams);

        requestParams = filter;
      }

      const response = await api.get(source[getSourcePath()], {
        params: requestParams,
        cancelToken: sourceRequestToken.token,
      });

      const { results, ...rest } = getData(response);
      let items = state.isLoadingMore ? [...state.items, ...results] : results;

      if (requestWithItems) {
        items = await requestWithItems(items);
      }
      setState((prevState) => ({
        ...prevState,
        items,
        meta: rest,
        ...(state.isLoadingMore ? { isLoadingMore: false } : { isLoading: false }),
      }));

      if (+router.query.page > 1 && !results.length) {
        updateQuery({ page: +router.query.page - 1 });
      }

      if (params.loadUntilLastPage && rest.page < rest.last_page) {
        loadUntilLastPage(results, rest).catch((err) => new Error(err));
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, isFail: true, isLoading: false, isLoadingMore: false }));
    }
  };

  const onItemCreate = (item) => {
    setState({
      ...state,
      ...(item.ru && { items: [...state.items, item.ru] }),
      meta: { ...state.meta, count: state.meta.count + 1 },
    });
  };

  const onItemUpdate = (item) => {
    if (item.tech_name) {
      const updatedItems = state.items.map((i) => {
        return item.tech_name === i.tech_name ? { ...i, ...item } : i;
      });

      setState((prevState) => ({ ...prevState, items: updatedItems }));
      return;
    }

    if (item.ru) {
      const updatedItems = state.items.map((i) => (i.id === item.ru.id ? item.ru : i));
      setState((prevState) => ({ ...prevState, items: updatedItems }));
      return;
    }
    if (item.id) {
      const updatedItems = state.items.map((i) => (i.id === item.id ? item : i));

      setState((prevState) => ({ ...prevState, items: updatedItems }));
    }
  };

  const onItemRemove = (id) => {
    const updatedItems = state.items.filter((item) => item.id !== id);
    setState((prevState) => ({
      ...prevState,
      items: updatedItems,
      meta: { ...state.meta, count: state.meta.count - 1 },
    }));
  };

  const onMergeItem = (data) => {
    const updatedItems = state.items.map((item) =>
      item.id === data.id
        ? {
            ...item,
            ...data,
          }
        : item,
    );

    setState((prevState) => ({ ...prevState, items: updatedItems }));
  };

  const reloadList = () => {
    getList(true).catch((err) => new Error(err));
  };

  useEffect(() => {
    const shouldReload =
      !isEqual(filterQuery(router.query), filterQuery(state.prevQuery)) &&
      (useNestedRoute ? router.query.name === state.prevQuery.name : true);

    if (shouldReload) {
      getList().catch((err) => new Error(err));
    }
    setState((prevState) => ({ ...prevState, prevQuery: filterQuery(router.query) }));

    return () => {
      if (router.query !== state.prevQuery) {
        sourceRequestToken.cancel();
      }
    };
  }, [router.query]);
  useEffect(() => {
    Emitter.on(USE_LIST_RELOAD, reloadList);
    Emitter.on(USE_LIST_ITEM_CREATE, onItemCreate);
    Emitter.on(USE_LIST_ITEM_UPDATE, onItemUpdate);
    Emitter.on(USE_LIST_ITEM_REMOVE, onItemRemove);
    Emitter.on(USE_LIST_ITEM_MERGE, onMergeItem);

    return () => {
      Emitter.removeListener(USE_LIST_RELOAD, reloadList);
      Emitter.removeListener(USE_LIST_ITEM_CREATE, onItemCreate);
      Emitter.removeListener(USE_LIST_ITEM_UPDATE, onItemUpdate);
      Emitter.removeListener(USE_LIST_ITEM_REMOVE, onItemRemove);
      Emitter.removeListener(USE_LIST_ITEM_MERGE, onMergeItem);
    };
  }, [state.items]);

  useEffect(() => {
    init();
    getList().catch((err) => new Error(err));

    return () => {
      sourceRequestToken.cancel();
    };
  }, [source]);

  const update = async (item) => {
    setState((prevState) => ({ ...prevState, isItemLoading: true }));
    try {
      const response = await api.patch(source.detail(item.id), item);
      const updatedItems = state.items.map((i) =>
        i.id === response.data.id ? { ...i, ...response.data } : i,
      );

      setState((prevState) => ({ ...prevState, isItemLoading: false, items: updatedItems }));
      return response.data;
    } catch (error) {
      setState((prevState) => ({ ...prevState, isItemLoading: false }));
      return error.response.data;
    }
  };

  const remove = async (item) => {
    try {
      const response = await api.delete(source.detail(item.id));

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

  const loadMore = () => {
    setState((prevState) => ({ ...prevState, isLoadingMore: true }));
  };

  return {
    getList,
    items: state.items,
    meta: state.meta,

    state: {
      isLoading: state.isLoading,
      isFail: state.isFail,
      isItemLoading: state.isItemLoading,
      isLoadingMore: state.isLoadingMore,
    },
    setState,

    loadMore,
    reloadList,
    update,
    remove,

    onMergeItem,
  };
};

export default useList;
