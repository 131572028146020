import styled, { createGlobalStyle } from 'styled-components';

export default {
  GlobalStyles: createGlobalStyle`
    .modal {
      &__wrapper {
        max-width: 400px;
      }
    }
  `,

  Title: styled.div`
    font-size: 20px;
    margin-bottom: 24px;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Prices: styled.div`
    margin-top: 24px;
    display: flex;
  `,

  OldPrice: styled.div`
    display: flex;
    align-items: flex-end;
  `,

  NewPrice: styled.div`
    margin-left: 60px;
    display: flex;
    align-items: flex-end;
  `,

  PriceTitle: styled.div`
    margin: 0 8px 1px 0;
    display: flex;
    font-size: 12px;
    color: #5c5c5c;
  `,

  Price: styled.div`
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
  `,
};
