import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Body: styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 688px;
    height: 100%;
    padding: 32px 40px;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 16px 16px 32px;
      border-radius: 8px 8px 0 0;
    }
  `,

  SpinnerContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
