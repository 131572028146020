enum RoundRules {
  hundred = 'hundred',
  ten = 'ten',
  unit = 'unit',
  cent = 'cent',
}

const ROUND_RULES_OPTIONS = [
  { value: RoundRules.hundred, name: 'Сотни' },
  { value: RoundRules.ten, name: 'Десятки' },
  { value: RoundRules.unit, name: 'Единицы' },
  { value: RoundRules.cent, name: 'Копейки' },
];

const ROUND_SCOPE_TYPE = {
  EVENT_SOURCE: 'EVENT_SOURCE',
  COMPANY: 'COMPANY',
  SOURCE: 'SOURCE',
  SYSTEM: 'SYSTEM',
};

export default {
  ROUND_RULES_OPTIONS,
  RoundRules,
  ROUND_SCOPE_TYPE,
};
