import config from 'config/config';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import COOKIES from 'shared/constants/COOKIES';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { createAttachments } from 'shared/lib/attachments';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';
import attachmentsSource from 'shared/sources/attachments';
import landingsPersonalitiesSources from 'shared/sources/landings/landingsPersonalities';
import { buildPersonsAttachments } from '../../helpers/landingHelpers';
import PersonsForm from './PersonsForm';

interface PersonsCreateProps {
  closeModal: () => void;
  onSuccess: (data: any) => any;
  onChangeLng: () => void;
}

const PersonsCreate: React.FC<PersonsCreateProps> = (props) => {
  const { closeModal, onSuccess, onChangeLng } = props;

  const handleCreate = async (formData) => {
    const { ru: personData } = formData;
    const attachmentTypes = await attachmentsSource.getAttachmentsTypes('persons');
    const attachments = await buildPersonsAttachments(personData);
    const companyId = getCookie(COOKIES.COMPANY);

    const response = await api.post(landingsPersonalitiesSources.root, {
      person_id: personData.external.value,
      slug: '',
      company_id: companyId,
    });
    await attachmentsSource.createPersonsAttachments({
      event_id: response.data.id,
      attachmentsData: attachments,
    });

    const infoAttachments = createAttachments(
      { attachments: [] },
      { lng: 'ru', ru: personData },
      attachmentTypes,
    );

    await Promise.all(
      infoAttachments
        .filter((attachment) => !isEmpty(attachment.info))
        .map(async (attachment) => {
          const route = `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons`;

          const infoResponse = await api.post(
            `${route}/${response.data.id}/attachments/${attachment.attachment_type_id}/info`,
            {
              ...attachment.info,
              language_code: attachment.language_code,
            },
          );

          return infoResponse;
        }),
    );

    const translations = await landingsPersonalitiesSources.createTranslation(response.data.id, {
      ...personData,
      language_code: LANGUAGES.RU,
    });

    await landingsPersonalitiesSources
      .createContentGroups(
        response.data.id,
        personData.contentGroups.map((i) => i.value),
      )
      .catch((error) => {
        if (error.response?.data?.detail === 'Already exists') {
          NotificationManager.error('Данная персона с таким тегом существует', 'Ошибка');
        }
        return error;
      });

    await landingsPersonalitiesSources.createQnA(
      response.data.id,
      (personData.qna || []).map((i) => i.value),
    );

    return translations;
  };

  return (
    <PersonsForm
      key="create"
      title="landings:personality.create_title"
      onSubmit={handleCreate}
      onSuccess={onSuccess}
      closeModal={closeModal}
      onChangeLng={onChangeLng}
      initialValues={{
        item_create: true,
      }}
    />
  );
};

export default PersonsCreate;
