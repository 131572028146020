import { invoke } from '@withease/factories';
import { createStore, sample } from 'effector';
import sortBy from 'lodash/sortBy';

import * as countriesApi from 'shared/api/reference/country';
import { ParsedCountriesList } from 'shared/api/types';
import { createPagesMountStates } from 'shared/factory';
import { groupAlphabetically } from 'shared/helpers/array';

export const { pageMounted, $query: $countriesQuery, $loading } = invoke(createPagesMountStates);

export const $countries = createStore<ParsedCountriesList>({
  results: {},
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});

sample({
  clock: countriesApi.fetchCountriesFx.done,
  fn: () => false,
  target: $loading,
});

sample({
  source: { countriesQuery: $countriesQuery },
  fn: ({ countriesQuery }) => countriesQuery,
  target: countriesApi.fetchCountriesFx,
});

sample({
  clock: countriesApi.fetchCountriesFx.done,
  fn: ({ result }) => {
    const { results, ...meta } = result;
    return {
      ...meta,
      results: groupAlphabetically(sortBy(results, 'code')),
    };
  },
  target: $countries,
});
