import styled, { css } from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import COLORS from 'shared/constants/COLORS';

interface CSSProps {
  active?: boolean;
  current?: boolean;
  isHidden?: boolean;
  isVertical?: boolean;
  horizontalOn?: any;
  mobileHorizontal?: boolean;
  selectable?: boolean;
  isInvalid?: boolean;
}

export default {
  Wrapper: styled.div<CSSProps>`
    display: ${({ isHidden }) => isHidden && 'none'};
    position: relative;
    flex: 1 1 100%;

    &:not(:last-child)::before,
    &:not(:last-child)::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 50%;
      bottom: 8px;
      background: #f5f5f5;
      z-index: 1;
    }

    &:not(:last-child)::after {
      width: ${(props) => (props.active && !props.current ? '100%' : '0')};
      background: #2dc472;
      transition: width 0.2s ease;
      z-index: 2;
    }

    ${(props) =>
      props.isVertical &&
      css`
        margin-bottom: 24px;

        &:not(:last-child)::before,
        &:not(:last-child)::after {
          content: '';
          position: absolute;
          height: 50px;
          width: 2px;
          left: 8px;
          top: 0;
          background: #f5f5f5;
          z-index: 1;
        }

        &:not(:last-child)::after {
          display: none;
        }
      `}

    @media ${({ horizontalOn }) => horizontalOn || MEDIA_QUERIES.MOBILE} {
      ${({ mobileHorizontal, active }) =>
        mobileHorizontal &&
        css`
          flex: 0 0 auto;
          margin-bottom: 0;

          &:not(:last-child)::before,
          &:not(:last-child)::after {
            display: none;
          }

          &:not(:last-child)::after {
            position: absolute;
            top: 4px;
            left: auto;
            right: 0;
            bottom: auto;
            display: block;
            width: auto;
            height: auto;
            content: '';
            border-style: solid;
            padding: 3px;
            border-width: 1px;
            border-color: ${active
              ? '#2dc472 #2dc472 transparent transparent'
              : '#e1e1e1 #e1e1e1 transparent transparent'};
            background: none;
            z-index: 2;
            transform: rotate(45deg);
          }
        `}
    }
  `,

  Progress: styled.div<CSSProps>`
    display: flex;
    width: 100%;
    align-items: center;

    ${(props) =>
      props.isVertical &&
      css`
        flex-direction: column;
        align-items: flex-start;
      `}

    @media ${({ horizontalOn }) => horizontalOn || MEDIA_QUERIES.MOBILE} {
      ${({ mobileHorizontal }) =>
        mobileHorizontal &&
        css`
          flex-direction: row;
        `}
    }
  `,

  Button: styled.div<CSSProps>`
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    color: ${(props) => (props.active ? '#000' : 'rgba(0, 0, 0, 0.64)')};
    transition: color 0.2s ease;

    ${(props) =>
      props.selectable &&
      css`
        &:hover {
          cursor: pointer;
          color: #000;
        }
      `}

    ${(props) =>
      props.isVertical &&
      css`
        display: flex;
        flex-direction: row-reverse;
      `}

    ${(props) =>
      props.isInvalid &&
      css`
        color: ${COLORS.RED};
      `}

    @media ${({ horizontalOn }) => horizontalOn || MEDIA_QUERIES.MOBILE} {
      ${({ mobileHorizontal }) =>
        mobileHorizontal &&
        css`
          flex-shrink: 0;
          flex-direction: row;
          padding: 0 16px 9px;
        `}
    }
  `,

  Circle: styled.div<CSSProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 4px auto 0;
    border: 1px solid ${(props) => (props.active ? '#2dc472' : '#E1E1E1')};
    border-radius: 16px;
    background: ${(props) => (props.active && !props.current ? '#2dc472' : '#fff')};
    z-index: 3;
    transition: border-color 0.2s ease;

    svg {
      width: 8px;
      height: 8px;
    }

    ${(props) =>
      props.isVertical &&
      css`
        margin: 0 16px 0 0;
      `}

    @media ${({ horizontalOn }) => horizontalOn || MEDIA_QUERIES.MOBILE} {
      ${({ mobileHorizontal, active, current }) =>
        mobileHorizontal &&
        css`
          position: absolute;
          left: 0;
          bottom: 0;
          width: ${current ? 'calc(100% - 16px)' : '100%'};
          height: 2px;
          margin: 0;
          border: none;
          border-radius: 0;
          background-color: ${(active || current) && '#2dc472'};

          svg {
            display: none;
          }
        `}
    }
  `,
};
