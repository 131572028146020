import { connect } from 'react-redux';
import AnnouncementPlanningPriceModal from 'components/Modals/AnnouncementPlanningPriceModal';
import {
  loadAnnouncement,
  loadPriceChangeSchedule,
  editPriceChangeSchedule,
  deletePriceChangeSchedule,
  createPriceChangeSchedule,
} from 'entities/announcement';

export default connect(null, {
  loadAnnouncement,
  loadPriceChangeSchedule,
  editPriceChangeSchedule,
  deletePriceChangeSchedule,
  createPriceChangeSchedule,
})(AnnouncementPlanningPriceModal);
