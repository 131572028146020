import React from 'react';
import styled from 'styled-components';
import { PRICE_CHANGE_TYPES } from 'shared/constants/ORDER';
import { NumberInput, Select } from '../../../FormControls';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface ControlsProps {
  onChangeMarkup: (value: number) => void;
  onChangeMarkupValue: (value: string | number) => void;
  disabled?: boolean;
  markup?: number | string;
  // eslint-disable-next-line camelcase
  markup_value?: number | string;
}

const OPTIONS = [
  { id: PRICE_CHANGE_TYPES.NUMBER, value: PRICE_CHANGE_TYPES.NUMBER, name: 'Выбранная валюта' },
  { id: PRICE_CHANGE_TYPES.PERCENTAGE, value: PRICE_CHANGE_TYPES.PERCENTAGE, name: 'Наценка в процентах, %' },
  { id: PRICE_CHANGE_TYPES.FIX, value: PRICE_CHANGE_TYPES.FIX, name: 'Фиксированная цена' },
];

const Wrapper = styled.div`
  display: flex;
  margin-top: 16px;
  margin-left: 16px;

  @media (max-width: ${BREAKPOINTS.MD}) {
    box-sizing: border-box;
    margin-left: 0;
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  width: 200px;

  @media (max-width: ${BREAKPOINTS.MD}) {
    width: calc(100% - 98px);
  }
`;

const SelectWrapper = styled.div`
  width: 235px;
  margin: 0 8px;

  @media (max-width: ${BREAKPOINTS.MD}) {
    width: 90px;
    margin-right: 0;
  }
`;

const MarkupControls: React.FC<ControlsProps> = (props) => {
  const handleChangeInput = (value) => {
    props.onChangeMarkup(value);
  };
  const handleChangeSelect = ({ target }) => {
    if (target.value !== PRICE_CHANGE_TYPES.PERCENTAGE && Number(props.markup) < 0) {
      props.onChangeMarkup(0);
    }

    props.onChangeMarkupValue(target.value);
  };

  const inputProps = {
    placeholder: 'Наценка',
    onChange: handleChangeInput,
    value: props.markup,
    max: 9999999,
    min: -100,
    float: true,
    disabled: props.disabled,
  };

  if (Number(props.markup_value) !== PRICE_CHANGE_TYPES.PERCENTAGE) {
    inputProps.min = 0;
  }

  return (
    <Wrapper>
      <InputWrapper>
        <NumberInput {...inputProps} />
      </InputWrapper>
      <SelectWrapper>
        <Select
          disabled={props.disabled}
          options={OPTIONS}
          onChange={handleChangeSelect}
          value={props.markup_value}
        />
      </SelectWrapper>
    </Wrapper>
  );
};

MarkupControls.defaultProps = {
  markup: '',
  markup_value: '',
  disabled: false,
};

export default MarkupControls;
