import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
  `,

  Title: styled.div`
    font-size: 20px;
    word-break: break-all;
    margin-bottom: 16px;
  `,

  Table: styled.table`
    border-collapse: separate;
    border-spacing: 0;

    & > tr:not(:last-child) > td {
      padding-bottom: 48px;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      display: flex;
      flex-direction: column;
    }
  `,

  TableRow: styled.tr`
    @media ${MEDIA_QUERIES.MOBILE} {
      display: flex;
      flex-direction: column;
    }
  `,

  TableCellTitle: styled.td`
    padding-right: 32px;
    vertical-align: top;
    white-space: nowrap;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding-bottom: 24px !important;
    }
  `,

  TableCell: styled.td`
    width: 100%;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding-bottom: 24px !important;
    }
  `,

  TableCellTitleSubText: styled.div`
    margin-top: 4px;
    min-width: 176px;
    font-size: 14px;
    color: #5c5c5c;
    white-space: normal;
  `,

  FieldsRow: styled.div`
    display: flex;
  `,

  Checkboxes: styled.div`
    margin-top: 32px;
    display: flex;

    & > div {
      width: 50%;

      & > label > span {
        margin-right: 16px;
      }
    }

    @media (max-width: ${BREAKPOINTS.XXS}) {
      flex-direction: column;

      & > div {
        width: 100%;
      }

      & > div:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  `,

  Row: styled.div`
    margin-left: 16px;
    width: 150px;
  `,

  Categories: styled.div`
    display: flex;

    & > div {
      width: calc(50% - 8px);
    }

    & > div:first-child {
      margin-right: 16px;
    }
  `,

  Tickets: styled.div`
    width: 100%;
    margin-top: 32px;
    display: flex;

    & > div:not(:last-child) {
      margin-right: 16px;
    }
  `,

  TicketSigns: styled.div`
    margin-top: 32px;
  `,

  Prices: styled.div<{ fullWidth?: boolean }>`
    display: flex;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    ${({ fullWidth }) =>
      !fullWidth &&
      css`
        & > div {
          width: calc(50% - 8px);
        }

        & > div:first-child {
          margin-right: 16px;
        }
      `}
  `,

  Provider: styled.div`
    margin-bottom: 32px;
  `,

  PriceIcon: styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    img {
      max-height: 12px;
    }
  `,
};
