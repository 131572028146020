import { connect } from 'react-redux';
import ReportsConfigModal from 'components/Modals/ReportsConfigModal';
import { loadOpex, editOpex } from 'entities/reports';

const mapDispathToProps = {
  loadOpex,
  editOpex,
};

export default connect(null, mapDispathToProps)(ReportsConfigModal);
