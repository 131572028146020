import styled from 'styled-components';
import { rgba } from 'polished';
import COLORS from 'shared/constants/COLORS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Container: styled.div`
    & .menu-wrapper {
      margin-left: -24px;
    }

    & .menu-wrapper button {
      text-transform: none;
      font-size: 16px;
    }
  `,

  Wrapper: styled.div`
    margin: 16px 0;
  `,

  Title: styled.div`
    font-size: 20px;
    margin-bottom: 16px;
  `,

  FieldItem: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  `,

  Table: styled.table`
    border-collapse: separate;
    border-spacing: 0;

    & > tr:not(:last-child) > td {
      padding-bottom: 48px;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      display: flex;
      flex-direction: column;
    }
  `,

  TableRow: styled.tr`
    @media ${MEDIA_QUERIES.MOBILE} {
      display: flex;
      flex-direction: column;
    }
  `,

  TableCellTitle: styled.td`
    padding-right: 16px;
    vertical-align: top;
    white-space: nowrap;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding-bottom: 24px !important;
    }
  `,

  TableCell: styled.td`
    width: 100%;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding-bottom: 24px !important;
    }
  `,

  TableCellTitleSubText: styled.div`
    margin-top: 4px;
    min-width: 148px;
    font-size: 14px;
    color: #5c5c5c;
    white-space: normal;
  `,

  FieldsRow: styled.div`
    display: flex;
  `,

  Checkboxes: styled.div`
    margin-top: 32px;
    display: flex;

    & > div {
      width: 50%;

      & > label > span {
        margin-right: 16px;
      }
    }

    @media (max-width: ${BREAKPOINTS.XXS}) {
      flex-direction: column;

      & > div {
        width: 100%;
      }

      & > div:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  `,

  Row: styled.div`
    margin-left: 16px;
    width: 150px;
  `,

  Categories: styled.div`
    display: flex;

    & > div {
      width: calc(50% - 8px);
    }

    & > div:first-child {
      margin-right: 16px;
    }
  `,

  Tickets: styled.div`
    width: 100%;
    margin-top: 32px;
    display: flex;

    & > div:not(:last-child) {
      margin-right: 16px;
    }
  `,

  TicketSigns: styled.div`
    margin-top: 32px;
  `,

  Prices: styled.div`
    display: flex;

    & > div {
      width: calc(50% - 8px);
    }

    & > div:first-child {
      margin-right: 16px;
    }
  `,

  TotalPrice: styled.div`
    margin-top: 32px;
    width: calc(50% - 8px);
  `,

  RemoveButton: styled.button`
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 10px;
    font-size: 14px;
    line-height: 18px;
    border: 0;
    outline: none;
    background: transparent;
    color: ${rgba(COLORS.RED, 0.8)};
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      color: ${COLORS.RED};
      background: ${rgba(COLORS.RED, 0.08)};
    }
  `,

  AddPosition: styled.button`
    align-self: center;
    margin-top: 8px;
    padding: 3px 10px;
    font-size: 14px;
    line-height: 18px;
    color: ${rgba(COLORS.BLUE, 0.8)};
    outline: none;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    background: transparent;
    flex-shrink: 0;
    min-height: 24px;
    margin-bottom: 16px;

    &:hover,
    &:focus {
      background: ${rgba(COLORS.BLUE, 0.08)};
      color: ${COLORS.BLUE};
    }
  `,

  Spinner: styled.div`
    display: flex;
    justify-content: center;
  `,
};
