import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Container: styled.div`
    box-sizing: border-box;
    margin-bottom: 32px;
  `,

  TH: styled.span`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: #999;
  `,

  EditRow: styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: center;

    @media (max-width: ${BREAKPOINTS.XL}) {
      position: absolute;
      top: 4px;
      right: 16px;
    }
  `,

  ExpansionWrapper: styled.div`
    display: flex;
    box-sizing: border-box;
    margin-left: -8px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      margin-left: 0;
    }
  `,

  Expansion: styled.div`
    display: flex;
    box-sizing: border-box;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding-left: 7px;
    }
  `,

  MobileHide: styled.div`
    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  DesktopLabel: styled.span`
    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,
};
