import React from 'react';
import Styled from './styles';

interface IconProps {
  src: string;
  alt?: string;
  ariaHidden?: boolean;
  opacity?: number;
  hoverOpacity?: number;
  width?: string;
  height?: string;
}

const Icon: React.FC<IconProps> = ({ src, alt, ariaHidden, opacity = 1, hoverOpacity, width, height }) => (
  <Styled.Img
    src={src}
    alt={alt}
    aria-hidden={ariaHidden}
    customOpacity={opacity}
    hoverOpacity={hoverOpacity}
    customWidth={width}
    customHeight={height}
  />
);

export default Icon;
