import React from 'react';
import styled from 'styled-components';
import omit from 'lodash/omit';
import Tooltip from '../Tooltip';

interface OptionProps {
  // eslint-disable-next-line no-undef
  children: string | JSX.Element;
  innerProps: any;
  data: any;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  className?: string;
  cx?: Function;
  getStyles: Function;
}

interface CheckboxProps {
  selected?: boolean;
  optionColor?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 12px;
`;

const Checkbox = styled.div<CheckboxProps>`
  box-sizing: border-box;
  position: relative;
  height: 16px;
  width: 16px;
  border: ${({ selected }) => (selected ? '5px' : '2px')} solid ${({ optionColor }) => optionColor};
  border-radius: 50%;
  margin-right: 12px;
  background: transparent;
`;

const StatusDropdownOption: React.FC<OptionProps> = (props) => {
  const {
    children,
    innerProps,
    isDisabled,
    isFocused,
    isSelected,
    className,
    cx,
    getStyles,
    data: { tooltip, color },
  } = props;

  const option = (
    <Wrapper data-selenium={children}>
      <Checkbox optionColor={color} selected={isSelected} />
      {children}
    </Wrapper>
  );

  return (
    <div
      css={getStyles('option', omit(props, ['isFocused']))}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      {...innerProps}
    >
      <Tooltip text={tooltip} placement="left">
        {option}
      </Tooltip>
    </div>
  );
};

export default StatusDropdownOption;
