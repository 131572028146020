import styled, { css } from 'styled-components';

interface ContainerProps {
  square?: boolean;
  color?: string;
}

export default {
  Wrapper: styled.div``,
  Container: styled.label<ContainerProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    & > input {
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      display: none;
    }

    & > input:checked + span {
      border-width: ${(props) => (props.square ? 8 : 6)}px;
      border-style: solid;
      border-color: ${(props) => props.color};
    }

    & > input ~ svg {
      width: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }

    & > input:checked ~ svg {
      opacity: 1;
    }
  `,

  Round: styled.span<ContainerProps>`
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: ${(props) => `2px solid ${props.color}`};
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;

    ${(props) =>
      props.square &&
      css`
        border-radius: 1px;
      `}
  `,

  Label: styled.div<any>`
    margin-left: 8px;
  `,
};
