import React from 'react';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { SmallFormDescription, Checkbox } from '../../../FormControls';

const PERSONALITY_IMAGES = [1, 2];

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const FooterStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Выберите дизайн персонали</Styled.Title>
    </Styled.Header>

    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.List grid>
              {PERSONALITY_IMAGES.map((item) => (
                <Styled.Item grid width="50%" key={item} data-selenium={`variant-${item}`}>
                  <Variant name="ui_config_personality_block" value={item}>
                    <img src={`/static/constructor/personality/${item}.jpg`} alt={`Вид №${item}`} />
                  </Variant>
                </Styled.Item>
              ))}
            </Styled.List>
            <Styled.SubTitle>Настройка блока</Styled.SubTitle>
            <Styled.CheckboxContainer>
              <Field name="ui_config_personality_grouped" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox variant="round" meta={meta} {...input}>
                    Показывать детей
                  </Checkbox>
                )}
              </Field>
              <SmallFormDescription>Показывать детей персонали, если есть таковые</SmallFormDescription>
            </Styled.CheckboxContainer>
            <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
          </form>
        )}
      />
    </Styled.Content>
  </Styled.Container>
);

export default FooterStep;
