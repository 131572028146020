import React from 'react';
import { Select } from '../../../FormControls';
import { WIDGET_FILTERS } from 'shared/constants/WIDGET';
import Styled from './styles';

interface SelectProps {
  value: string;
  options: any[];
  handleOnChange: (value: any) => void;
  className: string;
  field?: string;
  disabled?: boolean;
}

const FieldSelect: React.FC<SelectProps> = (props) => {
  const { value, options, className, handleOnChange, field, disabled } = props;

  if (field === WIDGET_FILTERS.ALL_TICKETS) return null;

  return (
    <Styled.FieldContainer>
      <Select
        className={className}
        value={value}
        placeholder=""
        onChange={(e) => handleOnChange(e.target.value)}
        disabled={disabled}
        options={options.map((option) => {
          const key = option.id ? `key-${option.id}` : `key-${option.name}`;

          return { id: key, name: option.label, value: option.name };
        })}
      />
    </Styled.FieldContainer>
  );
};

export default FieldSelect;
