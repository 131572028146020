import React from 'react';
import Error from '../Error';
import Label from '../LabelNew';
import Option from './option';
import Styled from './styles';

interface SelectProps {
  options: any[];
  small?: boolean;
  label?: string;
  fluid?: boolean;
  withEmpty?: boolean;
  disabled?: boolean;
  value?: string | number | null;
  meta?: any;
  placeholder?: string | null;
  errorPosition?: 'right';
  fixedError?: boolean;
  onChange: (event: any) => void;
  className?: string;
}
const Select = ({
  small = false,
  label = '',
  fluid = false,
  options,
  meta = {},
  withEmpty = false,
  placeholder = null,
  value = null,
  fixedError = false,
  errorPosition,
  ...rest
}: SelectProps) => (
  <Styled.Container>
    {label && (
      <Label value={value} meta={meta}>
        {label}
      </Label>
    )}
    <Styled.Select
      small={small}
      fluid={fluid}
      meta={meta}
      value={value || ''}
      withEmpty={withEmpty}
      {...rest}
    >
      {withEmpty ? <Option>{placeholder}</Option> : null}
      {options.map(
        (item) => (
          <Option key={item.value} value={item.value} disabled={item.disabled}>
            {item.name}
          </Option>
        ),
        this,
      )}
    </Styled.Select>
    <Error meta={meta} fixedError={fixedError} position={errorPosition} />
  </Styled.Container>
);

export default Select;
