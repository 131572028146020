import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { scrollbar } from 'app/styles/scrollbar';

const SettingsButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -48px;
  visibility: hidden;
  transition: 0.2s ease-in-out;
`;

interface RowProps {
  withBorder?: boolean;
}

interface FieldProps {
  withSettings?: boolean;
}

interface ListTitleProps {
  disabled?: boolean;
}

export default {
  Row: styled.div<RowProps>`
    box-sizing: border-box;
    display: flex;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: ${({ withBorder }) => withBorder && '1px solid #f5f5f5'};

    &:not(:last-child) {
      border-bottom: 1px solid #f5f5f5;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      flex-direction: column;
    }
  `,

  Sidebar: styled.div`
    flex-shrink: 0;
    width: 128px;

    @media (max-width: ${BREAKPOINTS.SM}) {
      width: 100%;
      margin-bottom: 24px;
    }
  `,

  Content: styled.div`
    width: 100%;
    padding-left: 32px;

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding-left: 0;
    }
  `,

  RowTitle: styled.p`
    margin: 0 0 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  `,

  Description: styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: #585858;
  `,

  FormRow: styled.div`
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  `,

  Field: styled.div<FieldProps>`
    position: relative;
    width: 100%;
    max-width: ${({ withSettings }) => (withSettings ? 'calc(100% - 48px)' : '100%')};
  `,

  FieldWithSettings: styled.div`
    position: relative;

    &:hover ${SettingsButtonWrapper} {
      visibility: visible;
    }
  `,

  SettingsButtonWrapper,

  List: styled.div`
    max-height: 60vh;
    overflow-y: auto;
    padding: 4px 40px;

    ${scrollbar}

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 4px 0;
    }
  `,

  ListItem: styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0;

    &:nth-child(even) {
      background-color: #fafafa;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 6px 4px 6px 16px;
      &:nth-child(even) {
        background-color: #fff;
      }
    }
  `,

  ListTitle: styled.div<ListTitleProps>`
    width: calc(100% - 41px);
    margin: 4px 0;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  `,

  ListDescription: styled.div`
    width: 100%;
    margin: 4px 0;
  `,

  ListCheckbox: styled.div`
    flex-shrink: 0;
    width: 41px;
    margin: 10px 0 0;
  `,

  Sites: styled.div`
    > div {
      margin: 0;
    }

    button {
      justify-content: space-between;
      width: 100%;
      border: 1px solid #e1e1e1;
      min-height: 40px;
    }
  `,
};
