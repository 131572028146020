import AntdDatePicker from 'antd/lib/date-picker';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import IDS from 'shared/constants/IDS';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import CloseIcon from '../../../../static/icons/close.svg';
import Portal from '../../Portal';
import Error from '../Error';
import Label from '../LabelNew';
import Styled from './styles';

type DateTimePickerProps = typeof DateTimePicker.defaultProps & {
  onChange: (val: any) => void;
  input: {
    name: string;
    onChange: (val: any) => void;
    value: any;
  };
  meta: any;
  handleForm: any;
  name: string;
  fieldSyncName: string | boolean;
  label: string;
  highlighted?: boolean;
  disabled?: boolean;
  outdatedToggler?: boolean;
  minuteStep?: number;
  inModal?: boolean;
  disabledDate: () => any;
  fixedError?: boolean;
  withoutTimePick?: boolean;
  showToday?: boolean;
  withClearButton?: boolean;
  format?: string;
  lng?: string;
  isMobile?: boolean;
  mobileClose?: boolean;
  placement?: string;
};

class DateTimePicker extends Component<DateTimePickerProps, any> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    meta: {},
    fieldSyncName: null,
    highlighted: false,
    disabled: false,
    outdatedToggler: true,
    minuteStep: 1,
    inModal: false,
    fixedError: false,
    showToday: true,
    withoutTimePick: false,
    withClearButton: false,
    format: DATE_FORMATS.DATE_TIME_PREVIEW,
    handleForm: null,
    onChange: null,
    disabledDate: null,
    lng: null,
    isMobile: false,
    mobileClose: false,
  };

  scrollContainer: any;

  pickerContainer: any;

  constructor(props) {
    super(props);

    this.scrollContainer = props.inModal ? IDS.MODAL_CONTAINER : IDS.APP_LAYOUT;

    this.state = {
      isOpen: false,
      outdated: true,
      isFocus: false,
    };

    this.pickerContainer = null;
  }

  componentDidMount() {
    this.pickerContainer = document.getElementById(IDS.POPUP_CONTAINER);
    const scrollContainer = document.getElementById(this.scrollContainer);

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', this.handleScroll);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.input.value !== nextProps.input.value) {
      return true;
    }

    if (nextProps.disabled !== this.props.disabled) {
      return true;
    }

    if ((nextProps.meta.touched || this.props.meta.touched) && !isEqual(nextProps.meta, this.props.meta)) {
      return true;
    }

    if (this.state.outdated !== nextState.outdated) {
      return true;
    }

    if (this.state.isOpen !== nextState.isOpen) {
      return true;
    }

    if (this.state.isFocus !== nextState.isFocus) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    const node = document.getElementById(this.scrollContainer);

    if (node) node.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.hideDatePicker();
  };

  handleChange = (_, dateString) => {
    const { input, name, handleForm, fieldSyncName, format, onChange, lng } = this.props;

    if (onChange) {
      onChange(dateString);
    } else {
      const value = {
        ...input.value,
        [name]: dayjs(dateString, format).format(DATE_FORMATS.DATE_TIME),
      };

      input.onChange(lng ? { lng: value } : value);
      handleForm.change(input.name, dateString ? dayjs(dateString, format) : null);

      if (fieldSyncName) {
        handleForm.change(fieldSyncName, dateString ? dayjs(dateString, format).add(2, 'hours') : null);
      }
    }
  };

  hideDatePicker = () => {
    this.setState({
      isOpen: false,
    });
  };

  toggleOutdated = () => {
    this.setState(({ outdated }) => ({
      outdated: !outdated,
    }));
  };

  disabledDate = (current) => {
    const { disabledDate } = this.props;

    if (disabledDate) {
      return disabledDate(current);
    }

    if (current < dayjs().startOf('day')) {
      return true;
    }

    return false;
  };

  render() {
    const {
      input,
      meta,
      label,
      highlighted,
      disabled,
      outdatedToggler,
      minuteStep,
      disabledDate,
      fixedError,
      withoutTimePick,
      format,
      showToday,
      withClearButton,
      isMobile,
      mobileClose,
      placement,
    } = this.props;

    const { outdated, isOpen } = this.state;
    const value = input.value ? dayjs(input.value).locale('ru') : null;

    return (
      <>
        {isOpen && (
          <Portal>
            <Styled.Overlay onClick={this.hideDatePicker} />
          </Portal>
        )}
        <Styled.Container highlighted={highlighted} meta={meta}>
          {label && (
            <Label isOpen={this.state.isFocus} meta={meta} value={input.value} disabled={disabled}>
              {label}
            </Label>
          )}

          <AntdDatePicker
            className="date-picker-container datepicker-info"
            value={value ? value : null}
            placement={placement}
            onChange={this.handleChange}
            onCalendarChange={this.handleChange}
            onFocus={() => this.setState({ isFocus: true })}
            onBlur={() => this.setState({ isFocus: false })}
            placeholder=""
            allowClear={withClearButton}
            renderExtraFooter={() => (
              <>
                {isMobile && mobileClose && (
                  <Styled.MobileClose type="button" onClick={() => this.setState({ isOpen: false })}>
                    <CloseIcon />
                  </Styled.MobileClose>
                )}
                {outdatedToggler && !disabledDate && (
                  <Styled.Outdated onClick={this.toggleOutdated}>
                    {`${outdated ? 'Показать' : 'Скрыть'} прошедшие даты`}
                  </Styled.Outdated>
                )}
              </>
            )}
            locale={locale}
            format={format}
            disabled={disabled}
            onKeyDown={(e) => {
              if (
                !/[\d/-]/.test(e.key) &&
                e.key !== 'Backspace' &&
                e.key !== 'Delete' &&
                !e.ctrlKey &&
                !e.metaKey
              ) {
                e.preventDefault();
              }
            }}
            disabledDate={outdated ? this.disabledDate : () => false}
            {...(!withoutTimePick && {
              showTime: {
                format,
                minuteStep,
                showNow: showToday,
                defaultValue: dayjs(),
              },
            })}
          />
          <Error meta={meta} fixedError={fixedError} />
        </Styled.Container>
      </>
    );
  }
}

export default withCheckIsMobile(DateTimePicker);
