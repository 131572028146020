import React from 'react';
import styled, { css } from 'styled-components';
import { TICKET_STATUS } from 'shared/constants/TICKET_TYPES';

const DESTIONATIONS = {
  1: '#EB5757',
  2: '#2DC472',
};

const B2B_DESTIONATIONS = {
  1: '#2DC472',
  2: '#EB5757',
};

interface TicketStatusProps {
  children: string | number;
  status: number;
  isPreorderTicket?: boolean;
  order?: {
    id?: number;
    destination?: number;
  };
}

interface TicketColorProps {
  isPreorderTicket?: boolean;
  status?: number;
  order?: {
    id?: number;
    destination?: number;
    b2b?: boolean;
  };
  readOnly?: boolean;
}

const TicketColor = styled.span<TicketColorProps>`
  position: relative;
  transition: all 0.2s ease-in-out;
  line-height: 18px;
  cursor: default;

  ${(props) => {
    let color = '#000';
    let crossed: any = false;

    if (props.isPreorderTicket) {
      color = '#F09E00';
      crossed = false;
    }

    if (props.status !== TICKET_STATUS.IN_SALE && props.status !== TICKET_STATUS.BURNED) {
      color = '#EB5757';
    }

    if (props.status !== TICKET_STATUS.IN_SALE && props.status === TICKET_STATUS.SOLD) {
      crossed = '#EB5757';
    }

    if (props.status === TICKET_STATUS.SOLD && props.order) {
      color = DESTIONATIONS[props.order.destination];
      crossed = DESTIONATIONS[props.order.destination];
    }

    if ((props.status === TICKET_STATUS.IN_ORDER || props.status === TICKET_STATUS.SOLD) && props.order) {
      if (props.order.b2b) {
        color = B2B_DESTIONATIONS[props.order.destination];
      } else {
        color = DESTIONATIONS[props.order.destination];
      }
    }

    if (props.status === TICKET_STATUS.IN_ORDER && !props.order) {
      color = '#2F80ED';
    }

    if (crossed && props.readOnly) {
      return css`
        color: ${color};

        &:after {
          content: '';
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          position: absolute;
          display: block;
          width: 100%;
          height: 3px;
          background-color: ${color};
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          box-sizing: border-box;
        }
      `;
    }

    return css`
      color: ${color};
    `;
  }}
  white-space: normal;
`;

const StyledTicketStatus = styled.div`
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
`;

const TicketStatus: React.FC<TicketStatusProps> = ({ children, status, order, isPreorderTicket }) => (
  <StyledTicketStatus>
    <TicketColor status={status} order={order} isPreorderTicket={isPreorderTicket}>
      {children}
    </TicketColor>
  </StyledTicketStatus>
);

export default TicketStatus;

export { TicketColor };
