import styled, { css, createGlobalStyle } from 'styled-components';
import { layoutScrollbar } from '../../../app/styles/scrollbar';
import Modal from '../../Modal';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface TicketsRowProps {
  withoutBorder?: boolean;
}

interface ScheduleProps {
  formIsOpen?: boolean;
}

interface ScheduleItemProps {
  withOpacity?: boolean;
  last?: boolean;
}

interface ButtonsProps {
  last?: boolean;
}

interface ShowAppliedProps {
  showApplied?: boolean;
}

export default {
  GlobalStyles: createGlobalStyle`
    .modal__wrapper {
      max-width: 544px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__overlay {
        overflow: hidden;
      }

      .modal__body {
        border-radius: 8px 8px 0 0;

        @media (max-width: ${BREAKPOINTS.MD}) {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #fff;
          border-radius: 8px 8px 0 0;
        }
      }

      .modal__close {
        position: fixed;
      }
    }
  `,

  Inner: styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: ${BREAKPOINTS.MD}) {
      height: calc(100% - 56px);
      overflow-y: auto;
      padding: 0 32px 32px;
      margin: 0 -32px -32px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 0 16px 32px;
      margin: 0 -16px -32px;
    }
  `,

  EventContainer: styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-shrink: 0;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 16px;
    }
  `,

  EventTime: styled.div`
    margin-top: 16px;
    font-size: 14px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      order: 2;
      margin: 0;
      font-size: 12px;
    }
  `,

  EventName: styled.div`
    margin: 4px 0 6px 0;
    font-size: 18px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      order: 1;
      width: 100%;
      margin: 0 0 8px;
      font-size: 16px;
    }
  `,

  EventInfo: styled.div`
    font-size: 14px;
    color: #5c5c5c;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: relative;
      order: 3;
      margin: 0;
      font-size: 12px;

      &:before {
        position: relative;
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 5px;
        background-color: #5c5c5c;
        vertical-align: middle;
        border-radius: 50%;
      }
    }
  `,

  TicketsRow: styled.div<TicketsRowProps>`
    padding: 12px 24px;
    margin: 16px -40px 0 -40px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    box-sizing: border-box;

    border-bottom: ${({ withoutBorder }) => withoutBorder && 'none'};

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-shrink: 0;
      padding: 8px 16px;
      margin: 8px 0 0;
      border-bottom: 1px solid #e1e1e1;
    }
  `,

  TicketsRowInfo: styled.div`
    display: flex;
    flex-direction: column;
  `,

  TicketsRowLabel: styled.div`
    display: flex;
    color: #5c5c5c;
    font-size: 12px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-bottom: 8px;
    }
  `,

  Icon: styled.img`
    max-height: 12px;
    width: auto;
  `,

  TicketsRowValue: styled.div`
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  `,

  Row: styled.div`
    margin-bottom: 32px;
    display: flex;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
      padding: 0 16px;

      & > div {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  `,

  Sidebar: styled.div`
    flex-shrink: 0;
    width: 128px;
    margin-right: 32px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  SidebarTitle: styled.div`
    font-size: 16px;
  `,

  SidebarDescription: styled.div`
    margin-top: 4px;
    font-size: 14px;
    color: #5c5c5c;
  `,

  NewPrice: styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0 -12px;

    & > div {
      font-size: 20px;
      font-weight: bold;
      margin-left: 8px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 0;
      color: #5c5c5c;

      & > div {
        color: #000;
      }
    }
  `,

  Schedule: styled.div<ScheduleProps>`
    margin: 0 -48px;
    padding: 0 48px;
    max-height: 290px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    ${layoutScrollbar};

    & .infinite-scroll-component > div:not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
    }

    max-height: ${({ formIsOpen }) => formIsOpen && '128px'};

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-shrink: 0;
      margin: 0;
      padding: 0 16px;
      max-height: unset;
      overflow-y: visible;
    }
  `,

  ScheduleItem: styled.div<ScheduleItemProps>`
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    opacity: ${({ withOpacity }) => withOpacity && '0.64'};

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-wrap: wrap;
      flex-shrink: 0;
      position: relative;
      min-height: 0;
      padding: 8px 0;

      ${({ last }) =>
        last &&
        css`
          margin: 0 -16px;
          padding: 8px 16px;
        `}

      &:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
      }
    }
  `,

  Form: styled.div`
    margin: 0 -40px -59px -40px;
    padding: 32px 40px 0 40px;
    box-sizing: border-box;
    border-top: 1px solid #e1e1e1;

    @media (max-width: ${BREAKPOINTS.MD}) {
      height: 100%;
      min-height: 0;
      padding: 24px 0 0;
      margin: 0;
      border-top: none;

      & > div,
      & form {
        height: 100%;
      }
    }
  `,

  ChangeInfo: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 40% - 72px);

    & > svg {
      margin-right: 8px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: calc(100% - 40px);
      order: 2;
    }
  `,

  Date: styled.div`
    width: calc(100% - 60% - 72px);

    @media (max-width: ${BREAKPOINTS.MD}) {
      order: 1;
      width: 100%;
      margin-bottom: 4px;
      font-size: 12px;
      color: #5c5c5c;
    }
  `,

  Buttons: styled.div<ButtonsProps>`
    width: 72px;
    display: flex;
    justify-content: flex-end;

    & > button:not(:last-child) {
      margin-right: 8px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: absolute;
      top: 50%;
      right: ${({ last }) => (last ? '16px' : '0')};
      transform: translateY(-50%);
    }
  `,

  Applied: styled.div`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  Popover: styled.div`
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
  `,

  ShowApplied: styled.div<ShowAppliedProps>`
    margin: 16px 0 4px -2px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #2f80ed;
    cursor: pointer;
    user-select: none;

    & svg {
      margin-right: 8px;
      transform: ${({ showApplied }) => showApplied && 'rotate(180deg)'};
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      justify-content: space-between;
      flex-shrink: 0;
      height: 32px;
      padding: 0 16px;
      margin: 8px 0;

      & svg {
        order: 1;
      }
    }
  `,

  Delete: styled.div`
    display: none;
    margin-right: auto;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: block;
    }
  `,

  FooterWrapper: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-top: auto;
    }
  `,

  Footer: styled(Modal.Footer)`
    @media (max-width: ${BREAKPOINTS.MD}) {
      min-height: 0;
      border-top: 1px solid #e1e1e1;
      padding: 8px;
      margin: 32px 0 0;
      flex-direction: row;
      justify-content: flex-end;

      & > button {
        width: auto;

        &:not(:first-child) {
          margin-left: 8px;
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  `,

  Price: styled.span`
    display: block;
  `,
};
