import React from 'react';
import StyledSpinner from './styles';

interface SpinnerProps {
  borderWidth?: number;
  color?: string;
  size?: number;
  center?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
  borderWidth = 2,
  color = '#ffda00',
  size = 50,
  center,
}) => (
  <StyledSpinner.Wrapper color={color} size={size} center={center} className="spinner">
    <svg viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth={borderWidth} />
    </svg>
  </StyledSpinner.Wrapper>
);
