import Placeholder from 'components/Placeholder';
import Spinner from 'components/Spinner';
import SplittedNumber from 'components/SplittedNumber';
import permissions from 'config/permissions';
import Can from 'containers/Can';
import Layout from 'containers/Layout';
import { useUnit } from 'effector-react';
import { isEqual, omit } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { noun } from 'plural-ru';
import React, { useEffect } from 'react';
import { MainLayout } from 'widgets/layouts/main-layout';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { ReferenceCreateButton } from 'widgets/reference/reference-create-button';
import { ReferenceSearch } from 'widgets/reference/reference-search';
import { useToggler } from 'shared/lib/toggler';
import { ReferenceTabs } from 'shared/ui/reference/tabs';
import { $countries, $loading, pageMounted } from '../model';
import { CountriesList } from './countries-list';
import { countryCreateModal, CountryCreateModal } from './create-modal';
import { CountryUpdateModal } from './update-modal';

let prevQuery = { page: 1 };

export const ReferenceCountriesPage = () => {
  const { open: openCreateModal } = useToggler(countryCreateModal.toggler);
  const router = useRouter();
  const { query } = router;
  const { t } = useTranslation();
  const [handlePageMount, countries, loading] = useUnit([pageMounted, $countries, $loading]);
  const { count } = countries;

  useEffect(() => {
    const omittedQuery = omit(query, ['modalType', 'modalId']);
    if (!isEqual(prevQuery, omittedQuery)) {
      prevQuery = omittedQuery;
      handlePageMount(query);
    }
  }, [query]);

  return (
    <MainLayout title={t('references:title')}>
      <Layout disableLayout withoutPaddingsOnMobile title={t('references:title')}>
        <ReferenceTabs loading={loading}>
          <ReferenceLayout.MainWrapper>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <ReferenceLayout.Header>
                  <>
                    {count !== undefined ? (
                      <div>
                        <SplittedNumber>{count}</SplittedNumber>{' '}
                        <ReferenceLayout.CountLabel>
                          {noun(count, 'Страна', 'Страны', 'Стран')}
                        </ReferenceLayout.CountLabel>
                      </div>
                    ) : (
                      <ReferenceLayout.PlaceholderContainer>
                        <Placeholder />
                      </ReferenceLayout.PlaceholderContainer>
                    )}
                    <ReferenceLayout.Actions>
                      <ReferenceSearch referenceName={'countries'} />
                      <ReferenceLayout.HeaderControls>
                        <Can permission={permissions.reference}>
                          <ReferenceCreateButton referenceName="countries" onClick={openCreateModal} />
                        </Can>
                      </ReferenceLayout.HeaderControls>
                    </ReferenceLayout.Actions>
                  </>
                </ReferenceLayout.Header>
                <ReferenceLayout.Wrapper>
                  <CountriesList
                    data={countries.results}
                    state={{
                      loading,
                    }}
                  />
                </ReferenceLayout.Wrapper>
              </>
            )}
          </ReferenceLayout.MainWrapper>
        </ReferenceTabs>
        <CountryCreateModal />
        <CountryUpdateModal />
      </Layout>
    </MainLayout>
  );
};
