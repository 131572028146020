import styled, { createGlobalStyle } from 'styled-components';
import { smallModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Spinner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Price: styled.div`
    margin-top: 20px;
  `,

  PriceLabel: styled.span`
    margin-right: 8px;
    font-size: 12px;
    color: #5c5c5c;
  `,
};
