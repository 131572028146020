import styled, { createGlobalStyle, css } from 'styled-components';
import { mediumModalStyles } from 'shared/mixins/modal';
import { layoutScrollbar } from '../../../app/styles/scrollbar';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

interface ListProps {
  centered?: boolean;
}

interface TagDatesSelectorProps {
  placeholderIsRed?: boolean;
}

export default {
  GlobalStyles: createGlobalStyle`
    ${mediumModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    & > div {
      margin-bottom: 16px;
    }
  `,

  Footer: styled.div`
    margin-top: -48px;
  `,

  InfiniteScroll: styled.div`
    & > div {
      margin: 16px -40px 0 -40px;

      @media ${MEDIA_QUERIES.MOBILE} {
        box-sizing: border-box;
        max-width: 100vw;
        margin: 16px -16px 0;
      }
    }
  `,

  List: styled.ul<ListProps>`
    padding: 8px 0;
    max-height: 475px;
    min-height: 475px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e1e1e1;
    box-sizing: border-box;
    overflow: auto;

    ${({ centered }) =>
      centered &&
      css`
        align-items: center;
        justify-content: center;
      `}

    ${layoutScrollbar}
  `,

  ListItem: styled.li`
    & > button {
      margin: 0;
      padding: 0 40px;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      border: 0;
      background: none;
      text-align: left;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      outline: none;

      @media ${MEDIA_QUERIES.MOBILE} {
        padding: 0 16px;
      }

      &:hover,
      &:focus {
        background-color: #f4f4f4;

        & > div > svg {
          opacity: 0.4;
        }
      }
    }
  `,

  ListItemIcon: styled.div`
    margin-left: 16px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      opacity: 0;
      transition: all 0.2s ease-in-out;

      @media ${MEDIA_QUERIES.MOBILE} {
        opacity: 1;
      }
    }

    &:hover {
      & > svg {
        opacity: 1 !important;
      }
    }
  `,

  Tags: styled.div`
    position: relative;
    z-index: 4;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;

    & > div:not(:last-child) {
      margin-right: 8px;
    }

    & > div {
      margin-bottom: 8px;
    }
  `,

  Tag: styled.div`
    padding: 4px 8px;
    height: 24px;
    max-width: 224px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: #f4f4f4;
    font-size: 12px;

    & > span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & input {
      position: absolute;
    }
  `,

  TagDatesSelector: styled.div<TagDatesSelectorProps>`
    margin-left: 8px;

    .react-select__control {
      min-height: auto;
      border: 0;
      box-shadow: none;
      background-color: transparent;
      cursor: pointer;
    }

    .react-select__placeholder {
      position: relative;
      font-size: 12px;
      color: #2f80ed;
      margin: 0;
      transform: none;
      white-space: nowrap;

      color: ${({ placeholderIsRed }) => placeholderIsRed && '#EB5757'};
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      display: none;
    }

    .react-select__clear-indicator {
      display: none;
    }

    .react-select__menu {
      width: auto;
    }

    .react-select__menu-list > div {
      white-space: nowrap;
    }

    .react-select__value-container {
      padding: 0;
    }
  `,

  TagCloseIcon: styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;
    opacity: 0.24;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    & svg {
      width: 11px;
      height: 11px;
    }
  `,

  LoadingMore: styled.div`
    transform: translateY(-66px);
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
  `,
};
