import styled, { createGlobalStyle, css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { mediumModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle<any>`
    ${mediumModalStyles}
  `,

  TitleWrapper: styled.div<{ isEdit?: boolean }>`
    ${({ isEdit }) =>
      isEdit &&
      css`
        div {
          margin-bottom: 16px;
        }
      `}
  `,

  Sections: styled.div``,
  SelectionSelect: styled.div`
    margin-bottom: 40px;
  `,

  Section: styled.div`
    display: flex;

    &:not(:last-child) {
      margin-bottom: 48px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
    }
  `,

  Rows: styled.div`
    width: 100%;
  `,

  Row: styled.div`
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  `,

  Description: styled.div`
    margin-top: 16px;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.64);
  `,

  Link: styled.a`
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    color: #2f80ed;

    svg {
      margin-left: 8px;
    }
  `,
};
