export default {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
  ADD_SCHEME: 'add-scheme',
  EDIT_SCHEME: 'edit-scheme',
  CLONE: 'clone',
  MERGE: 'merge',
  COPY: 'copy',
  DELETE_MARKUP: 'delete-markup',
  AFTER_CREAT: 'after-create',
  EDIT_EFFECTOR_MODAL: 'edit-effector-modal',
  EDIT_REFERENCE_QNA: 'edit-reference-qna',
};
