import styled, { css } from 'styled-components';

interface CSSProps {
  transparentBlue?: boolean;
  disabled?: boolean;
}

export default {
  Dropdown: styled.div<CSSProps>`
    transition: all 0.2s ease-in-out;

    & button {
      padding: 3px 8px 3px 8px;
      min-height: 24px;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;

        & > div {
          pointer-events: none;
        }
      `}
  `,

  DropdownLabel: styled.div<CSSProps>`
    font-size: 14px;
    color: ${({ transparentBlue }) => transparentBlue && '#2f80ed'};
  `,
};
