import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'next-i18next';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { orderSources } from 'entities/order';
import LOCALES from 'shared/constants/LOCALES';
import MODALS from 'shared/constants/MODALS';
import { groupTickets } from 'shared/helpers/tickets';
import { maxNumberValue, minNumberValue, composeValidators, required } from 'shared/helpers/validations';
import api from 'shared/services/api';
import { ModalFunctions } from '../../../../interfaces/modal';
import Button from '../../Button';
import Form from '../../Form';
import { NumberInput } from '../../FormControls';
import Modal from '../../Modal/Modal';
import Spinner from '../../Spinner';
import SplittedNumber from '../../SplittedNumber';
import Styled from './styles';

interface ModalProps {
  data: any;
  loadSale: (id: number) => any;
  openModal: ModalFunctions['openModal'];
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  refundOrder: (id: number, data: any) => any;
  t: any;
}

interface ModalState {
  data: any;
  isLoading: boolean;
}

class OrderRefundModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const {
      data: { id },
    } = this.props;

    const response = await api.get(orderSources.detail(id));

    this.setState({
      isLoading: false,
      data: response.data,
    });
  }

  get totalPrice() {
    const {
      data: { tickets },
    } = this.state;

    return groupTickets(tickets, true).totalPrice;
  }

  submitRefund = (data) => {
    const {
      data: { id },
    } = this.state;
    const { refundOrder, loadSale, openModal, forceCloseModal } = this.props;

    return refundOrder(id, data).then((response) => {
      if (
        response.error &&
        head(response.error.response.data?.detail?.error?.order) === 'Order has glass announcements.'
      ) {
        forceCloseModal();

        return openModal(MODALS.GLASS_TICKETS_MODAL, {
          announcements: response.error.response.data.detail.error.glass,
          orderAction: 'refund',
          orderId: id,
          orderAmount: data.amount,
          modalData: {
            confirmClose: true,
          },
        });
      }

      if (response.error) {
        return response;
      }

      if (!isEmpty(response.payload.data)) {
        forceCloseModal();

        return openModal(MODALS.PARTNER_TICKETS_MODAL, {
          tickets: response.payload.data,
        });
      }

      loadSale(id);

      return forceCloseModal();
    });
  };

  render() {
    const { isLoading, data } = this.state;
    const { closeModal } = this.props;
    const currencyIcon = data && data.currency ? data.currency.icon : null;

    if (isLoading) {
      return (
        <Styled.Spinner>
          <Styled.GlobalStyles />
          <Spinner />
        </Styled.Spinner>
      );
    }

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Modal.Title>Возврат средств</Modal.Title>
        <Form
          t={this.props.t}
          translationPrefix="forms:"
          onSubmit={this.submitRefund}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="amount"
                validate={composeValidators(
                  required,
                  minNumberValue(0),
                  maxNumberValue(Math.abs(this.totalPrice)),
                )}
              >
                {({ input, meta }) => <NumberInput label="Сумма возврата" {...input} meta={meta} />}
              </Field>
              <Styled.Price>
                <Styled.PriceLabel>Стоимость заказа:</Styled.PriceLabel>
                <SplittedNumber withSuffix icon={currencyIcon}>
                  {this.totalPrice}
                </SplittedNumber>
              </Styled.Price>
              <Modal.Footer>
                <Button type="button" transparent onClick={closeModal} data-selenium="button_cancel_refund">
                  Отмена
                </Button>
                <Button data-selenium="button_done_refund">Вернуть средства</Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Styled.Container>
    );
  }
}

export default withTranslation([LOCALES.FORMS])(OrderRefundModal);
