import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Styled from './styles';
import { formatFullDateAndTime, formatRawDateTime } from 'shared/helpers/formatters/date';
import Spinner from '../../Spinner';
import { renderTickets } from 'shared/helpers/tickets';
import AnnouncementPlanningPriceForm from './AnnouncementPlanningPriceForm';
import Button from '../../Button';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import CheckGreenIcon from '../../../../static/icons/check-green.svg';
import PenIcon from '../../../../static/icons/pen.svg';
import TrashIcon from '../../../../static/icons/trash.svg';
import Tooltip from '../../Tooltip';
import { PRICE_CHANGE_TYPES, DESTINATION_TYPES } from 'shared/constants/ORDER';
import ArrowIcon from '../../../../static/icons/arrow.svg';
import ArrowRedDownIcon from '../../../../static/icons/arrow-red-down.svg';
import ArrowGreenUpIcon from '../../../../static/icons/arrow-green-up.svg';
import ConfirmModal from '../ConfirmModal';
import SplittedNumber from '../../SplittedNumber';
import Modal from '../../Modal';
import { ModalFunctions } from '../../../../interfaces/modal';

const getDestinationText = (type, applied) => {
  if (type === DESTINATION_TYPES.MARKUP && !applied) {
    return 'Повышение цены на';
  }

  if (type === DESTINATION_TYPES.MARKUP && applied) {
    return 'Цена повышена на';
  }

  if (type === DESTINATION_TYPES.DISCOUNT && !applied) {
    return 'Понижение цены на';
  }

  if (type === DESTINATION_TYPES.DISCOUNT && applied) {
    return 'Цена понижена на';
  }

  return '';
};

interface Props {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  editPriceChangeSchedule: (id: number | string, secondId: number | string, request: any) => Promise<any>;
  createPriceChangeSchedule: (id: number | string, request: any) => Promise<any>;
  deletePriceChangeSchedule: (id: number | string, secondId: number | string) => Promise<any>;
  loadAnnouncement: (id: number | string) => Promise<any>;
  loadPriceChangeSchedule: (id: number | string, data?: any) => Promise<any>;
  isMobile?: boolean;
}

interface State {
  data: any;
  meta: any;
  isEdit: any;
  isLoading: boolean;
  formIsOpen: boolean;
  showApplied: boolean;
  showDeleteConfirm: any;
  showCancelConfirm: boolean;
  announcementData: any;
}

class AnnouncementPlanningPriceModal extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      meta: null,
      isEdit: {},
      isLoading: true,
      formIsOpen: false,
      showApplied: false,
      showDeleteConfirm: {},
      showCancelConfirm: false,
      announcementData: null,
    };

    this.load = this.load.bind(this);
    this.deleteSchedule = this.deleteSchedule.bind(this);
  }

  componentDidMount() {
    this.load().catch((err) => new Error(err));
  }

  loadMore = async () => {
    const {
      loadPriceChangeSchedule,
      data: { announcementId: id },
    } = this.props;

    const data = await loadPriceChangeSchedule(id, { page: Number(this.state.meta.page) + 1 });

    const { results, ...meta } = data.payload.data.data;

    this.setState((prevState) => ({
      data: [...prevState.data, ...results],
      meta,
    }));
  };

  createSchedule = (data) => {
    const {
      editPriceChangeSchedule,
      createPriceChangeSchedule,
      data: { announcementId: id },
    } = this.props;
    const { isEdit } = this.state;

    const request = {
      change: data.amount,
      discount_type: Number(data.discount_type),
      destination_type: Number(data.destination),
      change_date: data.change_date.format(DATE_FORMATS.DATE_TIME),
    };

    if (!isEmpty(isEdit)) {
      return editPriceChangeSchedule(id, isEdit.id, request).then(() => {
        this.setState(
          {
            isEdit: {},
            isLoading: true,
            formIsOpen: false,
          },
          () => {
            this.load().catch((err) => new Error(err));
          },
        );
      });
    }

    return createPriceChangeSchedule(id, request).then(() => {
      this.setState(
        {
          isLoading: true,
          formIsOpen: false,
        },
        () => {
          this.load().catch((err) => new Error(err));
        },
      );
    });
  };

  showForm = () => {
    this.setState({
      formIsOpen: true,
    });
  };

  hideForm = () => {
    this.setState({
      showCancelConfirm: true,
    });
  };

  editSchedule = (_, data) => {
    this.setState({
      formIsOpen: true,
      isEdit: {
        id: data.id,
        amount: data.change,
        change_date: moment(data.change_date),
        discount_type: data.discount_type.toString(),
        destination: data.destination_type.toString(),
        delete: this.showDeleteConfirm,
        item: data,
      },
    });
  };

  showDeleteConfirm = (_, data) => {
    this.setState({
      showDeleteConfirm: data,
    });
  };

  hideDeleteConfirm = () => {
    this.setState({
      showDeleteConfirm: {},
    });
  };

  toggleApplied = () => {
    this.setState(({ showApplied }) => ({
      showApplied: !showApplied,
    }));
  };

  async load() {
    const {
      loadAnnouncement,
      loadPriceChangeSchedule,
      data: { announcementId: id },
    } = this.props;

    const {
      payload: { data: announcementData },
    } = await loadAnnouncement(id);

    const {
      payload: {
        data: {
          data: { results, ...meta },
        },
      },
    } = await loadPriceChangeSchedule(id);

    this.setState({
      meta,
      data: results,
      announcementData,
      isLoading: false,
    });
  }

  deleteSchedule() {
    const {
      deletePriceChangeSchedule,
      data: { announcementId: id },
    } = this.props;

    const { showDeleteConfirm } = this.state;

    return deletePriceChangeSchedule(id, showDeleteConfirm.id).then((response) => {
      if (response.error) {
        return response;
      }

      this.setState(
        {
          isLoading: true,
          showDeleteConfirm: {},
        },
        () => {
          this.load().catch((err) => new Error(err));
        },
      );

      return response;
    });
  }

  render() {
    const {
      meta,
      isEdit,
      isLoading,
      formIsOpen,
      showApplied,
      announcementData,
      showDeleteConfirm,
      showCancelConfirm,
      data: dataWithApplied,
    } = this.state;

    const { isMobile } = this.props;

    if (isLoading) return <Spinner center />;

    const {
      title,
      date_start: dateStart,
      time_start: timeStart,
      place: { name: placeName },
    } = announcementData.event;

    const { sector, row, tickets, total_price: price, category, currency } = announcementData;

    const scheduleDataWithApplied = dataWithApplied.filter((item) => item.applied);
    const scheduleData = dataWithApplied.filter((item) => !item.applied);
    const newData = [...scheduleDataWithApplied, ...scheduleData];
    const data = showApplied ? newData : scheduleData;
    const appliedExists = scheduleDataWithApplied.length > 0;

    const scheduleList = data.map((item, index) => {
      const {
        id,
        change,
        applied,
        change_date: changeDate,
        discount_type: discountType,
        destination_type: destinationType,
      } = item;
      const discountTypeText =
        discountType === PRICE_CHANGE_TYPES.NUMBER ? <Styled.Icon src={currency.icon} /> : '%';
      const changeInfo = (priceProps = {}) => (
        <>
          {getDestinationText(destinationType, applied)}
          &nbsp;
          <Styled.Price>
            <SplittedNumber {...priceProps}>{change}</SplittedNumber>
            {discountTypeText}
          </Styled.Price>
        </>
      );
      const date = `${formatRawDateTime(changeDate)}, в ${moment(changeDate).format(
        DATE_FORMATS.TIME_PREVIEW,
      )}`;

      const isLast = index === scheduleDataWithApplied.length - 1;

      return (
        <Styled.ScheduleItem key={id} withOpacity={applied} last={isLast}>
          <Tooltip
            text={changeInfo({ withSuffix: true, white: true, icon: currency.icon })}
            placement="topLeft"
          >
            <Styled.ChangeInfo>
              {destinationType === DESTINATION_TYPES.MARKUP ? <ArrowGreenUpIcon /> : <ArrowRedDownIcon />}
              {changeInfo()}
            </Styled.ChangeInfo>
          </Tooltip>
          <Tooltip text={date}>
            <Styled.Date>{date}</Styled.Date>
          </Tooltip>
          <Styled.Buttons last={isLast}>
            {applied ? (
              <Tooltip text="Изменение применено">
                <Styled.Applied>
                  <CheckGreenIcon fill="#5c5c5c" />
                </Styled.Applied>
              </Tooltip>
            ) : (
              <>
                {!isMobile && (
                  <Tooltip text="Удалить">
                    <Button
                      opacity={0.4}
                      onlySmallIcon
                      transparent
                      data={item}
                      onClick={this.showDeleteConfirm}
                    >
                      <TrashIcon />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip text="Редактировать">
                  <Button opacity={0.4} onlySmallIcon transparent data={item} onClick={this.editSchedule}>
                    <PenIcon />
                  </Button>
                </Tooltip>
              </>
            )}
          </Styled.Buttons>
        </Styled.ScheduleItem>
      );
    });

    return (
      <>
        <Styled.GlobalStyles />
        {!isEmpty(showDeleteConfirm) && (
          <ConfirmModal
            withPopover
            data={{
              title: 'Удалить запланированное изменение цены?',
              text: 'Данное изменение удалится. Вы уверены, что хотите совершить это действие?',
              onSubmit: () => this.deleteSchedule(),
            }}
            closeModal={this.hideDeleteConfirm}
            padding="24px"
          />
        )}
        {showCancelConfirm && (
          <ConfirmModal
            withPopover
            data={{
              title: 'Отменить действие?',
              text: 'Вся набранная вами информация удалится. Вы уверены, что хотите отменить действие?',
              onSubmit: () => this.setState({ isEdit: {}, formIsOpen: false, showCancelConfirm: false }),
            }}
            closeModal={() => this.setState({ showCancelConfirm: false })}
            padding="24px"
          />
        )}
        <Modal.Title>Планирование изменения цены</Modal.Title>
        <Styled.Inner>
          <Styled.EventContainer>
            <Styled.EventTime>{formatFullDateAndTime(`${dateStart} ${timeStart}`)}</Styled.EventTime>
            <Styled.EventName>{title}</Styled.EventName>
            <Styled.EventInfo>{placeName}</Styled.EventInfo>
          </Styled.EventContainer>
          <Styled.TicketsRow withoutBorder={formIsOpen && data.length === 0}>
            <Styled.TicketsRowInfo>
              <Styled.TicketsRowLabel>Сектор / Категория</Styled.TicketsRowLabel>
              <Tooltip text={sector || category}>
                <Styled.TicketsRowValue>{sector || category}</Styled.TicketsRowValue>
              </Tooltip>
            </Styled.TicketsRowInfo>
            <Styled.TicketsRowInfo>
              <Styled.TicketsRowLabel>Ряд</Styled.TicketsRowLabel>
              <Tooltip text={row}>
                <Styled.TicketsRowValue>{row}</Styled.TicketsRowValue>
              </Tooltip>
            </Styled.TicketsRowInfo>
            <Styled.TicketsRowInfo>
              <Styled.TicketsRowLabel>Места</Styled.TicketsRowLabel>
              <Tooltip text={renderTickets(tickets)}>
                <Styled.TicketsRowValue>{renderTickets(tickets)}</Styled.TicketsRowValue>
              </Tooltip>
            </Styled.TicketsRowInfo>
            <Styled.TicketsRowInfo>
              <Styled.TicketsRowLabel>
                Цена за шт, <Styled.Icon src={currency.icon} />
              </Styled.TicketsRowLabel>
              <Tooltip
                text={
                  <span>
                    <SplittedNumber withSuffix white icon={currency.icon}>
                      {price}
                    </SplittedNumber>
                  </span>
                }
                placement="topLeft"
              >
                <Styled.TicketsRowValue>
                  <SplittedNumber>{price}</SplittedNumber>
                </Styled.TicketsRowValue>
              </Tooltip>
            </Styled.TicketsRowInfo>
          </Styled.TicketsRow>
          {appliedExists && (
            <>
              {isMobile && formIsOpen ? null : (
                <Styled.ShowApplied onClick={this.toggleApplied} showApplied={showApplied}>
                  <ArrowIcon opacity={0.8} fill="#2F80ED" />
                  {`${showApplied ? 'Скрыть' : 'Показать'} прошедшие изменения`}
                </Styled.ShowApplied>
              )}
            </>
          )}
          {formIsOpen && isMobile ? null : (
            <Styled.Schedule formIsOpen={formIsOpen} id="schedule">
              {isMobile ? (
                scheduleList
              ) : (
                <InfiniteScroll
                  next={this.loadMore}
                  dataLength={data.length}
                  hasMore={meta.page !== meta.last_page}
                  scrollableTarget={document.getElementById('schedule')}
                  loader={() => null}
                >
                  {scheduleList}
                </InfiniteScroll>
              )}
            </Styled.Schedule>
          )}
          {formIsOpen && (
            <AnnouncementPlanningPriceForm
              price={price}
              isEdit={isEdit}
              hideForm={this.hideForm}
              createSchedule={this.createSchedule}
              currency={currency}
            />
          )}
          {!formIsOpen && (
            <Styled.FooterWrapper>
              <Styled.Footer>
                <Button transparent type="button" onClick={this.props.closeModal}>
                  Закрыть
                </Button>
                <Button type="button" onClick={this.showForm}>
                  Запланировать
                </Button>
              </Styled.Footer>
            </Styled.FooterWrapper>
          )}
        </Styled.Inner>
      </>
    );
  }
}

export default withCheckIsMobile(AnnouncementPlanningPriceModal);
