import Badge from 'components/Badge';
import Box from 'components/Box';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Can from 'containers/Can';
import React from 'react';
import LOCALES from 'shared/constants/LOCALES';
import PenIcon from '../../../../static/icons/pen.svg';
import TrashIcon from '../../../../static/icons/trash-alt.svg';
import { useTranslation } from '../../../i18n';
import ReferenceModalLayout from '../ReferenceModalLayout';
import ReferenceRelatedItem from './ReferenceRelatedItem';

interface Props {
  name: string;
  isLoading: boolean;
  count?: number;
  data?: any[];
  modalType?: string;
  href?: any;
  editHallLayout?: (_: any, id: number) => void;
  deleteHallLayout?: (_: any, id: number) => void;
  permission?: string;
  withButtons?: boolean;
}

const ReferenceRelated: React.FC<Props> = ({
  isLoading,
  count,
  data = [],
  modalType,
  href,
  name,
  permission,
  editHallLayout,
  deleteHallLayout,
  withButtons,
}) => {
  if (isLoading) return <Spinner />;

  const { t } = useTranslation([LOCALES.REFERENCES]);

  if (count) {
    return (
      <>
        <ReferenceModalLayout.Hr />
        <div>
          <Box mb="16px">
            {t('halls.related')} {name}
            <Box ml="8px">
              <Badge>{count}</Badge>
            </Box>
          </Box>
          <ul>
            {data.map((item) => {
              const renderItem = (
                <ReferenceModalLayout.Item key={item.id}>
                  <ReferenceRelatedItem href={href} item={item} modalType={modalType} />
                  {withButtons && (
                    <ReferenceModalLayout.Buttons>
                      <Button onlyIcon transparent onClick={editHallLayout} data={item.id}>
                        <PenIcon />
                      </Button>
                      <Button onlyIcon dangerTransparent onClick={deleteHallLayout} data={item.id}>
                        <TrashIcon />
                      </Button>
                    </ReferenceModalLayout.Buttons>
                  )}
                </ReferenceModalLayout.Item>
              );

              if (permission) {
                return (
                  <Can
                    key={item.id}
                    permission={permission}
                    fallback={() => <ReferenceModalLayout.Item>{item.name}</ReferenceModalLayout.Item>}
                  >
                    {renderItem}
                  </Can>
                );
              }

              return renderItem;
            })}
          </ul>
        </div>
      </>
    );
  }

  return null;
};

export default ReferenceRelated;
