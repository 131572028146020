import { createEffect, createEvent, createStore, sample } from 'effector';
import { NotificationManager } from 'react-notifications';
import {
  deletePlaceFx,
  fetchPlaceFx,
  fetchPlacesFx,
  FormSubmitData,
  Place,
  updatePlaceFx,
} from 'shared/api/reference/places';
import PAGES from 'shared/constants/PAGES';
import { $query } from '../../../model';
import { invoke } from '@withease/factories';
import { createUpdateModalStates } from 'shared/factory/modal';
import { IBaseRequestDataParams } from 'shared/api/types';

const { $loading, $modalId, modalClosed, modalOpened, toggler } = invoke(createUpdateModalStates);

const formSubmitted = createEvent<FormSubmitData>();
const deleteSubmitted = createEvent<IBaseRequestDataParams>();
export const errorsModalClosed = createEvent();

const $place = createStore<Place | null>(null);
const $deleteErrors = createStore<any>(null);

const successNotificationFx = createEffect(() => {
  NotificationManager.success('Успешно');
});

const failNotificationFx = createEffect((message: string | null) => {
  NotificationManager.error(message, 'Ошибка');
});

export const placeUpdateModal = {
  toggler,
  modalOpened,
  modalClosed,
  formSubmitted,
  deleteSubmitted,
  errorsModalClosed,
  $place,
  $loading,
  $modalId,
  $deleteErrors,
};

//load
sample({
  clock: modalOpened,
  target: fetchPlaceFx,
});

sample({
  clock: toggler.open,
  target: $modalId,
});

sample({
  clock: toggler.close,
  fn: () => true,
  target: $loading,
});

sample({
  clock: toggler.close,
  fn: () => null,
  target: $place,
});

sample({
  clock: fetchPlaceFx.done,
  filter: ({ params }) => params.type === 'update',
  fn: ({ result }) => {
    return result;
  },
  target: $place,
});

sample({
  clock: fetchPlaceFx.done,
  filter: ({ params }) => params.type === 'update',
  fn: () => false,
  target: $loading,
});

// update
sample({
  clock: formSubmitted,
  fn: (data) => {
    return {
      data,
      id: data!.id,
      title: data!.title,
      type_id: data.type_id.value,
      location_id: data.location_id.value,
    };
  },
  target: updatePlaceFx,
});

sample({
  source: {
    query: $query,
  },
  clock: updatePlaceFx.done,
  fn: ({ query }) => query,
  target: fetchPlacesFx,
});

//delete

sample({
  clock: deleteSubmitted,
  target: deletePlaceFx,
});

sample({
  clock: deletePlaceFx.fail,
  fn: ({ error, params }) => {
    return {
      errors: error.message,
      name: params.title,
      page: PAGES.REFERENCE_PLACES,
    };
  },
  target: $deleteErrors,
});
sample({
  source: {
    query: $query,
  },
  clock: deletePlaceFx.done,
  fn: ({ query }) => query,
  target: fetchPlacesFx,
});

sample({
  clock: deletePlaceFx.done,
  target: toggler.close,
});

sample({
  clock: errorsModalClosed,
  fn: () => null,
  target: $deleteErrors,
});

// notifications
sample({
  clock: [updatePlaceFx.done, deletePlaceFx.done],
  target: [successNotificationFx, toggler.close],
});

sample({
  clock: updatePlaceFx.fail,
  fn: ({ error }) => {
    return error.response?.data?.detail?.includes('already exist')
      ? 'Площадка с таким именем уже существует'
      : null;
  },
  target: failNotificationFx,
});
