const REFERENCE_TABS_NOUN_NAME = {
  countries: [],
  locations: ['Город', 'Города', 'Городов'],
  currency: ['Валюта', 'Валюты', 'Валют'],
  places: ['Площадка', 'Площадки', 'Площадок'],
  halls: ['Зал', 'Зала', 'Залов'],
  'event-categories': [],
  expansions: [],
  persons: ['Персоналия', 'Персоналии', 'Персоналий'],
  subdomains: ['Домен', 'Домена', 'Доменов'],
  actual: [],
  qna: ['результат', 'результата', 'результатов'],
  markup: ['результат', 'результата', 'результатов'],
};

const REFERENCE_TABS_COUNT = {
  countries: 'countriesCount',
  locations: 'locationsCount',
  places: 'placesCount',
  halls: 'hallsCount',
  'event-categories': 'eventCategoriesCount',
  expansions: 'expansionsCount',
  persons: 'personsCount',
  currency: 'currencyCount',
  subdomains: 'subdomainsCount',
  actual: 'actualCount',
  qna: 'qnaCount',
  markup: 'markupCount',
};

export { REFERENCE_TABS_COUNT, REFERENCE_TABS_NOUN_NAME };

export default {
  COUNTRIES: 'countries',
  LOCATIONS: 'locations',
  PLACES: 'places',
  HALLS: 'halls',
  EVENT_CATEGORIES: 'event-categories',
  EXPANSIONS: 'expansions',
  PERSONS: 'persons',
  CURRENCY: 'currency',
  SUBDOMAINS: 'subdomains',
  ACTUAL: 'actual',
  QnA: 'qna',
  MARKUP_CREATE: 'markupCreate',
};
