import React from 'react';
import Styled from './styles';
import CloseIcon from '../../../../static/icons/close.svg';

interface Props {
  innerProps: any;
}

const InputWithSelectClearIndicator = (props: Props) => {
  const { innerProps } = props;

  return (
    <Styled.ClearIndicator {...innerProps}>
      <CloseIcon width={10} height={10} opacity={0.4} />
    </Styled.ClearIndicator>
  );
};

export default InputWithSelectClearIndicator;
