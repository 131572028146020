import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
    .modal {
      &__wrapper {
        max-width: 432px;
      }
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      .modal {
      &__table-wrapper {
        vertical-align: bottom;
      }

      &__wrapper {
        max-width: 100%;
      }

      &__body {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100vh;
        padding: 16px 16px 32px;
        border-radius: 8px 8px 0 0;
        padding-bottom: 8px;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      }

      &__footer {
        margin-bottom: -8px;
      }
    }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Placeholder: styled.div`
    height: 80px;
  `,
};
