import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Wrapper: styled.div`
    box-sizing: border-box;
    width: 100%;
    max-width: 880px;
    margin: 0 auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 16px;
    }
  `,

  ButtonWrapper: styled.div`
    margin: auto auto 0;
  `,

  Row: styled.div`
    display: flex;
    align-items: flex-start;

    &:not(:last-of-type) {
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid #f5f5f5;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
    }
  `,

  Name: styled.div`
    width: 176px;
    margin-right: 32px;
    font-size: 16px;
    line-height: 21px;
    color: #000;
    flex-shrink: 0;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      margin: 0 0 24px;
    }
  `,

  Desc: styled.div`
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.64);
  `,

  Field: styled.div`
    width: 385px;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
    }
  `,

  DisplayType: styled.div`
    width: 100%;
  `,

  DisplayTypeField: styled.div`
    width: 500px;
    margin-bottom: 32px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
    }
  `,
};
