import React from 'react';
import omit from 'lodash/omit';
import Styled from './styles';
import CloseIcon from '../../../../static/icons/close.svg';

interface Props {
  innerProps: any;
}

const InputWithSelectMultiValueRemove: React.FC<Props> = (props) => {
  const { innerProps } = props;

  return (
    <Styled.RemoveMultiValue {...omit(innerProps, 'className')}>
      <CloseIcon width={10} height={10} opacity={0.24} />
    </Styled.RemoveMultiValue>
  );
};

export default InputWithSelectMultiValueRemove;
