import React from 'react';
import { Field } from 'react-final-form';
import { required } from 'shared/helpers/validations';
import CheckIcon from '../../../../../static/icons/check-white.svg';
import HelpIcon from '../../../../../static/icons/info.svg';
import Styled from './styles';
import Tooltip from '../../../Tooltip';

interface VariantProps {
  name: string;
  value: number | string;
  withoutLabel?: boolean;
  withoutFormat?: boolean;
  children: JSX.Element;
  info?: string;
  disabled?: boolean;
}

const Variant: React.FC<VariantProps> = ({
  name,
  value,
  children,
  withoutLabel,
  info,
  withoutFormat,
  disabled,
}) => (
  <Field name={name} value={value} type="radio" validate={required}>
    {({ input }) => {
      const handleChange = (event) => {
        input.onChange(withoutFormat ? event.target.value : Number(event.target.value));
      };

      return (
        <Styled.Variant checked={input.checked} disabled={disabled}>
          {children}
          <Styled.Radio
            type="radio"
            name={input.name}
            value={input.value}
            onChange={handleChange}
            disabled={disabled}
          />
          {!withoutLabel && (
            <Styled.Label checked={input.checked}>
              <Styled.CheckIconWrapper>
                <CheckIcon fill="#2DC472" />
              </Styled.CheckIconWrapper>
              Выбрано
            </Styled.Label>
          )}
          {info && (
            <Tooltip text={info}>
              <Styled.Mobile type="button">
                <HelpIcon />
              </Styled.Mobile>
            </Tooltip>
          )}
          <Styled.Number>{value}</Styled.Number>
        </Styled.Variant>
      );
    }}
  </Field>
);

export default Variant;
