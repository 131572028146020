import moment from 'moment';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import { required, validDate } from 'shared/helpers/validations';
import { ModalFunctions } from '../../../../interfaces/modal';
import Button from '../../Button';
import Form from '../../Form';
import { Select, DateTimePicker } from '../../FormControls';
import Modal from '../../Modal/Modal';
import Styled from './styles';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  addNotification: ({ id, sendAt }: { id: number; sendAt: any }) => any;
}

const EditUserCustomerModal: React.FC<ModalProps> = (props) => {
  const { data, addNotification, closeModal } = props;
  const { id } = data;

  const onSubmit = (formData) => {
    const { when, datetime } = formData;

    const sendAt =
      when !== 'custom'
        ? moment()
            .add(+when, 'minutes')
            .format(DATE_FORMATS.DATE_TIME)
        : datetime.format(DATE_FORMATS.DATE_TIME);

    return addNotification({
      id,
      sendAt,
    });
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Form
        onSubmit={onSubmit}
        onSuccess={closeModal}
        render={({ handleSubmit, form, values }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title>Добавление напоминания</Modal.Title>
            <Styled.Label>Напомнить через</Styled.Label>
            <Styled.Fields>
              <Field name="when" validate={required}>
                {({ input, meta }) => (
                  <Select
                    options={[
                      { value: '10', name: '10 минут' },
                      { value: '15', name: '15 минут' },
                      { value: '30', name: '30 минут' },
                      { value: 'custom', name: 'Другое' },
                    ]}
                    {...input}
                    meta={meta}
                    withEmpty
                    placeholder="Выбрать"
                  />
                )}
              </Field>

              {values.when && values.when === 'custom' && (
                <Field name="datetime" validate={validDate}>
                  {({ input, meta }) => (
                    <DateTimePicker
                      label="Дата и время"
                      handleForm={form}
                      input={input}
                      meta={meta}
                      name="datetime"
                      outdatedToggler={false}
                      inModal
                    />
                  )}
                </Field>
              )}
            </Styled.Fields>
            <Modal.Footer>
              <Button transparent type="button" onClick={closeModal}>
                Отмена
              </Button>
              <Button>Готово</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default EditUserCustomerModal;
