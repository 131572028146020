import api from 'shared/services/api';

export const currencySources = {
  root: 'reference/currency',
  detail: (id: number | string) => `reference/currency/${id}`,
  search: 'reference/currency/search',

  async getCurrencies() {
    const response = await api.get('/reference/currency');

    return response.data.data.results;
  },
};
