import Placeholder from 'components/Placeholder';
import SplittedNumber from 'components/SplittedNumber';
import permissions from 'config/permissions';
import Can from 'containers/Can';
import Layout from 'containers/Layout';
import { useTranslation } from 'next-i18next';
import { noun } from 'plural-ru';
import React from 'react';
import { MainLayout } from 'widgets/layouts/main-layout';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { ReferenceCreateButton } from 'widgets/reference/reference-create-button';
import { ReferenceSearch } from 'widgets/reference/reference-search';
import REFERENCE_TABS from 'shared/constants/REFERENCE_TABS';
import { useToggler } from 'shared/lib/toggler';
import { useList } from 'shared/lib/use-list';
import { ReferenceTabs } from 'shared/ui/reference/tabs';
import { Spinner } from 'shared/ui/spinner';
import { $loading, $places, pageMounted } from '../model';
import { PlaceCreateModal, placeCreateModal } from './create-modal';
import { PlacesList } from './list';
import { PlaceUpdateModal } from './update-modal';
import { PlaceViewModal } from './view-modal';

export const ReferencePlacesPage = () => {
  const name = REFERENCE_TABS.PLACES;
  const { t } = useTranslation();
  const { data } = useList({
    mountPage: pageMounted,
    places: $places,
    loading: $loading,
  });
  const { open: openCreatePlaceModal } = useToggler(placeCreateModal.toggler);
  const { loading, places } = data;
  const { count } = places;

  return (
    <MainLayout title={t('references:title')}>
      <Layout disableLayout withoutPaddingsOnMobile title={t('references:title')}>
        <ReferenceTabs loading={loading}>
          <ReferenceLayout.MainWrapper>
            <ReferenceLayout.Header>
              <>
                {!loading ? (
                  <div>
                    <SplittedNumber>{count}</SplittedNumber>{' '}
                    <ReferenceLayout.CountLabel>
                      {noun(count, 'Площадка', 'Площадки', 'Площадок')}
                    </ReferenceLayout.CountLabel>
                  </div>
                ) : (
                  <ReferenceLayout.PlaceholderContainer>
                    <Placeholder />
                  </ReferenceLayout.PlaceholderContainer>
                )}
                <ReferenceLayout.Actions>
                  <ReferenceSearch referenceName={name} resetPagination />
                  <ReferenceLayout.HeaderControls>
                    <Can permission={permissions.reference}>
                      <ReferenceCreateButton referenceName={name} onClick={openCreatePlaceModal} />
                    </Can>
                  </ReferenceLayout.HeaderControls>
                </ReferenceLayout.Actions>
              </>
            </ReferenceLayout.Header>
            <ReferenceLayout.Wrapper>
              {loading ? <Spinner center /> : <PlacesList data={places} />}
            </ReferenceLayout.Wrapper>
          </ReferenceLayout.MainWrapper>
        </ReferenceTabs>
      </Layout>
      <PlaceCreateModal />
      <PlaceUpdateModal />
      <PlaceViewModal />
    </MainLayout>
  );
};
