import Modal from 'components/Modal';
import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Close: styled(Modal.SecondaryCloseButton)`
    @media (max-width: ${BREAKPOINTS.MD}) {
      top: 16px;
      height: 20px;

      & > img {
        height: 14px;
      }
    }
  `,
};
