interface User {
  // eslint-disable-next-line camelcase
  first_name?: string;
  // eslint-disable-next-line camelcase
  last_name?: string;
  username?: string;
}

function getUserName(user: User | null, withDefaultValue?: boolean) {
  if (withDefaultValue && !user?.first_name && !user?.last_name && !user?.username) return 'Без имени';

  return user?.first_name && user?.last_name ? `${user.first_name} ${user.last_name}` : user?.username;
}

function getManagerName(user: User) {
  return user?.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : 'Без имени';
}

export { getUserName, getManagerName };
