import locationSources from 'shared/api/reference/locations';

export const REFERENCE_FILTERS = {
  location: {
    query: 'location',
    props: {
      route: {
        ...locationSources,
        detail: (id: number) => `${locationSources.root}/${id}`,
      },
    },
  },
};
