import React, { Component } from 'react';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import UndoIcon from '../../../../../../static/icons/undo.svg';
import RedoIcon from '../../../../../../static/icons/redo.svg';
import { widgetSources } from 'entities/widget';
import Button from '../../../../Button';
import { InputWithAutocomplete, InputWithSelect } from '../../../../FormControls';
import { renderFilters } from 'shared/helpers/optionsRenderers';
import { InputWithSelectAll, OptionWithCheckboxAndTypes } from '../../../../FormControls/InputWithSelect';
import Styled from './styles';

interface SelectorProps {
  onChange: (data: any, seconData?: any) => any;
  setDefaultQuery: (data: any, seconData?: any) => any;
  type: string;
  initialCurrency?: number;
  currentCurrency?: number;
  withCurrency?: boolean;
  selectedDate?: any;
  eventDates?: any[];
  onChangeDate?: Function;
  defaultQuery?: any;
}

interface SelectorState {
  query?: any;
  value?: any;
  selectedCurrency: any;
}

class PatternSelector extends Component<SelectorProps, SelectorState> {
  constructor(props) {
    super(props);

    this.state = {
      query: null,
      value: null,
      selectedCurrency: props.initialCurrency,
    };

    this.sortedEventDates = this.sortedEventDates.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if (props.initialCurrency !== props.currentCurrency) {
      return {
        selectedCurrency: props.currentCurrency,
      };
    }

    return null;
  }

  handleChange = (query) => {
    this.setState(
      {
        query,
        value: query,
      },
      () => {
        this.props.onChange(query);
      },
    );
  };

  setCurrentQuery = () => {
    this.setState(
      (prevState) => ({
        value: prevState.query,
      }),
      () => {
        const { onChange } = this.props;
        const { query, selectedCurrency } = this.state;

        onChange(query, selectedCurrency);
      },
    );
  };

  setDefaultQuery = () => {
    const selectedQuery = this.state.query;

    this.setState(
      {
        value: null,
      },
      () => {
        const { setDefaultQuery, initialCurrency } = this.props;

        setDefaultQuery(selectedQuery, initialCurrency);
      },
    );
  };

  sortedEventDates() {
    const { eventDates } = this.props;
    if (!eventDates) return eventDates;
    return sortBy(eventDates, (item) => moment(item.label, 'DD MMM YYYY, HH:mm', 'ru').toDate());
  }

  render() {
    const { query, value, selectedCurrency } = this.state;
    const { type, initialCurrency, currentCurrency, withCurrency, eventDates, onChangeDate, selectedDate } =
      this.props;
    const undoNotDisabled = (value && query) || (withCurrency && selectedCurrency !== initialCurrency);
    const redoNotDisabled =
      (!value && query) ||
      (withCurrency &&
        selectedCurrency &&
        selectedCurrency !== initialCurrency &&
        selectedCurrency !== currentCurrency);

    return (
      <Styled.Container withDates={eventDates}>
        <Styled.InputWrapper withDates={eventDates}>
          <InputWithAutocomplete
            route={widgetSources}
            routeName="pattern"
            query={{
              pattern_type: type,
            }}
            optionsRenderer={renderFilters}
            placeholder="Выбрать шаблон"
            noBackground
            disablePaginate
            value={value}
            onChange={this.handleChange}
            isClearable={false}
            dataSelenium="pattern-select"
          />
        </Styled.InputWrapper>
        {eventDates && (
          <Styled.DateSelect>
            Выбор даты
            <InputWithSelect
              isMulti
              withCounter
              withExceptOption
              placeholder={selectedDate ? selectedDate.length === 1 && selectedDate[0].label : ''}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
              isClearable={false}
              options={this.sortedEventDates()}
              onChange={onChangeDate}
              value={selectedDate}
              components={{ Option: OptionWithCheckboxAndTypes, MenuList: InputWithSelectAll }}
              meta={{}}
            />
          </Styled.DateSelect>
        )}
        <Styled.Buttons>
          <Button
            type="button"
            onlyIcon
            transparent
            disabled={!undoNotDisabled}
            onClick={this.setDefaultQuery}
            data-selenium="pattern-button-undo"
          >
            <UndoIcon />
          </Button>
          <Button
            type="button"
            onlyIcon
            transparent
            disabled={!redoNotDisabled}
            onClick={this.setCurrentQuery}
            data-selenium="pattern-button-redo"
          >
            <RedoIcon key="redo" />
          </Button>
        </Styled.Buttons>
      </Styled.Container>
    );
  }
}

export default PatternSelector;
