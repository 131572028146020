import omit from 'lodash/omit';
import React from 'react';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { getTranslations } from '../../../pages/landings/helpers/landingHelpers';
import Styled from './styles';

interface Props {
  isFocused: boolean;
  children: any;
  innerProps: any;
  data: any;
}

const OptionWithPlaceInfo: React.FC<Props> = (props) => {
  const { children, innerProps, isFocused, data } = props;

  const info = getTranslations(data?.data?.place?.location?.info, LANGUAGES.RU);

  return (
    <Styled.Option {...omit(innerProps, ['onMouseMove', 'onMouseOver'])} isFocused={isFocused}>
      <Styled.PlaceInfo>
        {children}
        <Styled.PlaceInfoAddress>{info?.title}</Styled.PlaceInfoAddress>
      </Styled.PlaceInfo>
    </Styled.Option>
  );
};

export default OptionWithPlaceInfo;
