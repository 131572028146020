export default {
  IN: 'in',
  NOT_IN: 'notIn',
  EQUAL: '=',
  NOT_EQUAL: '!=',
  BETWEEN: 'between',
  NOT_BETWEEN: 'not_between',
  LESS: '<',
  GREATER: '>',
  LESS_OR_EQUAL: '<=',
  GREATER_OR_EQUAL: '>=',
  ALL: 'all',
  NONE: 'none',
};
