import { compact, isEmpty, isObject } from 'lodash';
import { IConstructorLandingData, ILandingGalleryAttachment } from 'shared/api/landings/constructor';
import { AttachmentTypes } from 'shared/constants/ATTACHMENTS_TYPE';
import landingsSites from 'shared/sources/landings/landingsSites';

export const deleteGalleryAttachments = ({ modalId }: { modalId: number }) => {
  landingsSites.deleteConstructorAttachments({
    tech_name: modalId,
    id: AttachmentTypes.gallery.id,
  });
};

export const createGalleryAttachments = ({
  valuesToUpdate,
  modalId,
}: {
  valuesToUpdate: IConstructorLandingData;
  modalId: number;
}) => {
  const origin: string[] = [];
  const webp: string[] = [];

  valuesToUpdate?.main_page_gallery?.forEach((attachment) => {
    origin.push(attachment.img);
    webp.push(attachment.img_webp);
  });

  const attachments = {
    attachment_type_id: AttachmentTypes.gallery.id,
    data: { origin: origin, webp: webp },
  };

  if (!isEmpty(attachments?.data?.origin)) {
    landingsSites.createConstructorAttachments({
      tech_name: modalId,
      attachments: attachments,
    });
  }
};

export const updateGalleryAttachments = ({
  valuesToUpdate,
  modalId,
  galleryAttachment,
}: {
  valuesToUpdate: IConstructorLandingData;
  modalId: number;
  galleryAttachment: ILandingGalleryAttachment;
}) => {
  const origin: string[] = [];
  const webp: string[] = [];

  const newAttachments = compact(
    valuesToUpdate?.main_page_gallery?.map((attachment: string | { img: string; img_webp: string }) => {
      if (isObject(attachment)) {
        origin.push(attachment.img);
        webp.push(attachment.img_webp);
      } else {
        return attachment;
      }
    }),
  );

  if (!isEmpty(newAttachments)) {
    newAttachments.map((attacment) => {
      const uniquePart = attacment!.match(/\/([^\/]+)\.[^/.]+$/)?.[1];

      if (!uniquePart) return;

      const filteredOrigin = galleryAttachment.data.origin.filter((url) => url.includes(uniquePart));
      const filteredWebp = galleryAttachment.data.webp.filter((url) => url.includes(uniquePart));

      origin.push(...filteredOrigin);
      webp.push(...filteredWebp);
    });
  }

  const attachments = {
    data: {
      origin,
      webp,
    },
  };

  if (!isEmpty(attachments?.data?.origin)) {
    landingsSites.updateConstructorGalleryAttachments({
      tech_name: modalId,
      attachment_type_id: AttachmentTypes.gallery.id,
      attachments: attachments,
    });
  }
};

export const createConstuctorAttachments = ({
  valuesToUpdate,
  modalId,
}: {
  valuesToUpdate: IConstructorLandingData;
  modalId: number;
}) => {
  if (!isEmpty(valuesToUpdate?.main_page_banner_image)) {
    const attachments = {
      attachment_type_id: AttachmentTypes.banner.id,
      data: valuesToUpdate?.main_page_banner_image,
    };

    if (!isEmpty(attachments)) {
      landingsSites.createConstructorAttachments({
        tech_name: modalId,
        attachments: attachments,
      });
    }
  }

  if (!isEmpty(valuesToUpdate?.ui_config_bg_pattern)) {
    const attachments = {
      attachment_type_id: AttachmentTypes.background_pattern.id,
      data: [valuesToUpdate?.ui_config_bg_pattern],
    };

    if (!isEmpty(attachments)) {
      landingsSites.createConstructorAttachments({
        tech_name: modalId,
        attachments: attachments,
      });
    }
  }
};

export const updateConstuctorAttachments = ({
  valuesToUpdate,
  modalId,
}: {
  valuesToUpdate: IConstructorLandingData;
  modalId: number;
}) => {
  if (!isEmpty(valuesToUpdate?.main_page_banner_image)) {
    const attachments = {
      data: valuesToUpdate?.main_page_banner_image,
    };

    if (!isEmpty(attachments)) {
      landingsSites.updateConstructorGalleryAttachments({
        tech_name: modalId,
        attachment_type_id: AttachmentTypes.banner.id,
        attachments: attachments,
      });
    }
  }

  if (!isEmpty(valuesToUpdate?.ui_config_bg_pattern)) {
    const attachments = {
      data: [valuesToUpdate?.ui_config_bg_pattern],
    };

    if (!isEmpty(attachments)) {
      landingsSites.updateConstructorGalleryAttachments({
        tech_name: modalId,
        attachment_type_id: AttachmentTypes.background_pattern.id,
        attachments: attachments,
      });
    }
  }
};

export const deleteConstuctorAttachments = ({
  modalId,
  valuesToUpdate,
  state,
}: {
  valuesToUpdate: IConstructorLandingData;
  modalId: number;
  state: { initialValues: IConstructorLandingData };
}) => {
  if (state.initialValues?.main_page_banner_image && isEmpty(valuesToUpdate?.main_page_banner_image)) {
    landingsSites.deleteConstructorAttachments({
      tech_name: modalId,
      id: AttachmentTypes.banner.id,
    });
  }

  if (state.initialValues?.ui_config_bg_pattern && isEmpty(valuesToUpdate?.ui_config_bg_pattern)) {
    landingsSites.deleteConstructorAttachments({
      tech_name: modalId,
      id: AttachmentTypes.background_pattern.id,
    });
  }
};
