import React from 'react';
import Styled from './styles';
import { ANNOUNCEMENTS_TYPES } from 'shared/constants/ANNOUNCEMENT_TYPES';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalTypeChange: React.FC<IHistoryItem> = ({ data, user }) => (
  <Styled.Info>
    <Styled.InfoTitle>Изменен тип объявления</Styled.InfoTitle>
    <AnnouncementHistoryModalName data={user} />
    <Styled.InfoText>{ANNOUNCEMENTS_TYPES[data.new.announcement_type]}</Styled.InfoText>
  </Styled.Info>
);

export default AnnouncementHistoryModalTypeChange;
