import Button from 'components/Button';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import useAttachments from 'shared/helpers/attachments';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import api from 'shared/services/api';
import attachmentsSource from 'shared/sources/attachments';
import landingsPlaygroundsSources from 'shared/sources/landings/landingsPlaygrounds';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import {
  buildPlacesAttachments,
  getDifferenceInContentGroups,
  getTranslations,
} from '../../helpers/landingHelpers';
import { Place } from '../../types/Place';
import PlacesForm from './PlacesForm';
import isEmpty from 'lodash/isEmpty';
import { updateAttachments } from 'shared/lib/attachments';
import { getCookie } from 'shared/lib/cookie';
import COOKIES from 'shared/constants/COOKIES';
import config from 'config/config';

interface PlacesUpdateProps {
  data: Place;
  onSuccess: (data: any) => any;
  onChangeLng: () => any;
  showDeleteModal: () => any;
  isMobile: boolean;
  lng: string;
  closeModal: () => any;
}

const PlacesUpdate: React.FC<PlacesUpdateProps> = (props) => {
  const { data, onSuccess, onChangeLng, showDeleteModal, isMobile, lng, closeModal } = props;
  const { t } = useTranslation();

  const cover = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Cover;
  });
  const gallery = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Gallery;
  });
  const preview = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Preview;
  });
  const coverInfo = cover?.info.find((i) => i.language_code === lng);
  const previewInfo = preview?.info.find((i) => i.language_code === lng);
  const info = getTranslations(data.info, lng);

  const initialValues = {
    id: data.id,
    title: info?.title,
    address: info?.address,
    description: info?.description,
    annotation: info?.annotation,
    contentGroups:
      (data.content_groups &&
        data.content_groups
          .filter((i) => i.id)
          .map((item) => ({
            value: item.id,
            label: item.title,
          }))) ||
      [],
    place: data.place && {
      label: data.place.title,
      value: data.place.id,
    },
    cover: cover?.data,
    preview: preview?.data,
    gallery: gallery?.data,
    ...(coverInfo && { cover_title: coverInfo.title, cover_alt: coverInfo.alt }),
    ...(previewInfo && { preview_title: previewInfo.title, preview_alt: previewInfo.alt }),
    qna:
      data.qna.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
  };

  const deletePlacesAttachments = (formData, attachementID) =>
    attachmentsSource.deletePlacesAttachments(formData, attachementID);

  const createPlacesAttachments = (formData, attachementID) => {
    return attachmentsSource.createPlacesAttachments(formData, attachementID);
  };

  const editPlacesAttachments = (formData, attachementID, formAttachment) => {
    return attachmentsSource.editPlacesAttachments(formData, attachementID, formAttachment);
  };

  const { buildCoverAttachment, buildPreviewAttachment, buildGalleryAttachment } = useAttachments({
    deleteMetod: deletePlacesAttachments,
    createMetod: createPlacesAttachments,
    editMetod: editPlacesAttachments,
    data,
  });

  const updateAttachmentsInfo = async ({ id, formData, formLang }) => {
    const place = await api.get(landingsPlaygroundsSources.detail(id)).then((res) => res.data);
    const infoAttachments = updateAttachments(place, { lng: formLang, [formLang]: formData });

    await Promise.all(
      infoAttachments
        .filter((attachment) => !isEmpty(attachment.info))
        .map(async (attachment) => {
          const route = `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/landings/places`;
          if (attachment.info?.is_create) {
            const response = await api.post(
              `${route}/${id}/attachments/${attachment.attachment_type_id}/info`,
              {
                ...attachment.info,
                language_code: attachment.language_code,
              },
            );

            return response;
          }

          const response = await api.patch(
            `${route}/${id}/attachments/${attachment.attachment_type_id}/info/${attachment.language_code}`,
            attachment.info,
          );

          return response;
        }),
    );
  };

  const handleUpdate = async (submitData) => {
    const formLang = submitData.lng;
    const formData = submitData[formLang];
    if (formLang === LANGUAGES.RU && isEqual(formData, initialValues)) {
      return {};
    }

    const translations = getTranslations(data.info, formLang);

    if (translations) {
      await landingsPlaygroundsSources.updateTranslation(data.id, formLang, formData);
    } else {
      await landingsPlaygroundsSources.createTranslation(data.id, { ...formData, language_code: formLang });
    }
    if (formLang !== LANGUAGES.RU) {
      await updateAttachmentsInfo({ id: data.id, formData, formLang });
      return {};
    }

    const { contentGroupsforDelete, contentGroupsforUpdate, qnaforUpdate, qnaforDelete } =
      getDifferenceInContentGroups({
        initialData: initialValues,
        formData,
      });

    if (contentGroupsforDelete.length) {
      landingsPlaygroundsSources.deleteContentGroups(data.id, contentGroupsforDelete);
    }

    if (contentGroupsforUpdate.length) {
      const groupsResponse: any = await landingsPlaygroundsSources
        .createContentGroups(data.id, contentGroupsforUpdate)
        .catch((error) => error);

      if (groupsResponse.response?.data?.detail.includes('Place with content group')) {
        return {
          error: {
            response: {
              data: {
                error: ['Данная площадка с таким тегом существует'],
              },
            },
          },
        };
      }
    }

    if (qnaforUpdate.length) {
      landingsPlaygroundsSources.createQnA(data.id, qnaforUpdate);
    }

    if (qnaforDelete.length) {
      landingsPlaygroundsSources.deleteQnA(data.id, qnaforDelete);
    }

    const attachments = await buildPlacesAttachments(formData);

    await buildCoverAttachment(formData, attachments);
    await buildPreviewAttachment(formData, attachments);
    await buildGalleryAttachment(formData, attachments);
    await updateAttachmentsInfo({ id: data.id, formData, formLang });

    const response = await api.patch(landingsPlaygroundsSources.detail(data.id), {
      attachments,
      place_id: formData.place.value,
      slug: data.slug,
      company_id: data.company_id,
    });

    return response;
  };

  return (
    <PlacesForm
      isEdit
      title="Редактировать площадку"
      onSubmit={handleUpdate}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onChangeLng={onChangeLng}
      lng={lng}
    >
      <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
        {isMobile ? <TrashIcon /> : t('forms:delete')}
      </Button>
      <Button type="button" transparent onClick={closeModal}>
        {t('forms:cancel')}
      </Button>
      <Button type="submit">{t('forms:save')}</Button>
    </PlacesForm>
  );
};

export default withCheckIsMobile(PlacesUpdate);
