import Form from 'components/Form';
import { InputWithSelect, Input } from 'components/FormControls';
import { OptionWithCheckbox } from 'components/FormControls/InputWithSelect';
import Modal from 'components/Modal';
import React from 'react';
import { Field } from 'react-final-form';
import { ModalLayout } from 'widgets/layouts/modal-layout';
import { companySources } from 'entities/company';
import { composeValidators, required, validEmail, minValue } from 'shared/helpers/validations';
import Styled from './styled';

interface FormProps {
  isInvite?: boolean;
  title: string;
  onSubmit: (data: object) => void;
  initialValues?: {
    [key: string]: object;
  };
  children: React.ReactNode;
}

export const CompanyMemberForm: React.FC<FormProps> = ({ children, onSubmit, isInvite, title, ...rest }) => (
  <Form
    onSubmit={onSubmit}
    {...rest}
    render={({ handleSubmit }: { handleSubmit: () => void }) => (
      <form onSubmit={handleSubmit}>
        <Styled.GlobalStyles />
        <Modal.Title>{title}</Modal.Title>
        {isInvite && (
          <div>
            <ModalLayout.Row>
              <ModalLayout.Sidebar>
                <ModalLayout.RowTitle>Сотрудник</ModalLayout.RowTitle>
                <ModalLayout.Description>Основная информация</ModalLayout.Description>
              </ModalLayout.Sidebar>
              <ModalLayout.Content>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Field name="last_name" validate={composeValidators(required, minValue(3))}>
                      {({ input, meta }) => <Input label="Фамилия" meta={meta} {...input} />}
                    </Field>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Field name="first_name" validate={composeValidators(required, minValue(3))}>
                      {({ input, meta }) => <Input label="Имя" meta={meta} {...input} />}
                    </Field>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Field name="email" validate={composeValidators(required, validEmail)}>
                      {({ input, meta }) => <Input label="Почта" meta={meta} {...input} />}
                    </Field>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
              </ModalLayout.Content>
            </ModalLayout.Row>
            <ModalLayout.Row>
              <ModalLayout.Sidebar>
                <ModalLayout.RowTitle>Роли</ModalLayout.RowTitle>
                <ModalLayout.Description>
                  Функции к которым будет иметь доступ сотрудник
                </ModalLayout.Description>
              </ModalLayout.Sidebar>
              <ModalLayout.Content>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Field name="roles">
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          isMulti
                          label="Роль"
                          route={companySources}
                          routeName="role"
                          meta={meta}
                          withExceptOption
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option: OptionWithCheckbox }}
                          {...input}
                        />
                      )}
                    </Field>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
              </ModalLayout.Content>
            </ModalLayout.Row>
          </div>
        )}
        {!isInvite && (
          <Field name="roles">
            {({ input, meta }) => (
              <InputWithSelect
                isAsync
                isMulti
                label="Роль"
                route={companySources}
                routeName="role"
                meta={meta}
                {...input}
              />
            )}
          </Field>
        )}

        <Modal.Footer>{children}</Modal.Footer>
      </form>
    )}
  />
);
