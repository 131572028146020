import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';
import flow from 'lodash/flow';
import { Router, withRouter } from 'next/router';
import { createContext, removeContext, contextShouldClose } from 'entities/call-context';
import Styled from './styles';
import stopPropagation from 'shared/helpers/stopPropagation';
import CallContextForm from 'components/CallContextForm';
import Tooltip from 'components/Tooltip';
import HistoryIcon from '../../../static/icons/history.svg';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { createInvoice, loadInvoices } from 'entities/invoices';
import routes from 'shared/helpers/routes';

interface CallContextProps {
  callContext?: any;
  contextShouldClose: () => void;
  shouldClear: boolean;
  shouldOpen: boolean;
  createContext: (data: any) => any;
  removeContext: () => void;
  createInvoice: (data: any) => any;
  loadInvoices: (query: any) => any;
  orderContext?: any;
  router: Router;
}

interface CallContextState {
  active: boolean;
  isOpen: boolean;
  lastContext: any;
}

class CallContext extends Component<CallContextProps, CallContextState> {
  constructor(props) {
    super(props);

    const { name, phone } = props.callContext;

    this.state = {
      active: name && phone,
      isOpen: false,
      lastContext: null,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.shouldOpen && !state.isOpen) {
      return {
        isOpen: true,
      };
    }

    if (!isEmpty(props.callContext) && !state.lastContext) {
      return {
        active: true,
        lastContext: {
          ...props.callContext,
        },
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldOpen) {
      this.props.contextShouldClose();
    }

    if (this.state.isOpen) {
      document.addEventListener('keyup', this.handleKeyUp);
    } else {
      document.removeEventListener('keyup', this.handleKeyUp);
    }

    if (this.props.shouldClear) {
      this.removeContext();
    }
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  handleKeyUp = ({ keyCode }) => {
    if (keyCode === 27) {
      this.setState({
        isOpen: false,
      });
    }
  };

  setLastContext = () => {
    const { name, phone } = this.state.lastContext;

    this.createContext({ name, phone, submit_action: SUBMIT_ACTIONS.CONTINUE });
  };

  toggleForm = () => {
    if (!this.props.orderContext) {
      this.setState(({ isOpen }) => ({
        isOpen: !isOpen,
      }));
    }
  };

  createContext = (data) => {
    const { pathname, query } = this.props.router;
    const { name, phone, submit_action: submitAction } = data;

    if (submitAction === SUBMIT_ACTIONS.CONTINUE) {
      this.setState(
        {
          active: true,
          lastContext: {
            name,
            phone,
          },
        },
        () => {
          this.close();
          this.props.createContext({ name, phone });
        },
      );
    }

    if (submitAction === SUBMIT_ACTIONS.CREATE) {
      this.props.createInvoice({ name, phone }).then((response) => {
        if (response.error) {
          return NotificationManager.error('Ошибка');
        }

        this.close();

        if (pathname === routes.invoices) {
          this.props.loadInvoices(query);
        }

        return NotificationManager.success('Заявка успешно создана');
      });
    }
  };

  removeContext = () => {
    this.setState(
      {
        active: false,
        isOpen: false,
      },
      () => {
        this.props.removeContext();
      },
    );
  };

  close = () => {
    this.props.contextShouldClose();
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { active, isOpen, lastContext } = this.state;
    const { name, phone } = this.props.callContext;
    const { orderContext } = this.props;

    return (
      <Styled.Container
        disabled={!!orderContext}
        active={active}
        isOpen={isOpen}
        onClick={this.toggleForm}
        data-selenium="create-context"
      >
        {active ? (
          <Styled.Info>
            <Styled.InfoInner>
              <Styled.Name>{name}</Styled.Name>
              <Styled.Phone>{phone}</Styled.Phone>
            </Styled.InfoInner>
            <Styled.Arrow />
          </Styled.Info>
        ) : (
          <img src="/static/CallContext/plus.svg" alt="Создание заявки" />
        )}
        {active
          ? isOpen && (
              <>
                <Styled.Dropdown onClick={stopPropagation}>
                  <Styled.DropdownOption danger onClick={this.removeContext}>
                    Отмена
                  </Styled.DropdownOption>
                </Styled.Dropdown>
                <Styled.MobileInfo onClick={this.removeContext}>
                  {name}
                  <Styled.MobilePhone>{phone}</Styled.MobilePhone>
                </Styled.MobileInfo>
              </>
            )
          : isOpen && (
              <>
                <Styled.Overlay />
                <Styled.FormWrapper onClick={stopPropagation}>
                  <CallContextForm onSubmit={this.createContext} isOpen={isOpen} close={this.close} />
                  {lastContext && (
                    <Styled.Footer onClick={this.setLastContext}>
                      <Styled.HistoryIcon>
                        <HistoryIcon />
                      </Styled.HistoryIcon>
                      <Tooltip text={lastContext.name}>
                        <Styled.FooterTitle>{lastContext.name}</Styled.FooterTitle>
                      </Tooltip>
                      <Styled.FooterText>{lastContext.phone}</Styled.FooterText>
                    </Styled.Footer>
                  )}
                </Styled.FormWrapper>
              </>
            )}
      </Styled.Container>
    );
  }
}

const mapStateToProps = (state) => ({
  callContext: state.callContext.data,
  shouldOpen: state.callContext.shouldOpen,
  shouldClear: state.callContext.shouldClear,
  orderContext: state.orderContext.data,
});

const mapDispatchToProps = {
  createContext,
  removeContext,
  contextShouldClose,
  createInvoice,
  loadInvoices,
};

const withHoC = flow([onClickOutside, withRouter, connect(mapStateToProps, mapDispatchToProps)]);

export default withHoC(CallContext);
