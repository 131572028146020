import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
    .modal__wrapper {
      box-sizing: border-box;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__body {
        padding: 0;
      }
    }
  `,

  ScrollContainer: styled.div`
    height: 100%;
    overflow-y: auto;
  `,

  Title: styled.div`
    display: flex;
    align-items: center;
    margin: 32px 0 0;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
      margin: 16px 0 0;
    }
  `,

  ProgressBar: styled.div`
    width: 290px;
  `,

  Wrapper: styled.div`
    padding: 0 28px 28px;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: auto;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 0 16px 16px;
    }

    .rule {
      display: flex;
      margin-top: 16px;
    }

    .combinators label {
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      text-overflow: initial;
      color: #000000;
    }

    .ruleGroup-addRule,
    .ruleGroup-addGroup {
      display: inline-block;
      vertical-align: middle;
      padding: 11px 12px;
      margin-right: 24px;
      font-size: 14px;
      line-height: 18px;
      color: #000;

      svg {
        margin-right: 12px;
        color: #000;
      }

      @media (max-width: ${BREAKPOINTS.MD}) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;

        & svg {
          margin: 0;
          flex-shrink: 0;
        }
      }
    }

    .rule-remove,
    .ruleGroup-remove {
      display: inline-block;
      vertical-align: middle;
    }

    .ruleGroup {
      margin-top: 28px;
      padding-left: 16px;
      border-left: 1px solid #2dc472;

      @media (max-width: ${BREAKPOINTS.MD}) {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }

    .rule,
    .ruleGroup .ruleGroup {
      margin-left: 16px;

      @media (max-width: ${BREAKPOINTS.MD}) {
        margin-left: 0;
        flex-wrap: wrap;
        width: 100%;

        & > div:last-of-type {
          max-width: calc(100% - 40px);
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  `,

  ButtonWrapper: styled.div`
    display: flex;
    margin: auto auto 0;
    padding-top: 32px;

    button {
      margin: 0 8px;
    }
  `,

  Description: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,

  Select: styled.div`
    margin-left: 16px;
  `,

  AddButton: styled.div`
    margin-top: 32px;
  `,

  Row: styled.div`
    display: flex;
    width: 100%;
    margin: 0 -4px;
  `,

  Column: styled.div`
    width: 50%;
    margin: 0 4px;
  `,

  ValueContainer: styled.div`
    box-sizing: border-box;
    width: 100%;
    max-width: 248px;
    margin-right: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-width: calc(100% - 40px);
      margin-right: 0;
    }
  `,

  Currency: styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
      align-items: flex-start;
    }
  `,

  CurrencySelect: styled.div`
    display: flex;
    align-items: center;
    margin-right: 40px;
    white-space: nowrap;

    select {
      margin-left: 16px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-bottom: 16px;
      margin-right: 0;
    }
  `,

  CurrencyCheckbox: styled.div`
    display: flex;
    align-items: center;
  `,
};
