import React from 'react';
import Styled from './styles';
import Error from '../Error';
import Label from '../LabelNew';

interface TextAreaProps {
  label?: string;
  meta: {
    touched?: boolean;
    error?: string;
  };
  maxValue?: number;
  value?: string;
  disabled?: boolean;
  onKeyDown?: (event: any) => void;
  placeholder?: string;
  autoFocus?: boolean;
  height?: string;
}

const Textarea: React.FC<TextAreaProps> = ({ height, label, meta, maxValue, ...rest }) => (
  <Styled.Container>
    {label && (
      <Label value={rest.value} meta={meta}>
        {label}
      </Label>
    )}
    <Styled.Field height={height} {...rest} meta={meta} maxLength={maxValue} />

    {maxValue && (
      <Styled.Count>
        {rest.value.length} / {maxValue}
      </Styled.Count>
    )}
    <Styled.Error shifted={Boolean(maxValue)}>
      <Error meta={meta} />
    </Styled.Error>
  </Styled.Container>
);

export default Textarea;
