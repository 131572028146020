import React, { useState } from 'react';
import omit from 'lodash/omit';
import Styled from './styles';
import Checkbox from '../Checkbox';
import preventClick from 'shared/helpers/preventClick';
import SELECT_ACTIONS from 'shared/constants/SELECT_ACTIONS';

interface Props {
  data: any;
  withoutIcon: boolean;
  setValue: (value: any) => void;
  isFocused: boolean;
  isSelected: boolean;
  hasValue: boolean;
  innerProps: any;
  selectProps: any;
  options: any[];
  value: number | string;
  children: any;
}

const OptionWithCheckboxAndIcon: React.FC<Props> = (props) => {
  const [allSelected, selectAll] = useState(false);
  const { children, innerProps, isSelected, isFocused, options, value, setValue, hasValue, data } = props;
  const handleOptionClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (value === SELECT_ACTIONS.SELECT_ALL) {
      if (hasValue) {
        setValue(null);
        selectAll(false);
      } else {
        setValue(options.filter((item) => item.value !== value));
        selectAll(true);
      }
    } else {
      innerProps.onClick();
    }
  };

  return (
    <Styled.Option
      isFocused={isFocused}
      onClick={handleOptionClick}
      onTouchEnd={preventClick}
      {...omit(innerProps, ['onMouseMove', 'onMouseOver', 'onClick'])}
      data-selenium={children}
    >
      <Styled.OptionCheckbox>
        <Checkbox
          plain
          isMulti={value === SELECT_ACTIONS.SELECT_ALL && hasValue && !allSelected}
          checked={(value === SELECT_ACTIONS.SELECT_ALL && allSelected) || isSelected}
          readOnly
        />
      </Styled.OptionCheckbox>
      {children}
      {value !== SELECT_ACTIONS.SELECT_ALL && !data.withoutIcon && (
        <Styled.LanguageWrapper>
          <Styled.Language src={`/static/icons/locales/${value}.svg`} alt={`${value}`} />
        </Styled.LanguageWrapper>
      )}
    </Styled.Option>
  );
};

export default OptionWithCheckboxAndIcon;
