import SchemePoolModal from 'components/Pages/Pool/SchemePoolModal';
import { connect } from 'react-redux';
import { createOrder, removeContext, addTicketsFromPool } from 'entities/order';
import { selectTicketFromScheme } from 'entities/pool/scheme';
import { selectedTicketsIdsSelector } from '../../../../selectors/announcements';

const mapStateToProps = (state) => ({
  event: state.event.data,
  callContext: state.callContext.data,
  orderContext: state.orderContext.data,
  selectedTicketsIds: selectedTicketsIdsSelector(state),
});
const mapDispatchToProps = {
  createOrder,
  removeContext,
  addTicketsFromPool,
  selectTicketFromScheme,
};
export default connect(mapStateToProps, mapDispatchToProps)(SchemePoolModal);
