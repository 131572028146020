import React, { useState } from 'react';
import get from 'lodash/get';
import { useDebouncedCallback } from 'use-debounce';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import playgroundsSources from 'shared/sources/playgrounds';
import { renderWidgetPlaygrounds } from 'shared/helpers/optionsRenderers';
import { InputWithAutocomplete, Input } from '../../../../FormControls';
import { DataContext } from '../DataContext';
import WidgetStyled from '../../WidgetForm/styles';
import { createSeatsValidation, seatValidation } from 'shared/helpers/validations';
import { StyledError } from '../../../../FormControls/Error';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';
import { seatsStringToArray, convertSeats } from 'shared/helpers/seats';
import Tooltip from '../../../../Tooltip';
import { isEmpty } from 'lodash';

let seats: any;

const setSeatsIds = (_: any, seatsIds: any) => {
  seats = seatsIds;
};

interface SeatProps {
  sector: any;
  row: any;
  field: string;
  title: string;
  disabled: boolean;
  onChangeSector: (data: any) => void;
  onChangeRow: (data: any) => void;
  onChangeSeat: (data: any) => void;
  value: any;
  parentId: number | string;
}

const ValueEditorSeat: React.FC<SeatProps> = (props) => {
  let asyncSeatsValidation: any = null;
  const { sector, row, field, title, disabled, onChangeSector, onChangeRow, onChangeSeat, value, parentId } =
    props;
  const [error, setError] = useState(null);
  const [seatsValue, setSeats] = useState(value ? convertSeats(value.map((seat: any) => seat.name)) : null);

  const validateAndSetSeats = useDebouncedCallback((values) => {
    asyncSeatsValidation(values).then((errors: any) => {
      const seatError = get(errors, 'seat');

      if (seatError) {
        setError(seatError);
        Emmiter.emit(EVENTS.WIDGET_QUERY_ERROR, seatError);
        return;
      }

      const seatsArray = seatsStringToArray(values.seat).map((item) => ({
        value: seats[item],
        name: item,
      }));

      onChangeSeat(seatsArray);
    });
  }, 500);

  const handleChange = ({ target: { value: inputValue } }) => {
    const seatsFormatError = seatValidation(inputValue);

    setSeats(inputValue);

    if (seatsFormatError) {
      setError(seatsFormatError);
      Emmiter.emit(EVENTS.WIDGET_QUERY_ERROR, seatsFormatError);
      return;
    }

    if (error) {
      setError(null);
      Emmiter.emit(EVENTS.WIDGET_QUERY_ERROR);
    }

    validateAndSetSeats({
      seat: inputValue,
      row: {
        value: row.value,
      },
    });
  };

  return (
    <DataContext.Consumer>
      {(context: any) => {
        const event = context && context.event;
        const markup = context?.markup?.find((markupItem: any) => parentId === markupItem?.id);

        let markupHallLayout;
        if (markup) {
          markupHallLayout = markup?.hallLayouts ? markup?.hallLayouts?.value : markup?.query?.hall_layout_id;
        }

        let filtersHallLayout;
        if (context?.placesFilter) {
          filtersHallLayout =
            context.placesFilter?.hallLayout || context.placesFilter?.hallLayout?.select === 'empty'
              ? context.placesFilter?.hallLayout?.value
              : context?.placesFilter?.hall_layout_id;
        }

        const hallLayoutId = event
          ? (event.hall_layout && event.hall_layout.id) || event.hall_layout_id
          : markupHallLayout || filtersHallLayout;

        asyncSeatsValidation = createSeatsValidation({
          event: {
            ...event,
            hall_layout_id: hallLayoutId,
          },
          setSeatsIds,
        });
        const query = {
          hall_layout: hallLayoutId,
          fragment_type: FRAGMENT_TYPES.SECTOR,
        };
        const rowQuery = {
          hall_layout: hallLayoutId,
          fragment_type: FRAGMENT_TYPES.ROW,
          parent: sector && sector.value,
        };

        return (
          <WidgetStyled.Row>
            <WidgetStyled.Column>
              <Tooltip text={sector && sector.name}>
                <div>
                  <InputWithAutocomplete
                    fluid
                    defaultOptions={!!markupHallLayout}
                    disablePaginate
                    withoutDefaultValue
                    key={markupHallLayout || `${field}_sector`}
                    value={sector}
                    title={title}
                    query={query}
                    isMulti={false}
                    isDisabled={!hallLayoutId}
                    route={playgroundsSources}
                    routeName="root"
                    onChange={onChangeSector}
                    optionsRenderer={renderWidgetPlaygrounds}
                  />
                </div>
              </Tooltip>
            </WidgetStyled.Column>
            <WidgetStyled.Column>
              <InputWithAutocomplete
                fluid
                isMulti={false}
                defaultOptions
                disablePaginate
                withoutDefaultValue
                key={`${field}_row`}
                value={row}
                title={title}
                query={rowQuery}
                isDisabled={!sector}
                route={sector && playgroundsSources}
                routeName="root"
                onChange={onChangeRow}
                optionsRenderer={renderWidgetPlaygrounds}
              />
            </WidgetStyled.Column>
            <WidgetStyled.Column>
              <Input
                label="Места"
                readOnly={!row}
                disabled={!row}
                onChange={handleChange}
                value={seatsValue}
              />
              {error && <StyledError>{error}</StyledError>}
            </WidgetStyled.Column>
          </WidgetStyled.Row>
        );
      }}
    </DataContext.Consumer>
  );
};

export default ValueEditorSeat;
