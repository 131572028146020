import React from 'react';
import WidgetFirstStep from '../FirstStep';
import WidgetSecondStep from '../SecondStep/SecondStepContainer';
import ThirdStepContainer from '../ThirdStep/ThirdStepContainer';
import StepModal from '../../../../Modals/StepModal';
import ProgressBar from '../../../../ProgressBar';
import Modal from '../../../../Modal';
import { ModalFunctions } from '../../../../../../interfaces/modal';
import Styled from './styles';

const STEPS = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
};

const STEP_COMPONENT = {
  [STEPS.FIRST]: WidgetFirstStep,
  [STEPS.SECOND]: WidgetSecondStep,
  [STEPS.THIRD]: ThirdStepContainer,
};

const STEP_COMPONENTS_PROPS = {
  [STEPS.THIRD]: {
    withCurrency: true,
  },
};

interface WidgetFormProps {
  isMobile: boolean;
  closeModal: ModalFunctions['closeModal'];
  isEdit: boolean;
  data: any;
  isCloning: boolean;
  isPreview: boolean;
  updateState: (data: any) => any;
  onSubmit: (data: any) => any;
  step: number;
  goBack: () => void;
  selectStep: (step: number) => void;
  stepWords: string[];
  onChangeLng: (lng: string) => void;
  isChangingData: boolean;
}

const WidgetForm: React.FC<WidgetFormProps> = (props) => {
  const {
    isEdit,
    isPreview,
    closeModal,
    isMobile,
    onSubmit,
    step,
    data,
    isCloning,
    goBack,
    selectStep,
    updateState,
    stepWords,
    onChangeLng,
    isChangingData,
  } = props;
  const StepComponent = STEP_COMPONENT[step];
  const componentProps: any = STEP_COMPONENTS_PROPS[step] || {};

  const getTitle = () => {
    let prefix = 'Создание';

    if (isEdit) {
      prefix = 'Редактирование';
    }

    if (isCloning) {
      prefix = 'Клонирование';
    }

    return `${prefix} Виджета`;
  };

  return (
    <>
      <Styled.GlobalStyles />
      <StepModal.Header>
        <StepModal.Title>{getTitle()}</StepModal.Title>
        <StepModal.ProgressBar>
          <ProgressBar steps={stepWords} currentStep={step} createMode={!isEdit} onSelect={selectStep} />
        </StepModal.ProgressBar>
        <div />
      </StepModal.Header>
      <Modal.FullSizeContent>
        <StepComponent
          onSubmit={onSubmit}
          data={data}
          isEdit={isEdit}
          isPreview={isPreview}
          goBack={goBack}
          closeModal={closeModal}
          isMobile={isMobile}
          updateState={updateState}
          onChangeLng={onChangeLng}
          isChangingData={isChangingData}
          {...componentProps}
        />
      </Modal.FullSizeContent>
    </>
  );
};

export default WidgetForm;
