import values from 'lodash/values';
import REFERENCE_TABS from './REFERENCE_TABS';

export const TYPES = {
  COUNTRY_CREATE: 'country-create',
  COUNTRY_UPDATE: 'country-update',
  LOCATION_UPDATE: 'location-update',
  LOCATION_CREATE: 'location-create',
  HALL_V2: 'hallv2',
  EVENT_CATEGORY: 'event-category',
  EXPANSION: 'expansion',
  TAG: 'TAG',
  PERSONS: 'PERSONS',
  CURRENCY: 'CURRENCY',
  CURRENCY_HISTORY: 'CURRENCY_HISTORY',
  SUBDOMAIN_UPDATE: 'SUBDOMAIN_UPDATE',
  QnA: 'QnA',
};

export const TYPES_ARRAY = values(TYPES);

export const LIST_MODAL_TYPES = {
  [REFERENCE_TABS.COUNTRIES]: TYPES.COUNTRY_CREATE,
  [REFERENCE_TABS.EVENT_CATEGORIES]: TYPES.EVENT_CATEGORY,
  [REFERENCE_TABS.EXPANSIONS]: TYPES.EXPANSION,
  [REFERENCE_TABS.PERSONS]: TYPES.PERSONS,
  [REFERENCE_TABS.CURRENCY]: TYPES.CURRENCY,
  [REFERENCE_TABS.SUBDOMAINS]: TYPES.SUBDOMAINS,
  [REFERENCE_TABS.QnA]: TYPES.QnA,
};

export const LIST_MODAL_TYPES_V2 = {
  [REFERENCE_TABS.COUNTRIES]: TYPES.COUNTRY_CREATE,
  [REFERENCE_TABS.HALLS]: TYPES.HALL_V2,
  [REFERENCE_TABS.EVENT_CATEGORIES]: TYPES.EVENT_CATEGORY,
  [REFERENCE_TABS.EXPANSIONS]: TYPES.EXPANSION,
  [REFERENCE_TABS.PERSONS]: TYPES.PERSONS,
  [REFERENCE_TABS.CURRENCY]: TYPES.CURRENCY,
  [REFERENCE_TABS.SUBDOMAINS]: TYPES.SUBDOMAINS,
  [REFERENCE_TABS.QnA]: TYPES.QnA,
};
