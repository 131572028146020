import config from 'config/config';
import { createEffect } from 'effector';
import api from 'services/api';

const route = `${config.COMMON_API_URL}/admin/v1/events`;

interface CreateDate {
  finished_at: string;
  labels: number[];
  started_at: string;
  status_id: string | number;
}
interface EditDate {
  finished_at: string;
  labels: number[];
  started_at: string;
  status_id: string | undefined;
  id: number;
}
interface DeleteDate {
  eventID: number;
  dateID: number;
}

export const createDatesFx = createEffect(async ({ id, dates }: { id: number; dates: CreateDate[] }) => {
  const requests = dates.map(async (date) => {
    const response = await api.post(`${route}/${id}/dates`, date);

    return response;
  });

  await Promise.all(requests);
});

export const editDateFx = createEffect(async ({ id, dates }: { id: number; dates: EditDate[] }) => {
  const requests = dates.map(async (date) => {
    const response = await api.patch(`${route}/${id}/dates/${date?.id}`, date);

    return response;
  });

  await Promise.all(requests);
});

export const deleteDateFx = createEffect(async ({ eventID, dateID }: DeleteDate) => {
  const response = await api.delete(`${route}/${eventID}/dates/${dateID}`);

  return response.data;
});
