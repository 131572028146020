import React from 'react';
import Dropdown from '../Dropdown';
import Styled from './styles';
import { ACTIONS, ACTIONS_LABELS } from 'shared/constants/GLASS_TICKETS_MODAL';

interface Props {
  action?: string;
  disabled?: boolean;
  withDuplicates?: boolean;
  onClick: (key: string | number, data: any, event: any) => void;
  selectedDuplicateTicket?: boolean;
}

const GlassTicketsModalDropdown: React.FC<Props> = ({
  onClick,
  action,
  disabled,
  withDuplicates,
  selectedDuplicateTicket,
}) => {
  const actionIsUnset = action === ACTIONS.UNSET;

  const ACTIONS_OPTIONS = [
    {
      value: ACTIONS.SALE,
      label: 'Выставить на продажу',
      disabled: withDuplicates || selectedDuplicateTicket,
    },
    { value: ACTIONS.REFUND, label: 'Вернуть поставщику' },
    { value: ACTIONS.DELETE, label: 'Удалить с биржи' },
  ];

  return (
    <Styled.Dropdown disabled={disabled}>
      <Dropdown
        withIndicator
        onClick={onClick}
        placement="bottomRight"
        options={ACTIONS_OPTIONS}
        transparentBlue={actionIsUnset}
        disabled={disabled}
      >
        <Styled.DropdownLabel transparentBlue={actionIsUnset}>
          {actionIsUnset ? 'Выбрать действие' : ACTIONS_LABELS[action]}
        </Styled.DropdownLabel>
      </Dropdown>
    </Styled.Dropdown>
  );
};

export default GlassTicketsModalDropdown;
