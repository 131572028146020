import React from 'react';
import Styled from './styles';
import ArrowIcon from '../../../../static/icons/arrow.svg';

interface Props {
  isFocused: boolean;
}

const InputWithSelectDropdownIndicator: React.FC<Props> = ({ isFocused }) => (
  <Styled.DropdownIndicator isFocused={isFocused}>
    <ArrowIcon fill={isFocused ? '#2F80ED' : '#999'} />
  </Styled.DropdownIndicator>
);

export default InputWithSelectDropdownIndicator;
