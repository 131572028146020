import styled from 'styled-components';

export default {
  SpinnerContainer: styled.div`
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
