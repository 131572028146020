import { NotificationManager } from 'react-notifications';
import head from 'lodash/head';
import { each, get, isArray } from 'lodash';
import updateObject from 'lodash/update';
import api from 'shared/services/api';
import Emmiter from 'shared/services/Emmiter';
import { createErrorMessage, saveTranslationData } from 'shared/helpers/form';
import { USE_LIST_RELOAD } from './useList';
import PAGES from 'shared/constants/PAGES';

interface ListParams {
  source: {
    detail: (id: number) => string;
    root: string;
    bulkPublish?: string;
  };
  mappedCreateData?: (data: any) => Object;
  mappedUpdateData?: (data: any) => Object;
  query?: object;
  page?: string;
}

const formatErrorData = (errorData) => {
  updateObject(errorData, 'error.response.data', (error) => {
    let errors = {};
    each(error, (err) => {
      errors = { ...errors, ...err };
    });

    return {
      error: {
        ru: errors,
      },
    };
  });
  return errorData;
};

const useLandings = ({ source, mappedCreateData, mappedUpdateData, query = {}, page }: ListParams) => {
  const create = async (submitData) => {
    try {
      const translationData = await saveTranslationData({
        data: mappedCreateData(submitData),
        create: source.root,
        update: source.detail,
        query,
      });

      if (translationData.error) {
        const errors = formatErrorData(translationData);
        const currentError = get(errors, 'error.response.data.error.ru.0');

        if (currentError === 'Invalid input.' && page === PAGES.LANDING_TAGS) {
          NotificationManager.error('Данный тег уже есть в системе.', 'Ошибка');
        } else {
          NotificationManager.error('Ошибка');
        }

        return errors;
      }

      return translationData;
    } catch (error) {
      const errorMessage = createErrorMessage(error);

      NotificationManager.error('Ошибка', errorMessage);

      return { error };
    }
  };

  const update = async (submitData) =>
    saveTranslationData({
      data: mappedUpdateData(submitData),
      create: source.root,
      update: source.detail,
      query,
    }).then((translationData) => {
      if (translationData.error) {
        NotificationManager.error('Ошибка');

        return formatErrorData(translationData);
      }

      return translationData;
    });

  const remove = async ({ id }, params = {}) => {
    try {
      const response = await api.delete(source.detail(id), { params });

      Emmiter.emit(USE_LIST_RELOAD);

      return response;
    } catch (error) {
      let errorMessage = [];
      if (
        isArray(error.response.data.error) &&
        error.response.data.error[0] === 'You can not delete the object.' &&
        page === PAGES.LANDING_TEMPLATE
      ) {
        errorMessage = ['Удаление невозможно. Данный шаблон используется.'];
      } else {
        errorMessage = Object.keys(head(error.response.data.error)).map((err) => {
          if (err === 'Cannot delete engaged personality.') {
            return 'Нельзя удалить связанные персоналии';
          }

          return err;
        });
      }

      return {
        error: {
          response: {
            data: {
              error: errorMessage,
            },
          },
        },
      };
    }
  };

  return { remove, update, create };
};

export default useLandings;
