import { connect } from 'react-redux';
import { editParsersVenue } from 'entities/parsers/venues';
import { editParsersCategory } from 'entities/parsers/categoires';
import ParsersExternalldModal from 'components/Modals/ParsersExternalldModal';

const mapDispatchToProps = {
  editParsersVenue,
  editParsersCategory,
};

export default connect(null, mapDispatchToProps)(ParsersExternalldModal);
