import React from 'react';
import Form from '../../../Form';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';

const ITEMS = Array.from({ length: 11 }, (v, i) => i + 1);

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const GalleryStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Выберите дизайн Галереи</Styled.Title>
    </Styled.Header>
    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.ListWrapper>
              <Styled.List grid>
                {ITEMS.map((i) => (
                  <Styled.Item grid width="50%" key={i} data-selenium={`variant-${i}`}>
                    <Variant name="ui_config_gallery" value={i}>
                      <img src={`/static/constructor/gallery/${i}.jpg`} alt={`${i} вариант`} />
                    </Variant>
                  </Styled.Item>
                ))}
              </Styled.List>
            </Styled.ListWrapper>
            <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
          </form>
        )}
      />
    </Styled.Content>
  </Styled.Container>
);

export default GalleryStep;
