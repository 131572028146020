import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import BREAKPOINTS from '../constants/BREAKPOINTS';

export const useDeviceType = () => {
  const [isClient, setIsClient] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINTS.XL_NUMBER });
  const isTablet = useMediaQuery({ minWidth: BREAKPOINTS.MD_NUMBER, maxWidth: BREAKPOINTS.XL_NUMBER - 1 });
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.MD_NUMBER - 1 });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
    }
  }, []);

  return {
    isDesktop: isClient ? isDesktop : true,
    isTablet: isClient ? isTablet : false,
    isMobile: isClient ? isMobile : false,
  };
};
