import 'cropperjs/dist/cropper.css';
import React, { useState, useEffect } from 'react';
import Cropper from 'cropperjs';
import uuid from 'uuid/v4';
import mime from 'mime-types';
import StepModal from '../../../Modals/StepModal';
import Modal from '../../../Modal';
import ProgressBar from '../../../ProgressBar';
import Button from '../../../Button';
import KEY_CODES from 'shared/constants/KEY_CODES';
import UploadSources from 'shared/sources/upload';
import api from 'shared/services/api';
import Styled from './styles';
import FileCropperModalActions from './FileCropperModalActions';

let isMove = false;
const blobs = [];

interface ModalProps {
  close: () => any;
  image: any;
  onChange: (values: any) => any;
  value: any;
  stepsEnum: any;
  ratio: any;
  words: string[];
  sizes: string[];
  imageSizes: any[];
}

const FileCropperModal = ({
  image,
  close,
  onChange,
  value,
  stepsEnum,
  ratio,
  words,
  sizes,
  imageSizes,
}: ModalProps) => {
  const [cropper, setCropper] = useState(null);
  const [isLoading, toggleLoading] = useState(false);
  const [step, setStep] = useState<number>(stepsEnum.FIRST);
  const STEPS_COUNT = words.length;
  blobs[0] = image.file;

  const handleKeydown = (event) => {
    if (event.keyCode === KEY_CODES.SPACEBAR && !isMove) {
      cropper.setDragMode('move');
      isMove = true;
    }
  };

  const handleKeyup = (event) => {
    if (event.keyCode === KEY_CODES.SPACEBAR && isMove) {
      cropper.setDragMode('crop');
      isMove = false;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyup);
    };
  }, []);

  useEffect(() => {
    const imageEl: any = document.getElementById('image');

    setCropper(
      new Cropper(imageEl, {
        aspectRatio: ratio[step],
        viewMode: 2,
      }),
    );
  }, []);

  useEffect(() => {
    if (cropper) {
      cropper.setAspectRatio(ratio[step]);
    }
  }, [step]);

  const goBack = () => {
    setStep(step - 1);
  };

  const changeStep = () => {
    if (step === STEPS_COUNT - 1) {
      toggleLoading(true);
      return Promise.all(
        blobs.map((blob) => {
          if (!blob) return null;

          const formData = new FormData();
          formData.append('image', blob, `${uuid()}.${mime.extension(blob.type)}`);

          return api.post(UploadSources.root, formData).then((response) => response.data);
        }),
      )
        .then((results) => {
          const [src, ...rest] = results;

          toggleLoading(false);

          const values = {
            src: src && src.link ? src.link : value.src,
            ...sizes.reduce((acc, size, index) => {
              acc[size] = rest[index].link;
              acc[`${size}_webp`] = rest[index].link_webp;

              return acc;
            }, {}),
          };

          onChange(values);
          close();
        })
        .catch((err) => new Error(err));
    }

    return setStep(step + 1);
  };

  const saveCroppedImage = () => {
    cropper
      .getCroppedCanvas({
        imageSmoothingQuality: 'high',
        ...(imageSizes && {
          width: imageSizes[step].width,
          height: imageSizes[step].height,
        }),
      })
      .toBlob((blob) => {
        blobs[step + 1] = blob;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        changeStep();
      }, image.type);
  };

  return (
    <>
      <Styled.Global />
      <Styled.Overlay />
      <Modal fullSize>
        <Modal.Body fullSize className="cropper__body">
          <Modal.Close className="modal__close" onClick={close} />
          <Styled.Container>
            <StepModal.Header>
              Настройка изображений
              <StepModal.ProgressBar>
                <ProgressBar steps={words} currentStep={step} />
              </StepModal.ProgressBar>
            </StepModal.Header>
            <Styled.Cropper>
              <img src={image.result} alt="" id="image" />
            </Styled.Cropper>
            <Styled.Buttons>
              {step !== 0 && (
                <Button transparent type="button" onClick={goBack}>
                  Назад
                </Button>
              )}
              {cropper && <FileCropperModalActions cropper={cropper} />}
              <Styled.SubmitButtons>
                <Button type="button" onClick={saveCroppedImage} disabled={isLoading}>
                  {step === STEPS_COUNT - 1 ? 'Сохранить' : 'Далее'}
                </Button>
              </Styled.SubmitButtons>
            </Styled.Buttons>
          </Styled.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileCropperModal;
