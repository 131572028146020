import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContext } from 'entities/call-context';
import { openModal } from 'entities/modal';
import { closeScheme, closeSchemeContext } from 'entities/pool/scheme';
import { IBasicEvent } from 'shared/api/events';
import MODALS from 'shared/constants/MODALS';
import preventClick from 'shared/helpers/preventClick';
import CallContextForm from '../../../CallContextForm';
import Styled from './styles';

interface ContextProps {
  event: IBasicEvent;
}

const SchemeContext: React.FC<ContextProps> = ({ event }) => {
  const contextIsVisible = useSelector((state) => state.poolScheme.contextIsVisible);
  if (!contextIsVisible) return null;
  const dispatch = useDispatch();

  const handleSubmitContext = (data: any) => {
    const { name, phone } = data;

    dispatch(createContext({ name, phone }));
    dispatch(closeSchemeContext());
    dispatch(closeScheme());
    dispatch(
      openModal(MODALS.BOOK_TICKETS_MODAL, {
        event,
      }),
    );
  };

  const handleCloseSchemeContext = () => dispatch(closeSchemeContext());

  return (
    <Styled.CallContextOverlay onClick={handleCloseSchemeContext}>
      <Styled.CallContextFormWrapper onClick={preventClick}>
        <CallContextForm
          isOpen={contextIsVisible}
          close={handleCloseSchemeContext}
          onSubmit={handleSubmitContext}
        />
      </Styled.CallContextFormWrapper>
    </Styled.CallContextOverlay>
  );
};

export default SchemeContext;
