import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

interface DescriptionProps {
  withLabel?: boolean;
}

interface PreviewContainerProps {
  withPreview?: boolean;
  bg?: string;
  width?: number;
  height?: number;
  style?: {
    opacity: number | string;
  };
}

interface LinkProps {
  maxWidth?: number;
}

const Button = styled.button`
  display: none;
  position: absolute;
  top: -2px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  outline: none;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: #ffffff;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

export default {
  Container: styled.div`
    display: flex;
  `,

  Row: styled.div`
    display: flex;
  `,

  PreviewWrapper: styled.div`
    width: 100%;
    flex-grow: 0;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 156px;
      flex-shrink: 0;
    }
  `,

  Description: styled.div<DescriptionProps>`
    flex-grow: 0;
    width: 100%;
    padding-left: 32px;
    margin-top: ${({ withLabel }) => withLabel && '25px'};
    margin-bottom: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding-left: 16px;
      margin-top: ${({ withLabel }) => withLabel && '20px'};
      margin-bottom: 0;
    }
  `,

  Preview: styled.label`
    width: 80px;
    flex-shrink: 0;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  `,

  Input: styled.input<any>`
    opacity: 0;
    position: absolute;
    top: 0;
    left: -99999px;

    &:focus + div {
      border-color: #2f80ed;
    }
  `,

  PreviewContainer: styled.div.attrs((props: PreviewContainerProps) => ({
    style:
      props.withPreview && props.bg
        ? {
            backgroundImage: `url(${props.bg})`,
            textIndent: '-9999999px',
          }
        : null,
  }))`
    position: relative;
    width: ${(props: PreviewContainerProps) => (props.width ? `${props.width}px` : null)};
    height: ${(props: PreviewContainerProps) => (props.height ? props.height : 40)}px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2f80ed;
    border: 1px solid rgba(23, 2, 0, 0.12);
    border-style: dashed;
    box-sizing: border-box;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    background-repeat: 50% 50%;
    background-size: cover;

    &:hover {
      border-color: #2f80ed;
    }

    & > img {
      margin-right: 8px;
    }

    ${(props: PreviewContainerProps) =>
      props.withPreview &&
      css`
        height: ${props.height ? props.height : 101}px;
      `}

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 0 12px;
      font-size: 16px;
      text-align: center;
    }
  `,

  LinkWrapper: styled.div`
    display: flex;
  `,

  Link: styled.div<LinkProps>`
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : 350)}px;
    margin-top: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media ${MEDIA_QUERIES.MOBILE} {
      max-width: calc(100vw - 60px);
    }
  `,

  Icon: styled.div`
    margin-top: 16px;
    margin-left: 8px;
  `,

  ArrayContainer: styled.div`
    display: flex;
    position: relative;
    margin: 0 -16px;
    flex-wrap: wrap;

    @media ${MEDIA_QUERIES.MOBILE} {
      margin: 0;
    }
  `,

  ArrayItem: styled.div`
    padding: 0 16px 16px;

    &:hover button {
      display: block;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      padding: 0 4px 8px;
    }
  `,

  ArrayPlus: styled.label`
    padding: 0 16px 16px;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding: 0 4px 8px;
    }
  `,

  DeleteButton: styled(Button)`
    right: -2px;
  `,

  Text: styled.div`
    color: #999;
  `,

  EditButton: styled(Button)`
    position: absolute;
    left: -2px;
  `,
};
