import styled from 'styled-components';

interface ImgProps {
  customWidth?: string;
  customHeight?: string;
  customOpacity?: string | number;
  hoverOpacity?: string | number;
}

export default {
  Img: styled.img<ImgProps>`
    width: ${(props) => (props.customWidth ? props.customWidth : null)};
    height: ${(props) => (props.customHeight ? props.customHeight : null)};
    opacity: ${(props) => (props.customOpacity ? props.customOpacity : null)};
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: ${(props) => (props.hoverOpacity ? props.hoverOpacity : null)};
    }
  `,
};
