const ANNOUNCEMENT_EVERYWHERE_STATUS = 40;
const ANNOUNCEMENT_SALED_STATUS = 50;
const ANNOUNCEMENT_BURNED_STATUS = 60;
const ANNOUNCEMENT_GLASS_STATUS = 70;

const ANNOUNCEMENT_STATUS_COLORS = {
  10: '#EB5757',
  20: '#2F80ED',
  30: '#F09E00',
  40: '#2DC472',
  70: '#5C5C5C',
};

const ANNOUNCEMENT_STATUS_NAME = {
  10: 'Скрыто',
  20: 'Биржа',
  30: 'Виджет',
  40: 'Везде',
  50: 'Продано',
  60: 'Гарь',
  70: 'Стекло',
};

const ANNOUNCEMENT_STATUS = [
  {
    value: 10,
    label: ANNOUNCEMENT_STATUS_NAME[10],
    color: ANNOUNCEMENT_STATUS_COLORS[10],
    tooltip: 'Скрывать везде',
  },
  {
    value: 20,
    label: ANNOUNCEMENT_STATUS_NAME[20],
    color: ANNOUNCEMENT_STATUS_COLORS[20],
    tooltip: 'Выводить только на бирже',
  },
  {
    value: 30,
    label: ANNOUNCEMENT_STATUS_NAME[30],
    color: ANNOUNCEMENT_STATUS_COLORS[30],
    tooltip: 'Выводить только в виджете',
  },
  {
    value: 40,
    label: ANNOUNCEMENT_STATUS_NAME[40],
    color: ANNOUNCEMENT_STATUS_COLORS[40],
    tooltip: 'Выводить на бирже и в виджете',
  },
  {
    value: 70,
    label: ANNOUNCEMENT_STATUS_NAME[70],
    color: ANNOUNCEMENT_STATUS_COLORS[70],
    hidden: true,
  },
];

const ANNOUNCEMENT_STATUS_SELECT = [
  { value: 10, name: 'Скрыто', id: 10 },
  { value: 20, name: 'Биржа', id: 20 },
  { value: 30, name: 'Виджет', id: 30 },
  { value: 40, name: 'Везде', id: 40 },
];

const ANNOUNCEMENT_STATUS_SELECT_IN = [
  {
    label: 'Действующие',
    options: [
      { value: 10, label: 'Скрыто', id: 10 },
      { value: 20, label: 'Биржа', id: 20 },
      { value: 30, label: 'Виджет', id: 30 },
      { value: 40, label: 'Везде', id: 40 },
    ],
  },
  {
    label: 'Завершенные',
    options: [
      { value: 50, label: 'Завершено', id: 50 },
      { value: 60, label: 'Гарь', id: 60 },
    ],
  },
];

const ANNOUNCEMENT_STATUS_ARRAY = {
  10: ANNOUNCEMENT_STATUS[0],
  20: ANNOUNCEMENT_STATUS[1],
  30: ANNOUNCEMENT_STATUS[2],
  40: ANNOUNCEMENT_STATUS[3],
  70: ANNOUNCEMENT_STATUS[4],
};

export {
  ANNOUNCEMENT_STATUS,
  ANNOUNCEMENT_STATUS_ARRAY,
  ANNOUNCEMENT_EVERYWHERE_STATUS,
  ANNOUNCEMENT_SALED_STATUS,
  ANNOUNCEMENT_STATUS_SELECT,
  ANNOUNCEMENT_STATUS_SELECT_IN,
  ANNOUNCEMENT_BURNED_STATUS,
  ANNOUNCEMENT_GLASS_STATUS,
  ANNOUNCEMENT_STATUS_NAME,
  ANNOUNCEMENT_STATUS_COLORS,
};
