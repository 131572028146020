import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface WrapperProps {
  hiddenBlock?: boolean;
}

interface LoadMoreProps {
  hiddenBlock?: boolean;
}

export default {
  Container: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: 0 24px 50px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 0 8px 50px;
    }
  `,

  Wrapper: styled.div<WrapperProps>`
    display: flex;
    height: 32px;
    opacity: ${(props) => (props.hiddenBlock ? '0' : '1')};
    pointer-events: ${(props) => (props.hiddenBlock ? 'none' : 'all')};

    & > ul {
      display: flex;
      user-select: none;
    }

    & > ul > li {
      outline: none;
    }

    & > ul > li > a {
      width: 100%;
      height: 100%;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
    }

    /* prev, next btns */

    & > ul > li.previous,
    & > ul > li.next {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 8px;
      height: 32px;
      font-size: 0;
      background-position: center;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      background-repeat: no-repeat;
      box-sizing: border-box;

      &:hover {
        background-color: #f5f5f5;
      }

      @media (max-width: ${BREAKPOINTS.XL}) {
        height: 40px;
      }

      @media (max-width: ${BREAKPOINTS.XS}) {
        height: 32px;
      }
    }

    & > ul > li.previous > a,
    & > ul > li.next > a {
      padding-left: 16px;
      padding-right: 16px;
    }

    & > ul > li.previous {
      background-image: url('/static/Pagination/prev.svg');
    }

    & > ul > li.next {
      background-image: url('/static/Pagination/next.svg');
    }

    & > ul > li.previous.disabled,
    & > ul > li.next.disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    }

    /* pages btns */

    & > ul > li:not(.previous):not(.next) {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 32px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #000;
      background-color: #fff;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      &.selected {
        background-color: #fff6c2;
        pointer-events: none;
      }

      @media (max-width: ${BREAKPOINTS.XL}) {
        width: 32px;
        height: 40px;
      }

      @media (max-width: ${BREAKPOINTS.XS}) {
        width: 28px;
        height: 32px;
      }

      @media (max-width: ${BREAKPOINTS.XXS}) {
        width: 24px;
        height: 32px;
        font-size: 12px;
      }
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      order: 3;
      height: 40px;
    }

    @media (max-width: ${BREAKPOINTS.XS}) {
      height: 32px;
    }
  `,

  LoadMore: styled.div<LoadMoreProps>`
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    opacity: ${(props) => (props.hiddenBlock ? '0' : '1')};
    pointer-events: ${(props) => (props.hiddenBlock ? 'none' : 'all')};

    @media (max-width: ${BREAKPOINTS.XL}) {
      order: 1;
      margin-bottom: 16px;
    }
  `,
  Spinner: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `,

  LimitSelect: styled.div`
    @media (max-width: ${BREAKPOINTS.XL}) {
      order: 2;

      select {
        padding-left: 8px;
        padding-right: 22px;
        height: 40px;
        background-position: right 8px center;
      }
    }

    @media (max-width: ${BREAKPOINTS.XS}) {
      select {
        height: 32px;
      }
    }
    @media (max-width: ${BREAKPOINTS.XXS}) {
      select {
        padding-right: 18px;
        font-size: 12px;
      }
    }
  `,
};
