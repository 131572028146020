import { useState } from 'react';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import api from 'shared/services/api';
import Emitter from 'shared/services/Emmiter';
import {
  USE_LIST_RELOAD,
  USE_LIST_ITEM_CREATE,
  USE_LIST_ITEM_UPDATE,
  USE_LIST_ITEM_REMOVE,
  USE_LIST_ITEM_MERGE,
} from './useList';

interface UseListItemProps {
  source: {
    root: string;
    detail: (id: string | number, confirm?: any) => string;
    newDetail?: (id: string | number, confirm?: any) => string;
  };
  detail?: (id: string | number, confirm?: any) => string;
  newDetailApi?: boolean;
}

const useListItem = ({ source, detail, newDetailApi }: UseListItemProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const detailSource = newDetailApi ? detail || source.newDetail : detail || source.detail;
  const emitListReload = () => {
    Emitter.emit(USE_LIST_RELOAD);
  };

  const onListItemCreate = (item) => {
    Emitter.emit(USE_LIST_ITEM_CREATE, item);
  };

  const onListItemUpdate = (item) => {
    Emitter.emit(USE_LIST_ITEM_UPDATE, item);
  };

  const onListItemRemove = (item) => {
    Emitter.emit(USE_LIST_ITEM_REMOVE, item);
  };

  const onListItemMerge = (item) => {
    Emitter.emit(USE_LIST_ITEM_MERGE, item);
  };

  const get = async (id, lng = LANGUAGES.RU, params = {}) => {
    setIsLoading(true);
    try {
      const url = lng && lng !== LANGUAGES.RU ? `${detailSource(id)}/translation/${lng}` : detailSource(id);
      const response = await api.get(url, { params });
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return {
        ...error.response,
        lng,
      };
    }
  };

  const create = async (item) => {
    setIsLoading(true);
    try {
      const response = await api.post(source.root, item);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return error.response.data;
    }
  };

  const update = async (item) => {
    setIsLoading(true);
    try {
      const response = await api.patch(detailSource(item.id), item);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return error.response.data;
    }
  };

  const remove = async ({
    id,
    confirm,
    params = {},
  }: {
    id: number | string;
    confirm?: any;
    params?: any;
  }) => {
    setIsLoading(true);
    try {
      const response = await api.delete(detailSource(id, confirm), { params });
      setIsLoading(false);
      return response.data || {};
    } catch (error) {
      setIsLoading(false);
      return error.response.data;
    }
  };

  return {
    get,
    create,
    update,
    remove,
    isLoading,
    emitListReload,
    onListItemCreate,
    onListItemUpdate,
    onListItemRemove,
    onListItemMerge,
  };
};

export default useListItem;
