import React from 'react';
import { QueryContext } from '../ThirdStep/QueryContext';
import { Select } from '../../../FormControls';
import Styled from './styles';

interface SelectorProps {
  value: string;
  options: any[];
  className?: string;
  handleOnChange: (value: any) => void;
  level: number;
}

const PriceTypeSelector: React.FC<SelectorProps> = (props) => {
  const { value, options, className, handleOnChange, level } = props;

  return (
    <QueryContext.Consumer>
      {(context: any) => (
        <Styled.PriceTypes className="priceTypes">
          <Select
            className={className}
            value={value}
            placeholder=""
            onChange={(e) => handleOnChange(e.target.value)}
            options={options.map((option) => {
              const postfix = context ? context.id : Math.random();

              const key = option.id
                ? `key-${option.id}-${level}-${postfix}`
                : `key-${option.name}-${level}-${postfix}`;

              return { id: key, name: option.label, value: option.name };
            })}
          />
        </Styled.PriceTypes>
      )}
    </QueryContext.Consumer>
  );
};

export default PriceTypeSelector;
