export const STEPS = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
};

export const STEPS_PREVIEW = {
  FIRST: 0,
  SECOND: 1,
};

export const STEPS_EVENT_PREVIEW = {
  FIRST: 0,
};

export const ASPECT_RATIO = {
  [STEPS.FIRST]: 3.3 / 1,
  [STEPS.SECOND]: 3.5 / 2,
  [STEPS.THIRD]: 1 / 1.05,
};

export const ASPECT_RATIO_PREVIEW = {
  [STEPS.FIRST]: 1,
  [STEPS.SECOND]: 1.8 / 1,
};

export const MARKETPLACE_RATIO = {
  [STEPS.FIRST]: 4.16 / 1,
  [STEPS.SECOND]: 2.74 / 1,
  [STEPS.THIRD]: 1.29 / 1,
};

export const MARKETPLACE_PREVIEW_RATIO = {
  [STEPS.FIRST]: 1,
  [STEPS.SECOND]: 1.3333 / 1,
};

export const MARKETPLACE_EVENT_PREVIEW_RATIO = {
  [STEPS.FIRST]: 0.7,
};

export const STEPS_WORDS = ['Десктоп, L', 'Планшет, M', 'Мобайл, S'];
export const STEPS_WORDS_PREVIEW = ['Десктоп, L', 'Мобайл, S'];
export const STEPS_WORDS_EVENT_PREVIEW = ['Десктоп, L'];

export const SIZES = ['lg', 'md', 'sm'];
export const SIZES_PREVIEW = ['md', 'sm'];
export const MARKETPLACE_SIZES_PREVIEW = ['md'];

export const MARKETPLACE_IMAGE_SIZES = [
  { width: 1280, height: 308 },
  { width: 1152, height: 420 },
  { width: 540, height: 420 },
];

export const MARKETPLACE_PREVIEW_IMAGE_SIZES = [
  { width: 210, height: 210 },
  { width: 320, height: 240 },
];

export const MARKETPLACE_EVENT_PREVIEW_IMAGE_SIZES = [{ width: 368, height: 520 }];

export const LANDINGS_IMAGE_SIZES = [
  { width: 1920, height: 648 },
  { width: 1215, height: 540 },
  { width: 768, height: 1024 },
];

export const LANDINGS_PREVIEW_IMAGE_SIZES = [
  { width: 960, height: 528 },
  { width: 720, height: 720 },
];
