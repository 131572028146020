import styled from 'styled-components';

interface BadgeProps {
  highlighted?: boolean;
  number?: boolean;
}

const Badge = styled.div<BadgeProps>`
  min-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  color: #000;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${(props) => (props.highlighted ? '#ffda00' : '#e1e1e1')};
  word-break: break-all;

  /* number */
  letter-spacing: ${(props) => (props.number ? '-1px' : null)};
`;

export default Badge;
