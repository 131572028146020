import { useUnit } from 'effector-react';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from 'entities/modal';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import MODALS from 'shared/constants/MODALS';
import { findTranslation } from 'shared/helpers/translations';
import { Modal, ModalClose } from 'shared/ui/modal';
import { Spinner } from 'shared/ui/spinner';
import PlaceForm from '../../form';
import { placeUpdateModal } from '../model';
import Styled from './styles';

export const PlaceUpdateModal = () => {
  const { t } = useTranslation();
  const [lang, setLang] = useState(LANGUAGES.RU);
  const dispatch = useDispatch();

  const { modalOpened, modalClosed, deleteSubmitted, formSubmitted } = useUnit({
    modalOpened: placeUpdateModal.modalOpened,
    modalClosed: placeUpdateModal.modalClosed,
    deleteSubmitted: placeUpdateModal.deleteSubmitted,
    formSubmitted: placeUpdateModal.formSubmitted,
  });
  const { data, loading, modalId, isOpen } = useUnit({
    data: placeUpdateModal.$place,
    loading: placeUpdateModal.$loading,
    modalId: placeUpdateModal.$modalId,
    isOpen: placeUpdateModal.toggler.$isOpen,
  });

  useEffect(() => {
    if (isOpen && modalId) {
      modalOpened({ id: modalId, type: 'update' });
    }

    return () => {
      modalClosed();
      setLang(LANGUAGES.RU);
    };
  }, [isOpen]);

  const openDeleteForm = () => {
    dispatch(
      openModal(MODALS.CONFIRM_MODAL, {
        onSubmit: async () => {
          await deleteSubmitted({
            id: data!.id,
            title: data!.title,
          });

          dispatch(closeModal());
        },
        title: t('references:places.delete_title'),
        text: t('references:places.delete_text'),
        modalData: { size: 400 },
      }),
    );
  };

  const getInitialValues = () => {
    return {
      ...data,
      type_id: {
        label: findTranslation(data!.type.info, lang)?.title,
        value: data!.type.id,
      },
      location_id: {
        label: findTranslation(data!.location.info, lang)?.title,
        value: data!.location.id,
      },
    };
  };

  return (
    <Modal toggler={placeUpdateModal.toggler} name="place-edit" id={modalId}>
      {({ closeModal }) => (
        <Styled.Body className="modal__body">
          <ModalClose onClick={closeModal} />
          {loading ? (
            <Styled.SpinnerContainer>
              <Spinner />
            </Styled.SpinnerContainer>
          ) : (
            <PlaceForm
              isEdit
              initialValues={getInitialValues()}
              title="references:places.edit_title"
              onSubmit={formSubmitted}
              closeModal={closeModal}
              onChangeLng={setLang}
              openDeleteForm={openDeleteForm}
            />
          )}
        </Styled.Body>
      )}
    </Modal>
  );
};
