import React from 'react';
import Styled from './styles';

interface InfoProps {
  announcement: any;
  isNumericTicket?: boolean;
  tickets?: any[];
  count?: any;
}

const AnnouncementInfo: React.FC<InfoProps> = ({ announcement, isNumericTicket, tickets, count }) => (
  <Styled.AvailableTicketsList>
    <Styled.CompanyName>{announcement.create_by.company.name}</Styled.CompanyName>
    {announcement.sector && <div>{`Сектор: ${announcement.sector}`}</div>}
    {announcement.row && <div>{`Ряд: ${announcement.row}`}</div>}
    {isNumericTicket ? 'Кол-во:' : 'Места:'}{' '}
    {isNumericTicket ? count : tickets.map((ticket) => ticket.seat).join(', ')}
  </Styled.AvailableTicketsList>
);

AnnouncementInfo.defaultProps = {
  isNumericTicket: false,
  tickets: [],
  count: null,
};

export default AnnouncementInfo;
