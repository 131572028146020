import React from 'react';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import ConstructorAutoUpdate from 'shared/lib/ConstructorAutoUpdate';
import Button from '../../../Button';
import Styled from './styles';
import ChevronLeftIcon from '../../../../../static/icons/chevron-left.svg';

interface FooterProps {
  goBack?: () => void;
  form: {
    change: (name: string, value: any) => any;
  };
  withoutNextAction?: boolean;
  updateData: any;
}

const PageConstructorFooter: React.FC<FooterProps> = ({ goBack, form, withoutNextAction, updateData }) => {
  const setCreateAction = () => {
    form.change('submit_action', SUBMIT_ACTIONS.CREATE);
  };
  const setContinueAction = () => {
    form.change('submit_action', SUBMIT_ACTIONS.CONTINUE);
  };

  return (
    <Styled.Buttons>
      <ConstructorAutoUpdate save={updateData} />
      {goBack && (
        <Styled.BackButton>
          <Button transparent type="button" onClick={goBack}>
            Назад
            <ChevronLeftIcon />
          </Button>
        </Styled.BackButton>
      )}
      <Styled.SubmitButtons>
        <Button gray={!withoutNextAction} type="submit" onClick={setCreateAction}>
          Сохранить
        </Button>
        {!withoutNextAction && (
          <Button type="submit" onClick={setContinueAction}>
            Далее
          </Button>
        )}
      </Styled.SubmitButtons>
    </Styled.Buttons>
  );
};

export default PageConstructorFooter;
