import React from 'react';
import { Field } from 'react-final-form';
import Styled from './styles';
import Modal from '../../Modal';
import Button from '../../Button';
import { ACTIONS } from 'shared/constants/PREORDER';
import Form from '../../Form';
import { InputWithSelect, NumberInput } from '../../FormControls';
import { ModalFunctions } from '../../../../interfaces/modal';
import { currencySources } from 'entities/currency/sources';

interface FormProps {
  onSubmit: (data: any) => void;
  isTransferFromOrder?: boolean;
  closeModal: ModalFunctions['closeModal'];
  tickets: any[];
}

const PreorderModalForm: React.FC<FormProps> = ({ onSubmit, closeModal, tickets, isTransferFromOrder }) => {
  const isDisabled = isTransferFromOrder
    ? tickets.filter((ticket) => ticket.action === ACTIONS.SELECTED).length === 0
    : tickets.filter((ticket) => ticket.action === ACTIONS.DELETE).length !== 0;

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {isTransferFromOrder && (
            <>
              <Styled.FormRow>
                <Field name="price">
                  {({ input, meta }) => (
                    <NumberInput label="Номинал за шт" max={9999999} {...input} meta={meta} type="number" />
                  )}
                </Field>
                <Field name="purchase_price">
                  {({ input, meta }) => (
                    <NumberInput
                      label="Закупочная за шт"
                      max={9999999}
                      {...input}
                      meta={meta}
                      type="number"
                    />
                  )}
                </Field>
              </Styled.FormRow>
              <Styled.FormRow full small>
                <Field name="currency">
                  {({ input, meta }) => (
                    <InputWithSelect isAsync label="Валюта" route={currencySources} {...input} meta={meta} />
                  )}
                </Field>
              </Styled.FormRow>
            </>
          )}
          {isTransferFromOrder && (
            <Styled.Helper>Необходимо вводить сумму в валюте, которой куплены билеты</Styled.Helper>
          )}
          <Modal.Footer>
            <Button transparent type="button" onClick={closeModal}>
              Отмена
            </Button>
            <Button type="submit" disabled={isDisabled}>
              Отправить
            </Button>
          </Modal.Footer>
        </form>
      )}
    />
  );
};

export default PreorderModalForm;
