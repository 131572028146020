import styled from 'styled-components';
import StyledTabs from '../../Tabs/styles';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    ${StyledTabs.HorizontalTabs} {
      position: absolute;
      top: 40px;
      left: -40px;
      right: -40px;
      width: auto;
      padding: 0 18px;
      z-index: 1;

      .menu-wrapper {
        padding: 0;
      }

      .menu-item-wrapper {
        height: 32px;
      }

      .menu-item-wrapper li {
        height: 32px;
      }
    }

    ${StyledTabs.Tab} {
      font-size: 14px;
    }
  `,

  Title: styled.div`
    margin-bottom: 48px;
    font-size: 20px;
    line-height: 26px;

    @media ${MEDIA_QUERIES.MOBILE} {
      margin-top: 8px;
    }
  `,

  Spinner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
