import React from 'react';
import FirstIcon from '../../../../../../static/constructor/favicon/1.svg';
import SecondIcon from '../../../../../../static/constructor/favicon/2.svg';
import ThirdIcon from '../../../../../../static/constructor/favicon/3.svg';
import FourthIcon from '../../../../../../static/constructor/favicon/4.svg';
import FifthIcon from '../../../../../../static/constructor/favicon/5.svg';
import SixthIcon from '../../../../../../static/constructor/favicon/6.svg';
import SeventhIcon from '../../../../../../static/constructor/favicon/7.svg';
import EighthIcon from '../../../../../../static/constructor/favicon/8.svg';
import NinthIcon from '../../../../../../static/constructor/favicon/9.svg';

export default [
  '',
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ё',
  'Ж',
  'З',
  'И',
  'Й',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Э',
  'Ю',
  'Я',
  'D',
  'F',
  'G',
  'I',
  'J',
  'N',
  'Q',
  'R',
  'S',
  'U',
  'V',
  'W',
  'Y',
  'Z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  <FirstIcon />,
  <SecondIcon />,
  <ThirdIcon />,
  <FourthIcon />,
  <FifthIcon />,
  <SixthIcon />,
  <SeventhIcon />,
  <EighthIcon />,
  <NinthIcon />,
];
