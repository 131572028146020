import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import { noun } from 'plural-ru';
import React from 'react';
import ReactSelect from 'react-select';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import CloseIcon from '../../../../static/icons/close.svg';
import { OptionWithCheckbox } from '../../FormControls/InputWithSelect';
import Styled from './styles';

interface ModalProps {
  data: any;
  unselectEvent: (ids: any[]) => any;
  selectEventChildren: (id: number) => any;
  selectedDatesIds: number[];
}

const ReportsEventsFilterModalTag: React.FC<ModalProps> = (props) => {
  const { data, selectedDatesIds, selectEventChildren, unselectEvent } = props;
  const { title, children } = data;
  const tagIds = compact(flatten([data.id, children && children.map((item) => item.id)]));
  const tagSelectedIds = selectedDatesIds.filter((item) => includes(tagIds, item));

  const value = selectedDatesIds.map((item) => ({ label: title, value: item }));
  const placeholder = () => {
    let { length } = tagSelectedIds;

    if (length === children.length) {
      return 'Все даты';
    }

    if (length === 0) {
      return 'Нет дат';
    }

    return `${length} ${noun(length, 'дата', 'даты', 'дат')}`;
  };

  const handleChange = (_, meta) => {
    const {
      option: { value: id },
    } = meta;

    selectEventChildren(id);
  };

  const handleClick = () => unselectEvent(tagIds);

  return (
    <Styled.Tag>
      <span>{title}</span>
      {children && (
        <Styled.TagDatesSelector placeholderIsRed={tagSelectedIds.length === 0}>
          <ReactSelect
            isMulti
            value={value}
            isSearchable={false}
            placeholder={placeholder()}
            onChange={handleChange}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            classNamePrefix="react-select"
            controlShouldRenderValue={false}
            components={{ Option: OptionWithCheckbox }}
            options={children.map((item) => ({
              value: item.id,
              label: formatFullDateAndTime(`${item.date_start} ${item.time_start}`),
            }))}
          />
        </Styled.TagDatesSelector>
      )}
      <Styled.TagCloseIcon onClick={handleClick}>
        <CloseIcon />
      </Styled.TagCloseIcon>
    </Styled.Tag>
  );
};

export default ReportsEventsFilterModalTag;
