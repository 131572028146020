import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Card: styled.div<{ small?: boolean; noHover?: boolean }>`
    padding: ${(props) => (props.small ? '16px 24px' : '24px')};
    background-color: #fff;
    border: 0;
    box-sizing: border-box;
    border-radius: ${(props) => (props.small ? 4 : 12)}px;
    transition: box-shadow 0.2s ease-in-out;
    border: 2px solid #f5f5f5;
    border-radius: 12px;

    ${(props) =>
      !props.noHover &&
      css`
        &:hover {
          cursor: pointer;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        }
      `}

    @media (max-width: ${BREAKPOINTS.MD}) {
      ${({ small }) =>
        small &&
        css`
          border-radius: 0;
          padding: 12px 16px;
          border: none;
          border-bottom: 2px solid #f5f5f5;
        `}
    }
  `,

  Title: styled.div<{ small?: boolean }>`
    margin-bottom: 4px;
    font-weight: bold;
    line-height: normal;
    font-size: ${(props) => (props.small ? 14 : 20)}px;
    color: #170200;
    word-wrap: break-word;
  `,
};
