export default {
  LOCATION: '/static/icons/location-arrow.svg',
  GLOBE: '/static/icons/globe.svg',
  BRIEFCASE: '/static/icons/briefcase.svg',
  BAN: '/static/icons/ban.svg',
  PEN: '/static/icons/pen.svg',
  LOGOUT: '/static/icons/logout.svg',
  MOBILE: '/static/icons/mobile.svg',
  DESKTOP: '/static/icons/desktop.svg',
  TRASH: '/static/icons/trash.svg',
  LOCK: '/static/icons/lock.svg',
  CLIPBOARD_CHECK: '/static/icons/clipboard-check.svg',
  CLOSE: '/static/icons/close.svg',
  CLOSE_RED: '/static/icons/close-red.svg',
  CLOSE_GREY: '/static/icons/close-grey.svg',
  CALENDAR_PLUS: '/static/icons/calendar-plus.svg',
  USER: '/static/icons/user.svg',
  MAP_MARKER: '/static/icons/map-marker.svg',
  CHECK_GREEN: '/static/icons/check-green.svg',
  SYNC: '/static/icons/sync.svg',
  UPLOAD: '/static/icons/upload.svg',
  BOX: '/static/icons/box.svg',
  SEARCH: '/static/icons/search.svg',
  BELL: '/static/icons/bell.svg',
  BARS: '/static/icons/bars.svg',
  LINK: '/static/icons/chain.svg',
  UNLINK: '/static/icons/unchain.svg',
  SEND: '/static/icons/send.svg',
  EXTERNAL_LINK: '/static/icons/external-link.svg',
  PHONE: '/static/icons/phone.svg',
  CALENDAR: '/static/icons/calendar.svg',
  DOCUMENT: '/static/icons/doc.svg',
  UPLOAD_PICTURE: '/static/icons/upload-picture.svg',
  QUESTION_RED: '/static/icons/question-red.svg',
  INFO: '/static/icons/info.svg',
  COINS_FILLED: '/static/icons/coins-filled.svg',
  QUILL_IMAGE: '/static/icons/image-quill.png',
};
