import React, { ChangeEvent, Fragment } from 'react';
import Tooltip from '../../Tooltip';
import Error from '../Error';
import Label from '../Label';
import Styled from './styled';

interface Button {
  value: number | string;
  id: number | string;
  name: number | string;
  tooltip?: string;
}

interface RadioGroupProps {
  buttons: Button[];
  meta?: any;
  label?: string;
  onChange: (event: any) => void;
  name?: string;
  postfix?: string;
  small?: boolean;
  withoutOverflow?: boolean;
  disabled?: boolean;
  value?: string | number;
  isNumber?: boolean;
  defaultValue?: { id: number; value: number; name: string };
}

const RadioGroup = ({
  buttons,
  meta = {},
  label = '',
  name = '',
  postfix = '',
  small = false,
  withoutOverflow = false,
  disabled = false,
  isNumber,
  defaultValue,
  ...rest
}: RadioGroupProps) => {
  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = isNumber ? +value : value;

    rest.onChange({
      target: {
        value: selectedValue,
      },
    });
  };

  const value = defaultValue && !rest.value ? defaultValue.value : rest.value;

  return (
    <Styled.Wrapper>
      {label && <Label>{label}</Label>}
      <Styled.Container data-selenium={`${name}_buttons`} className="radio__container">
        {buttons.map((button, index) => {
          const id = `radio_${name}_${button.id}${postfix}`;

          return (
            <Fragment key={button.id}>
              <Styled.StyledInput
                id={id}
                {...rest}
                name={name}
                onChange={handleChange}
                value={button.value}
                checked={button.value === value}
                type="radio"
                small={small}
                withoutOverflow={withoutOverflow}
                disabled={disabled}
                className="radio__input"
              />
              <Styled.Preview
                length={buttons.length}
                htmlFor={id}
                disabled={disabled}
                data-selenium={`${name}_${index}`}
                className="radio__preview"
              >
                <Styled.PreviewText>
                  {button.tooltip ? (
                    <Tooltip text={button.tooltip}>
                      <div>{button.name}</div>
                    </Tooltip>
                  ) : (
                    button.name
                  )}
                </Styled.PreviewText>
              </Styled.Preview>
            </Fragment>
          );
        })}
      </Styled.Container>
      <Error meta={meta} />
    </Styled.Wrapper>
  );
};

export default RadioGroup;
