import styled, { css, createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import IDS from 'shared/constants/IDS';

interface ContainerProps {
  fluid?: boolean;
  highlighted?: boolean;
  withMobile?: boolean;
  disabled?: boolean;
}

interface HorizontalTabsProps {
  sidebarIsOpen?: boolean;
  hideBorder?: boolean;
}

interface TabProps {
  active?: boolean;
  disabled?: boolean;
}

interface ArrowProps {
  next?: boolean;
}

export default {
  GlobalStyles: createGlobalStyle`
    #${IDS.APP_LAYOUT} {
      flex-grow: 1;
      height: auto;
      margin-top: 40px;
    }
  `,

  Container: styled.div<ContainerProps>`
    width: ${({ fluid }) => (fluid ? '100%' : null)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

    .menu-wrapper {
      padding: 0 20px;

      @media (max-width: ${BREAKPOINTS.MD}) {
        padding: 0;
      }
    }

    .menu-wrapper--inner {
      display: flex;
      align-items: center;
    }

    .menu-item-wrapper {
      height: 40px;
      outline: none;

      & li {
        height: 40px;
        padding: 0 20px;
        box-sizing: border-box;

        ${(props) =>
          props.highlighted &&
          css`
            color: #000;
          `}

        @media (max-width: ${BREAKPOINTS.MD}) {
          padding: 0 16px;
        }
      }
    }

    .menu-wrapper--inner > div:first-child {
      padding-left: 0;
    }

    .menu-wrapper--inner > div:last-child {
      padding-right: 0;
    }

    .scroll-menu-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0 10px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      @media (max-width: ${BREAKPOINTS.MD}) {
        padding: 0;
      }
    }

    .scroll-menu-arrow.scroll-menu-arrow--disabled {
      opacity: 0;
      pointer-events: none;
    }

    ${(props) =>
      props.withMobile &&
      css`
        @media (max-width: ${BREAKPOINTS.XL}) {
          flex-direction: column;
        }
      `}
  `,

  HorizontalTabs: styled.ul<HorizontalTabsProps>`
    z-index: 5;
    position: fixed;
    top: 64px;
    left: ${({ sidebarIsOpen }) => (sidebarIsOpen ? '224px' : '64px')};
    width: ${({ sidebarIsOpen }) => (sidebarIsOpen ? 'calc(100% - 244px)' : 'calc(100% - 84px)')};
    border-bottom: ${({ hideBorder }) => (hideBorder ? 'none' : '1px solid #e1e1e1')};

    background-color: #fff;

    & .menu-wrapper {
      flex-grow: 1;
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      top: 55px;
      left: 0;
      width: 100%;
    }
  `,

  Tab: styled.li<TabProps>`
    position: relative;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    color: ${({ active }) => (active ? '#000' : '#585858')};
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #000;

      & > svg {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      width: calc(100% - 40px);
      height: 2px;
      bottom: 0px;
      left: 20px;
      display: block;
      background-color: ${({ active }) => (active ? '#ffda00' : 'transparent')};
      transition: all 0.2s ease-in-out;

      @media (max-width: ${BREAKPOINTS.MD}) {
        left: 10px;
        width: calc(100% - 20px);
      }
    }

    & > svg {
      margin-right: 8px;
      opacity: ${({ active }) => (active ? '1' : '0.4')};
      transition: all 0.2s ease-in-out;
    }

    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;

        &:hover {
          color: #585858;
        }
      `}
  `,

  Arrow: styled.li<ArrowProps>`
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: #fff;
    font-size: 18px;

    &:hover svg {
      opacity: 1;
    }

    & svg {
      width: 8px;
      height: 12px;
      opacity: 0.4;
      transition: all 0.2s ease-in-out;

      transform: ${({ next }) => next && 'rotate(180deg)'};
    }
  `,
};
