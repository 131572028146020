import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  FieldContainer: styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 248px;
    margin-right: 16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
  `,

  Combinators: styled.div`
    box-sizing: border-box;
    display: inline-block;
    margin-right: 24px;
    width: 90px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-right: auto;
      order: -1;
    }
  `,

  PriceTypes: styled.div`
    box-sizing: border-box;
    display: inline-block;
    margin-right: 24px;
    max-width: 180px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 16px 0 0;
      width: 100%;
      max-width: 100%;
    }
  `,
};
