import get from 'lodash/get';
import React from 'react';
import { Field } from 'react-final-form';
import { currencySources } from 'entities/currency';
import {
  composeValidators,
  required,
  minNumberValue,
  maxNumberValue,
  intValidation,
} from 'shared/helpers/validations';
import { InputWithSelect, NumberInput } from '../../../../FormControls';
import Styled from './styles';

interface PricesProps {
  currency?: any;
  isEdit?: boolean;
  isCertificate?: boolean;
}

const AnnouncementPrices: React.FC<PricesProps> = (props) => {
  const { currency = null, isEdit = false, isCertificate = false } = props;

  const icon = get(currency, 'data.icon');

  const placeholder = icon ? (
    <Styled.PriceIcon>
      <img src={icon} alt={currency.label} />
    </Styled.PriceIcon>
  ) : (
    ''
  );

  return (
    <>
      <Styled.Prices fullWidth={isCertificate}>
        {!isCertificate && (
          <Field name="currency" validate={required}>
            {({ input, meta }) => (
              <InputWithSelect
                isAsync
                label="Валюта"
                route={currencySources}
                {...input}
                meta={meta}
                disabled={isEdit}
              />
            )}
          </Field>
        )}
        <Field
          name="price"
          validate={composeValidators(required, intValidation, minNumberValue(1), maxNumberValue(9999999))}
        >
          {({ input, meta }) => (
            <NumberInput label="Номинал" icon={placeholder} {...input} meta={meta} type="number" />
          )}
        </Field>
      </Styled.Prices>
      {!isCertificate && (
        <Styled.Prices>
          <Field
            name="purchase_price"
            validate={composeValidators(required, intValidation, minNumberValue(1), maxNumberValue(9999999))}
          >
            {({ input, meta }) => (
              <NumberInput label="Закупочная" icon={placeholder} {...input} meta={meta} type="number" />
            )}
          </Field>
          <Field
            name="total_price"
            validate={composeValidators(required, intValidation, minNumberValue(1), maxNumberValue(9999999))}
          >
            {({ input, meta }) => (
              <NumberInput label="Итоговая" icon={placeholder} {...input} meta={meta} type="number" />
            )}
          </Field>
        </Styled.Prices>
      )}
    </>
  );
};

export default AnnouncementPrices;
