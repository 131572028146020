import React, { Component } from 'react';
import omit from 'lodash/omit';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import head from 'lodash/head';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import Styled from './styles';
import { eventsSources } from 'entities/events';
import { Select, Checkbox, RadioGroup, InputWithSelect, DateTimePicker } from '..';
import { formatDateToDayPreview, formatTimeByTime } from 'shared/helpers/formatters/date';
import api from 'shared/services/api';
import Placeholder from '../../Placeholder';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import { renderEventsForSelector, renderEventsForWidgetSelector } from 'shared/helpers/optionsRenderers';
import { OptionWithEventInfo } from '../InputWithSelect';

type WidgetEventSelectorProps = typeof WidgetEventSelector.defaultProps & {
  disabled?: boolean;
  canSelectParent?: boolean;
  dateRenderCount?: number;
  meta: any;
  input: any;
  isEdit?: boolean;
};

class WidgetEventSelector extends Component<WidgetEventSelectorProps, any> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    disabled: false,
    canSelectParent: false,
    dateRenderCount: 5,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      data: null,
      selectedDay: null,
      isLoading: true,
      parentSelected: props.canSelectParent,
    };
  }

  componentDidMount() {
    const { value, name, label, data } = this.props.input.value;

    if (value) {
      if (data && data.is_periodical) {
        const params = { outdated: true };
        const id = data.parent ? data.parent.id : data.id;

        return api.get(eventsSources.detail(id), { params }).then((response) => {
          const children = sortBy(
            response.data.children.filter((item) => {
              const dateTimeStart = moment(`${item.date_start} ${item.time_start}`, DATE_FORMATS.DATE_TIME);

              if (dateTimeStart.isBefore(moment().subtract(1, 'minutes')) && item.id !== value) {
                return null;
              }

              return item;
            }),
            'date_start',
          );

          if (!children.length) {
            NotificationManager.error('Выбранная дата была удалена. Создайте новый виджет');
          }

          if (children.length) {
            const selectedDay = find(children, { id: value }) || head(children);

            const state: any = {
              id: value,
              data: {
                label: response.data.title,
                value,
                data: { ...response.data, children },
                withManyChildrens: true,
              },
              selectedDay: selectedDay.date_start,
              isLoading: false,
            };

            if (value !== state.data.data.id) {
              const childSelectedDay = find(response.data.children, { id: Number(value) });

              if (!childSelectedDay) {
                NotificationManager.error('Выбранная дата была удалена. Создайте новый виджет');
                state.isLoading = true;
              }

              state.selectedDay = childSelectedDay && childSelectedDay.date_start;
              state.parentSelected = !childSelectedDay;
            }

            this.setState(state);
          }
        });
      }

      const { parentSelected } = this.state;

      const withManyChildrens =
        Boolean(data.children && data.children.length) || (parentSelected && get(data, 'children'));

      return this.setState({
        id: value,
        data: {
          label: name || label,
          value,
          data,
          withManyChildrens,
        },
        ...(withManyChildrens &&
          !parentSelected && { selectedDay: find(data.children, { id: Number(value) }).date_start }),
        isLoading: false,
      });
    }

    return this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate(_, prevState) {
    const { id, data } = this.state;

    if (!data) {
      return this.props.input.onChange(null);
    }

    if (id !== prevState.id) {
      return this.props.input.onChange({ ...data, value: id });
    }

    return null;
  }

  handleOnChange = (data) => {
    if (!data) {
      return this.setState({
        id: null,
        data: null,
        selectedDay: null,
      });
    }

    const children = get(data, 'data.children');
    const newData = {
      ...data,
      data: {
        ...data.data,
        ...(children && children.length && { children: sortBy(children, 'date_start') }),
      },
      ...(children && children.length && { withManyChildrens: true }),
    };
    const { canSelectParent } = this.props;

    if (canSelectParent) {
      return this.setState({
        data: newData,
        id: data.data.id,
        parentSelected: true,
        // @ts-ignore
        ...(children && children.length && { selectedDay: head(children).date_start }),
      });
    }

    if (children && children.length < 5) {
      return this.setState({
        data: newData,
        id: data.value,
      });
    }

    if (children && children.length >= 5) {
      return this.setState({
        data: newData,
        // @ts-ignore
        id: head(children).id,
        // @ts-ignore
        selectedDay: head(children).date_start,
      });
    }

    return this.setState({
      data: newData,
      id: data.data.id,
    });
  };

  handleDateTimePickerChange = (value) => {
    this.selectDay({ target: { value } });
  };

  get datesInfo() {
    const { selectedDay } = this.state;
    const childrens = get(this.state, 'data.data.children');
    const days = groupBy(childrens, 'date_start');
    const daysArray = map(days, (_, index) => index);
    const daysCount = daysArray.length;
    const dayTimes = days[selectedDay];

    return {
      childrens,
      days,
      daysArray,
      daysCount,
      dayTimes,
    };
  }

  selectDay = ({ target: { value } }) => {
    const { days } = this.datesInfo;

    this.setState({
      selectedDay: value,
      id: head(days[value]).id,
    });
  };

  selectTime = ({ target: { value } }) => {
    this.setState({
      id: value,
    });
  };

  selectParent = ({ target: { checked } }) => {
    const { children, id } = this.state.data.data;

    this.setState((prevState) => ({
      parentSelected: checked,
      id: checked ? id : prevState.data.value,
      ...(checked === false && {
        // @ts-ignore
        selectedDay: head(children).date_start,
        // @ts-ignore
        id: head(children).id,
      }),
    }));
  };

  disabledDate = (current) => {
    const { daysArray } = this.datesInfo;

    if (daysArray.indexOf(current.format(DATE_FORMATS.DATE)) !== -1) return false;

    return true;
  };

  render() {
    const { input, meta, disabled, canSelectParent, dateRenderCount } = this.props;
    const { selectedDay, id, data, isLoading, parentSelected } = this.state;
    const withManyChildrens = get(this.state, 'data.withManyChildrens');
    const { days, daysCount, dayTimes } = this.datesInfo;

    if (isLoading) {
      return (
        <Styled.Container>
          <Placeholder />
        </Styled.Container>
      );
    }

    return (
      <Styled.Container>
        <InputWithSelect
          isAsync
          label="Мероприятие"
          route={eventsSources}
          query={{
            sortBy: 'title',
            sortOn: 'asc',
          }}
          {...omit(input, 'onChange')}
          onChange={this.handleOnChange}
          value={
            data
              ? {
                  value: data.value,
                  label: data.label,
                  data: data.data,
                }
              : ''
          }
          meta={meta}
          optionsRenderer={canSelectParent ? renderEventsForWidgetSelector : renderEventsForSelector}
          components={{ Option: OptionWithEventInfo }}
          isDisabled={disabled}
        />
        {canSelectParent && get(data, 'data.is_periodical') && (
          <Styled.Row>
            <Styled.RowField>
              <Checkbox checked={parentSelected} variant="round" onChange={this.selectParent}>
                Выбрать все даты
              </Checkbox>
            </Styled.RowField>
          </Styled.Row>
        )}
        {withManyChildrens && (
          <>
            {canSelectParent && !parentSelected && (
              <Styled.Row>
                {daysCount < dateRenderCount ? (
                  <Styled.RowField>
                    <RadioGroup
                      onChange={this.selectDay}
                      value={selectedDay}
                      buttons={map(days, (_, index) => ({
                        value: index,
                        name: formatDateToDayPreview(index),
                        id: index,
                      }))}
                      small
                      withoutOverflow
                      disabled={daysCount === 1}
                    />
                  </Styled.RowField>
                ) : (
                  <Styled.RowField isDatepicker>
                    <DateTimePicker
                      label="Дата"
                      showToday={false}
                      outdatedToggler={false}
                      withoutTimePick
                      format={DATE_FORMATS.DATE}
                      onChange={this.handleDateTimePickerChange}
                      disabledDate={this.disabledDate}
                      input={{ value: moment(this.state.selectedDay, DATE_FORMATS.DATE) }}
                      meta={meta}
                    />
                  </Styled.RowField>
                )}
                {selectedDay && dayTimes.length < 5 && (
                  <Styled.RowField>
                    <RadioGroup
                      onChange={this.selectTime}
                      value={Number(id)}
                      buttons={days[selectedDay].map((item) => ({
                        value: item.id,
                        name: formatTimeByTime(item.time_start),
                        id: item.id,
                      }))}
                      small
                      withoutOverflow
                      disabled={dayTimes.length === 1}
                    />
                  </Styled.RowField>
                )}
                {selectedDay && dayTimes.length >= 5 && (
                  <Styled.RowField>
                    <Select
                      label="Время"
                      onChange={this.selectTime}
                      value={Number(id)}
                      options={days[selectedDay].map((item) => ({
                        value: item.id,
                        name: formatTimeByTime(item.time_start),
                        id: item.id,
                      }))}
                    />
                  </Styled.RowField>
                )}
              </Styled.Row>
            )}
          </>
        )}
      </Styled.Container>
    );
  }
}

export default WidgetEventSelector;
