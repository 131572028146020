import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);
export default {
  root: `${config.LANDING_API_URL}/api/v1/custom_pages`,
  detail: (id) => `${config.LANDING_API_URL}/api/v1/custom_pages/${id}`,
  newDetail: (id: any) => `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/custom_pages/${id}`,
  detailConstructor: (id) => `${config.LANDING_API_URL}/api/v1/custom_pages/${id}`,
  blocks: (id, lng?) =>
    `${config.LANDING_API_URL}/api/v1/custom_pages/${id}/blocks${lng ? `?lang=${lng}` : ''}`,
  blocksDetail: (id, blockId) => `${config.LANDING_API_URL}/api/v1/custom_pages/${id}/blocks/${blockId}`,
  events: `${config.LANDING_API_URL}/api/v1/events`,

  async createQnA(id: any, qnaforUpdate: any) {
    const promises = api.post(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/custom_pages/${id}/qna`,
      {
        qna_id: qnaforUpdate,
      },
    );

    return promises;
  },

  async deleteQnA(id: any, qnaforDelete: number[]) {
    const promises = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/custom_pages/${id}/qna/${qnaforDelete}`,
    );

    return promises;
  },
};
