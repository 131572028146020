import React, { Component } from 'react';
import dropRight from 'lodash/dropRight';
import toString from 'lodash/toString';
import omit from 'lodash/omit';
import Styled from './styles';
import KEY_CODES from 'shared/constants/KEY_CODES';
import InputWithTagsTag from './InputWithTagsTag';
import Label from '../LabelNew';

interface Props {
  label?: string;
  onBlur: (event: any) => void;
  meta: any;
  onChange: (value: any) => void;
  value: any[];
}

interface State {
  value: string;
  tags: any[];
}

class InputWithTags extends Component<Props, State> {
  inputRef: any;

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      tags: props.value || [],
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(_, prevState) {
    const { tags } = this.state;
    const { onChange } = this.props;
    const { tags: prevTags } = prevState;

    if (prevTags !== tags) {
      onChange(tags);
    }
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleKeyDown = (event) => {
    const { tags, value } = this.state;

    const keyCodes = [KEY_CODES.ENTER, KEY_CODES.SPACEBAR];

    switch (event.keyCode) {
      case KEY_CODES.BACKSPACE:
        if (tags.length === 0) return;

        if (value === '') {
          this.setState({
            tags: dropRight(tags),
          });
        }

        break;

      default:
        if (value.trim() === '') return;

        if (tags.indexOf(value) >= 0) return;

        if (keyCodes.indexOf(event.keyCode) !== -1) {
          this.setState({
            value: '',
            tags: [...tags, value],
          });
        }

        break;
    }
  };

  handleBlur = (event) => {
    const { tags, value } = this.state;

    this.props.onBlur(event);

    if (value.trim() === '') return;

    if (tags.indexOf(value) >= 0) return;

    this.setState({
      value: '',
      tags: [...tags, value],
    });
  };

  handleClick = () => {
    this.inputRef.current.focus();
  };

  removeTag = (tag) => {
    this.setState(({ tags }) => ({
      tags: tags.filter((item) => item !== tag),
    }));
  };

  render() {
    const { label, meta, ...rest } = this.props;
    const { value, tags } = this.state;

    return (
      <Styled.Container onClick={this.handleClick} meta={meta}>
        {label && (
          <Styled.Label>
            <Label value={value || tags.length !== 0} meta={meta}>
              {label}
            </Label>
          </Styled.Label>
        )}
        <Styled.Tags>
          {tags.map((tag) => (
            <InputWithTagsTag key={tag} removeTag={this.removeTag}>
              {toString(tag)}
            </InputWithTagsTag>
          ))}

          <Styled.Input
            value={value}
            ref={this.inputRef}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            {...omit(rest, ['value', 'onChange', 'onBlur'])}
          />
        </Styled.Tags>
      </Styled.Container>
    );
  }
}

export default InputWithTags;
