import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import get from 'lodash/get';
import tables from 'config/tables';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import Form from '../../Form';
import Button from '../../Button';
import { renderMembers } from 'shared/helpers/optionsRenderers';
import { InputWithSelect } from '../../FormControls';
import { companySources } from 'entities/company';
import { required } from 'shared/helpers/validations';
import { getIdsError } from 'shared/helpers/tables';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  bulkAssignManager: (data: any) => any;
  setRowsErrors: ({ name, errors }: { name: string; errors: any }) => any;
}

const OrdersAssignMemberModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    bulkAssignManager,
    setRowsErrors,
    data: { ids: selectedRows, modalData, name, page },
  } = props;
  const { errorText } = tables[page];

  const onSubmit = ({ manager }) =>
    bulkAssignManager({
      ids: selectedRows,
      manager,
    }).then((response) => {
      if (response.error) {
        const ids = get(response, 'error.response.data.error');

        setRowsErrors({
          name,
          errors: getIdsError({ ids, page }),
        });

        return NotificationManager.error(errorText, 'Ошибка');
      }

      closeModal();

      modalData.onModalSubmit();

      return response;
    });

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title isSmall className="modal__title">
        Редактирование статуса
      </Modal.Title>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values: formValues, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Field name="manager" validate={required}>
              {({ input, meta: inputMeta }) => (
                <InputWithSelect
                  isAsync
                  placeholder="Менеджер"
                  route={companySources}
                  routeName="managersAssign"
                  optionsRenderer={renderMembers}
                  {...input}
                  meta={inputMeta}
                />
              )}
            </Field>
            <Modal.Footer mobileRow className="modal__footer">
              <Button type="button" transparent onClick={closeModal}>
                Отмена
              </Button>
              <Button disabled={!formValues.manager || pristine}>Готово</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default OrdersAssignMemberModal;
