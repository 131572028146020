import { AxiosError } from 'axios';
import config from 'config/config';
import { createEffect } from 'effector';
import { Router } from 'next/router';
import { i18n } from 'next-i18next';
import { IBaseModalRequestParams, IBaseRequestDataParams } from '../../../api/types';
import api from '../../../services/api';
import { CreatedPlaceData, CreatePlaceData, CreatePlaceResponseData, Place } from './types';

export const route = `${config.COMMON_API_URL}/admin/v1/places`;

export const referencePlaceSources = {
  root: route,
  placeType: `${config.COMMON_API_URL}/admin/v1/place_types`,
  detail: (id: number) => `${route}/${id}`,
};

export const fetchPlacesFx = createEffect(async (query: Router['query'] = {}) => {
  const { location, page, ...rest } = query;

  const response = await api.get(route, {
    params: {
      ...rest,
      ...(!rest?.search_string && { page: page }),
      ...(query && { language_code: i18n?.language }),
      ...(location && { location_id_in: location }),
      language_code: i18n?.language,
    },
  });

  return response.data;
});

export const fetchPlaceFx = createEffect(async ({ id }: IBaseModalRequestParams): Promise<Place> => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const createPlaceFx = createEffect<CreatePlaceData, CreatedPlaceData, AxiosError>(
  async ({ title, type_id, location_id }) => {
    const response = await api.post(route, {
      title,
      type_id,
      location_id,
    });

    return response.data;
  },
);

export const updatePlaceFx = createEffect<CreatePlaceData, CreatePlaceResponseData, AxiosError>(
  async ({ title, type_id, location_id, id }) => {
    const response = await api.patch(`${route}/${id}`, {
      title,
      type_id,
      location_id,
    });

    return response.data;
  },
);

export const deletePlaceFx = createEffect(async ({ id }: IBaseRequestDataParams) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});
