import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import COLORS from 'shared/constants/COLORS';
import Z_INDEX from 'shared/constants/Z_INDEX';
import { getBorderOnError } from 'shared/helpers/input';

interface CSSProps {
  inModal?: boolean;
}

export default {
  Container: styled.div<any>`
    width: 100%;
    position: relative;

    .tox-tinymce {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      ${getBorderOnError};
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .tox .tox-toolbar--scrolling {
        flex-wrap: wrap;
      }

      .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
        border-right: none;
        width: 100%;
      }

      .tox-tbtn--select.tox-tbtn--bespoke {
        width: 100%;
        justify-content: space-between;
      }
    }
  `,

  Label: styled.div<CSSProps>`
    margin-left: ${(props) => (props.inModal ? '48px' : null)};

    & > div {
      font-size: 16px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-left: ${({ inModal }) => inModal && '0'};
    }
  `,

  Error: styled.div<CSSProps>`
    margin-top: 8px;
    margin-left: ${(props) => (props.inModal ? '48px' : null)};
  `,

  Count: styled.div`
    margin-top: 3px;
    font-size: 13px;
    line-height: 17px;
    text-align: right;
    color: rgba(0, 0, 0, 0.64);
  `,

  Helper: styled.p`
    margin: 0 0 2px;
    color: #5c5c5c;
    font-size: 14px;
  `,

  EditText: styled.div`
    width: 100%;
  `,

  SpinnerWrapper: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
  `,

  EditWrapper: styled.div`
    margin-top: 5px;
  `,

  EditorText: styled.div`
    color: ${COLORS.GRAY5C};
  `,

  QuillWrapper: styled.div``,

  ToolbarWrapper: styled.div``,

  HeadingSelect: styled.select`
    z-index: ${Z_INDEX.EDITOR_HEADING_SELECT};
  `,

  Option: styled.option``,

  Span: styled.span``,

  Button: styled.button``,

  ImgWrapper: styled.div`
    width: 10px;
  `,

  InputWrapper: styled.div`
    margin: 10px 0;
  `,

  FileInputWrapper: styled.div`
    margin-bottom: 10px;
  `,
};
