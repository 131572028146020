import React, { memo } from 'react';
import Button from '../../../Button';
import { Table } from '../../../Table';
import { ANNOUNCEMENTS_TYPES } from 'shared/constants/ANNOUNCEMENT_TYPES';
import { TICKET_TYPES } from 'shared/constants/TICKET_TYPES';
import Dropdown from '../../../Dropdown';
import TextOverflow from '../../../TextOverflow';
import Tooltip from '../../../Tooltip';
import { convertSeats } from 'shared/helpers/seats';
import Styled from './styles';
import PenIcon from '../../../../../static/icons/pen.svg';
import SplittedNumber from '../../../SplittedNumber';
import TableRow from '../../../Table/TableRow';
import { AnnouncementsVisibleScopeNames } from 'shared/constants/ANNOUNCEMENT';

const CELLS_PROPS = (isCertificate) => ({
  1: {
    width: { desktop: '2%', tablet: '100%' },
    tabletHeight: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
    tabletPadding: '0',
  },
  2: {
    width: { desktop: isCertificate ? '22%' : '8%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0 24px 0 0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  3: {
    width: { desktop: isCertificate ? '21%' : '8%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  4: {
    width: { desktop: '8%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  5: {
    width: { desktop: isCertificate ? '21%' : '8%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  6: {
    width: { desktop: '8%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  7: {
    width: { desktop: '13%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  8: {
    width: { desktop: isCertificate ? '30%' : '13%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  9: {
    width: { desktop: '13%', tablet: '100%' },
    tabletHeight: '0',
    padding: '2px 0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  10: {
    width: { desktop: '8%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  11: {
    width: { desktop: '7%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
  12: {
    width: { desktop: '4%', tablet: '100%' },
    tabletHeight: '0',
    tabletPadding: '0',
    justifyContent: 'flexStart',
    mobileAlign: 'flex-start',
  },
});

// eslint-disable-next-line
const Seats = memo(({ seats }: any) => {
  // eslint-disable-next-line
  const seatsArray = seats.split(',');

  return (
    <Tooltip text={convertSeats(seatsArray)}>
      <TextOverflow>{convertSeats(seatsArray)}</TextOverflow>
    </Tooltip>
  );
});

interface PreviewProps {
  announcements: any[];
  editAnnouncement: (_: any, id: number) => any;
  isCertificate: boolean;
}

const AnnouncementPreview: React.FC<PreviewProps> = ({ announcements, editAnnouncement, isCertificate }) => (
  <Styled.Container>
    <Table.Table>
      <Table.Head inModal>
        <Table.Cell {...CELLS_PROPS(isCertificate)[1]} heading />
        <Table.Cell {...CELLS_PROPS(isCertificate)[2]} heading>
          <Styled.TH>Тип</Styled.TH>
        </Table.Cell>
        <Table.Cell {...CELLS_PROPS(isCertificate)[3]} heading>
          <Styled.TH>Сектор</Styled.TH>
        </Table.Cell>
        {!isCertificate && (
          <Table.Cell {...CELLS_PROPS(isCertificate)[4]} heading>
            <Styled.TH>Ряд</Styled.TH>
          </Table.Cell>
        )}
        <Table.Cell {...CELLS_PROPS(isCertificate)[5]} heading>
          <Styled.TH>Места</Styled.TH>
        </Table.Cell>
        {!isCertificate && (
          <>
            <Table.Cell {...CELLS_PROPS(isCertificate)[6]} heading>
              <Styled.TH>Категория</Styled.TH>
            </Table.Cell>

            <Table.Cell {...CELLS_PROPS(isCertificate)[7]} heading>
              <Styled.TH>Закупочная</Styled.TH>
            </Table.Cell>
          </>
        )}
        <Table.Cell {...CELLS_PROPS(isCertificate)[8]} heading>
          <Styled.TH>Номинал</Styled.TH>
        </Table.Cell>
        {!isCertificate && (
          <>
            <Table.Cell {...CELLS_PROPS(isCertificate)[9]} heading>
              <Styled.TH>Цена</Styled.TH>
            </Table.Cell>
            <Table.Cell {...CELLS_PROPS(isCertificate)[10]} heading>
              <Styled.TH>Доп услуги</Styled.TH>
            </Table.Cell>
          </>
        )}
        <Table.Cell {...CELLS_PROPS(isCertificate)[11]} heading>
          <Styled.TH>Видимость</Styled.TH>
        </Table.Cell>
        <Table.Cell {...CELLS_PROPS(isCertificate)[12]} heading />
      </Table.Head>
      <Table.Body>
        {announcements.map((announcement, index) => {
          const isCategory = announcement.category;

          return (
            <TableRow noHover mobileBorder key={announcement.id} data-selenium="table-add-ads">
              <Table.Cell {...CELLS_PROPS(isCertificate)[1]} bold gray tabletHeight="0" tabletPadding="0">
                <Styled.MobileHide>{index + 1}</Styled.MobileHide>
              </Table.Cell>
              <Table.Cell {...CELLS_PROPS(isCertificate)[2]} fontSize="16px">
                <Table.Label desktopHide width="100px">
                  Тип
                </Table.Label>
                {isCertificate
                  ? 'В наличии, Абонемент'
                  : `${ANNOUNCEMENTS_TYPES[announcement.announcement_type]}, ${
                      TICKET_TYPES[announcement.ticket_type]
                    }`}
              </Table.Cell>
              <Table.Cell {...CELLS_PROPS(isCertificate)[3]} fontSize="16px">
                {announcement.sector && (
                  <>
                    <Table.Label desktopHide width="100px">
                      Сектор
                    </Table.Label>
                    <Tooltip text={announcement.sector.label}>
                      <TextOverflow>{announcement.sector.label}</TextOverflow>
                    </Tooltip>
                  </>
                )}
              </Table.Cell>
              {!isCertificate && (
                <Table.Cell {...CELLS_PROPS(isCertificate)[4]} fontSize="16px">
                  {announcement.row && (
                    <>
                      <Table.Label desktopHide width="100px">
                        Ряд
                      </Table.Label>
                      {announcement.row.label}
                    </>
                  )}
                </Table.Cell>
              )}
              <Table.Cell {...CELLS_PROPS(isCertificate)[5]} fontSize="16px">
                {announcement.seat && (
                  <>
                    <Table.Label desktopHide width="100px">
                      Места
                    </Table.Label>
                    <Seats seats={announcement.seat} />
                  </>
                )}
                {announcement.count > 0 && (
                  <>
                    <Table.Label desktopHide width="100px">
                      Кол-во:
                    </Table.Label>
                    <span>
                      <Styled.DesktopLabel>Кол-во:</Styled.DesktopLabel>
                      <SplittedNumber>{announcement.count}</SplittedNumber> шт
                    </span>
                  </>
                )}
                {announcement.count_categories > 0 && (
                  <>
                    <Table.Label desktopHide width="100px">
                      Кол-во:
                    </Table.Label>
                    <span>
                      <Styled.DesktopLabel>Кол-во:</Styled.DesktopLabel>
                      <SplittedNumber>{announcement.count_categories}</SplittedNumber> шт
                    </span>
                  </>
                )}
              </Table.Cell>
              {!isCertificate && (
                <>
                  <Table.Cell {...CELLS_PROPS(isCertificate)[6]} fontSize="16px">
                    {announcement.category && (
                      <>
                        <Table.Label desktopHide width="100px">
                          Категория
                        </Table.Label>
                        <Tooltip text={announcement.category.label}>
                          <TextOverflow>{announcement.category.label}</TextOverflow>
                        </Tooltip>
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell {...CELLS_PROPS(isCertificate)[7]} fontSize="16px">
                    <Table.Label desktopHide width="100px">
                      Закупочная
                    </Table.Label>
                    <SplittedNumber withSuffix icon={isCertificate ? null : announcement.currency.data.icon}>
                      {announcement.purchase_price}
                    </SplittedNumber>
                  </Table.Cell>
                </>
              )}
              <Table.Cell {...CELLS_PROPS(isCertificate)[8]} fontSize="16px">
                <Table.Label desktopHide width="100px">
                  Номинал
                </Table.Label>
                <SplittedNumber withSuffix icon={isCertificate ? null : announcement.currency.data.icon}>
                  {announcement.price}
                </SplittedNumber>
              </Table.Cell>
              {!isCertificate && (
                <>
                  <Table.Cell {...CELLS_PROPS(isCertificate)[9]} fontSize="16px">
                    <Table.Label desktopHide width="100px">
                      Цена
                    </Table.Label>
                    <SplittedNumber withSuffix icon={isCertificate ? null : announcement.currency.data.icon}>
                      {announcement.total_price}
                    </SplittedNumber>
                  </Table.Cell>
                  <Table.Cell {...CELLS_PROPS(isCertificate)[10]} fontSize="16px">
                    <Table.Label desktopHide width="93px">
                      Доп услуги
                    </Table.Label>
                    <Styled.ExpansionWrapper>
                      {announcement.expansion && announcement.expansion.length > 0 ? (
                        <Dropdown
                          readonly
                          maxHeight="180px"
                          options={announcement.expansion.map((expansion) => ({ label: expansion.label }))}
                        />
                      ) : (
                        <Styled.Expansion>Не выбрано</Styled.Expansion>
                      )}
                    </Styled.ExpansionWrapper>
                  </Table.Cell>
                </>
              )}
              <Table.Cell
                {...CELLS_PROPS(isCertificate)[11]}
                fontSize="16px"
                tabletHeight="0"
                tabletPadding="0"
              >
                <Table.Label desktopHide width="93px">
                  Видимость объявления
                </Table.Label>
                {isCategory && 'Категория'}
                {!isCategory && AnnouncementsVisibleScopeNames[announcement.view_scope_type]}
              </Table.Cell>
              <Table.Cell
                {...CELLS_PROPS(isCertificate)[12]}
                fontSize="16px"
                tabletHeight="0"
                tabletPadding="0"
              >
                <Styled.EditRow>
                  <Button onlySmallIcon transparent onClick={editAnnouncement} data={announcement.id}>
                    <PenIcon />
                  </Button>
                </Styled.EditRow>
              </Table.Cell>
            </TableRow>
          );
        })}
      </Table.Body>
    </Table.Table>
  </Styled.Container>
);

export default AnnouncementPreview;
