import React, { useState } from 'react';
import Form from '../../../Form';
import Tabs from '../../../Tabs';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { getSelectedTab } from 'shared/helpers/constructor';

const TABS = [
  {
    text: 'Список',
    key: 1,
  },
  {
    text: 'Карточки',
    key: 2,
  },
];

const LIST = [2, 3];
const CARDS = [1, 4];

const position = (item, index: number) => {
  const id = item.id || item;

  return (
    <Styled.Item grid width="50%" key={id} data-selenium={`variant-${index + 1}`}>
      <Variant name="ui_config_places" value={id} info={item.info}>
        <img src={`/static/constructor/places/${id}.jpg`} alt="" />
      </Variant>
    </Styled.Item>
  );
};

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const PlacesStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => {
  const [tab, setTab] = useState(getSelectedTab(data.ui_config_events, LIST));

  return (
    <Styled.Container withTabs>
      <Styled.Header withTabs>
        <Styled.Title>Выберите дизайн площадок</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Tabs tabs={TABS} onSelect={setTab} selectedTab={tab}>
          <Form
            onSubmit={onSubmit}
            initialValues={data}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Styled.ListWrapper>
                  <Styled.List grid>
                    {tab === 1 && (
                      <>
                        {LIST.slice(0, 3).map((item, index) => position(item, index))}
                        <Styled.Divider />
                        {LIST.slice(3, LIST.length).map((item, index) => position(item, index + 3))}
                      </>
                    )}
                    {tab === 2 && CARDS.map((card, index) => position(card, index))}
                  </Styled.List>
                </Styled.ListWrapper>
                <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
              </form>
            )}
          />
        </Tabs>
      </Styled.Content>
    </Styled.Container>
  );
};

export default PlacesStep;
