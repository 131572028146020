import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import uuid from 'uuid/v4';
import { NotificationManager } from 'react-notifications';
import { WIDGET_PATTERN_TYPES } from 'shared/constants/WIDGET';
import { findEmptyRules } from 'shared/helpers/query';
import Button from '../../../../Button';
import { formatData } from 'shared/helpers/widgets';
import Modal from '../../../../Modal';
import EventsLayout from '../../../Events/EventsLayout';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import Spinner from '../../../../Spinner';
import Styled from '../WidgetForm/styles';
import { DataContext } from './DataContext';
import PatternSelector from '../PatternSelector';
import WidgetQueryBuilder from './WidgetQueryBuilder';
import { DataContextProvider } from '../../SecondStep/DataContext';

const defaultQuery = (data) =>
  isEmpty(data.scope || (data.widget && data.widget.scope))
    ? { id: uuid(), rules: [], combinator: 'and' }
    : data.scope || data.widget.scope;

interface StepProps {
  data: any;
  onSubmit: (data: any) => any;
  isPreview?: boolean;
  goBack?: () => void;
  // eslint-disable-next-line no-undef
  backButton?: JSX.Element;
  withSubmit?: boolean;
  selectedDate?: any;
  eventDates?: any[];
  onChangeDate: any;
  isLoading?: boolean;
}

const WidgetSecondStep: React.FC<StepProps> = ({
  data,
  onSubmit,
  isPreview,
  goBack,
  backButton,
  selectedDate,
  eventDates,
  onChangeDate,
  withSubmit,
  isLoading,
}) => {
  const [query, setQuery] = useState<any>({});

  useEffect(() => {
    setQuery(defaultQuery(data));
  }, [data.scope || (data.widget && data.widget.scope)]);

  const submit = (submitType) => {
    const emptyRule = findEmptyRules(query.rules);

    if (emptyRule) {
      return NotificationManager.error('Заполните все поля', 'Ошибка');
    }

    return onSubmit({ scope: query, submit_action: submitType });
  };

  const handleChangePattern = ({ data: patternData }) => {
    setQuery(patternData.pattern);
  };

  const setDefaultQuery = () => {
    setQuery(defaultQuery(data));
  };

  const queryBuilder = (
    <DataContextProvider pricesRules={formatData(data)}>
      <WidgetQueryBuilder query={query} setQuery={setQuery} isPreview={isPreview} />
    </DataContextProvider>
  );

  return (
    <EventsLayout.Wrapper>
      <Styled.Wrapper>
        <Styled.Title>
          Выберите места, которые будут отображаться в виджете или выберите уже существующий шаблон для мест
          {!isPreview && (
            <PatternSelector
              defaultQuery={defaultQuery(data)}
              type={WIDGET_PATTERN_TYPES.FILTER}
              setDefaultQuery={setDefaultQuery}
              onChange={handleChangePattern}
              selectedDate={selectedDate}
              eventDates={eventDates}
              onChangeDate={onChangeDate}
            />
          )}
        </Styled.Title>
        {isLoading ? (
          <Spinner center />
        ) : (
          <>{data.eventDates ? <>{data ? queryBuilder : 'Выберите дату'}</> : queryBuilder}</>
        )}
      </Styled.Wrapper>
      <Modal.Footer fullSize justify>
        {backButton || (
          <Button transparent type="button" onClick={goBack} data-selenium="button-back">
            Назад
          </Button>
        )}
        {withSubmit && (
          <Button gray type="button" onClick={() => submit(SUBMIT_ACTIONS.SAVE)} data-selenium="button-save">
            Сохранить
          </Button>
        )}
        <Button type="button" onClick={() => submit(SUBMIT_ACTIONS.CONTINUE)} data-selenium="button-continue">
          Продолжить
        </Button>
      </Modal.Footer>
    </EventsLayout.Wrapper>
  );
};

// @ts-ignore
WidgetSecondStep.contextType = DataContext;

export default WidgetSecondStep;
