import styled, { css } from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

interface PreviewButtonProps {
  selected?: boolean;
}

export default {
  Container: styled.div`
    position: relative;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      & > div,
      & > p {
        width: calc(100% - 120px);
      }
    }
  `,

  Description: styled.p`
    margin: 16px 0 0;
    font-size: 14px;
    line-height: 18px;
    color: #5c5c5c;
  `,

  Preview: styled.svg`
    position: absolute;
    top: 0;
    left: calc(100% + 85px);
    width: 104px;
    height: 72px;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      left: auto;
      right: 0;
    }
  `,
  PreviewButton: styled.g<PreviewButtonProps>`
    opacity: 0.24;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    ${({ selected }) =>
      selected &&
      css`
        opacity: 1;

        path {
          fill: #2dc472;
        }
      `}
  `,
};
