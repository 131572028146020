import Link from 'components/Link';
import { i18n } from 'next-i18next';
import queryString from 'query-string';
import React from 'react';
import styled from 'styled-components';
import { getCurrentRouteWithNewQuery } from 'shared/helpers/routes';

interface ItemProps {
  item: any;
  modalType?: string;
  href?: (id: string | number) => string;
}

const StyledLink = styled(Link)<any>`
  padding-top: 8px;
  padding-bottom: 8px;
  word-break: break-all;
`;

const ReferenceRelatedItem: React.FC<ItemProps> = ({ item, href, modalType }) => {
  const query = {
    ...queryString.parse(window.location.search),
    modalType,
    modalId: item.id,
  };

  const linkProps = href
    ? {
        href: href(item.id),
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {
        href: getCurrentRouteWithNewQuery(query),
      };

  const info = item.info?.find((i: any) => i.language_code === i18n?.language);

  return (
    <StyledLink {...linkProps} prefetch={false}>
      {info?.title || item.name}
    </StyledLink>
  );
};

export default ReferenceRelatedItem;
