import React from 'react';
import EventIcon from '../../../static/icons/event.svg';
import EventsIcon from '../../../static/Sidebar/Events.svg';
import routes from 'shared/helpers/routes';
import { formatEventDateTime } from 'shared/helpers/formatters/date';
import SearchList from './SearchList';
import SearchStyled from './styles';

interface EventsProps {
  data: any[];
  meta: any;
  search: string;
  saveHistory: () => void;
  hideMenu: () => void;
}

const Events: React.FC<EventsProps> = ({ data, meta, search, saveHistory, hideMenu }) => {
  if (!data.length) return null;

  return (
    <SearchList
      length={data.length}
      meta={meta}
      icon={<EventsIcon />}
      title="Мероприятия"
      href={routes.searchEvents}
      saveHistory={saveHistory}
      hideMenu={hideMenu}
      search={search}
    >
      {data.map((event) => {
        const { date: dateStart, time: timeStart } = formatEventDateTime(event.date_start, event.time_start);

        return (
          <SearchStyled.Result key={event.id}>
            <SearchStyled.Link href={`${routes.events}?event=${event.id}`} onClick={saveHistory}>
              <SearchStyled.Row>
                <SearchStyled.Column croppedText>
                  <SearchStyled.Icon>
                    <EventIcon />
                  </SearchStyled.Icon>
                  <SearchStyled.Name>{event.title}</SearchStyled.Name>
                  <SearchStyled.Date>
                    {`${dateStart}, ${timeStart}`}
                    {event.place && (
                      <>
                        {' '}
                        | {event.place.name} - {event.place.address}
                      </>
                    )}
                  </SearchStyled.Date>
                </SearchStyled.Column>
              </SearchStyled.Row>
            </SearchStyled.Link>
          </SearchStyled.Result>
        );
      })}
    </SearchList>
  );
};

export default Events;
