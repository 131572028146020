import React from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Field, FormSpy } from 'react-final-form';

interface Props {
  field: string;
  becomes: string | number;
  set: string;
  to: string | number;
}

const WhenFieldChanges: React.FC<Props> = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={field}>
            {(value) => {
              if (value === becomes) {
                onChange(to);
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

export default WhenFieldChanges;
