import React from 'react';
import { Field } from 'react-final-form';
import { InputWithSelect, Input, Textarea } from '../../FormControls';
import Form from '../../Form';
import Modal from '../../Modal';
import { ModalLayout } from 'widgets/layouts/modal-layout';
import Styled from './styles';
import EmailIcon from '../../../../static/icons/email.svg';

interface FormProps {
  title: string;
  onSubmit?: (data: any) => any;
  onSuccess?: (data: any) => any;
  data: any;
  initialValues?: any;
}

const CompanyInfoForm: React.FC<FormProps> = ({ children, onSubmit, onSuccess, title, data, ...rest }) => (
  <Form
    onSubmit={onSubmit}
    onSuccess={onSuccess}
    {...rest}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Styled.GlobalStyles />
        <Modal.Title>{title}</Modal.Title>
        <div>
          <ModalLayout.Row>
            <ModalLayout.Sidebar>
              <ModalLayout.RowTitle>Основная иформация</ModalLayout.RowTitle>
              <ModalLayout.Description>Информация о компании, контактые данные</ModalLayout.Description>
            </ModalLayout.Sidebar>
            <ModalLayout.Content>
              <ModalLayout.FormRow>
                <ModalLayout.Field>
                  <Field name="name">
                    {({ input, meta }) => <Input label="Название" {...input} meta={meta} disabled />}
                  </Field>
                </ModalLayout.Field>
              </ModalLayout.FormRow>
              <ModalLayout.FormRow>
                <ModalLayout.Field>
                  <Field name="address">
                    {({ input, meta }) => <Input label="Адрес" {...input} meta={meta} disabled />}
                  </Field>
                </ModalLayout.Field>
              </ModalLayout.FormRow>
              <ModalLayout.FormRow>
                <ModalLayout.Field>
                  <Field name="description">
                    {({ input, meta }) => <Textarea label="Описание" {...input} meta={meta} disabled />}
                  </Field>
                </ModalLayout.Field>
              </ModalLayout.FormRow>
            </ModalLayout.Content>
          </ModalLayout.Row>
          {data.emails && (
            <ModalLayout.Row>
              <ModalLayout.Sidebar>
                <ModalLayout.RowTitle>Контакты</ModalLayout.RowTitle>
                <ModalLayout.Description>Почта и телефон</ModalLayout.Description>
              </ModalLayout.Sidebar>
              <ModalLayout.Content>
                {data.emails.map((item) => (
                  <ModalLayout.FormRow key={item.email}>
                    <ModalLayout.Field>
                      <Input value={item.email} icon={<EmailIcon opacity={0.4} />} disabled />
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                ))}
              </ModalLayout.Content>
            </ModalLayout.Row>
          )}
        </div>
        <Modal.Footer>{children}</Modal.Footer>
      </form>
    )}
  />
);

export default CompanyInfoForm;
