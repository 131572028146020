import React, { useState, useEffect, useMemo } from 'react';
import { IMarkupRulesWithQuery } from 'shared/api/markups';

export const DataContext = React.createContext(null);

interface Props {
  children: React.ReactNode;
  pricesRules?: { currency: string | null; markup: IMarkupRulesWithQuery[] };
  filterPlace?: {
    combinator?: string;
    created_at?: string;
    hall_id?: number;
    id: number;
    place_id?: number;
    hall_layout_id?: number;
    query_builder_id?: string;
    rules?: [];
    title?: string;
    updated_at?: string;
    filter_name?: string;
  };
}

interface SelectOption {
  label: string;
  value: number;
}
interface StateProps {
  currency?: string;
  markup: [];
  place?: SelectOption;
  hall?: SelectOption;
  hallLayouts?: SelectOption;
}

export interface IPlacesFilterState {
  combinator?: string;
  created_at?: string;
  hall_id?: number;
  id: number;
  place_id?: number;
  hall_layout_id?: number;
  query_builder_id?: string;
  rules?: [];
  title?: string;
  updated_at?: string;
  filter_name?: string;
}

export const DataContextProvider: React.FC<Props> = ({ children, pricesRules, filterPlace }) => {
  const [state, setState] = useState<StateProps | undefined>(pricesRules);
  const [placesFilter, setPlacesFilter] = useState<IPlacesFilterState | undefined>(filterPlace);

  useEffect(() => {
    setPlacesFilter(filterPlace);
  }, [filterPlace]);

  useEffect(() => {
    setState((prevState: any) => ({
      ...prevState,
      markup: pricesRules?.markup?.map((priceRule: { id: number }) => {
        const statePrices = prevState.markup.find(
          (stateMarkup: { id: number }) => stateMarkup.id === priceRule.id,
        );
        return statePrices ? statePrices : priceRule;
      }),
    }));
  }, [pricesRules?.markup && pricesRules?.markup[pricesRules.markup.length - 1]?.id]);

  const setPlace = (place: SelectOption, priceID: number) => {
    setState((prevState: any) => ({
      ...prevState,
      markup: prevState?.markup?.map((markupItem: { id: number }) =>
        markupItem.id === priceID ? { ...markupItem, place } : markupItem,
      ),
    }));
  };

  const setHall = (hall: SelectOption, priceID: number) => {
    setState((prevState: any) => ({
      ...prevState,

      markup: prevState?.markup?.map((markupItem: { id: number }) =>
        markupItem.id === priceID ? { ...markupItem, hall } : markupItem,
      ),
    }));
  };

  const setHallLayouts = (hallLayouts: SelectOption, priceID: number) => {
    setState((prevState: any) => ({
      ...prevState,

      markup: prevState?.markup?.map((markupItem: { id: number }) =>
        markupItem.id === priceID ? { ...markupItem, hallLayouts } : markupItem,
      ),
    }));
  };

  const setPlaceForPlacesFilter = (place: { label: string; value: number }) => {
    if (place) {
      return setPlacesFilter((prevState: any) => ({
        ...prevState,
        place,
      }));
    }
    setPlacesFilter((prevState: any) => ({
      ...prevState,
      place: { value: null, label: null, select: 'empty' },
    }));
  };

  const setHallForPlacesFilter = (hall: SelectOption) => {
    if (hall) {
      return setPlacesFilter((prevState: any) => ({
        ...prevState,
        hall,
      }));
    }
    setPlacesFilter((prevState: any) => ({
      ...prevState,
      hall: { value: null, label: null, select: 'empty' },
    }));
  };

  const setHallLayoutForPlacesFilter = (hallLayout: SelectOption) => {
    if (hallLayout) {
      return setPlacesFilter((prevState: any) => ({
        ...prevState,
        hallLayout,
      }));
    }
    setPlacesFilter((prevState: any) => ({
      ...prevState,

      hallLayout: { value: null, label: null, select: 'empty' },
    }));
  };

  const value = useMemo(
    () => ({
      ...state,
      placesFilter,
      setPlace,
      setHall,
      setHallLayouts,
      setPlaceForPlacesFilter,
      setHallForPlacesFilter,
      setHallLayoutForPlacesFilter,
    }),
    [
      state,
      setPlace,
      setHall,
      setHallLayouts,
      setPlaceForPlacesFilter,
      placesFilter,
      setHallForPlacesFilter,
      setHallLayoutForPlacesFilter,
    ],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
