import styled from 'styled-components';

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Time: styled.div`
    font-size: 14px;
  `,

  Name: styled.div`
    margin: 4px 0 6px 0;
    font-size: 18px;
  `,

  Info: styled.div`
    font-size: 14px;
    color: #5c5c5c;
  `,

  Form: styled.div`
    margin-top: 32px;
  `,

  FormRow: styled.div`
    display: flex;
    align-items: flex-end;
  `,

  Arrow: styled.div`
    width: 32px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  `,

  Warning: styled.div`
    margin-top: 32px;
    display: flex;
    color: #5c5c5c;

    & > svg {
      margin-top: 4px;
      flex-shrink: 0;
      margin-right: 8px;
    }
  `,
};
