import Can from 'containers/Can';
import debounce from 'lodash/debounce';
import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { connect } from 'react-redux';
import { cleanURL } from 'shared/helpers/routes';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Link from '../Link';
import Tooltip from '../Tooltip';
import Styled from './styles';

interface TabsProps {
  router: Router;
  tabs: {
    href?: string;
    // eslint-disable-next-line no-undef
    text: JSX.Element | string;
  }[];
  children?: any;
  fluid?: boolean;
  withMobile?: boolean;
  compareFullUrl?: boolean;
  sidebarIsOpen?: boolean;
  selectedTab?: number;
  onSelect?: (data: any) => void;
  alignCenter?: boolean;
  scrollToSelected?: boolean;
  onClick: () => void;
  highlighted?: boolean;
  disabled?: boolean;
}

class Tabs extends Component<TabsProps> {
  menuRef: React.RefObject<unknown>;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    fluid: false,
    children: null,
    withMobile: false,
    compareFullUrl: false,
    sidebarIsOpen: false,
    selectedTab: null,
    onSelect: () => {},
    alignCenter: false,
    scrollToSelected: true,
    onClick: () => {},
    highlighted: false,
  };

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = debounce(() => {
    this.forceUpdate();
  }, 500);

  get currentPath() {
    const {
      router: { asPath },
      compareFullUrl,
    } = this.props;

    return compareFullUrl ? asPath : cleanURL(asPath);
  }

  isDraggable = () => {
    const menu: any = this.menuRef.current;
    if (!menu) return false;

    const { allItemsWidth, menuWidth } = menu;
    const visibleMenuWidth = this.props.sidebarIsOpen ? menuWidth - 224 : menuWidth - 64;

    return allItemsWidth > visibleMenuWidth;
  };

  render() {
    const {
      tabs,
      selectedTab,
      children,
      fluid,
      withMobile,
      sidebarIsOpen,
      onSelect,
      alignCenter,
      scrollToSelected,
      onClick,
      highlighted,
      disabled,
      hideBorder,
    } = this.props;

    const data = tabs.map((item: any) => {
      const { text, href, as, permission, key, disabled: disabledItem, hasNestedRoutes } = item;
      let isActive = key
        ? key === selectedTab
        : this.currentPath === cleanURL(href) || (hasNestedRoutes && this.currentPath.indexOf(href) === 0);

      if (as) {
        isActive = key ? key === selectedTab : this.currentPath === as;
      }

      const handleClick = () => {
        if (disabledItem) return;
        onSelect(key);
      };

      const handleClickLink = () => {
        if (!isActive) onClick();
      };

      let tab = key ? (
        <Tooltip text={item.tooltip}>
          <Styled.Tab key={key} active={isActive} onClick={handleClick} disabled={disabledItem}>
            {text}
          </Styled.Tab>
        </Tooltip>
      ) : (
        <Link as={as} href={href} key={as || href} onClick={handleClickLink}>
          <Tooltip text={item.tooltip}>
            <Styled.Tab active={isActive}>{text}</Styled.Tab>
          </Tooltip>
        </Link>
      );

      if (isActive) {
        tab = (
          <Styled.Tab active={isActive} key={as || href}>
            {text}
          </Styled.Tab>
        );
      }

      if (permission) {
        return (
          <Can permission={permission} key={key || as || href}>
            {tab}
          </Can>
        );
      }

      return tab;
    });

    return (
      <>
        <Styled.GlobalStyles />
        <Styled.Container disabled={disabled} fluid={fluid} withMobile={withMobile} highlighted={highlighted}>
          <Styled.HorizontalTabs
            hideBorder={hideBorder}
            sidebarIsOpen={sidebarIsOpen}
            className="horizontal-tabs"
          >
            <ScrollMenu
              selected={this.currentPath}
              data={data}
              dragging={this.isDraggable()}
              alignCenter={alignCenter}
              hideArrows
              scrollToSelected={scrollToSelected}
              // @ts-ignore
              ref={this.menuRef}
            />
          </Styled.HorizontalTabs>
          {children}
        </Styled.Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarIsOpen: state.layout.sidebarIsOpen,
});

export default withCheckIsMobile(withRouter(connect(mapStateToProps)(Tabs)));
