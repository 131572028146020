import Button from 'components/Button';
import { LangForm } from 'components/Form';
import { Input, FormRow, InputWithSelect } from 'components/FormControls';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Field } from 'react-final-form';
import { Editor } from 'entities/editor';
import { FormSubmitData } from 'shared/api/reference/halls';
import { referencePlaceSources } from 'shared/api/reference/places';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderReferencePlaces } from 'shared/helpers/optionsRenderers';
import {
  composeValidators,
  required,
  minValue,
  maxValue,
  landingDescriptionValidation,
} from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Styled from 'shared/ui/reference/modal-styles';

interface FormProps {
  isMobile?: boolean;
  title: string;
  isEdit?: boolean;
  onChangeLng?: (lng: string) => void;
  onSubmit?: (data: FormSubmitData) => void;
  isSubmitting?: boolean;
  stashLanguages?: boolean;
  initialValues?: any;
  closeModal: () => void;
  openDeleteForm?: () => void;
}

const Form: React.FC<FormProps> = ({
  title,
  // openDeleteForm,
  isSubmitting,
  closeModal,
  onChangeLng,
  isMobile,
  isEdit,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <LangForm
      {...rest}
      withoutNotification
      onChangeLng={onChangeLng}
      render={({ handleSubmit, switcher, i18n }) => (
        <form onSubmit={handleSubmit}>
          {isMobile ? (
            <Styled.Header>
              <StepModal.Title>{t(title)}</StepModal.Title>
            </Styled.Header>
          ) : (
            <Modal.Title>{t(title)}</Modal.Title>
          )}
          <Styled.Content>
            <FormRow>{switcher}</FormRow>
            <Field name={`${i18n.language}.id`} component="input" type="hidden" />
            {i18n.language !== LANGUAGES.RU ? null : (
              <FormRow>
                <Field name={`${i18n.language}.place_id`} validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      route={referencePlaceSources}
                      optionsRenderer={renderReferencePlaces}
                      query={{
                        language_code: i18n.language,
                      }}
                      label={t('references:halls.place')}
                      meta={meta}
                      searchQueryName="search_string"
                      {...input}
                    />
                  )}
                </Field>
              </FormRow>
            )}
            <FormRow>
              <Field
                name={`${i18n.language}.title`}
                validate={composeValidators(required, minValue(3), maxValue(100))}
              >
                {({ input, meta }) => <Input label={t('references:halls.name')} {...input} meta={meta} />}
              </Field>
            </FormRow>

            <Styled.Editor>
              <Field name={`${i18n.language}.description`} validate={landingDescriptionValidation}>
                {({ input, meta }) => (
                  <Editor
                    id="hall_1"
                    key={rest.initialValues?.description || i18n.language}
                    label={t('references:halls.description')}
                    inModal
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
            </Styled.Editor>
            <Styled.FooterContainer>
              <Modal.Footer>
                {isEdit ? (
                  <>
                    <Button type="button" onClick={closeModal} transparent>
                      {t('forms:cancel')}
                    </Button>
                    {/* <Button danger onClick={openDeleteForm} type="button">
                      {t('forms:delete')}
                    </Button> */}
                    <Button disabled={isSubmitting}>{t('forms:save')}</Button>
                  </>
                ) : (
                  <>
                    {isMobile && (
                      <Button transparent onClick={closeModal}>
                        {t('forms:cancel')}
                      </Button>
                    )}
                    <Button disabled={isSubmitting}>{t('forms:create')}</Button>
                  </>
                )}
              </Modal.Footer>
            </Styled.FooterContainer>
          </Styled.Content>
        </form>
      )}
    />
  );
};

export const HallForm = withCheckIsMobile(Form);
