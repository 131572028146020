import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import get from 'lodash/get';
import tables from 'config/tables';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import Form from '../../Form';
import { ANNOUNCEMENT_STATUS, ANNOUNCEMENT_GLASS_STATUS } from 'shared/constants/ANNOUNCEMENT_STATUS';
import Button from '../../Button';
import Tooltip from '../../Tooltip';
import QuestionIcon from '../../../../static/icons/question.svg';
import preventClick from 'shared/helpers/preventClick';
import { getIdsError } from 'shared/helpers/tables';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  updateAnnouncementsStatus: ({ ids, status }: { ids: (string | number)[]; status: string | number }) => any;
  setRowsErrors: ({ name, errors }: { name: string; errors: any }) => any;
  isMobile?: boolean;
}

const AnnouncementStatusEditModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    updateAnnouncementsStatus,
    setRowsErrors,
    data: { ids: selectedRows, modalData, name, page },
    isMobile,
  } = props;
  const { errorText } = tables[page];

  const onSubmit = ({ status }) =>
    updateAnnouncementsStatus({
      ids: selectedRows,
      status,
    }).then((response) => {
      if (response.error) {
        const ids = get(response, 'error.response.data.error');

        setRowsErrors({
          name,
          errors: getIdsError({ ids, page }),
        });

        return NotificationManager.error(errorText, 'Ошибка');
      }

      closeModal();

      modalData.onModalSubmit();

      return response;
    });

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title isSmall className="modal__title">
        Редактирование статуса
      </Modal.Title>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values: formValues, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Styled.OptionsList>
              {ANNOUNCEMENT_STATUS.filter((item) => item.value !== ANNOUNCEMENT_GLASS_STATUS).map((item) => (
                <Field key={item.value} name="status" type="radio" value={item.value}>
                  {({ input }) => (
                    <Styled.Option>
                      <Styled.Input type="radio" {...input} />
                      <Styled.Radio
                        color={item.color}
                        isChecked={input.value === Number(formValues.status)}
                      />
                      {item.label}
                      <Styled.Info>
                        <Tooltip text={item.tooltip} placement={isMobile ? 'left' : 'top'}>
                          <Button transparent onlyIcon onClick={preventClick} type="button">
                            <QuestionIcon />
                          </Button>
                        </Tooltip>
                      </Styled.Info>
                    </Styled.Option>
                  )}
                </Field>
              ))}
            </Styled.OptionsList>
            <Modal.Footer mobileRow className="modal__footer">
              <Button type="button" transparent onClick={closeModal}>
                Отмена
              </Button>
              <Button disabled={!formValues.status || pristine}>Готово</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default AnnouncementStatusEditModal;
