import styled, { css } from 'styled-components';
import { scrollbar } from '../../../app/styles/scrollbar';
import stylesFlagsPosition from './styles-flags-position';
import { getBorderOnError } from 'shared/helpers/input';

interface ContainerProps {
  hasValue?: boolean;
  disabled?: boolean;
  meta: any;
}

export default {
  Container: styled.div<ContainerProps>`
    position: relative;
    width: 100%;

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;

        & input {
          pointer-events: none;
        }

        & .flag-dropdown {
          pointer-events: none;
        }
      `}

    .react-tel-input {
      position: relative;
      width: 100%;
    }

    .react-tel-input :disabled {
      cursor: not-allowed;
    }

    .react-tel-input .flag {
      width: 16px;
      height: 11px;
      background: url('/static/flags.png');
      pointer-events: none;
    }

    .react-tel-input * {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
    }
    .react-tel-input .hide {
      display: none;
    }
    .react-tel-input .v-hide {
      visibility: hidden;
    }
    .react-tel-input .form-control {
      width: 100%;
      position: relative;
      z-index: 0;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-left: 40px;
      margin-left: 0;
      background: #fff;
      border: 1px solid #e1e1e1;
      border-radius: 8px;
      height: 40px;
      outline: none;

      &:focus {
        border: 2px solid #2f80ed;
        padding-left: 39px;
      }

      &::placeholder {
        color: #5c5c5c;
      }

      ${getBorderOnError}
    }
    .react-tel-input .form-control.invalid-number {
      border: 1px solid #d79f9f;
      background-color: #faf0f0;
      border-left-color: #cacaca;
    }
    .react-tel-input .form-control.invalid-number:focus {
      border: 1px solid #d79f9f;
      border-left-color: #cacaca;
      background-color: #faf0f0;
    }
    .react-tel-input .form-control.open {
      z-index: 2;
    }
    .react-tel-input .flag-dropdown {
      outline: none;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 6px;
      bottom: 0;
      padding: 0;
      height: 36px;
    }
    .react-tel-input .flag-dropdown.open {
      z-index: 2;
    }

    .react-tel-input .flag-dropdown.open .selected-flag {
      background: #e1e1e1;
    }

    .react-tel-input .selected-flag {
      position: relative;
      width: 38px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 6px 0 0 6px;
      padding-left: 5px;

      pointer-events: ${({ hasValue }) => !hasValue && 'none'};

      &:hover {
        background-color: #f4f4f4;
      }
    }

    .react-tel-input .selected-flag .flag {
      position: absolute;
      top: 50%;
      margin-top: -5px;
    }

    .react-tel-input .selected-flag .arrow {
      position: absolute;
      right: -12px;
      top: 4px;
      width: 8px;
      height: 4px;
      background-image: url('/static/icons/arrow.svg');
      background-size: 8px 4px;
      opacity: 0.5;
      transition: all 0.2s ease-in-out;

      display: ${({ hasValue, disabled }) => (!hasValue || disabled) && 'none'};
    }

    .react-tel-input .selected-flag .arrow.up {
      transform: rotate(180deg);
    }

    .react-tel-input .country-list {
      z-index: 1;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 0 0 10px -1px;
      background-color: white;
      width: 280px;
      max-height: 210px;
      overflow-y: scroll;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      ${scrollbar};
    }
    .react-tel-input .country-list--top {
      bottom: 100%;
      border-radius: 3px 3px 0 0;
    }
    .react-tel-input .country-list .flag {
      display: inline-block;
    }
    .react-tel-input .country-list .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }
    .react-tel-input .country-list .country {
      padding: 6px 12px;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #f4f4f4;
      }
    }

    .react-tel-input .country-list .country .dial-code {
      color: #5c5c5c;
    }

    .react-tel-input .country-list .country.highlight {
      background-color: #f1f1f1;
    }
    .react-tel-input .country-list .flag {
      margin-right: 7px;
      margin-top: 2px;
    }
    .react-tel-input .country-list .country-name {
      margin-right: 6px;
    }
    .react-tel-input .country-list .search {
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 10px 0 6px 10px;
    }
    .react-tel-input .country-list .search-emoji {
      font-size: 15px;
    }
    .react-tel-input .country-list .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;
    }
    .react-tel-input .country-list .no-entries-message {
      padding: 7px 10px 11px;
      opacity: 0.7;
    }

    .react-tel-input .search-box {
      height: 32px;
      margin: 0 !important;
      padding: 1px 10px 0 10px !important;
      box-sizing: border-box;
      width: 97%;
      background: #fff;
      outline: none;
      font-size: 16px;
      color: #000;
      transition: all 0.2s ease-in-out;
      background-repeat: no-repeat;

      border-style: solid;
      border-width: 2px;
      border-color: #e1e1e1;
      border-radius: 8px;

      &:not(:disabled):hover {
        border-color: #000;
      }

      &:focus:not(:read-only) {
        border-color: #2f80ed !important;
      }
    }

    .react-tel-input .search-box {
    }

    ${stylesFlagsPosition};
  `,

  PhoneIcon: styled.div`
    position: absolute;
    top: 11px;
    left: 15px;
    z-index: 3;
    opacity: 0.4;
    pointer-events: none;
  `,

  Clear: styled.div<any>`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    opacity: 0.4;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  `,
};
