import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  hasName?: boolean;
  mobileFluid?: boolean;
  link?: boolean;
}

export default {
  Wrapper: styled.div`
    padding: 0 40px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: 0 16px;
    }
  `,

  Header: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 72px;
    padding: 0 40px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: 0 16px;
      border-bottom: 1px solid #e1e1e1;
    }
  `,

  PlaceholderContainer: styled.div`
    height: 21px;
    min-width: 200px;
  `,

  HeadingContainer: styled.div`
    max-width: 296px;
    width: 100%;
    display: flex;
  `,

  HeaderControls: styled.div`
    display: flex;
    margin-left: auto;

    & > div:first-child {
      width: 360px;
    }

    & > button {
      margin-left: auto;
    }
  `,

  Buttons: styled.div`
    display: flex;
    margin-left: auto;

    & > *:not(:first-child) {
      margin-left: 8px;
    }
  `,

  Title: styled.h1<CSSProps>`
    font-size: 32px;
    line-height: 41px;
    opacity: 0.8;

    @media (max-width: ${BREAKPOINTS.MD}) {
      ${({ hasName }) =>
        hasName &&
        css`
          font-size: 20px;
          line-height: 26px;
        `}
    }
  `,

  Main: styled.div`
    position: relative;
    z-index: 1;
    padding-top: 86px;
    display: flex;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding-top: 24px;
    }
  `,

  MainWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    box-sizing: border-box;
  `,

  Limit: styled.div`
    transform: translateY(-32px);
    align-self: flex-end;
  `,

  DesktopContainer: styled.div`
    width: 100%;
    max-width: 296px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  MobileContainer: styled.div<CSSProps>`
    position: relative;
    display: none;
    order: 1;
    max-width: calc(100% - 70px);
    padding-left: ${({ hasName }) => hasName && '25px'};

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: block;
    }
  `,

  SearchForm: styled.div`
    display: flex;
    margin-right: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;

      & > form {
        width: 100%;
      }
    }
  `,

  Count: styled.p`
    font-size: 16px;
    line-height: 21px;
    opacity: 0.64;
  `,

  Back: styled.span`
    position: absolute;
    z-index: 1;
    top: 0;
    left: -10px;
    display: block;
    padding: 5px 10px;
    margin: 0;
    border: 0;
    background: none;
    opacity: 0.4;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }
  `,

  List: styled.ul<CSSProps>`
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 4px;
    }

    .data-preview__message {
      position: absolute;
      top: 35vh !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      ${({ mobileFluid }) =>
        mobileFluid &&
        css`
          margin: 0 -16px;

          & > div:not(:last-child) {
            margin-bottom: 0;
          }
        `}
    }
  `,

  ListWrapper: styled.div`
    margin: 0 -8px;
    margin-bottom: 64px;
    flex-shrink: 0;

    .data-preview__message {
      top: 35vh !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-top: 16px;
    }
  `,

  Item: styled.li`
    display: block;
    width: 280px;
    padding: 0 8px;
    margin-bottom: 16px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      box-sizing: border-box;
      width: 50%;
    }

    @media (max-width: ${BREAKPOINTS.LG}) {
      width: 100%;
    }
  `,

  Content: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: -32px;
  `,

  SpinnerOverlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Nav: styled.div`
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    margin-top: 32px;
    margin-bottom: 32px;
  `,

  Info: styled.div<CSSProps>`
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
    }

    & > span {
      font-size: 14px;
      color: #000;
    }

    & > a {
      font-size: 14px;
      color: #2f80ed;
    }

    /* link */
    white-space: ${({ link }) => link && 'nowrap'};
    overflow: ${({ link }) => link && 'hidden'};

    @media (max-width: ${BREAKPOINTS.MD}) {
      white-space: initial;
    }
  `,

  Description: styled.div`
    font-size: 14px;
    color: #474747;
    overflow: hidden;
    word-break: break-all;
  `,

  Actions: styled.div`
    display: flex;
    align-items: center;
  `,

  CountLabel: styled.span`
    color: #5c5c5c;
  `,

  TableIcon: styled.div`
    & > svg {
      width: 14px;
      height: 14px;
    }
  `,
};
