import styled from 'styled-components';

export default {
  Container: styled.div``,

  Inputs: styled.div`
    display: flex;
    align-items: center;
    margin: 0 -4px 16px;
  `,

  Input: styled.div`
    position: relative;
    margin: 0 4px;
  `,

  Sign: styled.div`
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  `,

  Icon: styled.img`
    max-height: 12px;
    width: auto;
  `,

  Range: styled.div`
    margin: 0 12px;
  `,
};
