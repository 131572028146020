import { RadioGroup, Input } from 'components/FormControls';
import { FormApi } from 'final-form';
import { trim } from 'lodash';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { fetchBundlesSlugs } from '../../api/bundles';
import { LANGUAGES } from '../../constants/LANGUAGES';
import { composeValidators, required, validBundleSlug, validSLugChars } from '../../helpers/validations';
import { SLUG_TYPES } from './config/index';
import Styled from './styles';

const SLUG_CREATE_TYPES = [
  {
    id: SLUG_TYPES.TRANSLITERATE,
    value: SLUG_TYPES.TRANSLITERATE,
    name: 'Транслитерация',
  },
  {
    id: SLUG_TYPES.ENGLISH,
    value: SLUG_TYPES.ENGLISH,
    name: 'Английский язык',
  },
];

const simpleMemoize = (fn: any) => {
  let lastArg: string | null = null;
  let lastResult: string;
  return (arg: string) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

interface SlugProps {
  isEdit?: boolean;
  form: FormApi & { errors: FormRenderProps['errors'] };
  values: FormRenderProps['values'];
  createSlug: () => Promise<void>;
  bundles?: boolean;
}

export const SlugGenerate: React.FC<SlugProps> = (props) => {
  const { isEdit, values, bundles, createSlug, form } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language || LANGUAGES.RU;

  const validateBundleSlugField = simpleMemoize(async (slug: string) => {
    if (!trim(slug)) return t('forms:validations.required');
    if (bundles) {
      const response = await fetchBundlesSlugs({ search_string: slug });
      if (!isEdit && !response.is_valid) {
        return `${t('bundles:validations.slug')} ${response.exist_slugs.join(', ')}`;
      }
    }
  });

  const requiredSlugSource = (value: string) => {
    if (bundles ? values.slug : values[lang]?.slug) return undefined;
    return required(value);
  };

  return (
    <>
      {!isEdit && (
        <>
          <Styled.Field>
            <Field name="slug_source" validate={composeValidators(requiredSlugSource, validBundleSlug())}>
              {({ input, meta }) => <Input label="Поле для слага" {...input} meta={meta} disabled={isEdit} />}
            </Field>
          </Styled.Field>
          <Styled.Field>
            <Field name="slug_type">
              {({ input, meta }) => (
                <RadioGroup
                  isNumber
                  defaultValue={SLUG_CREATE_TYPES[0]}
                  buttons={SLUG_CREATE_TYPES}
                  {...input}
                  meta={meta}
                  disabled={!values.slug_source}
                />
              )}
            </Field>
          </Styled.Field>
        </>
      )}
      <Styled.Field>
        <Field
          name={bundles ? 'slug' : `${i18n.language}.slug`}
          validate={composeValidators(validSLugChars(), validateBundleSlugField)}
        >
          {({ input, meta }) => (
            <Input
              label="Слаг"
              {...input}
              meta={{ ...meta, ...(values.slug && { touched: true }) }}
              disabled={isEdit || !values.slug}
            />
          )}
        </Field>
      </Styled.Field>
      {!isEdit && (
        <Styled.Field>
          <Styled.GenerateSlugButton
            type="button"
            onClick={createSlug}
            disabled={!values.slug_source || Boolean(form.errors?.slug_source)}
          >
            Принять
          </Styled.GenerateSlugButton>
        </Styled.Field>
      )}
    </>
  );
};
