import React, { useState } from 'react';
import Styled from './styles';
import InputWithSelect from '../InputWithSelect';

const OPTIONS = {
  TOP_LEFT: { value: 'TOP_LEFT', label: 'Верх Левый' },
  TOP_CENTER: { value: 'TOP_CENTER', label: 'Верх Центр' },
  TOP_RIGHT: { value: 'TOP_RIGHT', label: 'Верх Правый' },
  MIDDLE_LEFT: { value: 'MIDDLE_LEFT', label: 'Середина Левый' },
  MIDDLE_CENTER: { value: 'MIDDLE_CENTER', label: 'Середина Центр' },
  MIDDLE_RIGHT: { value: 'MIDDLE_RIGHT', label: 'Середина Правый' },
};

const OPTIONS_LIST = [
  OPTIONS.TOP_LEFT,
  OPTIONS.TOP_CENTER,
  OPTIONS.TOP_RIGHT,
  OPTIONS.MIDDLE_LEFT,
  OPTIONS.MIDDLE_CENTER,
  OPTIONS.MIDDLE_RIGHT,
];

interface Props {
  input: any;
  meta: any;
}

const ImagePositionSelect: React.FC<Props> = ({ input, meta }) => {
  const [state, setState] = useState(OPTIONS[input.value] || OPTIONS.MIDDLE_CENTER);

  const handleChange = (data) => {
    setState(data);
    return input.onChange(data.value);
  };

  return (
    <Styled.Container>
      <Styled.Preview width="104" height="72" viewBox="0 0 104 72" fill="none">
        <rect width="104" height="72" rx="1" fill="#F5F5F5" />
        <path
          d="M19.6018 9C13.5058 9 9 13.7747 9 19.6105C9 25.7116 13.5058 30.2211 19.6018 
          30.2211C25.4328 30.2211 30.2037 25.7116 30.2037 19.6105C30.2037 13.7747 25.4328 
          9 19.6018 9ZM11.1204 62.0526H95.935V40.8316L72.611 17.7537C71.2857 16.4274 
          69.4304 16.4274 68.1052 17.7537L36.5648 49.32L25.9629 38.9747C24.6377 
          37.6484 22.7824 37.6484 21.4571 38.9747L11.1204 49.32V62.0526Z"
          fill="#FAFAFA"
        />
        <Styled.PreviewButton
          id="position-top-center"
          selected={state.value === OPTIONS.TOP_CENTER.value}
          onClick={() => handleChange(OPTIONS.TOP_CENTER)}
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M60 0L44 6.99382e-07L44 2L60 2L60 0Z" fill="black" />
          <path
            d="M52.8705 5.15277L56.8455 9.10956C57.1756 9.43816 56.9418 10 56.4749 
            10H48.5251C48.0582 10 47.8244 9.43816 48.1545 9.10957L52.1295 
            5.15278C52.3341 4.94908 52.6659 4.94908 52.8705 5.15277Z"
            fill="black"
          />
        </Styled.PreviewButton>
        <Styled.PreviewButton
          id="position-middle-left"
          selected={state.value === OPTIONS.MIDDLE_LEFT.value}
          onClick={() => handleChange(OPTIONS.MIDDLE_LEFT)}
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M0 44V28H2V44H0Z" fill="black" />
          <path
            d="M5.15277 36.1295L9.10956 32.1545C9.43816 31.8244 10 32.0582 10 
            32.525V40.4749C10 40.9418 9.43816 41.1756 9.10956 40.8455L5.15277 
            36.8705C4.94908 36.6659 4.94907 36.3341 5.15277 36.1295Z"
            fill="black"
          />
        </Styled.PreviewButton>
        <Styled.PreviewButton
          id="position-middle-right"
          selected={state.value === OPTIONS.MIDDLE_RIGHT.value}
          onClick={() => handleChange(OPTIONS.MIDDLE_RIGHT)}
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M102 44L102 28L104 28L104 44H102Z" fill="black" />
          <path
            d="M98.8472 36.1295L94.8904 32.1545C94.5618 31.8244 94 32.0582 94 
            32.525L94 40.4749C94 40.9418 94.5618 41.1756 94.8904 40.8455L98.8472 
            36.8705C99.0509 36.6659 99.0509 36.3341 98.8472 36.1295Z"
            fill="black"
          />
        </Styled.PreviewButton>
        <Styled.PreviewButton
          id="position-top-left"
          selected={state.value === OPTIONS.TOP_LEFT.value}
          onClick={() => handleChange(OPTIONS.TOP_LEFT)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 0L12 5.24537e-07L12 2L2 2L2 12H0L4.80824e-07 1C5.04965e-07 
            0.447715 0.447715 -2.41412e-08 1 0Z"
            fill="black"
          />
          <path
            d="M5.53684 5.01284L11.1454 5C11.6112 4.99894 11.8432 5.56153 11.5131 5.89164L5.89163 
            11.5131C5.56153 11.8432 4.99893 11.6112 5 11.1454L5.01284 5.53684C5.0135 5.24811 5.24811 
            5.0135 5.53684 5.01284Z"
            fill="black"
          />
        </Styled.PreviewButton>
        <Styled.PreviewButton
          id="position-top-right"
          selected={state.value === OPTIONS.TOP_RIGHT.value}
          onClick={() => handleChange(OPTIONS.TOP_RIGHT)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M103 0L92 5.24537e-07L92 2L102 2L102 12H104L104 1C104 0.447715 103.552 -2.41412e-08 103 0Z"
            fill="black"
          />
          <path
            d="M98.4632 5.01284L92.8546 5C92.3888 4.99894 92.1568 5.56153 92.4869 5.89164L98.1084 
            11.5131C98.4385 11.8432 99.0011 11.6112 99 11.1454L98.9872 5.53684C98.9865 5.24811 
            98.7519 5.0135 98.4632 5.01284Z"
            fill="black"
          />
        </Styled.PreviewButton>
        <Styled.PreviewButton
          id="position-middle-center"
          selected={state.value === OPTIONS.MIDDLE_CENTER.value}
          onClick={() => handleChange(OPTIONS.MIDDLE_CENTER)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51 35V30H53V35H58V37H53V42H51V37H46V35H51Z"
            fill="black"
          />
        </Styled.PreviewButton>
      </Styled.Preview>
      <InputWithSelect
        {...input}
        meta={meta}
        value={state}
        isClearable={false}
        label="Размещение"
        options={OPTIONS_LIST}
        onChange={handleChange}
        id="position-select"
      />
      <Styled.Description>Привязка картинки к области при изменении размеров</Styled.Description>
    </Styled.Container>
  );
};

export default ImagePositionSelect;
