import Button from 'components/Button';
import { PlainTable } from 'components/Table';
import permissions from 'config/permissions';
import Can from 'containers/Can';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { Hall } from 'shared/api/reference/halls';
import { findTranslation } from 'shared/helpers/translations';
import { useToggler } from 'shared/lib/toggler';
import EyeIcon from '../../../../../../static/icons/eye.svg';
import PencilAltIcon from '../../../../../../static/icons/pencil-alt.svg';
import { updateHallModal } from '../update-modal';
import { viewHallModal } from '../view-modal';
import Styled from './styles';

interface ItemProps {
  data: Hall;
}

export const HallListItem: React.FC<ItemProps> = ({ data }) => {
  const { open: openUpdateModal } = useToggler(updateHallModal.toggler);
  const { open: openViewModal } = useToggler(viewHallModal.toggler);
  const { i18n } = useTranslation();
  const info = findTranslation(data.info, i18n.language);
  const placeLocation = data.place && findTranslation(data.place.location.info, i18n.language);

  const handleUpdateModal = () => openUpdateModal(data.id);
  const handleViewModal = () => openViewModal(data.id);

  return (
    <PlainTable.Tr mobileFlexRow>
      <PlainTable.Td verticalAlign="middle" mobileWidth="80%" tabletAutoHeight mobileOrder={1} mobileBold>
        {info?.title}
      </PlainTable.Td>
      <PlainTable.Td
        verticalAlign="middle"
        mobileWidth="100%"
        mobilePadding="0 24px 5px"
        tabletAutoHeight
        mobileOrder={3}
      >
        {placeLocation?.title}
      </PlainTable.Td>
      <PlainTable.Td
        verticalAlign="middle"
        mobildeAlignItems="center"
        mobileWidth="100%"
        mobilePadding="0 24px 5px"
        tabletAutoHeight
        mobileOrder={4}
      >
        {data.place && data.place.title}
      </PlainTable.Td>
      <PlainTable.Td
        width="100px"
        verticalAlign="middle"
        mobileWidth="20%"
        mobildeAlignItems="center"
        mobileDisplay="flex"
        tabletAutoHeight
        mobileOrder={2}
        mobilePadding="0 24px 0 0"
      >
        <Styled.ButtonWrapper>
          <Button transparent onlyIcon onClick={handleViewModal} data-selenium={info?.title.concat('-view')}>
            <EyeIcon />
          </Button>
          <Can permission={permissions.reference}>
            <Button transparent onlyIcon onClick={handleUpdateModal} data-selenium={info?.title}>
              <ReferenceLayout.TableIcon>
                <PencilAltIcon />
              </ReferenceLayout.TableIcon>
            </Button>
          </Can>
        </Styled.ButtonWrapper>
      </PlainTable.Td>
    </PlainTable.Tr>
  );
};
