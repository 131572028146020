const DELIVERY_TYPES = {
  CDEK: 10,
  PECOM: 20,
  PONY: 30,
  COURIER: 40,
  SELF_DELIVERY: 50,
  SEND_EMAIL: 70,
};

const DELIVERY_TYPES_LABELS = {
  [DELIVERY_TYPES.COURIER]: 'Курьер',
  [DELIVERY_TYPES.SELF_DELIVERY]: 'Самовывоз',
  [DELIVERY_TYPES.SEND_EMAIL]: 'Email',
};

export { DELIVERY_TYPES_LABELS };

export default DELIVERY_TYPES;
