import omit from 'lodash/omit';
import React from 'react';
import SortIcon from '../../../../static/icons/sort-neutral.svg';
import Error from '../Error';
import Styled from './styles';

const TYPES = {
  default: Styled.Checkmark,
  round: Styled.RoundCheckmark,
  gray: Styled.Checkmark,
  select: Styled.Select,
};

const CHECKBOX_PROPS = {
  default: {
    type: 'default',
  },
  gray: { type: 'gray' },
};

interface CheckboxProps {
  rtl?: boolean;
  plain?: boolean;
  variant?: 'default' | 'select' | 'gray' | 'round';
  meta?: any;
  isMulti?: boolean;
  disabled?: boolean;
  subText?: string;
  name?: string;
  children?: any;
  selectAll?: boolean;
  checked?: boolean;
  onChange?: (event: any) => void;
  defaultChecked?: boolean;
  readOnly?: boolean;
  mobileNormal?: boolean;
  value?: any;
  fullWidth?: boolean;
}

const Checkbox = ({
  fullWidth = false,
  meta = {},
  isMulti = false,
  variant = 'default',
  children = null,
  subText,
  rtl = false,
  plain = false,
  disabled = false,
  selectAll = false,
  ...rest
}: CheckboxProps) => {
  const CheckboxComponent: any = TYPES[variant];

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {variant === 'select' ? (
        <Styled.Select>
          <input {...rest} type="checkbox" disabled={disabled} />
          {children}
          <SortIcon />
        </Styled.Select>
      ) : (
        <Styled.Container
          type={variant}
          rtl={rtl}
          disabled={disabled}
          data-selenium={rest.name}
          selectAll={selectAll}
        >
          <input
            {...omit(rest, ['menuPlacement'])}
            type="checkbox"
            disabled={disabled}
            data-selenium-checked={rest.checked}
          />
          <CheckboxComponent
            {...CHECKBOX_PROPS[variant]}
            plain={plain}
            isMulti={isMulti}
            disabled={disabled}
            rtl={rtl}
          />
          {(subText || children) && (
            <Styled.Info fullWidth={fullWidth} rtl={rtl}>
              {children}
              {subText && <Styled.SubText>{subText}</Styled.SubText>}
            </Styled.Info>
          )}
        </Styled.Container>
      )}

      <Error meta={meta} />
    </Styled.Wrapper>
  );
};

export default Checkbox;
