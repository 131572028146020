import React from 'react';
import has from 'lodash/has';
import toLower from 'lodash/toLower';
import Styled from './styles';
import { ANNOUNCEMENT_PRICE_TYPE, ANNOUNCEMENT_PRICE_NAME } from 'shared/constants/ANNOUNCEMENT';
import SplittedNumber from '../../SplittedNumber';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalPrice = ({
  data: { old: oldMeta, new: newMeta },
  currency,
}: {
  data: IHistoryItem['data'];
}) => {
  const renderPrice = () => {
    let priceType;
    if (has(newMeta, ANNOUNCEMENT_PRICE_TYPE.PRICE)) {
      priceType = ANNOUNCEMENT_PRICE_TYPE.PRICE;
    }

    if (has(newMeta, ANNOUNCEMENT_PRICE_TYPE.PURCHASE_PRICE)) {
      priceType = ANNOUNCEMENT_PRICE_TYPE.PURCHASE_PRICE;
    }

    if (has(newMeta, ANNOUNCEMENT_PRICE_TYPE.TOTAL_PRICE)) {
      priceType = ANNOUNCEMENT_PRICE_TYPE.TOTAL_PRICE;
    }

    const newPrice = newMeta[priceType];
    const oldPrice = oldMeta[priceType];
    const priceName = ANNOUNCEMENT_PRICE_NAME[priceType];

    if (!oldPrice) {
      return (
        <Styled.InfoText>
          {`Была установлена ${toLower(priceName)} в `}
          <SplittedNumber currency={currency} withSuffix>
            {newPrice}
          </SplittedNumber>
        </Styled.InfoText>
      );
    }

    return (
      <Styled.InfoText>
        {`${priceName} изменилась с `}
        <SplittedNumber currency={currency} withSuffix>
          {oldPrice}
        </SplittedNumber>
        {' на '}
        <SplittedNumber currency={currency} withSuffix>
          {newPrice}
        </SplittedNumber>
      </Styled.InfoText>
    );
  };

  return <Styled.Info>{renderPrice()}</Styled.Info>;
};

export default AnnouncementHistoryModalPrice;
