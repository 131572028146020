import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import ReactSelect, { components } from 'react-select';
import { styles, Container, Disabled, Arrow, MobileContainer } from './styles';
import { ANNOUNCEMENT_STATUS } from 'shared/constants/ANNOUNCEMENT_STATUS';
import StatusDropdownOption from './StatusDropdownOption';
import ArrowDownIcon from '../../../static/icons/arrow-down.svg';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import IDS from 'shared/constants/IDS';
import { openModal, closeModal } from 'entities/modal';
import MODALS from 'shared/constants/MODALS';
import StatusDropdownMobileOption from './StatusDropdownMobileOption';
import { ModalFunctions } from '../../../interfaces/modal';

interface DropdownProps {
  onChange?: Function;
  disabled?: boolean;
  label?: string;
  defaultValue?: any;
  data?: string | number;
  readonly?: boolean;
  openUp?: boolean;
  isMobile?: boolean;
  disabledColor?: string;
  openModal?: ModalFunctions['openModal'];
  closeModal?: ModalFunctions['closeModal'];
}

interface DropdownState {
  left: boolean;
}

const Indicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Arrow>
      <ArrowDownIcon />
    </Arrow>
  </components.DropdownIndicator>
);

class StatusDropdown extends Component<DropdownProps, DropdownState> {
  selectRef: React.RefObject<HTMLDivElement>;

  myRef: React.RefObject<any>;

  constructor(props) {
    super(props);

    this.state = {
      left: false,
    };

    this.selectRef = React.createRef();
    this.myRef = React.createRef();

    this.openDrawer = this.openDrawer.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.handleChangePosition();

      window.addEventListener('resize', debounce(this.handleChangePosition, 1000));
      document.getElementById(IDS.APP_LAYOUT).addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.handleChangePosition, 1000));
    document.getElementById(IDS.APP_LAYOUT).removeEventListener('scroll', this.handleScroll);
  }

  handleChangePosition = () => {
    if (!this.selectRef.current) return;

    if (this.selectRef.current.getBoundingClientRect().left < 128) {
      this.setState({ left: true });
    } else {
      this.setState({ left: false });
    }
  };

  handleScroll = () => {
    if (!this.myRef.current) return;

    if (this.myRef.current.state.menuIsOpen) {
      this.myRef.current.blur();
    }
  };

  handleChange = ({ value }) => {
    this.props.onChange(value, this.props.data);
  };

  openDrawer(items) {
    const { defaultValue } = this.props;

    this.props.openModal(MODALS.DRAWER_MODAL, {
      modalData: {
        withoutCloseButton: true,
        title: 'Изменение статуса',
        data: items.map((item) => (
          <StatusDropdownMobileOption
            key={item.value}
            color={item.color}
            isSelected={item.value === defaultValue.value}
            onClick={() => {
              this.handleChange({ value: item.value });
              this.props.closeModal();
            }}
            helper={item.tooltip}
          >
            {item.label}
          </StatusDropdownMobileOption>
        )),
      },
    });
  }

  render() {
    const { defaultValue, disabled, label, readonly, disabledColor, openUp, isMobile } = this.props;

    const { left } = this.state;

    const opts = ANNOUNCEMENT_STATUS.filter((item) => !item.hidden);

    return (
      <Container
        openUp={openUp}
        ref={this.selectRef}
        left={left}
        readonly={readonly}
        data-selenium="status-dropdown"
      >
        {!disabled && defaultValue ? (
          <>
            {isMobile ? (
              <MobileContainer
                color={defaultValue.color}
                isReadOnly={readonly}
                onClick={() => this.openDrawer(opts)}
              >
                {label} {!readonly && <ArrowDownIcon />}
              </MobileContainer>
            ) : (
              <ReactSelect
                ref={this.myRef}
                styles={styles}
                options={opts}
                isSearchable={false}
                onChange={this.handleChange}
                defaultValue={defaultValue}
                classNamePrefix="react-select"
                components={{ Option: StatusDropdownOption, DropdownIndicator: Indicator }}
              />
            )}
          </>
        ) : (
          <Disabled color={disabledColor}>{label}</Disabled>
        )}
      </Container>
    );
  }
}
const mapDispatchToProps = {
  openModal,
  closeModal,
};

export default withCheckIsMobile(connect(null, mapDispatchToProps)(StatusDropdown));
