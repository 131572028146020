export default {
  SLIDERS: '/static/Button/sliders.svg',
  COG: '/static/icons/cog.svg',
  EVENTS: '/static/Button/events.svg',
  CALENDAR: '/static/Button/calendar.svg',
  LINK: '/static/Button/chain.svg',
  TRASH: '/static/Button/trash.svg',
  CHECK: '/static/Button/check.svg',
  CLOSE: '/static/Button/close.svg',
  UNLINK: '/static/Button/unchain.svg',
};
