import Button from 'components/Button';
import Form from 'components/Form';
import { Input, InputWithSelect, FormRow } from 'components/FormControls';
import Modal from 'components/Modal';
import { i18n, useTranslation } from 'next-i18next';
import React from 'react';
import { Field } from 'react-final-form';
import locationSource from 'shared/api/reference/locations';
import { referencePlaceSources } from 'shared/api/reference/places';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderLocations, renderPlaceTypes } from 'shared/helpers/optionsRenderers';
import { composeValidators, required, minValue, maxValue } from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Styled from 'shared/ui/reference/modal-styles';

interface FormProps {
  isMobile?: boolean;
  title: string;
  isEdit?: boolean;
  onSubmit?: (data: any) => any;
  onSuccess?: (data: any) => any;
  closeModal: () => void;
  stashLanguages?: boolean;
  initialValues?: any;
  openDeleteForm: () => void;
  loading?: boolean;
}

const PlaceForm: React.FC<FormProps> = ({
  title,
  isMobile,
  isEdit,
  closeModal,
  // openDeleteForm,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Form
      {...rest}
      withoutNotification
      render={({ handleSubmit }: any) => {
        return (
          <form onSubmit={handleSubmit}>
            {isMobile ? (
              <Styled.Header>
                <Styled.Title>{t(title)}</Styled.Title>
              </Styled.Header>
            ) : (
              <Modal.Title>{t(title)}</Modal.Title>
            )}
            <Styled.Content>
              <Field name="id" component="input" type="hidden" />
              <FormRow>
                <Field name="title" validate={composeValidators(required, minValue(3), maxValue(100))}>
                  {({ input, meta }) => (
                    <Input label={t('references:places.name')} autoFocus {...input} meta={meta} />
                  )}
                </Field>
              </FormRow>
              <FormRow>
                <Field name="type_id" validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      query={{
                        language_code: i18n?.language || LANGUAGES.RU,
                      }}
                      route={referencePlaceSources}
                      optionsRenderer={renderPlaceTypes}
                      routeName="placeType"
                      label={t('references:places.type')}
                      meta={meta}
                      {...input}
                    />
                  )}
                </Field>
              </FormRow>
              <Styled.Row>
                <Styled.Col>
                  <FormRow>
                    <Field name="location_id" validate={required}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label={t('references:places.city')}
                          disabled={isEdit && i18n?.language !== LANGUAGES.RU}
                          route={locationSource}
                          query={{ language_code: i18n?.language || LANGUAGES.RU }}
                          optionsRenderer={renderLocations}
                          searchQueryName="search_string"
                          {...input}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </FormRow>
                </Styled.Col>
              </Styled.Row>
              <Styled.FooterContainer>
                <Modal.Footer>
                  {isEdit ? (
                    <>
                      <Button type="button" onClick={closeModal} transparent>
                        {t('forms:cancel')}
                      </Button>
                      <Button>{t('forms:save')}</Button>
                      {/* <Button danger onClick={openDeleteForm} type="button">
                        {t('forms:delete')}
                      </Button> */}
                    </>
                  ) : (
                    <>
                      {isMobile && (
                        <Button transparent onClick={closeModal}>
                          {t('forms:cancel')}
                        </Button>
                      )}
                      <Button disabled={loading}>{t('forms:create')}</Button>
                    </>
                  )}
                </Modal.Footer>
              </Styled.FooterContainer>
            </Styled.Content>
          </form>
        );
      }}
    />
  );
};

export default withCheckIsMobile(PlaceForm);
