import styled, { css, createGlobalStyle } from 'styled-components';
import { scrollbar } from '../../../app/styles/scrollbar';

interface HeaderProps {
  withShadow?: boolean;
}

interface InnerProps {
  withOptions?: boolean;
}

interface OptionsProps {
  withHeader?: boolean;
}

interface OptionsItemProps {
  divider?: boolean;
  disabled?: boolean;
}

interface OptionProps {
  danger?: boolean;
  disabled?: boolean;
  selected?: boolean;
}

interface IconWrapperProps {
  disabled?: boolean;
}

export default {
  GlobalStyles: createGlobalStyle`
    .modal {
      &__table-wrapper {
        vertical-align: bottom;
      }

      &__wrapper {
        max-width: 100%;
      }

      &__body {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        max-height: 100vh;
        overflow: hidden;
        padding: 0;
        border-radius: 8px 8px 0 0;
        padding-bottom: 8px;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      }
    }
  `,

  Header: styled.div<HeaderProps>`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    padding: 0 16px;
    height: 40px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #585858;
    transition: 0.2s ease-in-out;

    ${({ withShadow }) =>
      withShadow &&
      css`
        border-bottom: 1px solid #e1e1e1;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      `}
  `,

  Close: styled.button`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    opacity: 0.4;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
    }
  `,

  Inner: styled.div<InnerProps>`
    padding: ${({ withOptions }) => !withOptions && '0 16px'};
    overflow-y: auto;
    ${scrollbar}
  `,

  Options: styled.div<OptionsProps>`
    padding-top: ${({ withHeader }) => !withHeader && '15px'};
    overflow: hidden;
  `,

  OptionsItem: styled.div<OptionsItemProps>`
    padding: 0;
    border-bottom: ${({ divider }) => divider && '1px solid #E1E1E1'};
  `,

  Option: styled.div<OptionProps>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    min-height: 40px;
    box-sizing: border-box;
    color: #000;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }

    /* danger */
    color: ${({ danger }) => danger && '#EB5757'};

    /* disabled */
    color: ${({ disabled }) => disabled && '#929292'};

    ${({ selected }) =>
      selected &&
      css`
        color: #2f80ed;

        & svg {
          fill: #2f80ed;
        }
      `}
  `,

  IconWrapper: styled.div<IconWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-right: 12px;

    & > svg {
      height: 100%;
      fill: ${({ disabled }) => disabled && '#929292'};
    }
  `,
};
