import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Field } from 'react-final-form';
import {
  Textarea,
  FileCropperInput,
  FileArrayInput,
  InputWithSelect,
  Checkbox,
  FormDescription,
  SmallFormDescription,
  FormControl,
  Input,
} from 'components/FormControls';
import { OptionWithEventInfo } from 'components/FormControls/InputWithSelect';
import Modal from 'components/Modal';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import LandingsModalLayout from 'containers/Pages/Landings/landingsModalLayout';
import { Editor } from 'entities/editor';
import { eventsSources } from 'shared/api/events';
import { tagsRoute } from 'shared/api/landings/tags';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import {
  STEPS_PREVIEW,
  ASPECT_RATIO_PREVIEW,
  STEPS_WORDS_PREVIEW,
  SIZES_PREVIEW,
  LANDINGS_IMAGE_SIZES,
  LANDINGS_PREVIEW_IMAGE_SIZES,
} from 'shared/constants/IMAGE_CONFIG';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderEventsForLandingEventSelector, renderContentGroups } from 'shared/helpers/optionsRenderers';
import {
  composeValidators,
  required,
  maxValue,
  landingDescriptionValidation,
} from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import qna from 'shared/sources/reference/qna';
import Styled from '../styled';

interface EventInfoProps {
  switcher: any;
  values: any;
  i18n: any;
  isEdit: boolean;
  isDesktop?: boolean;
  t: (value: string, params?: { [key: string]: any }) => string;
  form?: any;
  initialValue?: any;
}

const LandingEventInfo: React.FC<EventInfoProps> = ({
  switcher,
  values,
  i18n,
  isEdit,
  isDesktop,
  t,
  form,
}) => {
  const showOutdated = get(values[i18n.language], 'show_outdated');
  const disabledByLanguage = i18n.language !== LANGUAGES.RU;
  const hasValueCover = !isEmpty(values[i18n.language]?.cover);
  const hasValuePreview = !isEmpty(values[i18n.language]?.preview);

  const resetAttachmentInput = (attachmentCode) => {
    form.batch(() => {
      form.change(`${i18n.language}.${attachmentCode}_title`, '');
      form.change(`${i18n.language}.${attachmentCode}_alt`, '');
    });
  };

  return (
    <>
      {!isDesktop && <LandingsModalLayout.Row>{switcher}</LandingsModalLayout.Row>}
      <Field name={`${i18n.language}.id`} component="input" type="hidden" />
      {!disabledByLanguage && (
        <>
          <Modal.Section>
            <LandingsModalLayout.Row grid>
              <FormDescription width={208}>
                {t('landings:events.event')}
                <SmallFormDescription>{t('landings:events.event_description')}</SmallFormDescription>
              </FormDescription>
              <FormControl column>
                <LandingsModalLayout.Row>
                  <Field
                    name={`${i18n.language}.event`}
                    validate={isEdit ? undefined : required}
                    key={showOutdated ? 'outdated' : 'not_outdated'}
                  >
                    {({ input, meta }) => (
                      <InputWithSelect
                        isAsync
                        meta={meta}
                        disabled={isEdit}
                        optionsRenderer={renderEventsForLandingEventSelector}
                        components={{ Option: OptionWithEventInfo }}
                        label={t('events:event')}
                        searchQueryName="search_string"
                        route={eventsSources}
                        query={{ language_code: i18n.language }}
                        {...input}
                      />
                    )}
                  </Field>
                </LandingsModalLayout.Row>
              </FormControl>
            </LandingsModalLayout.Row>
          </Modal.Section>
          <Modal.Section>
            <LandingsModalLayout.Row grid>
              <FormDescription width={208}>
                {t('landings:events.place_in_site_title')}
                <SmallFormDescription>{t('landings:events.place_in_site_description')}</SmallFormDescription>
              </FormDescription>
              <FormControl mobileColumn>
                <Styled.CheckboxContainer>
                  <Field name={`${i18n.language}.is_top`} type="checkbox">
                    {({ input, meta }) => (
                      <Checkbox
                        subText={t('landings:events.top_afisha')}
                        variant="round"
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                  <SmallFormDescription>{t('landings:events.top_afisha_description')}</SmallFormDescription>
                </Styled.CheckboxContainer>
                <Styled.CheckboxContainer>
                  <Field name={`${i18n.language}.is_cover`} type="checkbox">
                    {({ input, meta }) => (
                      <Checkbox
                        subText={t('landings:events.on_cover')}
                        variant="round"
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                  <SmallFormDescription>{t('landings:events.on_cover_description')}</SmallFormDescription>
                </Styled.CheckboxContainer>
              </FormControl>
            </LandingsModalLayout.Row>
          </Modal.Section>

          <Modal.Section>
            <LandingsModalLayout.Row grid>
              <FormDescription width={208}>{t('landings:events.indexing_title')}</FormDescription>
              <FormControl>
                <Field name={`${i18n.language}.allow_indexing`} type="checkbox">
                  {({ input, meta }) => (
                    <Checkbox
                      subText={t('landings:events.indexing')}
                      variant="round"
                      meta={meta}
                      {...input}
                    />
                  )}
                </Field>
              </FormControl>
            </LandingsModalLayout.Row>
          </Modal.Section>

          <Modal.Section>
            <LandingsModalLayout.Row grid>
              <FormDescription width={208}>
                {t('landings:events.tags')}
                <SmallFormDescription>{t('landings:events.tags_description')}</SmallFormDescription>
              </FormDescription>
              <FormControl>
                <Field name={`${i18n.language}.contentGroups`} validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      isMulti
                      isCreatable
                      searchQueryName="search_string"
                      label={t('landings:events.tags')}
                      optionsRenderer={renderContentGroups}
                      route={{ root: tagsRoute }}
                      meta={meta}
                      disabled={disabledByLanguage}
                      {...input}
                    />
                  )}
                </Field>
              </FormControl>
            </LandingsModalLayout.Row>
          </Modal.Section>
        </>
      )}

      <Modal.Section>
        <LandingsModalLayout.Row grid>
          <FormDescription width={208}>
            {t('landings:events.qna')}
            <SmallFormDescription>{t('landings:events.qna_description')}</SmallFormDescription>
          </FormDescription>
          <FormControl>
            <Field name={`${i18n.language}.qna`}>
              {({ input, meta }) => (
                <InputWithSelect
                  isAsync
                  isMulti
                  searchQueryName="search_string"
                  label={t('landings:events.qna')}
                  optionsRenderer={renderContentGroups}
                  route={qna}
                  meta={meta}
                  disabled={disabledByLanguage}
                  {...input}
                />
              )}
            </Field>
          </FormControl>
        </LandingsModalLayout.Row>
      </Modal.Section>

      <Modal.Section>
        <LandingsModalLayout.Row grid>
          <FormDescription width={208}>
            {t('landings:events.heading')}
            <SmallFormDescription>{t('landings:events.heading_description')}</SmallFormDescription>
          </FormDescription>
          <FormControl column>
            <LandingsModalLayout.Row>
              <Field name={`${i18n.language}.title`} validate={composeValidators(required, maxValue(255))}>
                {({ input, meta }) => (
                  <Textarea label={t('landings:events.title')} maxValue={255} {...input} meta={meta} />
                )}
              </Field>
            </LandingsModalLayout.Row>
            <LandingsModalLayout.Row>
              <Field
                name={`${i18n.language}.affiche_title`}
                validate={composeValidators(required, maxValue(70))}
              >
                {({ input, meta }) => (
                  <Textarea label={t('landings:events.heading')} maxValue={70} {...input} meta={meta} />
                )}
              </Field>
            </LandingsModalLayout.Row>
            <LandingsModalLayout.Row>
              <Field
                name={`${i18n.language}.annotation`}
                validate={composeValidators(required, maxValue(255))}
              >
                {({ input, meta }) => (
                  <Textarea label={t('landings:events.announce')} maxValue={255} {...input} meta={meta} />
                )}
              </Field>
            </LandingsModalLayout.Row>
          </FormControl>
        </LandingsModalLayout.Row>
      </Modal.Section>
      <Modal.Section>
        <LandingsModalLayout.Row grid>
          <FormDescription width={208}>Текст подсказки виджета</FormDescription>
          <FormControl column>
            <LandingsModalLayout.Row>
              <FormControl>
                <Field name={`${i18n.language}.hint`}>
                  {({ input, meta }) => <Input {...input} meta={meta} />}
                </Field>
              </FormControl>
            </LandingsModalLayout.Row>
          </FormControl>
        </LandingsModalLayout.Row>
      </Modal.Section>

      {!disabledByLanguage ? (
        <>
          <Modal.Section>
            <LandingsModalLayout.Row grid>
              <FormDescription width={208}>
                {t('landings:events.images')}
                <SmallFormDescription>{t('landings:events.images_description')}</SmallFormDescription>
              </FormDescription>
              <FormControl column>
                <LandingsModalLayout.Row>
                  <Field name={`${i18n.language}.cover`}>
                    {({ input, meta }) => (
                      <FileCropperInput
                        placeholder={t('landings:events.upload_cover')}
                        // eslint-disable-next-line
                        description={t('landings:events.upload_cover_description')}
                        imageSizes={LANDINGS_IMAGE_SIZES}
                        {...input}
                        meta={meta}
                        attachmentCode={AttachmentType.Cover}
                        resetAttachmentForm={resetAttachmentInput}
                      />
                    )}
                  </Field>
                  {hasValueCover && (
                    <>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.cover_title`}>
                          {({ input, meta }) => (
                            <Input label={t('forms:cover_title')} {...input} meta={meta} />
                          )}
                        </Field>
                      </Styled.InputWrapper>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.cover_alt`}>
                          {({ input, meta }) => <Input label={t('forms:cover_alt')} {...input} meta={meta} />}
                        </Field>
                      </Styled.InputWrapper>
                    </>
                  )}
                </LandingsModalLayout.Row>
                <LandingsModalLayout.Row>
                  <Field name={`${i18n.language}.preview`}>
                    {({ input, meta }) => (
                      <FileCropperInput
                        placeholder={t('landings:events.upload_preview')}
                        description={t('landings:events.upload_preview_description')}
                        {...input}
                        meta={meta}
                        stepsEnum={STEPS_PREVIEW}
                        ratio={ASPECT_RATIO_PREVIEW}
                        words={STEPS_WORDS_PREVIEW}
                        sizes={SIZES_PREVIEW}
                        imageSizes={LANDINGS_PREVIEW_IMAGE_SIZES}
                        attachmentCode={AttachmentType.Preview}
                        resetAttachmentForm={resetAttachmentInput}
                      />
                    )}
                  </Field>
                  {hasValuePreview && (
                    <>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.preview_title`}>
                          {({ input, meta }) => (
                            <Input label={t('forms:preview_title')} {...input} meta={meta} />
                          )}
                        </Field>
                      </Styled.InputWrapper>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.preview_alt`}>
                          {({ input, meta }) => (
                            <Input label={t('forms:preview_alt')} {...input} meta={meta} />
                          )}
                        </Field>
                      </Styled.InputWrapper>
                    </>
                  )}
                </LandingsModalLayout.Row>
              </FormControl>
            </LandingsModalLayout.Row>
          </Modal.Section>
          <Modal.Section>
            <LandingsModalLayout.Row grid>
              <FormDescription width={208}>
                {t('landings:events.gallery')}
                <SmallFormDescription>{t('landings:events.gallery_description')}</SmallFormDescription>
              </FormDescription>
              <FormControl column>
                <LandingsModalLayout.Row>
                  <Field name={`${i18n.language}.gallery`}>
                    {({ input, meta }) => <FileArrayInput {...input} meta={meta} />}
                  </Field>
                </LandingsModalLayout.Row>
              </FormControl>
            </LandingsModalLayout.Row>
          </Modal.Section>
        </>
      ) : (
        <>
          {(hasValueCover || hasValuePreview) && (
            <Modal.Section>
              <LandingsModalLayout.Row grid>
                <FormDescription width={208}>
                  {t('landings:events.images')}
                  <SmallFormDescription>{t('landings:events.images_description')}</SmallFormDescription>
                </FormDescription>
                <FormControl column>
                  {hasValueCover && (
                    <LandingsModalLayout.Row>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.cover_title`}>
                          {({ input, meta }) => (
                            <Input label={t('forms:cover_title')} {...input} meta={meta} />
                          )}
                        </Field>
                      </Styled.InputWrapper>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.cover_alt`}>
                          {({ input, meta }) => <Input label={t('forms:cover_alt')} {...input} meta={meta} />}
                        </Field>
                      </Styled.InputWrapper>
                    </LandingsModalLayout.Row>
                  )}

                  {hasValuePreview && (
                    <LandingsModalLayout.Row>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.preview_title`}>
                          {({ input, meta }) => (
                            <Input label={t('forms:preview_title')} {...input} meta={meta} />
                          )}
                        </Field>
                      </Styled.InputWrapper>
                      <Styled.InputWrapper auto>
                        <Field name={`${i18n.language}.preview_alt`}>
                          {({ input, meta }) => (
                            <Input label={t('forms:preview_alt')} {...input} meta={meta} />
                          )}
                        </Field>
                      </Styled.InputWrapper>
                    </LandingsModalLayout.Row>
                  )}
                </FormControl>
              </LandingsModalLayout.Row>
            </Modal.Section>
          )}
        </>
      )}
      <Modal.Editor>
        <Field
          name={`${i18n.language}.description`}
          validate={landingDescriptionValidation}
          key={i18n.language}
        >
          {({ input, meta }) => (
            <Editor
              key={i18n.language}
              inModal
              label="Описание"
              id="landing_event_1"
              input={input}
              meta={meta}
            />
          )}
        </Field>
      </Modal.Editor>
    </>
  );
};

export default withCheckIsMobile(LandingEventInfo);
