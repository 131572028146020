import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

interface WrapperProps {
  size?: number;
  center?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};

  ${(props) =>
    props.center
      ? css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        `
      : null}

  & > svg {
    width: 100%;
    height: 100%;
    animation: ${rotate} 2s linear infinite;
  }

  & > svg > circle {
    stroke: ${(props) => `${props.color}`};
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-dasharray: 100, 200;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

export default {
  Wrapper,
  Container,
};
