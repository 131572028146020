import { createEvent, sample } from 'effector';
import * as markupsApi from 'shared/api/markups/markups';
import { $query } from '../../../model';

export const deletedMarkup = createEvent();

sample({
  source: { query: $query },
  clock: deletedMarkup,
  fn: ({ query }) => query,
  target: markupsApi.markupItemsFx,
});
