import config from 'config/config';
import { createEffect } from 'effector';
import { isEmpty, omit } from 'lodash';
import { Router } from 'next/router';
import { i18n } from 'next-i18next';
import COOKIES from '../../constants/COOKIES';
import { getCookie } from '../../lib/cookie';
import api from '../../services/api';
import attachmentsSource from '../../sources/attachments';
import { Language } from '../types';
import {
  Bundle,
  BundleAttachmentTypes,
  BundleInfo,
  CheckSlugResponse,
  CreateBundleData,
  PaginatedBundles,
  ResponseError,
} from './types';

const route = `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/bundles`;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const bundlesSources = {
  root: route,
};

export const fetchBundlesFx = createEffect(async (query: Router['query']): Promise<PaginatedBundles> => {
  const response = await api.get(route, {
    params: {
      ...omit(query, ['started_at', 'finished_at']),
      ...(!isEmpty(omit(query, ['date_type'])) && {
        language_code: i18n?.language,
      }),
    },
  });

  return response.data;
});

export const fetchBundlesLoadMoreFx = createEffect(async ({ data }: any) => {
  const response = await api.get(route, {
    params: {
      ...data.query,
    },
  });

  const { results, ...meta } = response.data;

  return { results: [...data.bundles.results, ...results], ...meta };
});

export const createBundleFx = createEffect(async (data: CreateBundleData) => {
  const response = await api.post(route, data);

  return response.data;
});

export const fetchBundleFx = createEffect(async (id: number): Promise<Bundle> => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const updateBundleFx = createEffect(
  async ({ id, data }: { id: number; data: CreateBundleData }): Promise<Bundle> => {
    const response = await api.patch(`${route}/${id}`, data);

    return response.data;
  },
);

export const updateIsTopBundleFx = createEffect(
  async ({ id, is_top }: { id: number; is_top: boolean; page: string }): Promise<Bundle> => {
    const response = await api.patch(`${route}/${id}`, { is_top });

    return response.data;
  },
);

export const fetchAttachmentsTypesFx = createEffect(async (): Promise<BundleAttachmentTypes> => {
  const response = await attachmentsSource.getAttachmentsTypes('bundles');

  return response;
});

export const createBundleInfoFx = createEffect<{ id: number; translation: BundleInfo }, any, ResponseError>(
  async ({ id, translation }) => {
    const response = await api.post(`${route}/${id}/info`, translation);

    return response.data;
  },
);

export const updateBundleInfoFx = createEffect<{ id: number; translation: BundleInfo }, any, ResponseError>(
  async ({ id, translation }) => {
    const response = await api.patch(`${route}/${id}/info/${translation.language_code}`, translation);

    return response.data;
  },
);

export const createBundleAttachmentsFx = createEffect(
  async ({
    id,
    attachments,
  }: {
    id: number;
    attachments: { attachment_type_id: number; data: { [key: string]: string } }[];
  }) => {
    const requests = attachments.map(async (attachment, index) => {
      await sleep(index * 1000);
      const response = api.post(`${route}/${id}/attachments`, attachment);

      return response;
    });

    await Promise.all(requests);
  },
);

export const updateBundleAttachmentsFx = createEffect(
  async ({
    id,
    attachments,
  }: {
    id: number;
    attachments: { id: number; attachment_type_id: number; data: { [key: string]: string } }[];
  }) => {
    const requests = attachments.map(async (attachment, index) => {
      const { id: attachmentId, ...rest } = attachment;
      await sleep(index * 1000);
      const response = api.patch(`${route}/${id}/attachments/${attachmentId}`, rest);

      return response;
    });

    await Promise.all(requests);
  },
);

export const deleteBundleAttachmentsFx = createEffect(
  async ({ id, attachments }: { id: number; attachments: { id: number }[] }) => {
    const requests = attachments.map(async (attachment, index) => {
      await sleep(index * 1000);
      const response = api.delete(`${route}/${id}/attachments/${attachment.id}`);

      return response;
    });

    await Promise.all(requests);
  },
);

export const createBundleSeoFx = createEffect(
  async ({
    id,
    seo,
  }: {
    id: number;
    seo: { title: string; description: string; language_code: Language };
  }) => {
    const response = await api.post(`${route}/${id}/seo`, seo);

    return response;
  },
);

export const updateBundleSeoFx = createEffect(
  async ({
    id,
    seo,
  }: {
    id: number;
    seo: { title: string; description: string; language_code: Language };
  }) => {
    const { language_code, ...restSeo } = seo;
    const response = await api.patch(`${route}/${id}/seo/${language_code}`, restSeo);

    return response;
  },
);

export const deleteBundleFx = createEffect(async (id: number) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});

export const addBundlesToEntity = ({ id, bundles }: { id: number; bundles: number[] }) => {
  const eventEntityType = 2;
  return Promise.all(
    bundles.map((bundle) =>
      api.post(`${route}/${bundle}/entity_types/${eventEntityType}/entities`, { entity_id: id }),
    ),
  );
};

export const deleteBundlesFromEntity = ({ id, bundles }: { id: number; bundles: number[] }) => {
  const eventEntityType = 2;
  return Promise.all(
    bundles.map((bundle) => api.delete(`${route}/${bundle}/entity_types/${eventEntityType}/entities/${id}`)),
  );
};

export const fetchBundlesSlugs = async ({
  search_string,
}: {
  search_string: string;
}): Promise<CheckSlugResponse> => {
  const response = await api.get(
    `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/entity_types/bundles/slugs`,
    {
      params: {
        search_string,
      },
    },
  );

  return response.data;
};

export const addBundlesToEntityFx = createEffect(
  async ({ id, bundles }: { id: number; bundles: number[] }) => {
    const eventEntityType = 2;
    return Promise.all(
      bundles.map((bundle) =>
        api.post(`${route}/${bundle}/entity_types/${eventEntityType}/entities`, { entity_id: id }),
      ),
    );
  },
);

export const deleteBundlesFromEntityFx = createEffect(
  async ({ id, bundles }: { id: number; bundles: number[] }) => {
    const eventEntityType = 2;
    return Promise.all(
      bundles.map((bundle) =>
        api.delete(`${route}/${bundle}/entity_types/${eventEntityType}/entities/${id}`),
      ),
    );
  },
);
