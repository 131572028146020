import React from 'react';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import { FileArrayInput } from '../../../FormControls';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const SectionsStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Настройки медиа</Styled.Title>
    </Styled.Header>
    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.FormRow>
              <Styled.FormDescription>
                Галлерея
                <Styled.FormSmallDescription>Галлерея на главной странице</Styled.FormSmallDescription>
              </Styled.FormDescription>
              <Styled.FormControls large>
                <Styled.FormControl>
                  <Field name="main_page_gallery">
                    {({ input, meta }) => (
                      <FileArrayInput withAttributes withoutEditButton {...input} meta={meta} />
                    )}
                  </Field>
                </Styled.FormControl>
              </Styled.FormControls>
            </Styled.FormRow>

            <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
          </form>
        )}
      />
    </Styled.Content>
  </Styled.Container>
);

export default SectionsStep;
