import React, { Component } from 'react';
import IDS from 'shared/constants/IDS';
import preventClick from 'shared/helpers/preventClick';
import { ModalFunctions } from '../../../interfaces/modal';
import ModalFooter from '../ModalFooter';
import ModalPopover from './ModalPopover';
import Styled from './styles';

interface ModalProps {
  children: any;
  size?: number;
  importantSize?: boolean;
  close?: () => void;
  fullSize?: boolean;
  popoverIsOpen?: boolean;
  closePopover?: () => void;
  openModal?: ModalFunctions['openModal'];
  closeModal?: ModalFunctions['closeModal'];
}

class Modal extends Component<ModalProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    size: 688,
  };

  static Header: any;

  static Title: any;

  static Body: any;

  static Close: any;

  static Footer: any;

  static FooterText: any;

  static Image: any;

  static HtmlContent: any;

  static Info: any;

  static InfoItem: any;

  static SecondaryCloseButton: any;

  static Label: any;

  static Container: any;

  static Section: any;

  static Editor: any;

  static FullSizeContainer: any;

  static FullSizeContent: any;

  isMouseDown: boolean;

  startY: any;

  constructor(props: any) {
    super(props);

    this.isMouseDown = false;
  }

  handleMouseDown = (event) => {
    this.startY = event.pageY;

    if (event.nativeEvent.which === 1) {
      this.isMouseDown = true;
    }
  };

  handleMouseUp = (event) => {
    const diffY = event.pageY - this.startY;
    if (Math.abs(diffY) < 5 && this.isMouseDown && this.props.close) {
      this.props.close();
      this.isMouseDown = false;
    }

    this.startY = 0;
  };

  render() {
    const { children, size, importantSize, fullSize, close, popoverIsOpen, closePopover } = this.props;

    return (
      <Styled.Overlay
        className="modal__overlay"
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        popoverIsOpen={popoverIsOpen}
        id={IDS.MODAL_CONTAINER}
      >
        <Styled.GlobalStyles fullSize={fullSize} />

        <ModalPopover close={close} closePopover={closePopover} />
        <Styled.TableContainer className="modal__table-container">
          <Styled.TableWrapper fullSize={fullSize} className="modal__table-wrapper">
            <Styled.Wrapper
              className="modal__wrapper"
              size={size}
              importantSize={importantSize}
              fullSize={fullSize}
              onClick={preventClick}
              onMouseDown={preventClick}
            >
              {children}
            </Styled.Wrapper>
          </Styled.TableWrapper>
        </Styled.TableContainer>
      </Styled.Overlay>
    );
  }
}

Modal.Header = Styled.Header;
Modal.Title = Styled.Title;
Modal.Body = Styled.Body;
Modal.Close = Styled.Close;
Modal.Footer = ModalFooter;
Modal.Info = Styled.Info;
Modal.InfoItem = Styled.InfoItem;
Modal.SecondaryCloseButton = Styled.SecondaryCloseButton;
Modal.Label = Styled.Label;
Modal.Container = Styled.Container;
Modal.Section = Styled.Section;
Modal.Editor = Styled.Editor;
Modal.FullSizeContainer = Styled.FullSizeContainer;
Modal.FullSizeContent = Styled.FullSizeContent;

export default Modal;
