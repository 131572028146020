import get from 'lodash/get';
import React from 'react';
import { Field } from 'react-final-form';
import { REJECT_REASONS } from 'shared/constants/ORDER';
import { renderRejectReasons } from 'shared/helpers/optionsRenderers';
import { required } from 'shared/helpers/validations';
import { ModalFunctions } from '../../../../interfaces/modal';
import Button from '../../Button';
import Form from '../../Form';
import { Textarea, InputWithSelect } from '../../FormControls';
import Styled from './styles';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  onSubmit: (data: any) => void;
  rejectReasonsRoute: any;
  title?: string;
  submitText?: string;
}

const RejectModalForm: React.FC<ModalProps> = ({
  onSubmit,
  closeModal,
  rejectReasonsRoute,
  title = 'Причина отмены заказов',
  submitText = 'Отменить заказы',
}) => {
  const isSubmitButtonDisabled = (values) => {
    const isOtherReason = get(values, 'reason.value') === REJECT_REASONS.OTHER;

    if (isOtherReason && !values.message) {
      return true;
    }

    if (!isOtherReason && !get(values, 'reason.value')) {
      return true;
    }

    return false;
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Styled.Title>{title}</Styled.Title>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Styled.Reason>
              <Field name="reason" validate={required}>
                {({ input, meta }) => (
                  <InputWithSelect
                    menuPosition="fixed"
                    isAsync
                    placeholder="Выбрать причину"
                    route={rejectReasonsRoute}
                    routeName="rejectReasonsList"
                    optionsRenderer={renderRejectReasons}
                    {...input}
                    meta={meta}
                  />
                )}
              </Field>
            </Styled.Reason>
            {get(values, 'reason.value') === REJECT_REASONS.OTHER && (
              <Styled.Textarea>
                <Field name="message" validate={required}>
                  {({ input, meta }) => <Textarea label="Причина" autoFocus {...input} meta={meta} />}
                </Field>
              </Styled.Textarea>
            )}
            <Styled.Footer>
              <Button type="button" transparent onClick={closeModal}>
                Отмена
              </Button>
              <Button danger disabled={isSubmitButtonDisabled(values)}>
                {submitText}
              </Button>
            </Styled.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default RejectModalForm;
