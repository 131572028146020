import React from 'react';
import COLORS from 'shared/constants/COLORS';
import CheckIcon from '../../../../static/icons/check-white.svg';
import Error from '../Error';
import Styled from './styles';

interface RadioProps {
  children?: string | Element;
  meta?: {
    touched?: boolean;
    error?: string;
  };
  square?: boolean;
  color?: string;
  checkValue?: boolean;
  dataSelenium?: string;
  checked?: boolean;
  name?: string;
  value?: any;
  onChange?: any;
  type?: string;
}

const Radio: React.FC<RadioProps> = ({
  meta = {},
  children,
  square,
  color = COLORS.GREEN,
  checkValue,
  dataSelenium,
  ...rest
}) => {
  const addProps: any = {};
  if (checkValue) {
    addProps.checked = rest.checked;
  }

  return (
    <Styled.Wrapper>
      <Styled.Container color={color} square={square} data-selenium={dataSelenium}>
        <input name={rest.name} value={rest.value} onChange={rest.onChange} type="radio" {...addProps} />
        <Styled.Round square={square} color={color} />
        {square && <CheckIcon className="radioSquare" fill="white" />}
        {children ? <Styled.Label>{children}</Styled.Label> : null}
      </Styled.Container>
      <Error meta={meta} />
    </Styled.Wrapper>
  );
};

export default Radio;
