import Modals from 'containers/Modal';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { logout } from 'entities/auth';
import IDS from 'shared/constants/IDS';
import WS_MESSAGE_TYPES from 'shared/constants/WS_MESSAGE_TYPES';
import WebsocketService from 'shared/services/WebsocketService';
import AngleIcon from '../../../../static/icons/angle.svg';
import { Header } from './Header';
import Styled from './styled';

// let Modals = null;

interface MainLayoutProps {
  withoutLayout?: boolean;
  withoutModal?: boolean;
  // eslint-disable-next-line no-undef
  title?: string | JSX.Element;
  // eslint-disable-next-line no-undef
  breadcrumbs?: JSX.Element[];
  logout: () => void;
  children: any;
}

const MainLayoutUI: React.FC<MainLayoutProps> = ({
  withoutLayout,
  children,
  withoutModal,
  title,
  breadcrumbs,
  ...rest
}) => {
  if (!withoutModal && !Modals) {
    // Modals = dynamic(() => import('containers/Modal'), { ssr: false, loading: () => null });
  }

  const handleLogout = (event: any) => {
    const { data } = JSON.parse(event.data);

    if (data && data.message_type === WS_MESSAGE_TYPES.BAN) {
      rest.logout();
    }
  };

  useEffect(() => {
    WebsocketService.addEvent(handleLogout);

    return () => WebsocketService.removeEvent(handleLogout);
  }, []);

  return (
    <>
      <Styled.App>
        {!withoutLayout && (
          <>
            <Styled.TopAngle>
              <AngleIcon />
            </Styled.TopAngle>
            <Styled.BottomAngle>
              <AngleIcon />
            </Styled.BottomAngle>
            <Header title={title} breadcrumbs={breadcrumbs} />
          </>
        )}
        <Styled.SearchOverlay id={IDS.SEARCH_OVERLAY} />
        <Styled.Container>{children}</Styled.Container>
      </Styled.App>
      <Modals />
    </>
  );
};

export const MainLayout = connect(null, { logout })(MainLayoutUI);
