import Button from 'components/Button';
import Form from 'components/Form';
import { Input } from 'components/FormControls';
import Modal from 'components/Modal';
import { map, omit } from 'lodash';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { createLegalInfo, editLegalInfo, loadCompanyLegalsInfo } from 'entities/company/legals-info';
import { checkForInitialValues } from 'shared/helpers/form';
import { composeValidators, mustBeNumber, required } from 'shared/helpers/validations';
import { queryFilter } from 'shared/lib/queryFilter';
import { ModalFunctions } from '../../../../../../../interfaces/modal';
import Styled from './styled';

interface ModalProps {
  data: {templates:[]};
  closeModal: ModalFunctions['closeModal'];
  editLegalInfo: (id: number, data: object) => void;
  createLegalInfo: (data: object) => void;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadCompanyLegalsInfo: () => void;
}

interface TemplateProps {
  page_type:string;
  id:number;
  name:string;
}

interface SubmitData {
  ABOUT:{value:string}; 
  PRIVACY_POLICY:{value:string};
  PAY_AND_DELIVERY:{value:string};
  OFERTA:{value:string};
  id:number;
}

const InfoModal: React.FC<ModalProps> = (props) => {
  const isEdit = !isEmpty(props.data);

  const getInitialValues = () => {
    let initial = {};

    map(props.data.templates, (template:TemplateProps) => {
      initial = {
        ...initial,
        [template.page_type]: { value: template.id, label: template.name },
      };
    });

    return {
      ...omit(props.data, ['templates']),
      ...initial,
    };
  };

  const initialValues = isEdit ? getInitialValues() : {};

  const onSubmit = (data: SubmitData) => {
    const mappedData = {
      ...omit(data, ['ABOUT', 'PRIVACY_POLICY', 'PAY_AND_DELIVERY', 'OFERTA']),
      template_ids: compact([
        data.ABOUT && {
          id: data.ABOUT.value,
          page_type: 'ABOUT',
        },
        data.PRIVACY_POLICY && {
          id: data.PRIVACY_POLICY.value,
          page_type: 'PRIVACY_POLICY',
        },
        data.PAY_AND_DELIVERY && {
          id: data.PAY_AND_DELIVERY.value,
          page_type: 'PAY_AND_DELIVERY',
        },
        data.OFERTA && {
          id: data.OFERTA.value,
          page_type: 'OFERTA',
        },
      ]),
    };

    const responseCallback = (response: {error:string}, withoutListLoad?: boolean) => {
      if (response.error) {
        NotificationManager.error('Ошибка');

        return response;
      }

      if (!withoutListLoad) {
        props.loadCompanyLegalsInfo();
        queryFilter({ search: '' });
      }

      return response;
    };

    if (isEdit) {
      return props
        .editLegalInfo(
          data.id,
          checkForInitialValues(
            {
              ...omit(props.data, ['templates']),
              ...(props.data.templates && {
                template_ids: map(props.data.templates, (template:TemplateProps) => ({
                  id: template.id,
                  page_type: template.page_type,
                })),
              }),
            },
            mappedData,
          ),
        )
        .then((response:any) => responseCallback(response, true));
    }

    return props.createLegalInfo(mappedData).then((response:any) => responseCallback(response));
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title>{`${isEdit ? 'Редактировать' : 'Добавить'}`} юридическую информацию</Modal.Title>
      <Form
        onSubmit={onSubmit}
        onSuccess={props.forceCloseModal}
        initialValues={initialValues}
        render={({ handleSubmit }:{handleSubmit:()=>void}) => (
          <form onSubmit={handleSubmit}>
            <Styled.Fields>
              <Field name="actual_address">
                {({ input, meta }) => <Input label="Фактический адрес" {...input} meta={meta} />}
              </Field>
              <Field name="legal_address">
                {({ input, meta }) => <Input label="Юридический адрес" {...input} meta={meta} />}
              </Field>
              <Field name="company_name" validate={required}>
                {({ input, meta }) => <Input label="Наименование организации" {...input} meta={meta} />}
              </Field>
              <Field name="kpp">{({ input, meta }) => <Input label="КПП" {...input} meta={meta} />}</Field>
              <Field name="inn" validate={composeValidators(required, mustBeNumber)}>
                {({ input, meta }) => <Input label="ИНН" {...input} meta={meta} />}
              </Field>
              <Field name="ogrn">{({ input, meta }) => <Input label="ОГРН" {...input} meta={meta} />}</Field>
              <Field name="okpo">{({ input, meta }) => <Input label="ОКПО" {...input} meta={meta} />}</Field>
            </Styled.Fields>
            <Modal.Footer>
              <Button type="button" transparent onClick={props.closeModal}>
                Отмена
              </Button>
              <Button type="submit">{isEdit ? 'Сохранить' : 'Добавить'}</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

const mapDispatchToProps = {
  editLegalInfo,
  createLegalInfo,
  loadCompanyLegalsInfo,
};

export const CompanyLegalInfoModal = connect(null, mapDispatchToProps)(InfoModal);
