import React, { Component } from 'react';
import { Field } from 'react-final-form';
import get from 'lodash/get';
import Styled from './styles';
import Spinner from '../../Spinner';
import {eventsSources} from 'entities/events';
import { formatEventDateTime } from 'shared/helpers/formatters/date';
import Form from '../../Form';
import { InputWithSelect } from '../../FormControls';
import { renderEventsWithoutChildrens } from 'shared/helpers/optionsRenderers';
import { required } from 'shared/helpers/validations';
import Modal from '../../Modal/Modal';
import Button from '../../Button';
import WarningIcon from '../../../../static/icons/warning.svg';
import { OptionWithEventInfo } from '../../FormControls/InputWithSelect';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  loadEvent: ({ event, outdated }: { event: number; outdated?: boolean }) => any;
  mergeEvents: (event: any, { donor }: { donor: any }) => any;
  closeModal: ModalFunctions['closeModal'];
}

interface ModalState {
  data: any;
  isLoading: boolean;
}

class MergeEventsModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { id } = this.props.data;

    this.props.loadEvent({ event: id, outdated: true }).then((response) =>
      this.setState({
        isLoading: false,
        data: response.payload.data,
      }),
    );
  }

  getDate() {
    const { data } = this.state;

    const { date: dateStart, time: timeStart } = formatEventDateTime(data.date_start, data.time_start);
    const { date: dateFinish, time: timeFinish } = formatEventDateTime(data.date_finish, data.time_finish);

    return {
      dateStart,
      timeStart,
      dateFinish,
      timeFinish,
    };
  }

  mergeEvents = (data) => {
    const { donor, event } = data;

    return this.props.mergeEvents(event.value, { donor: donor.value });
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) return <Spinner center />;

    const { id, title, place, hall_layout: hallLayout } = this.state.data;
    const { dateStart, timeStart, dateFinish, timeFinish } = this.getDate();

    return (
      <Styled.Container>
        <Modal.Title>Объединение мероприятий</Modal.Title>
        <Styled.Time>{`${dateStart}, ${timeStart} - ${dateFinish}, ${timeFinish}`}</Styled.Time>
        <Styled.Name>{title}</Styled.Name>
        <Styled.Info>{`${place?.name || ''} ${hallLayout ? ` - ${hallLayout.name}` : ''}`}</Styled.Info>
        <Styled.Form>
          <Form
            onSubmit={this.mergeEvents}
            onSuccess={() => window.location.reload()}
            initialValues={{
              event: {
                name: title,
                value: id,
              },
            }}
            render={({ handleSubmit, values }) => {
              const eventName = get(values, 'event.name');
              const eventId = get(values, 'event.value');
              const donorName = get(values, 'donor.label');
              const donorId = get(values, 'donor.value');

              return (
                <form onSubmit={handleSubmit}>
                  <Styled.FormRow>
                    <Field name="donor" validate={required}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          route={eventsSources}
                          query={{
                            sortBy: 'title',
                            sortOn: 'asc',
                          }}
                          label="Объединить с"
                          optionsRenderer={(result) => renderEventsWithoutChildrens(result, id)}
                          components={{ Option: OptionWithEventInfo }}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.FormRow>
                  {donorId && (
                    <Styled.Warning>
                      <WarningIcon />
                      {/* eslint-disable-next-line */}
                      {`Мероприятие "${donorName}" (ID-${donorId}) будет удалено. Все объявления, заказы, билеты и виджеты будут перенесены в "${eventName}" (ID-${eventId}).`}
                    </Styled.Warning>
                  )}
                  <Modal.Footer>
                    <Button transparent type="button" onClick={this.props.closeModal}>
                      Отмена
                    </Button>
                    <Button>Связать</Button>
                  </Modal.Footer>
                </form>
              );
            }}
          />
        </Styled.Form>
      </Styled.Container>
    );
  }
}

export default MergeEventsModal;
