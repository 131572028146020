import head from 'lodash/head';
import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import ReactPagination from 'react-paginate';
import { queryFilter } from 'shared/lib/queryFilter';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import LimitSelect from '../LimitSelect';
import Spinner from '../Spinner';
import LoadMoreButton from './LoadMoreButton';
import Styled from './styles';

interface Meta {
  page: number;
  count: number;
  // eslint-disable-next-line camelcase
  last_page: number;
  limit: number;
}

type PaginationProps = {
  meta: Meta;
  updateQuery?: boolean;
  isDesktop: boolean;
  state: any;
  onChange: (page: { page: number; limit: number } | number) => void;
  loadMore: () => void;
  router: Router;
  limits: number[];
  returnLimits?: boolean;
};

class Pagination extends Component<PaginationProps, any> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    updateQuery: false,
    meta: {
      page: undefined,
      last_page: undefined,
      count: 0,
      limit: 25,
    },
    loadMore: null,
    onChange: () => {},
    limits: [25, 50, 100, 150],
  };

  componentDidMount() {
    if (this.props.meta.page === 0) {
      queryFilter({ page: 1 });
    }
  }

  handleChange = (data: { selected?: string; limit?: string }) => {
    const page = Number(data.selected) + 1;
    const limit = Number(data.limit);

    if (this.props.updateQuery) {
      queryFilter({ page });
    }

    if (this.props.onChange) {
      const params = this.props.returnLimits
        ? {
            page: Number.isNaN(page) ? this.props.meta.page : page,
            limit: Number.isNaN(limit) ? this.props.meta.limit : limit,
          }
        : page;
      this.props.onChange(params);
    }
  };

  render() {
    const { meta, loadMore, state, limits, isDesktop } = this.props;

    if (state && state.isLoading) return null;

    return (
      <Styled.Container>
        {state && state.isLoadingMore ? (
          <Styled.Spinner>
            <Spinner />
          </Styled.Spinner>
        ) : (
          <>
            {loadMore && (
              <Styled.LoadMore
                hiddenBlock={meta.last_page === 1 || meta.count === 0}
                data-selenium="change-page"
              >
                <LoadMoreButton loadMore={loadMore} meta={meta} />
              </Styled.LoadMore>
            )}

            <Styled.Wrapper hiddenBlock={meta.last_page === 1 || meta.count === 0}>
              {meta && meta.page && (
                <ReactPagination
                  initialPage={meta.page - 1}
                  forcePage={meta.page - 1}
                  pageCount={meta.last_page}
                  pageRangeDisplayed={!isDesktop ? 2 : 4}
                  marginPagesDisplayed={!isDesktop ? 1 : 0}
                  onPageChange={this.handleChange}
                  breakLabel={!isDesktop ? undefined : false}
                  disableInitialCallback
                />
              )}
            </Styled.Wrapper>

            {meta.count > (head(limits) || 0) && (
              <Styled.LimitSelect>
                <LimitSelect
                  limits={limits}
                  currentLimit={
                    this.props.returnLimits ? meta.limit.toString() : this.props.router.query.limit
                  }
                  onChange={this.props.returnLimits ? this.handleChange : null}
                  returnLimits={this.props.returnLimits}
                />
              </Styled.LimitSelect>
            )}
          </>
        )}
      </Styled.Container>
    );
  }
}

export default withCheckIsMobile(withRouter(Pagination));
