import React, { Component } from 'react';
import ReactPhoneInput2 from 'react-phone-input-2/lib/lib';
import ru from 'react-phone-input-2/lang/ru.json';
import omit from 'lodash/omit';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import Styled from './styles';
import CloseIcon from '../../../../static/icons/close.svg';
import Error from '../Error';
import PhoneIcon from '../../../../static/icons/phone.svg';

interface PhoneInputProps {
  meta?: any;
  handleForm?: any;
  disabled?: boolean;
  fixedError?: boolean;
  getFormattedNumber?: (value: any) => void;
  input: any;
  label?: string;
}

interface PhoneInputState {
  key: string;
  countryCode: string | number;
}

class PhoneInput extends Component<PhoneInputProps, PhoneInputState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    meta: {},
    handleForm: {},
    disabled: false,
    fixedError: false,
    getFormattedNumber: null,
  };

  inputRef: any;

  constructor(props) {
    super(props);

    this.state = {
      key: 'default',
      countryCode: undefined,
    };

    this.inputRef = React.createRef();

    this.changeCurrentCountry = this.changeCurrentCountry.bind(this);
  }

  componentDidMount() {
    const { getFormattedNumber } = this.props;

    if (getFormattedNumber) {
      getFormattedNumber(this.inputRef.current.state.formattedNumber);
    }
  }

  handleChange = (value, country) => {
    const { input } = this.props;
    const { countryCode, dialCode } = country;

    if (countryCode && dialCode) {
      // country change from DD format validaion bugfix
      const phone = startsWith(value, '+') ? value : `+${value}`;

      return this.setState(
        {
          countryCode,
        },
        () => {
          input.onChange(phone);
          this.changeCurrentCountry(dialCode);
        },
      );
    }

    return null;
  };

  handleFocus = () => {
    const { input } = this.props;

    if (!input.value) {
      this.setState(
        {
          countryCode: 'ru',
        },
        () => {
          input.onChange('+7');
          this.changeCurrentCountry('ru');
        },
      );
    }
  };

  clear = ({ withFocus }) => {
    this.props.input.onChange('');
    this.setState(
      {
        key: 'reset',
        countryCode: undefined,
      },
      () => {
        this.setState(
          {
            key: 'default',
          },
          () => {
            this.changeCurrentCountry(undefined);

            if (withFocus) {
              this.inputRef.current.numberInputRef.focus();
            }
          },
        );
      },
    );
  };

  changeCurrentCountry(dialCode) {
    const { handleForm, input } = this.props;

    handleForm.change(`${input.name}_dial_code`, dialCode);
  }

  render() {
    const { key, countryCode } = this.state;
    const { meta, input, disabled, fixedError } = this.props;

    return (
      <Styled.Container key={key} meta={meta} disabled={disabled} hasValue={input.value}>
        {!countryCode && !get(input, 'value') && (
          <Styled.PhoneIcon>
            <PhoneIcon />
          </Styled.PhoneIcon>
        )}
        <ReactPhoneInput2
          placeholder="+7 123 456-78-90"
          localization={ru}
          country={countryCode}
          {...omit(input, 'onChange')}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          preserveOrder={['preferredCountries']}
          ref={this.inputRef}
          enableSearch
          disableSearchIcon
          searchPlaceholder="Поиск"
          countryCodeEditable={false}
          enableLongNumbers
          preferredCountries={[
            'am',
            'by',
            'gb',
            'de',
            'ge',
            'il',
            'kz',
            'lv',
            'lt',
            'ru',
            'us',
            'ua',
            'cz',
            'ee',
          ]}
        />
        {input.value && !disabled && (
          <Styled.Clear onClick={this.clear}>
            <CloseIcon width={10} height={10} />
          </Styled.Clear>
        )}
        <Error meta={meta} fixedError={fixedError} />
      </Styled.Container>
    );
  }
}

export default PhoneInput;
