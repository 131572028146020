import styled from 'styled-components';

export default {
  Container: styled.div`
    margin: -24px;
    padding: 40px 24px 0 24px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #fff;
  `,

  Title: styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
  `,

  SubTitle: styled.div`
    margin-top: 16px;
    color: #333;
  `,

  Exclamation: styled.div`
    margin-right: 16px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    background-color: #fce8c2;
    background-image: url('/static/icons/exclamation.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
  `,

  Attention: styled.div`
    display: flex;
    margin-top: 32px;
    color: rgba(0, 0, 0, 0.64);
  `,

  AttentionIcon: styled.div`
    margin-right: 16px;
    font-size: 16px;
    line-height: 21px;
  `,
};
