import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  App: styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #000;
    overflow: hidden;
  `,

  TopAngle: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 16;
    width: 8px;
    height: 8px;
    line-height: 0;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  BottomAngle: styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 7;
    width: 8px;
    height: 8px;
    line-height: 0;
    transform: rotate(-90deg);

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  Container: styled.div`
    display: flex;
    height: 100%;
    min-height: 0;
    background: #fff;
  `,

  SearchOverlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    background: rgba(0, 0, 0, 0.24);
  `,
};
