import React from 'react';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import playgroundsSources from 'shared/sources/playgroundsv2';
import { renderWidgetPlaygrounds } from 'shared/helpers/optionsRenderers';
import { InputWithAutocomplete } from '../../../../../FormControls';
import Tooltip from '../../../../../Tooltip';
import { DataContext } from '../DataContext';
import WidgetStyled from '../../WidgetForm/styles';

interface RowProps {
  sector: any;
  field: string;
  title: string;
  disabled: boolean;
  onChangeSector: (data: any) => void;
  onChangeRow: (data: any) => void;
  isMulti: boolean;
  value: any;
}

const ValueEditorRow: React.FC<RowProps> = (props) => {
  const { sector, field, title, disabled, onChangeSector, onChangeRow, isMulti, value } = props;

  return (
    <DataContext.Consumer>
      {(context) => {
        const event = context && context.event;
        const query = {
          layout_id: event && (event.hall_layout && event.hall_layout.id || event.hall_layout_id),
          fragment_type: FRAGMENT_TYPES.SECTOR,
        };
        const rowQuery = {
          layout_id: event && (event.hall_layout && event.hall_layout.id || event.hall_layout_id),
          fragment_type: FRAGMENT_TYPES.ROW,
          parent_id: sector && sector.value,
        };

        return (
          <WidgetStyled.Row>
            <WidgetStyled.Column>
              <Tooltip text={sector && sector.name}>
                <div>
                  <InputWithAutocomplete
                    fluid
                    defaultOptions
                    disablePaginate
                    withoutDefaultValue
                    key={field}
                    value={sector}
                    title={title}
                    query={query}
                    isMulti={false}
                    isDisabled={disabled}
                    route={playgroundsSources}
                    routeName="root"
                    onChange={onChangeSector}
                    optionsRenderer={renderWidgetPlaygrounds}
                  />
                </div>
              </Tooltip>
            </WidgetStyled.Column>
            <WidgetStyled.Column>
              <InputWithAutocomplete
                fluid
                isMulti={isMulti}
                defaultOptions
                disablePaginate
                withoutDefaultValue
                key={field}
                value={value}
                title={title}
                query={rowQuery}
                isDisabled={!sector}
                route={sector && playgroundsSources}
                routeName="root"
                onChange={onChangeRow}
                optionsRenderer={renderWidgetPlaygrounds}
              />
            </WidgetStyled.Column>
          </WidgetStyled.Row>
        );
      }}
    </DataContext.Consumer>
  );
};

export default ValueEditorRow;
