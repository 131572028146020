import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import map from 'lodash/map';
import size from 'lodash/size';
import { noun } from 'plural-ru';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import ReactSelect from 'react-select';
import KEY_CODES from 'shared/constants/KEY_CODES';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import preventClick from 'shared/helpers/preventClick';
import CloseIcon from '../../../../static/icons/close.svg';
import InputWithSelectClearIndicator from './InputWithSelectClearIndicator';
import OptionWithCheckbox from './OptionWithCheckbox';
import Styled from './styles';

const ACTIONS = {
  SELECT: 'select-option',
  DESELECT: 'deselect-option',
  CLEAR: 'clear',
  SELECT_ALL: 'SELECT_ALL',
};

const handleKeyDown = (event) => {
  if (event.keyCode === KEY_CODES.SPACEBAR) {
    event.preventDefault();
  }
};

interface Props {
  data: any;
  selectProps: any;
}

interface State {
  isOpen: boolean;
}

class InputWithSelectMultiValueForEvent extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.getValueData = this.getValueData.bind(this);
    this.getEventChildrens = this.getEventChildrens.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getPlaceholderText = this.getPlaceholderText.bind(this);
  }

  componentDidMount() {
    const {
      data: { data: event, isPreload },
      selectProps: { value: inputValue, onChange },
    } = this.props;

    if (!event.parent && event.children) {
      let currentValue = [];

      if (inputValue) {
        currentValue = inputValue;
      }

      const childrens = event.children.map((item) => ({
        value: item.id,
        label: formatFullDateAndTime(`${item.date_start} ${item.time_start}`),
        isChildren: true,
        data: { ...item, ...event, parent: { id: event.id } },
      }));

      const childrenIds = map(childrens, (children) => children.value);

      if (isPreload) {
        return onChange([...currentValue, ...childrens.filter((item) => includes(childrenIds, item))]);
      }

      return onChange([...currentValue, ...childrens]);
    }

    return null;
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  handleChange = (value, actionMeta) => {
    const {
      data: { value: parentId },
      selectProps: { onChange, value: inputValue },
    } = this.props;

    const { inputRelativeValues, selectedChildrensCount, childrensCount } = this.getValueData();
    const filteredValue = value.filter((item) => item.value !== ACTIONS.SELECT_ALL);
    const childrens = this.getEventChildrens();

    if (get(actionMeta, 'option.value') === ACTIONS.SELECT_ALL) {
      if (selectedChildrensCount <= childrensCount && selectedChildrensCount !== 0) {
        return onChange(inputValue.filter((item) => !find(inputRelativeValues, { value: item.value })));
      }

      if (selectedChildrensCount === 0) {
        return onChange([...inputValue, ...childrens]);
      }
    }

    if (actionMeta.action === ACTIONS.SELECT) {
      return onChange(filteredValue);
    }

    if (actionMeta.action === ACTIONS.DESELECT) {
      return onChange(filteredValue);
    }

    if (actionMeta.action === ACTIONS.CLEAR) {
      onChange(
        inputValue.filter(
          (item) => !find([...inputRelativeValues, { value: parentId }], { value: item.value }),
        ),
      );
    }

    return null;
  };

  handleClick = () => {
    const {
      data: { value: parentId },
      selectProps: { onChange, value: inputValue },
    } = this.props;
    const { inputRelativeValues } = this.getValueData();

    onChange(
      inputValue.filter(
        (item) => !find([...inputRelativeValues, { value: parentId }], { value: item.value }),
      ),
    );
  };

  getValueData() {
    const {
      data: { value: parentId, data: event },
      selectProps: { value: inputValues },
    } = this.props;
    const childrensCount = size(event.children);

    const inputRelativeValues = compact(
      map(inputValues, (item) => {
        if (parentId === get(item, 'data.parent.id')) {
          return item;
        }

        return null;
      }),
    );

    const selectedChildrensCount = size(inputRelativeValues);

    return {
      childrensCount,
      inputRelativeValues,
      selectedChildrensCount,
    };
  }

  getPlaceholderText() {
    const { inputRelativeValues, childrensCount, selectedChildrensCount } = this.getValueData();
    const firstSelectedChildren = head(inputRelativeValues);

    if (childrensCount === selectedChildrensCount) {
      return 'Все даты';
    }

    if (!firstSelectedChildren)
      return <Styled.MultiValueForEventPlaceholderRed>Нет дат</Styled.MultiValueForEventPlaceholderRed>;

    if (selectedChildrensCount === 1) {
      const { date_start: dateStart, time_start: timeStart } = firstSelectedChildren.data;

      return formatFullDateAndTime(`${dateStart} ${timeStart}`);
    }

    return `${selectedChildrensCount} ${noun(selectedChildrensCount, 'дата', 'даты', 'дат')}`;
  }

  getEventChildrens() {
    const {
      data: { value: parentId, data: event },
    } = this.props;

    return map(event.children, (item) => ({
      value: item.id,
      label: formatFullDateAndTime(`${item.date_start} ${item.time_start}`),
      data: {
        ...event,
        parent: { id: parentId },
        date_start: item.date_start,
        time_start: item.time_start,
      },
      isChildren: true,
    }));
  }

  toggleDropdown = (event) => {
    event.stopPropagation();
    event.preventDefault();

    this.setState((prevProps) => ({
      isOpen: !prevProps.isOpen,
    }));
  };

  render() {
    const {
      selectProps,
      data: { data: event, isChildren },
    } = this.props;

    if (isChildren) return null;

    const { selectedChildrensCount } = this.getValueData();
    const childrens = this.getEventChildrens();
    const isChildrensNotSelected = selectedChildrensCount === 0;

    if (event.children && event.children.length > 1) {
      return (
        <Styled.MultiValueForEvent
          onClick={this.toggleDropdown}
          onTouchEnd={this.toggleDropdown}
          onMouseDown={preventClick}
          isChildrensNotSelected={isChildrensNotSelected}
        >
          <ReactSelect
            value={selectProps.value}
            isMulti
            classNamePrefix="react-select-multi-value-for-event"
            options={[
              {
                label: isChildrensNotSelected ? 'Выбрать все' : 'Снять все',
                value: ACTIONS.SELECT_ALL,
              },
              ...childrens,
            ]}
            onChange={this.handleChange}
            onKeyDown={handleKeyDown}
            closeMenuOnSelect={false}
            menuIsOpen={this.state.isOpen}
            isSearchable={false}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            placeholder={
              <Styled.MultiValueForEventPlaceholder>
                <span>{event.title}</span>
                <span>{this.getPlaceholderText()}</span>
              </Styled.MultiValueForEventPlaceholder>
            }
            components={{ Option: OptionWithCheckbox, ClearIndicator: InputWithSelectClearIndicator }}
          />
        </Styled.MultiValueForEvent>
      );
    }

    if (isChildren) return null;

    return (
      <Styled.Tag onTouchStart={preventClick} onTouchEnd={preventClick}>
        <span>{event.title}</span>
        <Styled.ClearIndicator onClick={this.handleClick} className="react-select-clear-indicator">
          <CloseIcon width={10} height={10} opacity={0.4} />
        </Styled.ClearIndicator>
      </Styled.Tag>
    );
  }
}

export default onClickOutside(InputWithSelectMultiValueForEvent);
