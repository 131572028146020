import { createEffect, createEvent, createStore, sample } from 'effector';
import { NotificationManager } from 'react-notifications';
import {
  deleteHallLayoutFx,
  fetchHallLayoutsFx,
  PaginatedHallLayouts,
} from 'shared/api/reference/hall-layouts';
import { fetchHallFx, Hall } from 'shared/api/reference/halls';
import { createToggler } from 'shared/lib/toggler';

export const toggler = createToggler();

export const modalOpened = createEvent<{ id: string; type: string }>();
export const modalClosed = createEvent();
const deleteSchemeSubmitted = createEvent<number>();

export const $data = createStore<Hall | null>(null);
export const $relatedHallLayouts = createStore<PaginatedHallLayouts['data'] | null>(null);
export const $loading = createStore(true);
export const $relatedHallLayoutsLoading = createStore(true);
export const $modalId = createStore<string | null>(null);

const successDeleteHallLayoutNotificationFx = createEffect(() => {
  NotificationManager.success('Успешно');
});

const failDeleteHallLayoutNotificationFx = createEffect(() => {
  NotificationManager.error('Невозможно удалить, схема не пуста.', 'Ошибка');
});

export const viewHallModal = {
  toggler,
  modalOpened,
  modalClosed,
  deleteSchemeSubmitted,
  $data,
  $relatedHallLayouts,
  $loading,
  $relatedHallLayoutsLoading,
  $modalId,
};

//load
sample({
  clock: modalOpened,
  target: fetchHallFx,
});

sample({
  clock: toggler.open,
  target: $modalId,
});

sample({
  clock: toggler.close,
  fn: () => true,
  target: [$loading, $relatedHallLayoutsLoading],
});

sample({
  clock: toggler.close,
  fn: () => null,
  target: [$data, $relatedHallLayouts],
});

sample({
  clock: fetchHallFx.done,
  filter: ({ params }) => params.type === 'view',
  fn: ({ result }) => {
    return result;
  },
  target: $data,
});

sample({
  clock: fetchHallFx.done,
  filter: ({ params }) => params.type === 'view',
  fn: () => false,
  target: $loading,
});

sample({
  clock: fetchHallFx.done,
  filter: ({ params }) => params.type === 'view',
  fn: ({ result }) => {
    return {
      page: 1,
      limit: 9999,
      hall: result.id,
    };
  },
  target: fetchHallLayoutsFx,
});

sample({
  clock: fetchHallLayoutsFx.done,
  fn: ({ result }) => result.data,
  target: $relatedHallLayouts,
});

sample({
  clock: fetchHallLayoutsFx.done,
  fn: () => false,
  target: $relatedHallLayoutsLoading,
});

// delete scheme
sample({
  clock: deleteSchemeSubmitted,
  target: deleteHallLayoutFx,
});

sample({
  source: {
    hall: $data,
  },
  clock: deleteHallLayoutFx.done,
  fn: ({ hall }) => {
    return {
      page: 1,
      limit: 9999,
      hall: hall!.id,
    };
  },
  target: [successDeleteHallLayoutNotificationFx, fetchHallLayoutsFx],
});

sample({
  clock: deleteHallLayoutFx.fail,
  target: failDeleteHallLayoutNotificationFx,
});
