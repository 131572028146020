import Placeholder from 'components/Placeholder';
import SplittedNumber from 'components/SplittedNumber';
import permissions from 'config/permissions';
import Can from 'containers/Can';
import Layout from 'containers/Layout';
import { withRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { noun } from 'plural-ru';
import React, { useState } from 'react';
import { MainLayout } from 'widgets/layouts/main-layout';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { ReferenceCreateButton } from 'widgets/reference/reference-create-button';
import { ReferenceSearch } from 'widgets/reference/reference-search';
import { LIST_MODAL_TYPES } from 'shared/constants/REFERENCE_MODALS';
import REFERENCE_TABS from 'shared/constants/REFERENCE_TABS';
import useList, { ListParams } from 'shared/lib/useList';
import { ReferenceTabs } from 'shared/ui/reference/tabs';
import { ExpansionsList } from './list';

interface PageProps {
  router: {
    query: {
      name?: string;
      page?: string | number;
      limit?: string | number;
    };
  };
}

const ReferencePage = (props: PageProps) => {
  const { page, limit } = props.router.query;
  const name = REFERENCE_TABS.EXPANSIONS;
  const modalType = LIST_MODAL_TYPES[name];
  const [listParams, setListParams] = useState<ListParams>({});
  const { t } = useTranslation();
  const { items, meta, update, state } = useList(listParams);
  const count = meta.count;

  return (
    <MainLayout title={t('references:title')}>
      <Layout disableLayout withoutPaddingsOnMobile title={t('references:title')}>
        <ReferenceTabs loading={state.isLoading}>
          <ReferenceLayout.MainWrapper>
            <ReferenceLayout.Header>
              <>
                {count !== undefined ? (
                  <div>
                    <SplittedNumber>{count}</SplittedNumber>{' '}
                    <ReferenceLayout.CountLabel>
                      {noun(
                        count,
                        'Ограничения/Привилегии',
                        'Ограничения/Привилегии',
                        'Ограничения/Привилегии',
                      )}
                    </ReferenceLayout.CountLabel>
                  </div>
                ) : (
                  <ReferenceLayout.PlaceholderContainer>
                    <Placeholder />
                  </ReferenceLayout.PlaceholderContainer>
                )}
                <ReferenceLayout.Actions>
                  <ReferenceSearch referenceName={name} />
                  <ReferenceLayout.HeaderControls>
                    <Can permission={permissions.reference}>
                      <ReferenceCreateButton referenceName={name} />
                    </Can>
                  </ReferenceLayout.HeaderControls>
                </ReferenceLayout.Actions>
              </>
            </ReferenceLayout.Header>
            <ReferenceLayout.Wrapper>
              <ExpansionsList
                page={page || 1}
                items={limit || 25}
                modalType={modalType}
                setListParams={setListParams}
                update={update}
                meta={meta}
                data={items}
                state={state}
              />
            </ReferenceLayout.Wrapper>
          </ReferenceLayout.MainWrapper>
        </ReferenceTabs>
      </Layout>
    </MainLayout>
  );
};

export default withRouter(ReferencePage);
