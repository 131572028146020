import { createEffect, createEvent, createStore, sample } from 'effector';
import { isEmpty } from 'lodash';
import { createToggler } from 'shared/lib/toggler';

const toggler = createToggler();

const insertedLink = createEvent<any>();

const $data = createStore({});

const insertedLinkFx = createEffect((data) => {
  data.current.editor.clipboard.dangerouslyPasteHTML(data.range, data.linkHtml);
});

export const editorLinkModal = { toggler, insertedLink };

sample({
  clock: toggler.open,
  fn: ({ current }) => {
    let range = 0;

    const selection = current.unprivilegedEditor?.getSelection();

    if (!isEmpty(selection)) {
      range = selection?.index;
    }

    return { range, current };
  },
  target: $data,
});

sample({
  source: {
    data: $data,
  },
  clock: insertedLink,
  fn: ({ data }, values) => {
    const linkHtml = `<a href="${values.linkUrl}">${values.displayText}</a>`;

    return {
      linkHtml,
      ...data,
    };
  },
  target: insertedLinkFx,
});

sample({
  clock: insertedLinkFx.done,
  target: toggler.close,
});
