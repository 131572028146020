import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Selects: styled.div`
    margin-right: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  SearchSelect: styled.div`
    width: 248px;
    margin-left: 8px;
  `,
};
