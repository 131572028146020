import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import { InputWithSelect } from '../../../FormControls';
import Styled from './styles';
import PageConstructorFooter from './PageConstructorFooter';
import qna from 'shared/sources/reference/qna';
import { renderContentGroups } from 'shared/helpers/optionsRenderers';
import landingsCustomPageSources from 'shared/sources/landings/landingsCustom';
import api from 'shared/services/api';

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  step: number;
  data: any;
  blockData: any;
  steps: string[];
}

const QnACustomPagesStep: React.FC<StepProps> = ({ onSubmit, updateData, data, step, goBack, steps }) => {
  const [customPageData, setCustomPageData] = useState(null);
  const router = useRouter();

  const initialValues = {
    qna:
      customPageData?.data?.qna?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
  };

  const fetchData = async () => {
    const response = await api.get(landingsCustomPageSources.newDetail(router.query.modalId));
    setCustomPageData(response);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnSubmit = async (newData) => {
    const newQnAIds = newData.qna.map((i) => i.value);
    const initialQnAIds = customPageData.data.qna?.map((i) => i.id);
    const initialQnA = new Set(initialQnAIds);
    const newQnA = new Set(newQnAIds);
    const QnAforDelete = initialQnAIds?.filter((x) => !newQnA.has(x));
    const QnAforUpdate = newQnAIds?.filter((x) => !initialQnA.has(x));

    if (QnAforUpdate.length) {
      await Promise.all(
        QnAforUpdate.map((qnaforUpdateId) =>
          landingsCustomPageSources.createQnA(router.query.modalId, qnaforUpdateId),
        ),
      );
    }
    if (QnAforDelete.length) {
      await Promise.all(
        QnAforDelete.map((qnaforDeleteId) =>
          landingsCustomPageSources.deleteQnA(router.query.modalId, qnaforDeleteId),
        ),
      );
    }
    await fetchData();
    const requestData = data.map((item) => {
      if (item.uuid === steps[step]) {
        return {
          ...item,
        };
      }

      return item;
    });
    return onSubmit({ blocks: requestData, submit_action: newData.submit_action });
  };

  const handleUpdateData = () => {
    const requestData = data.map((item) => {
      if (item.uuid === steps[step]) {
        return {
          ...item,
        };
      }

      return item;
    });
    return updateData(requestData);
  };

  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка блока QnA</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          onSubmit={handleOnSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field name="qna">
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      isMulti
                      label="QnA"
                      searchQueryName="search_string"
                      optionsRenderer={renderContentGroups}
                      route={qna}
                      meta={meta}
                      {...input}
                    />
                  )}
                </Field>

                <PageConstructorFooter
                  goBack={goBack}
                  form={form}
                  updateData={handleUpdateData}
                  withoutNextAction={steps.length - 1 === step}
                />
              </form>
            );
          }}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default QnACustomPagesStep;
