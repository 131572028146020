import React from 'react';
import styled from 'styled-components';
import SquareDisableIcon from '../../../static/icons/square-disabled.svg';

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: not-allowed;
`;

const TableRowSelectorDisabled = () => (
  <StyledIcon>
    <SquareDisableIcon />
  </StyledIcon>
);

export default TableRowSelectorDisabled;
