import config from 'config/config';
import get from 'lodash/get';
import includes from 'lodash/includes';
import values from 'lodash/values';
import moment from 'moment';
import Link from 'next/link';
import React, { Component, Fragment } from 'react';
import onClickOutside from 'react-onclickoutside';
import COOKIES from 'shared/constants/COOKIES';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import STORAGE_TOKENS from 'shared/constants/STORAGE_TOKENS';
import WS_MESSAGE_TYPES from 'shared/constants/WS_MESSAGE_TYPES';
import { downloadReport } from 'shared/helpers/reports';
import routes from 'shared/helpers/routes';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';
import WebsocketService from 'shared/services/WebsocketService';
import RedBellIcon from '../../../static/icons/bell-red.svg';
import BellIcon from '../../../static/icons/bell.svg';
import ClockIcon from '../../../static/icons/clock.svg';
import CoinsIcon from '../../../static/icons/coins-black.svg';
import DownloadIcon from '../../../static/icons/download.svg';
import EnvelopeIcon from '../../../static/icons/envelope.svg';
import ReportIcon from '../../../static/icons/report.svg';
import TimesIcon from '../../../static/icons/times.svg';
import UserTieIcon from '../../../static/icons/user-tie.svg';
import { IUser } from '../../entities/account/types';
import Button from '../Button';
import Styled from './styles';

const NOTIFICATIONS_MESSAGE_TYPES = [
  WS_MESSAGE_TYPES.ORDER_REPORT_CREATE_SUCCESS,
  WS_MESSAGE_TYPES.ORDER_REPORT_CREATE_FAIL,
];

interface NotificationsProps {
  account: IUser;
}

interface NotificationsState {
  isOpen: boolean;
  notifications: any[];
}

class Notifications extends Component<NotificationsProps, NotificationsState> {
  notificationAudio: React.RefObject<unknown>;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      notifications: [],
    };

    this.notificationAudio = React.createRef();

    this.removeNotification = this.removeNotification.bind(this);
  }

  componentDidMount() {
    const token = getCookie(COOKIES.WS_TOKEN);
    const notificationsFromStorage = localStorage
      ? JSON.parse(localStorage.getItem(STORAGE_TOKENS.NOTIFICATIONS) || '[]')
      : [];
    WebsocketService.addEvent(this.loadNotification);

    api.get(`${config.WS_HOST_API_URL}ws/unread/${token}`).then(
      (response) => {
        const unreadMessages = response.data.data.messages
          .map((item) => ({
            message_type: WS_MESSAGE_TYPES.UNREAD_MESSAGES,
            message: item,
          }))
          .filter((item) => item.message.msg_type !== WS_MESSAGE_TYPES.ADD_NEW_ORDER);

        this.setState(
          ({ notifications }) => ({
            notifications: this.filterNotifications([
              ...notifications,
              ...notificationsFromStorage,
              ...unreadMessages,
            ]),
          }),
          () => {
            localStorage.setItem(STORAGE_TOKENS.NOTIFICATIONS, JSON.stringify(this.state.notifications));
          },
        );
      },
      (e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      },
    );
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  filterNotifications = (notifications: any) => {
    const notificationTypes = values(WS_MESSAGE_TYPES);

    return notifications.filter(
      (item) =>
        notificationTypes.indexOf(item.message.msg_type) >= 0 &&
        item.message.msg_type !== WS_MESSAGE_TYPES.BAN,
    );
  };

  loadNotification = (event) => {
    const { data } = JSON.parse(event.data);

    if (includes(NOTIFICATIONS_MESSAGE_TYPES, get(data, 'message_type'))) {
      this.setState(
        ({ notifications }) => ({
          notifications: [...notifications, data],
        }),
        () => {
          // @ts-ignore
          const playPromise = this.notificationAudio.current.play();

          if (playPromise !== undefined) {
            playPromise.catch((e) => {
              // eslint-disable-next-line no-console
              console.log(e);
            });
          }
        },
      );
    }
  };

  toggleDropdown = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  readAll = () => {
    this.setState({
      notifications: [],
    });
    localStorage.removeItem(STORAGE_TOKENS.NOTIFICATIONS);
  };

  hanldeDownloadReport = (_, { link, index }) => {
    downloadReport(link);
    this.removeNotification(index);
  };

  removeNotification(notificationIndex) {
    this.setState(
      ({ notifications }) => ({
        notifications: notifications.filter((_, index) => index !== notificationIndex),
      }),
      () => {
        localStorage.setItem(STORAGE_TOKENS.NOTIFICATIONS, JSON.stringify(this.state.notifications));
      },
    );
  }

  renderNotification(data, index) {
    if (data) {
      const { message } = data;
      const { msg_type: messageType } = message;

      switch (messageType || data.message_type) {
        case WS_MESSAGE_TYPES.ORDER_PAYMENT: {
          const {
            msg: { created, order_id: orderId },
          } = message;

          return (
            <Styled.Notification>
              <Styled.NotificationIcon bg="#F5F5F5">
                <CoinsIcon />
              </Styled.NotificationIcon>
              <Styled.NotificationData>
                <Styled.NotificationInfo>
                  <Styled.NotificationText>
                    Поступила оплата в заказе
                    {orderId && (
                      <Styled.Link
                        href={`${routes.sales}?id=${orderId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {orderId}
                      </Styled.Link>
                    )}
                  </Styled.NotificationText>
                  <Styled.NotificationDate>
                    {created && moment(created, DATE_FORMATS.DATE_TIME).fromNow()}
                  </Styled.NotificationDate>
                </Styled.NotificationInfo>
              </Styled.NotificationData>
            </Styled.Notification>
          );
        }

        case WS_MESSAGE_TYPES.ORDER_REPORT_CREATE_SUCCESS: {
          const link = message.link || (message.msg && message.msg.link);
          return (
            <Styled.Notification>
              <Styled.NotificationIcon bg="#FFFBE6">
                <ReportIcon />
              </Styled.NotificationIcon>
              <Styled.NotificationData>
                <Styled.NotificationInfo>
                  <Styled.NotificationText>Отчет готов для скачивания</Styled.NotificationText>
                </Styled.NotificationInfo>
                {link && (
                  <Styled.NotificationDownload>
                    <Button
                      plain
                      onClick={this.hanldeDownloadReport}
                      data={{
                        link,
                        index,
                      }}
                    >
                      Скачать
                      <DownloadIcon stroke="#2F80ED" />
                    </Button>
                  </Styled.NotificationDownload>
                )}
              </Styled.NotificationData>
            </Styled.Notification>
          );
        }

        case WS_MESSAGE_TYPES.ORDER_REPORT_CREATE_FAIL:
          return (
            <Styled.Notification>
              <Styled.NotificationIcon bg="#FFFBE6">
                <ReportIcon />
              </Styled.NotificationIcon>
              <Styled.NotificationData>
                <Styled.NotificationInfo>
                  <Styled.NotificationText>Не удалось сформировать отчёт по продажам</Styled.NotificationText>
                </Styled.NotificationInfo>
              </Styled.NotificationData>
            </Styled.Notification>
          );

        case WS_MESSAGE_TYPES.NEW_COMMENT: {
          const {
            msg: { author, order_id: orderId, created, is_sale: isSale },
          } = message;

          const href = isSale ? `${routes.sales}?id=${orderId}` : `${routes.purchases}?id=${orderId}`;

          return (
            <Styled.Notification>
              <Styled.NotificationIcon bg="#EBF3FE">
                <EnvelopeIcon fill="#2F80ED" />
              </Styled.NotificationIcon>
              <Styled.NotificationData>
                <Styled.NotificationInfo>
                  <Styled.NotificationText>
                    Сообщение{' '}
                    {author && (
                      <>
                        от
                        <Styled.UserIcon>
                          {author}
                          <UserTieIcon fill="#999" />
                        </Styled.UserIcon>
                      </>
                    )}{' '}
                    в заказе
                    {orderId && (
                      <Styled.Link href={href} target="_blank" rel="noopener noreferrer">
                        {orderId}
                      </Styled.Link>
                    )}
                  </Styled.NotificationText>
                  <Styled.NotificationDate>
                    {created && moment(created, DATE_FORMATS.DATE_TIME).fromNow()}
                  </Styled.NotificationDate>
                </Styled.NotificationInfo>
              </Styled.NotificationData>
            </Styled.Notification>
          );
        }

        case WS_MESSAGE_TYPES.NOTIFICATION: {
          const {
            msg: { order: orderId, send_at: sendAt },
          } = message;

          const href = `${routes.sales}?id=${orderId}`;

          return (
            <Styled.Notification>
              <Styled.NotificationIcon bg="#EBF3FE">
                <ClockIcon width={17} height={17} fill="#2F80ED" />
              </Styled.NotificationIcon>
              <Styled.NotificationData>
                <Styled.NotificationInfo>
                  <Styled.NotificationText>
                    Вы оставляли напоминание по заказу
                    {orderId && (
                      <Styled.Link href={href} target="_blank" rel="noopener noreferrer">
                        {orderId}
                      </Styled.Link>
                    )}
                  </Styled.NotificationText>
                  <Styled.NotificationDate>
                    {sendAt && moment(sendAt, DATE_FORMATS.DATE_TIME).fromNow()}
                  </Styled.NotificationDate>
                </Styled.NotificationInfo>
              </Styled.NotificationData>
            </Styled.Notification>
          );
        }

        default:
          return null;
      }
    } else {
      return null;
    }
  }

  render() {
    const { isOpen, notifications } = this.state;

    return (
      <Styled.Container>
        <Styled.Audio ref={this.notificationAudio}>
          <source src="/static/notification.mp3" type="audio/mpeg" />
        </Styled.Audio>
        <Styled.ButtonWrapper hasNotifications={notifications.length > 0}>
          <Button onlyIcon transparent type="button" onClick={this.toggleDropdown} isOpen={isOpen}>
            <Styled.Bell hasNotifications={notifications.length > 0}>
              {notifications.length > 0 ? <RedBellIcon /> : <BellIcon />}
            </Styled.Bell>
          </Button>
        </Styled.ButtonWrapper>
        <Styled.Dropdown isOpen={isOpen}>
          <Styled.Title>
            <Styled.TitleIcon>
              <BellIcon />
            </Styled.TitleIcon>
            Оповещения
            <Styled.Close onClick={this.handleClickOutside}>
              <TimesIcon />
            </Styled.Close>
          </Styled.Title>
          <Styled.NotificationList>
            {notifications.length > 0 ? (
              notifications.map((data, index) => (
                // eslint-disable-next-line
                <Fragment key={index}>{this.renderNotification(data, index)}</Fragment>
              ))
            ) : (
              <Styled.Empty>Оповещения отсутствуют</Styled.Empty>
            )}
          </Styled.NotificationList>
          <Styled.Footer>
            {notifications.length > 0 && (
              <Styled.ReadAll onClick={this.readAll}>Отметить все прочитанными</Styled.ReadAll>
            )}
            <Link href={routes.notifications}>
              <Styled.History>История</Styled.History>
            </Link>
          </Styled.Footer>
        </Styled.Dropdown>
      </Styled.Container>
    );
  }
}

export default onClickOutside(Notifications);
