import styled from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import { layoutScrollbar } from '../../../../../app/styles/scrollbar';

export default {
  ControlsHeader: styled.div<{ mt?: number }>`
    display: flex;
    padding-bottom: 16px;
    margin-top: ${({ mt }) => `${mt}px`};
    margin-bottom: 16px;
    border-bottom: 1px solid #e1e1e1;

    @media ${MEDIA_QUERIES.MOBILE} {
      flex-direction: column;
    }
  `,

  Controls: styled.div`
    display: flex;
    align-items: center;
    margin-right: 44px;

    @media ${MEDIA_QUERIES.MOBILE} {
      margin-right: 0;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  `,

  Label: styled.div`
    margin-right: 16px;
  `,

  Control: styled.div`
    &:not(:last-child) {
      margin-right: 16px;
    }
  `,

  List: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,

  Preview: styled.div<{ square?: boolean; bg?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 12px;
    background: ${(props) => props.bg};
    color: #fff;
    transition: border-radius 0.2s linear;
    border-radius: ${(props) => (props.square ? '2px' : '50%')};
    font-family: Helvetica, PT Sans, sans-serif;
    font-weight: bold;
    font-size: 10px;
    line-height: 1;

    svg {
      height: 1em;
      width: 1em;
    }
  `,

  ImagePreview: styled.img`
    margin: 12px;
    width: 32px !important;
    height: 32px;
  `,

  Content: styled.div`
    flex-grow: 1;
    padding-top: 8px;
    overflow: hidden;
    overflow-y: auto;

    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      ${layoutScrollbar};
    }
  `,
};
