import React from 'react';
import { components } from 'react-select';
import Styled from './styles';
import Checkbox from '../Checkbox';

interface Props {
  setValue: (value: any) => void;
  selectProps: any;
  options: any[];
  value: number | string;
  children: any;
}

const InputWithSelectAll: React.FC<Props> = (props) => {
  const { children, setValue, options, selectProps } = props;

  const values = new Set(selectProps.value ? selectProps.value.map((value) => value.value) : []);

  const selectAll = (event) => {
    const { checked } = event.target;

    if (!checked) return setValue([]);

    return setValue(options);
  };

  const isChecked = selectProps.options.every((option) => values.has(option.value));

  return (
    <components.MenuList {...props}>
      <Styled.OptionLabel data-selenium="select_all">
        <Styled.OptionCheckbox>
          <Checkbox plain checked={isChecked} readOnly onChange={selectAll} selectAll />
        </Styled.OptionCheckbox>
        Выбрать все
      </Styled.OptionLabel>
      {children}
    </components.MenuList>
  );
};

export default InputWithSelectAll;
