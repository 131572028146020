import React from 'react';
import Styled from './styles';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';

interface CheckboxProps {
  ticket: {
    seat: string;
  };
  onChange: (ticket: any) => void;
  checked?: boolean;
  editMode?: boolean;
  isDesktop: boolean;
  isPreorder?: boolean;
}

const TableSeatCheckbox: React.FC<CheckboxProps> = ({
  ticket,
  onChange,
  checked,
  editMode,
  isDesktop,
  isPreorder,
}) => {
  const handleOnMouseEnter = (event) => {
    if (event.buttons === 1 || event.buttons === 3) {
      onChange(ticket);
    }
  };

  const handleMobileChange = () => {
    onChange(ticket);
  };

  const checkbox = (
    <Styled.Checkbox editMode={editMode} isPreorder={isPreorder}>
      <div>{ticket.seat}</div>
    </Styled.Checkbox>
  );

  if (!isDesktop) {
    return (
      <Styled.Container data-selenium={`seat_places-${ticket.seat}`} editMode={editMode}>
        <input checked={checked} type="checkbox" onChange={handleMobileChange} />
        {checkbox}
      </Styled.Container>
    );
  }

  return (
    <Styled.Container
      editMode={editMode}
      onMouseDown={handleOnMouseEnter}
      onMouseEnter={handleOnMouseEnter}
      data-selenium={`seat_places-${ticket.seat}`}
    >
      <input checked={checked} type="checkbox" onChange={() => {}} />
      {checkbox}
    </Styled.Container>
  );
};

export default withCheckIsMobile(TableSeatCheckbox);
