const ACTIONS = {
  UNSET: 'UNSET',
  SALE: 'SALE',
  REFUND: 'REFUND',
  DELETE: 'DELETE',
};

const ACTIONS_LABELS = {
  [ACTIONS.UNSET]: 'Выбрать действие',
  [ACTIONS.SALE]: 'Продажа',
  [ACTIONS.REFUND]: 'Возврат поставщику',
  [ACTIONS.DELETE]: 'Удалить с биржи',
};

const ACTIONS_OPTIONS = [
  { value: ACTIONS.SALE, label: 'Выставить на продажу' },
  { value: ACTIONS.REFUND, label: 'Вернуть поставщику' },
  { value: ACTIONS.DELETE, label: 'Удалить с биржи' },
];

export { ACTIONS, ACTIONS_LABELS, ACTIONS_OPTIONS };
