import React from 'react';
import styled, { css } from 'styled-components';

const types = {
  primary: css`
    background: #bcd6f9;
  `,
  success: css`
    background: #bff2d6;
  `,
  warning: css`
    background: #fae0ad;
  `,
  danger: css`
    background: #f9c9c9;
  `,
  plain: css`
    background: #e7e7e7;
  `,
  primaryToDanger: css`
    background: linear-gradient(90deg, #bcd6f9 0%, #f9c9c9 100%);
  `,
  successToPrimary: css`
    background: linear-gradient(90deg, #bff2d6 0%, #bcd6f9 100%);
  `,
  warningToPrimary: css`
    background: linear-gradient(90deg, #fae0ad 0%, #bcd6f9 100%);
  `,
  warningToDanger: css`
    background: linear-gradient(90deg, #fae0ad 0%, #f9c9c9 100%);
  `,
  plainToWarning: css`
    background: linear-gradient(90deg, #e7e7e7 0%, #fae0ad 100%);
  `,
  plainToDanger: css`
    background: linear-gradient(90deg, #e7e7e7 0%, #f9c9c9 100%);
  `,
};

interface BadgeProps {
  children: string;
  type?: string;
  checkManager?: boolean;
  manager?: any;
  status?: number;
}

const StyledBadge = styled.div<BadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  min-width: 88px;
  height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  border-radius: 6px;
  box-sizing: border-box;

  ${(props) => types[props.type]};
`;

const StatusBadge: React.FC<BadgeProps> = ({ children, type, checkManager, manager, status }) => (
  <StyledBadge type={type} checkManager={checkManager} manager={manager} status={status}>
    {children}
  </StyledBadge>
);

StatusBadge.defaultProps = {
  type: 'primary',
};

export default StatusBadge;
