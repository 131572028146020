import { rgba } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import COLORS from 'shared/constants/COLORS';
import Tooltip from '../Tooltip';

interface TableRowProps {
  isMyTicket?: boolean;
  isApi?: string;
  isCall?: boolean;
  isBTB?: boolean;
  isApiSeo?: boolean;
  isApiAdv?: boolean;
  children?: any;
  onClick?: any;
  noHover?: boolean;
  noPointer?: boolean;
  isNew?: boolean;
  switchToTablet?: boolean;
  mobilePadding?: any;
  mobileBorder?: boolean;
  isOpen?: boolean;
}

const getBadgeName = (props: TableRowProps) => {
  if (props.isMyTicket) return 'my';
  if (props.isApi) return 'api-badge';
  if (props.isCall) return 'call-badge';
  if (props.isBTB) return 'btb-badge';
  if (props.isApiSeo) return 'api-seo-badge';
  if (props.isApiAdv) return 'api-adv-badge';

  return null;
};

interface RowProps {
  isBTB?: boolean;
  switchToTablet?: boolean;
  isOpen?: boolean;
  paddingLeft?: string;
  noHover?: boolean;
  noPointer?: boolean;
  innerRow?: boolean;
  isNew?: boolean;
  mobilePadding?: string;
}

const Row = styled.div<RowProps>`
  position: relative;
  min-height: 56px;
  display: flex;
  align-items: flex-start;
  flex-wrap: ${({ switchToTablet }) => switchToTablet && 'wrap'};
  border-bottom: 2px solid #f5f5f5;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: ${(props) =>
    props.isOpen ? '0px 0px 8px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08)' : null};
  transition: all 0.2s ease-in-out;
  padding-left: ${({ paddingLeft }) => paddingLeft || '8px'};
  flex-shrink: 0;
  z-index: ${({ isOpen }) => isOpen && 4};

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    margin: 0 auto;
    bottom: -2px;
    left: 0;
    right: 0;
    width: ${(props) => (props.isOpen ? 'calc(100% - 48px)' : 0)};
    height: 2px;
    display: block;
    background-color: ${COLORS.YELLOW};
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    cursor: ${(props) => (props.noHover || props.noPointer ? null : 'pointer')};

    &:before {
      opacity: ${({ noHover }) => !noHover && '1'};
    }
  }

  ${(props) =>
    props.innerRow &&
    css`
      background: rgba(255, 255, 255, 0.8);

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        margin: 0 auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: calc(100% - 48px);
        height: 1px;
        display: block;
        background-color: #e1e1e1;
        transition: all 0.25s ease-in-out;
      }
    `}

  ${(props) =>
    props.isNew &&
    css`
      animation-name: highlightNewOrder;
      animation-duration: 0.3s;
      animation-timing-function: linear;

      @keyframes highlightNewOrder {
        0% {
          background-color: #fff;
        }
        50% {
          background-color: ${rgba(COLORS.YELLOW, 0.24)};
        }
        to {
          background-color: #fff;
        }
      }
    `}

  ${({ switchToTablet, mobilePadding, isOpen }) =>
    switchToTablet &&
    css`
      flex-wrap: wrap;
      padding: ${mobilePadding || '12px 8px'};
      margin-bottom: 0;
      border-bottom: ${isOpen ? '2px solid #f5f5f5' : '4px solid #f5f5f5'};
    `}

  @media (max-width: ${BREAKPOINTS.XL}) {
    flex-wrap: wrap;
    padding: ${({ mobilePadding }) => mobilePadding || '12px 8px'};
    margin-bottom: 0;
    border-bottom: ${({ isOpen }) => (isOpen ? '2px solid #f5f5f5' : '4px solid #f5f5f5')};
  }
`;

const Badge = styled.div<any>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 52px;
  height: 24px;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  z-index: 1;
`;

const TableRow = (props: TableRowProps) => {
  const { children, isMyTicket, isApi, isCall, isBTB, isApiSeo, isApiAdv, ...rest } = props;

  const badgeName = getBadgeName(props);
  const badgeImg = badgeName ? <img src={`/static/Table/${badgeName}.svg`} alt="" /> : null;
  let badge = (
    <Badge isMyTicket={isMyTicket} isApi={isApi} isCall={isCall} isBTB={isBTB}>
      {badgeImg}
    </Badge>
  );

  if (isApi) {
    badge = (
      <Tooltip text={isApi}>
        <Badge
          isMyTicket={isMyTicket}
          isApi={isApi}
          isCall={isCall}
          isBTB={isBTB}
          isApiSeo={isApiSeo}
          isApiAdv={isApiAdv}
        >
          {badgeImg}
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Row isBTB={isBTB} {...rest}>
      {badge}
      {children}
    </Row>
  );
};

TableRow.defaultProps = {
  isMyTicket: false,
  isApi: '',
  isCall: false,
  isBTB: false,
  isApiSeo: false,
  isApiAdv: false,
  children: null,
};

export default TableRow;
