import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface DescriptionProps {
  withLabel?: boolean;
}

interface PreviewContainerProps {
  bg?: string;
  bgOpacity?: string | number;
  withPreview?: boolean;
  height?: number;
}

export default {
  Container: styled.div`
    display: flex;
  `,

  PreviewWrapper: styled.div`
    width: 166px;
    flex-grow: 0;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 120px;
      flex-shrink: 0;
    }
  `,

  Description: styled.div<DescriptionProps>`
    flex-grow: 0;
    width: 100%;
    padding-left: 32px;
    margin-top: ${({ withLabel }) => withLabel && '25px'};
    margin-bottom: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding-left: 16px;
      margin: 0;
    }
  `,

  Preview: styled.label`
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  `,

  Input: styled.input<any>`
    opacity: 0;
    position: absolute;
    top: 0;
    left: -99999px;

    &:focus + div {
      border-color: #2f80ed;
    }
  `,

  PreviewContainer: styled.div<PreviewContainerProps>`
    position: relative;
    width: 166px;
    height: 166px;
    flex-shrink: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2f80ed;
    border: 1px solid rgba(23, 2, 0, 0.12);
    border-style: dashed;
    box-sizing: border-box;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 120px;
      height: 120px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: ${({ bg }) => (bg ? 'block' : 'none')};
      width: 100%;
      height: 100%;
      background-image: ${({ bg }) => `url(${bg})`};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: ${({ bgOpacity }) => bgOpacity};
    }

    &:hover {
      border-color: #2f80ed;
    }

    & > img {
      margin-right: 8px;
    }

    ${(props) =>
      props.withPreview &&
      css`
        height: ${props.height ? props.height : 101}px;
      `}

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 0 12px;
      font-size: 16px;
      text-align: center;
    }
  `,

  Buttons: styled.div``,

  ArrayContainer: styled.div`
    display: flex;
    position: relative;
    margin: 0 -16px;
    flex-wrap: wrap;
  `,

  ArrayItem: styled.div`
    padding: 0 16px 16px;

    &:hover button {
      display: block;
    }
  `,

  ArrayPlus: styled.label`
    padding: 0 16px 16px;
  `,

  DeleteButton: styled.button`
    display: none;
    position: absolute;
    top: -2px;
    right: -2px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    outline: none;
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    background-color: #ffffff;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  `,

  Text: styled.div`
    color: #999;
  `,

  Delete: styled.div`
    margin-top: 24px;

    button {
      color: #eb5757;

      &:hover:not(:disabled) {
        background-color: #fdebeb;
      }
    }
  `,

  Upload: styled.label`
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    height: 32px;
    background-color: transparent;
    border-radius: 6px;
    color: #2f80ed;
    font-size: 14px;
    cursor: pointer;

    &:hover:not(:disabled) {
      background-color: #e6f0fd;
    }

    &:active:not(:disabled) {
      background-color: #cde1fb;
    }

    & svg {
      margin-left: 8px;
    }
  `,
};
