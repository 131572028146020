import styled, { css } from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';

interface BadgeProps {
  isSmall?: boolean;
}

const Badge = styled.div<BadgeProps>`
  position: absolute;
  top: 1px;
  right: 1px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.4);
  padding: 0 11px;
  height: ${({ isSmall }) => (isSmall ? '30px' : '38px')};
  display: flex;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

interface FieldProps {
  isSmall?: boolean;
  withIcon?: boolean;
  postfix?: any;
  meta: any;
}

interface IconProps {
  isSmall?: boolean;
}

interface PostfixProps {
  isSmall?: boolean;
}

interface ErrorProps {
  shifted?: boolean;
}

export default {
  Container: styled.div`
    position: relative;
    width: 100%;
  `,

  Badge,

  Field: styled.input<FieldProps>`
    padding: 1px 12px;
    width: 100%;
    height: ${({ isSmall }) => (isSmall ? '32px' : '40px')};
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    font-size: 16px;
    color: #000;
    transition: all 0.2s ease-in-out;
    outline: none;
    ${getBorderOnError}

    &::placeholder {
      color: #5c5c5c;
      transition: all 0.2s ease-in-out;
    }

    &:not(:disabled):hover {
      border-color: #000;
    }

    &:focus {
      padding: 0 10px;
      border-color: #2f80ed !important;
      border-width: 2px;

      + ${Badge} {
        top: 2px;
        right: 2px;
        height: ${({ isSmall }) => (isSmall ? '28px' : '36px')};
      }
    }

    &:focus::placeholder {
      opacity: 0;
    }

    &:disabled {
      color: #999;
      cursor: not-allowed;
    }

    ${({ withIcon }) =>
      withIcon &&
      css`
        padding-left: 40px;

        &:focus:not(:read-only) {
          padding-left: 40px !important;
        }
      `}
    ${({ postfix }) =>
      postfix &&
      css`
        padding-right: 32px;

        &:focus:not(:read-only) {
          padding-right: 32px !important;
        }
      `}
  `,

  Icon: styled.div<IconProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 40px;
    height: ${({ isSmall }) => (isSmall ? '32px' : '40px')};
    pointer-events: none;
  `,

  Postfix: styled.div<PostfixProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 32px;
    height: ${({ isSmall }) => (isSmall ? '32px' : '40px')};
    pointer-events: none;
    color: #5c5c5c;
  `,

  Count: styled.div`
    margin-top: 3px;
    font-size: 13px;
    line-height: 17px;
    text-align: right;
    color: rgba(0, 0, 0, 0.64);
  `,

  Error: styled.div<ErrorProps>`
    margin-top: ${(props) => (props.shifted ? '-19px' : null)};
  `,
};
