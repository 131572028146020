import React from 'react';
import get from 'lodash/get';
import Styled from './styles';
import { getUserName } from 'shared/helpers/user';
import UserTieIcon from '../../../../static/icons/user-tie.svg';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const renderName = (data: IHistoryItem['user']) => {
  if (!get(data, 'first_name') && !get(data, 'last_name')) {
    return 'Без имени';
  }

  return getUserName(data);
};

const AnnouncementHistoryModalName = ({ data }: { data: IHistoryItem['user'] }) => {
  if (!data) return null;

  return (
    <Styled.InfoUser>
      <UserTieIcon opacity={0.4} />
      {renderName(data)}
    </Styled.InfoUser>
  );
};

export default AnnouncementHistoryModalName;
