import React from 'react';
import { Field } from 'react-final-form';
import { PlainTable } from 'components/Table';
import { Radio } from 'components/FormControls';
import { required } from 'shared/helpers/validations';
import { formatFullDate, formatDotsDate } from 'shared/helpers/formatters/date';

const renderData = (dateStart, timeStart) => {
  if (dateStart && timeStart) {
    return formatFullDate(`${dateStart} ${timeStart}`);
  }

  if (dateStart) {
    return formatDotsDate(dateStart);
  }

  return null;
};

const EventRow = ({ data }: any) => (
  <PlainTable.Tr data-selenium={data.title}>
    <PlainTable.Td>
      <Field name="id" type="radio" value={data.id} validate={required}>
        {({ input, meta }) => (
          <Radio {...input} meta={meta} dataSelenium={`parser-event-radio-${data.title}`} />
        )}
      </Field>
    </PlainTable.Td>
    <PlainTable.Td>{data.title}</PlainTable.Td>
    <PlainTable.Td>{data.place && data.place.name}</PlainTable.Td>
    <PlainTable.Td minWidth="140px">{renderData(data.date_start, data.time_start)}</PlainTable.Td>
  </PlainTable.Tr>
);

export default EventRow;
