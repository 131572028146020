import React from 'react';
import Button from '../Button';
import { MobileOption, MobileOptionCheckbox, MobileOptionButton } from './styles';
import QuestionIcon from '../../../static/icons/question.svg';
import Tooltip from '../Tooltip';
import preventClick from 'shared/helpers/preventClick';

interface OptionProps {
  children: string;
  onClick: () => void;
  isSelected?: boolean;
  color?: string;
  helper: string;
}

const StatusDropdownMobileOption: React.FC<OptionProps> = ({
  children,
  onClick,
  isSelected,
  color,
  helper,
}) => (
  <MobileOption onClick={onClick}>
    <MobileOptionCheckbox color={color} isSelected={isSelected} />
    {children}
    <Tooltip text={helper} placement="left">
      <MobileOptionButton>
        <Button onlyIcon transparent onClick={preventClick}>
          <QuestionIcon />
        </Button>
      </MobileOptionButton>
    </Tooltip>
  </MobileOption>
);

StatusDropdownMobileOption.defaultProps = {
  isSelected: false,
  color: '#5c5c5c',
};

export default StatusDropdownMobileOption;
