import React from 'react';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import Button from '../../Button';
import WarningIcon from '../../../../static/icons/warning.svg';
import ExternalOrders from '../../Order/ExternalOrders';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
}

const OrderUpdatesModal: React.FC<ModalProps> = (props) => {
  const { tickets } = props.data;

  return (
    <Styled.Container>
      <Modal.Title>Произошла ошибка</Modal.Title>
      <Styled.Tickets>
        <ExternalOrders data={tickets} isOpen />
      </Styled.Tickets>
      <Styled.Warning>
        <WarningIcon />В заказе есть билеты, которые уже используются в других заказах, необходимо их удалить
        или заменить на другие, перед тем как заказ вернётся в продажу
      </Styled.Warning>
      <Modal.Footer>
        <Button type="button" onClick={props.closeModal}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Styled.Container>
  );
};

export default OrderUpdatesModal;
