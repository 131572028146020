import React from 'react';
import Button from '../../Button';
import BUTTON_ICONS from 'shared/constants/BUTTON_ICONS';
import Styled from './styles';

interface Props {
  onClick: () => void;
  data: any;
}

const ModalControlButton: React.FC<Props> = ({ onClick, data }) => (
  <Styled.ButtonWrapper>
    <Button onlyIcon secondary onClick={onClick} data={data}>
      <img src={BUTTON_ICONS.COG} alt="edit company" />
    </Button>
  </Styled.ButtonWrapper>
);

export default ModalControlButton;
