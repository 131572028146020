import React, { Component } from 'react';
import get from 'lodash/get';
import { NotificationManager } from 'react-notifications';
import { companySources } from 'entities/company';
import api from 'shared/services/api';
import DataPreview from '../../DataPreview';
import { getUserName } from 'shared/helpers/user';
import Button from '../../Button';
import { Radio } from '../../FormControls';
import Styled from './styles';
import Modal from '../../Modal';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  id: number;
  data: any;
  closeModal: ModalFunctions['closeModal'];
  // eslint-disable-next-line camelcase
  assignUser: (id: number | string, { user_id }: { user_id: number | string }) => any;
}

interface ModalState {
  data: any[];
  state: {
    isLoading: boolean;
  };
  selectedManager: number | string;
}

class AssignManagerModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      state: {
        isLoading: true,
      },
      selectedManager: null,
    };
  }

  componentDidMount() {
    api
      .get(companySources.managersAssign, { params: { limit: 9999 } })
      .then((response) => {
        this.setState({
          data: response.data.data.results,
          state: {
            isLoading: false,
          },
        });
      })
      .catch((err) => new Error(err));
  }

  assignManager = () => {
    const {
      assignUser,
      closeModal,
      data: { id },
    } = this.props;
    const { selectedManager } = this.state;

    assignUser(id, { user_id: selectedManager }).then((response) => {
      if (response.error) {
        NotificationManager.error(get(response, 'error.response.data.error.order'), 'Ошибка');
      }

      closeModal();
    });
  };

  selectManager = ({ target }) => {
    this.setState({
      selectedManager: Number(target.value),
    });
  };

  render() {
    const { closeModal } = this.props;
    const { selectedManager, data, state } = this.state;

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <DataPreview length={data.length} state={state}>
          <Modal.Title>Назначение менеджера</Modal.Title>
          <Styled.List>
            {data.map((manager) => (
              <Radio
                type="round"
                name="manager"
                value={manager.id}
                checked={Boolean(selectedManager)}
                onChange={this.selectManager}
              >
                {getUserName(manager)}
              </Radio>
            ))}
          </Styled.List>
          <Modal.Footer>
            <Button type="button" transparent onClick={closeModal}>
              Отмена
            </Button>
            <Button type="button" onClick={this.assignManager} disabled={!selectedManager}>
              Назначить
            </Button>
          </Modal.Footer>
        </DataPreview>
      </Styled.Container>
    );
  }
}

export default AssignManagerModal;
