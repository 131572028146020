import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import EditMemberForm from './EditMemberForm';
import Button from '../../Button';
import Spinner from '../../Spinner';
import { ModalFunctions } from '../../../../interfaces/modal';

const initialState = {
  data: {},
  isActive: true,
  isLoading: true,
};

interface ModalProps {
  data?: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  updateMembersUserPermissions: (data: any) => any;
  loadMembersUser: (id: string | number) => any;
  // eslint-disable-next-line camelcase
  setMemberIsActive: (id: string | number, { is_active }: { is_active: boolean }) => any;
  currentUserId: number;
}

interface IState {
  data: any;
  isActive: boolean;
  isLoading: boolean;
}

const EditMemberModal: React.FC<ModalProps> = ({
  data,
  loadMembersUser,
  setMemberIsActive,
  updateMembersUserPermissions,
  closeModal,
  forceCloseModal,
  currentUserId,
}) => {
  const [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    const { id } = data;

    loadMembersUser(id).then((response) => {
      const { data: newData } = response.payload;
      setState({
        isLoading: false,
        data: newData,
        isActive: newData.is_active,
      });
    });
  }, []);

  const toggleBan = () => {
    const {
      data: { id },
      isActive,
    } = state;
    setMemberIsActive(id, { is_active: !isActive }).then(() => {
      setState({
        ...state,
        isActive: !isActive,
      });
    });
  };

  const editMemeber = (values) => {
    const { id } = state.data;

    return updateMembersUserPermissions({
      ...values,
      permissions: map(values.permissions, (item) => item.value),
    }).then(() => {
      loadMembersUser(id);
      forceCloseModal();
    });
  };

  const { data: user, isLoading, isActive } = state;

  return isLoading ? (
    <Spinner center />
  ) : (
    <EditMemberForm
      title="Редактирование пользователя"
      onSubmit={editMemeber}
      toggleBan={toggleBan}
      isActive={isActive}
      isCurrentUser={data.id === currentUserId}
      initialValues={{
        ...user,
        permissions: map(user.permissions, (item) => ({ value: item.id, label: item.name })),
      }}
    >
      <Button type="button" transparent onClick={closeModal}>
        Отмена
      </Button>
      <Button type="submit">Сохранить</Button>
    </EditMemberForm>
  );
};

export default EditMemberModal;
