import config from 'config/config';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import COOKIES from 'shared/constants/COOKIES';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { createAttachments } from 'shared/lib/attachments';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';
import attachmentsSource from 'shared/sources/attachments';
import landingsPlaygroundsSources from 'shared/sources/landings/landingsPlaygrounds';
import { buildPlacesAttachments } from '../../helpers/landingHelpers';
import PlacesForm from './PlacesForm';

interface PlacesCreateProps {
  onSuccess: (data: any) => any;
  closeModal: () => void;
  onChangeLng: () => void;
}

const PlacesCreate: React.FC<PlacesCreateProps> = (props) => {
  const { onSuccess, closeModal, onChangeLng } = props;

  const handleCreate = async (formData) => {
    const { ru: placeData } = formData;
    const attachmentTypes = await attachmentsSource.getAttachmentsTypes('places');
    const attachments = await buildPlacesAttachments(placeData);
    const companyId = getCookie(COOKIES.COMPANY);

    const response = await api.post(landingsPlaygroundsSources.root, {
      place_id: placeData.place.value,
      old_id: placeData.place.value,
      slug: '',
      company_id: companyId,
    });
    await attachmentsSource.createPlacesAttachments(response.data.id, attachments);

    const infoAttachments = createAttachments(
      { attachments: [] },
      { lng: 'ru', ru: placeData },
      attachmentTypes,
    );

    await Promise.all(
      infoAttachments
        .filter((attachment) => !isEmpty(attachment.info))
        .map(async (attachment) => {
          const route = `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places`;

          const infoResponse = await api.post(
            `${route}/${response.data.id}/attachments/${attachment.attachment_type_id}/info`,
            {
              ...attachment.info,
              language_code: attachment.language_code,
            },
          );

          return infoResponse;
        }),
    );

    const translations = await landingsPlaygroundsSources.createTranslation(response.data.id, {
      ...placeData,
      language_code: LANGUAGES.RU,
    });

    await landingsPlaygroundsSources
      .createContentGroups(
        response.data.id,
        placeData.contentGroups.map((i) => i.value),
      )
      .catch((error) => {
        const errMessage = error.response?.data?.detail;
        if (errMessage && errMessage.includes('Place with content group')) {
          NotificationManager.error('Данная площадка с таким тегом существует', 'Ошибка');
        }
        return error;
      });

    await landingsPlaygroundsSources.createQnA(
      response.data.id,
      (placeData.qna || []).map((i) => i.value),
    );

    return translations;
  };

  return (
    <PlacesForm
      title="Новая площадка"
      onSubmit={handleCreate}
      onSuccess={onSuccess}
      closeModal={closeModal}
      onChangeLng={onChangeLng}
      initialValues={{
        item_create: true,
      }}
    />
  );
};

export default PlacesCreate;
