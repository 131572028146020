import React from 'react';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import { REPORTS_DESCRIPTIONS } from 'shared/constants/REPORTS';
import Button from '../../Button';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
}

const ReportsDescriptionModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    data: { type },
  } = props;

  return (
    <Styled.Container>
      <Modal.Title>Описание отчета</Modal.Title>
      <Styled.List>
        {REPORTS_DESCRIPTIONS[type].map(({ title, description }) => (
          <Styled.ListItem>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Description>{description}</Styled.Description>
          </Styled.ListItem>
        ))}
      </Styled.List>
      <Modal.Footer>
        <Button transparent type="button" onClick={closeModal}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Styled.Container>
  );
};

export default ReportsDescriptionModal;
