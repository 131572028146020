import { useUnit } from 'effector-react';
import React from 'react';
import { Form, Field } from 'react-final-form';
import Button from 'components/Button';
import FileInput from 'components/FormControls/FileInput';
import Input from 'components/FormControls/Input';
import Z_INDEX from 'shared/constants/Z_INDEX';
import { required } from 'shared/helpers/validations';
import { Modal, ModalClose } from 'shared/ui/modal';
import { editorImageModal } from '../model';
import Styled from './styles';

export const ImageModal = () => {
  const { handleInsertImage, getImageSize, imageSize } = useUnit({
    handleInsertImage: editorImageModal.insertedImage,
    getImageSize: editorImageModal.getImageSize,
    imageSize: editorImageModal.$imageSize,
  });

  return (
    <Modal zIndex={Z_INDEX.EDITOR_MODAL} toggler={editorImageModal.toggler}>
      {({ closeModal }) => {
        return (
          <Styled.Body className="modal__body">
            <ModalClose onClick={closeModal} />
            <Styled.Title>Вставить изображение</Styled.Title>
            <Form
              onSubmit={handleInsertImage}
              initialValues={{ imageWidth: imageSize?.width, imageHeight: imageSize?.height }}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Styled.FileInputWrapper>
                      <Field name="selectImage" validate={required}>
                        {({ input, meta }) => (
                          <FileInput
                            key={input.value}
                            placeholder="+"
                            withPreview
                            description="Выберите изображение"
                            {...input}
                            meta={meta}
                            wysiwygEditor
                            getImageSize={getImageSize}
                          />
                        )}
                      </Field>
                    </Styled.FileInputWrapper>

                    <Styled.InputWrapper>
                      <Field name="alt" validate={required}>
                        {({ input, meta }) => (
                          <Input label="Альтернативное описание" meta={meta} {...input} />
                        )}
                      </Field>
                    </Styled.InputWrapper>
                    <Styled.InputWrapper>
                      <Field name="imageWidth" validate={required}>
                        {({ input, meta }) => <Input label="Ширина изображения" meta={meta} {...input} />}
                      </Field>
                    </Styled.InputWrapper>
                    <Styled.InputWrapper>
                      <Field name="imageHeight" validate={required}>
                        {({ input, meta }) => <Input label="Высота изображения" meta={meta} {...input} />}
                      </Field>
                    </Styled.InputWrapper>

                    <Styled.ButtonWrapper>
                      <Button type="button" onClick={closeModal} transparent>
                        Отмена
                      </Button>

                      <Button>Сохранить</Button>
                    </Styled.ButtonWrapper>
                  </form>
                );
              }}
            />
          </Styled.Body>
        );
      }}
    </Modal>
  );
};
