import styled, { css } from 'styled-components';
import COLORS from 'shared/constants/COLORS';

interface CSSProps {
  editMode?: boolean;
  isPreorder?: boolean;
}

const Container = styled.label<CSSProps>`
  display: flex;

  & > input {
    display: none;
  }

  & > input:checked + span {
    background-color: ${COLORS.YELLOW};
    border-color: ${COLORS.YELLOW};

    ${({ editMode }) =>
      editMode &&
      css`
        &:hover {
          color: #969696;
          background-color: #f4f4f4;
          border-color: #e1e1e1;
        }

        & > div:after {
          background-color: #969696;
          border-top: 1px solid #fafafa;
          border-bottom: 1px solid #fafafa;
        }
      `}
  }

  &:hover > input:checked + span {
    ${({ editMode }) =>
      editMode &&
      css`
        & > div:after {
          background-color: #969696;
          border-top: 1px solid #fafafa;
          border-bottom: 1px solid #fafafa;
        }
      `}
  }

  ${({ editMode }) =>
    editMode &&
    css`
      & > input:checked + span {
        color: #969696;
        background-color: #fafafa;
        border-color: #fafafa;
      }

      & > input:checked + span > div:after {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        width: calc(100% + 8px);
        background-color: #969696;
        border-top: 1px solid #fafafa;
        border-bottom: 1px solid #fafafa;
      }
    `}
`;

const CheckboxCSS = css`
  display: block;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  user-select: none;

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:after {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: #fff6c2;
    border-color: ${COLORS.YELLOW};
  }
`;

const Checkbox = styled.span<CSSProps>`
  ${CheckboxCSS}

  ${({ editMode }) =>
    editMode &&
    css`
      &:hover {
        background-color: #fad7d7;
        border-color: #eb5757;
      }
    `}

  ${({ isPreorder }) =>
    isPreorder &&
    css`
      color: #f09e00;
    `}
`;

export default {
  Container,
  Checkbox,
};

export { CheckboxCSS };
