import Button from 'components/Button';
import RoleForm from 'components/Modals/RoleModal/RoleForm';
import Spinner from 'components/Spinner';
import compact from 'lodash/compact';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import replace from 'lodash/replace';
import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPermissions, loadCompanyPermissions, loadAdminPermissions } from 'entities/auth';
import {
  loadMemberCompany,
  loadMemberSources,
  updateMemberSources,
  loadMembersCompanies,
  updateMemberPermissions,
} from 'entities/members/companies';
import { loadMembersUser, loadMembersUsers, updateMembersUserPermissions } from 'entities/members/users';
import { createRole, updateRole, deleteRole, loadRoles, loadRole } from 'entities/role';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import ROLE_MODAL_TYPES from 'shared/constants/ROLE_MODAL_TYPES';
import { ModalFunctions } from '../../../../interfaces/modal';


interface ModalProps {
  data?: object;
  loadRole: (id: string | number) => void;
  loadRoles: (query?: object) => void;
  router: Router;
  createRole: (data: object) => void;
  closeModal: ModalFunctions['closeModal'];
  updateRole: (data: object) => void;
  modalState: string;
  loadMembersUser: (id: string | number) => void;
  loadPermissions: (id?: string | number) => void;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadMembersUsers: () => void;
  loadMemberSources: () => void;
  loadMemberCompany: (id: string | number) => void;
  updateMemberSources: (data: object) => void;
  loadAdminPermissions: () => void;
  loadMembersCompanies: () => void;
  loadCompanyPermissions: (id?: string | number) => void;
  updateMemberPermissions: (data: object) => void;
  updateMembersUserPermissions: (data: object) => void;
}

interface ModalState {
  data: any;
  isLoading: boolean;
  permissions: [];
}

/* eslint-disable */
const getPermissionsFromData = (data: object) =>
  compact(map(omit(data, 'name'), (item, index) => item && Number(replace(index, 'id_', ''))));
/* eslint-enable */

class Role extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      isLoading: true,
      permissions: [],
    };

    this.getTypeData = this.getTypeData.bind(this);
  }

  componentDidMount() {
    const { id, type } = this.props.data;
    const isSources = type === ROLE_MODAL_TYPES.SOURCES;

    const { permissions, detail } = this.getTypeData();

    permissions(id).then((response: any) => {
      const { results } = response.payload.data.data;
      const permissionsData = isSources
        ? map(results, (item) => ({
            id: item.id,
            name: item.title,
          }))
        : results;

      this.setState(
        {
          isLoading: Boolean(id),
          permissions: permissionsData,
        },
        () => {
          if (id) {
            detail(id).then((detailResponse) => {
              const detailResults = detailResponse.payload.data;
              const detailData = isSources
                ? { ...detailResults, permissions: detailResults.sources }
                : detailResults;

              this.setState({
                isLoading: false,
                data: detailData,
              });
            });
          }
        },
      );
    });
  }

  getTypeData() {
    const {
      data: { type },
    } = this.props;

    switch (type) {
      case ROLE_MODAL_TYPES.USER:
        return {
          permissions: this.props.loadPermissions,
          detail: this.props.loadRole,
          update: this.props.updateRole,
          editTitleText: 'Редактирование роли',
          list: this.props.loadRoles,
        };

      case ROLE_MODAL_TYPES.COMPANY:
        return {
          permissions: this.props.loadCompanyPermissions,
          detail: this.props.loadMemberCompany,
          update: this.props.updateMemberPermissions,
          editTitleText: 'Редактирование ограничений',
          list: this.props.loadMembersCompanies,
        };

      case ROLE_MODAL_TYPES.SOURCES:
        return {
          permissions: this.props.loadMemberSources,
          detail: this.props.loadMemberCompany,
          update: this.props.updateMemberSources,
          editTitleText: 'Редактирование доступов',
          list: this.props.loadMembersCompanies,
        };

      case ROLE_MODAL_TYPES.ADMIN:
        return {
          permissions: this.props.loadAdminPermissions,
          detail: this.props.loadMembersUser,
          update: this.props.updateMembersUserPermissions,
          editTitleText: 'Редактирование ограничений',
          list: this.props.loadMembersUsers,
        };

      default:
        return null;
    }
  }

  render() {
    const {
      modalState,
      data: { type },
      router: { query },
    } = this.props;

    const {
      permissions,
      isLoading,
      data: { id },
    } = this.state;

    const { editTitleText, list, update, detail } = this.getTypeData();

    const handleOnSuccess = () => {
      if (id) {
        return detail(id).then(() => this.props.forceCloseModal());
      }

      return list(query).then(() => this.props.forceCloseModal());
    };

    if (isLoading) return <Spinner center />;

    switch (modalState) {
      case MODAL_STATES.CREATE: {
        const handleSubmit = (data) => {
          const { name } = data;

          return this.props.createRole({ name, permissions: getPermissionsFromData(data) });
        };

        return (
          <RoleForm
            type={type}
            title="Создание роли"
            onSubmit={handleSubmit}
            onSuccess={handleOnSuccess}
            permissions={permissions}
          >
            <Button type="button" transparent onClick={this.props.closeModal}>
              Отмена
            </Button>
            <Button type="submit">Создать</Button>
          </RoleForm>
        );
      }

      case MODAL_STATES.EDIT: {
        const { name, permissions: currentPermissions } = this.state.data;

        const initialPermissions = reduce(
          currentPermissions,
          (acc, curr) => {
            // @ts-expect-error delete-error
            acc[`id_${isObject(curr) ? curr.id : curr}`] = true;

            return acc;
          },
          {},
        );

        const handleSubmit = (data) =>
          update({
            id,
            name: data.name,
            permissions: getPermissionsFromData(data),
          });

        return (
          <RoleForm
            type={type}
            title={editTitleText}
            onSubmit={handleSubmit}
            onSuccess={handleOnSuccess}
            permissions={permissions}
            initialValues={{ name, ...initialPermissions }}
          >
            <Button type="button" transparent onClick={this.props.closeModal}>
              Отмена
            </Button>
            <Button>Сохранить</Button>
          </RoleForm>
        );
      }

      default:
        break;
    }
    return null;
  }
}


const mapDispatchToProps = {
  loadRole,
  loadRoles,
  createRole,
  updateRole,
  deleteRole,
  loadMembersUser,
  loadPermissions,
  loadMembersUsers,
  loadMemberSources,
  loadMemberCompany,
  updateMemberSources,
  loadAdminPermissions,
  loadMembersCompanies,
  loadCompanyPermissions,
  updateMemberPermissions,
  updateMembersUserPermissions,
};

export const RoleModal =  withRouter(connect(null, mapDispatchToProps)(Role));
