import styled, { css, createGlobalStyle } from 'styled-components';
import StyledTabs from '../../../Tabs/styles';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import { layoutScrollbar } from '../../../../app/styles/scrollbar';

const Number = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: auto;
  height: 20px;
  padding: 0 4px;
  border-radius: 2px 0 0 0;
  font-size: 12px;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: #2dc472;
  opacity: 0;
`;

const Variant = styled.label<{ checked?: boolean; disabled?: boolean }>`
  display: block;
  position: relative;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 0;
  opacity: ${({ disabled }) => disabled && '0.3'};

  ${({ checked }) =>
    checked &&
    `
    border-color: #2dc472;

    ${Number} {
      opacity: 1;
    }
  `}

  img {
    width: 100%;
  }

  &:hover {
    border-color: #2dc472;
    cursor: pointer;

    ${Number} {
      opacity: 1;
    }
  }
`;

export default {
  GlobalStyles: createGlobalStyle`
    .modal__body {
      display: flex;
      flex-direction: column;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      .modal__body {
        height: 100vh;
        padding: 8px 0 0;
      }
    }
  `,
  Main: styled.div`
    position: relative;
    display: flex;
    padding: 40px 40px 72px;
    box-sizing: border-box;
    height: calc(100vh - 72px - 16px);
    flex-grow: 1;

    @media ${MEDIA_QUERIES.MOBILE} {
      box-sizing: border-box;
      padding: 16px 16px 57px;
      flex-shrink: 1;
      height: calc(100vh - 500px);
    }
  `,

  Progress: styled.div`
    flex-shrink: 0;
    flex-grow: 1;
    width: 198px;
    margin-right: 32px;
    overflow: hidden;
    overflow-y: auto;

    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      ${layoutScrollbar}
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      position: absolute;
      top: -26px;
      left: 0;
      margin-right: 0;
      width: 100%;
      overflow-x: auto;
    }
  `,

  Divider: styled.div`
    flex-shrink: 0;
    width: 97%;
    height: 2px;
    background-color: #e1e1e1;
    margin: 0 auto 16px;
  `,

  TitleLabel: styled.span`
    @media ${MEDIA_QUERIES.MOBILE} {
      display: none;
    }
  `,

  CheckboxContainer: styled.div`
    @media (max-width: ${MEDIA_QUERIES.MOBILE}) {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  `,

  Container: styled.div<{ withTabs?: boolean; withTitle?: boolean }>`
    position: relative;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;

    ${StyledTabs.HorizontalTabs} {
      position: absolute;
      top: 40px;
      left: 0;

      .menu-wrapper {
        padding: 0;
      }

      @media ${MEDIA_QUERIES.MOBILE} {
        top: 32px;
      }
    }

    ${(props) =>
      props.withTabs &&
      css`
        @media ${MEDIA_QUERIES.MOBILE} {
          margin: 0 -16px;
        }
      `}
  `,

  Content: styled.div`
    flex-grow: 1;
    padding-top: 8px;
    overflow: hidden;
    overflow-y: auto;

    & > div > ul {
      z-index: 3;
    }

    @media ${MEDIA_QUERIES.TABLET_AND_DESKTOP} {
      ${layoutScrollbar};
    }
  `,

  Header: styled.div<{ withTabs?: boolean }>`
    margin-bottom: ${(props) => (props.withTabs ? 66 : 24)}px;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding-left: ${({ withTabs }) => withTabs && '16px'};
      margin-bottom: ${(props) => (props.withTabs ? 58 : 8)}px;
    }
  `,

  Title: styled.div`
    font-size: 16px;
    line-height: 21px;
  `,

  SubTitle: styled.div`
    margin: 24px 0 32px;
    font-size: 16px;
    line-height: 21px;
  `,

  Variant,

  Mobile: styled.button`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 0 2px 0 2px;
    background-color: #2dc472;
    cursor: pointer;

    svg path {
      fill: #fff;
    }
  `,

  Radio: styled.input`
    position: absolute;
    left: -9999px;
    opacity: 0;
  `,

  Label: styled.div<{ checked?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    display: ${(props) => (props.checked ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #2dc472;
    border-bottom-right-radius: 4px;
    color: #fff;

    svg {
      width: 8px;
      height: 8px;
    }
  `,

  CheckIconWrapper: styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    background: #fff;
    border-radius: 14px;
  `,

  Buttons: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 8px;
    right: 8px;
    bottom: 8px;
    margin: auto;
    padding: 15px;
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #e1e1e1;
    z-index: 3;

    button {
      margin: 0 8px;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      left: 0;
      right: 0;
      bottom: 0;
      padding: 8px 0;
    }
  `,

  SubmitButtons: styled.div`
    display: flex;
    margin-left: auto;
  `,

  ListWrapper: styled.div`
    padding: 0 12px;
  `,

  List: styled.div<{ grid?: boolean }>`
    ${(props) =>
      props.grid &&
      css`
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
      `};
  `,

  Item: styled.div<{ grid?: boolean; width?: string; logo?: boolean }>`
    margin-bottom: 24px;
    box-sizing: border-box;

    ${(props) =>
      props.grid &&
      css`
        width: ${props.width || '33.333%'};
        padding: 0 12px;

        @media ${MEDIA_QUERIES.MOBILE} {
          width: 100%;
        }
      `}

    ${(props) =>
      props.logo &&
      css`
        padding: 0 12px;

        ${Variant} {
          padding: 28px 24px;
          box-sizing: border-box;
          min-width: 95px;

          img {
            height: 50px;
          }
        }

        &:not(:last-child) {
          margin-right: 32px;
        }
      `}
  `,

  BorderedItem: styled.div`
    border-bottom: 2px solid #e1e1e1;
    padding-bottom: 16px;
    margin-bottom: 16px;
  `,

  FormRow: styled.div<{ withBorder?: boolean }>`
    display: flex;
    margin-bottom: ${({ withBorder }) => (withBorder ? '32px' : '48px')};

    ${({ withBorder }) =>
      withBorder &&
      css`
        max-width: 544px;
        padding-bottom: 32px;
        border-bottom: 1px solid #e1e1e1;
      `}

    @media ${MEDIA_QUERIES.MOBILE} {
      flex-direction: column;
    }
  `,

  FormDescription: styled.div`
    width: 165px;
    margin-right: 24px;

    @media ${MEDIA_QUERIES.MOBILE} {
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  `,

  FormSmallDescription: styled.div`
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.64);
  `,

  FormControls: styled.div<{ large?: boolean }>`
    width: ${(props) => (props.large ? 500 : 355)}px;

    @media ${MEDIA_QUERIES.MOBILE} {
      width: 100%;
    }
  `,

  FormControl: styled.div<{ child?: boolean; width?: number; grid?: boolean; withOffset?: boolean }>`
    margin-top: ${({ child }) => child && '-8px'};
    margin-bottom: 24px;
    max-width: ${({ width }) => `${width}px`};

    ${(props) =>
      props.grid &&
      css`
        display: flex;
        margin: ${props.withOffset ? '0 -12px 24px' : '0 -12px'};

        @media ${MEDIA_QUERIES.MOBILE} {
          flex-direction: column;
          margin: 0;
        }
      `}
  `,

  Column: styled.div`
    padding: 0 12px;

    @media ${MEDIA_QUERIES.MOBILE} {
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  `,

  ButtonPreview: styled.div`
    margin-left: 48px;
  `,

  Button: styled.div`
    padding: 14px 40px;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  `,

  TwistingContainer: styled.div`
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 100%;
    margin-left: 32px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #e1e1e1;
    }

    &:before {
      top: -12px;
      left: 0;
      width: 2px;
      height: 38px;
    }

    &:after {
      top: 0;
      left: -12px;
      height: 2px;
      width: 50px;
    }
  `,

  TwistingPreview: styled.div<{ twisting?: number }>`
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    width: 40px;
    height: 28px;
    border-width: 2px;
    border-style: solid;
    border-color: #2dc472 transparent transparent #2dc472;
    border-top-left-radius: ${({ twisting }) => `${twisting}px`};
  `,

  SmallInput: styled.div`
    position: relative;
    max-width: 166px;
  `,

  BackButton: styled.div`
    svg {
      display: none;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 0;
      }

      svg {
        display: block;
        flex-shrink: 0;
      }
    }
  `,

  Sections: styled.div`
    width: 100%;
    max-width: 355px;
  `,

  SectionsItem: styled.div<{ withShadow?: boolean }>`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: ${({ withShadow }) => withShadow && '0px 4px 16px rgba(0, 0, 0, 0.08)'};

    &:active {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      z-index: 1;
      cursor: grabbing;
    }
  `,

  Grab: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: calc(100% - 56px);

    svg {
      flex-shrink: 0;
      cursor: grab;
    }
  `,

  SectionField: styled.div<{ large?: boolean }>`
    margin-top: 16px;

    ${({ large }) =>
      large &&
      css`
        margin-top: 0;

        &:not(:first-child) {
          margin-top: 32px;
        }
      `}
  `,

  LangSwitcher: styled.div`
    display: inline-block;
    margin-left: 16px;

    & select {
      text-indent: 0;
    }
  `,

  QnaField: styled.div`
    position: relative;
  `,

  QnaDeleteButton: styled.div`
    position: absolute;
    top: 0;
    right: -48px;
  `,

  AddNotification: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 544px;
  `,

  NotificationContainer: styled.div`
    position: relative;
  `,

  Notification: styled.div<{ rounding?: number }>`
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 16px;
    width: 355px;
    border: 1px solid ${({ color }) => color || '#fff'};
    border-radius: ${({ rounding }) => `${rounding}px` || '0px'};
    padding: 15px 45px 15px 15px;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
    background: #fff;
    overflow: hidden;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: ${({ color }) => color || '#fff'};
    }
  `,

  NotificationClose: styled.button`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
    color: #000;
    font-size: 24px;
    opacity: 0.4;
  `,

  Number,
};
