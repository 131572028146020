import React from 'react';
import get from 'lodash/get';
import Styled from './styles';
import { renderTickets } from 'shared/helpers/tickets';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalTicketSell: React.FC<IHistoryItem> = ({ data, user }) => {
  if (!get(data, 'tickets')) return null;
  if (data.tickets.length === 0) return null;

  return (
    <Styled.Info>
      <Styled.InfoTitle>Продажа билетов</Styled.InfoTitle>
      <AnnouncementHistoryModalName data={user} />
      <Styled.InfoText>
        {`Были проданы билеты: `}
        {renderTickets(data.tickets)}
      </Styled.InfoText>
    </Styled.Info>
  );
};

export default AnnouncementHistoryModalTicketSell;
