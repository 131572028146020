import styled from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';

const BORDER = '#EB5757 !important';
const BORDER_ERROR = '#E1E1E1 !important';

interface FieldProps {
  plain?: boolean;
  meta: {
    touched?: boolean;
    error?: string;
  };
  height?: string;
}

interface ErrorProps {
  shifted?: boolean;
}

export default {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  `,

  Field: styled.textarea<FieldProps>`
    height: ${({ height }) => (height ? height : '80px')};
    min-height: 40px;
    box-sizing: border-box;
    padding-top: 8px;
    width: 100%;
    background: #fff;
    border: ${(props) => (props.plain ? 0 : '1px solid #e1e1e1')};
    border-radius: 8px;
    outline: none;
    font-size: 16px;
    color: #000;
    transition: border-color 0.2s ease-in-out;
    padding: 8px 11px;
    border-color: ${({ meta }) => (meta && meta.error && meta.touched ? BORDER : BORDER_ERROR)};
    resize: vertical;
    ${getBorderOnError};

    &:focus:not(:read-only) {
      border-color: #2f80ed !important;
      border-width: 2px;
      padding: 7px 10px;
    }

    &::placeholder {
      color: #999;
      transition: all 0.2s ease-in-out;
    }

    &:focus::placeholder {
      opacity: 0;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${(props) => (props.disabled ? '#f7f7f7 !important' : '#FFF !important')};
    }
    maxlength: ${({ maxLength }) => maxLength || undefined};
  `,

  Count: styled.div`
    margin-top: 3px;
    font-size: 13px;
    line-height: 17px;
    text-align: right;
    color: rgba(0, 0, 0, 0.64);
  `,

  Error: styled.div<ErrorProps>`
    margin-top: ${(props) => (props.shifted ? '-19px' : null)};
  `,
};
