import React from 'react';
import Styled from './styles';
import PlusIcon from '../../../../static/icons/plus.svg';
import EventInfo from '../../EventInfo/EventInfo';

interface ModalProps {
  data: any;
  selectEvent: (data: any) => void;
}

const ReportsEventsFilterModal: React.FC<ModalProps> = (props) => {
  const { data, selectEvent } = props;

  const {
    title,
    children,
    date_start: dateStart,
    time_start: timeStart,
    // @ts-ignore
    place: { name: place } = {},
  }: any = data;

  const eventDateTime = `${dateStart} ${timeStart}`;

  const handleClick = () => selectEvent(data);

  return (
    <Styled.ListItem>
      <button type="button" onClick={handleClick}>
        <EventInfo
          inTable
          withParentIndicator
          childrens={children}
          date={eventDateTime}
          name={title}
          place={place}
        />
        <Styled.ListItemIcon>
          <PlusIcon />
        </Styled.ListItemIcon>
      </button>
    </Styled.ListItem>
  );
};

export default ReportsEventsFilterModal;
