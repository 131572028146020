import { isArray, isEmpty } from 'lodash';
import { Language } from '../api/types';
import { AttachmentItem, AttachmentType } from '../constants/ATTACHMENTS_TYPE';
import { FILE_TYPES_REGEX } from '../constants/REGEX';
import { EventAttachments } from 'shared/api/market/events';

interface AttachmentTypes {
  attachment_type: { code: string };
}

interface IAttachment {
  data: object;
  attachment_type: {
    id: number;
    title: string;
    code: string;
    multiple: boolean;
  };
  info: { title: string; alt: string; language_code: Language }[];
}
interface AttachmentProps {
  attachments: IAttachment[];
}

const attachment = (item: AttachmentProps, type: string) =>
  item?.attachments?.find((i: AttachmentTypes) => i.attachment_type.code === type);

export const filterCreateAttachments = (data: AttachmentProps, formData: any) =>
  (!attachment(data, AttachmentType.Cover) && formData[formData.lng]?.cover) ||
  (!attachment(data, AttachmentType.Preview) && formData[formData.lng]?.preview) ||
  (!attachment(data, AttachmentType.Gallery) && formData[formData.lng]?.gallery) ||
  (!attachment(data, AttachmentType.Main_Image) && formData[formData.lng]?.main_image) ||
  (!attachment(data, AttachmentType.Schemas) && formData[formData.lng]?.schemas) ||
  (!attachment(data, AttachmentType.Gallery) && formData[formData.lng]?.ticket_cover);

export const createAttachments = (item: any, formData: any, attachmentTypes: any) => {
  const attachments: EventAttachments[] = [];

  const attachmentItemCover = attachment(item, AttachmentType.Cover);
  const attachmentItemPreview = attachment(item, AttachmentType.Preview);
  const attachmentItemGallery = attachment(item, AttachmentType.Gallery);
  const attachmentItemMainImage = attachment(item, AttachmentType.Main_Image);
  const attachmentItemSchemas = attachment(item, AttachmentType.Schemas);

  if (formData[formData.lng]?.cover && !attachmentItemCover && !isEmpty(formData[formData.lng].cover)) {
    const attachmentType = attachmentTypes[AttachmentType.Cover];
    attachments.push({
      attachment_type_id: attachmentType!.id,
      data: formData[formData.lng].cover,
      language_code: formData.lng,
      info: {
        title: formData[formData.lng]?.cover_title || '',
        alt: formData[formData.lng]?.cover_alt || '',
      },
    });
  }

  if (
    formData[formData.lng]?.preview &&
    !attachmentItemPreview &&
    !isEmpty(formData[formData.lng]?.preview)
  ) {
    const attachmentType = attachmentTypes[AttachmentType.Preview];
    attachments.push({
      attachment_type_id: attachmentType!.id,
      data: formData[formData.lng].preview,
      language_code: formData.lng,
      info: {
        title: formData[formData.lng]?.preview_title || '',
        alt: formData[formData.lng]?.preview_alt || '',
      },
    });
  }

  if (
    formData[formData.lng]?.main_image &&
    !attachmentItemMainImage &&
    !isEmpty(formData[formData.lng]?.main_image)
  ) {
    const attachmentType = attachmentTypes[AttachmentType.Main_Image];

    attachments.push({
      attachment_type_id: attachmentType!.id,
      data: formData[formData.lng].main_image,
      language_code: formData.lng,
      info: {
        title: formData[formData.lng]?.main_image_title || '',
        alt: formData[formData.lng]?.main_image_alt || '',
      },
    });
  }

  if (formData.ru?.ticket_cover && !attachmentItemGallery && !isEmpty(formData.ru?.ticket_cover)) {
    const attachmentType = attachmentTypes[AttachmentType.Gallery];

    attachments.push({
      attachment_type_id: attachmentType!.id,
      data: [formData.ru.ticket_cover],
    });
  }

  if (formData.ru?.gallery && !attachmentItemGallery && !isEmpty(formData.ru.gallery)) {
    const attachmentType = attachmentTypes[AttachmentType.Gallery];
    const galleryWebpAttachmentType = attachmentTypes[AttachmentType.Gallery_Web];
    attachments.push({
      attachment_type_id: attachmentType!.id,
      data: formData.ru.gallery,
    });
    attachments.push({
      attachment_type_id: galleryWebpAttachmentType.id,
      data: formData.ru.gallery.map((img: string) => img.replace(FILE_TYPES_REGEX, 'webp')),
    });
  }

  if (formData.ru.schemas && !attachmentItemSchemas && !isEmpty(formData.ru.schemas)) {
    const attachmentType = attachmentTypes[AttachmentType.Schemas];
    attachments.push({
      attachment_type_id: attachmentType!.id,
      data: formData.ru.schemas,
    });
    attachments.push({
      attachment_type_id: AttachmentItem.schemas_webp.id,
      data: formData.ru.schemas.map((img: string) => img.replace(FILE_TYPES_REGEX, 'webp')),
    });
  }

  return attachments;
};

export const filterUpdateAttachments = (data: any, formData: any) =>
  (attachment(data, AttachmentType.Cover) &&
    formData[formData.lng]?.cover &&
    !isEmpty(formData[formData.lng]?.cover)) ||
  (attachment(data, AttachmentType.Preview) &&
    formData[formData.lng]?.preview &&
    !isEmpty(formData[formData.lng]?.preview)) ||
  (attachment(data, AttachmentType.Gallery) && formData.ru.gallery && !isEmpty(formData.ru.gallery)) ||
  (attachment(data, AttachmentType.Main_Image) &&
    formData[formData.lng].main_image &&
    !isEmpty(formData[formData.lng].main_image)) ||
  (attachment(data, AttachmentType.Schemas) && formData.ru.schemas && !isEmpty(formData.ru.schemas));

export const updateAttachments = (item: any, formData: any) => {
  const attachments = [];
  const attachmentItemCover = attachment(item, AttachmentType.Cover);
  const attachmentItemPreview = attachment(item, AttachmentType.Preview);
  const attachmentItemGallery = attachment(item, AttachmentType.Gallery);
  const attachmentItemGalleryWebp = attachment(item, AttachmentType.Gallery_Web);
  const attachmentItemMainImage = attachment(item, AttachmentType.Main_Image);
  const attachmentItemSchemas = attachment(item, AttachmentType.Schemas);

  if (formData[formData.lng]?.cover && !isEmpty(formData[formData.lng]?.cover) && attachmentItemCover) {
    attachments.push({
      attachment_type_id: attachmentItemCover.attachment_type.id,
      data: formData[formData.lng]?.cover,
      language_code: formData.lng,
      info: {
        is_create: !attachmentItemCover.info?.find((i) => i.language_code === formData.lng),
        title: formData[formData.lng]?.cover_title || '',
        alt: formData[formData.lng]?.cover_alt || '',
      },
    });
  }

  if (formData[formData.lng]?.preview && !isEmpty(formData[formData.lng]?.preview) && attachmentItemPreview) {
    attachments.push({
      attachment_type_id: attachmentItemPreview.attachment_type.id,
      data: formData[formData.lng]?.preview,
      language_code: formData.lng,
      info: {
        is_create: !attachmentItemPreview.info?.find((i) => i.language_code === formData.lng),
        title: formData[formData.lng]?.preview_title || '',
        alt: formData[formData.lng]?.preview_alt || '',
      },
    });
  }

  if (
    formData[formData.lng]?.main_image &&
    !isEmpty(formData[formData.lng].main_image) &&
    attachmentItemMainImage
  ) {
    attachments.push({
      attachment_type_id: attachmentItemMainImage.attachment_type.id,
      data: formData[formData.lng].main_image,
      language_code: formData.lng,
      info: {
        is_create: !attachmentItemMainImage.info?.find((i) => i.language_code === formData.lng),
        title: formData[formData.lng]?.main_image_title || '',
        alt: formData[formData.lng]?.main_image_alt || '',
      },
    });
  }

  if (formData.ru?.gallery && !isEmpty(formData.ru.gallery) && attachmentItemGallery) {
    attachments.push({
      attachment_type_id: attachmentItemGallery.attachment_type.id,
      data: formData.ru.gallery,
    });
    attachments.push({
      attachment_type_id: attachmentItemGalleryWebp?.attachment_type.id,
      data: formData.ru.gallery.map((img: string) => img.replace(FILE_TYPES_REGEX, 'webp')),
    });
  }

  if (formData?.ru?.schemas && !isEmpty(formData.ru.schemas) && attachmentItemSchemas) {
    attachments.push({
      attachment_type_id: attachmentItemSchemas.attachment_type.id,
      data: formData?.ru?.schemas,
    });
    attachments.push({
      attachment_type_id: AttachmentItem.schemas_webp.id,
      data: formData?.ru?.schemas.map((img: string) => img.replace(FILE_TYPES_REGEX, 'webp')),
    });
  }

  if (formData.ru?.ticket_cover && !isEmpty(formData.ru.ticket_cover) && attachmentItemGallery) {
    attachments.push({
      attachment_type_id: attachmentItemGallery.attachment_type.id,
      data: isArray(formData.ru.ticket_cover) ? formData.ru.ticket_cover : [formData.ru.ticket_cover],
    });
  }

  return attachments;
};

export const filterDeleteAttachments = (data: any, formData: any, eventAttachment?: boolean) => {
  const itemCover = attachment(data, AttachmentType.Cover);
  const itemPreview = attachment(data, AttachmentType.Preview);
  const itemGallery = attachment(data, AttachmentType.Gallery);
  const itemMainImage = attachment(data, AttachmentType.Main_Image);
  const itemSchemas = attachment(data, AttachmentType.Schemas);

  const galleryForm = eventAttachment ? isEmpty(formData.ru?.ticket_cover) : isEmpty(formData?.ru?.gallery);

  if (
    (itemCover && isEmpty(formData.ru.cover)) ||
    (itemPreview && isEmpty(formData.ru.preview)) ||
    (itemGallery && galleryForm) ||
    (itemMainImage && isEmpty(formData.ru.main_image)) ||
    (itemSchemas && isEmpty(formData.ru.schemas))
  )
    return true;
  return false;
};

export const deleteAttachments = (item: any, data: any, eventAttachment?: boolean) => {
  const attachments = [];
  const attachmentItemCover = attachment(item, AttachmentType.Cover);
  const attachmentItemPreview = attachment(item, AttachmentType.Preview);
  const attachmentItemGallery = attachment(item, AttachmentType.Gallery);
  const attachmentItemGalleryWebp = attachment(item, AttachmentType.Gallery_Web);
  const attachmentItemMainImage = attachment(item, AttachmentType.Main_Image);
  const attachmentItemSchemas = attachment(item, AttachmentType.Schemas);

  if (data.ru?.cover && isEmpty(data.ru.cover) && attachmentItemCover) {
    attachments.push({
      id: attachmentItemCover.attachment_type.id,
    });
  }

  if (data.ru?.preview && isEmpty(data.ru.preview) && attachmentItemPreview) {
    attachments.push({
      id: attachmentItemPreview.attachment_type.id,
    });
  }

  if (data.ru?.main_image && isEmpty(data.ru.main_image) && attachmentItemMainImage) {
    attachments.push({
      id: attachmentItemMainImage.attachment_type.id,
    });
  }

  if (eventAttachment) {
    if (
      !data.ru.ticket_cover &&
      (isEmpty(data.ru.ticket_cover) || !data.ru.ticket_cover) &&
      attachmentItemGallery
    ) {
      attachments.push({
        id: attachmentItemGallery.attachment_type.id,
      });
    }
  }

  if (data?.ru?.gallery && isEmpty(data.ru?.gallery) && attachmentItemGallery) {
    attachments.push({
      id: attachmentItemGallery.attachment_type.id,
    });
    attachments.push({
      id: attachmentItemGalleryWebp?.attachment_type.id,
    });
  }

  if (data.ru.schemas && isEmpty(data.ru.schemas) && attachmentItemSchemas) {
    attachments.push({
      id: attachmentItemSchemas.attachment_type.id,
    });
    attachments.push({
      id: AttachmentItem.schemas_webp.id,
    });
  }

  return attachments;
};
