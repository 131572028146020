import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: ${BREAKPOINTS.XL}) {
      width: calc(100% - 24px);
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: auto;
      margin: 0 -16px;
    }

    .data-preview__message {
      position: absolute;
      top: 35vh !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  `,

  SpinnerOverlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
