import styled, { css } from 'styled-components';
import { rgba } from 'polished';

interface ContainerProps {
  readonly?: boolean;
  openUp?: boolean;
  left?: boolean;
}

interface MobileContainerProps {
  isReadOnly?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;

  & input {
    max-height: 0;
    position: absolute;
    pointer-events: none;
  }

  ${({ readonly }) =>
    readonly &&
    css`
      .react-select__indicators {
        display: none;
      }

      .react-select__value-container {
        justify-content: center;
      }
    `}

  ${({ openUp }) =>
    openUp &&
    css`
      .react-select__menu-list {
        bottom: 25px;
      }
    `}

  ${({ left }) =>
    left &&
    css`
      .react-select__menu-list {
        left: 0;
        right: auto;
      }
    `}
`;

const Disabled = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || '#999'};
  font-weight: 400;
  font-size: 12px;
  height: 24px;
  width: 80px;
  padding: 0 12px;
  text-transform: uppercase;
`;

const MobileContainer = styled.div<MobileContainerProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ isReadOnly }) => isReadOnly && 'center'};
  font-weight: 400;
  font-size: 12px;
  height: 24px;
  width: 80px;
  border-radius: 6px;
  padding: 0 12px;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    fill: ${({ color }) => color};
  }

  ${({ color }) =>
    color &&
    css`
      color: ${color};
      border: 1px solid ${rgba(color, 0.4)};

      &:hover {
        background-color: ${rgba(color, 0.1)};
      }
    `}
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  transition: 0.2s ease-in-out;

  & svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
  }
`;

const styles = {
  control: (provided, state) => {
    const borderColor = rgba(state.selectProps.value.color, 0.4);
    const bgColor = rgba(state.selectProps.value.color, 0.1);
    return {
      ...provided,
      minWidth: 80,
      minHeight: 24,
      height: 24,
      borderStyle: 'solid',
      boxSizing: 'border-box',
      borderRadius: 6,
      paddingLeft: 8,
      paddingRight: 8,
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: bgColor,
      },

      borderWidth: '1px',
      borderColor,

      backgroundColor: (() => {
        if (state.menuIsOpen) {
          return bgColor;
        }

        return '#fff';
      })(),
    };
  },

  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    minHeight: 24,
    height: 24,

    input: {
      maxHeight: 'none !important',
    },
  }),

  singleValue: () => ({
    fontWeight: 'normal',
    fontSize: 12,
    color: '#000',
    textTransform: 'uppercase',
    letterSpacing: '0.04em',
    transition: 'all 0.2s ease-in-out',
    minHeight: 0,
    height: 'auto',
    lineHeight: '1',
    marginBottom: '3px',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    minHeight: 22,
    height: 22,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    transition: '0.2s ease-in-out',
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    minHeight: 22,
    height: 22,
    width: 10,
    alignItems: 'center',
    pointerEvents: 'none',
    color: state.selectProps.value.color,
  }),

  menu: () => ({
    zIndex: 10,
    position: 'relative',
  }),

  menuList: () => ({
    minWidth: 128,
    paddingTop: 8,
    paddingBottom: 8,
    position: 'absolute',
    right: 0,
    backgroundColor: '#fff',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
  }),

  option: () => ({
    position: 'relative',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 14,
    color: '#000',

    transition: 'background-color 0.2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  placeholder: () => ({
    display: 'none',
  }),
};

const MobileOption = styled.div`
  display: flex;
  align-items: center;
`;

const MobileOptionCheckbox = styled.div<{ isSelected?: boolean }>`
  box-sizing: border-box;
  position: relative;
  height: 16px;
  width: 16px;
  border: ${({ isSelected }) => (isSelected ? '5px' : '2px')} solid ${({ color }) => color};
  border-radius: 50%;
  margin-right: 12px;
  background: transparent;
`;

const MobileOptionButton = styled.div`
  margin-left: auto;

  svg {
    opacity: 0.4;
    transition: 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    svg {
      opacity: 1;
    }
  }
`;

export {
  Container,
  MobileContainer,
  Disabled,
  Arrow,
  styles,
  MobileOption,
  MobileOptionCheckbox,
  MobileOptionButton,
};
