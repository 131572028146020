import React, { useState } from 'react';
import Form from '../../../Form';
import Tabs from '../../../Tabs';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { getSelectedTab } from 'shared/helpers/constructor';

const TABS = [
  {
    text: 'Список',
    key: 1,
  },
  {
    text: 'Карточки',
    key: 2,
  },
];

const LIST = [
  1,
  2,
  3,
  8,
  9,
  10,
  11,
  12,
  13,
  15,
  16,
  17,
  18,
  21,
  23,
  24,
  26,
  { id: 33, info: 'Показ города' },
  34,
  35,
  36,
  { id: 37, info: 'На мобильной версии горизонтальная карусель' },
];
const CARDS = [
  4,
  5,
  6,
  7,
  14,
  19,
  20,
  22,
  25,
  27,
  { id: 28, info: 'На мобильном экране карточки располагаются вертикально' },
  { id: 29, info: 'На мобильном экране карточки располагаются горизонтально в карусели' },
  30,
  31,
  32,
];

const position = (item: any) => {
  const id = item.id || item;
  return (
    <Styled.Item grid width="50%" key={id} data-selenium={`variant-${id}`}>
      <Variant name="ui_config_events_top" value={id} info={item.info}>
        <img src={`/static/constructor/afisha/${id}_top.jpg`} alt="" />
      </Variant>
    </Styled.Item>
  );
};

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const AfishaStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => {
  const [tab, setTab] = useState(getSelectedTab(data.ui_config_events_top, LIST));

  return (
    <Styled.Container withTabs>
      <Styled.Header withTabs>
        <Styled.Title>Выберите дизайн лучших мероприятий Афиши</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Tabs tabs={TABS} onSelect={setTab} selectedTab={tab}>
          <Form
            onSubmit={onSubmit}
            initialValues={data}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Styled.ListWrapper>
                  <Styled.List grid>
                    {tab === 1 && (
                      <>
                        {LIST.slice(0, 3).map((item) => position(item))}
                        <Styled.Divider />
                        {LIST.slice(3, LIST.length).map((item) => position(item))}
                      </>
                    )}
                    {tab === 2 && CARDS.map((card) => position(card))}
                  </Styled.List>
                </Styled.ListWrapper>
                <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
              </form>
            )}
          />
        </Tabs>
      </Styled.Content>
    </Styled.Container>
  );
};

export default AfishaStep;
