import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
    .modal {
      &__wrapper {
        max-width: 432px;
      }
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      .modal {
      &__table-wrapper {
        vertical-align: bottom;
      }

      &__wrapper {
        max-width: 100%;
      }

      &__body {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        max-height: 100vh;
        padding: 16px 16px 32px;
        border-radius: 8px 8px 0 0;
        padding-bottom: 8px;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      }

      &__title {
        margin-bottom: 16px;
      }

      &__footer {
        margin-bottom: -8px;
      }
    }
    }
  `,

  Container: styled.div``,

  Fields: styled.div`
    /* .input-label {
      left: 30px;
    } */
    .ant-time-picker-input {
      padding-left: 30px;
      color: #000;
    }
    .ant-time-picker-icon {
      right: auto;
      left: 11px;
    }
    & > div {
      margin: 0 0 16px;
    }

    @media (min-width: ${BREAKPOINTS.SM}) {
      display: flex;
      margin: 0 -8px;

      & > div {
        width: 100%;
        margin: 0 8px;
      }
    }
  `,
};
