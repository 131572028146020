import React from 'react';
import Button from '../../../../Button';
import RuleIcon from '../../../../../../static/icons/widget-rule.svg';
import GroupIcon from '../../../../../../static/icons/widget-group.svg';

const ICONS = {
  'Добавить критерий': <RuleIcon />,
  'Добавить группу': <GroupIcon />,
};

interface ActionProps {
  label: string;
  className: string;
  handleOnClick: (e: any) => void;
  title: string;
}

const Action: React.FC<ActionProps> = (props) => {
  const { label, className, handleOnClick, title } = props;

  return (
    <Button transparent className={className} title={title} onClick={(e) => handleOnClick(e)}>
      {ICONS[label]}
      {label}
    </Button>
  );
};

export default Action;
