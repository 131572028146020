import styled from 'styled-components';

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  List: styled.ul`
    margin-top: -16px;
    display: flex;
    flex-direction: column;

    & > li:not(:last-child) {
      margin-bottom: 24px;
    }
  `,

  ListItem: styled.li`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    font-size: 14px;
    font-weight: bold;
  `,

  Description: styled.div`
    margin-top: 8px;
    font-size: 14px;
  `,
};
