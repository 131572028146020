import React from 'react';
import Styled from './styles';
import { ANNOUNCEMENT_STATUS_NAME } from 'shared/constants/ANNOUNCEMENT_STATUS';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalStatus: React.FC<IHistoryItem> = ({
  data: {
    new: { status },
  },
  user,
}) => (
  <Styled.Info>
    <Styled.InfoTitle>Статус изменен</Styled.InfoTitle>
    <AnnouncementHistoryModalName data={user} />
    <Styled.InfoText>{`Видимость была изменена на «${ANNOUNCEMENT_STATUS_NAME[status]}»`}</Styled.InfoText>
  </Styled.Info>
);

export default AnnouncementHistoryModalStatus;
