import React from 'react';
import StatusBadge from '../StatusBadge';
import Tooltip from '../Tooltip';
import { BADGE_STATUSES, SALE_STATUSES, ORDER_STATUSES } from 'shared/constants/ORDER';
import ICONS from 'shared/constants/ICONS';
import Icon from '../Icon';
import Styled from './styles';
import CoinsIcon from '../../../static/icons/coins.svg';
import TruckIcon from '../../../static/icons/truck.svg';
import InvoiceIcon from '../../../static/icons/invoice.svg';
import Button from '../Button';
import OrderManager from '../Order/OrderManager';

interface OrderStatusProps {
  switchToTablet?: boolean;
  withoutManager?: boolean;
  order: any;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ switchToTablet, order, withoutManager }) => {
  const {
    status,
    manager,
    has_payment: hasPayment,
    send_invoice: sendInvoice,
    has_delivery: hasDelivery,
    reject_reason: rejectReason,
  } = order;

  return (
    <Styled.OrderInfo switchToTablet={switchToTablet}>
      <Tooltip text={status === ORDER_STATUSES.CANCELED && rejectReason} placement="topLeft">
        <Styled.OrderHeader>
          <StatusBadge type={BADGE_STATUSES[status]} checkManager manager={manager} status={status}>
            {SALE_STATUSES[status] === 'Поиск билетов' ? 'Поиск' : SALE_STATUSES[status]}
          </StatusBadge>

          <Styled.OrderInfoButtons>
            <Tooltip text={hasPayment && 'Деньги получены'}>
              <Button>
                {!hasPayment ? (
                  <CoinsIcon fill="#E1E1E1" />
                ) : (
                  <Icon width="12px" height="13px" src={ICONS.COINS_FILLED} />
                )}
              </Button>
            </Tooltip>
            <Tooltip text={hasDelivery && 'Билеты переданы'}>
              <Button>
                <TruckIcon fill={!hasDelivery ? '#E1E1E1' : undefined} />
              </Button>
            </Tooltip>
            <Tooltip text={sendInvoice && 'Счет выставлен'}>
              <Button>
                <InvoiceIcon fill={!sendInvoice ? '#E1E1E1' : undefined} />
              </Button>
            </Tooltip>
          </Styled.OrderInfoButtons>
        </Styled.OrderHeader>
      </Tooltip>
      {!withoutManager && <OrderManager data={order} switchToTablet={switchToTablet} />}
    </Styled.OrderInfo>
  );
};

export default OrderStatus;
