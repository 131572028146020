import { Component } from 'react';
import { Router, withRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import FILTERS_CONFIG from 'shared/constants/FILTERS_CONFIG';
import api from 'shared/services/api';
import { findTranslation } from 'shared/helpers/translations';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

interface Props {
  children: any;
  filters: string[];
  filtersConfig?: any;
  optionRenderers?: any;
  router: Router;
}

interface State {
  isLoading: boolean;
  initialValues: any;
}

class PreloadFilterData extends Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    filtersConfig: FILTERS_CONFIG,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: !isEmpty(omit(props.router.query, ['page', 'limit'])),
      initialValues: {},
    };
  }

  componentDidMount() {
    if (this.state.isLoading) {
      const { filters, filtersConfig, optionRenderers } = this.props;
      const { query } = this.props.router;

      const promises = filters.map((filter) => {
        const {
          route,
          query: customQuery,
          isShortUnderline,
          isRangeDateTimePicker,
          isLandingsRangeDateTimePicker,
        } = filtersConfig[filter].props;
        const { isMulti } = filtersConfig[filter];
        if (route && query[filter]) {
          if (isMulti) {
            // @ts-ignore
            const splittedQuery = query[filter].split(',');

            return splittedQuery.map((item) =>
              api.get(route.detail(item)).then((result) => ({
                filter,
                value: result.data,
                isMulti: true,
              })),
            );
          }

          return api.get(route.detail(query[filter])).then((result) => ({
            ...result.data,
            filter,
          }));
        }

        if (isLandingsRangeDateTimePicker) {
          return [
            {
              filter,
              value: compact([query.date_gte, query.date_lte]),
            },
            {
              filter: 'date_gte',
              value: query.date_gte,
            },
            {
              filter: 'date_lte',
              value: query.date_lte,
            },
          ];
        }

        if (isRangeDateTimePicker) {
          const underline = isShortUnderline ? '_' : '__';
          return [
            {
              filter,
              value: compact([query[`${filter}${underline}gte`], query[`${filter}${underline}lte`]]),
            },
            {
              filter: `${filter}${underline}gte`,
              value: query[`${filter}${underline}gte`],
            },
            {
              filter: `${filter}${underline}lte`,
              value: query[`${filter}${underline}lte`],
            },
          ];
        }

        if (customQuery) {
          return {
            filter: customQuery,
            value: query[customQuery],
          };
        }

        if (query[filter]) {
          return {
            filter,
            value: query[filter],
          };
        }

        return null;
      });

      Promise.all(flatten(promises))
        .then((results) => {
          const initialValues = results.reduce((acc, curr) => {
            if (curr && curr.isMulti) {
              if (!acc[curr.filter]) {
                acc[curr.filter] = [];
              }

              if (optionRenderers[curr.filter]) {
                const optionRenderer = optionRenderers[curr.filter];
                acc[curr.filter].push(optionRenderer(curr.value));
              } else {
                acc[curr.filter].push({
                  value: curr.value.id,
                  label: curr.value.name || curr.value.title,
                  data: curr.value,
                });
              }
            } else if (curr && curr.id && !curr.isMulti && curr.filter !== 'place') {
              const label =
                curr.info && Array.isArray(curr.info)
                  ? findTranslation(curr.info, LANGUAGES.RU)?.title
                  : curr.name;
              acc[curr.filter] = {
                value: curr.id,
                label,
                data: curr,
              };
            } else if (curr && curr.filter === 'place') {
              acc[curr.filter] = {
                value: curr.id,
                label: curr.title,
                data: curr,
              };
            } else if (curr && typeof curr.filter !== 'object') {
              acc[curr.filter] = curr.value;
            }

            return acc;
          }, {});

          this.setState({
            initialValues,
            isLoading: false,
          });
        })
        .catch((err) => new Error(err));
    }
  }

  render() {
    const { initialValues, isLoading } = this.state;
    const { children } = this.props;

    return children({ initialValues, isLoading });
  }
}

export default withRouter(PreloadFilterData);
