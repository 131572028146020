import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface UlProps {
  items?: number;
}

interface ButtonProps {
  noHover?: boolean;
  withIcon?: boolean;
}

export default {
  List: styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    padding: 0;
    box-sizing: border-box;
    flex-shrink: 0;

    & > li:not(:last-child) {
      margin-bottom: 48px;
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: 16px 16px 0;
    }
  `,

  Ul: styled.ul<UlProps>`
    column-width: 200px;
    column-gap: 16px;
    column-count: ${({ items }) => {
      if (items <= 5) {
        return 1;
      }

      if (items >= 12) {
        return 4;
      }

      return 3;
    }};
  `,

  Item: styled.li`
    display: flex;
    margin-bottom: 16px;
  `,

  Title: styled.div`
    position: relative;
    min-width: 48px;
    font-size: 32px;
    color: #000;

    & > span {
      position: absolute;
      top: -6px;
    }
  `,

  Button: styled.div<ButtonProps>`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    overflow: hidden;
    word-wrap: break-word;
    width: 200px;

    & > button {
      width: 200px;
      text-align: left;
    }

    ${(props) =>
      !props.noHover &&
      css`
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      `}

    ${(props) =>
      props.withIcon &&
      css`
        & > button:not(.button-with-icon) {
          width: 175px;
          display: inline-block;
          vertical-align: middle;
        }

        .button-with-icon {
          display: none;
          width: auto;
          padding: 0;
          display: none;
          vertical-align: middle;
          line-height: 1;
        }

        &:hover .button-with-icon {
          display: inline-block;
        }
      `}
  `,
};
