import React, { useEffect, useState, useCallback } from 'react';
import toArray from 'lodash/toArray';
import isEqual from 'lodash/isEqual';
import uuid from 'uuid/v4';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import Error from '../../Error';
import Styled from '../styles';
import PlusIcon from '../../../../../static/icons/plus.svg';
import FileArrayInputItem from './FileArrayInputItem';
import { imageValidation } from 'shared/helpers/validations';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';

interface Props {
  meta: any;
  onChange: (links: string[]) => any;
  value: any;
  withAttributes?: boolean;
  withoutEditButton?: boolean;
}

const FileArrayInput: React.FC<Props> = ({ meta, onChange, value, withAttributes, withoutEditButton }) => {
  const [files, setFiles] = useState(Array.isArray(value) ? value.map((link) => ({ link, id: uuid() })) : []);

  const handleChange = ({ target }) => {
    Array.from(target.files).forEach((file) => {
      if (!imageValidation({ maxSize: false, file, target })) return;
    });

    Emmiter.emit(EVENTS.ADD_PICTURE_GALLERY_ATTACHMENTS);
    setFiles((currentFiles) => [
      ...currentFiles,
      ...toArray(target.files).map((file) => {
        if (withAttributes) {
          return {
            file,
            id: uuid(),
            link: {
              alt: '',
              title: '',
              img: '',
              img_webp: '',
            },
          };
        }

        return {
          file,
          link: null,
          id: uuid(),
        };
      }),
    ]);
  };

  useEffect(() => {
    if (value === '' && files.length === 0) return;

    if (!files.length && value.length > 0) {
      setFiles(value.map((link) => ({ link, id: uuid() })));
    } else if (!isEqual(files, value)) {
      const links = files.reduce((acc, file) => {
        if (file.link) acc.push(file.link);
        return acc;
      }, []);
      onChange(links);
    }
  }, [files, typeof value]);

  const updateFile = (id, link, linkWebp) => {
    setFiles((currentFiles) =>
      currentFiles.map((file) => {
        if (file.id === id) {
          if (withAttributes) {
            return { ...file, link: { ...file.link, img: link, img_webp: linkWebp } };
          }

          return { ...file, link };
        }

        return file;
      }),
    );
  };

  const deleteFile = (id) => {
    Emmiter.emit(EVENTS.DELETE_GALLERY_ATTACHMENTS);
    const links = files
      .filter((file) => file.id !== id)
      .reduce((acc, file) => {
        if (file.link) acc.push(file.link);
        return acc;
      }, []);
    onChange(links);
    setFiles((currentFiles) => currentFiles.filter((file) => file.id !== id));
  };

  const editFile = (id, data) => {
    setFiles((currentFiles) =>
      currentFiles.map((file) => (file.id === id ? { ...file, link: { ...file.link, ...data } } : file)),
    );
  };

  const moveFile = useCallback(
    (dragIndex, hoverIndex) => {
      const dragFile = files[dragIndex];
      const filesCopy = [...files];

      filesCopy.splice(dragIndex, 1);

      setFiles([...filesCopy.slice(0, hoverIndex), dragFile, ...filesCopy.slice(hoverIndex)]);
    },
    [files],
  );

  return (
    /*
    // @ts-ignore */
    <DndProvider backend={Backend} context={window}>
      <Styled.ArrayContainer>
        <Styled.ArrayPlus>
          <Styled.Input type="file" size="90" onChange={handleChange} multiple accept=".png, .jpg, .jpeg" />
          <Styled.PreviewContainer width={80} height={80}>
            <PlusIcon fill="#2F80ED" />
          </Styled.PreviewContainer>
        </Styled.ArrayPlus>
        {files.map((file, index) => (
          <Styled.ArrayItem key={file.id}>
            <FileArrayInputItem
              file={file}
              index={index}
              onLoad={updateFile}
              onFail={deleteFile}
              withAttributes={withAttributes}
              withoutEditButton={withoutEditButton}
              onDelete={deleteFile}
              onEdit={editFile}
              moveFile={moveFile}
            />
          </Styled.ArrayItem>
        ))}
      </Styled.ArrayContainer>
      <Error meta={meta} />
    </DndProvider>
  );
};

export default FileArrayInput;
