import React from 'react';
import { queryFilter } from 'shared/lib/queryFilter';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Select from '../FormControls/Select';

const handleChangeQueries = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = target;
  queryFilter({ limit: value, page: null });
};

interface LimitSelectProps {
  limits: number[];
  currentLimit?: string;
  isMobile?: boolean;
  onChange?: (args: { limit: string }) => void | null;
  returnLimits?: boolean;
}

const LimitSelect: React.FC<LimitSelectProps> = ({
  limits,
  currentLimit,
  isMobile,
  onChange,
  returnLimits,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (returnLimits && onChange) {
      onChange({
        limit: value,
      });
    } else {
      handleChangeQueries(event);
    }
  };

  return (
    <Select
      options={limits.map((item, index) => ({
        id: index,
        name: isMobile ? `По ${item}` : `Показывать по ${item}`,
        value: item,
      }))}
      onChange={handleChange}
      value={currentLimit}
      small
      data-selenium="limit-select"
    />
  );
};

export default withCheckIsMobile(LimitSelect);
