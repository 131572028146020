import React from 'react';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { Checkbox, InputWithSelect } from '../../../FormControls';
import CONSTRUCTOR_FILTERS from 'shared/constants/CONSTRUCTOR_FILTERS';

const ITEMS = Array.from({ length: 12 }, (v, i) => i + 1);
let finalForm = null;

const onSelectFilters = (value) => {
  if (!value) {
    finalForm.change('ui_config_filters', false);
  }
};

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const FiltersStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Выберите дизайн фильтров</Styled.Title>
    </Styled.Header>

    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form, values: formValues }) => {
          finalForm = form;
          return (
            <form onSubmit={handleSubmit}>
              <Styled.List grid>
                {ITEMS.map((i) => (
                  <Styled.Item grid width="100%" key={i} data-selenium={`variant-${i}`}>
                    <Variant name="ui_config_filter_type" value={i}>
                      <img src={`/static/constructor/filters/${i}.jpg`} alt={`Вид №${i}`} />
                    </Variant>
                  </Styled.Item>
                ))}
              </Styled.List>
              <Styled.SubTitle>Настройка блока</Styled.SubTitle>
              <Styled.FormControl>
                <Field name="ui_config_filters" type="checkbox">
                  {({ input, meta }) => (
                    <Checkbox variant="round" meta={meta} {...input}>
                      Включение фильтров
                    </Checkbox>
                  )}
                </Field>
              </Styled.FormControl>
              {formValues.ui_config_filters && (
                <Styled.FormControl child width={350}>
                  <Field name="ui_config_filters_list">
                    {({ input, meta }) => (
                      <InputWithSelect
                        options={[
                          CONSTRUCTOR_FILTERS[1],
                          CONSTRUCTOR_FILTERS[2],
                          CONSTRUCTOR_FILTERS[3],
                          CONSTRUCTOR_FILTERS[4],
                          CONSTRUCTOR_FILTERS[5],
                        ]}
                        isMulti
                        placeholder="Выбрать"
                        onSelect={onSelectFilters}
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                </Styled.FormControl>
              )}
              <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
            </form>
          );
        }}
      />
    </Styled.Content>
  </Styled.Container>
);

export default FiltersStep;
