import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  maxWidth?: number;
  disabled?: boolean;
  switchToTablet?: boolean;
  inTable?: boolean;
}

export default {
  Container: styled.div<CSSProps>`
    position: relative;
    width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
    display: flex;
    flex-direction: column;
    cursor: pointer;

    /* disabled */
    pointer-events: ${({ disabled }) => disabled && 'none'};

    ${({ switchToTablet }) =>
      switchToTablet &&
      css`
        width: 40%;
        overflow: hidden;
      `}

    ${({ inTable }) =>
      inTable &&
      css`
        @media (max-width: ${BREAKPOINTS.XL}) {
          width: 40%;
        }
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      overflow: hidden;
    }
  `,

  Username: styled.div<CSSProps>`
    display: flex;
    font-size: 14px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ switchToTablet }) =>
      switchToTablet &&
      css`
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        text-align: right;
      `}

    @media (min-width: ${BREAKPOINTS.LAPTOP}) {
      font-size: 16px;
    }

    ${({ inTable }) =>
      inTable &&
      css`
        @media (max-width: ${BREAKPOINTS.XL}) {
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
          text-align: right;
        }
      `}
  `,

  Status: styled.div`
    display: flex;
    padding: 1px;
    align-items: center;
    pointer-events: none;
  `,

  StatusLabel: styled.div`
    margin-right: 8px;
    color: #5c5c5c;
    line-height: 1;

    @media (max-width: ${BREAKPOINTS.MD}) {
      font-size: 12px;
      line-height: 16px;
    }
  `,

  Manager: styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    & > span {
      color: #5c5c5c;
      font-size: 12px;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  `,

  ManagerIcon: styled.div`
    & > svg {
      margin-right: 4px;
    }
  `,

  Info: styled.div`
    margin-top: 4px;
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 4px;
    }
  `,

  Tooltip: styled.div`
    min-width: 150px;
  `,

  Row: styled.div`
    display: flex;
  `,

  Name: styled.div`
    max-width: 100%;
    color: #000;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,

  Text: styled.div`
    color: #000;
    font-size: 12px;

    & > svg {
      margin-right: 8px;
    }

    & > a {
      word-break: break-all;
      color: #2f80ed;
    }
  `,

  Phone: styled.div`
    margin-top: 2px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.64);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,

  Empty: styled.div`
    color: #5c5c5c;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,

  Icons: styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;

    & > svg {
      opacity: 0.4;
    }

    & > svg:not(:last-child) {
      margin-right: 8px;
    }
  `,
};
