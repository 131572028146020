import React from 'react';
import SearchStyled from '../styles';

interface ItemProps {
  data: string;
  onClick: (data: any) => void;
}

const SearchHistoryItem: React.FC<ItemProps> = ({ data, onClick }) => {
  const handleClick = (event) => {
    event.preventDefault();

    onClick(data);
  };
  return <SearchStyled.Link onClick={handleClick}>{data}</SearchStyled.Link>;
};

export default SearchHistoryItem;
