import styled from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';

interface WrapperProps {
  meta: any;
}

interface InputProps {
  meta?: any;
}

export default {
  Container: styled.div`
    position: relative;
    width: 100%;
  `,

  Wrapper: styled.div<WrapperProps>`
    display: flex;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    ${getBorderOnError};
  `,

  Input: styled.div<InputProps>`
    height: 100%;
    width: 100%;

    & > div {
      height: 100%;
    }

    input {
      min-height: 40px;
      height: 100%;
      width: 100%;
      border: 0;
      padding: 0 10px;
      border: 1px solid #e1e1e1;
      border-radius: 8px;
      outline: none;
      box-sizing: border-box;
      ${getBorderOnError};
    }

    input:focus:not(:read-only) {
      border: 1px solid #e1e1e1 !important;
    }
  `,

  SelectWrapper: styled.div`
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid #000;
      opacity: 0.4;
    }
  `,

  Select: styled.select`
    height: 40px;
    width: 58px;
    padding: 0 16px 0 12px;
    margin-left: 8px;
    border: 0;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    background: transparent;
    appearance: none;
    outline: none;
  `,

  ArrowIcon: styled.span`
    margin-left: 8px;
  `,
};
