import styled from 'styled-components';

export default {
  Hr: styled.div`
    height: 1px;
    margin: 16px 0;
    background-color: #e1e1e1;
  `,

  Item: styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
  `,

  Spinner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Buttons: styled.div`
    display: flex;
    align-items: center;

    button:not(:last-child) {
      margin-right: 8px;
    }
  `,
};
