import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Field } from 'react-final-form';
import SECTIONS, { CONSTRUCTOR_HOME_SECTIONS_FIELDS } from 'shared/constants/CONSTRUCTOR_HOME_SECTIONS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import GripIcon from '../../../../../../static/icons/grip-vertical.svg';
import { Checkbox, Input } from '../../../../FormControls';
import Styled from '../styles';

interface ItemProps {
  index: number;
  section: any;
  values: any;
  lng: string;
}

const HomeSectionItem: React.FC<ItemProps> = ({ index, section, values, lng }) => {
  const [checked, setChecked] = useState(false);

  if (lng !== LANGUAGES.RU) {
    return section.name !== SECTIONS.HOW_TO ? (
      <Styled.SectionField large>
        <Field name={`${CONSTRUCTOR_HOME_SECTIONS_FIELDS[section.name]}`}>
          {({ input, meta }) => <Input label={`Название блока ${section.value}`} meta={meta} {...input} />}
        </Field>
      </Styled.SectionField>
    ) : null;
  }

  return (
    <Draggable draggableId={section.id} index={index} isDragDisabled={!checked}>
      {(provided) => (
        <Styled.SectionsItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Field name={section.name} type="checkbox">
            {({ input, meta }) => {
              setChecked(input.checked);

              return (
                <>
                  <Checkbox variant="round" meta={meta} {...input}>
                    {section.value}
                  </Checkbox>
                  {checked && (
                    <Styled.Grab>
                      <GripIcon />
                    </Styled.Grab>
                  )}
                </>
              );
            }}
          </Field>
          {values[section.name] && (
            <Styled.SectionField>
              <Field name={`${CONSTRUCTOR_HOME_SECTIONS_FIELDS[section.name]}`}>
                {({ input, meta }) => (
                  <Input
                    label={`Название блока ${section.value}`}
                    meta={meta}
                    {...input}
                    disabled={section.name === SECTIONS.HOW_TO}
                  />
                )}
              </Field>
            </Styled.SectionField>
          )}
        </Styled.SectionsItem>
      )}
    </Draggable>
  );
};

export default HomeSectionItem;
