import { useUnit } from 'effector-react';
import React from 'react';
import { Modal, ModalClose } from 'shared/ui/modal/ui';
import { Spinner } from 'shared/ui/spinner';
import { HallLayoutForm } from '../../hall-layout-form';
import { updateSchemeModal } from '../model';
import Styled from './styles';

export const UpdateSchemeModal = () => {
  const handleSubmit = useUnit(updateSchemeModal.formSubmitted);

  const { loading, data } = useUnit({
    loading: updateSchemeModal.$loading,
    data: updateSchemeModal.$data,
  });

  return (
    <Modal toggler={updateSchemeModal.toggler}>
      {({ closeModal }) => (
        <Styled.Body>
          <ModalClose onClick={closeModal} />
          {loading ? (
            <Styled.SpinnerContainer>
              <Spinner />
            </Styled.SpinnerContainer>
          ) : (
            <HallLayoutForm isEdit initialValues={data} closeModal={closeModal} onSubmit={handleSubmit} />
          )}
        </Styled.Body>
      )}
    </Modal>
  );
};
