import React from 'react';
import head from 'lodash/head';
import get from 'lodash/get';
import map from 'lodash/map';
import Styled from './styles';
import { TicketColor } from '../TicketStatus';
import TicketOrderStatus from '../TicketOrderStatus';
import { renderTickets } from 'shared/helpers/tickets';
import Tooltip from '../Tooltip';
import { TICKET_STATUS } from 'shared/constants/TICKET_TYPES';

interface SeatsProps {
  isOpen?: boolean;
  readOnly?: boolean;
  groupedTickets?: any;
}

const TableSeats: React.FC<SeatsProps> = ({ isOpen, groupedTickets, readOnly }) => {
  const tooltipStatus = ({ status, isPreorderTicket, order }) => {
    if (order) return null;

    if (isPreorderTicket) {
      return 'Предзаказ';
    }

    if (!order && status === TICKET_STATUS.IN_ORDER) {
      return 'Забронирован';
    }

    if (status !== 1 && status !== 2) {
      return 'Требуется замена';
    }

    if (status !== 1 && status === 4) {
      return 'Требуется замена';
    }

    return null;
  };

  return (
    <Styled.Seats type="button" isOpen={isOpen} readOnly={readOnly} data-selenium="ads-seats-places">
      {map(groupedTickets, (tickets) => {
        const orders = map(tickets, ({ order, seat }) => ({ order, seat }));
        const { id, status, order, isPreorderTicket } = head(tickets);

        const coloredTicket = (
          <TicketColor order={order} status={status} readOnly={readOnly} isPreorderTicket={isPreorderTicket}>
            {renderTickets(tickets)}
          </TicketColor>
        );

        if (order && order.destination) {
          return (
            <TicketOrderStatus order={order} orders={orders} key={id}>
              {coloredTicket}
            </TicketOrderStatus>
          );
        }

        return (
          <Tooltip text={tooltipStatus({ status, isPreorderTicket, order })} isSmall key={id}>
            {coloredTicket}
          </Tooltip>
        );
      }).map((item, index, array) => [
        item,
        array.length - 1 !== index && (
          <Styled.Comma key="comma">
            <TicketColor
              readOnly={readOnly}
              order={get(item, 'props.children.props.order')}
              status={get(item, 'props.children.props.status')}
              isPreorderTicket={get(item, 'props.children.props.isPreorderTicket')}
            >
              ,
            </TicketColor>
          </Styled.Comma>
        ),
      ])}
    </Styled.Seats>
  );
};

export default TableSeats;
