import styled, { createGlobalStyle } from 'styled-components';
import Button from '../../Button';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  GlobalStyles: createGlobalStyle`
	.modal__overlay {
		background: rgba(0, 0, 0, 0.3);
	}

	.modal__wrapper {
		max-width: 291px;
		border-radius: 10px;

		@media ${MEDIA_QUERIES.DESKTOP} {
			max-width: 400px;
		}
	}

	.modal__body {
		padding: 12px 20px 24px;
		border-radius: 10px;
	}
	`,

  Container: styled.div`
    background-color: #fff;
    text-align: center;
  `,

  Title: styled.h3`
    margin: 0 0 13px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    @media ${MEDIA_QUERIES.DESKTOP} {
      font-size: 20px;
      line-height: 24px;
    }
  `,

  Text: styled.p`
    margin: 0 0 13px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    @media ${MEDIA_QUERIES.DESKTOP} {
      font-size: 14px;
      line-height: 18px;
    }
  `,

  Link: styled.a`
    color: rgba(215, 191, 50, 1);
  `,

  Buttons: styled.div`
    display: flex;
    margin: 0 -3px;
  `,

  ActionButton: styled(Button)`
    width: 100%;
    padding: 9px 4px;
    margin: 0 3px;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
  `,
};
