import React from 'react';
import HistoryIcon from '../../../../static/icons/history.svg';
import Styled from './styles';
import SearchHistoryItem from './SearchHistoryItem';

interface HistoryProps {
  history: string[];
  clearHistory: () => void;
  onSelectHistory: (data: any) => void;
}

const SearchHistory: React.FC<HistoryProps> = ({ history, clearHistory, onSelectHistory }) => {
  const selectHistory = (data) => {
    onSelectHistory(data);
  };

  return (
    <>
      <Styled.Header>
        <Styled.Title>
          <Styled.Icon>
            <HistoryIcon />
          </Styled.Icon>
          Недавние запросы
        </Styled.Title>
        <Styled.Clear type="button" onClick={clearHistory}>
          Очистить историю
        </Styled.Clear>
      </Styled.Header>
      {history.map((item) => (
        <SearchHistoryItem key={item} data={item} onClick={selectHistory} />
      ))}
    </>
  );
};

export default SearchHistory;
