import styled, { css } from 'styled-components';

interface CSSProps {
  isLoading?: boolean;
  alignRight?: boolean;
  withBadge?: boolean;
  isBTB?: boolean;
  isApi?: boolean;
  isCall?: boolean;
  withoutMaxWidth?: boolean;
  margin?: string;
}

export default {
  Container: styled.div<CSSProps>`
    position: relative;
    padding: 16px 0;
    box-sizing: border-box;
    border-bottom: 2px solid #f5f5f5;
    overflow: hidden;

    .data-preview__message {
      display: block;
      margin: auto;
    }

    &:after {
      content: '';
      position: absolute;
      top: 16px;
      left: 24px;
      right: 24px;
      bottom: 16px;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      background-color: #fff;
      transition: all 0.2s ease-in-out;
      pointer-events: none;

      ${({ isLoading }) =>
        isLoading &&
        css`
          opacity: 0.8;
          cursor: not-allowed;
          pointer-events: all;
        `}
    }
  `,

  Table: styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  `,

  TableHead: styled.thead``,

  TableHeadRow: styled.tr`
    & > th > span {
      margin: 0 16px;
    }

    & > th:first-child > span {
      margin-left: 24px;
    }

    & > th:last-child > span {
      margin-right: 24px;
    }
  `,

  TableHeadCell: styled.th<CSSProps>`
    padding-bottom: 8px;
    text-align: left;
    color: #5c5c5c;
    font-weight: normal;
    font-size: 12px;
    text-align: ${({ alignRight }) => alignRight && 'right'};
  `,

  TableBody: styled.tbody`
    & > tr:not(:last-child) > td {
      border-bottom: 1px solid #e1e1e1;
    }

    & > tr:not(:first-child) table > thead {
      display: none;
    }
  `,

  TableRow: styled.tr``,

  TableCell: styled.td<CSSProps>`
    width: ${({ width }) => width};
    position: relative;
    font-size: 12px;
    padding: 5px 0;
    vertical-align: top;
    text-align: ${({ alignRight }) => alignRight && 'right'};
    box-sizing: border-box;

    ${({ withBadge, isBTB, isApi, isCall }) =>
      withBadge &&
      css`
        &:before {
          content: '';
          position: absolute;
          transform: translate(-15px);
          top: 1px;
          bottom: 1px;
          width: 10px;
          height: 100%;
          max-height: 30px;

          background-image: ${isBTB && "url('/static/reports/reports-order-b2b.svg')"};
          background-image: ${isApi && "url('/static/reports/reports-order-api.svg')"};
          background-image: ${isCall && "url('/static/reports/reports-order-call.svg')"};
        }
      `};

    & > div {
      min-width: ${({ width }) => width};
      max-width: ${({ width, withoutMaxWidth }) => !withoutMaxWidth && width};
      margin: ${({ margin }) => margin};
    }
  `,

  Link: styled.a`
    color: #2f80ed;
  `,

  ExternalId: styled.span`
    color: #5c5c5c;
  `,

  Date: styled.div`
    margin: 0 16px;
    color: #5c5c5c;
  `,

  User: styled.div`
    margin: 0 16px;

    & > div > div > div {
      font-size: 12px;
    }
  `,

  Ids: styled.div`
    margin-left: 24px;
  `,

  Status: styled.div`
    margin-left: 16px;
  `,

  Manager: styled.div`
    & > div {
      color: #000;
    }
  `,

  Event: styled.div`
    margin: 0 16px;
  `,

  Empty: styled.div`
    color: #999;
    text-align: center;
  `,
};
