import Button from 'components/Button';
import Form from 'components/Form';
import { Input, InputWithTags } from 'components/FormControls';
import Modal from 'components/Modal';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import {
  createAtolConfig,
  editAtolConfig,
  loadCompanyAtolConfig,
} from 'entities/company/atol-config';
import KEY_CODES from 'shared/constants/KEY_CODES';
import { checkForInitialValues } from 'shared/helpers/form';
import { required } from 'shared/helpers/validations';
import { queryFilter } from 'shared/lib/queryFilter';
import { ModalFunctions } from '../../../../../../../interfaces/modal';
import Styled from './styled';

interface ModalProps {
  data: object;
  closeModal: ModalFunctions['closeModal'];
  editAtolConfig: (id: number, data: object) => void
  createAtolConfig: (data: object) => void;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadCompanyAtolConfig: () => void;
}

const AtolConfig: React.FC<ModalProps> = (props) => {
  const isEdit = !isEmpty(props.data);

  const onSubmit = (data: {id:number}) => {
    const responseCallback = (response: {error:string}, withoutListLoad?: boolean) => {
      if (response.error) {
        NotificationManager.error('Ошибка');

        return response;
      }

      if (!withoutListLoad) {
        props.loadCompanyAtolConfig();
        queryFilter({ search: '' });
      }

      return response;
    };

    if (isEdit) {
      return props
        .editAtolConfig(data.id, checkForInitialValues(props.data, data))
        .then((response) => responseCallback(response, true));
    }

    return props.createAtolConfig(data).then((response) => responseCallback(response));
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title>{`${isEdit ? 'Редактировать' : 'Добавить'}`} настройку ОФД</Modal.Title>
      <Form
        onSubmit={onSubmit}
        onSuccess={props.forceCloseModal}
        initialValues={props.data}
        render={({ handleSubmit }:{handleSubmit:()=>void}) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (event.keyCode === KEY_CODES.ENTER) {
                event.preventDefault();
                return false;
              }

              return null;
            }}
          >
            <Styled.Fields>
              <Field name="login" validate={required}>
                {({ input, meta }) => <Input label="Логин" {...input} meta={meta} />}
              </Field>
              <Field name="password">
                {({ input, meta }) => <Input label="Пароль" {...input} meta={meta} />}
              </Field>
              <Field name="group" validate={required}>
                {({ input, meta }) => <Input label="Группа" {...input} meta={meta} />}
              </Field>
              <Field name="company_name" validate={required}>
                {({ input, meta }) => <Input label="Наименование организации" {...input} meta={meta} />}
              </Field>
              <Field name="company_address" validate={required}>
                {({ input, meta }) => <Input label="Cайт организации" {...input} meta={meta} />}
              </Field>
              <Field name="company_mail" validate={required}>
                {({ input, meta }) => <Input label="Почта" {...input} meta={meta} />}
              </Field>
              <Field name="company_inn" validate={required}>
                {({ input, meta }) => <Input label="ИНН" {...input} meta={meta} />}
              </Field>
              <Field name="company_sno" validate={required}>
                {({ input, meta }) => <Input label="Система налогообложения" {...input} meta={meta} />}
              </Field>
              <Field name="sites">
                {({ input, meta }) => <InputWithTags label="Сайты" {...input} meta={meta} />}
              </Field>
            </Styled.Fields>
            <Modal.Footer>
              <Button type="button" transparent onClick={props.closeModal}>
                Отмена
              </Button>
              <Button type="submit">{isEdit ? 'Сохранить' : 'Добавить'}</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

const mapDispatchToProps = {
  editAtolConfig,
  createAtolConfig,
  loadCompanyAtolConfig,
};

export const CompanyAtolConfigModal =  connect(null, mapDispatchToProps)(AtolConfig);
