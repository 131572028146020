import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  selector?: string;
  // eslint-disable-next-line no-undef
  children: JSX.Element;
}

class Portal extends React.Component<PortalProps> {
  element: HTMLElement;

  componentDidMount() {
    const { selector = 'body' } = this.props;
    this.element = document.querySelector(selector);
    this.forceUpdate();
  }

  render() {
    if (this.element === undefined) {
      return null;
    }

    return ReactDOM.createPortal(this.props.children, this.element);
  }
}

export default Portal;
