import React, { useState, useEffect, useRef } from 'react';
import uuid from 'uuid/v4';
import { NotificationManager } from 'react-notifications';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import money from 'money';
import { WIDGET_PATTERN_TYPES } from 'shared/constants/WIDGET';
import Button from '../../../../Button';
import { findEmptyRules, findNotValidRules } from 'shared/helpers/query';
import { formatData, getDefaultMarkup } from 'shared/helpers/widgetsv2';
import Modal from '../../../../Modal';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';
import Spinner from '../../../../Spinner';
import { Select, Checkbox } from '../../../../FormControls';
import { PRICE_CHANGE_TYPES } from 'shared/constants/ORDER';
import { getCurrency, getCharCode, getCurrencyByCharCode } from 'shared/helpers/currency';
import CURRENCY_CHAR_CODES from 'shared/constants/CURRENCY_CHAR_CODES';
import EventsLayout from '../../../Events/EventsLayout';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { DataContext } from '../SecondStep/DataContext';
import Styled from '../WidgetForm/styles';
import QueryGroup from './QueryGroup';
import PatternSelector from '../PatternSelector';
import { DataContextProvider } from '../../SecondStep/DataContext';

let error = null;

money.base = CURRENCY_CHAR_CODES.USD;

interface ThirdStepProps {
  onSubmit: (data: any) => any;
  data: any;
  isPreview?: boolean;
  goBack?: () => void;
  withNext?: boolean;
  // eslint-disable-next-line no-undef
  backButton?: JSX.Element;
  withSubmit?: boolean;
  withCurrency?: boolean;
  selectedDate?: any;
  eventDates?: any[];
  onChangeDate?: Function;
  isLoading?: boolean;
}

interface IState {
  currency?: any;
  widgetCurrency?: number;
  widgetUseCurrency: boolean;
  markups?: any;
}

const WidgetThirdStep: React.FC<ThirdStepProps> = ({
  data,
  onSubmit,
  isPreview,
  goBack,
  withNext,
  backButton,
  withCurrency,
  selectedDate,
  eventDates,
  onChangeDate,
  withSubmit,
  isLoading,
}) => {
  const setError = (queryError) => {
    error = queryError;
  };

  const [state, setState] = useState<IState>({
    currency: null,
    widgetCurrency: data.currency_id ? +data.currency_id : null,
    widgetUseCurrency: data.use_currency || false,
  });
  const initialCurrency = useRef(null);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      markups: getDefaultMarkup(data),
      widgetCurrency: data.currency_id ? +data.currency_id : null,
      widgetUseCurrency: data.use_currency || false,
    }));
  }, [data.markups || (data.widget && data.widget.markups)]);

  const addRule = () => {
    setState((prevState) => ({
      ...prevState,
      markups: prevState.markups.concat({
        id: uuid(),
        query: {},
      }),
    }));
  };

  const submit = (submitType) => {
    if (error) {
      return NotificationManager.error(error);
    }

    const hasEmptyMarkup = state.markups.find((markup) => !markup.query.markup);

    if (hasEmptyMarkup) {
      return NotificationManager.error('Наценка не может быть пустой');
    }

    const emptyRule = state.markups.find((item) => findEmptyRules(item.query.rules));
    const notValidRules = state.markups
      .map((item) => findNotValidRules(item.query.rules))
      .filter((item) => item.length);

    if (notValidRules.length) {
      const errors = uniq(flatten(notValidRules));

      return NotificationManager.error(
        errors.map((message: string) => <div>{message}</div>),
        'Ошибка',
      );
    }

    if (emptyRule) {
      return NotificationManager.error('Заполните все поля', 'Ошибка');
    }

    return onSubmit({
      markups: state.markups,
      currency_id: state.widgetCurrency,
      use_currency: state.widgetUseCurrency,
      submit_action: submitType,
    });
  };

  const setQuery = (id, query) => {
    setState((prevState) => ({
      ...prevState,
      markups: prevState.markups.map((markupItem) =>
        markupItem.id === id
          ? {
              ...markupItem,
              query,
            }
          : markupItem,
      ),
    }));
  };

  const deleteQuery = (_, id) => {
    setState((prevState) => ({
      ...prevState,
      markups: prevState.markups.filter((markupItem) => markupItem.id !== id),
    }));
  };

  const getMarkupWithNewCurrency = ({ markups, prevCurrency, newCurrency }) => {
    const newChartCode = getCharCode(state.currency, newCurrency);
    const prevCharCode = getCharCode(state.currency, prevCurrency);
    return markups.map((currentMarkup) => {
      const { markup_value: markupType, markup: markupValue } = currentMarkup.query;

      if (+markupType !== PRICE_CHANGE_TYPES.PERCENTAGE) {
        const convertedMarkup = money.convert(markupValue, {
          from: prevCharCode,
          to: newChartCode,
        });

        return {
          ...currentMarkup,
          query: {
            ...currentMarkup.query,
            markup: convertedMarkup,
          },
        };
      }
      return currentMarkup;
    });
  };

  const handleChangePattern = (query, selectedCurrency) => {
    if (!query && selectedCurrency) {
      return setState((prevState) => ({
        ...prevState,
        widgetCurrency: selectedCurrency,
        markups: getMarkupWithNewCurrency({
          markups: prevState.markups,
          prevCurrency: state.widgetCurrency,
          newCurrency: selectedCurrency,
        }),
      }));
    }

    const { data: patternData } = query;

    return setState((prevState) => ({
      ...prevState,
      widgetCurrency: getCurrencyByCharCode(state.currency, CURRENCY_CHAR_CODES.RUB).value,
      markups: patternData.pattern,
    }));
  };

  const setDefaultQuery = (selectedQuery, widgetInitialCurrency) => {
    if (selectedQuery) {
      return setState((prevState) => ({
        ...prevState,
        widgetCurrency: widgetInitialCurrency,
        markups: getDefaultMarkup(data),
      }));
    }

    return setState((prevState) => ({
      ...prevState,
      widgetCurrency: widgetInitialCurrency,
      markups: getMarkupWithNewCurrency({
        markups: prevState.markups,
        prevCurrency: state.widgetCurrency,
        newCurrency: widgetInitialCurrency,
      }),
    }));
  };

  const handleChangeCurrency = ({ target }) => {
    const newCurrency = +target.value;

    setState((prevState) => ({
      ...prevState,
      widgetCurrency: newCurrency,
      markups: getMarkupWithNewCurrency({
        markups: prevState.markups,
        prevCurrency: state.widgetCurrency,
        newCurrency,
      }),
    }));
  };

  const handleChangeUseCurrency = ({ target }) => {
    setState((prevState) => ({
      ...prevState,
      widgetUseCurrency: target.checked,
    }));
  };

  useEffect(() => {
    Emmiter.on(EVENTS.WIDGET_QUERY_ERROR, setError);

    const fetchCurrency = async () => {
      const { results, options } = await getCurrency();

      const rubCurrencyId = results.find((currency) => currency.char_code === CURRENCY_CHAR_CODES.RUB).id;

      initialCurrency.current = state.widgetCurrency || rubCurrencyId;

      setState((prevState) => ({
        ...prevState,
        currency: options,
        widgetCurrency: state.widgetCurrency || rubCurrencyId,
      }));

      money.rates = results.reduce((acc, item) => {
        acc[item.char_code] = item.rate;

        return acc;
      }, {});
    };

    fetchCurrency().catch((err) => new Error(err));

    return () => {
      Emmiter.removeListener(EVENTS.WIDGET_QUERY_ERROR, setError);
    };
  }, []);

  if (!state.currency) {
    return (
      <Styled.Wrapper>
        <Spinner center />
      </Styled.Wrapper>
    );
  }

  const queryGroup = (
    <DataContextProvider pricesRules={formatData(data)}>
      {state.markups.map((markupItem) => (
        <QueryGroup
          key={markupItem.id}
          id={markupItem.id}
          query={markupItem.query}
          onChange={setQuery}
          deleteQuery={deleteQuery}
          isPreview={isPreview}
        />
      ))}
      {!isPreview && (
        <Styled.AddButton>
          <Button type="button" transparent onClick={addRule}>
            Добавить правило
          </Button>
        </Styled.AddButton>
      )}
    </DataContextProvider>
  );

  return (
    <EventsLayout.Wrapper>
      <Styled.Wrapper>
        <Styled.Title>
          Выберите места и установите на них наценку или выберите уже существующий шаблон наценок
          {!isPreview && (
            <PatternSelector
              defaultQuery={getDefaultMarkup(data)}
              type={WIDGET_PATTERN_TYPES.MARKUP}
              setDefaultQuery={setDefaultQuery}
              onChange={handleChangePattern}
              initialCurrency={initialCurrency.current}
              currentCurrency={state.widgetCurrency}
              withCurrency={withCurrency}
              selectedDate={selectedDate}
              eventDates={eventDates}
              onChangeDate={onChangeDate}
            />
          )}
        </Styled.Title>
        {withCurrency && (
          <Styled.Currency>
            <Styled.CurrencySelect>
              Текущая валюта
              <Select
                small
                options={state.currency}
                value={state.widgetCurrency}
                onChange={handleChangeCurrency}
              />
            </Styled.CurrencySelect>

            <Checkbox
              rtl
              variant="round"
              checked={state.widgetUseCurrency}
              onChange={handleChangeUseCurrency}
            >
              Использовать выбранную валюту
            </Checkbox>
          </Styled.Currency>
        )}
        {isLoading ? (
          <Spinner center />
        ) : (
          <>{data.eventDates ? <>{data ? queryGroup : 'Выберите дату'}</> : queryGroup}</>
        )}
      </Styled.Wrapper>
      <Modal.Footer fullSize justify>
        {backButton ||
          (goBack && (
            <Button gray type="button" onClick={goBack}>
              Назад
            </Button>
          ))}
        {withSubmit && (
          <Button gray type="button" onClick={() => submit(SUBMIT_ACTIONS.SAVE)} data-selenium="button-save">
            Сохранить
          </Button>
        )}
        <Button type="button" onClick={() => submit(SUBMIT_ACTIONS.CONTINUE)} data-selenium="button-continue">
          {withNext ? 'Продолжить' : 'Готово'}
        </Button>
      </Modal.Footer>
    </EventsLayout.Wrapper>
  );
};

export default WidgetThirdStep;
