import React, { Component } from 'react';
import { connect } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import { NotificationManager } from 'react-notifications';
import AnnouncementsModal from './AnnouncementsModal';
import { editAnnouncement } from 'entities/announcement';
import { ModalFunctions } from '../../../../../interfaces/modal';

interface Props {
  id: number;
  event: any;
  initialValues?: any;
  announcementData?: any;
  editAnnouncement: (id: number, data: any) => any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

class AnnouncementsEdit extends Component<Props> {
  seatsIds: {};

  // eslint-disable-next-line react/static-property-placement
  static defaultProps: { initialValues: {} };

  constructor(props) {
    super(props);

    this.seatsIds = {};
  }

  setSeatsIds = (rowId, seatsIds) => {
    this.seatsIds = {
      ...this.seatsIds,
      [rowId]: {
        ...(this.seatsIds[rowId] || {}),
        ...seatsIds,
      },
    };
  };

  editAnnouncement = (data) => {
    const request: any = {
      announcement_type: Number(data.announcement_type),
      ticket_type: Number(data.ticket_type),
      non_returnable: data.non_returnable,
      personal: data.personal,
      price: data.price,
      purchase_price: data.purchase_price,
      total_price: data.total_price,
    };

    if (data.view_scope_type) {
      request.view_scope_type = Number(data.view_scope_type);
    }

    if (data.provider) {
      request.provider_id = data.provider.value;
    } else {
      request.provider_id = null;
    }

    if (data.expansion) {
      request.expansion = differenceBy(
        data.expansion,
        this.props.announcementData.announcement.data.expansion,
        'id',
      ).map((item: any) => item.value);
    } else {
      request.expansion = [];
    }

    this.props.editAnnouncement(this.props.id, request).then((response) => {
      if (!response.error) {
        this.props.forceCloseModal();
        return NotificationManager.success('Успешно');
      }
      NotificationManager.error('Ошибка');

      return response;
    });
  };

  render() {
    const { event, initialValues } = this.props;

    return (
      <AnnouncementsModal
        isEdit
        event={event}
        initialValues={{ ...initialValues, ...(initialValues.category && { is_categories: 'true' }) }}
        setSeatsIds={this.setSeatsIds}
        onSubmit={this.editAnnouncement}
        close={this.props.closeModal}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.company.data,
});

const mapDispatchToProps = {
  editAnnouncement,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementsEdit);
