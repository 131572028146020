import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Container: styled.div<{ withDates?: boolean }>`
    padding: 10px 16px 16px 16px;
    position: relative;

    .horizontal-tabs {
      margin-right: 50px;
      position: static;
      width: ${({ withDates }) => (withDates ? 'calc(100% - 200px)' : '100%')};
      z-index: 1;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .horizontal-tabs {
        width: 100%;
      }
    }
  `,

  DisplayType: styled.div`
    max-width: 500px;
    margin-top: 16px;
  `,

  LeftoverThreshold: styled.div`
    max-width: 150px;
  `,

  DisplayTypeDescription: styled.div`
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.64);
  `,

  IsTableCheckbox: styled.div`
    margin-top: 16px;
  `,

  AddMarkup: styled.div`
    position: absolute;
    top: -40px;
    right: 16px;
    max-width: 250px;
    z-index: 2;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: static;
      margin-top: 8px;
    }
  `,

  Select: styled.div`
    max-width: 250px;
    margin-top: 16px;
  `,

  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%; // Добавлено для определения высоты
  `,

  Column: styled.div`
    width: 50%;
    flex-shrink: 0; // Предотвращает сжимание колонки

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
    }
  `,
  DatesSelect: styled.div`
    display: flex;
    align-items: flex-end;

    button {
      margin-left: 8px;
    }
  `,

  Dates: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  `,

  SelectedDate: styled.div`
    padding: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: #f5f5f5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 14px;
    box-sizing: border-box;
    word-break: break-all;
  `,

  SelectWrapper: styled.div`
    display: flex;
    overflow-x: auto;
    padding-top: 10px;
    flex-wrap: wrap;
  `,
  RoundSelect: styled.div`
    margin: 10px 5px 0 0;
    width: 110px;
  `,

  FooterWrapper: styled.div<{ marketMarkupsFilter?: boolean }>`
    position: ${(marketMarkupsFilter) => (marketMarkupsFilter ? 'fixed' : 'static')};
    bottom: 9px;
    width: calc(100% - 32px);
    left: 10px;
    box-sizing: border-box;

    .modal__footer {
      margin: 0;
    }
  `,
};
