import reduce from 'lodash/reduce';
import uuid from 'uuid/v4';
import { PRICE_CHANGE_TYPES } from 'shared/constants/ORDER';

const formatData = (data) => {
  if (data.hall_layout) {
    return reduce(
      data,
      (acc: any, item, field) => {
        if (field === 'hall_layout') {
          acc.event = { hall_layout: item };
        } else {
          acc[field] = item;
        }

        return acc;
      },
      {},
    );
  }

  return data;
};

const getDefaultMarkup = (data?: any) => {
  const defaultMarkup = [
    {
      id: uuid(),
      query: {
        id: uuid(),
        rules: [
          {
            id: uuid(),
            field: 'all_tickets',
            value: null,
            operator: 'in',
          },
        ],
        combinator: 'and',
        markup: 50,
        markup_value: PRICE_CHANGE_TYPES.PERCENTAGE,
      },
    },
  ];

  if (!data) return defaultMarkup;

  const markups =
    data.markups || (data.widget && data.widget.markups)
      ? data.markups || data.widget.markups
      : defaultMarkup;

  if (!Array.isArray(markups)) {
    return defaultMarkup;
  }

  return markups;
};

export { formatData, getDefaultMarkup };
