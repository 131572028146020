import { Menu } from 'antd';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

const StyledDropdownItem = styled(({ isSmall, divider, ...rest }) => <Menu.Item {...rest} />)`
  padding: 0 !important;

  ${({ divider }) =>
    divider &&
    css`
      &:after {
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        margin: 7px 0 8px;
        background-color: #e1e1e1;
      }
    `}

  &:hover {
    background: none !important;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      border-radius: 4px;
      overflow: hidden;
    `}
`;

const StyledTimer = styled.div`
  padding: 0 12px;
  color: #000;
  font-size: 14px;
`;

interface StyledOptionProps {
  danger?: boolean;
  disabled?: boolean;
  isSmall?: boolean;
  selected?: boolean;
}

const StyledOption = styled.div<StyledOptionProps>`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px !important;
  min-height: 32px;
  box-sizing: border-box;
  color: #000;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }

  /* danger */
  color: ${({ danger }) => danger && '#EB5757'};

  /* disabled */
  ${({ disabled }) =>
    disabled &&
    css`
      color: #929292;

      & svg {
        fill: #929292;
      }
    `}

  /* isSmall */
  min-height: ${({ isSmall }) => isSmall && '24px'};
  padding: ${({ isSmall }) => isSmall && '0 4px'};

  ${({ selected }) =>
    selected &&
    css`
      color: #2f80ed;

      & svg {
        fill: #2f80ed;
      }
    `}
`;

interface StyledIconWrapperProps {
  disabled?: boolean;
}

const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 12px;

  & > svg {
    height: 100%;
    fill: ${({ disabled }) => disabled && '#929292'};
  }
`;

interface DropdownItemProps {
  item: any;
  isSmall?: boolean;
  selenium?: string;
}

const DropdownItem = ({ item, isSmall, selenium, ...rest }: DropdownItemProps) => {
  const [timer, setTimer] = useState(item.timer || 0);
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setTimer(0);
      return '0:0';
    }
    return `${minutes}:${seconds}`;
  };
  return (
    <StyledDropdownItem
      key={item.value}
      disabled={Boolean(timer) || item.disabled}
      divider={item.divider}
      isSmall={isSmall}
      {...rest}
      eventKey={item.value}
      data-selenium={selenium}
    >
      <Tooltip text={timer ? 'Вы уже это нажимали, подождите…' : item.tooltip} isMedium>
        <StyledOption
          danger={item.danger}
          disabled={Boolean(timer) || item.disabled}
          isSmall={isSmall}
          selected={item.selected}
        >
          {item.icon && <StyledIconWrapper disabled={item.disabled}>{item.icon}</StyledIconWrapper>}
          {item.label}
          {item.timer && (
            <StyledTimer>
              <Countdown date={timer} renderer={renderer} />
            </StyledTimer>
          )}
        </StyledOption>
      </Tooltip>
    </StyledDropdownItem>
  );
};

export default DropdownItem;
