import { createEvent, createStore, sample } from 'effector';
import * as countriesApi from 'shared/api/reference/country';
import { Country, FormSubmitData } from 'shared/api/types';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { showedErrorNotification, showedSuccesNotification } from 'shared/helpers/notification';
import { createToggler } from 'shared/lib/toggler';
import { $countries, $countriesQuery } from '../../../model';
import { CountryData } from '../../../types';

export const createFormSubmitted = createEvent<FormSubmitData>();
export const toggler = createToggler();
export const $validatedCountryName = createStore<{
  validateData?: CountryData;
  error?: string;
}>({});

sample({
  source: $countries,
  clock: createFormSubmitted,
  fn: (countries, data) => {
    const title = data[LANGUAGES.RU].title || '';
    const countryKey = title[0];
    const countriesList = countries.results;
    const countryIsExist = Boolean(countriesList[countryKey]?.find((item) => item.code === title));

    if (countryIsExist) {
      return { error: 'already exist' };
    }

    return { validateData: { title, data } };
  },
  target: $validatedCountryName,
});

sample({
  clock: $validatedCountryName,
  filter: ({ error }) => Boolean(error),
  fn: ({ error }) => {
    return error === 'already exist' ? 'Страна с таким именем уже существует' : '';
  },
  target: showedErrorNotification,
});

sample({
  clock: $validatedCountryName,
  filter: ({ validateData, error }) => Boolean(validateData) && !error,
  fn: ({ validateData }) =>
    ({
      title: validateData?.title,
      data: validateData?.data,
    } as CountryData),
  target: countriesApi.createCountryFx,
});

sample({
  clock: countriesApi.createCountryFx.done,
  fn: ({ params, result }) => {
    const translations = Object.keys(params.data).reduce((acc: any, lang: string) => {
      if (LANGUAGES[lang.toUpperCase()] && params.data[lang]) {
        acc.push({
          language_code: lang,
          title: params.data[lang].title,
        });

        return acc;
      }
      return acc;
    }, []);

    return { id: result.id, translations };
  },
  target: countriesApi.createCountryInfosFx,
});

sample({
  clock: countriesApi.createCountryFx.fail,
  fn: ({ error }) => {
    if (error.message.includes('already exist')) {
      return 'Страна с таким именем уже существует';
    }
    return 'Ошибка';
  },
  target: showedErrorNotification,
});

sample({
  clock: countriesApi.createCountryInfoFx.done,
  target: showedSuccesNotification,
});

sample({
  clock: [countriesApi.createCountryInfosFx.done, countriesApi.updateCountryInfoFx.done],
  target: [toggler.close, showedSuccesNotification],
});

sample({
  clock: countriesApi.createCountryInfosFx.fail,
  fn: ({ error }) => {
    if (error.response?.data?.detail.includes('already exist')) {
      return 'Страна с таким именем уже существует';
    }
    return 'Ошибка';
  },
  target: showedErrorNotification,
});

export const countryCreateModal = { createFormSubmitted, toggler };

sample({
  source: { countriesQuery: $countriesQuery },
  clock: countriesApi.createCountryInfoFx.done,
  fn: ({ countriesQuery }) => countriesQuery,
  target: countriesApi.fetchCountriesFx,
});
