import styled from 'styled-components';

interface ContainerProps {
  isDisabled?: boolean;
}

export default {
  Container: styled.div<ContainerProps>`
    position: relative;
    width: 100%;

    .react-select--is-disabled {
      cursor: not-allowed;
      pointer-events: all;
    }

    .react-select__indicator {
      cursor: pointer;
    }

    .react-select__control {
      cursor: text;
    }

    .react-select__control--is-disabled {
      background-color: #f7f7f7;
      pointer-events: none;
    }

    .react-select__multi-value__remove {
      cursor: pointer;
    }
  `,
};
