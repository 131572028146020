import React from 'react';
import Styled from './styles';
import ModalControlButton from './ModalControlButton';

const ModalControl: any = ({ children }: any) => <Styled.ModalControl>{children}</Styled.ModalControl>;

ModalControl.Button = ModalControlButton;
ModalControl.FormElement = Styled.FormElement;

export default ModalControl;
