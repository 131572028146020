import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import Button from 'components/Button';
import Can from '../../../Can';
import LandingsCustomPageForm from './LandingsCustomPageForm';
import landingsCustomPageSources from 'shared/sources/landings/landingsCustom';
import withLandingData from '../withLandingData';
import permissions from 'config/permissions';
import LandingsModalLayout from '../landingsModalLayout';
import TrashIcon from '../../../../../static/icons/trash.svg';
import { editTranslationData } from 'shared/helpers/form';
import MODALS from 'shared/constants/MODALS';
import { openRouterModal } from 'entities/modal';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { selectAccount } from '../../../../entities/account/reducer';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  data?: any;
  modalState?: string;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  onChangeLng?: any;
  updateList: (data?: any) => any;
  onUpdate: (data: any) => any;
  create: (data: any) => any;
  update: (data: any) => any;
  showDeleteModal: () => void;
  lng: string;
}

const LandingsCustomPageModal: React.FC<ModalProps> = (props) => {
  const account = useSelector(selectAccount);
  const { data, onChangeLng, updateList, onUpdate, modalState, create, update, showDeleteModal, lng } = props;

  const dispatch = useDispatch();
  const handleOpenRouterModal = (modalData) => dispatch(openRouterModal(modalData));

  if (modalState === MODAL_STATES.CREATE) {
    return (
      <LandingsCustomPageForm
        key="create"
        title="Создание кастомной страницы"
        onSubmit={create}
        onSuccess={(values) => {
          updateList();
          handleOpenRouterModal({ modalType: MODALS.PAGE_CONSTRUCTOR_MODAL, data: values.ru });
        }}
        onChangeLng={onChangeLng}
        initialValues={{
          item_create: true,
          company_id: account.company.id,
          show_in_menu: false,
        }}
      >
        <Button transparent type="button" onClick={props.closeModal}>
          Отмена
        </Button>
        <Button>Перейти в конструктор</Button>
      </LandingsCustomPageForm>
    );
  }

  const initialValues = {
    company_id: account.company.id,
    ...data,
    id: data.id || data.item_id,
    blocks:
      data.blocks &&
      data.blocks.map((item) => ({
        ...item,
        title: item.title || '',
      })),
    content_groups: data.content_groups
      ? data.content_groups.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : [],
  };
  const handleUpdate = (formData) => {
    const dataForCompare = formData[lng];

    if (isEqual(dataForCompare, initialValues)) {
      return {};
    }

    return update(formData);
  };
  return (
    <LandingsCustomPageForm
      key="edit"
      title="Редактирование кастомной страницы"
      isEdit
      onSubmit={handleUpdate}
      onSuccess={onUpdate}
      forceCloseModal={props.forceCloseModal}
      modalData={{
        ...data,
        id: data.id || data.item,
      }}
      openRouterModal={handleOpenRouterModal}
      initialValues={initialValues}
      onChangeLng={onChangeLng}
    >
      <Can permission={permissions.companySuperManager}>
        <LandingsModalLayout.MobileContainer>
          <Button dangerTransparent onlyIcon type="button" onClick={showDeleteModal}>
            <TrashIcon />
          </Button>
        </LandingsModalLayout.MobileContainer>
        <LandingsModalLayout.TabletUpContainer>
          <Button dangerTransparent type="button" onClick={showDeleteModal}>
            Удалить
          </Button>
        </LandingsModalLayout.TabletUpContainer>
      </Can>
      <Button type="button" transparent onClick={props.closeModal}>
        Отмена
      </Button>
      <Button>Сохранить</Button>
    </LandingsCustomPageForm>
  );
};

LandingsCustomPageModal.defaultProps = {
  modalState: MODAL_STATES.EDIT,
  data: null,
};

const mappedSubmitData = (item) => ({
  ...item,
  ...(item.content_groups && {
    content_groups: item.content_groups.map((group) => group.value),
  }),
  ...(item.blocks && {
    blocks: item.blocks.map((block) => ({
      ...block,
      gallery_webp: block.gallery_webp || [],
      html: block.html || '',
    })),
  }),
});

export default withLandingData(LandingsCustomPageModal, {
  translation: 'common',
  newDetailApi: true,
  sources: landingsCustomPageSources,
  mappedCreateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
  mappedUpdateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
});
