import React from 'react';
import omit from 'lodash/omit';
import Styled from './styles';

interface Props {
  isFocused: boolean;
  children: any;
  innerProps: any;
}

const InputWithSelectOption: React.FC<Props> = (props) => {
  const { children, innerProps, isFocused } = props;

  return (
    <Styled.Option {...omit(innerProps, ['onMouseMove', 'onMouseOver'])} isFocused={isFocused}>
      {children}
    </Styled.Option>
  );
};

export default InputWithSelectOption;
