import React from 'react';
import { components } from 'react-select';
import Styled from './styles';

interface Props {
  children: any[];
  selectProps: {
    name: string;
  };
}

const ValueContainer: React.FC<Props> = ({ children, ...props }) => (
  <Styled.ValueContainer data-selenium={`select-${props.selectProps.name}`}>
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </Styled.ValueContainer>
);

export default ValueContainer;
