import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import StyledForm from '../../Form/styles';
import Modal from '../../Modal';

export default {
  Header: styled.div`
    margin-bottom: 60px;
    text-align: center;
  `,
  Note: styled.div`
    margin-top: 20px;
    font-size: 14px;
    opacity: 0.4;
    line-height: 1.5;
  `,

  Wrapper: styled(Modal.FullSizeContent)`
    ${StyledForm.FormWrapper} {
      height: 100%;

      form {
        height: 100%;
      }
    }
  `,

  Row: styled.div<{ small?: boolean; grid?: boolean }>`
    width: 100%;
    margin-bottom: ${(props) => (props.small ? 8 : 32)}px;

    ${(props) =>
      props.grid &&
      css`
        display: flex;
        margin-bottom: 0;

        @media (max-width: ${BREAKPOINTS.MD}) {
          flex-direction: column;
        }
      `}
  `,

  Column: styled.div`
    width: 100%;

    & > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  `,
};
