import React from 'react';
import map from 'lodash/map';
import Styled from './styles';
import { Checkbox } from '../../FormControls';
import { ACTIONS } from 'shared/constants/PREORDER';

interface Props {
  isTransferFromOrder: boolean;
  handleStandingChange: (ids: any, value?: boolean) => any;
  row: any[];
}

const PreorderModalCheckbox: React.FC<Props> = ({ handleStandingChange, row, isTransferFromOrder }) => {
  const handleOnChange = (event) => {
    const ids = map(row, (item) => item.id);

    if (event.target.checked) {
      handleStandingChange(ids);
    } else {
      handleStandingChange(ids, true);
    }
  };

  return (
    <Styled.NumericInput>
      <Checkbox
        variant="round"
        onChange={handleOnChange}
        checked={
          row.filter((item) => item.action === (isTransferFromOrder ? ACTIONS.SELECTED : ACTIONS.IS_FOUND))
            .length === row.length
        }
      >
        Билеты найдены
      </Checkbox>
    </Styled.NumericInput>
  );
};

export default PreorderModalCheckbox;
