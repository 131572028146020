import classnames from 'classnames';
import RCTooltip from 'rc-tooltip';
import React from 'react';

interface TooltipProps {
  visible?: boolean;
  children: any;
  placement?: string;
  text?: any;
  isWhite?: boolean;
  isSmall?: boolean;
  delay?: number;
  isBig?: boolean;
  isMedium?: boolean;
  isPriceInput?: boolean;
  isError?: boolean;
  getTooltipContainer?: (node: HTMLElement) => HTMLElement;
  trigger?: string;
}

const Tooltip = ({
  visible,
  children,
  placement = 'top',
  text,
  isWhite,
  isSmall,
  delay = 0,
  isBig,
  isMedium,
  isPriceInput,
  isError,
  getTooltipContainer,
  trigger = 'hover',
}: TooltipProps) => {
  if (!text) return children;

  const tooltipProps = {
    overlayClassName: classnames({
      'rc-tooltip--white': isWhite,
      'rc-tooltip--small': isSmall,
      'rc-tooltip--medium': isMedium,
      'rc-tooltip--medium-left': isMedium && placement === 'left',
      'rc-tooltip--big': isBig,
      'rc-tooltip--priceInput': isPriceInput,
      'rc-tooltip--is-error': isError,
    }),
    placement,
    trigger: [trigger],
    overlay: text,
    mouseLeaveDelay: delay,
    getTooltipContainer,
    ...(typeof visible === 'boolean' && {
      visible,
    }),
  };

  return <RCTooltip {...tooltipProps}>{children}</RCTooltip>;
};

export default Tooltip;
