import styled from 'styled-components';
import {
  display,
  space,
  width,
  color,
  justifyContent,
  alignItems,
  flexWrap,
  flex,
  minWidth,
  overflow,
  textAlign,
} from 'styled-system';

const Box = styled.div<any>`
  display: flex;
  box-sizing: border-box;

  ${display}
  ${space}
  ${width}
  ${color}
  ${justifyContent}
  ${alignItems}
  ${flexWrap}
  ${flex}
  ${minWidth}
  ${overflow}
  ${textAlign}
`;

export default Box;
