import styled from 'styled-components';

const ButtonWrapper = styled.div`
  visibility: hidden;
`;

const ModalControl = styled.div`
  display: flex;

  &:hover ${ButtonWrapper} {
    visibility: visible;
  }
`;
const FormElement = styled.div`
  margin-right: 8px;
`;

export default {
  ButtonWrapper,
  ModalControl,
  FormElement,
};
