import { sample, createStore, createEvent, createEffect } from 'effector';
import { createToggler } from 'shared/lib/toggler';

export const sourceCodeToggler = createToggler();

const $text = createStore(null);

const instertedText = createEvent<any>();

const $data = createStore(null);

const changedTextFx = createEffect(({ text, input }) => {
  input.onChange(text);
});

export const sourceCodeModal = { $text, instertedText, $data };

sample({
  clock: sourceCodeToggler.open,
  fn: ({ value, input }) => {
    return { value, input };
  },
  target: $data,
});

sample({
  source: { data: $data },
  clock: instertedText,
  fn: ({ data }, result) => {
    return { text: result?.sourceCodeText, input: data?.input };
  },
  target: changedTextFx,
});

sample({
  clock: changedTextFx.done,
  target: sourceCodeToggler.close,
});
