const INVOICE_STATUSES = {
  NEW: 0,
  WAIT: 1,
  IN_WORK: 2,
  IN_ORDER: 3,
  CANCEL: 4,
};

const REJECT_REASONS = {
  OTHER: 0,
};

const INVOICE_STATUS_SELECT_IN = [
  {
    label: 'Действующие',
    options: [
      { value: INVOICE_STATUSES.NEW, label: 'Не в работе', id: INVOICE_STATUSES.NEW },
      { value: INVOICE_STATUSES.WAIT, label: 'Ожидание', id: INVOICE_STATUSES.WAIT },
      { value: INVOICE_STATUSES.IN_WORK, label: 'В работе', id: INVOICE_STATUSES.IN_WORK },
      { value: INVOICE_STATUSES.IN_ORDER, label: 'В заказе', id: INVOICE_STATUSES.IN_ORDER },
    ],
  },
  {
    label: 'Завершенные',
    options: [{ value: INVOICE_STATUSES.CANCEL, label: 'Отмена', id: INVOICE_STATUSES.CANCEL }],
  },
];

export { INVOICE_STATUSES, REJECT_REASONS, INVOICE_STATUS_SELECT_IN };
