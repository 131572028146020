import { connect } from 'react-redux';
import EditMemberModal from 'components/Modals/EditMemberModal';
import {
  loadMembersUser,
  setMemberIsActive,
  updateMembersUserPermissions,
} from 'entities/members/users';

const mapStateToProps = (state) => ({
  currentUserId: state.account.data.id,
});

const mapDispatchToProps = {
  loadMembersUser,
  setMemberIsActive,
  updateMembersUserPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMemberModal);
