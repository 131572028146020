import { createEffect, createEvent, sample } from 'effector';
import { NotificationManager } from 'react-notifications';
import { createHallLayoutFx, FormSubmitData } from 'shared/api/reference/hall-layouts';
import { fetchHallFx } from 'shared/api/reference/halls';
import { createToggler } from 'shared/lib/toggler';
import { $data } from '../../view-modal';

const toggler = createToggler();

const formSubmitted = createEvent<FormSubmitData>();

const successNotificationFx = createEffect(() => {
  NotificationManager.success('Успешно');
});

export const createSchemeModal = { toggler, formSubmitted };

sample({
  source: {
    hall: $data,
  },
  clock: formSubmitted,
  fn: ({ hall }, { name }) => ({
    hall: hall!.id,
    name,
  }),
  target: createHallLayoutFx,
});

sample({
  source: {
    hall: $data,
  },
  clock: createHallLayoutFx.done,
  fn: ({ hall }) => ({ id: hall!.id, type: 'view' }),
  target: [toggler.close, fetchHallFx, successNotificationFx],
});
