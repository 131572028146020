import { useUnit } from 'effector-react';
import React, { useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { errorsModalClosed as countryErrorsModalClosed } from 'pages/reference/countries';
import { errorsModalClosed as hallErrorsModalClosed } from 'pages/reference/halls';
import { errorsModalClosed as locationErrorsModalClosed } from 'pages/reference/locations/ui/update-modal';
import { errorsModalClosed as personErrorsModalClosed } from 'pages/reference/persons/ui/update-modal/model';
import { errorsModalClosed as placeErrorsModalClosed } from 'pages/reference/places';
import LOCALES from 'shared/constants/LOCALES';
import PAGES from 'shared/constants/PAGES';
import useListItem from 'shared/lib/useListItem';
import expansionSources from 'shared/sources/expansion';
import { ModalFunctions } from '../../../../interfaces/modal';
import ExternalLinkIcon from '../../../../static/icons/external-link.svg';
import { useTranslation } from '../../../i18n';
import Button from '../../Button';
import Modal from '../../Modal/Modal';
import Tooltip from '../../Tooltip';
import Styled from './styles';

interface ModalProps {
  data?: any;
  closeModal: ModalFunctions['closeModal'];
}

const LINK_PATTERNS = {
  Event: '/events?event=[ID]',
  'Event Category': '/reference/event-categories?modalId=[ID]&modalType=event-category',
  'Event Tag': '/events?event=[ID]',
  Hall: '/reference/halls?modalId=[ID]&modalType=hall',
  'Hall Layout': '/editor/[ID]',
  Location: '/reference/locations?modalId=[ID]&modalType=location',
  Person: '/reference/persons?modalId=[ID]&modalType=PERSONS',
  'Person Event': '/events?event=[EVENT_ID]',
  'Person Tag': '/reference/persons?modalId=[ID]&modalType=PERSONS',
  'Place tag': '/reference/places?modalId=[ID]&modalType=place',
  'Parsers category': '/parsers/categories?search=[ID]',
  Tag: '/reference/tags?modalId=[ID]&modalType=TAG',
  Announcement: '/announcements/[EVENT_ID]?announcement=[ID]',
  Bundle: '/bundles/active?modalId=[ID]&modalType=bundle',
};

const modalText = {
  [PAGES.REFERENCE_PLACES]: 'places',
  [PAGES.REFERENCE_HALLS]: 'halls',
  [PAGES.REFERENCE_EVENT_CATEGORIES]: 'event_categories',
  [PAGES.REFERENCE_COUNTRIES]: 'country',
  [PAGES.REFERENCE_PERSONS]: 'persons',
  [PAGES.REFERENCE_EXPANSION]: 'expansion',
  [PAGES.REFERENCE_TAGS]: 'tags',
  [PAGES.REFERENCE_LOCATIONS]: 'location',
};

const linkPattern = (type, id, eventId) =>
  LINK_PATTERNS[type].replace('[ID]', id).replace('[EVENT_ID]', eventId);

const ReferenceErrorsModal: React.FC<ModalProps> = ({ data, closeModal }) => {
  const { t } = useTranslation(LOCALES.REFERENCES);
  const { errors, name, page } = data;
  const { remove } = useListItem({ source: expansionSources });
  const modalUnit = {
    [PAGES.REFERENCE_COUNTRIES]: countryErrorsModalClosed,
    [PAGES.REFERENCE_PLACES]: placeErrorsModalClosed,
    [PAGES.REFERENCE_HALLS]: hallErrorsModalClosed,
    [PAGES.REFERENCE_LOCATIONS]: locationErrorsModalClosed,
    [PAGES.REFERENCE_PERSONS]: personErrorsModalClosed,
  };

  const handleErrorsModalClosed = useUnit(modalUnit[page]);

  const SECTIONS = {
    Event: t('errors.sections.event'),
    'Event Category': t('errors.sections.event_categories'),
    'Event Tag': t('errors.sections.event'),
    Hall: t('errors.sections.halls'),
    'Hall Layout': t('errors.sections.hall_layout'),
    Location: t('errors.sections.locations'),
    Person: t('errors.sections.persons'),
    'Person Event': t('errors.sections.event'),
    'Person Tag': t('errors.sections.persons_tag'),
    Announcement: t('errors.sections.announcements'),
    Tag: t('errors.sections.tags'),
    'Place tag': t('errors.sections.places'),
    Bundle: t('errors.sections.bundles'),
    'Parsers category': t('errors.sections.parsers_category'),
  };

  // @ts-ignore
  const errorsList = errors[Object.keys(errors)];
  const isExpansionPage = page === PAGES.REFERENCE_EXPANSION;

  useEffect(() => {
    return () => {
      handleErrorsModalClosed();
    };
  });

  const handleDeleteExpansion = () => {
    remove({ id: data.expansion.id, confirm: true })
      .then(() => {
        closeModal();
        NotificationManager.success('Успешно!');
      })
      .catch((err) => new Error(err));
  };

  // eslint-disable-next-line
  const renderLink = ({ errorName, key, id, event }: any) => (
    <Styled.ErrorsItem>
      <Tooltip text={errorName}>
        <Styled.Name>{errorName}</Styled.Name>
      </Tooltip>
      <Styled.Link href={linkPattern(key, id, event)} target="_blank" rel="noopener noreferrer">
        <ExternalLinkIcon />
      </Styled.Link>
    </Styled.ErrorsItem>
  );

  const renderError = (key, error) => {
    const errorName = error.title || error.name || error.event_title;

    if (typeof error === 'string') {
      return renderLink({ key, errorName: error, id: error });
    }

    return renderLink({
      key,
      errorName,
      id: error.id,
      event: error.event || error.event_id,
    });
  };

  return (
    <>
      <Styled.GlobalStyle />
      <Styled.Wrapper>
        <Styled.Header>
          <Styled.Title>{t(`${modalText[page]}.errors.title`)}</Styled.Title>
          <Styled.Description>
            {t(`${modalText[page]}.errors.description`).replace('[name]', name)}
          </Styled.Description>
        </Styled.Header>
        <Styled.List>
          {errorsList ? (
            errorsList.map((err) =>
              Object.keys(err).map((key) => (
                <Styled.Section key={key}>
                  <Styled.ListTitle>{SECTIONS[key]}</Styled.ListTitle>
                  <Styled.ErrorsList>
                    {err[key].map
                      ? err[key].map((error) => renderError(key, error))
                      : renderError(key, err[key])}
                  </Styled.ErrorsList>
                </Styled.Section>
              )),
            )
          ) : (
            <Styled.ErrorsItem>
              <Styled.Name>{t('errors.cannot_delete')}</Styled.Name>
            </Styled.ErrorsItem>
          )}
        </Styled.List>
      </Styled.Wrapper>
      <Modal.Footer>
        {isExpansionPage && (
          <Button type="button" danger onClick={handleDeleteExpansion}>
            {t('errors.buttons.confirm')}
          </Button>
        )}
        <Button type="button" onClick={closeModal}>
          {isExpansionPage ? t('errors.buttons.hide') : t(`errors.buttons.close`)}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ReferenceErrorsModal;
