import { createEffect, createEvent, createStore, sample } from 'effector';
import { isEmpty } from 'lodash';
import { createToggler } from 'shared/lib/toggler';

interface InsertedImageData {
  alt: string;
  imageHeight: number;
  imageWidth: number;
  selectImage: string;
}
interface InsertedImage {
  imgElement: HTMLImageElement;
  range: number;
  current: {
    editor: {
      clipboard: {
        dangerouslyPasteHTML: (range: number, imgElementOuterHTML: string) => void;
      };
    };
  };
}

interface ImageSize {
  width: number;
  height: number;
}

const toggler = createToggler();

const insertedImage = createEvent<InsertedImageData>();
const getImageSize = createEvent<{ width: number; height: number }>();

const $data = createStore({});
const $imageSize = createStore<ImageSize>({} as ImageSize);

export const editorImageModal = { toggler, insertedImage, getImageSize, $imageSize };

const insertedImageFx = createEffect((data: InsertedImage) => {
  data.current.editor.clipboard.dangerouslyPasteHTML(data.range, data.imgElement.outerHTML);
});

sample({
  clock: getImageSize,
  fn: (result) => result,
  target: $imageSize,
});

sample({
  clock: toggler.open,
  fn: ({ current }) => {
    let range = 0;
    const selection = current.unprivilegedEditor?.getSelection();

    if (!isEmpty(selection)) {
      range = selection?.index;
    }

    return { range, current };
  },
  target: $data,
});

sample({
  source: {
    data: $data,
  },
  clock: insertedImage,
  fn: ({ data }, values) => {
    const imgElement = document.createElement('img');
    imgElement.src = values.selectImage;
    imgElement.width = values.imageWidth;
    imgElement.height = values.imageHeight;
    imgElement.alt = values.alt;

    return {
      imgElement,
      ...data,
    };
  },
  target: insertedImageFx,
});

sample({
  clock: insertedImageFx.done,
  target: toggler.close,
});

sample({
  clock: insertedImageFx.done,
  fn: () => null,
  target: $imageSize,
});
