import React from 'react';
import omit from 'lodash/omit';
import Styled from './styles';
import EventInfo from '../../EventInfo';

interface Props {
  data: any;
  isFocused: boolean;
  innerProps: any;
  children: any;
}

const OptionWithEventComment: React.FC<Props> = (props) => {
  const { data, children, isFocused, innerProps } = props;

  return (
    <Styled.Option isFocused={isFocused} {...omit(innerProps, ['onMouseMove', 'onMouseOver'])}>
      <EventInfo name={children} place={data.description} />
    </Styled.Option>
  );
};

export default OptionWithEventComment;
