import { invoke } from '@withease/factories';
import { createStore, sample } from 'effector';
import { IMarkups } from 'shared/api/markups';
import * as markupsApi from 'shared/api/markups/markups';
import { createPagesMountStates } from 'shared/factory';

export const { pageMounted, $query, $loading } = invoke(createPagesMountStates);

export const $markups = createStore<IMarkups>({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});

sample({
  source: { query: $query },
  fn: ({ query }) => query,
  target: markupsApi.markupItemsFx,
});

sample({
  source: $query,
  fn: () => true,
  target: $loading,
});

sample({
  clock: markupsApi.markupItemsFx.done,
  fn: () => false,
  target: $loading,
});

sample({
  clock: markupsApi.markupItemsFx.done,
  fn: ({ result }) => result,
  target: $markups,
});
