import config from 'config/config';
import api from 'shared/services/api';

const route = `${config.COMMON_API_URL}/admin/v1/qna`;
export default {
  root: `${route}`,
  detail: (id) => `${route}/${id}`,

  async qnaItems(page, limit, search_string, sort_on, sort_by) {
    const response = await api.get(`${route}`, {
      params: {
        page,
        limit,
        search_string,
        sort_on,
        sort_by,
      },
    });

    return response.data;
  },

  async qnaItem(qnaId) {
    const response = await api.get(`${route}/${qnaId}`);

    return response.data;
  },

  async qnaItemCreate(data) {
    const response = await api.post(`${route}`, {
      title: data.title,
      weight: data.weight,
    });
    return response;
  },

  async qnaItemUpdate(qnaId, submitData) {
    const response = await api.patch(`${route}/${qnaId}`, {
      title: submitData.ru.title,
      weight: submitData.ru.weight,
    });

    return response.data;
  },

  async qnaItemDelete(qnaId) {
    const response = await api.delete(`${route}/${qnaId}`);

    return response;
  },

  async qnaItemCreateTranslation(id, data, formLang) {
    const response = await api.post(`${route}/${id}/info`, {
      question: data.question,
      answer: data.answer,
      language_code: formLang,
    });

    return response;
  },

  async qnaItemUpdateTranslation(id, lang, data) {
    const response = await api.patch(`${route}/${id}/info/${lang}`, {
      question: data.question,
      answer: data.answer,
    });

    return response;
  },
};
