import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import uuid from 'uuid/v4';
import { NotificationManager } from 'react-notifications';
import {
  WIDGET_DISPLAY_TYPES_OPTIONS,
  WIDGET_PATTERN_TYPES,
  WidgetDisplayTypes,
} from 'shared/constants/WIDGET';
import { findEmptyRules } from 'shared/helpers/query';
import Button from '../../../Button';
import Styled from '../WidgetForm/styles';
import { DataContext, DataContextProvider } from './DataContext';
import PatternSelector from '../PatternSelector';
import WidgetQueryBuilder from './WidgetQueryBuilder';
import { formatData } from 'shared/helpers/widgets';
import Modal from '../../../Modal';
import EventsLayout from '../../Events/EventsLayout';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import Spinner from '../../../Spinner';
import { Checkbox, NumberInput, RadioGroup } from '../../../FormControls';

const defaultQuery = (data) =>
  isEmpty(data.filters || (data.widget && data.widget.filters))
    ? { id: uuid(), rules: [], combinator: 'and' }
    : data.filters || data.widget.filters;

interface StepProps {
  data: any;
  onSubmit: (data: any) => any;
  isPreview?: boolean;
  goBack?: () => void;
  // eslint-disable-next-line no-undef
  backButton?: JSX.Element;
  withSubmit?: boolean;
  selectedDate?: any;
  eventDates?: any[];
  onChangeDate: any;
  isLoading?: boolean;
  withDisplayType?: boolean;
  withLeftoverThreshold?: boolean;
}

const WidgetSecondStep: React.FC<StepProps> = ({
  data,
  onSubmit,
  isPreview,
  goBack,
  backButton,
  selectedDate,
  eventDates,
  onChangeDate,
  withSubmit,
  isLoading,
  withDisplayType,
  withLeftoverThreshold,
}) => {
  const [submitting, setSubmitState] = useState(false);
  const [query, setQuery] = useState<any>({});
  const [displayType, setDisplayType] = useState<Number>(data.display_type || WidgetDisplayTypes.SEATS);
  const [leftoverThreshold, setLeftoverThreshold] = useState<Number>(data.leftover_threshold || 0);
  const [isTable, setIsTable] = useState<boolean>(data.is_table || false);
  const [useAnnouncements, setUseAnnouncements] = useState<boolean>(data.use_announcements || false);

  useEffect(() => {
    setQuery(defaultQuery(data));
  }, [data.filters || (data.widget && data.widget.filters)]);

  const submit = (submitType) => {
    const emptyRule = findEmptyRules(query.rules);

    if (emptyRule) {
      return NotificationManager.error('Заполните все поля', 'Ошибка');
    }

    if (submitType !== SUBMIT_ACTIONS.SAVE) {
      setSubmitState(true);
    }
    return onSubmit({
      filters: query,
      submit_action: submitType,
      display_type: displayType,
      leftover_threshold: leftoverThreshold,
      is_table: isTable,
      use_announcements: useAnnouncements,
    });
  };

  const handleChangePattern = ({ data: patternData }) => {
    setQuery(patternData.pattern);
  };

  const setDefaultQuery = () => {
    setQuery(defaultQuery(data));
  };

  const handleChangeDisplayType = ({ target }) => {
    setDisplayType(Number(target.value));
  };

  const handleChangeLeftoverThreshold = (value) => {
    setLeftoverThreshold(value);
  };

  const toggleIsTable = ({ target }) => {
    setIsTable(target.checked);
  };

  const toggleUseAnnouncements = ({ target }) => {
    setUseAnnouncements(target.checked);
  };

  const queryBuilder = (
    // eslint-disable-next-line react/jsx-no-undef
    <DataContextProvider pricesRules={formatData(data)}>
      <WidgetQueryBuilder query={query} setQuery={setQuery} isPreview={isPreview} />
    </DataContextProvider>
  );

  return (
    <EventsLayout.Wrapper>
      <Styled.Wrapper>
        <Styled.Title>
          Выберите места, которые будут отображаться в виджете или выберите уже существующий шаблон для мест
          {!isPreview && (
            <PatternSelector
              defaultQuery={defaultQuery(data)}
              type={WIDGET_PATTERN_TYPES.FILTER}
              setDefaultQuery={setDefaultQuery}
              onChange={handleChangePattern}
              selectedDate={selectedDate}
              eventDates={eventDates}
              onChangeDate={onChangeDate}
            />
          )}
        </Styled.Title>
        {withDisplayType && (
          <>
            <Styled.DisplayType>
              Форма продажи
              <Styled.DisplayTypeDescription>
                Продажа билетов по местам или по категориям
              </Styled.DisplayTypeDescription>
              <RadioGroup
                value={displayType}
                onChange={handleChangeDisplayType}
                buttons={WIDGET_DISPLAY_TYPES_OPTIONS}
              />
            </Styled.DisplayType>
            <Styled.IsTableCheckbox>
              <Checkbox variant="round" checked={isTable} onChange={toggleIsTable}>
                Таблица
              </Checkbox>
            </Styled.IsTableCheckbox>
            <Styled.IsTableCheckbox>
              <Checkbox variant="round" checked={useAnnouncements} onChange={toggleUseAnnouncements}>
                Показывать меню объявлений
              </Checkbox>
            </Styled.IsTableCheckbox>
          </>
        )}
        {withLeftoverThreshold && (
          <Styled.DisplayType>
            Остатки
            <Styled.DisplayTypeDescription>При каком % билетов выделять сектор</Styled.DisplayTypeDescription>
            <Styled.LeftoverThreshold>
              <NumberInput
                value={Number(leftoverThreshold)}
                onChange={handleChangeLeftoverThreshold}
                min={0}
                max={100}
              />
            </Styled.LeftoverThreshold>
          </Styled.DisplayType>
        )}
        {isLoading ? (
          <Spinner center />
        ) : (
          <>{data.eventDates ? <>{data ? queryBuilder : 'Выберите дату'}</> : queryBuilder}</>
        )}
      </Styled.Wrapper>
      <Modal.Footer fullSize justify>
        {backButton || (
          <Button transparent type="button" onClick={goBack} data-selenium="button-back">
            Назад
          </Button>
        )}
        {withSubmit && (
          <Button
            gray
            type="button"
            disabled={submitting}
            onClick={() => submit(SUBMIT_ACTIONS.SAVE)}
            data-selenium="button-save"
          >
            Сохранить
          </Button>
        )}
        <Button
          type="button"
          disabled={submitting}
          onClick={() => submit(SUBMIT_ACTIONS.CONTINUE)}
          data-selenium="button-continue"
        >
          Продолжить
        </Button>
      </Modal.Footer>
    </EventsLayout.Wrapper>
  );
};

// @ts-ignore
WidgetSecondStep.contextType = DataContext;

export default WidgetSecondStep;
