import PAGES from '../shared/constants/PAGES';

export default {
  [PAGES.MY_SALES]: {
    errorText: 'В заказах есть ошибки. Чтобы увидеть подробности наведите на чекбокс в строке объявления',
    showOnlyErrorsTooltipText: {
      default: 'Показать заказы только с ошибками',
      active: 'Показать все заказы',
    },
  },
  [PAGES.ANNOUNCEMENT_DETAIL]: {
    errorText: 'Не удалось выполнить действие на некоторых объявлениях',
    showOnlyErrorsTooltipText: {
      default: 'Показать объявления только с ошибками',
      active: 'Показать все объявления',
    },
  },
  [PAGES.PARSERS_EVENTS]: {
    errorText: 'Не удалось выполнить действие на некоторых парсерах',
    showOnlyErrorsTooltipText: {
      default: 'Показать парсеры только с ошибками',
      active: 'Показать все парсеры',
    },
  },
  [PAGES.LANDING_SITES]: {
    errorText: 'Не удалось выполнить действие на некоторых сайтах',
    showOnlyErrorsTooltipText: {
      default: 'Показать сайты только с ошибками',
      active: 'Показать все сайты',
    },
  },
  [PAGES.LANDING_EVENTS]: {
    errorText: 'Не удалось выполнить действие на некоторых мероприятиях',
    showOnlyErrorsTooltipText: {
      default: 'Показать мероприятия только с ошибками',
      active: 'Показать все мероприятия',
    },
  },
  [PAGES.LANDING_NEWS]: {
    errorText: 'Не удалось выполнить действие на некоторых новостях',
    showOnlyErrorsTooltipText: {
      default: 'Показать новости только с ошибками',
      active: 'Показать все новости',
    },
  },
  [PAGES.LANDING_PERSONALITIES]: {
    errorText: 'Не удалось выполнить действие на некоторых персоналиях',
    showOnlyErrorsTooltipText: {
      default: 'Показать персоналии только с ошибками',
      active: 'Показать все персоналии',
    },
  },
  [PAGES.LANDING_PLAYGROUNDS]: {
    errorText: 'Не удалось выполнить действие на некоторых площадках',
    showOnlyErrorsTooltipText: {
      default: 'Показать площадки только с ошибками',
      active: 'Показать все площадки',
    },
  },
  [PAGES.LANDING_SEO]: {
    errorText: 'Не удалось выполнить действие на некоторых позициях',
    showOnlyErrorsTooltipText: {
      default: 'Показать позиции только с ошибками',
      active: 'Показать все позиции',
    },
  },
  [PAGES.LANDING_CUSTOM]: {
    errorText: 'Не удалось выполнить действие на некоторых позициях',
    showOnlyErrorsTooltipText: {
      default: 'Показать позиции только с ошибками',
      active: 'Показать все позиции',
    },
  },
};
