import React from 'react';
import omit from 'lodash/omit';
import Styled from './styles';

interface OptionWithClientInfoProps {
  isFocused: boolean;
  innerProps: any;
  data: any;
}

const OptionWithClientInfo: React.FC<OptionWithClientInfoProps> = (props) => {
  const { children, innerProps, isFocused, data } = props;

  return (
    <Styled.Option {...omit(innerProps, ['onMouseMove', 'onMouseOver'])} isFocused={isFocused}>
      <Styled.Client>
        <Styled.ClientName>{children}</Styled.ClientName>
        <Styled.ClientInfo>
          {data.data.phone && <span>{data.data.phone}</span>}
          {data.data.email && <span>{data.data.email}</span>}
        </Styled.ClientInfo>
      </Styled.Client>
    </Styled.Option>
  );
};

export default OptionWithClientInfo;
