import styled, { createGlobalStyle } from 'styled-components';
import StyledTabs from 'components/Tabs/styles';
import StyledForm from 'components/Form/styles';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
    .modal__footer {
      margin: auto 0 0 0;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__body {
        padding: 0;
      }
  }
  `,
  Wrapper: styled.div`
    height: 100%;

    ${StyledForm.FormWrapper} {
      height: 100%;

      form {
        height: 100%;
      }
    }
  `,

  WidgetPreviewContainer: styled.div`
    position: relative;
    background: #f5f5f5;
    border-radius: 8px;
  `,

  WidgetPreviewTabs: styled.div`
    ${StyledTabs.HorizontalTabs} {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  `,

  WidgetPreviewBody: styled.div`
    min-height: 630px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${BREAKPOINTS.MD}) {
      min-height: auto;
    }
  `,

  OutdatedCheckbox: styled.div`
    margin-top: 8px;
  `,

  CheckboxContainer: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  `,

  InputWrapper: styled.div<{ auto?: boolean }>`
    margin-top: 15px;
    max-width: ${({ auto }) => (auto ? '100%' : '300px')};
  `,
};
