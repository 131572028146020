import React from 'react';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import { Checkbox, Input, InputWithSelect } from '../../../FormControls';
import { validEmail } from 'shared/helpers/validations';
import EmailIcon from '../../../../../static/icons/email.svg';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { companySources } from 'entities/company';
import { renderLandingPhones } from 'shared/helpers/optionsRenderers';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
  lng: string;
}

const ContactsStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack, lng }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Контакты</Styled.Title>
    </Styled.Header>
    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.FormRow>
              <Styled.FormControls>
                {lng === LANGUAGES.RU && (
                  <Styled.FormControl>
                    <Field name="contact_info_apikey">
                      {({ input, meta }) => <Input label="API key" meta={meta} {...input} />}
                    </Field>
                  </Styled.FormControl>
                )}
                <Styled.FormControl>
                  <Field name="contact_info_address">
                    {({ input, meta }) => <Input label="Адрес" meta={meta} {...input} />}
                  </Field>
                </Styled.FormControl>
                {lng === LANGUAGES.RU && (
                  <>
                    <Styled.FormControl>
                      <Field name="contact_info_phone">
                        {({ input, meta }) => (
                          <InputWithSelect
                            label="Номер телефона"
                            isAsync
                            route={companySources}
                            routeName="landingPhones"
                            optionsRenderer={(options) =>
                              renderLandingPhones(options, data.legal_information.id)
                            }
                            {...input}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </Styled.FormControl>
                    <Styled.FormControl>
                      <Field name="contact_info_second_phone">
                        {({ input, meta }) => (
                          <InputWithSelect
                            label="Второй номер телефона"
                            isAsync
                            route={companySources}
                            routeName="landingPhones"
                            optionsRenderer={(options) =>
                              renderLandingPhones(options, data.legal_information.id)
                            }
                            {...input}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </Styled.FormControl>
                    <Styled.FormControl>
                      <Field name="contact_info_email_support" validate={validEmail}>
                        {({ input, meta }) => (
                          <Input
                            label="E-mail support"
                            icon={<EmailIcon opacity={0.4} />}
                            {...input}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </Styled.FormControl>
                    <Styled.FormControl>
                      <Field name="contact_info_email_info" validate={validEmail}>
                        {({ input, meta }) => (
                          <Input
                            label="E-mail info"
                            icon={<EmailIcon opacity={0.4} />}
                            {...input}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </Styled.FormControl>
                    <Styled.FormControl>
                      <Field name="ui_config_show_contacts" type="checkbox">
                        {({ input, meta }) => (
                          <Checkbox variant="round" meta={meta} {...input}>
                            Не отображать юр. данные
                          </Checkbox>
                        )}
                      </Field>
                    </Styled.FormControl>
                  </>
                )}
              </Styled.FormControls>
            </Styled.FormRow>
            <ConstructorFooter
              goBack={goBack}
              form={form}
              updateData={updateData}
              withoutNextAction={lng !== LANGUAGES.RU}
            />
          </form>
        )}
      />
    </Styled.Content>
  </Styled.Container>
);

export default ContactsStep;
