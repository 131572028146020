export const reportsSources = {
  root: (type: string) => `report/${type}`,
  excel: (type: string) => `report/${type}/excel`,
  graph: (type: string) => `report/${type}/graph`,
  detail: (type: string) => `report/${type}/detail`,
  widget: 'report/order/widgets',
  provider: (type: string) => `report/${type}/provider`,
  total: (type: string) => `report/${type}/total`,
  searchSales: 'report/sales/search',
  searchRefunds: 'report/refund/search',
  searchPurchases: 'report/purchase/search',
  opex: 'report/opex',
  opexDetail: (id: number | string) => `report/opex/${id}`,
  clientContacts: 'report/client-contacts',
};
