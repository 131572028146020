import { Checkbox } from 'components/FormControls';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { selectRow, selectAllRows, selectManyRows, unSelectAllRows } from 'entities/tables';
import TABLES_ROW_ACTIONS from 'shared/constants/TABLES_ROW_ACTIONS';
import convertToCamelCase, { POSTFIXS } from 'shared/helpers/convertToCamelCase';

interface SelectorProps {
  page: string;
  ids: number[] | string[];
  ignoredIds?: number[] | string[];
  selectAllRows: (data: any) => any;
  unSelectAllRows: (data: any) => any;
  tablesSelectedRows: any;
}

const TableAllRowsSelector: React.FC<SelectorProps> = (props) => {
  const { page, tablesSelectedRows, ids: originalIds, ignoredIds: originalIgnoredIds } = props;

  const name = convertToCamelCase({ str: page, postfix: POSTFIXS.TABLE_SELECTED_ROWS });
  const ids = originalIds.map((item) => item.toString());
  const ignoredIds = originalIgnoredIds ? originalIgnoredIds.map((item) => item.toString()) : [];

  const handleChange = () => {
    if (tablesSelectedRows[name].ids.length > 0) {
      return props.unSelectAllRows({ name, rowAction: TABLES_ROW_ACTIONS.SELECT });
    }

    return props.selectAllRows({
      name,
      ids,
      rowAction: TABLES_ROW_ACTIONS.SELECT,
      ignoredIds,
    });
  };

  if (isEqual(ids, ignoredIds)) return null;

  return (
    <Checkbox
      isMulti={tablesSelectedRows[name].ids.length > 0 && !tablesSelectedRows[name].allSelected}
      variant="gray"
      onChange={handleChange}
      checked={tablesSelectedRows[name].allSelected}
    />
  );
};

TableAllRowsSelector.defaultProps = {
  ignoredIds: [],
};

const mapStateToProps = (state) => ({
  tablesSelectedRows: state.tables.selectedRows,
});

const mapDispatchToProps = { selectRow, selectAllRows, selectManyRows, unSelectAllRows };

export default connect(mapStateToProps, mapDispatchToProps)(TableAllRowsSelector);
