import React from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import Styled from './styles';
import EventInfo from '../../EventInfo';

interface Props {
  data: any;
  isFocused: boolean;
  innerProps: any;
  children: any;
}

const OptionWithEventInfo: React.FC<Props> = (props) => {
  const { data, children, isFocused, innerProps } = props;

  return (
    <Styled.Option isFocused={isFocused} {...omit(innerProps, ['onMouseMove', 'onMouseOver'])}>
      <EventInfo
        date={get(data, 'data.started_at')}
        name={children}
        place={get(data, 'data.place_name')}
        childrens={get(data, 'data.children')}
        withParentIndicator={get(data, 'withParentIndicator')}
        withSingleChildren={get(data, 'withSingleChildren')}
      />
    </Styled.Option>
  );
};

export default OptionWithEventInfo;
