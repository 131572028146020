import React from 'react';
import { useDispatch } from 'react-redux';
import { openRouterModal } from 'entities/modal';
import Styled from './styles';

interface CardProps {
  children: any;
  modalType?: string;
  data?: any;
  small?: boolean;
  noHover?: boolean;
  dataSelenium?: string | number;
}

export const ReferenceCard: React.FC<CardProps> = ({
  data,
  children,
  modalType,
  small,
  noHover,
  dataSelenium,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (modalType) {
      dispatch(openRouterModal({ modalType, data }));
    }
  };

  return (
    <Styled.Card small={small} noHover={noHover} onClick={handleClick} data-selenium={dataSelenium}>
      {children}
    </Styled.Card>
  );
};

export const ReferenceCardTitle = Styled.Title;
