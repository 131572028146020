import { connect } from 'react-redux';
import { createDelivery } from 'entities/delivery';
import OrderEditDeliveryModal from 'components/Modals/OrderEditDeliveryModal';

const mapStateToProps = (state) => ({
  order: state.orderDetail.data,
});
const mapDispatchToProps = {
  createDelivery,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderEditDeliveryModal);
