import React from 'react';
import { components } from 'react-select';

interface Props {
  selectProps: Record<string, unknown>;
}

const CustomInputWithSelect = (props: Props) => <components.Input {...props} name={props.selectProps.name} />;

export default CustomInputWithSelect;
