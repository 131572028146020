import Button from 'components/Button';
import Spinner from 'components/Spinner';
import map from 'lodash/map';
import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  loadCompanyMembers,
  inviteNewMember,
  loadCompanyMember,
  setMemberRole,
} from 'entities/company/members';
import { editModalData } from 'entities/modal';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import { ModalFunctions } from '../../../../../../../interfaces/modal';
import { CompanyMemberForm } from './form';

interface ModalProps {
  data?: any;
  router: Router;
  closeModal: ModalFunctions['closeModal'];
  modalState: string;
  setMemberRole: ({ user, roles }: { user: string | number; roles: number[] }) => void;
  inviteNewMember: (data: object) => void;
  loadCompanyMember: (id: number) => void;
  loadCompanyMembers: (query: object) => void;
  editModalData: (data: object) => void;
}

interface ModalState {
  data: any;
  isLoading: boolean;
  editSuccess: boolean;
  inviteSuccess: boolean;
  inviteEmail: string | null;
}

class Modal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      isLoading: props.modalState === MODAL_STATES.EDIT,
      editSuccess: false,
      inviteSuccess: false,
      inviteEmail: null,
    };
  }

  componentDidMount() {
    if (this.props.modalState === MODAL_STATES.CREATE) return;

    const { id } = this.props.data;

    this.props.loadCompanyMember(id).then((response) => {
      this.setState({
        isLoading: false,
        data: response.payload.data,
      });
    });
  }

  inviteMember = (data) => {
    const {
      router: { query },
    } = this.props;

    return this.props
      .inviteNewMember({
        ...data,
        roles: map(data.roles, (item) => item.value),
      })
      .then((response) => {
        if (!response.error) {
          this.props.editModalData({ modalData: { confirmClose: false } });
          this.setState({
            inviteSuccess: true,
            inviteEmail: data.email,
          });

          this.props.loadCompanyMembers(query);
        }

        return response;
      });
  };

  editMemeber = (data: { user: string; roles: [] }) => {
    const { id } = this.state.data;

    return this.props
      .setMemberRole({
        user: data.user,
        roles: map(data.roles, (item: { value: number }) => item.value),
      })
      .then(() => {
        this.props.editModalData({ modalData: { confirmClose: false } });
        this.setState({
          editSuccess: true,
        });
        this.props.loadCompanyMember(id);
      });
  };

  render() {
    const { modalState } = this.props;

    const { data: user, isLoading, editSuccess, inviteSuccess, inviteEmail } = this.state;

    if (isLoading) return <Spinner center />;

    switch (modalState) {
      case MODAL_STATES.CREATE: {
        if (inviteSuccess) {
          return `Приглашение отправлено на почту ${inviteEmail}`;
        }

        return (
          <CompanyMemberForm title="Добавление сотрудника" onSubmit={this.inviteMember} isInvite>
            <Button type="button" transparent onClick={this.props.closeModal}>
              Отмена
            </Button>
            <Button type="submit">Создать</Button>
          </CompanyMemberForm>
        );
      }

      case MODAL_STATES.EDIT: {
        if (editSuccess) {
          return 'Данный пользователь должен выполнить вход повторно';
        }

        return (
          <CompanyMemberForm
            title="Редактирование сотрудника"
            onSubmit={this.editMemeber}
            initialValues={{
              user: Number(user.id),
              roles: map(user.roles, (item) => ({ value: item.id, label: item.name })),
            }}
          >
            <Button type="button" transparent onClick={this.props.closeModal}>
              Отмена
            </Button>
            <Button type="submit">Сохранить</Button>
          </CompanyMemberForm>
        );
      }

      default:
        break;
    }

    return null;
  }
}

const mapDispatchToProps = {
  setMemberRole,
  inviteNewMember,
  loadCompanyMember,
  loadCompanyMembers,
  editModalData,
};

export const CompanyMemberModal = withRouter(connect(null, mapDispatchToProps)(Modal));
