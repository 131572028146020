import OptionWithPlaceInfo from 'components/FormControls/InputWithSelect/OptionWithPlaceInfo';
import get from 'lodash/get';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { referencePlaceSources } from 'shared/api/reference/places';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderReferencePlaces } from 'shared/helpers/optionsRenderers';
import { required } from 'shared/helpers/validations';
import eventCategorySources from 'shared/sources/eventCategory';
import { ModalFunctions } from '../../../../interfaces/modal';
import Button from '../../Button';
import Form from '../../Form';
import { InputWithSelect } from '../../FormControls';
import { OptionWithEventCategory } from '../../FormControls/InputWithSelect';
import Modal from '../../Modal/Modal';
import Styled from './styles';
interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  editParsersVenue: (id: number, data: any) => any;
  editParsersCategory: (id: number, data: any) => any;
}

class ParsersExternalldModal extends Component<ModalProps> {
  onSubmit = (data) => {
    const {
      editParsersVenue,
      editParsersCategory,
      data: { id, isVenues, isCategories },
    } = this.props;
    const requestData = { external_id: get(data, 'external_id.value') };

    if (isVenues) {
      return editParsersVenue(id, requestData);
    }

    if (isCategories) {
      return editParsersCategory(id, requestData);
    }

    return null;
  };

  render() {
    const {
      closeModal,
      data: { isVenues, isCategories },
    } = this.props;

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Form
          onSubmit={this.onSubmit}
          onSuccess={closeModal}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Title>Связать с</Modal.Title>
              {isVenues && (
                <Field name="external_id" validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      label="Площадка"
                      route={referencePlaceSources}
                      query={{ language_code: LANGUAGES.RU }}
                      optionsRenderer={renderReferencePlaces}
                      searchQueryName="search_string"
                      components={{ Option: OptionWithPlaceInfo }}
                      meta={meta}
                      {...input}
                    />
                  )}
                </Field>
              )}
              {isCategories && (
                <Field name="external_id" validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      label="Категория"
                      route={eventCategorySources}
                      routeName="search"
                      components={{ Option: OptionWithEventCategory }}
                      {...input}
                      meta={meta}
                    />
                  )}
                </Field>
              )}
              <Modal.Footer>
                <Button transparent type="button" onClick={closeModal}>
                  Отмена
                </Button>
                <Button>Добавить</Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Styled.Container>
    );
  }
}

export default ParsersExternalldModal;
