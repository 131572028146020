import React from 'react';
import EventPrices from 'components/EventPrices';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import { LandingEvent } from '../../../types/Event';
import { isPasteDate } from 'shared/helpers/events';

interface LandingsEventPricesProps {
  event: LandingEvent;
  goNext: () => void;
  goBack: () => void;
}

const LandingsEventPrices: React.FC<LandingsEventPricesProps> = (props) => {
  const { event, goBack, goNext } = props;

  const dates = event.event.dates
    ?.map((date) => {
      return {
        value: date.id,
        label: formatFullDateAndTime(date.started_at),
        outdated: isPasteDate(date.finished_at),
      };
    })
    .filter((d) => !d.outdated);

  return <EventPrices eventSourceId={event.id} eventDates={dates} goBack={goBack} goNext={goNext} />;
};

export default LandingsEventPrices;
