interface Item {
  code: string;
  name?: string;
}

export const groupAlphabetically = (data: Item[]) =>
  data.reduce((acc, curr) => {
    const title: string = (curr.code || curr.name)[0];
    acc[title] = acc[title] || [];

    acc[title].push(curr);

    return acc;
  }, {});
