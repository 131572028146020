import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { required } from 'shared/helpers/validations';
import Form from '../../Form';
import Button from '../../Button';
import { Input } from '../../FormControls';
import Modal from '../../Modal/Modal';
import Styled from './styles';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  loadSale: (id: number) => any;
  closeModal: ModalFunctions['closeModal'];
  setExternalId: (id: number, data: any) => any;
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

class OrderExternalIdModal extends Component<ModalProps> {
  onSubmit = (data) => {
    const {
      loadSale,
      data: { id },
      setExternalId,
    } = this.props;

    return setExternalId(id, data).then((response) => {
      if (response.error) {
        return response;
      }

      return loadSale(id);
    });
  };

  render() {
    const { closeModal, forceCloseModal } = this.props;

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Modal.Title>Добавление внешнего номера</Modal.Title>
        <Form
          onSubmit={this.onSubmit}
          onSuccess={forceCloseModal}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name="external_id" validate={required}>
                {({ input, meta }) => <Input label="Внешний номер заказа" {...input} meta={meta} />}
              </Field>

              <Modal.Footer>
                <Button type="button" transparent onClick={closeModal}>
                  Отмена
                </Button>
                <Button type="submit">Назначить</Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Styled.Container>
    );
  }
}

export default OrderExternalIdModal;
