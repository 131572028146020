import styled, { createGlobalStyle } from 'styled-components';
import { layoutScrollbar } from '../../../app/styles/scrollbar';
import { smallModalStyles, mobileModalStyles } from 'shared/mixins/modal';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}

    ${mobileModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  List: styled.div`
    margin: 0 -24px;
    display: flex;
    flex-direction: column;
    max-height: 320px;
    overflow: auto;
    ${layoutScrollbar};

    & label {
      min-height: 40px;
      transition: all 0.2s ease-in-out;
      padding: 0 24px;
      align-items: center;
      word-break: break-all;

      &:hover {
        background-color: #f4f4f4;
      }
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      margin: 0 -16px;
      max-height: 50vh;

      & label {
        padding: 0 16px;
      }
    }
  `,
};
