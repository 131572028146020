import config from 'config/config';
import { createEffect } from 'effector';
import { Router } from 'next/router';
import api from 'services/api';
import {
  IMarkup,
  IMarkupCopyUpdate,
  IMarkupCreatePriceRule,
  IMarkupFormValue,
  IMarkupRulesQuery,
  IMarkups,
} from './types';

const route = `${config.COMMON_API_URL}/admin/v2/prices`;

export const markupItemsFx = createEffect(async (query: Router['query'] = {}): Promise<IMarkups> => {
  const response = await api.get(route, {
    params: {
      ...query,
      search_string: query.search_string,
      limit: query.limit ? query.limit : 25,
    },
  });

  return response.data;
});

export const createMarkupFx = createEffect(async (title: string): Promise<IMarkup> => {
  const response = await api.post(route, {
    title,
    use_currency: true,
    round_enum: 'unit',
    currency_code: 'RUB',
  });

  return response.data;
});

export const markupItemUpdateFx = createEffect(
  async ({ priceId, data }: IMarkupFormValue): Promise<IMarkup> => {
    const response = await api.patch(`${route}/${priceId}`, {
      title: data.markup_name,
      filters: data.filters,
    });

    return response.data;
  },
);

export const createCopyMarkupFx = createEffect(async (title: string): Promise<IMarkup> => {
  const response = await api.post(route, {
    title,
    use_currency: true,
    round_enum: 'unit',
    currency_code: 'RUB',
  });

  return response.data;
});

export const markupItemFx = createEffect(async (priceId: number) => {
  const response = await api.get(`${route}/${priceId}`);

  return response.data;
});

export const copyMarkupItemUpdateFx = createEffect(
  async ({ priceId, data }: IMarkupCopyUpdate): Promise<IMarkup> => {
    const response = await api.patch(`${route}/${priceId}`, {
      title: '',
      filters: data.filters,
    });

    return response.data;
  },
);

export const rulesFx = createEffect(async (priceId: number | undefined) => {
  const response = await api.get(`${route}/${priceId}/rules`);

  return {
    markup: response.data.map((markup: { id: number }) => ({ id: markup.id, query: markup })),
  };
});

export const markupItemDeleteFx = createEffect(async (priceId: number, replacingPriceId: number | null) => {
  const response = await api.delete(`${route}/${priceId}`, { params: { new_price_id: replacingPriceId } });

  return response.data;
});

export const markupSources = {
  root: `${route}`,
  detail: (id: number) => `${route}/${id}`,

  async rules(priceId: number | undefined) {
    const response = await api.get(`${route}/${priceId}/rules`);

    return {
      markup: response.data.map((markup: { id: number }) => ({ id: markup.id, query: markup })),
    };
  },

  async createPriceRule({ priceId, markup }: IMarkupCreatePriceRule): Promise<IMarkupRulesQuery> {
    const response = await api.post(`${route}/${priceId}/rules`, markup?.query);

    return response.data;
  },

  async markupItemDelete(priceId: number, replacingPriceId: number | null) {
    const response = await api.delete(`${route}/${priceId}`, { params: { new_price_id: replacingPriceId } });

    return response.data;
  },
};
