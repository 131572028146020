import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const route = `${config.COMMON_API_URL}/admin/v1/events`;
const companyId = getCookie(COOKIES.COMPANY);

interface placesFilterSourcesProps {
  eventSourceId: number;
  filterId: number;
  event_date_id: number;
}

export const placesFilterSources = {
  root: `${config.COMMON_API_URL}/admin/v1/filters`,

  async filterTemplates(eventSourceId: number) {
    const response = await api.get(`${route}/${eventSourceId}/filters`);

    return response;
  },

  async bindFiltersToEvent(eventSourceId: number, filterId: number, data: any) {
    const response = await api.post(`${route}/${eventSourceId}/filters/${filterId}/dates`, data);

    return response.data;
  },

  async getEventFiltersDates(eventSourceId: number, filterId: number) {
    const response = await api.get(`${route}/${eventSourceId}/filters/${filterId}/dates`);

    return response.data;
  },

  async deleteEventFilter({ eventSourceId, filterId, event_date_id }: placesFilterSourcesProps) {
    const response = await api.delete(`${route}/${eventSourceId}/filters/${filterId}/dates/${event_date_id}`);
  },
};
