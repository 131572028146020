import React, { useEffect, useState } from 'react';
import { EVENTS } from 'shared/constants/EVENTS';
import { i18n } from '../../../i18n';
import Emmiter from 'shared/services/Emmiter';
import Tooltip from '../../Tooltip';
import Select from '../Select';
import Styled from './styles';
import api from 'shared/services/api';
import localesSource from 'shared/sources/locales';

interface LangSwitcherProps {
  isDisabled?: string;
  desktopColumn?: boolean;
  formLang?: string;
}

const LangSwitcher: React.FC<LangSwitcherProps> = ({ isDisabled, desktopColumn, formLang }) => {
  const [lng, setLng] = useState(formLang || i18n.language);
  const [locales, setLocales] = useState([]);
  const [disabled, toggleDisabled] = useState(true);

  const changeLang = ({ target: { value: newLng } }) => setLng(newLng);
  const toggleSwitcher = (state) => toggleDisabled(!state);

  useEffect(() => {
    api
      .get(localesSource.available)
      .then((response) => {
        const supportedLocales = response.data.map((item) => ({
          value: item.code,
          name: item.label,
        }));
        setLocales([...locales, ...supportedLocales]);
      })
      .catch((err) => new Error(err));

    Emmiter.on(EVENTS.SWITCHER_LANGUAGE_ENABLE, toggleSwitcher);
    Emmiter.on(EVENTS.CHANGE_LANGUAGE, changeLang);

    return () => {
      Emmiter.removeListener(EVENTS.SWITCHER_LANGUAGE_ENABLE, toggleSwitcher);
      Emmiter.removeListener(EVENTS.CHANGE_LANGUAGE, changeLang);
    };
  }, []);

  const handleChange = (e) => {
    Emmiter.emit(EVENTS.CHANGE_LANGUAGE, e);
  };

  return (
    <Tooltip text={(disabled && 'Сначала необходимо заполнить текущую локализацию') || isDisabled}>
      <Styled.Container desktopColumn={desktopColumn}>
        <Select
          small
          options={locales}
          value={lng}
          onChange={handleChange}
          disabled={disabled || Boolean(isDisabled)}
        />
      </Styled.Container>
    </Tooltip>
  );
};

export default LangSwitcher;
