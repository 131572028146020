import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface ContainerProps {
  desktopColumn?: boolean;
}

export default {
  Container: styled.div<ContainerProps>`
    position: relative;
    z-index: 2;
    margin: ${({ desktopColumn }) => (desktopColumn ? '8px 0 0 0' : '0 0 0 16px')};

    select {
      text-indent: 0;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      margin: '0 0 0 16px';
    }
  `,
};
