import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import mime from 'mime-types';
import ICONS from 'shared/constants/ICONS';
import Error from '../../Error';
import FileInputStyled from '../styles';
import FileCropperModal from './FileCropperModal';
import { STEPS, ASPECT_RATIO, STEPS_WORDS, SIZES } from 'shared/constants/IMAGE_CONFIG';
import Styled from './styles';
import { imageValidation } from 'shared/helpers/validations';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';
import { useForm } from 'react-final-form';

const SIZES_LABELS = {
  sm: 'S',
  md: 'M',
  lg: 'L',
};

interface Props {
  description?: string;
  meta?: any;
  onChange: (value: any) => any;
  value: any;
  placeholder?: string;
  stepsEnum?: {
    [key: string]: number;
  };
  ratio?: any;
  words?: string[];
  sizes?: string[];
  maxSize?: number;
  imageSizes?: any[];
  attachmentCode?: string;
  resetAttachmentForm?: (value: string) => any;
}

const FileCropperInput = (props: Props) => {
  const {
    description = 'Максимальное ограничение на размер файла - 1 мб',
    meta = {},
    onChange,
    value,
    placeholder,
    stepsEnum = STEPS,
    ratio = ASPECT_RATIO,
    words = STEPS_WORDS,
    maxSize,
    sizes = SIZES,
    imageSizes,
    attachmentCode,
    resetAttachmentForm,
  } = props;
  const [image, setImage] = useState(null);
  const hasValue = !isEmpty(value);

  const handleChange = ({ target }) => {
    if (!target.files.length) return;
    const [file] = target.files;

    if (imageValidation({ maxSize, file, target })) return;

    const reader = new FileReader();

    reader.onload = (event) => setImage({ result: event.target.result, type: file.type, file });
    reader.readAsDataURL(file);
  };

  const closeModal = () => {
    setImage(null);
  };

  const editImage = () => {
    Emmiter.emit(EVENTS.EDIT_ATTACHMENTS, attachmentCode);
    setImage({
      result: value.src,
      type: mime.lookup(value.src),
    });
  };

  const deleteImage = () => {
    Emmiter.emit(EVENTS.DELETE_ATTACHMENTS, attachmentCode);
    onChange({});
    if (attachmentCode && resetAttachmentForm) {
      resetAttachmentForm(attachmentCode);
    }
  };

  return (
    <>
      <div>
        <FileInputStyled.Container>
          <FileInputStyled.PreviewWrapper>
            {hasValue && (
              <Styled.Preview>
                {sizes.map((size) => (
                  <Styled.PreviewItem>
                    <Styled.PreviewImage size={size} src={value[size]} alt="" />
                    <Styled.PreviewType>{SIZES_LABELS[size]}</Styled.PreviewType>
                  </Styled.PreviewItem>
                ))}
              </Styled.Preview>
            )}
            {!hasValue && (
              <Styled.PreviewDefault>
                <FileInputStyled.Input key={image} type="file" size="90" onChange={handleChange} />
                <FileInputStyled.PreviewContainer>
                  {placeholder || (
                    <>
                      <img src={ICONS.UPLOAD_PICTURE} alt="Загрузить картинку" />
                      Загрузить картинку
                    </>
                  )}
                </FileInputStyled.PreviewContainer>
                <Error meta={meta} />
              </Styled.PreviewDefault>
            )}
          </FileInputStyled.PreviewWrapper>
          {!hasValue && (
            <FileInputStyled.Description>
              <FileInputStyled.Text>{description}</FileInputStyled.Text>
            </FileInputStyled.Description>
          )}
        </FileInputStyled.Container>
        {hasValue && (
          <Styled.EditButtons>
            <Styled.Download href={value.src} download target="_blank">
              Скачать оригинал
            </Styled.Download>
            <Styled.Edit type="button" onClick={editImage}>
              Редактировать
            </Styled.Edit>
            <Styled.Delete type="button" onClick={deleteImage}>
              Удалить
            </Styled.Delete>
          </Styled.EditButtons>
        )}
      </div>
      {image && (
        <FileCropperModal
          image={image}
          close={closeModal}
          value={value}
          onChange={onChange}
          stepsEnum={stepsEnum}
          ratio={ratio}
          words={words}
          sizes={sizes}
          imageSizes={imageSizes}
        />
      )}
    </>
  );
};

export default FileCropperInput;
