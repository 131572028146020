import React, { Component } from 'react';
import omit from 'lodash/omit';
import debouncePromise from 'debounce-promise';
import { NotificationManager } from 'react-notifications';
import { createSeatsValidation } from 'shared/helpers/validations';
import api from 'shared/services/api';
import { orderSources } from 'entities/order';
import Button from '../../Button';
import Styled from './styles';
import { prepareAnnouncementsData } from 'shared/helpers/announcement';
import ProgressModal from '../ProgressModal';
import OrderNewTicketsModalForm from '../../Order/OrderNewTicketsModalForm';
import Spinner from '../../Spinner';
import Modal from '../../Modal';
import { getCurrency, getCurrencyByCharCode } from 'shared/helpers/currency';
import CURRENCY_CHAR_CODES from 'shared/constants/CURRENCY_CHAR_CODES';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  addTicketsFromPool: (id: number, order: any) => any;
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

interface ModalState {
  data: any;
  isLoading: boolean;
  showProgress: boolean;
  announcements: { id: number }[];
  announcementsErrors: any[];
  currency: number;
}

class OrderNewTicketsModal extends Component<ModalProps, ModalState> {
  forms: {};

  seatsIds: {};

  seatsValidation: any;

  announcements: { id: number }[];

  constructor(props) {
    super(props);

    const id = new Date().getTime();

    this.forms = {};
    this.seatsIds = {};
    this.seatsValidation = null;
    this.announcements = [{ id }];

    this.state = {
      data: null,
      isLoading: true,
      showProgress: false,
      announcements: [{ id }],
      announcementsErrors: [],
      currency: null,
    };
  }

  async componentDidMount() {
    const {
      data: { id },
    } = this.props;

    const response = await api.get(orderSources.detail(id));
    const { options } = await getCurrency();

    const currency = getCurrencyByCharCode(options, CURRENCY_CHAR_CODES.RUB);

    this.setState(
      {
        data: response.data,
        currency: currency.value,
      },
      () => {
        this.seatsValidation = debouncePromise(
          createSeatsValidation({
            fromValues: true,
            event: this.state.data.event,
            setSeatsIds: this.setSeatsIds,
          }),
          500,
        );

        this.setState({
          isLoading: false,
        });
      },
    );
  }

  setSeatsIds = (rowId, seatsIds) => {
    this.seatsIds = {
      ...this.seatsIds,
      [rowId]: {
        ...(this.seatsIds[rowId] || {}),
        ...seatsIds,
      },
    };
  };

  setValues = (id, values, isCategories) => {
    this.announcements = this.announcements.map((announement) => {
      if (announement.id === id) {
        let announcementData = values;

        if (isCategories) {
          announcementData = omit(announcementData, ['sector', 'row', 'seat', 'count']);
        } else {
          announcementData = omit(announcementData, ['category', 'count_categories']);
        }

        return {
          id,
          ...announcementData,
        };
      }

      return announement;
    });
  };

  save = () => {
    const isValid = this.announcements.every((announcement) => {
      const form = this.forms[announcement.id];
      return !form.hasValidationErrors && !form.validating;
    });

    if (!isValid) {
      this.announcements.forEach((announcement) => this.forms[announcement.id].form.submit());
      return null;
    }

    const data = prepareAnnouncementsData({
      announcements: this.announcements,
      eventId: this.state.data.event.id,
      seatsIds: this.seatsIds,
      currency: this.state.currency,
    });

    return this.setState(
      {
        showProgress: true,
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      () =>
        api
          .post(orderSources.createTickets(this.state.data.id), { announcements: data })
          .then((response) => {
            const sellers = response.data.reduce((acc, announcement) => {
              const { _create_by: createBy } = announcement;

              acc[createBy.company.id] = acc[createBy.company.id] || {
                announcements: [],
              };
              const isStanding = !announcement.seat;

              if (isStanding) {
                acc[createBy.company.id].announcements.push({
                  id: announcement.id,
                  tickets: announcement.tickets.length,
                });
              } else {
                acc[createBy.company.id].announcements.push({
                  id: announcement.id,
                  tickets: announcement.tickets.map((ticket) => ticket.id),
                });
              }

              return acc;
            }, {});

            const order = {
              event_id: this.state.data.event.id,
              sellers: Object.keys(sellers).map((id) => ({
                ...sellers[id],
                id: Number(id),
              })),
            };

            this.props.addTicketsFromPool(this.state.data.id, order).then((action) => {
              if (action.error) {
                NotificationManager.error(action.error.response.data.error.join(' '), 'Ошибка');
              }

              this.props.forceCloseModal();
            });
          })
          .catch((response) => {
            this.setState({
              announcementsErrors: response.response.data.error,
            });
          }),
    );
  };

  addAnnouncement = () => {
    const id = new Date().getTime();
    this.setState((prevState) => ({
      announcements: [
        ...prevState.announcements,
        {
          id,
        },
      ],
    }));
    this.announcements.push({ id });
  };

  removeAnnouncement = (index) => {
    this.setState((prevState) => ({
      announcements: prevState.announcements.filter((_, i) => i !== index),
    }));

    this.announcements = this.announcements.filter((_, i) => i !== index);
  };

  hideProgress = () => {
    this.setState({
      showProgress: false,
      announcementsErrors: [],
    });
  };

  render() {
    const { closeModal } = this.props;
    const { announcements, announcementsErrors, showProgress, isLoading } = this.state;

    if (isLoading) {
      return (
        <>
          <Styled.GlobalStyles />
          <Styled.Spinner>
            <Spinner />
          </Styled.Spinner>
        </>
      );
    }

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        {showProgress && (
          <Styled.ProgressModalContainer>
            <ProgressModal
              useFakeProgress
              errors={announcementsErrors}
              title="Идет добавление билетов"
              hideProgress={this.hideProgress}
              isError={announcementsErrors.length > 0}
            />
          </Styled.ProgressModalContainer>
        )}
        <Modal.Title>Добавление билетов</Modal.Title>
        <Styled.Inner>
          <div>
            {announcements.map((announcement, index) => (
              <OrderNewTicketsModalForm
                index={index}
                event={this.state.data.event}
                forms={this.forms}
                key={announcement.id}
                setValues={this.setValues}
                announcement={announcement}
                seatsValidation={this.seatsValidation}
                removeAnnouncement={this.removeAnnouncement}
              />
            ))}
          </div>
          <Styled.AddPosition>
            <Button type="button" transparentBlue onClick={this.addAnnouncement}>
              + Добавить позицию
            </Button>
          </Styled.AddPosition>
        </Styled.Inner>
        <Modal.Footer>
          <Button type="button" transparent onClick={closeModal}>
            Отмена
          </Button>
          <Button type="button" onClick={this.save} disabled={announcements.length === 0}>
            Добавить билеты
          </Button>
        </Modal.Footer>
      </Styled.Container>
    );
  }
}

export default OrderNewTicketsModal;
