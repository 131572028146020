import { useUnit } from 'effector-react';
import React from 'react';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import { Modal, ModalClose } from 'shared/ui/modal';
import { CountryForm } from '../../form';
import { countryCreateModal } from '../model';
import Styled from './styles';

interface ModalProps {
  isMobile: boolean;
}

const CountryModal: React.FC<ModalProps> = (props) => {
  const handleSubmit = useUnit(countryCreateModal.createFormSubmitted);
  const { isMobile } = props;

  return (
    <Modal toggler={countryCreateModal.toggler}>
      {({ closeModal, forceCloseModal }) => {
        const createCountry = (data: any) => {
          handleSubmit(data);
        };

        return (
          <Styled.Body className="modal__body">
            <ModalClose onClick={closeModal} />
            <CountryForm
              key="create"
              title="references:country.create_title"
              onSubmit={createCountry}
              onSuccess={forceCloseModal}
              isMobile={isMobile}
              closeModal={closeModal}
              stashLanguages
            />
          </Styled.Body>
        );
      }}
    </Modal>
  );
};

export const CountryCreateModal = withCheckIsMobile(CountryModal);
