const SECTIONS = {
  TOP_AFISHA: 'top-afisha',
  AFISHA: 'afisha',
  NEWS: 'news',
  GALLERY: 'gallery',
  HOW_TO: 'how-to',
  LEGAL_INFO: 'legal-info',
  PLACE_INFO: 'place-info',
  REQUEST_FORM: 'request-form',
  QNA: 'qna',
};

const CONSTRUCTOR_HOME_SECTIONS_FIELDS = {
  [SECTIONS.TOP_AFISHA]: 'ui_config_block_name_top_afisha',
  [SECTIONS.AFISHA]: 'ui_config_block_name_afisha',
  [SECTIONS.NEWS]: 'ui_config_block_name_news',
  [SECTIONS.GALLERY]: 'ui_config_block_name_gallery',
  [SECTIONS.HOW_TO]: 'ui_config_block_name_faq',
  [SECTIONS.LEGAL_INFO]: 'ui_config_block_name_contact',
  [SECTIONS.PLACE_INFO]: 'ui_config_block_name_address',
  [SECTIONS.REQUEST_FORM]: 'ui_config_block_name_issue',
  [SECTIONS.QNA]: 'ui_config_qna_name',
};

export { CONSTRUCTOR_HOME_SECTIONS_FIELDS };

export default SECTIONS;
