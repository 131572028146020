import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface TableProps {
  mobileDisableBorder?: boolean;
}

const Table = styled.table<TableProps>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  & td {
    border-bottom: 1px solid #e3e1e0;
  }

  & tr {
    & td:first-child,
    & th:first-child {
      padding-left: 24px;
    }

    & td:last-child,
    & th:last-child {
      padding-right: 24px;
    }
  }

  @media (max-width: ${BREAKPOINTS.XL}) {
    & td {
      border-bottom: ${({ mobileDisableBorder }) => (mobileDisableBorder ? 'none' : null)};
    }
  }
`;

const Tbody = styled.tbody``;

interface TrProps {
  mobileFlex?: boolean;
  mobileFlexRow?: boolean;
  inModal?: boolean;
}

const Tr = styled.tr<TrProps>`
  background-color: #fff;
  border-radius: 4px;

  @media (max-width: ${BREAKPOINTS.XL}) {
    position: relative;

    ${({ mobileFlex }) =>
      mobileFlex &&
      css`
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e3e1e0;
      `}

    ${({ mobileFlexRow }) =>
      mobileFlexRow &&
      css`
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #e3e1e0;
      `}

    ${({ inModal }) =>
      inModal &&
      css`
        padding: 12px 0 0;
        margin: 0 48px;

        & > td,
        & > th {
          padding: 0;
          height: auto;
        }

        & > td:first-child,
        & > th:first-child {
          padding-left: 0;
          border-radius: 0;
        }
      `}
  }

  @media (max-width: ${BREAKPOINTS.MD}) {
    ${({ inModal }) =>
      inModal &&
      css`
        margin: 0 16px;
      `}
  }
`;

interface TheadProps {
  mobileHide?: boolean;
}

const Thead = styled.thead<TheadProps>`
  background: #fafafa;
  border-radius: 4px;

  & > ${Tr} {
    background-color: transparent;
  }

  @media (max-width: ${BREAKPOINTS.XL}) {
    display: ${({ mobileHide }) => (mobileHide ? 'none' : null)};
  }
`;

interface ThProps {
  width?: string;
  colorBlack?: boolean;
  mobileHide?: boolean;
  mobilePadding?: string;
  mobileRight?: boolean;
  clickable?: boolean;
}

const Th = styled.th<ThProps>`
  width: ${({ width }) => width || null};
  height: 40px;
  padding: 4px;
  font-weight: bold;
  font-size: 12px;
  text-align: ${({ align }) => align || 'left'};
  color: #5a5a5a;
  box-sizing: border-box;
  color: ${({ colorBlack }) => (colorBlack ? '#000' : null)};
  cursor: ${({ clickable }) => clickable && 'pointer'};

  @media (max-width: ${BREAKPOINTS.XL}) {
    display: ${({ mobileHide }) => (mobileHide ? 'none' : null)};
    padding: ${({ mobilePadding }) => mobilePadding || null};
    text-align: ${({ mobileRight }) => mobileRight && 'right'};
  }
`;

interface TdProps {
  padding?: string;
  minWidth?: string;
  bold?: boolean;
  verticalAlign?: string;
  noBreak?: boolean;
  mobileBold?: boolean;
  mobileDisplay?: string;
  mobileHide?: boolean;
  mobilePadding?: string;
  mobileOrder?: string | number;
  mobileWidth?: string;
  mobileRight?: boolean;
  tabletAutoHeight?: boolean;
  mobildeAlignItems?: string;
  mobileJustify?: boolean;
  mobileCenter?: boolean;
}

const Td = styled.td<TdProps>`
  padding: ${({ padding }) => padding || '10px 8px'};
  font-size: 14px;
  min-height: 40px;
  text-align: ${({ align }) => align || 'left'};
  min-width: ${({ minWidth }) => minWidth || null};
  width: ${({ width }) => width || null};
  font-weight: ${({ bold }) => (bold ? 'bold' : null)};
  vertical-align: top;
  box-sizing: border-box;
  color: ${({ color }) => color || null};
  vertical-align: ${({ verticalAlign }) => verticalAlign || null};
  word-break: ${(props) => (props.noBreak ? 'normal' : 'break-all')};

  @media (max-width: ${BREAKPOINTS.XL}) {
    font-weight: ${({ mobileBold }) => (mobileBold ? 'bold' : null)};
    display: ${({ mobileDisplay }) => mobileDisplay || null};
    display: ${({ mobileHide }) => (mobileHide ? 'none' : null)};
    padding: ${({ mobilePadding }) => mobilePadding || null};
    order: ${({ mobileOrder }) => mobileOrder || null};
    width: ${({ mobileWidth }) => mobileWidth || null};
    text-align: ${({ mobileRight }) => mobileRight && 'right'};
    min-height: ${({ tabletAutoHeight }) => (tabletAutoHeight ? '0' : null)};
    align-items: ${({ mobildeAlignItems }) => mobildeAlignItems || null};
    justify-content: ${({ mobileJustify }) => mobileJustify && 'center'};
    margin: ${({ margin }) => margin && margin};
    text-align: ${({ mobileCenter }) => mobileCenter && 'center'};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  padding-right: 43px;

  @media (max-width: ${BREAKPOINTS.XL}) {
    position: absolute;
    top: 16px;
    right: 0;
    padding: 4px;
  }
`;

export default {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonWrapper,
};
