import React from 'react';
import { Field } from 'react-final-form';
import { InputWithSelect, Input } from '../../FormControls';
import Form from '../../Form';
import Modal from '../../Modal';
import { ModalLayout } from 'widgets/layouts/modal-layout';
import Styled from './styles';
import Button from '../../Button';
import { authSources } from 'entities/auth';

interface FormProps {
  title: string;
  onSubmit: (data: any) => any;
  onSuccess?: (data: any) => any;
  isActive: boolean;
  toggleBan: () => void;
  isCurrentUser: boolean;
  initialValues?: any;
}

const EditMemberForm: React.FC<FormProps> = ({
  children,
  onSubmit,
  onSuccess,
  title,
  isActive,
  toggleBan,
  isCurrentUser,
  ...rest
}) => (
  <Form
    onSubmit={onSubmit}
    onSuccess={onSuccess}
    {...rest}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Styled.GlobalStyles />
        <Modal.Title>{title}</Modal.Title>
        <div>
          <ModalLayout.Row>
            <ModalLayout.Sidebar>
              <ModalLayout.RowTitle>Пользователь</ModalLayout.RowTitle>
              <ModalLayout.Description>Основная информация</ModalLayout.Description>
            </ModalLayout.Sidebar>
            <ModalLayout.Content>
              <ModalLayout.FormRow>
                <ModalLayout.Field>
                  <Field name="last_name">
                    {({ input, meta }) => <Input label="Фамилия" meta={meta} {...input} disabled />}
                  </Field>
                </ModalLayout.Field>
              </ModalLayout.FormRow>
              <ModalLayout.FormRow>
                <ModalLayout.Field>
                  <Field name="first_name">
                    {({ input, meta }) => <Input label="Имя" meta={meta} {...input} disabled />}
                  </Field>
                </ModalLayout.Field>
              </ModalLayout.FormRow>
              <ModalLayout.FormRow>
                <ModalLayout.Field>
                  <Field name="username">
                    {({ input, meta }) => <Input label="Почта" meta={meta} {...input} disabled />}
                  </Field>
                </ModalLayout.Field>
              </ModalLayout.FormRow>
            </ModalLayout.Content>
          </ModalLayout.Row>
          <ModalLayout.Row>
            <ModalLayout.Sidebar>
              <ModalLayout.RowTitle>Возможности</ModalLayout.RowTitle>
              <ModalLayout.Description>Доступные функции пользователю</ModalLayout.Description>
            </ModalLayout.Sidebar>
            <ModalLayout.Content>
              <ModalLayout.FormRow>
                <ModalLayout.Field>
                  <Field name="permissions">
                    {({ input, meta }) => (
                      <InputWithSelect
                        isAsync
                        isMulti
                        label="Возможности"
                        route={authSources}
                        routeName="adminPermissions"
                        withCounter
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                </ModalLayout.Field>
              </ModalLayout.FormRow>
            </ModalLayout.Content>
          </ModalLayout.Row>
          {!isCurrentUser && (
            <ModalLayout.Row>
              <ModalLayout.Sidebar>
                <ModalLayout.RowTitle>Блокировака</ModalLayout.RowTitle>
                <ModalLayout.Description>Ограничение доступа к аккаунту</ModalLayout.Description>
              </ModalLayout.Sidebar>
              <ModalLayout.Content>
                <ModalLayout.FormRow>
                  <ModalLayout.Field>
                    <Button type="button" onClick={toggleBan} dangerTransparent>
                      {isActive ? 'Заблокировать' : 'Разблокировать'}
                    </Button>
                  </ModalLayout.Field>
                </ModalLayout.FormRow>
              </ModalLayout.Content>
            </ModalLayout.Row>
          )}
        </div>
        <Modal.Footer>{children}</Modal.Footer>
      </form>
    )}
  />
);

export default EditMemberForm;
