import Button from 'components/Button';
import { LangForm } from 'components/Form';
import { Input, FormRow } from 'components/FormControls';
import Modal from 'components/Modal';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Field } from 'react-final-form';
import {
  composeValidators,
  required,
  minValue,
  maxValue,
  validSpecialChars,
} from 'shared/helpers/validations';
import Styled from 'shared/ui/reference/modal-styles';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { FormSubmitData } from 'shared/api/types';
import { TranslationInfoParams } from 'shared/helpers/translations';

interface FormProps {
  isMobile?: boolean;
  title: string;
  onChangeLng?: (lng: string) => void;
  isEdit?: boolean;
  closeModal?: ModalFunctions['closeModal'];
  openDeleteForm?: () => void;
  onSubmit?: (data: FormSubmitData) => void;
  onSuccess?: (data: object) => void;
  initialValues?: TranslationInfoParams | null;
  stashLanguages?: boolean;
}

export const CountryForm: React.FC<FormProps> = ({
  title,
  isMobile,
  onChangeLng,
  isEdit,
  closeModal,
  openDeleteForm,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <LangForm
      {...rest}
      onChangeLng={onChangeLng}
      withoutNotification
      render={({ handleSubmit, switcher, i18n }) => (
        <form onSubmit={handleSubmit}>
          {isMobile ? (
            <Styled.Header>
              <Styled.Title>{t(title)}</Styled.Title>
            </Styled.Header>
          ) : (
            <Modal.Title>{t(title)}</Modal.Title>
          )}
          <Styled.Content>
            <FormRow>{switcher}</FormRow>
            <>
              <FormRow>
                <Field name={`${i18n.language}.id`} component="input" type="hidden" />
                <Field
                  name={`${i18n.language}.title`}
                  validate={composeValidators(
                    required,
                    validSpecialChars(i18n.language),
                    minValue(3),
                    maxValue(40),
                  )}
                >
                  {({ input, meta }) => <Input label={t('references:country.name')} {...input} meta={meta} />}
                </Field>
              </FormRow>
              <Styled.FooterContainer>
                <Modal.Footer>
                  {isEdit ? (
                    <>
                      <Button type="submit">{t('forms:save')}</Button>
                      <Button danger onClick={openDeleteForm} type="button">
                        {t('forms:delete')}
                      </Button>{' '}
                    </>
                  ) : (
                    <>
                      {isMobile && (
                        <Button transparent onClick={closeModal}>
                          {t('forms:cancel')}
                        </Button>
                      )}
                      <Button>{t('forms:create')}</Button>
                    </>
                  )}
                </Modal.Footer>
              </Styled.FooterContainer>
            </>
          </Styled.Content>
        </form>
      )}
    />
  );
};
