import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import Modal from 'components/Modal';

interface RowProps {
  align?: string;
  width?: string;
}

interface LabelProps {
  noMargin?: boolean;
}

export default {
  GlobalStyles: createGlobalStyle`
    .modal__close {
      top: 16px;
      right: 16px;
      width: 40px;
      height: 40px;

      @media (max-width: ${BREAKPOINTS.MD}) {
        top: 0;
        right: 0;
      }
    }

    .modal__wrapper {
      max-width: 688px;
    }

    .modal__body {
      padding: 32px 40px;

      @media (max-width: ${BREAKPOINTS.MD}) {
        padding: 16px;
      }
    }
  `,

  Container: styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 28px;
  `,

  Title: styled.h3`
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-bottom: 24px;
    }
  `,

  Row: styled.div<RowProps>`
    box-sizing: border-box;
    display: flex;
    align-items: ${(props) => (props.align ? props.align : 'flex-start')};
    width: ${(props) => (props.width ? props.width : '100%')};
  `,

  MessageField: styled.div`
    width: 100%;
    max-width: 400px;
    margin-top: 8px;
    margin-bottom: 16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-width: 100%;
    }
  `,

  Comments: styled.div`
    width: 100%;
    margin-bottom: -32px;
  `,

  EventInfo: styled.div`
    box-sizing: border-box;
    margin-bottom: 32px;
  `,

  EventTitle: styled.div`
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 23px;
  `,

  EventDate: styled.div`
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
  `,

  Place: styled.div`
    font-size: 12px;
    line-height: 16px;
  `,

  PlaceAddress: styled.span`
    position: relative;
    color: #5c5c5c;

    &:before {
      position: relative;
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      margin: 0 5px;
      background-color: #5c5c5c;
      vertical-align: middle;
      border-radius: 50%;
    }
  `,

  TotalContainer: styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #e1e1e1;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  `,

  Body: styled.div`
    margin: 0 -48px;

    & tr {
      & > td:first-child,
      & > th:first-child {
        padding-left: 48px;

        @media (max-width: ${BREAKPOINTS.XL}) {
          padding-left: 0;
        }
      }
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 0 -16px;
    }
  `,

  Footer: styled(Modal.Footer)`
    margin: 32px -40px -32px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 16px;
      margin: 32px -16px -32px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 8px;
      margin: 32px -16px -16px;
    }
  `,

  Context: styled.div`
    display: flex;
    align-items: center;
    margin: 32px -40px 0;
    padding: 32px 40px 12px;
    border-top: 1px solid #e1e1e1;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
      margin: 32px -16px 0;
      padding: 32px 16px 12px;
    }
  `,

  ContextSide: styled.div`
    flex-shrink: 0;
    width: 162px;
    margin-right: 16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      margin: 0 0 24px;
    }
  `,

  ContextTitle: styled.div`
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 21px;
  `,

  ContextDescription: styled.div`
    font-size: 14px;
    line-height: 18px;
    color: #5c5c5c;
  `,

  ContextInputs: styled.div`
    width: 100%;
    display: flex;
    margin: 0 -16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
    }
  `,

  ContextInput: styled.div`
    min-width: 180px;
    margin: 0 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  `,

  PhoneInput: styled.div`
    width: 100%;
    margin: 0 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  `,

  TotalPrice: styled.div`
    display: flex;
    align-items: flex-end;
    margin-left: auto;

    & > div:first-child {
      margin-right: 8px;
      margin-bottom: 0;
      line-height: 18px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin-left: 0;
      margin-bottom: 16px;
    }
  `,

  Label: styled.div<LabelProps>`
    margin-bottom: ${({ noMargin }) => !noMargin && '4px'};
    font-size: 12px;
    line-height: 16px;
    color: #5c5c5c;
  `,

  Value: styled.div`
    font-size: 16px;
  `,
};
