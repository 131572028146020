import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  isSimple?: boolean;
}

export default {
  Wrapper: styled.div`
    margin-bottom: 32px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 0 -16px 32px;
    }
  `,

  Ticket: styled.div<CSSProps>`
    padding: ${({ isSimple }) => (isSimple ? '12px 8px 12px 16px' : '12px 8px 4px 16px')};
    border: 1px dashed #e1e1e1;
    border-radius: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
  `,

  HeadingRow: styled.div<CSSProps>`
    box-sizing: border-box;
    position: relative;
    display: flex;
    padding-right: 40px;
    margin-bottom: ${({ isSimple }) => isSimple && '-18px'};
  `,

  TicketRow: styled.div<CSSProps>`
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: ${({ isSimple }) => (isSimple ? 'flex-end' : 'center')};
    padding-right: 40px;
    min-height: 40px;
  `,

  TicketCol: styled.div`
    box-sizing: border-box;
    padding-right: 8px;

    &:nth-child(1) {
      width: 35%;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4) {
      width: 10%;
    }

    &:nth-child(5) {
      width: 20%;
      text-align: right;
    }
  `,

  Delete: styled.div`
    position: absolute;
    top: 0;
    right: 0;
  `,

  Label: styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #5c5c5c;
  `,

  Value: styled.div`
    font-size: 16px;
  `,

  Footer: styled.div`
    display: flex;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 0 16px 0 8px;
    }
  `,

  SellerName: styled.div`
    flex: 1 1 auto;
    width: 1px;
    padding: 0 16px 0 8px;
    overflow: hidden;
  `,

  TotalPrice: styled.div`
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    margin-left: auto;

    & > div:first-child {
      margin-right: 8px;
      margin-bottom: 0;
      line-height: 18px;
    }
  `,

  MessageField: styled.div`
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    margin-top: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      max-width: 100%;
      padding: 0 16px;
    }
  `,

  Count: styled.span`
    display: none;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: initial;
    }
  `,

  Currency: styled.img`
    max-height: 10px;
    width: auto;
    vertical-align: baseline;
    opacity: 0.6;
    transform: translateY(1px);
  `,
};
