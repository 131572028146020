import React from 'react';
import { Field } from 'react-final-form';
import Form from '../../../Form';
import { Checkbox, InputWithSelect, FormDescription, SmallFormDescription } from '../../../FormControls';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import StyledSelect from 'containers/Pages/Landings/SiteModal/styles';
import qna from 'shared/sources/reference/qna';
import { renderContentGroups } from 'shared/helpers/optionsRenderers';
import landingsSites from 'shared/sources/landings/landingsSites';

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const QNAStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => {
  const onSubmitQnA = (submitData) => {
    const newQnAIds = submitData?.qna.map((i) => i.value);
    const initialQnAIds = data.qna?.map((i) => i.value);
    const initialQnA = new Set(initialQnAIds);
    const newQnA = new Set(newQnAIds);
    const QnAforDelete = initialQnAIds?.filter((x) => !newQnA.has(x));
    const QnAforUpdate = newQnAIds?.filter((x) => !initialQnA.has(x));

    if (QnAforUpdate.length) {
      landingsSites.createQnA(data.tech_name, QnAforUpdate);
    }
    if (QnAforDelete.length) {
      landingsSites.deleteQnA(data.tech_name, QnAforDelete);
    }
    onSubmit(submitData);
  };
  return (
    <Styled.Container withTabs>
      <Styled.Header withTabs>
        <Styled.Title>Вопрос — Ответ</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          onSubmit={onSubmitQnA}
          initialValues={data}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <>
                <StyledSelect.Section>
                  <FormDescription width={165}>
                    QnA
                    <SmallFormDescription>Добавление QnA</SmallFormDescription>
                  </FormDescription>
                  <StyledSelect.Row>
                    <Field name="qna">
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          isMulti
                          label="QnA"
                          searchQueryName="search_string"
                          optionsRenderer={renderContentGroups}
                          route={qna}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </StyledSelect.Row>
                </StyledSelect.Section>
                <Styled.FormRow>
                  <Styled.FormDescription>Отображение</Styled.FormDescription>
                  <Styled.FormControls>
                    <Styled.FormControl>
                      <Field name="ui_config_qna_per_event" type="checkbox">
                        {({ input, meta }) => (
                          <Checkbox variant="round" meta={meta} {...input}>
                            Показ блока на странице мероприятий
                          </Checkbox>
                        )}
                      </Field>
                      <Styled.FormSmallDescription>
                        Показывать блок вопрос ответ на всех страницах с мероприятиями
                      </Styled.FormSmallDescription>
                    </Styled.FormControl>
                    <Styled.FormControl>
                      <Field name="ui_config_qna_show_bg" type="checkbox">
                        {({ input, meta }) => (
                          <Checkbox variant="round" meta={meta} {...input}>
                            Добавить фон
                          </Checkbox>
                        )}
                      </Field>
                      <Styled.FormSmallDescription>
                        Добавить фон к вопросам (дизайн)
                      </Styled.FormSmallDescription>
                    </Styled.FormControl>
                  </Styled.FormControls>
                </Styled.FormRow>
              </>

              <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
            </form>
          )}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default QNAStep;
