import styled, { css } from 'styled-components';

interface PreviewProps {
  length: number;
  disabled?: boolean;
}

interface StyledInputProps {
  small?: boolean;
  withoutOverflow?: boolean;
}

export default {
  Wrapper: styled.div`
    width: 100%;
  `,

  Container: styled.div`
    display: flex;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
  `,

  Preview: styled.label<PreviewProps>`
    box-sizing: border-box;
    position: relative;
    flex: 1 1 100%;
    padding: 9px 8px 8px;
    max-width: ${({ length }) => `${(100 / length).toFixed(4)}%`};
    line-height: 21px;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        color: #999;
      `}
  `,

  PreviewText: styled.div``,

  StyledInput: styled.input<StyledInputProps>`
    position: absolute;
    left: -9999em;
    top: -9999em;
    visibility: hidden;

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        color: #999;
      `}

    & + label {
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          bottom: 10px;
          display: block;
          width: 1px;
          background: #e1e1e1;
        }
      }
    }

    & + label {
      /* small */
      font-size: ${({ small }) => small && '14px'};

      & div {
        /* withoutOverflow */
        ${({ withoutOverflow }) =>
          !withoutOverflow &&
          css`
            text-overflow: ellipsis;
            overflow: hidden;
          `}
      }
    }

    &:checked + label {
      &:after {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        display: block;
        border: 1px solid #ffda00;
        border-radius: 8px;

        ${({ disabled }) =>
          disabled &&
          css`
            cursor: not-allowed;
          `}
      }
    }

    &:checked + label {
      background: #fff6c2;
    }
  `,
};
