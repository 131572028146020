export const WIDGET_FILTERS = {
  PARSERS: 'parsers',
  SECTOR: 'sector',
  CATEGORY: 'category',
  PROVIDER: 'provider',
  COMPANY: 'company',
  PRICE: 'price',
  ALL_TICKETS: 'all_tickets',
  ZRITELI: 'zriteli',
  ROW: 'row_id',
  SEAT: 'seat_id',
};

export const WIDGET_PATTERN_TYPES = {
  MARKUP: '0',
  FILTER: '1',
};

export const WIDGET_PATTERN_TYPES_LABELS = {
  [WIDGET_PATTERN_TYPES.MARKUP]: 'Наценка',
  [WIDGET_PATTERN_TYPES.FILTER]: 'Фильтр',
};

export const WIDGET_OPTIONS = {
  EDIT: 'edit',
  VIEW: 'view',
  CLONE: 'clone',
  DELETE: 'delete',
};

export enum WidgetDisplayTypes {
  SEATS = 'default',
  CATEGORIES = 'categories',
}

export const WIDGET_DISPLAY_TYPES_OPTIONS = [
  {
    id: WidgetDisplayTypes.SEATS,
    value: WidgetDisplayTypes.SEATS,
    name: 'Места',
  },
  {
    id: WidgetDisplayTypes.CATEGORIES,
    value: WidgetDisplayTypes.CATEGORIES,
    name: 'Категории',
  },
];
