import DataPreview from 'components/DataPreview';
import { LangForm } from 'components/Form';
import { Input, FormRow, FileInput, Checkbox, Textarea } from 'components/FormControls';
import Modal from 'components/Modal';
import React from 'react';
import { Field } from 'react-final-form';
import IMAGE_TYPES from 'shared/constants/IMAGE_TYPES';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import {
  composeValidators,
  required,
  minValue,
  maxValue,
  validSpecialCharsWithDots,
} from 'shared/helpers/validations';
import Styled from 'shared/ui/reference/modal-styles';

interface FormProps {
  isMobile?: boolean;
  title: string;
  // eslint-disable-next-line no-undef
  children: JSX.Element | JSX.Element[];
  state: any;
  onChangeLng?: (lng: string) => any;
  t: (value: string, params?: any) => string;
  onSubmit?: (data: any) => any;
  onSuccess?: (data: any) => any;
  stashLanguages?: boolean;
  initialValues?: any;
}

const ExpansionForm: React.FC<FormProps> = ({
  children,
  title,
  isMobile,
  state,
  onChangeLng,
  t,
  ...rest
}) => (
  <DataPreview state={state}>
    <Styled.NarrowModalStyles />
    <LangForm
      onChangeLng={onChangeLng}
      {...rest}
      render={({ handleSubmit, switcher, i18n }) => {
        const disabledByLng = i18n.language !== LANGUAGES.RU;
        return (
          <form onSubmit={handleSubmit}>
            {isMobile ? (
              <Styled.Header>
                <Styled.Title>{t(title)}</Styled.Title>
              </Styled.Header>
            ) : (
              <Modal.Title>{t(title)}</Modal.Title>
            )}
            <Styled.Content>
              <FormRow>{switcher}</FormRow>
              <Field name={`${i18n.language}.id`} component="input" type="hidden" />
              <Styled.Table>
                <Styled.TableRow>
                  <Styled.TableCellTitle>
                    {t('references:expansion.common_information')}
                    <Styled.TableCellTitleSubText>
                      {t('references:expansion.name')} <Styled.Br />
                      {t('references:expansion.and_description')}
                    </Styled.TableCellTitleSubText>
                  </Styled.TableCellTitle>
                  <Styled.TableCell>
                    <FormRow>
                      <Field
                        name={`${i18n.language}.name`}
                        validate={composeValidators(
                          required,
                          validSpecialCharsWithDots(i18n.language),
                          minValue(3),
                          maxValue(100),
                        )}
                      >
                        {({ input, meta }) => (
                          <Input label={t('references:expansion.name')} autoFocus {...input} meta={meta} />
                        )}
                      </Field>
                    </FormRow>
                    <FormRow>
                      <Field
                        name={`${i18n.language}.description`}
                        validate={composeValidators(required, minValue(3), maxValue(100))}
                      >
                        {({ input, meta }) => (
                          <Textarea
                            label={t('references:expansion.description')}
                            maxValue={100}
                            {...input}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </FormRow>
                    {!disabledByLng && (
                      <FormRow>
                        <Field name={`${i18n.language}.is_external`} type="checkbox">
                          {({ input, meta }) => (
                            <>
                              <Checkbox variant="round" meta={meta} {...input}>
                                {t('references:expansion.is_external')}
                              </Checkbox>
                              <Styled.InputSubtitle>
                                {t('references:expansion.is_external_label')}
                              </Styled.InputSubtitle>
                            </>
                          )}
                        </Field>
                      </FormRow>
                    )}
                  </Styled.TableCell>
                </Styled.TableRow>
                {!disabledByLng && (
                  <Styled.TableRow>
                    <Styled.TableCellTitle>
                      {t('references:expansion.icon')}
                      <Styled.TableCellTitleSubText>
                        {t('references:expansion.icon_subtitle')}
                      </Styled.TableCellTitleSubText>
                    </Styled.TableCellTitle>
                    <Styled.TableCell>
                      <Field name={`${i18n.language}.icon`} validate={required}>
                        {({ input, meta }) => (
                          <FileInput
                            withPreview
                            imageTypes={[IMAGE_TYPES.PNG, IMAGE_TYPES.JPEG, IMAGE_TYPES.SVG]}
                            {...input}
                            meta={meta}
                            maxSize={1}
                            placeholder="+"
                            linkMaxWidth={290}
                            description={t('references:expansion.icon_requirements')}
                            expansionIcon={true}
                          />
                        )}
                      </Field>
                    </Styled.TableCell>
                  </Styled.TableRow>
                )}
              </Styled.Table>

              <Styled.FooterContainer>
                <Modal.Footer>{children}</Modal.Footer>
              </Styled.FooterContainer>
            </Styled.Content>
          </form>
        );
      }}
    />
  </DataPreview>
);

export default ExpansionForm;
