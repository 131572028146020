import React from 'react';
import QueryBuilder from 'react-querybuilder';
import isEmpty from 'lodash/isEmpty';
import { WIDGET_FILTERS } from 'shared/constants/WIDGET';
import QUERIES from 'shared/constants/QUERIES';
import RemoveButton from '../SecondStep/RemoveButton';
import ValueSelector from '../SecondStep/ValueSelector';
import Action from '../SecondStep/Action';
import FieldSelect from '../SecondStep/FieldSelect';
import ValueEditor from '../SecondStep/ValueEditor';
import { DataContextProvider } from '../SecondStep/DataContext';

const fields = [
  { name: WIDGET_FILTERS.PARSERS, label: 'Парсинг' },
  { name: WIDGET_FILTERS.PROVIDER, label: 'Поставщик' },
  { name: WIDGET_FILTERS.COMPANY, label: 'Брокер' },
  { name: WIDGET_FILTERS.ZRITELI, label: 'Зрители' },
];

const operators = [
  { name: QUERIES.IN, label: 'Соответствует' },
  { name: QUERIES.NOT_IN, label: 'Не соответствует' },
  { name: QUERIES.EQUAL, label: 'Равен' },
  { name: QUERIES.NOT_EQUAL, label: 'Не равен' },
];

interface FilterProps {
  onChange: (data: any) => any;
  isPreview?: boolean;
  data?: any;
}

const WidgetPatterFilter: React.FC<FilterProps> = ({ data, onChange, isPreview }) => (
  <DataContextProvider pricesRules={null}>
    {/*
    // @ts-ignore */}
    <QueryBuilder
      query={data && !isEmpty(data) ? data : null}
      fields={fields}
      operators={operators}
      onQueryChange={onChange}
      isPreview={isPreview}
      controlElements={{
        combinatorSelector: ValueSelector,
        addGroupAction: Action,
        addRuleAction: Action,
        fieldSelector: FieldSelect,
        operatorSelector: FieldSelect,
        removeRuleAction: RemoveButton,
        removeGroupAction: RemoveButton,
        valueEditor: ValueEditor,
      }}
      translations={{
        fields: {
          title: 'Fields',
        },
        operators: {
          title: 'Operators',
        },
        value: {
          title: 'Value',
        },
        removeRule: {
          label: 'x',
          title: 'Удалить критерий',
        },
        removeGroup: {
          label: 'x',
          title: 'Удалить группу',
        },
        addRule: {
          label: 'Добавить критерий',
          title: 'Добавить критерий',
        },
        addGroup: {
          label: 'Добавить группу',
          title: 'Добавить группу',
        },
        combinators: {
          title: 'Combinators',
        },
      }}
      combinators={[
        { name: 'and', label: 'И' },
        { name: 'or', label: 'Или' },
      ]}
    />
  </DataContextProvider>
);

export default WidgetPatterFilter;
