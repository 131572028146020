import config from 'config/config';
import { createEffect } from 'effector';
import api from '../../../services/api';
import { CreateHallLayoutData, HallLayout, HallLayoutsParams, PaginatedHallLayouts } from './types';

const route = `${config.EVENT_API_URL}/api/playgrounds/hall-layout`;

export const fetchHallLayoutsFx = createEffect(
  async (params: HallLayoutsParams): Promise<PaginatedHallLayouts> => {
    const response = await api.get(route, {
      params: params,
    });

    return response.data;
  },
);

export const fetchHallLayoutFx = createEffect(async ({ id }: { id: number }): Promise<HallLayout> => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const createHallLayoutFx = createEffect(async (data: CreateHallLayoutData): Promise<HallLayout> => {
  const response = await api.post(route, data);

  return response.data;
});

export const updateHallLayoutFx = createEffect(
  async ({ id, data }: { id: number; data: CreateHallLayoutData }): Promise<HallLayout> => {
    const response = await api.patch(`${route}/${id}`, data);

    return response.data;
  },
);

export const deleteHallLayoutFx = createEffect(async (id: number) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});
