import styled from 'styled-components';

interface PreviewProps {
  color: string;
}

export default {
  Container: styled.div`
    position: relative;
    padding: 6px 4px;
    height: 40px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    width: 166px;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }
  `,

  Body: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  Picker: styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
  `,

  Preview: styled.div<PreviewProps>`
    background: ${(props) => props.color};
    width: 24px;
    height: 24px;
    border: 1px solid #e1e1e1;
    border-radius: 24px;
  `,
};
