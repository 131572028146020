import React from 'react';
import omit from 'lodash/omit';
import Styled from './styles';
import Checkbox from '../Checkbox';
import preventClick from 'shared/helpers/preventClick';

interface Props {
  setValue: (value: any) => void;
  isFocused: boolean;
  isSelected: boolean;
  innerProps: any;
  selectProps: any;
  options: any[];
  value: number | string;
  children: any;
}

const OptionWithCheckbox: React.FC<Props> = (props) => {
  const { children, innerProps, isSelected, isFocused, options, value, setValue } = props;
  const { withExceptOption } = props.selectProps;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const all = options.reduce((acc, option) => {
      if (option.options) {
        const innerOptions = option.options.filter((innerOption) => innerOption.value !== value);

        acc.push(...innerOptions);
      } else if (option.value !== value) {
        acc.push(option);
      }

      return acc;
    }, []);
    setValue(all);
  };

  const handleOptionClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    innerProps.onClick();
  };

  return (
    <Styled.Option
      isFocused={isFocused}
      onClick={handleOptionClick}
      onTouchEnd={preventClick}
      {...omit(innerProps, ['onMouseMove', 'onMouseOver', 'onClick'])}
      data-selenium={children}
    >
      <Styled.OptionCheckbox>
        <Checkbox plain checked={isSelected} readOnly />
      </Styled.OptionCheckbox>
      {children}
      {withExceptOption && (
        <Styled.OptionAllExceptCurrent onClick={handleClick}>Все кроме</Styled.OptionAllExceptCurrent>
      )}
    </Styled.Option>
  );
};

export default OptionWithCheckbox;
