import React from 'react';
import { Field } from 'react-final-form';
import { maxValue } from 'shared/helpers/validations';
import Form from '../../../../Form';
import {
  Textarea,
  FileCropperInput,
  Checkbox,
  NumberInput,
  ImagePositionSelect,
} from '../../../../FormControls';
import Styled from '../styles';
import HeroStepStyled from './styles';
import ConstructorFooter from '../ConstructorFooter';
import { LANDINGS_IMAGE_SIZES } from 'shared/constants/IMAGE_CONFIG';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

interface Props {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
  lng: string;
}

const HeroStep: React.FC<Props> = ({ onSubmit, updateData, goBack, data, lng }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Заполните данные для Обложки</Styled.Title>
    </Styled.Header>
    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form, values }) => {
          if (!values.main_page_banner_text) {
            form.change('main_page_banner_text', '');
          }

          if (!values.main_page_banner_sub_text) {
            form.change('main_page_banner_sub_text', '');
          }

          return (
            <form onSubmit={handleSubmit}>
              <Styled.FormRow>
                <Styled.FormDescription>Текст на обложке сайта</Styled.FormDescription>
                <Styled.FormControls>
                  <Styled.FormControl>
                    <Field name="main_page_banner_text" validate={maxValue(80)}>
                      {({ input, meta }) => (
                        <Textarea label="Заголовок" {...input} meta={meta} maxValue={80} />
                      )}
                    </Field>
                  </Styled.FormControl>
                  <Styled.FormControl>
                    <Field name="main_page_banner_sub_text" validate={maxValue(120)}>
                      {({ input, meta }) => (
                        <Textarea label="Подзаголовок" {...input} meta={meta} maxValue={120} />
                      )}
                    </Field>
                  </Styled.FormControl>
                </Styled.FormControls>
              </Styled.FormRow>
              {lng === LANGUAGES.RU && (
                <>
                  <Styled.FormRow>
                    <Styled.FormDescription>
                      Изображение на обложке
                      <Styled.FormSmallDescription>
                        Рекомендуемый размер 1920х960px (16:9) и не более 5MB
                      </Styled.FormSmallDescription>
                    </Styled.FormDescription>
                    <Styled.FormControls>
                      <Styled.FormControl>
                        <Field name="main_page_banner_image">
                          {({ input, meta }) => (
                            <FileCropperInput {...input} meta={meta} imageSizes={LANDINGS_IMAGE_SIZES} />
                          )}
                        </Field>
                      </Styled.FormControl>
                      <Styled.FormControl>
                        <Field name="ui_config_placement">
                          {({ input, meta }) => <ImagePositionSelect input={input} meta={meta} />}
                        </Field>
                      </Styled.FormControl>
                    </Styled.FormControls>
                  </Styled.FormRow>
                  <Styled.FormRow>
                    <Styled.FormDescription>Затемнение обложки</Styled.FormDescription>
                    <Styled.FormControls>
                      <Styled.FormControl>
                        <Field name="ui_config_banner_blackout">
                          {({ input, meta }) => (
                            <NumberInput
                              label="Затемнение, %"
                              type="number"
                              {...input}
                              value={input.value}
                              min={0}
                              max={100}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </Styled.FormControl>
                    </Styled.FormControls>
                  </Styled.FormRow>
                  <Styled.FormRow>
                    <Styled.FormDescription>Размытие изображения обложки</Styled.FormDescription>
                    <Styled.FormControls>
                      <Styled.FormControl>
                        <Field name="ui_config_banner_blur">
                          {({ input, meta }) => (
                            <NumberInput
                              label="Размытие, %"
                              type="number"
                              {...input}
                              value={input.value}
                              min={0}
                              max={100}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </Styled.FormControl>
                    </Styled.FormControls>
                  </Styled.FormRow>
                  <Styled.FormRow>
                    <Styled.FormDescription>Календарь</Styled.FormDescription>
                    <Styled.FormControls>
                      <Styled.FormControl>
                        <HeroStepStyled.Calendar>
                          <Field name="ui_config_show_calendar" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Календарь на обложке
                              </Checkbox>
                            )}
                          </Field>
                          <Styled.FormSmallDescription>
                            Отображение календаря на обложке сайта
                          </Styled.FormSmallDescription>
                        </HeroStepStyled.Calendar>
                      </Styled.FormControl>
                    </Styled.FormControls>
                  </Styled.FormRow>
                </>
              )}
              <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
            </form>
          );
        }}
      />
    </Styled.Content>
  </Styled.Container>
);

export default HeroStep;
