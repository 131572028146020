import React from 'react';
import map from 'lodash/map';
import Styled from './styles';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalExpansionDelete: React.FC<IHistoryItem> = ({ data: { expansions }, user }) => (
  <Styled.Info>
    <Styled.InfoTitle>
      {`${expansions.length === 1 ? 'Расширение удалено' : 'Расширения удалены'}`}
    </Styled.InfoTitle>
    <AnnouncementHistoryModalName data={user} />
    <Styled.InfoText>{map(expansions, (item) => item.name).join(', ')}</Styled.InfoText>
  </Styled.Info>
);

export default AnnouncementHistoryModalExpansionDelete;
