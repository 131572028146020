import { actionTypes } from './actionTypes';
import { reportsSources } from './sources';

const loadOpex = () => ({
  type: actionTypes.LOAD_OPEX,
  payload: {
    request: {
      url: reportsSources.opex,
    },
  },
});

const editOpex = (id: number | string, data: any) => ({
  type: actionTypes.LOAD_OPEX,
  payload: {
    request: {
      method: 'PUT',
      url: reportsSources.opexDetail(id),
      data,
    },
  },
});

export { loadOpex, editOpex };
