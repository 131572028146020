import styled from 'styled-components';

interface RowProps {
  width?: string;
  height?: string;
  noWrap?: boolean;
  align?: string;
  justify?: string;
  mb?: number;
  mt?: number;
  ml?: number;
  mr?: number;
  m?: string;
  pl?: number;
  pr?: number;
}

interface ColProps {
  flexBasis?: string | number;
  flexGrow?: string | number;
  flexShrink?: string | number;
  noWrap?: boolean;
  mb?: number;
  mt?: number;
  ml?: number;
  mr?: number;
  m?: string;
  pl?: number;
  pr?: number;
  p?: string;
  alignSelf?: string;
  width?: string;
  align?: string;
  justify?: string;
  maxHeight?: string;
  maxWidth?: string;
  overflow?: string;
}

interface TextProps {
  color?: string;
  underline?: boolean;
  textTransform?: string;
  size?: string;
  weight?: string;
  breakWord?: boolean;
}

export default {
  Row: styled.div<RowProps>`
    display: flex;
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : null)};
    flex-wrap: ${(props) => (props.noWrap ? 'nowrap' : 'wrap')};
    align-items: ${(props) => (props.align ? props.align : 'flex-start')};
    justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};

    /* margin */
    margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : null)};
    margin-top: ${(props) => (props.mt ? `${props.mt}px` : null)};
    margin-left: ${(props) => (props.ml ? `${props.ml}px` : null)};
    margin-right: ${(props) => (props.mr ? `${props.mr}px` : null)};
    margin: ${(props) => (props.m ? props.m : null)};

    /* padding */
    padding-left: ${(props) => (props.pl ? `${props.pl}px` : null)};
    padding-right: ${(props) => (props.pr ? `${props.pr}px` : null)};

    /* remove later */
    min-width: 1%;
  `,

  Col: styled.div<ColProps>`
    flex-basis: ${(props) => (props.flexBasis ? props.flexBasis : '50%')};
    flex-grow: ${(props) => (props.flexGrow !== undefined ? props.flexGrow : 1)};
    flex-shrink: ${(props) => (props.flexShrink ? props.flexShrink : 1)};
    flex-wrap: ${(props) => (props.noWrap ? 'nowrap' : 'wrap')};

    /* margin */
    margin-top: ${(props) => (props.mt ? `${props.mt}px` : null)};
    margin-left: ${(props) => (props.ml ? `${props.ml}px` : null)};
    margin-right: ${(props) => (props.mr ? `${props.mr}px` : null)};
    margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : null)};
    margin: ${(props) => (props.m ? props.m : null)};

    /* padding */
    padding-left: ${(props) => (props.pl ? `${props.pl}px` : null)};
    padding-right: ${(props) => (props.pr ? `${props.pr}px` : null)};

    padding: ${(props) => (props.p ? props.p : null)};
    width: ${(props) => (props.width ? props.width : null)};
    align-self: ${(props) => (props.alignSelf ? props.alignSelf : null)};
    box-sizing: border-box;
    align-items: ${(props) => (props.align ? props.align : 'flex-start')};
    justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};

    max-height: ${(props) => props.maxHeight && props.maxHeight};
    max-width: ${(props) => props.maxWidth && props.maxWidth};
    overflow: ${(props) => props.overflow && props.overflow};
  `,

  Text: styled.span<TextProps>`
    color: ${(props) => (props.color ? props.color : null)};
    text-transform: ${(props) => (props.textTransform ? props.textTransform : null)};
    text-decoration: ${(props) => (props.underline ? 'underline' : null)};
    font-size: ${(props) => (props.size ? props.size : null)};
    font-weight: ${(props) => (props.weight ? props.weight : null)};
    word-break: ${(props) => (props.breakWord ? 'break-word' : null)};
  `,
};
