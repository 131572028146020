import styled from 'styled-components';
import { scrollbar } from '../../app/styles/scrollbar';

interface RowProps {
  isOpen?: boolean;
}

interface CellProps {
  width?: string;
  justifyContent?: string;
  fontSize?: string;
}

export default {
  Table: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 32px -24px 0 -24px;
  `,

  Head: styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    height: 32px;
    box-sizing: border-box;
    border-bottom: 1px solid #e3e1e0;
    padding: 0 24px;
    z-index: 1;

    &:after {
      position: absolute;
      bottom: -26px;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 25px;
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }
  `,

  Body: styled.div`
    max-height: 460px;
    margin-top: 40px;
    margin-bottom: -48px;
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    box-sizing: border-box;
    overflow-y: auto;
    padding-bottom: 140px;

    ${scrollbar};
  `,

  Row: styled.div<RowProps>`
    position: relative;
    min-height: 56px;
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #e3e1e0;
    box-shadow: ${({ isOpen }) => isOpen && '0px 4px 8px rgba(0, 0, 0, 0.08)'};
    transition: all 0.2s ease-in-out;

    margin: ${({ isOpen }) => isOpen && '0 -24px'};
    padding: ${({ isOpen }) => isOpen && '0 24px'};

    &:after {
      content: '';
      position: absolute;
      margin: 0 auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: ${({ isOpen }) => (isOpen ? 'calc(100% - 48px)' : 0)};
      height: 1px;
      display: block;
      background-color: #ffda00;
      transition: all 0.2s ease-in-out;
    }
  `,

  Cell: styled.div<CellProps>`
    width: ${(props) => props.width};
    display: flex;
    align-items: center;
    box-sizing: border-box;

    & > svg {
      margin-left: 8px;
    }

    & > span > svg {
      margin-left: 8px;
      vertical-align: middle;
    }

    /* justifyContent */
    justify-content: ${({ justifyContent }) => justifyContent};

    /* fontSize */
    font-size: ${({ fontSize }) => fontSize};
  `,

  HeadText: styled.div`
    font-size: 12px;
    text-transform: uppercase;
    color: #999;
    font-weight: bold;
  `,
};
