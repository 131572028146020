import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);
const route = `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events`;

export default {
  root: `${route}?language_code=ru`,
  detail: (id) => `${route}/${id}`,
  qnaList: (id, lang = 'ru') => `${config.LANDING_API_URL}/api/v2/events/${id}/qna-list?language=${lang}`,
  qnaPatch: (id) => `${config.LANDING_API_URL}/api/v2/events/${id}/qna-patch`,

  getEvent: async (id) => {
    const response = await api.get(`${route}/${id}`);

    return response.data;
  },

  createEvent: async ({ data, attachments }: { data: any; attachments?: any }) => {
    const response = await api.post(route, {
      event_id: data.event.value,
      is_top: data.is_top,
      is_cover: data.is_cover,
      allow_indexing: data.allow_indexing,
      ...(attachments ? { attachments } : {}),
      company_id: companyId,
    });

    return response.data;
  },
  updateEvent: async ({ data, attachments }: { data: any; attachments?: any }) => {
    const response = await api.patch(`${route}/${data.id}`, {
      ...(attachments ? { attachments } : {}),
      attachments,
      allow_indexing: data.allow_indexing,
      is_top: data.is_top,
      is_cover: data.is_cover,
      ...(data.widget_id && { widget_id: data.widget_id.value }),
    });

    return response.data;
  },

  async createTranslation(id, data) {
    const response = await api.post(`${route}/${id}/info`, {
      title: data.title,
      affiche_title: data.affiche_title,
      hint: data.hint,
      annotation: data.annotation,
      description: data.description,
      language_code: data.language_code,
    });

    return response.data;
  },

  async createTranslationToCopy(id, data) {
    const promises = data.map((eventInfo) => {
      return api.post(`${route}/${id}/info`, {
        title: eventInfo.title,
        affiche_title: eventInfo.affiche_title,
        hint: eventInfo.hint,
        annotation: eventInfo.annotation,
        description: eventInfo.description,
        language_code: eventInfo.language_code,
      });
    });

    return Promise.all(promises);
  },

  async updateTranslation(id, lang, data) {
    const response = await api.patch(`${route}/${id}/info/${lang}`, {
      title: data.title,
      affiche_title: data.affiche_title,
      hint: data.hint,
      annotation: data.annotation,
      description: data.description,
    });

    return response.data;
  },

  async createContentGroups(id: number, contentGroups: number[]) {
    const promises = contentGroups.map((contentGroupId) => {
      return api.post(`${route}/${id}/content_groups`, {
        content_group_id: contentGroupId,
      });
    });

    return Promise.all(promises);
  },
  async createQnA(id: number, qnaforUpdate: number[]) {
    const promises = qnaforUpdate.map((qnaforUpdateId) => {
      return api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${id}/qna`, {
        qna_id: qnaforUpdateId,
      });
    });

    return Promise.all(promises);
  },

  async deleteQnA(id: number, qnaforDelete: number[]) {
    const promises = qnaforDelete.map((qnaforDeleteId) => {
      return api.delete(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${id}/qna/${qnaforDeleteId}`,
      );
    });

    return Promise.all(promises);
  },

  async deleteContentGroups(id: number, contentGroups: number[]) {
    const promises = contentGroups.map((contentGroupId) => {
      return api.delete(`${route}/${id}/content_groups/${contentGroupId}`);
    });

    return Promise.all(promises);
  },

  async create(personID: number) {
    const promises = api.post(`${route}?language_code=ru`, {
      person_id: personID,
      slug: '',
      company_id: companyId,
    });

    return promises;
  },
};
