import React, { Component } from 'react';
import { connect } from 'react-redux';
import head from 'lodash/head';
import split from 'lodash/split';
import convertToCamelCase, { POSTFIXS } from 'shared/helpers/convertToCamelCase';
import TABLES from 'shared/constants/TABLES';
import { unSelectAllRows } from 'entities/tables';
import TABLES_ROW_ACTIONS from 'shared/constants/TABLES_ROW_ACTIONS';
import GlassTicketsModalDropdown from '../GlassTicketsModalDropdown/GlassTicketsModalDropdown';
import { ACTIONS } from 'shared/constants/GLASS_TICKETS_MODAL';

interface Props {
  setRowsAction: (ids: string[], action: string, callback: () => void) => void;
  unSelectAllRows: ({ name, rowAction }: { name: string; rowAction: string }) => any;
  tablesSelectedRows: any;
  announcementsWithDuplicatesIds: any;
}

class GlassTicketsModalRowsAction extends Component<Props> {
  name: string;

  constructor(props) {
    super(props);

    this.name = convertToCamelCase({
      str: TABLES.GLASS_TICKETS_MODAL,
      postfix: POSTFIXS.TABLE_SELECTED_ROWS,
    });
  }

  componentWillUnmount() {
    const { name } = this;

    this.props.unSelectAllRows({ name, rowAction: TABLES_ROW_ACTIONS.SELECT });
  }

  render() {
    const { name } = this;
    const { tablesSelectedRows, setRowsAction, announcementsWithDuplicatesIds } = this.props;

    const { ids } = tablesSelectedRows[name];

    const selectAction = (action) => {
      setRowsAction(ids, action, () => {
        this.props.unSelectAllRows({ name, rowAction: TABLES_ROW_ACTIONS.SELECT });
      });
    };

    if (ids.length <= 0) return null;

    const duplicate = ids.find((id) => {
      const announcementId = head(split(id, '_'));

      return announcementsWithDuplicatesIds.has(Number(announcementId));
    });

    return (
      <GlassTicketsModalDropdown
        onClick={selectAction}
        action={ACTIONS.UNSET}
        withDuplicates={Boolean(duplicate)}
      />
    );
  }
}

const mapDispatchToProps = { unSelectAllRows };

const mapStateToProps = (state) => ({
  tablesSelectedRows: state.tables.selectedRows,
});

export default connect(mapStateToProps, mapDispatchToProps)(GlassTicketsModalRowsAction);
