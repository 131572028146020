import React from 'react';
import Button from '../../../Button';
import Icon from '../../../Icon';
import ICONS from 'shared/constants/ICONS';

interface ElementProps {
  className?: string;
  data?: any;
  handleOnClick: (e: any, d: any) => void;
}

const RemoveElement: React.FC<ElementProps> = ({ className, handleOnClick, data }) => (
  <Button
    transparent
    dangerHoverable
    iconOnly
    className={className}
    onClick={(e, d) => handleOnClick(e, d)}
    opacity={0.4}
    data={data}
  >
    <Icon src={ICONS.TRASH} alt="Удалить" />
  </Button>
);

export default RemoveElement;
