import React from 'react';

interface OptionProps {
  value?: string | number;
  children: any;
  disabled?: boolean;
}

const Option = ({ value = '', children, disabled = false }: OptionProps) => (
  <option value={value} disabled={disabled}>
    {children}
  </option>
);

export default Option;
