import React, { useEffect, useState } from 'react';
import CompanyInfoForm from './CompanyInfoForm';
import Button from '../../Button';
import Spinner from '../../Spinner';
import { ModalFunctions } from '../../../../interfaces/modal';

const initialState = {
  data: {},
  isLoading: true,
};

interface ModalProps {
  data?: any;
  closeModal: ModalFunctions['closeModal'];
  loadMemberCompany: (id: string | number) => any;
}

const EditMemberModal: React.FC<ModalProps> = ({ data, loadMemberCompany, closeModal }) => {
  const [state, setState] = useState<any>(initialState);

  useEffect(() => {
    const { id } = data;

    loadMemberCompany(id).then((response) => {
      setState({
        isLoading: false,
        data: response.payload.data,
      });
    });
  }, []);

  const { data: user, isLoading } = state;

  if (isLoading) return <Spinner center />;

  return (
    <CompanyInfoForm
      data={user}
      title="О компании"
      initialValues={{
        ...user,
        address: user.address,
      }}
    >
      <Button type="button" transparent onClick={closeModal}>
        Закрыть
      </Button>
    </CompanyInfoForm>
  );
};

export default EditMemberModal;
