import EventsPlacesFilters from 'components/EventsPlacesFilters';
import React from 'react';
import { isPasteDate } from 'shared/helpers/events';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import { LandingEvent } from '../../../types/Event';

interface LandingsEventPlacesProps {
  event: LandingEvent;
  goNext: () => void;
  goBack: () => void;
}

const LandingsEventPlaces: React.FC<LandingsEventPlacesProps> = (props) => {
  const { event, goBack, goNext } = props;

  const dates = event.event.dates
    ?.map((date) => {
      return {
        value: date.id,
        label: formatFullDateAndTime(date.started_at),
        outdated: isPasteDate(date.finished_at),
      };
    })
    .filter((d) => !d.outdated);

  return <EventsPlacesFilters eventSourceId={event.id} eventDates={dates} goBack={goBack} goNext={goNext} />;
};

export default LandingsEventPlaces;
