import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';

interface Props {
  values: any;
  save: (values: any) => void;
}

class ConstructorAutoUpdate extends Component<Props> {
  promise: any;

  componentDidUpdate() {
    this.save().catch((err) => new Error(err));
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { values, save } = this.props;

    this.promise = save(values);
    await this.promise;
    delete this.promise;
  };

  render() {
    return null;
  }
}

export default (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={ConstructorAutoUpdate} />
);
