import styled from 'styled-components';

interface Props {
  width?: string;
}

const TextOverflow = styled.div<Props>`
  max-width: ${(props) => props.width || '100%'};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default TextOverflow;
