import qs from 'query-string';
import { sortBy } from 'lodash';
import config from 'config/config';
import api from 'shared/services/api';
import { getCookie } from 'shared/lib/cookie';
import COOKIES from 'shared/constants/COOKIES';

const companyId = getCookie(COOKIES.COMPANY);
const HEADERS = {
  headers: {
    'x-company-id': companyId,
  },
};

const pricesSources = {
  async getEventPrice({ eventSourceId, eventDateId }) {
    const query = qs.stringify({
      event_source_id: eventSourceId,
      event_date_id: eventDateId,
    });

    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/${companyId}/prices?${query}`, HEADERS);
    const data = response.data.results[0];

    const priceRules = await api
      .get(`${config.COMMON_API_URL}/admin/v1/${companyId}/prices/${data.id}/rules`, HEADERS)
      .then((rulesResponse) => rulesResponse.data.map((markup) => ({ ...markup, item_id: markup.id })));

    return {
      ...data,
      markup: priceRules.map((markup) => ({ id: markup.id, query: markup })),
    };
  },

  async update({ priceId, data }) {
    const response = await api.patch(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/prices/${priceId}`,
      {
        price_id: priceId,
        filters: data.filters,
        use_currency: data.use_currency,
        currency_code: data.currency_code,
        round_enum: data.round_enum,
        display_type: data.display_type,
        is_table: data.is_table,
        use_announcements: data.use_announcements,
        leftover_threshold: data.leftover_threshold,
        event_source_id: +data.event_source_id,
        event_date_id: data.event_date_id,
      },
      HEADERS,
    );

    return response;
  },

  async updatePriceRule({ id, markup }) {
    if (typeof markup.id === 'number') {
      const response = await api.patch(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/prices/${id}/rules/${markup.id}`,
        markup.query,
        HEADERS,
      );

      return response;
    }

    const response = await api.post(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/prices/${id}/rules`,
      markup.query,
      HEADERS,
    );

    return response;
  },

  async deletePriceRule({ id, ruleId }) {
    const response = await api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/prices/${id}/rules/${ruleId}`,
      HEADERS,
    );

    return response;
  },

  async getEventPriceFilters({ eventSourceId, eventDateId }) {
    const query = qs.stringify({
      event_source_id: eventSourceId,
      event_date_id: eventDateId,
    });

    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/${companyId}/prices?${query}`, HEADERS);
    const data = response.data.results[0];

    return {
      ...data,
    };
  },

  async copyRules({ id, markup }) {
    const response = api.post(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/prices/${id}/rules`,
      markup.query,
      HEADERS,
    );

    return response;
  },

  async getRules({ data }) {
    const priceRules = await api
      .get(`${config.COMMON_API_URL}/admin/v1/${companyId}/prices/${data.id}/rules`, HEADERS)
      .then((response) => response.data.map((markup) => ({ ...markup, item_id: markup.id })));

    return { markup: priceRules.map((markup) => ({ id: markup.id, query: markup })) };
  },

  async getEventPrices(eventSourceId) {
    const response = await api.get(
      `${config.COMMON_API_URL}/admin/v1/events/${eventSourceId}/prices`,
      HEADERS,
    );

    return sortBy(response.data.results, (price) => !price.is_default);
  },

  async getEventPriceDates(eventSourceId, priceId) {
    const response = await api.get(
      `${config.COMMON_API_URL}/admin/v1/events/${eventSourceId}/prices/${priceId}/dates`,
    );

    return response.data.results;
  },

  async bindPricesToEvent(eventSourceId, priceId, data) {
    const response = await api.post(
      `${config.COMMON_API_URL}/admin/v1/events/${eventSourceId}/prices/${priceId}/dates`,
      data,
      HEADERS,
    );

    return response.data;
  },

  async setDefaultPrice(eventSourceId, priceId) {
    const response = await api.post(
      `${config.COMMON_API_URL}/admin/v1/events/${eventSourceId}/default_prices/${priceId}`,
    );

    return response.data;
  },
};

export default pricesSources;
