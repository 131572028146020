import styled, { css } from 'styled-components';
import { layoutScrollbar } from '../../../app/styles/scrollbar';
import { StyledLinkCSS } from '../../Link/styles';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  isOpen?: boolean;
  mobileFixed?: boolean;
}

export default {
  Container: styled.ul<CSSProps>`
    max-height: 328px;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    flex-shrink: 0;
    overflow-y: auto;
    background-color: #fff;
    ${layoutScrollbar}

    & > li:not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
    }

    ${({ mobileFixed }) =>
      mobileFixed &&
      css`
        @media (max-width: ${BREAKPOINTS.MD}) {
          position: absolute;
          z-index: 10;
          top: 70px;
          left: 0;
          width: 100%;
          box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
          border-radius: 0px 0px 8px 8px;
        }
      `}
  `,

  ModalClose: styled.div<CSSProps>`
    display: none;

    ${({ mobileFixed, isOpen }) =>
      mobileFixed &&
      css`
        @media (max-width: ${BREAKPOINTS.MD}) {
          position: absolute;
          display: ${isOpen ? 'block' : 'none'};
          z-index: 9;
          top: 70px;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.24);
        }
      `}
  `,

  Item: styled.li`
    padding: 8px 16px 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
  `,

  Info: styled.div`
    box-sizing: border-box;
    width: calc(100% - 88px);
    padding-right: 24px;
  `,

  Link: styled.a`
    display: inline-block;
    margin-right: 8px;
    ${StyledLinkCSS}
    font-size: 12px;
  `,

  Rows: styled.div`
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 8px;
    }
  `,

  Row: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  `,

  Manager: styled.div`
    font-size: 12px;
    color: #585858;
    max-width: 250px;
  `,

  Status: styled.div`
    margin-left: auto;
    min-width: 72px;
    display: flex;
    justify-content: flex-end;

    & > div {
      min-width: 72px;
      height: 16px;
      margin: 0;
      font-size: 10px;
      border-radius: 4px;
    }
  `,

  NotAllowed: styled.div`
    min-width: 70px;
    font-size: 12px;
  `,
};
