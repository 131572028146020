import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface SeatsProps {
  readOnly?: boolean;
  isOpen?: boolean;
}

export default {
  Seats: styled.button<SeatsProps>`
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    border: 0;
    background: none;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    outline: none;
    padding-right: 30px;
    flex-wrap: wrap;
    justify-content: center;

    /* readOnly */
    padding-right: ${({ readOnly }) => readOnly && 0};
    cursor: ${({ readOnly }) => readOnly && 'text'};

    &:after {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto 0;
      content: '';
      display: block;
      height: 6px;
      width: 12px;
      background-repeat: no-repeat;
      background-image: url('/static/TableSeats/arrow-${(props) => (props.isOpen ? 'yellow' : 'black')}.svg');

      /* readOnly */
      display: ${({ readOnly }) => readOnly && 'none'};
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding-right: 0;

      &:after {
        display: none;
      }
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      justify-content: flex-start;
      width: 100%;
      font-size: 14px;
      text-align: left;
    }
  `,

  Comma: styled.span`
    cursor: default;
    padding-right: 5px;
  `,

  AllBooked: styled.div`
    color: #5c5c5c;
  `,
};
