import styled, { css } from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';

interface ContainerProps {
  meta: {
    active?: boolean;
  };
}

export default {
  Container: styled.div<ContainerProps>`
    position: relative;
    width: 100%;
    display: flex;
    min-height: 40px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    background-color: #fff;

    ${getBorderOnError};

    ${({ meta }) =>
      meta.active &&
      css`
        border-color: #2f80ed !important;
        border-width: 2px;
      `}
  `,

  Tags: styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 4px 12px 0;

    & > div:not(:last-child) {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  `,

  Tag: styled.div`
    min-height: 20px;
    padding: 0 8px;
    max-width: 90%;
    background-color: #f5f5f5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 12px;
    box-sizing: border-box;
    word-break: break-all;
  `,

  TagRemove: styled.div`
    margin-left: 8px;
    cursor: pointer;
    opacity: 0.24;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      fill: #eb5757;
    }

    & svg {
      width: 10px;
      height: 10px;
    }
  `,

  Input: styled.input`
    padding-left: 12px;
    border: 0;
    outline: none;
    border-radius: 8px;
  `,

  Label: styled.div`
    & > div {
      :after {
        height: 8px;
      }
    }
  `,
};
