import { connect } from 'react-redux';
import { setDiscount, deleteDiscount, loadSale } from 'entities/order';
import OrderDiscountModal from 'components/Modals/OrderDiscountModal';

const mapDispatchToProps = {
  loadSale,
  setDiscount,
  deleteDiscount,
};

export default connect(null, mapDispatchToProps)(OrderDiscountModal);
