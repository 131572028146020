import React from 'react';
import get from 'lodash/get';
import SalesIcon from '../../../static/Sidebar/Sell.svg';
import OrderIcon from '../../../static/icons/orders.svg';
import PurchasesIcon from '../../../static/Sidebar/Buy.svg';
import routes from 'shared/helpers/routes';
import { formatEventDateTime, formatDateFromNow } from 'shared/helpers/formatters/date';
import { groupTickets } from 'shared/helpers/tickets';
import TableUserInfo from '../TableUserInfo';
import GroupedTickets from './GroupedTickets';
import SearchList from './SearchList';
import SearchStyled from './styles';
import OrderStatus from '../OrderStatus/OrderStatus';
import { IUser } from '../../entities/account/types';

interface OrdersProps {
  account: IUser;
  data: any[];
  meta: any;
  search: string;
  sidebarIsOpen: boolean;
  saveHistory: () => void;
  hideMenu: () => void;
}

const Orders: React.FC<OrdersProps> = ({
  data,
  meta,
  account,
  sidebarIsOpen,
  search,
  saveHistory,
  hideMenu,
}) => {
  if (!data.length) return null;

  return (
    <SearchList
      length={data.length}
      meta={meta}
      title="Заказы"
      href={routes.searchOrders}
      search={search}
      saveHistory={saveHistory}
      hideMenu={hideMenu}
      icon={<OrderIcon />}
    >
      {data.map((order) => {
        const isSale = order.seller.id === account.company.id;
        let dateStart;
        let timeStart;

        const groupedTicketsBySector = order.tickets ? groupTickets(order.tickets).groupedTickets : [];
        const customerId = get(order.customer, 'id');
        const isBTB = customerId && customerId !== account.company.id;

        if (order.event.date_start && order.event.time_start) {
          const dates = formatEventDateTime(order.event.date_start, order.event.time_start);
          dateStart = dates.date;
          timeStart = dates.time;
        }

        const hasDateAndPlace = Boolean(dateStart && timeStart && order.event.place);

        return (
          <SearchStyled.Result key={order.id}>
            <SearchStyled.Link
              href={isSale ? `${routes.sales}?id=${order.id}` : `${routes.purchases}?id=${order.id}`}
              onClick={saveHistory}
            >
              <SearchStyled.Row>
                <SearchStyled.Column>
                  <SearchStyled.Icon>{isSale ? <SalesIcon /> : <PurchasesIcon />}</SearchStyled.Icon>
                  <SearchStyled.Id>
                    {`#${order.id}`}
                    {order.external_id && (
                      <SearchStyled.ExternalId>{`#${order.external_id}`}</SearchStyled.ExternalId>
                    )}
                  </SearchStyled.Id>
                  <SearchStyled.Event>
                    <SearchStyled.Name small sidebarIsOpen={sidebarIsOpen}>
                      {order.event.title}
                    </SearchStyled.Name>
                    <SearchStyled.Date small sidebarIsOpen={sidebarIsOpen}>
                      {dateStart && timeStart && `${dateStart}, ${timeStart}`}
                      {hasDateAndPlace && ' | '}
                      {order.event.place && `${order.event.place.address}, ${order.event.place.name}`}
                    </SearchStyled.Date>
                  </SearchStyled.Event>
                </SearchStyled.Column>
                <SearchStyled.Column>
                  <SearchStyled.Tickets>
                    {/*
                    // @ts-ignore */}
                    <GroupedTickets
                      groupedTickets={groupedTicketsBySector}
                      withoutTickets={!order.tickets}
                      sidebarIsOpen={sidebarIsOpen}
                    />
                  </SearchStyled.Tickets>
                  <SearchStyled.OrderStatusWrapper>
                    <OrderStatus order={order} />
                  </SearchStyled.OrderStatusWrapper>
                  {(order.user_customer || order.customer) && (
                    <SearchStyled.Customer>
                      <TableUserInfo
                        data={order.user_customer}
                        fallback={order.customer}
                        withoutTooltip
                        withoutStatus
                        withSimpleTooltip
                        maxWidth={110}
                        isBTB={isBTB}
                      />
                      <SearchStyled.PurchasedDate>
                        {formatDateFromNow(order.created)}
                      </SearchStyled.PurchasedDate>
                    </SearchStyled.Customer>
                  )}
                </SearchStyled.Column>
              </SearchStyled.Row>
            </SearchStyled.Link>
          </SearchStyled.Result>
        );
      })}
    </SearchList>
  );
};

export default Orders;
