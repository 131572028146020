import styled from 'styled-components';
import Modal from 'components/Modal';

export default {
  Title: styled(Modal.Title)`
    margin-bottom: 0;
  `,

  SubTitle: styled.div`
    margin-bottom: 10px;
    color: #585858;
    font-size: 16px;
  `,

  AvailableTicketsList: styled.div`
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  `,

  CompanyName: styled.div`
    margin-bottom: 3px;
    font-size: 16px;
  `,

  Buttons: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  `,
};
