import styled, { css } from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';

interface ContainerProps {
  isSmall?: boolean;
  meta: any;
  withIcon?: boolean;
  disabled?: boolean;
}

export default {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
  `,

  Container: styled.div<ContainerProps>`
    position: relative;
    width: 100%;

    input:disabled {
      cursor: not-allowed;
      background-color: #fff;
      color: #999;
    }

    input:disabled ~ b {
      cursor: not-allowed;

      &:hover {
        background: transparent;
        border-color: #e1e1e1;
      }
    }

    & > span {
      display: flex;

      & > b:after {
        font-size: 14px;
        text-align: center;
        color: #000;
      }
    }

    & > input {
      border-style: solid;
      border-radius: 8px;
      border-width: 1px;
      border-color: #e1e1e1;
      height: ${({ isSmall }) => (isSmall ? '32px' : '40px')};
      padding: 1px 11px;
      box-sizing: border-box;
      width: 100%;
      outline: none;
      ${getBorderOnError}
      transition: all 0.2s ease-in-out;

      &:hover:not(:read-only) {
        border-color: #5c5c5c;
      }

      &:focus:not(:read-only) {
        border-color: #2f80ed !important;
        border-width: 2px;
        padding: 0 10px;
      }

      &::placeholder {
        color: #999;
        transition: all 0.2s ease-in-out;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
      }

      &:focus::placeholder {
        opacity: 0;
      }
    }

    ${(props) =>
      props.withIcon &&
      css`
        & > input {
          padding-right: 32px;
        }
      `}
  `,

  Icon: styled.div`
    position: absolute;
    right: 8px;
    top: 50%;
    width: 16px;
    height: 16px;
    opacity: 0.4;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    transform: translateY(-50%);
  `,
};
