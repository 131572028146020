import React, { useEffect, useState } from 'react';
import { Router, useRouter, withRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import Modal from '../../../Modal';
import WidgetForm from '../WidgetForm';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import DataPreview from '../../../DataPreview';
import api from 'shared/services/api';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import { eventsSources } from 'entities/events';
import { getPeriodicalChildrenData } from 'shared/helpers/events';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { loadWidget } from 'entities/widget';
import { updateQuery } from 'shared/helpers/routes';
import { WidgetDisplayTypes } from 'shared/constants/WIDGET';

const STEPS_WORDS = ['Мероприятие', 'Места', 'Наценка'];

interface ModalProps {
  data?: any;
  router: Router;
  modalState?: string;
  isMobile?: boolean;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadWidget: (id: string | number, params?: Object, lng?: string) => any;
}

const WidgetModal: React.FC<ModalProps> = (props) => {
  const {
    data,
    router: {
      query: { isPreview, modalId },
    },
  } = props;
  const { query } = useRouter();
  const isEdit = Boolean(props.modalState === MODAL_STATES.EDIT || modalId);
  const isCloning = props.modalState === MODAL_STATES.CLONE;

  const [state, setState] = useState({
    data: {
      ru: {
        display_type: WidgetDisplayTypes.SEATS,
        leftover_threshold: 0,
        ...props.data,
      },
    },
    lng: 'ru',
    isChangingData: false,
    step: 0,
    stepsCount: STEPS_WORDS.length,
    isEdit,
    isLoading: isEdit || isCloning,
    isPreview: isPreview === 'true',
    isCloning,
  });

  const loadData = (lng: string = 'ru') => {
    props
      .loadWidget(state.data?.ru?.id || props.data.id || modalId, { outdated: true }, lng)
      .then(async (response) => {
        const results = response.payload;

        let dates = {};
        if (results.data.event?.is_periodical) {
          const event = await api.get(eventsSources.detail(results.data.event.id), {
            params: { outdated: true },
          });

          dates = getPeriodicalChildrenData(event.data);
        }

        setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            [lng]: {
              ...results.data,
              id: results.data.item_id || prevState.data.ru.id || props.data.id,
              ...dates,
              ...(results.data.event && {
                event_id: {
                  value: results.data.event.id,
                  label: results.data.event.title,
                },
              }),
            },
          },
          lng,
          isLoading: false,
          isChangingData: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          isChangingData: false,
        }));
      });
  };

  useEffect(() => {
    if (props.data.id || modalId) {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isChangingData: false,
      }));

      loadData();
    }
  }, [modalId, state.step]);

  const updateState = (newState) => {
    if (query.modalId) {
      updateQuery({ modalId: newState.data.ru.id });
    }

    setState((prevState) => ({
      ...prevState,
      ...newState,
      data: {
        ...prevState.data,
        ...newState.data,
      },
    }));
  };

  const updateData = (newData) => {
    const { step, stepsCount } = state;
    const done = step === stepsCount - 1 || data.submit_action === SUBMIT_ACTIONS.CREATE;
    const updatedData = newData && newData.lng ? newData[newData.lng] : newData;

    if (done) {
      props.forceCloseModal();
      NotificationManager.success('Успешно');

      return setTimeout(() => {
        window.location.reload();
      }, 0);
    }

    return setState((prevState) => ({
      ...prevState,
      step: prevState.step + 1,
      data: {
        ...prevState.data,
        [prevState.lng]: {
          ...prevState.data[prevState.lng],
          ...updatedData,
        },
      },
      lng: 'ru',
    }));
  };

  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  const selectStep = (selectedStep) => {
    setState((prevState) => ({
      ...prevState,
      step: selectedStep,
    }));
  };

  const onChangeLng = (lng: string) => {
    if (state.isEdit) {
      setState((prevState) => ({ ...prevState, isChangingData: true }));
      loadData(lng);
    }
  };

  return (
    <DataPreview state={{ isLoading: state.isLoading }} isEmpty={isEdit && isEmpty(state.data)} centered>
      <Modal.FullSizeContainer>
        <WidgetForm
          data={state.data[state.lng]}
          isEdit={state.isEdit}
          closeModal={props.forceCloseModal}
          isCloning={isCloning}
          isMobile={props.isMobile}
          isPreview={state.isPreview}
          updateState={updateState}
          onSubmit={updateData}
          step={state.step}
          goBack={goBack}
          selectStep={selectStep}
          stepWords={STEPS_WORDS}
          onChangeLng={onChangeLng}
          isChangingData={state.isChangingData}
        />
      </Modal.FullSizeContainer>
    </DataPreview>
  );
};

WidgetModal.defaultProps = {
  data: {},
};

const mapDispatchToProps = {
  loadWidget,
};

export default withCheckIsMobile(withRouter(connect(null, mapDispatchToProps)(WidgetModal)));
