import React, { useState } from 'react';
import Form from '../../../Form';
import Tabs from '../../../Tabs';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { getSelectedTab } from 'shared/helpers/constructor';

const TABS = [
  {
    text: 'Без изображения',
    key: 1,
  },
  {
    text: 'С изображениями',
    key: 2,
  },
];

const WITHOUT_IMAGES = [1, 2, 3, 11, 23, 24, 25, 27];
const WITH_IMAGES = [4, 5, 6, 7, 8, 9, 12, 10, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 26];

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const NewsStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => {
  const [tab, setTab] = useState(getSelectedTab(data.ui_config_news, WITHOUT_IMAGES));

  return (
    <Styled.Container withTabs>
      <Styled.Header withTabs>
        <Styled.Title>Выберите дизайн Новостей</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Tabs tabs={TABS} onSelect={setTab} selectedTab={tab}>
          <Form
            onSubmit={onSubmit}
            initialValues={data}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Styled.ListWrapper>
                  <Styled.List grid>
                    {tab === 1 &&
                      WITHOUT_IMAGES.map((image, index) => (
                        <Styled.Item key={image} grid width="50%" data-selenium={`variant-${index + 1}`}>
                          <Styled.BorderedItem>
                            <Variant name="ui_config_news" value={image}>
                              <img src={`/static/constructor/news/${image}.jpg`} alt={`${image}`} />
                            </Variant>
                          </Styled.BorderedItem>
                        </Styled.Item>
                      ))}
                    {tab === 2 &&
                      WITH_IMAGES.map((image, index) => (
                        <Styled.Item key={image} grid width="50%" data-selenium={`variant-${index + 1}`}>
                          <Styled.BorderedItem>
                            <Variant name="ui_config_news" value={image}>
                              <img src={`/static/constructor/news/${image}.jpg`} alt={`${image}`} />
                            </Variant>
                          </Styled.BorderedItem>
                        </Styled.Item>
                      ))}
                  </Styled.List>
                </Styled.ListWrapper>
                <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
              </form>
            )}
          />
        </Tabs>
      </Styled.Content>
    </Styled.Container>
  );
};

export default NewsStep;
