import Button from 'components/Button';
import Form from 'components/Form';
import {
  Select,
  Input,
  FormDescription,
  SmallFormDescription,
  InputWithSelect,
} from 'components/FormControls';
import Modal from 'components/Modal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { companySources } from 'entities/company';
import { addPayment, editPayment, loadCompanyPayments } from 'entities/company/payments';
import PAYMENTS, {
  PAYMENTS_OPTIONS,
  PAYMENT_PROVIDERS_OPTIONS,
  PAYMENT_PROVIDERS,
  PAYMENT_AREA_OPTIONS,
} from 'shared/constants/PAYMENTS';
import { renderAtol } from 'shared/helpers/optionsRenderers';
import { required } from 'shared/helpers/validations';
import { queryFilter } from 'shared/lib/queryFilter';
import { ModalFunctions } from '../../../../../../../interfaces/modal';
import Styled from './styled';

interface Props {
  addPayment: (data:object) => void;
  editPayment: (id: number | string, data: object) => void;
  closeModal: ModalFunctions['closeModal'];
  company: {id:number};
  data: {atol_config:{
    id:number;
    company_name:string;
  }};
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadCompanyPayments: () => void;
}

const PaymentMethodModal: React.FC<Props> = (props) => {
  let isEdit = false;
  const onSubmit = (data:{atol_id:{value:string}, id:number}) => {
    const { id, ...rest } = data;

    const callback = (response:{error:string}) => {
      if (response.error) {
        return response;
      }

      props.loadCompanyPayments();

      queryFilter({ search: '' });

      return response;
    };

    if (isEmpty(props.data)) {
      return props
        .addPayment({
          ...data,
          ...(get(data, 'atol_id.value') && {
            atol_id: data.atol_id.value,
          }),
        })
        .then(callback);
    }

    return props.editPayment(id, {
      ...rest,
      ...(get(data, 'atol_id.value') && {
        atol_id: data.atol_id.value,
      }),
    });
  };

  let initialValues = {
    company: props.company.id,
    payment_type: 1,
    area: 1,
    acquiring_type: PAYMENT_PROVIDERS.SBERBANK,
  };

  if (!isEmpty(props.data)) {
    initialValues = {
      ...initialValues,
      ...props.data,
      ...(props.data.atol_config && {
        atol_id: {
          value: props.data.atol_config.id,
          label: props.data.atol_config.company_name,
        },
      }),
    };
    isEdit = true;
  }

  return (
    <>
      <Styled.GlobalStyles />
      <Modal.Title>{isEdit ? 'Редактировать метод оплаты' : 'Добавить метод оплаты'}</Modal.Title>
      <Form
        onSubmit={onSubmit}
        onSuccess={props.forceCloseModal}
        initialValues={{
          ...initialValues,
          payment_type: PAYMENTS.ACQUIRING,
        }}
        render={({ handleSubmit, values }:{handleSubmit:()=>void; values:{payment_type:string}}) => (
          <form onSubmit={handleSubmit}>
            <Styled.Sections>
              <Styled.Section>
                <FormDescription width={128}>
                  Метод
                  <SmallFormDescription>Основная информация</SmallFormDescription>
                </FormDescription>
                <Styled.Rows>
                  <Field type="hidden" name="id" component="input" />
                  <Field type="hidden" name="company" component="input" />
                  <Styled.Row>
                    <Field name="name" validate={required}>
                      {({ input, meta }) => <Input label="Название" {...input} meta={meta} />}
                    </Field>
                  </Styled.Row>
                  <Styled.Row>
                    <Field name="payment_type" validate={required}>
                      {({ input, meta }) => (
                        <Select options={PAYMENTS_OPTIONS} label="Тип оплаты" {...input} meta={meta} />
                      )}
                    </Field>
                  </Styled.Row>
                  <Styled.Row>
                    <Field name="acquiring_type" validate={required}>
                      {({ input, meta }) => (
                        <Select
                          options={PAYMENT_PROVIDERS_OPTIONS}
                          label="Эквайринг"
                          {...input}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </Styled.Row>
                  <Styled.Row>
                    <Field name="atol_id">
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          label="ОФД"
                          route={companySources}
                          routeName="atolConfig"
                          optionsRenderer={renderAtol}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                  </Styled.Row>
                  <Styled.Row>
                    <Field name="area" validate={required}>
                      {({ input, meta }) => (
                        <Select options={PAYMENT_AREA_OPTIONS} label="Зона оплаты" {...input} meta={meta} />
                      )}
                    </Field>
                  </Styled.Row>
                  {parseInt(values.payment_type, 10) === PAYMENTS.CARD && (
                    <>
                      <Styled.Row>
                        <Field name="data.card" validate={required}>
                          {({ input, meta }) => <Input label="Номер карты" {...input} meta={meta} />}
                        </Field>
                      </Styled.Row>

                      <Styled.Row>
                        <Field name="data.fio" validate={required}>
                          {({ input, meta }) => <Input label="ФИО" {...input} meta={meta} />}
                        </Field>
                      </Styled.Row>
                    </>
                  )}
                </Styled.Rows>
              </Styled.Section>
              {parseInt(values.payment_type, 10) === PAYMENTS.ACQUIRING && (
                <Styled.Section>
                  <FormDescription width={128}>
                    Логин и пароль
                    <SmallFormDescription>Настройки подключения</SmallFormDescription>
                  </FormDescription>
                  <Styled.Rows>
                    <Styled.Row>
                      <Field name="login" validate={required}>
                        {({ input, meta }) => <Input label="Логин" {...input} meta={meta} />}
                      </Field>
                    </Styled.Row>
                    <Styled.Row>
                      <Field name="password" validate={required}>
                        {({ input, meta }) => <Input label="Пароль" type="password" {...input} meta={meta} />}
                      </Field>
                    </Styled.Row>
                  </Styled.Rows>
                </Styled.Section>
              )}
            </Styled.Sections>

            <Modal.Footer>
              <Button type="button" transparent onClick={props.closeModal}>
                Отмена
              </Button>
              <Button type="submit">{isEdit ? 'Сохранить' : 'Добавить'}</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  company: state.account.data.company,
});

const mapDispatchToProps = {
  addPayment,
  editPayment,
  loadCompanyPayments,
};

export const CompanyAddPaymentMethodModal = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodModal);
