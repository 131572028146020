import Button from 'components/Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { withRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import LOCALES from 'shared/constants/LOCALES';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import { FILE_TYPES_REGEX } from 'shared/constants/REGEX';
import { editTranslationData } from 'shared/helpers/form';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import landingsNewsSources from 'shared/sources/landings/landingsNews';
import { ModalFunctions } from '../../../../../interfaces/modal';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import { selectAccount } from '../../../../entities/account/reducer';
import { withTranslation } from '../../../../i18n';
import withLandingData from '../withLandingData';
import ArticleForm from './ArticleForm';

dayjs.extend(utc);
interface ModalProps {
  isMobile?: boolean;
  closeModal: ModalFunctions['closeModal'];
  data: any;
  modalState?: string;
  showDeleteModal: () => void;
  lng: string;
  t: (value: string, params?: { [key: string]: any }) => string;
  onChangeLng: any;
  updateList: (data: any) => void;
  onUpdate: (data: any) => void;
  create: (data: any) => void;
  update: (data: any) => void;
}

const ArticleModal: React.FC<ModalProps> = (props) => {
  const account = useSelector(selectAccount);
  const {
    data,
    onChangeLng,
    updateList,
    showDeleteModal,
    modalState,
    isMobile,
    onUpdate,
    t,
    create,
    update,
    lng,
  } = props;

  if (modalState === MODAL_STATES.CREATE) {
    return (
      <ArticleForm
        key="create"
        title="Новая новость"
        onSubmit={create}
        onSuccess={updateList}
        closeModal={props.closeModal}
        onChangeLng={onChangeLng}
        t={t}
        initialValues={{
          item_create: true,
          company_id: account.company.id,
        }}
      />
    );
  }

  const initialValues = {
    company_id: account.company.id,
    ...omit(data, ['published', 'company', 'date_create', 'date_edit', 'date_publish']),
    date_news: dayjs.utc(data.date_news),
    ...(data.content_groups && {
      content_groups: data.content_groups.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    }),
    extra: data.extra && JSON.stringify(data.extra),
  };

  const handleUpdate = (formData) => {
    const dataForCompare = formData[lng];

    if (isEqual(dataForCompare, initialValues)) {
      return {};
    }

    return update(formData);
  };

  return (
    <ArticleForm
      key="edit"
      isEdit
      title="Редактировать новость"
      onSubmit={handleUpdate}
      onSuccess={onUpdate}
      onChangeLng={onChangeLng}
      t={t}
      initialValues={initialValues}
      handleDelete={showDeleteModal}
    >
      <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
        {isMobile ? <TrashIcon /> : t('forms:delete')}
      </Button>
      <Button type="button" transparent onClick={props.closeModal}>
        {t('forms:cancel')}
      </Button>
      <Button type="submit">{t('forms:save')}</Button>
    </ArticleForm>
  );
};

const mappedSubmitData = (item) => ({
  ...item,
  ...(item.gallery && {
    gallery_webp: item.gallery.map((img) => img.replace(FILE_TYPES_REGEX, 'webp')),
  }),
  date_news: dayjs(item.date_news).format(DATE_FORMATS.DATE_TIME),
  extra: item.extra && omit(JSON.parse(item.extra), ['date_news']),
  ...(item.content_groups && {
    content_groups: item.content_groups.map((group) => group.value),
  }),
});

export default withCheckIsMobile(
  // @ts-ignore
  withLandingData(withRouter(withTranslation([LOCALES.FORMS, LOCALES.LANDINGS])(ArticleModal)), {
    translation: 'article',
    sources: landingsNewsSources,
    mappedCreateData: (submitData) =>
      editTranslationData(omit(submitData, 'action'), (item) => mappedSubmitData(item)),
    mappedUpdateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
  }),
);
