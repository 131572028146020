export enum AttachmentType {
  Cover = 'cover',
  Preview = 'preview',
  Gallery = 'gallery',
  Gallery_Web = 'gallery_webp',
  Main_Image = 'main_image',
  Schemas = 'schemas',
}

export const AttachmentItem = {
  main_image: { id: 4 },
  schemas_webp: { id: 6 },
};

export const AttachmentTypes = {
  gallery: { code: 'gallery', id: 2, title: 'Галерея' },
  banner: { code: 'main_page_banner', id: 3, title: 'Банер' },
  background_pattern: { code: 'background_pattern', id: 1, title: 'Фон' },
};
