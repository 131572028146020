import get from 'lodash/get';
import { i18n } from 'next-i18next';
import React from 'react';
import ArrowIcon from '../../../../static/icons/arrow.svg';
import Styled from './styles';

interface Props {
  isFocused: boolean;
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  innerProps: any;
  data: any;
}

const OptionWithEventCategory: React.FC<Props> = (props) => {
  const { children, innerProps, isFocused, data } = props;
  const categoryType = get(data, 'data.category_type');
  const parentCategory = get(data, 'data.parent');
  const categoryTypeInfo = categoryType && categoryType.info?.find((i) => i.language_code === i18n?.language);
  const parentCategoryInfo =
    parentCategory && parentCategory.info?.find((i) => i.language_code === i18n?.language);

  return (
    <Styled.Option {...innerProps} isFocused={isFocused}>
      <Styled.EventCategoryInfo>
        {children}
        {(categoryType || parentCategory) && (
          <Styled.EventCategoryInfoPath>
            {categoryTypeInfo && (
              <div>
                <ArrowIcon />
                {categoryTypeInfo?.title}
              </div>
            )}
            {parentCategoryInfo && (
              <div>
                <ArrowIcon />
                {parentCategoryInfo.title}
              </div>
            )}
          </Styled.EventCategoryInfoPath>
        )}
      </Styled.EventCategoryInfo>
    </Styled.Option>
  );
};

export default OptionWithEventCategory;
