import { createEffect, createEvent, createStore, sample } from 'effector';
import { NotificationManager } from 'react-notifications';
import { FormSubmitData, createPlaceFx, fetchPlacesFx } from 'shared/api/reference/places';
import { createToggler } from 'shared/lib/toggler';
import { $query } from '../../../model';

const formSubmitted = createEvent<FormSubmitData>();
const toggler = createToggler();

const $loading = createStore(false);

export const placeCreateModal = { formSubmitted, toggler, $loading };

const successNotificationFx = createEffect(() => {
  NotificationManager.success('Успешно');
});

const errorNotificationFx = createEffect((message: string | null = null) => {
  NotificationManager.error(message, 'Ошибка');
});

sample({
  clock: formSubmitted,
  fn: (data) => {
    return {
      data,
      title: data.title,
      type_id: data.type_id.value,
      location_id: data.location_id.value,
    };
  },
  target: createPlaceFx,
});

sample({
  clock: formSubmitted,
  fn: () => true,
  target: $loading,
});

sample({
  source: {
    query: $query,
  },
  clock: createPlaceFx.done,
  fn: ({ query }) => query,
  target: [fetchPlacesFx, successNotificationFx, toggler.close],
});

sample({ clock: [createPlaceFx.done, toggler.close], fn: () => false, target: $loading });

sample({
  clock: createPlaceFx.fail,
  fn: ({ error }) => {
    return error.response?.data?.detail?.includes('already exist')
      ? 'Площадка с таким именем уже существует'
      : null;
  },
  target: errorNotificationFx,
});

sample({
  clock: createPlaceFx.fail,
  fn: () => false,
  target: $loading,
});
