import Button from 'components/Button';
import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Column: styled.div`
    position: relative;
    flex-grow: 1;
  `,

  Field: styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    margin-bottom: 32px;

    @media screen and (max-width: ${BREAKPOINTS.MD}) {
      margin: 0 0 32px;
    }
  `,

  GenerateSlugButton: styled(Button)`
    margin: 0 0 32px auto;
  `,

  Error: styled.div`
    margin-top: 3px;
    font-size: 12px;
    transition: all 0.2s ease-in-out;
    color: #eb5757;
  `,
};
