import styled, { css } from 'styled-components';
import { getColorOnError } from 'shared/helpers/input';

interface ContainerProps {
  withIcon?: boolean;
  active?: boolean;
}

interface LabelProps {
  isFocused?: boolean;
  disabled?: boolean;
  meta?: any;
}

export default {
  Container: styled.div<ContainerProps>`
    z-index: 1;
    position: absolute;
    height: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: ${(props) => (props.withIcon ? 40 : 12)}px;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    padding: ${({ active }) => active && '0 4px'};

    &:after {
      content: '';
      top: 50%;
      position: absolute;
      width: ${({ active }) => (active ? '100%' : '0')};
      display: block;
      margin-top: 1px;
      height: 4px;
      background-color: #fff;
      transition: all 0.2s ease-in-out;
      transform: translateY(-50%);
    }

    ${({ active }) =>
      active &&
      css`
        top: -20px;
        left: 8px;
        font-size: 13px;
      `}
  `,

  Label: styled.div<LabelProps>`
    z-index: 2;
    position: relative;
    color: #5c5c5c;
    transition: color 0.2s ease-in-out;
    ${getColorOnError}

    color: ${({ isFocused }) => isFocused && '#2F80ED'};
    color: ${({ disabled }) => disabled && '#999'};
  `,
};
