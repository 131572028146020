import { useUnit } from 'effector-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { $deleteErrors } from 'pages/reference/countries/ui/update-modal/model';
import { $deleteLocationErrors } from 'pages/reference/locations/ui/update-modal';
import { openModal } from 'entities/modal';
import LOCALES from 'shared/constants/LOCALES';
import MODALS from 'shared/constants/MODALS';
import preventClick from 'shared/helpers/preventClick';
import { ModalFunctions } from '../../../../interfaces/modal';
import { useTranslation } from '../../../i18n';
import Button from '../../Button';
import Form from '../../Form';
import Styled from './styles';

interface IConfirmModalProps {
  data?: {
    onSubmit?: (data?: any) => void;
    title?: string;
    text?: string;
    onReject?: () => void;
    withoutNotification?: boolean;
  };
  closeModal?: ModalFunctions['closeModal'];
  closePopover?: () => void;
  padding?: string;
  zIndex?: number;
  withPopover?: boolean;
  t?: (value: string, options?: { [key: string]: any }) => string;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  const { title, text, onSubmit, onReject, withoutNotification } = props.data || {
    onSubmit: null,
    onReject: null,
    title: 'Отменить действие?',
    text: 'Вся набранная вами информация удалится. Вы уверены, что хотите отменить действие?',
    withoutNotification: false,
  };
  const { padding = null, withPopover = false, closeModal, closePopover, zIndex } = props;
  const dispatch = useDispatch();
  const [deleteErrors, locationDeleteErrors] = useUnit([$deleteErrors, $deleteLocationErrors]);
  const t = props.t || useTranslation([LOCALES.FORMS]).t;

  const deleteError = deleteErrors || locationDeleteErrors;

  useEffect(() => {
    if (deleteError) {
      dispatch(
        openModal(MODALS.REFERENCE_ERRORS_MODAL, {
          errors: deleteError.errors,
          name: deleteError.name,
          page: deleteError.page,
        }),
      );
    }
  }, [deleteError]);

  const modal = (
    <Styled.Container padding={padding}>
      <Styled.Top>
        <Styled.Question />
        {title}
      </Styled.Top>
      <Styled.Text>{text}</Styled.Text>

      {!onSubmit ? (
        <Styled.Buttons>
          <Button ghost onClick={closePopover} data-selenium="confirm_modal_no">
            {t('no')}
          </Button>
          <Button danger onClick={closeModal} data-selenium="confirm_modal_yes">
            {t('yes')}
          </Button>
        </Styled.Buttons>
      ) : (
        <Form
          t={t}
          onSubmit={onSubmit}
          onSuccess={closeModal}
          withoutNotification={withoutNotification}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Styled.Buttons>
                <Button type="button" ghost onClick={onReject || closeModal}>
                  {t('no')}
                </Button>
                <Button danger type="submit">
                  {t('yes')}
                </Button>
              </Styled.Buttons>
            </form>
          )}
        />
      )}
    </Styled.Container>
  );

  return withPopover ? (
    <Styled.Popover zIndex={zIndex} onClick={preventClick} onMouseDown={preventClick}>
      <Styled.PopoverGlobalStyles />
      {modal}
    </Styled.Popover>
  ) : (
    <>{modal}</>
  );
};

export default ConfirmModal;
