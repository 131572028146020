import React from 'react';
import uuid from 'uuid/v4';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Form from '../../../../Form';
import Styled from '../styles';
import PageConstructorFooter from '../PageConstructorFooter';
import SectionItem from './SectionItem';
import {
  PAGE_CONSTRUCTOR_OPTIONS,
  PAGE_CONSTRUCTOR_NAMES,
} from 'shared/constants/PAGE_CONSTRUCTOR_BLOCKS';
import Dropdown from '../../../../Dropdown';
import api from 'shared/services/api';
import landingsCustom from 'shared/sources/landings/landingsCustom';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  steps: string[];
  setSteps: (data: any) => void;
  data: any;
  step: number;
  onUpdateSteps: any;
  pageId: number;
}

const InitialStep: React.FC<StepProps> = ({
  onSubmit,
  updateData,
  data,
  setSteps,
  step,
  onUpdateSteps,
  steps,
  pageId,
}) => {
  const handleRequestData = (values) =>
    onSubmit({
      blocks: data.map((item, idx) => ({
        ...item,
        ordering: idx,
      })),
      submit_action: values.submit_action,
    });

  const handleUpdateData = () =>
    updateData(
      data.map((item, idx) => ({
        ...item,
        ordering: idx,
      })),
    );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(data, result.source.index, result.destination.index);

    onUpdateSteps();
    setSteps(items);
  };

  const handleAddStep = (blockName) => {
    setSteps([
      ...data,
      {
        block_type: blockName,
        ordering: data.length,
        block_name: PAGE_CONSTRUCTOR_NAMES[blockName],
        title: PAGE_CONSTRUCTOR_NAMES[blockName],
        is_publish: true,
        uuid: uuid(),
      },
    ]);
    onUpdateSteps();
  };

  const handleDeleteStep = async (_, value) => {
    const method = value.id ? api.delete(landingsCustom.blocksDetail(pageId, value.id)) : Promise.resolve();
    await method.then(() => {
      setSteps(data.filter((item) => item.uuid !== value.uuid));
      onUpdateSteps();
    });
  };

  const handleUpdateStep = (block) => {
    const updatedBlocks = data.map((item) => {
      if (item.uuid === block.uuid) {
        return block;
      }

      return item;
    });

    setSteps(updatedBlocks);
  };

  const initialOptions = () => {
    const arr = [];

    data.map((item) => {
      if (item.block_type === 'GALLERY' || item.block_type === 'EVENTS' || item.block_type === 'QNA') {
        arr.push(item.block_type);
      }
      return item;
    });

    return PAGE_CONSTRUCTOR_OPTIONS.filter((item) => !arr.includes(item.value));
  };

  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка блоков</Styled.Title>
      </Styled.Header>

      <Styled.Content>
        <Form
          onSubmit={handleRequestData}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Styled.FormRow>
                <Styled.FormDescription>
                  Блоки на странице сайта
                  <Styled.FormSmallDescription>
                    Добавление, показ и порядок блоков
                  </Styled.FormSmallDescription>
                </Styled.FormDescription>
                <Styled.FormRow column>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <Styled.Sections ref={provided.innerRef} {...provided.droppableProps}>
                          {data.map(
                            (section, index) =>
                              section.block_type !== 'initial' && (
                                <SectionItem
                                  section={section}
                                  key={section.uuid}
                                  index={index}
                                  deleteSection={handleDeleteStep}
                                  updateSection={handleUpdateStep}
                                />
                              ),
                          )}
                          {provided.placeholder}
                        </Styled.Sections>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Styled.DropdownContainer>
                    <Dropdown options={initialOptions()} onClick={handleAddStep}>
                      Добавить блок
                    </Dropdown>
                  </Styled.DropdownContainer>
                </Styled.FormRow>
              </Styled.FormRow>
              <PageConstructorFooter
                form={form}
                updateData={handleUpdateData}
                withoutNextAction={steps.length - 1 === step}
              />
            </form>
          )}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default InitialStep;
