import { createEvent, createStore, sample } from 'effector';

export const closeModalClicked = createEvent();
export const closeConfirmClicked = createEvent();

export const $confirmClose = createStore(false);

sample({
  clock: closeModalClicked,
  fn: () => true,
  target: $confirmClose,
});

sample({
  clock: closeConfirmClicked,
  fn: () => false,
  target: $confirmClose,
});
