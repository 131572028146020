export enum MarkupTypes {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
  CURRENCY = 'CURRENCY',
}

export enum PriceTypes {
  MARKUP = 'MARKUP',
  SERVICE_FEE = 'SERVICE_FEE',
}

export const priceTypesOptions = [
  { name: PriceTypes.MARKUP, label: 'Наценка' },
  { name: PriceTypes.SERVICE_FEE, label: 'Сервисный сбор' }
];