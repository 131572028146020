import styled, { css } from 'styled-components';

interface MessageProps {
  centered?: boolean;
}

export default {
  Message: styled.span<MessageProps>`
    font-size: 18px;
    text-align: center;
    color: #585858;
    margin-top: auto;
    margin-bottom: auto;

    ${({ centered }) =>
      centered &&
      css`
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
      `}
  `,
};
