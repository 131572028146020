import React from 'react';
import styled from 'styled-components';
import Spinner from '../Spinner';

interface StatePreviewProps {
  state: {
    isLoading?: boolean;
    isFail?: boolean;
  };
  error?: string;
}

const Container = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
`;

const Message = styled.div`
  font-size: 18px;
  text-align: center;
  color: #585858;
`;

const StatePreview: React.FC<StatePreviewProps> = ({
  state: { isLoading, isFail },
  error = 'Что-то пошло не так',
}) =>
  isLoading || isFail ? (
    <Container>
      {isLoading && <Spinner />}
      {isFail && <Message>{error}</Message>}
    </Container>
  ) : null;

export default StatePreview;
