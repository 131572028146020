import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  InputWrapper: styled.div`
    margin: 10px 0;
    max-width: 600px;
    padding-right: 5px;
  `,

  Body: styled.div`
    box-sizing: border-box;
    position: fixed;
    padding: 32px 40px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    .modal__footer button:first-child {
      margin-right: auto;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 32px 40px;
      border-radius: 8px 8px 0 0;
    }
  `,

  Title: styled.div`
    margin-bottom: 10px;
    font-weight: 600;
  `,

  ButtonWrapper: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  SourceCodeButton: styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  `,

  SaveButton: styled.div`
    margin-left: 10px;
  `,

  FileInputWrapper: styled.div`
    margin-bottom: 10px;
  `,
};
