import React from 'react';
import merge from 'lodash/merge';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import updateObject from 'lodash/update';
import { omit } from 'lodash';
import api from 'shared/services/api';
import { getTranslationRoute } from './routes';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

const checkForInitialValues = (initialValues, newValues, value = '') => {
  // library issue via https://github.com/final-form/react-final-form/issues/130#issuecomment-493447888
  const emptiedData = Object.keys(initialValues).reduce((acc, key) => {
    if (isObject(newValues[key] && key)) {
      acc[key] = checkForInitialValues(initialValues[key], newValues[key]);
    } else {
      acc[key] = typeof newValues[key] === 'undefined' ? value : newValues[key];
    }
    return acc;
  }, {});

  // need to deep merge to get new child properties
  return merge(emptiedData, newValues);
};

const createError = (errors) => {
  if (!Array.isArray(get(errors, 'response.data.error'))) {
    updateObject(errors, 'response.data.error', (error) => ({
      ru: error,
    }));

    return {
      error: errors,
    };
  }

  return { error: errors };
};

const saveTranslationData = async ({ data, create, update, query = {} }: any) => {
  const savedData: any = {};
  const languages = Object.keys(omit(data, 'lng'));

  if (data.ru && !data.ru.id) {
    return api
      .post(create, data.ru, {
        params: query,
      })
      .then((response) => {
        savedData.ru = response.data;

        languages.map((language) => {
          if (!isEmpty(data[language]) && language !== LANGUAGES.RU) {
            const isNewCreation = data.ru.item_create;
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const method = isNewCreation ? api.post : api.patch;

            return method(
              getTranslationRoute({
                route: update(response.data.id),
                lng: language,
                create: isNewCreation,
              }),
              {
                id: response.data.id,
                ...data[language],
                ...(isNewCreation && {
                  item: response.data.id,
                  lang: language,
                }),
              },
              {
                params: query,
                headers: {
                  'Accept-Language': language,
                },
              },
            ).then((languageResponse) => {
              savedData[language] = languageResponse.data;

              return savedData;
            });
          }

          return savedData;
        });

        return savedData;
      })
      .catch(createError);
  }

  return Promise.all(
    languages.map((language) => {
      if (!isEmpty(data[language]) && data[language] && data[language].id) {
        const isCreate = data[language].create;
        const isNewCreation = data[language].item_create;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const method = isCreate || isNewCreation ? api.post : api.patch;

        return method(
          getTranslationRoute({
            route: isNewCreation ? create : update(data[language].id),
            lng: language,
            create: isCreate,
          }),
          {
            ...data[language],
            ...(isCreate && {
              item: data[language].id,
              lang: language,
            }),
          },
          {
            params: query,
            headers: {
              'Accept-Language': language,
            },
          },
        );
      }

      return null;
    }),
  )
    .then((results) => {
      results.forEach((response, index) => {
        if (response) {
          savedData[languages[index]] = response.data;
        }
      });

      return savedData;
    })
    .catch(createError);
};

const editTranslationData = (data, callback) => {
  const languages = Object.keys(omit(data, 'lng'));

  return languages.reduce((acc, language) => {
    if (data[language]) {
      acc[language] = callback(data[language]);
    }

    return acc;
  }, {});
};

const createErrorMessage = (error) => {
  const errors = get(error, 'response.data.error');

  if (isPlainObject(errors)) {
    return reduce(
      error.response.data.error,
      (acc, message, name) => {
        acc.push(
          <div>
            {name} - {message.join('')}
          </div>,
        );
        return acc;
      },
      [],
    );
  }

  return '';
};

export { checkForInitialValues, saveTranslationData, createErrorMessage, editTranslationData };
