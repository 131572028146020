import urlParse from 'url-parse';
import replace from 'lodash/replace';

const stripUrl = (url: string) => {
  if (!url) return '';

  const parsedUrl = urlParse(url);

  parsedUrl.set('protocol', '');
  parsedUrl.set('query', '');
  parsedUrl.set('hash', '');
  // @ts-ignore
  parsedUrl.set('slashes', '');
  parsedUrl.set('pathname', '');

  let stripedUrl = parsedUrl.toString();

  if (stripedUrl.indexOf('www.') !== -1) {
    stripedUrl = stripedUrl.slice(4);
  }

  return stripedUrl;
};

const trimProtocol = (url: string) => replace(url, /https:\/\/|http:\/\//, '');

const landingUrl = (techName: string) => {
  return `https://${techName}.${process.env.LANDING_DOMAIN}`;
};

export { stripUrl, trimProtocol, landingUrl };
