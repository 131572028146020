import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  InputWrapper: styled.div`
    margin: 10px 0;
    max-width: 600px;
    padding-right: 5px;
  `,

  Body: styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 688px;
    height: 100%;
    padding: 32px 40px;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    .modal__footer button:first-child {
      margin-right: auto;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 32px 40px;
      border-radius: 8px 8px 0 0;
    }
  `,

  Title: styled.div`
    margin-bottom: 10px;
    font-weight: 600;
  `,

  ButtonWrapper: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  FileInputWrapper: styled.div`
    margin-bottom: 10px;
  `,
};
