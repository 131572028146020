import styled, { css } from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';

interface SelectProps {
  small?: boolean;
  fluid?: boolean;
  withEmpty?: boolean;
  meta?: any;
}

export default {
  Container: styled.div`
    width: 100%;
    position: relative;
  `,

  Select: styled.select<SelectProps>`
    width: 100%;
    height: ${(props) => (props.small ? '32px' : '40px')};
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding-left: ${({ small }) => (small ? '12px' : '16px')};
    padding-right: 32px;
    appearance: none;
    text-indent: 1px;
    text-overflow: ellipsis;
    background-repeat: no-repeat;
    background-image: url('/static/Select/arrow.svg');
    background-position: ${({ small }) => (small ? 'right 12px center' : 'right 16px center')};
    font-size: ${({ small }) => (small ? '14px' : '16px')};
    outline: none;
    transition: 0.2s ease-in-out;

    &:disabled {
      pointer-events: none;
    }

    &:hover:not(:disabled) {
      border-color: #000;
    }

    ${getBorderOnError};

    ${({ withEmpty }) =>
      !withEmpty &&
      css`
        & > option:first-child {
          color: #5c5c5c;
        }
      `}
  `,
};
