import React from 'react';
import Styled from './styles';
import CloseIcon from '../../../../static/icons/close.svg';

interface Props {
  removeTag: (value: any) => void;
  children: string;
}

const InputWithTagsTag: React.FC<Props> = ({ children, removeTag }) => {
  const handleClick = () => {
    removeTag(children);
  };

  return (
    <Styled.Tag>
      {children}
      <Styled.TagRemove onClick={handleClick}>
        <CloseIcon />
      </Styled.TagRemove>
    </Styled.Tag>
  );
};

export default InputWithTagsTag;
