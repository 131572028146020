import styled, { createGlobalStyle } from 'styled-components';
import { smallModalStyles, mobileModalStyles } from 'shared/mixins/modal';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}

    ${mobileModalStyles}

    @media ${MEDIA_QUERIES.MOBILE} {
      .modal__body {
        overflow: visible;
      }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
};
