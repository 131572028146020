import { createEffect } from 'effector';
import isEmpty from 'lodash/isEmpty';
import { Router } from 'next/router';
import { i18n } from 'next-i18next';
import config from 'config/config';
import api from 'services/api';
import attachmentsSource from '../../sources/attachments';
import { Language } from '../types';
import { EditEvent, IBasicEvent, EventValue, FormManager } from './types';

const route = `${config.COMMON_API_URL}/admin/v1/events`;

export const eventsSources = {
  root: route,
  detail: (id: number) => `${route}/${id}`,
  childDetail: (id: number) => `${route}?date_id_in=${id}`,
  restrictions: `${config.COMMON_API_URL}/admin/v1/restrictions`,
};

export const fetchEventsFx = createEffect(async (query: Router['query'] = {}) => {
  const { location, search, ...rest } = query;

  const response = await api.get(route, {
    params: {
      ...rest,
      ...(query && { language_code: i18n?.language }),
      ...(location && { location_id_in: location }),
      search_string: search,
    },
  });

  return response.data;
});

export const fetchEventFx = createEffect(async (id: number | null): Promise<IBasicEvent> => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const getEventLabelsFx = createEffect(async () => {
  const response = await api.get(`${config.COMMON_API_URL}/admin/v1/labels`);

  return response.data;
});

export const editPersonFx = createEffect(
  async ({ id, persons, manager_id }: { id: number; persons: number[]; manager_id: number }) => {
    const response = await api.patch(`${route}/${id}`, {
      persons,
      manager_id,
    });

    return response.data;
  },
);

export const fetchUserFx = createEffect(async (id: FormManager) => {
  const response = await api.get(`${config.API_URL}auth/internal/users`, { params: { id__in: id } });

  return response.data;
});

export const createEventAttachmentsFx = createEffect(
  async ({
    id,
    attachments,
  }: {
    id: number;
    attachments: {
      attachment_type_id: number;
      data: { [key: string]: string };
      language_code: Language;
      info: { is_create: boolean; title?: string; alt?: string };
    }[];
  }) => {
    const requests = attachments.map(async (attachment) => {
      const response = api.post(`${route}/${id}/attachments`, attachment);

      return response;
    });

    await Promise.all(requests);
    await Promise.all(
      attachments
        .filter((attachment) => !isEmpty(attachment.info))
        .map(async (attachment) => {
          const infoResponse = await api.post(
            `${route}/${id}/attachments/${attachment.attachment_type_id}/info`,
            {
              ...attachment.info,
              language_code: attachment.language_code,
            },
          );

          return infoResponse;
        }),
    );
  },
);

export const getAttachmentsTypesFx = createEffect(async () => {
  const response = await attachmentsSource.getAttachmentsTypes('events');

  return response;
});

export const updateEventAttachmentsFx = createEffect(
  async ({
    id,
    attachments,
  }: {
    id: number;
    attachments: {
      attachment_type_id: number;
      data: { [key: string]: string };
      language_code: Language;
      info: { is_create: boolean; title?: string; alt?: string };
    }[];
  }) => {
    const requests = attachments.map(async (attachment) => {
      const { attachment_type_id: attachmentId, ...rest } = attachment;
      const response = api.patch(`${route}/${id}/attachments/${attachmentId}`, rest);

      return response;
    });

    await Promise.all(requests);
    await Promise.all(
      attachments
        .filter((attachment) => !isEmpty(attachment.info))
        .map(async (attachment) => {
          if (attachment.info.is_create) {
            const response = await api.post(
              `${route}/${id}/attachments/${attachment.attachment_type_id}/info`,
              {
                ...attachment.info,
                language_code: attachment.language_code,
              },
            );

            return response;
          }

          const response = await api.patch(
            `${route}/${id}/attachments/${attachment.attachment_type_id}/info/${attachment.language_code}`,
            attachment.info,
          );

          return response;
        }),
    );
  },
);

export const fetchDateStatus = createEffect(async () => {
  const response = api.get(`${config.COMMON_API_URL}/admin/v1/date_statuses`);

  return response;
});

export const editEventFx = createEffect(async ({ id, data }: { id: number; data: EditEvent }) => {
  const response = await api.patch(`${route}/${id}`, data);

  return response.data;
});

export const createEventFx = createEffect(
  async (data: { title: string; category_id: number; restriction_id: number; manager_id: number }) => {
    const response = await api.post(route, data);

    return response.data;
  },
);

export const editHallSchemeFx = createEffect(
  async ({
    id,
    hall_layout_id,
    hall_id,
  }: {
    id: number;
    hall_layout_id: number | null;
    hall_id: number | null;
  }) => {
    const response = await api.patch(`${route}/${id}`, {
      hall_layout_id,
      hall_id,
    });

    return response.data;
  },
);

export const editPlaceFx = createEffect(
  async ({ id, place_id }: { id: number; place_id: number; formValue: EventValue }) => {
    const response = await api.patch(`${route}/${id}`, { place_id });

    return response.data;
  },
);
