import Button from 'components/Button';
import ModalFooter from 'components/ModalFooter';
import Spinner from 'components/Spinner';
import { useUnit } from 'effector-react';
import { i18n, useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import ReferenceRelated from 'widgets/reference/ReferenceRelated';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import routes from 'shared/helpers/routes';
import { findTranslation } from 'shared/helpers/translations';
import { Modal, ModalClose } from 'shared/ui/modal';
import { placeViewModal } from '../model';
import Styled from './styles';

export const PlaceViewModal = () => {
  const { t } = useTranslation();
  const { modalOpened, modalClosed } = useUnit({
    modalOpened: placeViewModal.modalOpened,
    modalClosed: placeViewModal.modalClosed,
  });
  const { place, loading, relatedHalls, relatedHallsLoading, modalId, isOpen } = useUnit({
    place: placeViewModal.$place,
    loading: placeViewModal.$loading,
    relatedHalls: placeViewModal.$relatedHalls,
    relatedHallsLoading: placeViewModal.$relatedHallsLoading,
    modalId: placeViewModal.$modalId,
    isOpen: placeViewModal.toggler.$isOpen,
  });

  useEffect(() => {
    if (isOpen && modalId) {
      modalOpened({ id: modalId, type: 'view' });
    }

    return () => {
      modalClosed();
    };
  }, [isOpen]);

  const lang = i18n?.language || LANGUAGES.RU;

  const placeTypeInfo = loading ? null : findTranslation(place!.type.info, lang);
  const buildHallModalRoute = (id: string | number) =>
    `${routes.referenceHalls}?modalId=${id}&modalType=hall-edit`;

  return (
    <Modal toggler={placeViewModal.toggler} name="place-view" id={modalId}>
      {({ forceCloseModal }) => (
        <Styled.Body>
          <ModalClose onClick={forceCloseModal} />
          {loading ? (
            <Styled.SpinnerContainer>
              <Spinner />
            </Styled.SpinnerContainer>
          ) : (
            <>
              <Styled.Title>{place?.title}</Styled.Title>
              <Styled.Row>{placeTypeInfo?.title}</Styled.Row>
              <ReferenceRelated
                isLoading={relatedHallsLoading}
                count={relatedHalls?.count}
                data={relatedHalls?.results}
                href={buildHallModalRoute}
                name="залы"
              />

              <ModalFooter>
                <Button onClick={forceCloseModal} type="button">
                  {t('forms:close')}
                </Button>
              </ModalFooter>
            </>
          )}
        </Styled.Body>
      )}
    </Modal>
  );
};
