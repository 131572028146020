import Button from 'components/Button';
import Form from 'components/Form';
import { Checkbox, Textarea, Input, PhoneInput } from 'components/FormControls';
import SplittedNumber from 'components/SplittedNumber';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import Router, { Router as RouterTypes, withRouter } from 'next/router';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { openModal } from 'entities/modal';
import { createOrder, addTicketsFromPool, removeContext, closeOrder } from 'entities/order';
import MODALS from 'shared/constants/MODALS';
import { formatFullDateAndTime } from 'shared/helpers/formatters/date';
import routes from 'shared/helpers/routes';
import { getSelectedAnnouncementsSellers } from 'shared/helpers/tickets';
import { composeValidators, required, minValue, validPhone, maxValue } from 'shared/helpers/validations';
import { ModalFunctions } from '../../../../../interfaces/modal';
import {
  ticketsPriceSelector,
  sellersSelector,
  selectedAnnouncementCurrency,
} from '../../../../selectors/announcements';
import BookTicketsModalRow from '../BookTicketsModalRow';
import Styled from './styles';

interface ModalProps {
  event?: any;
  data: any;
  callContext: any;
  sellers: any[];
  router: RouterTypes;
  orderContext?: any;
  selectedAnnouncements: any[];
  ticketsPrice?: number;
  openModal: ModalFunctions['openModal'];
  closeModal: ModalFunctions['closeModal'];
  closeOrder: () => any;
  removeContext: () => void;
  addTicketsFromPool: (id: string | number, data: any) => any;
  createOrder: (data: any) => any;
  selectedCurrency: any;
}

class BookTicketsModal extends Component<ModalProps> {
  static defaultProps = {
    event: {},
    ticketsPrice: 0,
    orderContext: {},
  };

  values: any;

  componentDidUpdate() {
    if (this.props.selectedAnnouncements.length === 0) {
      this.props.closeModal();
    }
  }

  submitOfflineOrder = () => {
    this.createOrder({
      ...this.values,
      isOffline: true,
    });
  };

  createOrder = (data) => {
    const {
      selectedAnnouncements,
      callContext,
      orderContext,
      data: { modalData, event },
      router,
    } = this.props;

    const { isOffline, comment, name, phone } = data;

    const sellers = getSelectedAnnouncementsSellers(selectedAnnouncements);

    const order: any = {
      comment,
      [orderContext ? 'event_id' : 'event_date_id']: router.query.event || event.id,
      from_exchange: true,
      booking: !isOffline,
      sellers: Object.keys(sellers).map((id) => ({
        ...sellers[id],
        comment: data[`comment_${id}`],
        id: Number(id),
      })),
    };

    if (!isEmpty(callContext)) {
      order.user_customer = callContext;
    }

    if (name && phone) {
      order.user_customer = {
        name,
        phone,
      };
    }

    if (isOffline) {
      order.offline = true;
    }

    const callback = (results) => {
      if (!results.error) {
        this.props.closeModal();

        let prevQuery = {};

        if (this.props.router.query.prevQuery) {
          prevQuery = JSON.parse(this.props.router.query.prevQuery);
        }

        if (orderContext) {
          this.props.closeOrder();
          this.props.removeContext();
          Router.push({
            pathname: routes.sales,
            query: { id: orderContext.id, ...prevQuery },
          }).catch((err) => new Error(err));
          return results;
        }

        if (modalData.onSuccess) {
          modalData.onSuccess();
        }

        Router.push(this.props.router.asPath).catch((err) => new Error(err));
      }

      if (results.error && results.error.response.data && results.error.response.data.checks) {
        this.props.openModal(MODALS.BOOKING_UPDATES_MODAL, {
          errorData: results.error.response.data,
          selectedAnnouncements,
          order,
        });
      }

      return results;
    };

    if (orderContext) {
      return this.props.addTicketsFromPool(orderContext.id, omit(order, ['user_customer'])).then(callback);
    }

    return this.props.createOrder(order).then(callback);
  };

  render() {
    const {
      selectedAnnouncements,
      ticketsPrice,
      sellers,
      callContext,
      orderContext,
      selectedCurrency,
      router,
    } = this.props;
    const { event } = this.props.data;
    const date = event?.dates?.find((date) => date.id === Number(router.query.event));
    const contextIsEmpty = isEmpty(callContext) && !orderContext;

    const groupedTicketsByCompany = groupBy(selectedAnnouncements, 'create_by.company.id');

    return (
      <>
        <Styled.GlobalStyles />
        <Styled.Title>Бронирование билетов</Styled.Title>
        <Styled.EventInfo>
          <Styled.EventTitle>{event.title}</Styled.EventTitle>
          <Styled.EventDate>
            <span>{formatFullDateAndTime(`${date?.started_at}`)}</span>
          </Styled.EventDate>
          <Styled.Place>{`${event.place.name}`}</Styled.Place>
        </Styled.EventInfo>
        <Form
          withoutNotification
          initialValues={{
            for_everyone: false,
          }}
          onSubmit={this.createOrder}
          withoutSpinner
          withNotification
          render={({ handleSubmit, values, form, submitting }) => {
            this.values = values;

            return (
              <form onSubmit={handleSubmit}>
                {map(groupedTicketsByCompany, (announcement) => (
                  <BookTicketsModalRow
                    allowMessage={!values.for_everyone}
                    announcement={announcement}
                    key={head(announcement).id}
                  />
                ))}
                <Styled.TotalContainer>
                  <Field name="for_everyone" type="checkbox">
                    {({ input, meta }) => (
                      <Styled.Row align="center" width="auto">
                        <Checkbox variant="round" meta={meta} {...input}>
                          Одно сообщение для всех
                        </Checkbox>
                      </Styled.Row>
                    )}
                  </Field>
                  <Styled.TotalPrice>
                    <Styled.Label>К оплате</Styled.Label>
                    <Styled.Value>
                      <SplittedNumber icon={selectedCurrency && selectedCurrency.icon} withSuffix>
                        {ticketsPrice}
                      </SplittedNumber>
                    </Styled.Value>
                  </Styled.TotalPrice>
                </Styled.TotalContainer>
                {values.for_everyone && (
                  <Field name="comment">
                    {({ input, meta }) => {
                      form.change(`comment_${sellers && sellers[0] && sellers[0].id}`, input.value);

                      return (
                        <Styled.MessageField>
                          <Textarea autoFocus label="Сообщение продавцам" {...input} meta={meta} />
                        </Styled.MessageField>
                      );
                    }}
                  </Field>
                )}
                {contextIsEmpty && (
                  <Styled.Context>
                    <Styled.ContextSide>
                      <Styled.ContextTitle>Заполните контекст</Styled.ContextTitle>
                      <Styled.ContextDescription>Данные покупателя</Styled.ContextDescription>
                    </Styled.ContextSide>
                    <Styled.ContextInputs>
                      <Styled.ContextInput>
                        <Field name="name" validate={composeValidators(required, minValue(3), maxValue(100))}>
                          {({ input, meta }) => <Input label="Имя" fixedError {...input} meta={meta} />}
                        </Field>
                      </Styled.ContextInput>
                      <Styled.PhoneInput>
                        <Field name="phone" validate={composeValidators(required, validPhone)}>
                          {({ input, meta }) => (
                            <PhoneInput input={input} handleForm={form} meta={meta} fixedError />
                          )}
                        </Field>
                      </Styled.PhoneInput>
                    </Styled.ContextInputs>
                  </Styled.Context>
                )}
                <Styled.Footer>
                  {contextIsEmpty && (
                    <Button disabled={submitting} gray type="button" onClick={this.submitOfflineOrder}>
                      Продолжить без контекста
                    </Button>
                  )}
                  <Button disabled={submitting} type="submit" data-selenium="reservation_modal">
                    Забронировать
                  </Button>
                </Styled.Footer>
              </form>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAnnouncements: state.selectedAnnouncements,
  selectedCurrency: selectedAnnouncementCurrency(state),
  ticketsPrice: ticketsPriceSelector(state),
  sellers: sellersSelector(state),
  callContext: state.callContext.data,
  orderContext: state.orderContext.data,
});

const mapDispatchToProps = {
  createOrder,
  openModal,
  addTicketsFromPool,
  closeOrder,
  removeContext,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookTicketsModal));
