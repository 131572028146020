import React, { Component } from 'react';
import isObject from 'lodash/isObject';
import AsyncSelect from 'react-select/async';
import { AsyncPaginate } from 'react-select-async-paginate';
import Styled from './styles';
import Label from '../../Label';
import Error from '../../Error';
import api from 'shared/services/api';
import COLORS from 'shared/constants/COLORS';

const styles = {
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0',
  }),

  option: (provided, state) => ({
    ...provided,
    minHeight: 32,
    padding: 0,
    paddingLeft: 8,
    paddingTop: 6,
    paddingBottom: 6,
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out',
    backgroundColor: state.isSelected || state.isFocused ? '#f4f4f4' : 'none',
    color: state.isSelected ? '#000' : 'none',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: 16,
    color: '#999',
  }),

  menu: (provided) => ({
    ...provided,
    margin: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
  }),

  menuList: (provided) => ({
    ...provided,
    paddingTop: 8,
    paddingBottom: 8,
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      borderRight: 'solid 2px transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 10px 10px #e1e1e1',
      borderRight: 'solid 2px transparent',
      boxSizing: 'border-box',
    },
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  loadingIndicator: () => ({
    display: 'none',
  }),
};

type InputWithAutocompleteProps = typeof InputWithAutocomplete.defaultProps & {
  label: string;
  meta: any;
  route: any;
  value: any;
  onChange: (data: any) => void;
  name: string;
  query: any;
  nameField: string;
  isClearable?: boolean;
  isMulti?: boolean;
  withoutDefaultValue?: boolean;
  isDisabled?: boolean;
  defaultOptions?: boolean;
  disablePaginate?: boolean;
  placeholder: string;
  routeName: string;
  components: any;
  cacheOptions?: boolean;
  optionsRenderer: () => any;
  outdated?: boolean;
  autoFocus?: boolean;
  fixedError?: boolean;
  noBorder?: boolean;
  noBackground?: boolean;
  dataSelenium: string;
  fluid?: boolean;
  title?: string;
};

class InputWithAutocomplete extends Component<InputWithAutocompleteProps, any> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    label: '',
    route: null,
    value: null,
    name: null,
    optionsRenderer: null,
    query: {},
    nameField: 'name',
    isClearable: true,
    isMulti: false,
    withoutDefaultValue: false,
    isDisabled: false,
    defaultOptions: true,
    placeholder: 'Начните ввод',
    routeName: 'root',
    meta: {},
    disablePaginate: false,
    components: {},
    cacheOptions: false,
    outdated: false,
    autoFocus: false,
    fixedError: false,
    noBorder: false,
    noBackground: false,
    dataSelenium: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromProps(props) {
    const { meta } = props;

    if (meta.touched && (meta.error || meta.submitError)) {
      return {
        hasError: Boolean(meta.error || meta.submitError),
      };
    }

    return {
      hasError: false,
    };
  }

  getAddressByYandex = async (inputValue) => {
    const result = await window.ymaps.suggest(inputValue);

    const options = result.map((item) => ({
      value: item.value,
      name: item.value,
    }));

    if (this.props.disablePaginate) {
      return options;
    }

    return {
      options,
    };
  };

  getDataByRoute = async (inputValue, _, currentPage) => {
    const { nameField, route, routeName, optionsRenderer, outdated, disablePaginate } = this.props;
    let page;
    let lastPage;
    const result = await api.get(
      route[routeName],
      disablePaginate
        ? {
            params: {
              ...this.props.query,
              search: inputValue,
              outdated,
            },
          }
        : {
            params: {
              ...this.props.query,
              search: inputValue,
              page: currentPage.page,
              outdated,
            },
          },
    );

    if (!disablePaginate) {
      const { page: resultPage, last_page: resultLastPage } = result.data.data;
      page = resultPage;
      lastPage = resultLastPage;
    }

    if (optionsRenderer) {
      if (disablePaginate) {
        return optionsRenderer(result);
      }

      return {
        options: optionsRenderer(result),
        hasMore: currentPage.page <= lastPage,
        additional: {
          page: Number(page) + 1,
        },
      };
    }

    const options = result.data.data.results.map((item) => ({
      value: item.id,
      name: item[nameField],
      data: item,
    }));

    if (disablePaginate) {
      return options;
    }

    return {
      options,
      hasMore: currentPage.page <= lastPage,
      additional: {
        page: Number(page) + 1,
      },
    };
  };

  render() {
    if (typeof window === 'undefined') return null;

    const {
      label,
      meta,
      route,
      value,
      onChange,
      name,
      isMulti,
      isClearable,
      withoutDefaultValue,
      isDisabled,
      defaultOptions,
      cacheOptions,
      autoFocus,
      placeholder,
      disablePaginate,
      components,
      fixedError,
      dataSelenium,
    } = this.props;
    const { hasError } = this.state;
    let selectProps = null;

    if (isObject(value)) {
      selectProps = {
        value,
      };
    }

    if (value !== '' && !isObject(value)) {
      selectProps = {
        defaultInputValue: value,
        value: {
          value,
          name: value,
        },
      };
    }

    if (withoutDefaultValue) {
      selectProps = {
        value,
        defaultInputValue: '',
      };
    }

    const AsyncComponent = disablePaginate ? AsyncSelect : AsyncPaginate;

    return (
      <Styled.Container isDisabled={isDisabled} data-selenium={dataSelenium}>
        {label && <Label>{label}</Label>}
        <AsyncComponent
          additional={
            !disablePaginate && {
              page: 1,
            }
          }
          cacheOptions={cacheOptions}
          components={components}
          noOptionsMessage={() => '...'}
          loadingMessage={() => '...'}
          defaultOptions={defaultOptions}
          loadOptions={route ? this.getDataByRoute : this.getAddressByYandex}
          name={name}
          value={value}
          onChange={onChange}
          styles={{
            ...styles,
            control: (provided, state) => ({
              ...provided,
              minHeight: 40,
              paddingTop: 0,
              paddingBottom: 0,
              borderColor: (() => {
                if (state.isFocused) {
                  return `${COLORS.BLUE} !important`;
                }

                return hasError ? `${COLORS.RED} !important` : `${COLORS.GRAYE1} !important`;
              })(),
              border: (() => {
                if (this.props.noBorder) {
                  return 'none';
                }
                return provided.border;
              })(),
              background: (() => {
                if (this.props.noBackground) {
                  return 'transparent';
                }
                return provided.background;
              })(),
              boxShadow: hasError ? 'none' : null,
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              transition: 'all 0.2s ease-in-out',
              transform: state.isFocused ? 'rotate(180deg)' : '0',
            }),
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.value}
          maxMenuHeight={190}
          autofill={false}
          isClearable={isClearable}
          isMulti={isMulti}
          isDisabled={isDisabled}
          autoFocus={autoFocus}
          placeholder={placeholder}
          classNamePrefix="react-select"
          {...selectProps}
        />
        <Error meta={meta} fixedError={fixedError} />
      </Styled.Container>
    );
  }
}

export default InputWithAutocomplete;
