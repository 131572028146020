import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import attachmentsSource from 'shared/sources/attachments';

const useAttachments = ({ deleteMetod, createMetod, editMetod, data }) => {
  const [deleteCoverAttachments, setDeleteCoverAttachments] = useState(false);
  const [editCoverAttachments, setEditCoverAttachments] = useState(false);
  const [editPreviewAttachments, setEditPreviewAttachments] = useState(false);
  const [deletePreviewAttachments, setDeletePreviewAttachments] = useState(false);
  const [deleteGalleryAttachment, setDeleteGalleryAttachment] = useState(false);
  const [addPictureGalleryAttachment, setAddPictureGalleryAttachment] = useState(false);
  const [deleteMainImageAttachments, setDeleteMainImageAttachments] = useState(false);
  const [editMainImageAttachments, setEditMainImageAttachments] = useState(false);

  const cover = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Cover;
  });
  const gallery = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Gallery;
  });
  const preview = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Preview;
  });
  const galleryWebp = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Gallery_Web;
  });
  const mainImage = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Main_Image;
  });

  const coverID = cover?.attachment_type?.id;
  const previewID = preview?.attachment_type?.id;
  const galleryID = gallery?.attachment_type?.id;
  const galleryWebpID = galleryWebp?.attachment_type?.id;
  const mainImageID = mainImage?.attachment_type?.id;

  useEffect(() => {
    const deleteAttachments = (attachementCode) => {
      if (attachementCode === AttachmentType.Cover) {
        setDeleteCoverAttachments(true);
      }
      if (attachementCode === AttachmentType.Main_Image) {
        setDeleteMainImageAttachments(true);
      }
      if (attachementCode === AttachmentType.Preview) {
        setDeletePreviewAttachments(true);
      }
    };

    const deleteGalleryAttachments = () => {
      setDeleteGalleryAttachment(true);
    };

    const editEventsAttachment = (attachementCode) => {
      if (attachementCode === AttachmentType.Cover) {
        setEditCoverAttachments(true);
      }
      if (attachementCode === AttachmentType.Main_Image) {
        setEditMainImageAttachments(true);
      }
      if (attachementCode === AttachmentType.Preview) {
        setEditPreviewAttachments(true);
      }
    };

    const addPictureGallery = () => {
      setAddPictureGalleryAttachment(true);
    };

    Emmiter.addListener(EVENTS.DELETE_ATTACHMENTS, deleteAttachments);
    Emmiter.addListener(EVENTS.DELETE_GALLERY_ATTACHMENTS, deleteGalleryAttachments);
    Emmiter.addListener(EVENTS.EDIT_ATTACHMENTS, editEventsAttachment);
    Emmiter.addListener(EVENTS.ADD_PICTURE_GALLERY_ATTACHMENTS, addPictureGallery);

    return () => {
      Emmiter.removeListener(EVENTS.DELETE_ATTACHMENTS, deleteAttachments);
      Emmiter.removeListener(EVENTS.DELETE_GALLERY_ATTACHMENTS, deleteGalleryAttachments);
      Emmiter.removeListener(EVENTS.EDIT_ATTACHMENTS, editEventsAttachment);
      Emmiter.removeListener(EVENTS.ADD_PICTURE_GALLERY_ATTACHMENTS, addPictureGallery);
    };
  }, []);

  async function createAttachmentsWithRetry(eventId, formAttachments, attachment) {
    try {
      const createEvents = await createMetod(eventId, formAttachments);
      return createEvents;
    } catch (error) {
      await deleteMetod(eventId, attachment.attachment_type.id);

      return createAttachmentsWithRetry(eventId, formAttachments, attachment);
    }
  }

  async function createGalleryWithRetry(eventId, attachments) {
    try {
      const createEvents = await createMetod(eventId, attachments);
      return createEvents;
    } catch (error) {
      await deleteMetod(eventId, galleryID);
      await deleteMetod(eventId, galleryWebpID);
      return createGalleryWithRetry(eventId, attachments);
    }
  }

  async function buildPreviewAttachment(formData, attachments) {
    const previewAttachments = attachments.filter((attachment) => {
      return attachment.code === AttachmentType.Preview;
    });
    if (deletePreviewAttachments) {
      await deleteMetod(formData.id, previewID);

      if (!isEmpty(formData.preview)) {
        await createMetod(formData.id, previewAttachments);
      }

      setDeletePreviewAttachments(false);
    }

    if ((!preview || isEmpty(preview?.data)) && !isEmpty(formData.preview)) {
      await createAttachmentsWithRetry(formData.id, previewAttachments, preview);
    }

    if (editPreviewAttachments && !isEmpty(formData?.preview)) {
      await editMetod(formData.id, previewID, previewAttachments);
    }

    if (
      (formData?.preview_title !== preview?.title || formData?.preview_alt !== preview?.alt) &&
      !isEmpty(preview?.data) &&
      !isEmpty(formData.preview) &&
      !deletePreviewAttachments
    ) {
      await editMetod(formData.id, previewID, previewAttachments);
    }
  }

  async function buildCoverAttachment(formData, attachments) {
    const coverAttachment = attachments.filter((attachment) => {
      return attachment.code === AttachmentType.Cover;
    });

    if (deleteCoverAttachments) {
      await deleteMetod(formData.id, coverID);

      if (!isEmpty(formData?.cover)) {
        await createMetod(formData.id, coverAttachment);
      }

      setDeleteCoverAttachments(false);
    }

    if ((!cover || isEmpty(cover.data)) && !isEmpty(formData.cover)) {
      await createAttachmentsWithRetry(formData.id, coverAttachment, cover);
    }

    if (editCoverAttachments && !isEmpty(formData?.cover)) {
      await editMetod(formData.id, coverID, coverAttachment);
    }

    if (
      (formData?.cover_title !== cover?.title || formData?.cover_alt !== cover?.alt) &&
      !isEmpty(cover?.data) &&
      !isEmpty(formData.cover) &&
      !deleteCoverAttachments
    ) {
      await editMetod(formData.id, coverID, coverAttachment);
    }
  }

  async function buildGalleryAttachment(formData, attachments) {
    const galleryAttachments = attachments.filter((attachment) => {
      return attachment.code === AttachmentType.Gallery;
    });

    const galleryWebpAttachments = attachments.filter((attachment) => {
      return attachment.code === AttachmentType.Gallery_Web;
    });

    if (deleteGalleryAttachment) {
      await deleteMetod(formData.id, galleryID);
      await deleteMetod(formData.id, galleryWebpID);

      if (formData.gallery && formData.gallery?.length) {
        await createGalleryWithRetry(formData.id, galleryAttachments);
        await createGalleryWithRetry(formData.id, galleryWebpAttachments);
      }

      setDeleteGalleryAttachment(false);
    }

    if ((!gallery || !gallery.data?.length) && formData.gallery?.length) {
      await createGalleryWithRetry(formData.id, galleryAttachments);
      await createGalleryWithRetry(formData.id, galleryWebpAttachments);
    }

    if (gallery?.data?.length && addPictureGalleryAttachment && formData.gallery?.length) {
      await editMetod(formData.id, galleryID, galleryAttachments);
      await editMetod(formData.id, galleryWebpID, galleryWebpAttachments);
    }
  }

  async function buildMainPageAttachment(formData, attachments) {
    const mainImageAttachment = attachments.filter((attachment) => {
      return attachment.code === AttachmentType.Main_Image;
    });
    if (deleteMainImageAttachments) {
      await attachmentsSource.deletePersonsAttachments(formData.id, mainImageID);

      if (!isEmpty(formData?.main_image)) {
        await attachmentsSource.createPersonsAttachments({
          event_id: formData.id,
          attachmentsData: mainImageAttachment,
        });
      }

      setDeleteMainImageAttachments(false);
    }

    if ((!mainImage || isEmpty(mainImage.data)) && (!!formData.main_image || !isEmpty(formData.main_image))) {
      await createAttachmentsWithRetry(formData.id, mainImageAttachment, mainImage);
    }

    if (editMainImageAttachments && !isEmpty(formData?.main_image)) {
      await attachmentsSource.editPersonsAttachments(formData.id, mainImageID, mainImageAttachment);
    }

    if (
      (formData?.main_image_title !== mainImage?.title || formData?.main_image_alt !== mainImage?.alt) &&
      !isEmpty(mainImage?.data) &&
      !isEmpty(formData.main_image) &&
      !deleteMainImageAttachments
    ) {
      await attachmentsSource.editPersonsAttachments(formData.id, mainImageID, mainImageAttachment);
    }
  }

  return {
    buildPreviewAttachment,
    buildCoverAttachment,
    buildGalleryAttachment,
    buildMainPageAttachment,
  };
};

export default useAttachments;
