import styled, { createGlobalStyle } from 'styled-components';
import { smallModalStyles, mobileModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}

    ${mobileModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Spinner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Fields: styled.div`
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 32px;
    }
  `,
};
