import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Body: styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 644px;
    height: 100%;
    padding: 32px 40px;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 0;
    }
  `,
};
