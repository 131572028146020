import styled, { css, createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface RowProps {
  grid?: boolean;
  small?: boolean;
}

export default {
  GlobalStyles: createGlobalStyle`
    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__body {
        padding: 0;
      }
    }
  `,

  MobileContainer: styled.div`
    display: none;

    @media (max-width: ${BREAKPOINTS.SM}) {
      display: block;
    }
  `,

  TabletUpContainer: styled.div`
    @media (max-width: ${BREAKPOINTS.SM}) {
      display: none;
    }
  `,

  Row: styled.div<RowProps>`
    width: 100%;
    margin-bottom: ${(props) => (props.small ? 8 : 32)}px;

    ${(props) =>
      props.grid &&
      css`
        display: flex;
        margin-bottom: 0;

        @media (max-width: ${BREAKPOINTS.MD}) {
          flex-direction: column;
        }
      `}
  `,

  Column: styled.div`
    width: 100%;

    & > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  `,

  FooterWrapper: styled.div`
    @media (max-width: ${BREAKPOINTS.XXS}) {
      & button {
        padding: 0 16px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  `,
};
