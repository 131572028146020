import styled from 'styled-components';
import { darken } from 'polished';

export default {
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 9px 25px;
  `,

  Title: styled.div`
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.64);
  `,

  Icon: styled.span`
    margin-right: 8px;
    vertical-align: middle;
  `,

  Clear: styled.button`
    font-size: 12px;
    line-height: 16px;
    color: #eb5757;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
      color: ${darken(0.15, '#eb5757')};
    }
  `,
};
