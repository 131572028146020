import { AxiosError, AxiosResponse } from 'axios';
import config from 'config/config';
import { createEffect } from 'effector';
import api from 'services/api';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import COOKIES from '../../../constants/COOKIES';
import { PagePaginationQuery } from '../../../factory';
import { getCookie } from '../../../lib/cookie';
import {
  ICreateLandingInfoParams,
  ICreateLandingInfosParams,
  ICreateLandingParams,
  IEditSiteRequestParams,
} from './types';

const companyId = getCookie(COOKIES.COMPANY);

export const landingsRoute = `${config.COMMON_API_URL}/admin/v1/${companyId}/landings`;
export const landingsRouteV2 = `${config.COMMON_API_URL}/admin/v2/${companyId}/landings`;

const fetchLandigsListHandler = async ({ query }: { query: PagePaginationQuery }) => {
  const response = await api.get(landingsRoute, {
    params: {
      limit: query.limit,
      page: query.page,
      search_string: query.search_string,
      content_group_id_in: query.content_groups,
      sort_by: query.sort_by,
      sort_on: query.sort_on,
      manager_id_in: query.manager__in,
      legal_id_in: query.legal__in,
      email_id_in: query.email__in,
      acquiring_id_in: query.acquiring__in,
      phone: query.phone,
      language_code: LANGUAGES.RU,
    },
  });

  return response.data;
};

export const fetchLandigsListFx = createEffect(fetchLandigsListHandler);

export const updateLandigsListFx = createEffect(fetchLandigsListHandler);

export const createLandingFx = createEffect<ICreateLandingParams, AxiosResponse<unknown>, AxiosError>(
  async (data) => {
    const response = await api.post(landingsRoute, { company_id: companyId, ...data });

    return response.data;
  },
);

export const fetchLandingFx = createEffect(async (tech_name: string) => {
  const response = await api.get(`${landingsRouteV2}/${tech_name}`);

  return response.data;
});

export const linkContentGroupWithSiteFx = createEffect(
  async ({ techName, addedContentGroups }: { techName: string; addedContentGroups: string[] }) => {
    const requests = addedContentGroups.map(async (item) => {
      const response = await api.post(`${landingsRoute}/${techName}/content_groups`, {
        content_group_id: item,
      });

      return response;
    });

    await Promise.all(requests);
  },
);

export const unlinkContentGroupWithSiteFx = createEffect(
  async ({ techName, removedContentGroups }: { techName: string; removedContentGroups: string[] }) => {
    const requests = removedContentGroups.map(async (id) => {
      const response = await api.delete(`${landingsRoute}/${techName}/content_groups/${id}`);

      return response;
    });

    await Promise.all(requests);
  },
);

export const createLandingInfosFx = createEffect<ICreateLandingInfosParams, void, AxiosError>(
  async ({ techName, translations }) => {
    const requests = translations.map(async (translation) => {
      const response = await api.post(`${landingsRouteV2}/${techName}/info`, translation);

      return response;
    });

    await Promise.all(requests);
  },
);

export const editLandingInfoFx = createEffect<ICreateLandingInfoParams, void, AxiosError>(
  async ({ techName, translation }) => {
    await api.patch(`${landingsRouteV2}/${techName}/info/${translation.language_code}`, translation);
  },
);

export const createLandingInfoFx = createEffect<ICreateLandingInfoParams, void, AxiosError>(
  async ({ techName, translation }) => {
    await api.post(`${landingsRouteV2}/${techName}/info`, translation);
  },
);

export const editLandingFx = createEffect<IEditSiteRequestParams, unknown, AxiosError>(
  async ({ techName, data }) => {
    const request = await api.patch(`${landingsRouteV2}/${techName}`, data);

    return request.data;
  },
);

export const removeLandingFx = createEffect(async (techName: string) => {
  await api.delete(`${landingsRouteV2}/${techName}`);
});
