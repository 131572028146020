import React from 'react';
import ReactNumericTextbox from 'react-numeric-textbox';
import Styled from './styles';
import Error from '../Error';
import Label from '../LabelNew';

interface NumberInputProps {
  label?: any;
  withoutError?: boolean;
  meta?: any;
  fixedError?: boolean;
  isSmall?: boolean;
  disabled?: boolean;
  icon?: any;
  errorPosition?: 'right';
  fixLabel?: boolean;
  float?: boolean;
  value?: string | number | null;
  name?: string;
  min?: number;
  max?: number;
  type?: string;
  onChange?: any;
  placeholder?: string;
  autoFocus?: boolean;
}

const NumericInput = ({
  label,
  meta = {},
  icon,
  fixedError,
  disabled,
  isSmall = false,
  errorPosition,
  withoutError = false,
  fixLabel = false,
  float = false,
  value,
  name,
  ...rest
}: NumberInputProps) => {
  const renderLabel = label && (
    <Label
      value={(typeof value !== 'undefined' && value !== null && value.toString()) || fixLabel}
      meta={meta}
      disabled={disabled}
    >
      {label}
    </Label>
  );

  return (
    <Styled.Wrapper>
      <Styled.Container
        isSmall={isSmall}
        meta={meta}
        disabled={disabled}
        withIcon={icon}
        data-selenium={name}
      >
        {renderLabel}
        <ReactNumericTextbox
          decimals={float ? 2 : 0}
          format=""
          disabled={disabled}
          autoCorrect
          {...rest}
          value={value === '' ? null : Number(value)}
        />
        {icon && <Styled.Icon>{icon}</Styled.Icon>}
      </Styled.Container>
      {!withoutError && <Error meta={meta} fixedError={fixedError} position={errorPosition} />}
    </Styled.Wrapper>
  );
};

export default NumericInput;
