import React from 'react';
import Form from '../../../Form';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const variants = Array.from({ length: 6 }, (v, i) => i + 1);

const RequestFormStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Выберите дизайн формы заявок</Styled.Title>
    </Styled.Header>

    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.List grid>
              {variants.map((variant) => (
                <Styled.Item grid width="50%" data-selenium={`variant-${variant}`}>
                  <Variant name="ui_config_form" value={variant}>
                    <img
                      src={`/static/constructor/request-form/${variant}.jpg`}
                      alt={`Форма заявки ${variant}`}
                    />
                  </Variant>
                </Styled.Item>
              ))}
            </Styled.List>
            <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
          </form>
        )}
      />
    </Styled.Content>
  </Styled.Container>
);

export default RequestFormStep;
