import React from 'react';
import head from 'lodash/head';
import { NotificationManager } from 'react-notifications';

const TEN_MB = 10 * 1024 * 1024;

interface FileUploadInputProps {
  onLoadCallback: (event: any, file: File, fileType: string) => any;
}

const FileUploadInput: React.FC<FileUploadInputProps> = (props) => {
  const { onLoadCallback } = props;

  const handleChange = (event) => {
    const { target } = event;

    const files: FileList = target.files;
    for (let i = 0; i < files.length; i++) {
      const file: any = files[i];

      if (file) {
        if (!file.type.match(/(image\/(jpg|jpeg|png|gif))|(application\/pdf)/)) {
          return NotificationManager.error('Недопустимый формат файла', 'Ошибка');
        }

        if (file.size > TEN_MB) {
          return NotificationManager.error('Файл слишком большой', 'Ошибка');
        }

        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = (eventOnLoad) => {
          onLoadCallback(eventOnLoad, file, file.type);

          target.value = '';
        };
      }
    }

    return null;
  };

  return <input type="file" accept="image/*,application/pdf" onChange={handleChange} multiple />;
};

export default FileUploadInput;
