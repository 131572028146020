import styled, { createGlobalStyle } from 'styled-components';
import { mediumModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${mediumModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Spinner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
