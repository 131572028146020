import React, { Component } from 'react';
import { Field } from 'react-final-form';
import {
  composeValidators,
  maxValue,
  required,
  validDate,
  validNoWhitespace,
} from 'shared/helpers/validations';
import Form from '../../Form';
import Button from '../../Button';
import { Input, InputWithSelect, DateTimePicker } from '../../FormControls';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import { parsersVenuesSources } from 'entities/parsers/venues';
import { parsersCategoriesSources } from 'entities/parsers/categoires';
import { membersCompaniesSources } from 'entities/members/companies';
import { renderSources } from 'shared/helpers/optionsRenderers';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  addEvent: (data: any) => any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
}

class ParsersAddEventModal extends Component<ModalProps> {
  onSubmit = (data) => {
    const { addEvent } = this.props;

    const {
      url,
      source_url: sourceUrl,
      name,
      when,
      venue: { value: venue },
      source: { value: source },
      category: { value: category },
    } = data;

    const request = {
      url,
      source_url: sourceUrl,
      name,
      venue,
      source,
      category,
      when: when.format(DATE_FORMATS.ISO_8601),
    };

    return addEvent(request);
  };

  render() {
    const { closeModal, forceCloseModal } = this.props;

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Form
          onSubmit={this.onSubmit}
          onSuccess={forceCloseModal}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Title>Добавить мероприятие</Modal.Title>
              <Styled.Fields>
                <Field name="when" validate={validDate}>
                  {({ input, meta }) => (
                    <DateTimePicker
                      label="Дата проведения"
                      name="when"
                      handleForm={form}
                      input={input}
                      meta={meta}
                      outdatedToggler={false}
                    />
                  )}
                </Field>
                <Field name="name" validate={composeValidators(required, maxValue(255))}>
                  {({ input, meta }) => <Input label="Название" {...input} meta={meta} />}
                </Field>
                <Field name="venue" validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      label="Площадка"
                      route={parsersVenuesSources}
                      meta={meta}
                      {...input}
                    />
                  )}
                </Field>
                <Field name="category" validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      label="Категория"
                      route={parsersCategoriesSources}
                      meta={meta}
                      {...input}
                    />
                  )}
                </Field>
                <Field name="url" validate={composeValidators(required, validNoWhitespace)}>
                  {({ input, meta }) => <Input label="Техническа cсылка" {...input} meta={meta} />}
                </Field>
                <Field name="source_url" validate={composeValidators(required, validNoWhitespace)}>
                  {({ input, meta }) => <Input label="Ссылка" {...input} meta={meta} />}
                </Field>
                <Field name="source" validate={required}>
                  {({ input, meta }) => (
                    <InputWithSelect
                      isAsync
                      label="Источник"
                      route={membersCompaniesSources}
                      routeName="source"
                      optionsRenderer={renderSources}
                      meta={meta}
                      {...input}
                    />
                  )}
                </Field>
              </Styled.Fields>
              <Modal.Footer>
                <Button transparent type="button" onClick={closeModal}>
                  Отмена
                </Button>
                <Button>Добавить</Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Styled.Container>
    );
  }
}

export default ParsersAddEventModal;
