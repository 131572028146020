import React from 'react';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';
import get from 'lodash/get';
import MODALS from 'shared/constants/MODALS';
import RejectModalForm from '../../Forms/RejectModalForm';
import { REJECT_REASONS } from 'shared/constants/ORDER';
import { orderSources } from 'entities/order';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  loadSale: (id: number) => any;
  openModal: ModalFunctions['openModal'];
  closeModal: ModalFunctions['closeModal'];
  cancelOrder: (id: number, data: any) => any;
}

const OrderCancelModal: React.FC<ModalProps> = (props) => {
  const {
    loadSale,
    openModal,
    closeModal,
    cancelOrder,
    data: { id },
  } = props;

  const onSubmit = ({ reason, message }) => {
    cancelOrder(id, {
      reject_reason: reason.value,
      ...(reason.value === REJECT_REASONS.OTHER && { message }),
    }).then((response) => {
      if (
        response.error &&
        head(response.error.response.data.error.order) === 'Order has glass announcements.'
      ) {
        return openModal(MODALS.GLASS_TICKETS_MODAL, {
          announcements: response.error.response.data.error.glass,
          orderAction: 'reject',
          orderId: id,
          modalData: {
            confirmClose: true,
          },
        });
      }

      if (!isEmpty(response.payload.data)) {
        return openModal(MODALS.PARTNER_TICKETS_MODAL, {
          tickets: response.payload.data,
        });
      }

      if (response.error) {
        NotificationManager.error(get(response, 'error.response.data.error'), 'Ошибка');
      }

      closeModal();

      return loadSale(id);
    });
  };

  return <RejectModalForm onSubmit={onSubmit} closeModal={closeModal} rejectReasonsRoute={orderSources} />;
};

export default OrderCancelModal;
