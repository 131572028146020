import styled from 'styled-components';
import ModalStyled from '../../../Modal/styles';

export default {
  Container: styled.div`
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 420px;
    transform: translateY(-50%);
    z-index: 9999;
    background: #fff;
    border-radius: 8px 8px 0 0;
  `,

  Form: styled.form`
    padding: 32px 40px 0;
  `,

  Field: styled.div`
    margin-bottom: 8px;
  `,

  Overlay: styled(ModalStyled.Overlay)``,
};
