import styled, { createGlobalStyle } from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import { smallModalStyles, mobileModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}

    ${mobileModalStyles}

    @media ${MEDIA_QUERIES.MOBILE} {
      .modal__body {
        overflow: visible;
      }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Spinner: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Fields: styled.div`
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 32px;
    }
  `,

  Client: styled.div`
    margin-bottom: 32px;
  `,

  ClientInfoField: styled.p`
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 21px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
};
