import React, { useState, useRef } from 'react';
import { SketchPicker } from 'react-color';
import preventClick from 'shared/helpers/preventClick';
import Error from '../Error';
import Label from '../LabelNew';
import Styled from './styles';

interface ColorPickerProps {
  input: any;
  label: string;
  isDisabled?: boolean;
  meta: any;
}

const ColorPicker = (props: ColorPickerProps) => {
  const [pickerIsVisible, togglePicker] = useState(false);
  const [color, setColor] = useState(props.input.value);
  const pickerRef = useRef();
  const { isDisabled = false } = props;

  const handleChangeComplete = (newColor: any) => {
    props.input.onChange(newColor.hex);
    setColor(newColor.hex);
  };

  const showPicker = () => {
    togglePicker(!pickerIsVisible);
  };

  return (
    <div>
      <Styled.Container onClick={showPicker}>
        <Label value={color} meta={props.meta} disabled={isDisabled}>
          {props.label}
        </Label>
        <Styled.Body>
          {color}
          {color && <Styled.Preview color={color} />}
          {pickerIsVisible && (
            <Styled.Picker ref={pickerRef} onClick={preventClick}>
              <SketchPicker color={color} onChange={handleChangeComplete} />
            </Styled.Picker>
          )}
        </Styled.Body>
      </Styled.Container>
      <Error meta={props.meta} />
    </div>
  );
};

export default ColorPicker;
