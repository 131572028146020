import React from 'react';
import { RadioGroup } from '../../../../FormControls';
import { QueryContext } from '../ThirdStep/QueryContext';
import Styled from './styles';

interface SelectorProps {
  value: string;
  options: any[];
  handleOnChange: (value: any) => void;
  level: number;
}

const ValueSelector: React.FC<SelectorProps> = (props) => {
  const { value, options, handleOnChange, level } = props;

  return (
    <QueryContext.Consumer>
      {(context: any) => (
        <Styled.Combinators className="combinators">
          <RadioGroup
            value={value}
            onChange={(e) => handleOnChange(e.target.value)}
            buttons={options.map((option) => {
              const postfix = context ? context.id : Math.random();

              const key = option.id
                ? `key-${option.id}-${level}-${postfix}`
                : `key-${option.name}-${level}-${postfix}`;

              return {
                id: key,
                value: option.id || option.name,
                name: option.label,
              };
            })}
          />
        </Styled.Combinators>
      )}
    </QueryContext.Consumer>
  );
};

export default ValueSelector;
