import { useUnit } from 'effector-react';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'entities/modal';
import MODALS from 'shared/constants/MODALS';
import { findTranslation } from 'shared/helpers/translations';
import { Modal, ModalClose } from 'shared/ui/modal';
import { Spinner } from 'shared/ui/spinner';
import { HallForm } from '../../form';
import { updateHallModal } from '../model';
import Styled from './styles';

export const UpdateHallModal = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();

  const { modalOpened, modalClosed, handleDelete, handleSubmit } = useUnit({
    modalOpened: updateHallModal.modalOpened,
    modalClosed: updateHallModal.modalClosed,
    handleDelete: updateHallModal.deleteSubmitted,
    handleSubmit: updateHallModal.formSubmitted,
  });
  const { data, loading, modalId, isOpen, isSubmitting } = useUnit({
    data: updateHallModal.$data,
    loading: updateHallModal.$loading,
    modalId: updateHallModal.$modalId,
    isOpen: updateHallModal.toggler.$isOpen,
    isSubmitting: updateHallModal.$isSubmitting,
  });

  useEffect(() => {
    if (isOpen && modalId) {
      modalOpened({ id: modalId, type: 'update' });
    }

    return () => {
      modalClosed();
      setLang(i18n.language);
    };
  }, [isOpen]);

  const openDeleteForm = () => {
    dispatch(
      openModal(MODALS.CONFIRM_MODAL, {
        onSubmit: () =>
          handleDelete({ id: data!.id, title: findTranslation(data!.info, i18n.language)!.title }),
        title: t('references:halls.delete_title'),
        text: t('references:halls.delete_text'),
        modalData: { size: 400 },
        withoutNotification: true,
      }),
    );
  };

  const initialValues = !data
    ? null
    : {
        ...data,
        ...findTranslation(data.info, lang),
        place_id: data.place && {
          value: data.place.id,
          label: data.place.title,
        },
      };

  return (
    <Modal toggler={updateHallModal.toggler} name="hall-edit">
      {({ closeModal }) => (
        <Styled.Body>
          <ModalClose onClick={closeModal} />
          {loading ? (
            <Styled.SpinnerContainer>
              <Spinner />
            </Styled.SpinnerContainer>
          ) : (
            <HallForm
              isEdit
              title="references:halls.edit_title"
              initialValues={initialValues}
              onChangeLng={setLang}
              onSubmit={handleSubmit}
              openDeleteForm={openDeleteForm}
              closeModal={closeModal}
              isSubmitting={isSubmitting}
            />
          )}
        </Styled.Body>
      )}
    </Modal>
  );
};
