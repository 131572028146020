import React, { Component } from 'react';
import playgroundsSources from 'shared/sources/playgrounds';
import { companySources } from 'entities/company';
import { parsersSources } from 'entities/parsers';
import { WIDGET_FILTERS } from 'shared/constants/WIDGET';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import QUERIES from 'shared/constants/QUERIES';
import { renderWidgetPlaygrounds, renderParsers, renderBrokers } from 'shared/helpers/optionsRenderers';
import { InputWithAutocomplete, NumberInput } from '../../../../FormControls';
import Box from '../../../../Box';
import { DataContext } from '../DataContext';
import WidgetStyled from '../../WidgetForm/styles';
import ValueEditorRow from './ValueEditorRow';
import ValueEditorSeat from './ValueEditorSeat';
import Styled from './styles';
import { isEmpty } from 'lodash';

const routes = {
  [WIDGET_FILTERS.PARSERS]: parsersSources,
  [WIDGET_FILTERS.ZRITELI]: parsersSources,
  [WIDGET_FILTERS.PROVIDER]: companySources,
  [WIDGET_FILTERS.COMPANY]: companySources,
  [WIDGET_FILTERS.SECTOR]: playgroundsSources,
  [WIDGET_FILTERS.CATEGORY]: playgroundsSources,
};

const VALUES = {
  [QUERIES.LESS]: 0,
  [QUERIES.GREATER]: 0,
  [QUERIES.LESS_OR_EQUAL]: 0,
  [QUERIES.GREATER_OR_EQUAL]: 0,
};

const optionsRenderers = {
  [WIDGET_FILTERS.SECTOR]: renderWidgetPlaygrounds,
  [WIDGET_FILTERS.CATEGORY]: renderWidgetPlaygrounds,
  [WIDGET_FILTERS.PARSERS]: renderParsers,
  [WIDGET_FILTERS.ZRITELI]: renderBrokers,
};

interface ValueEditorProps {
  operator: string;
  value: string | any;
  handleOnChange: (value: any) => void;
  title: string;
  field: string;
  disabled?: boolean;
  handleOnChangeSector: (data: any) => void;
  handleOnChangeSectorRow: (data: any) => void;
  sector?: any;
  row?: any;
  id?: number;
  parentId: number | string;
}

interface ValueEditorState {
  field: string;
  operator: string;
  sector?: any;
  row?: any;
}

class ValueEditor extends Component<ValueEditorProps, ValueEditorState> {
  constructor(props) {
    super(props);

    this.state = {
      field: props.field,
      operator: props.operator,
      sector: props.sector,
      row: props.row,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.field !== props.field || state.operator !== props.operator) {
      if (props.field === 'sector') {
        props.handleOnChange(props.value);

        return {
          field: props.field,
          operator: props.operator,
        };
      }

      if (props.field === 'row_id') {
        props.handleOnChange(props.value);

        return {
          field: props.field,
          operator: props.operator,
          sector: props.sector,
        };
      }

      if (props.field === 'seat_id') {
        props.handleOnChange(props.value);

        return {
          field: props.field,
          operator: props.operator,
          sector: props.sector,
          row: props.row,
        };
      }

      const operatorValue = VALUES[props.operator];
      const value = operatorValue === undefined ? null : operatorValue;

      props.handleOnChange(value);

      return {
        field: props.field,
        operator: props.operator,
        sector: null,
        row: null,
      };
    }

    return null;
  }

  handleChangeSector = (data) => {
    this.props.handleOnChange(null);

    if (data) {
      const { value, name } = data;
      this.props.handleOnChangeSector({ value, name });
      return this.setState({
        sector: {
          value,
          name,
        },
      });
    }

    this.props.handleOnChangeSector(null);
    return this.setState({
      sector: null,
    });
  };

  handleChangeSectorRow = (data) => {
    this.props.handleOnChange(null);

    if (data) {
      const { value, name } = data;
      this.props.handleOnChangeSectorRow({ value, name });
      return this.setState({
        row: {
          value,
          name,
        },
      });
    }

    this.props.handleOnChangeSectorRow(null);
    return this.setState({
      row: null,
    });
  };

  handleChange = (selectValue) => {
    this.props.handleOnChange(selectValue);
  };

  handleChangeRow = (selectValue) => {
    const { sector } = this.props;

    const value = Array.isArray(selectValue)
      ? selectValue.map((row) => ({
          ...row,
          value_alt: `row-${sector.name}-${row.name}`,
        }))
      : selectValue;
    this.props.handleOnChange(value);
  };

  handleChangeSeat = (selectValue) => {
    const { sector, row } = this.props;

    const value = Array.isArray(selectValue)
      ? selectValue.map((seat) => ({
          ...seat,
          value_alt: `seat-${sector.name}-${row.name}-${seat.name}`,
        }))
      : null;
    this.props.handleOnChange(value);
  };

  handleChangeFirstValue = (value) => {
    const { handleOnChange } = this.props;
    let { value: currentValue } = this.props;

    if (Array.isArray(currentValue)) {
      currentValue = [value, currentValue[1]];
    } else {
      currentValue = [value, 0];
    }

    handleOnChange(currentValue);
  };

  handleChangeSecondValue = (value) => {
    const { handleOnChange } = this.props;
    let { value: currentValue } = this.props;

    if (Array.isArray(currentValue)) {
      currentValue = [currentValue[0], value];
    } else {
      currentValue = [0, value];
    }

    handleOnChange(currentValue);
  };

  get optionsRenderer() {
    const { field } = this.state;

    return optionsRenderers[field] || null;
  }

  get routeName() {
    const { field } = this.state;

    if (field === WIDGET_FILTERS.PROVIDER) return 'providers';
    if (field === WIDGET_FILTERS.COMPANY) return 'list';
    if (field === WIDGET_FILTERS.ZRITELI) return 'zriteli';

    return 'root';
  }

  render() {
    const { value, title, disabled, parentId } = this.props;

    const { field, operator, sector, row } = this.state;
    const isDisabled = disabled || operator === QUERIES.NONE || operator === QUERIES.ALL;

    if (field === WIDGET_FILTERS.ALL_TICKETS) return null;

    if (operator === 'null' || operator === 'notNull') {
      return null;
    }

    if (operator === QUERIES.BETWEEN || operator === QUERIES.NOT_BETWEEN) {
      return (
        <Box m="0 -4px">
          <Box p="0 4px">
            <NumberInput
              disabled={disabled}
              value={Array.isArray(value) ? value[0] : 0}
              onChange={this.handleChangeFirstValue}
            />
          </Box>
          <Box p="0 4px">
            <NumberInput
              disabled={disabled}
              value={Array.isArray(value) ? value[1] : 0}
              onChange={this.handleChangeSecondValue}
            />
          </Box>
        </Box>
      );
    }

    if (field === WIDGET_FILTERS.PRICE) {
      return (
        <Box>
          <NumberInput disabled={disabled} value={value} onChange={this.handleChange} />
        </Box>
      );
    }

    const { optionsRenderer, routeName } = this;

    const isMulti = operator !== QUERIES.EQUAL && operator !== QUERIES.NOT_EQUAL;

    if (field === WIDGET_FILTERS.ROW) {
      return (
        <Styled.RowContainer>
          <ValueEditorRow
            sector={sector}
            field={field}
            title={title}
            disabled={isDisabled}
            isMulti={isMulti}
            value={value}
            parentId={parentId}
            onChangeSector={this.handleChangeSector}
            onChangeRow={this.handleChangeRow}
          />
        </Styled.RowContainer>
      );
    }

    if (field === WIDGET_FILTERS.SEAT) {
      return (
        <Styled.RowContainer>
          <ValueEditorSeat
            sector={sector}
            row={row}
            field={field}
            title={title}
            disabled={isDisabled}
            value={value}
            parentId={parentId}
            onChangeSector={this.handleChangeSector}
            onChangeRow={this.handleChangeSectorRow}
            onChangeSeat={this.handleChangeSeat}
          />
        </Styled.RowContainer>
      );
    }

    return (
      <WidgetStyled.ValueContainer>
        <DataContext.Consumer>
          {(context) => {
            const event = context && context.event;
            const markup = context?.markup?.find((markupItem) => this.props.parentId === markupItem?.id);
            let filtersHallLayout;
            let markupHallLayout;

            if (markup) {
              markupHallLayout = markup?.hallLayouts
                ? markup?.hallLayouts?.value
                : markup?.query?.hall_layout_id;
            }

            if (context?.placesFilter) {
              filtersHallLayout =
                context.placesFilter?.hallLayout || context.placesFilter?.hallLayout?.select === 'empty'
                  ? context.placesFilter?.hallLayout?.value
                  : context?.placesFilter?.hall_layout_id;
            }

            let query = {};
            const hallLayoutParams = event
              ? (event.hall_layout && event.hall_layout.id) || event.hall_layout_id
              : markupHallLayout || filtersHallLayout;

            if (field === WIDGET_FILTERS.SECTOR) {
              query = {
                hall_layout: hallLayoutParams,
                fragment_type: FRAGMENT_TYPES.SECTOR,
              };
            }

            if (field === WIDGET_FILTERS.CATEGORY) {
              query = {
                hall_layout: hallLayoutParams,
                fragment_type: FRAGMENT_TYPES.CATEGORY,
              };
            }

            return (
              <InputWithAutocomplete
                fluid
                defaultOptions={!!markupHallLayout}
                withoutDefaultValue
                key={markupHallLayout || field}
                value={value}
                title={title}
                query={query}
                isMulti={isMulti}
                isDisabled={
                  ((field === WIDGET_FILTERS.CATEGORY || field === WIDGET_FILTERS.SECTOR) &&
                    !hallLayoutParams) ||
                  (this.props?.operator === 'none' &&
                    (field === WIDGET_FILTERS.COMPANY ||
                      field === WIDGET_FILTERS.ZRITELI ||
                      field === WIDGET_FILTERS.PROVIDER ||
                      field === WIDGET_FILTERS.PARSERS))
                }
                route={routes[field]}
                routeName={routeName}
                onChange={this.handleChange}
                optionsRenderer={optionsRenderer}
                disablePaginate={
                  field === WIDGET_FILTERS.SECTOR ||
                  field === WIDGET_FILTERS.PARSERS ||
                  field === WIDGET_FILTERS.CATEGORY
                }
              />
            );
          }}
        </DataContext.Consumer>
      </WidgetStyled.ValueContainer>
    );
  }
}

export default ValueEditor;
