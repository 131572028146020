import styled, { createGlobalStyle } from 'styled-components';
import { smallModalStyles } from 'shared/mixins/modal';

export default {
  GlobalStyles: createGlobalStyle`
    ${smallModalStyles}
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Spinner: styled.div`
    display: flex;
    justify-content: center;
  `,

  Fields: styled.div`
    & > div:not(:last-child) {
      margin-bottom: 32px;
    }

    & input::placeholder {
      color: #5c5c5c;
      font-size: 16px;
      font-weight: normal;
      text-align: left;
    }
  `,

  Field: styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `,

  Percent: styled.div`
    position: absolute;
    right: 8px;
    font-size: 12px;
  `,

  ResetToDefault: styled.div`
    position: absolute;
    right: 32px;
    cursor: pointer;

    & svg {
      opacity: 0.4;
      transition: all 0.2s ease-in-out;
    }

    &:hover svg {
      opacity: 1;
    }
  `,
};
