import React from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import QueryBuilder from 'react-querybuilder';
import ValueEditor from '../SecondStep/ValueEditor';
import RemoveButton from '../SecondStep/RemoveButton';
import ValueSelector from '../SecondStep/ValueSelector';
import Action from '../SecondStep/Action';
import FieldSelect from '../SecondStep/FieldSelect';
import MarkupControls from './MarkupControls';
import QUERIES from 'shared/constants/QUERIES';
import { WIDGET_FILTERS } from 'shared/constants/WIDGET';
import { QueryContext } from './QueryContext';

const defaultFields = [
  {
    name: WIDGET_FILTERS.PRICE,
    label: 'Цена за позицию',
  },
  { name: WIDGET_FILTERS.ALL_TICKETS, label: 'Все билеты' },
  {
    name: WIDGET_FILTERS.PARSERS,
    label: 'Парсинг',
  },
  { name: WIDGET_FILTERS.SECTOR, label: 'Сектор' },
  { name: WIDGET_FILTERS.ROW, label: 'Ряд' },
  { name: WIDGET_FILTERS.SEAT, label: 'Место' },
  { name: WIDGET_FILTERS.CATEGORY, label: 'Категория' },
  { name: WIDGET_FILTERS.PROVIDER, label: 'Поставщик' },
  { name: WIDGET_FILTERS.COMPANY, label: 'Брокер' },
  { name: WIDGET_FILTERS.ZRITELI, label: 'Зрители' },
];

const operatorsByFilter = [
  { name: QUERIES.IN, label: 'Соответствует' },
  { name: QUERIES.NOT_IN, label: 'Не соответствует' },
  { name: QUERIES.EQUAL, label: 'Равен' },
  { name: QUERIES.NOT_EQUAL, label: 'Не равен' },
  { name: QUERIES.ALL, label: 'Все' },
  { name: QUERIES.NONE, label: 'Ни одного' },
];

const operatorsByFields = {
  [WIDGET_FILTERS.PRICE]: [
    { name: QUERIES.BETWEEN, label: 'Между' },
    { name: QUERIES.NOT_BETWEEN, label: 'Не между' },
    { name: QUERIES.LESS, label: 'Меньше' },
    { name: QUERIES.GREATER, label: 'Больше' },
    { name: QUERIES.LESS_OR_EQUAL, label: 'Меньше или равно' },
    { name: QUERIES.GREATER_OR_EQUAL, label: 'Больше или равно' },
  ],
  [WIDGET_FILTERS.PARSERS]: operatorsByFilter,
  [WIDGET_FILTERS.SECTOR]: operatorsByFilter,
  [WIDGET_FILTERS.ROW]: operatorsByFilter,
  [WIDGET_FILTERS.CATEGORY]: operatorsByFilter,
  [WIDGET_FILTERS.PROVIDER]: operatorsByFilter,
  [WIDGET_FILTERS.COMPANY]: operatorsByFilter,
  [WIDGET_FILTERS.ZRITELI]: operatorsByFilter,
};

const operators = [
  ...operatorsByFilter,
  { name: QUERIES.BETWEEN, label: 'Между' },
  { name: QUERIES.NOT_BETWEEN, label: 'Не между' },
  { name: QUERIES.LESS, label: 'Меньше' },
  { name: QUERIES.GREATER, label: 'Больше' },
  { name: QUERIES.LESS_OR_EQUAL, label: 'Меньше или равно' },
  { name: QUERIES.GREATER_OR_EQUAL, label: 'Больше или равно' },
];

const Wrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 28px;
  left: 505px;
`;

interface Props {
  id: string;
  onChange?: (id: string | number, query: any) => void;
  deleteQuery?: (_: any, id: string | number) => void;
  isPreview: boolean;
  query?: any;
  fields?: any[];
}

const QueryGroup: React.FC<Props> = ({ id, onChange, deleteQuery, query, fields, isPreview }) => {
  const handleChange = (newQuery) => {
    if (onChange) onChange(id, newQuery);
  };

  const getOperators = (field) => operatorsByFields[field] || operators;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <QueryContext.Provider value={{ id }}>
      <Wrapper>
        {!isPreview && (
          <ButtonWrapper>
            <RemoveButton handleOnClick={deleteQuery} data={id} />
          </ButtonWrapper>
        )}
        {/*
        // @ts-ignore */}
        <QueryBuilder
          withMarkup
          isPreview={isPreview}
          fields={fields}
          query={isEmpty(query) ? null : query}
          operators={operators}
          onQueryChange={handleChange}
          getOperators={getOperators}
          controlElements={{
            combinatorSelector: ValueSelector,
            addGroupAction: Action,
            addRuleAction: Action,
            fieldSelector: FieldSelect,
            operatorSelector: FieldSelect,
            removeRuleAction: RemoveButton,
            removeGroupAction: RemoveButton,
            valueEditor: ValueEditor,
            commonGroupInput: MarkupControls,
          }}
          translations={{
            fields: {
              title: 'Fields',
            },
            operators: {
              title: 'Operators',
            },
            value: {
              title: 'Value',
            },
            removeRule: {
              label: 'x',
              title: 'Удалить критерий',
            },
            removeGroup: {
              label: 'x',
              title: 'Удалить группу',
            },
            addRule: {
              label: 'Добавить критерий',
              title: 'Добавить критерий',
            },
            addGroup: {
              label: 'Добавить группу',
              title: 'Добавить группу',
            },
            combinators: {
              title: 'Combinators',
            },
          }}
          combinators={[
            { name: 'and', label: 'И' },
            { name: 'or', label: 'Или' },
          ]}
        />
      </Wrapper>
    </QueryContext.Provider>
  );
};

QueryGroup.defaultProps = {
  fields: defaultFields,
};

export default QueryGroup;
