import React from 'react';
import RejectModalForm from '../../Forms/RejectModalForm';
import { invoicesSources } from 'entities/invoices';
import { REJECT_REASONS } from 'shared/constants/INVOICES';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  rejectInvoice: (id: string | number, data: any) => any;
}

const InvoicesRejectModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    data: { id, title, submitText },
    rejectInvoice,
  } = props;

  const onSubmit = ({ reason, message }) => {
    rejectInvoice(id, {
      reject_reason: reason.value,
      ...(reason.value === REJECT_REASONS.OTHER && { message }),
    }).then(closeModal);
  };

  return (
    <RejectModalForm
      title={title}
      submitText={submitText}
      onSubmit={onSubmit}
      closeModal={closeModal}
      rejectReasonsRoute={invoicesSources}
    />
  );
};

export default InvoicesRejectModal;
