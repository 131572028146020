import React, { Component } from 'react';
import uuid from 'uuid/v4';
import Button from '../../../Button';
import Modal from '../../../Modal';
import AnnouncementsModal from './AnnouncementsModal';
import AnnouncementPreview from '../AnnouncementPreview';
import AnnouncementsSubmit from './AnnouncementsSubmit';
import ProgressModal from '../../../Modals/ProgressModal';
import Styled from './styles';
import ConfirmModal from '../../../Modals/ConfirmModal';
import { AnnouncementsVisibleScope } from 'shared/constants/ANNOUNCEMENT';

interface SecondStepProps {
  event: any;
  selectedEventId: number;
  initialValues?: any;
  clearAnnouncementData: () => void;
  goBack: () => void;
}

interface SecondStepState {
  announcements: any[];
  editableAnnouncement: any;
  showForm: boolean;
  showProgress: boolean;
  errors: any[];
  showConfirmModal: boolean;
}

class AnnouncementsSecondStep extends Component<SecondStepProps, SecondStepState> {
  seatsIds: {};

  constructor(props) {
    super(props);

    this.seatsIds = {};

    this.state = {
      announcements: [],
      editableAnnouncement: null,
      showForm: true,
      showProgress: false,
      errors: [],
      showConfirmModal: false,
    };
  }

  setSeatsIds = (rowId, seatsIds) => {
    this.seatsIds = {
      ...this.seatsIds,
      [rowId]: {
        ...(this.seatsIds[rowId] || {}),
        ...seatsIds,
      },
    };
  };

  setErrors = (errors) => {
    this.setState({
      errors,
    });
  };

  addAnnouncement = (data) => {
    let viewScopeType = data.view_scope_type;

    if (!viewScopeType && data.seat) {
      viewScopeType = AnnouncementsVisibleScope.SEATS;
    }

    this.setState(
      (prevState) => ({
        showForm: false,
        announcements: [
          ...prevState.announcements,
          {
            id: uuid(),
            ...data,
            view_scope_type: viewScopeType,
          },
        ],
      }),
      () => {
        this.props.clearAnnouncementData();
      },
    );
  };

  editAnnouncement = (event, id) => {
    this.setState((prevState) => ({
      showForm: true,
      editableAnnouncement: prevState.announcements.find((announcement) => announcement.id === id),
    }));
  };

  updateAnnouncement = (data) => {
    this.setState((prevState) => ({
      showForm: false,
      announcements: prevState.announcements.map((announcement) =>
        announcement.id === data.id
          ? {
              ...data,
              editable: false,
            }
          : announcement,
      ),
      editableAnnouncement: null,
    }));
  };

  deleteAnnouncement = (event, id) => {
    this.setState((prevState) => ({
      announcements: prevState.announcements.filter((announcement) => announcement.id !== id),
      editableAnnouncement: null,
      showForm: false,
    }));
  };

  hideForm = () => {
    this.setState(
      {
        showForm: false,
        showConfirmModal: false,
      },
      () => {
        this.props.clearAnnouncementData();
      },
    );
  };

  showForm = () => {
    this.setState({
      showForm: true,
      editableAnnouncement: null,
    });
  };

  showProgress = () => {
    this.setState({
      showProgress: true,
    });
  };

  hideProgress = () => {
    this.setState({
      showProgress: false,
      errors: [],
    });
  };

  hideConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
    });
  };

  showConfirmModal = () => {
    this.setState({
      showConfirmModal: true,
    });
  };

  render() {
    const { announcements, editableAnnouncement, showForm, showProgress, errors, showConfirmModal } =
      this.state;
    const { event, selectedEventId, initialValues, goBack } = this.props;

    const isCertificate = event?.is_certificate_event;

    return (
      <>
        <Styled.TitleWrapper>
          <Styled.Title>Добавление билетов</Styled.Title>
        </Styled.TitleWrapper>
        {showForm && (
          <Modal close={this.showConfirmModal} size={isCertificate ? 550 : 688}>
            {showConfirmModal && (
              <ConfirmModal
                withPopover
                closePopover={this.hideConfirmModal}
                closeModal={this.hideForm}
                padding="24px"
              />
            )}
            <AnnouncementsModal
              close={this.showConfirmModal}
              onSubmit={editableAnnouncement ? this.updateAnnouncement : this.addAnnouncement}
              event={event}
              setSeatsIds={this.setSeatsIds}
              initialValues={editableAnnouncement || initialValues}
              deleteAnnouncement={this.deleteAnnouncement}
              isCertificate={isCertificate}
            />
          </Modal>
        )}
        {showProgress && (
          <Modal size={400}>
            <ProgressModal
              useFakeProgress
              isError={errors.length > 0}
              errors={errors}
              hideProgress={this.hideProgress}
              title="Идет добавление объявлений"
            />
          </Modal>
        )}

        {announcements.length > 0 && (
          <AnnouncementPreview
            announcements={announcements}
            editAnnouncement={this.editAnnouncement}
            isCertificate={isCertificate}
          />
        )}

        <Styled.AddTickets>
          <Button isLink primary onClick={this.showForm} data-selenium="add-ticket">
            Добавить билеты
          </Button>
        </Styled.AddTickets>

        <Modal.Footer fullSize justify>
          <Button transparent type="button" onClick={goBack} data-selenium="back">
            Назад
          </Button>
          <AnnouncementsSubmit
            seatsIds={this.seatsIds}
            announcements={announcements}
            selectedEventId={selectedEventId}
            disabled={!announcements.length}
            showProgress={this.showProgress}
            setErrors={this.setErrors}
          />
        </Modal.Footer>
      </>
    );
  }
}

export default AnnouncementsSecondStep;
