import React, { useState } from 'react';
import SearchPlusIcon from '../../../../../static/icons/search-plus.svg';
import SearchMinusIcon from '../../../../../static/icons/search-minus.svg';
import UndoIcon from '../../../../../static/icons/undo.svg';
import ArrowHIcon from '../../../../../static/icons/arrow-alt-h.svg';
import ArrowVIcon from '../../../../../static/icons/arrow-alt-v.svg';
import Button from '../../../Button';
import Styled from './styles';

interface Props {
  cropper: any;
}

const FileCropperModalActions = ({ cropper }: Props) => {
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const zoomIn = () => {
    cropper.zoom(0.1);
  };

  const zoomOut = () => {
    cropper.zoom(-0.1);
  };

  const rotateLeft = () => {
    cropper.rotate(-45);
  };

  const rotateRight = () => {
    cropper.rotate(45);
  };

  const updateScaleX = () => {
    const newScale = scaleX * -1;
    cropper.scaleX(newScale);
    setScaleX(newScale);
  };

  const updateScaleY = () => {
    const newScale = scaleY * -1;
    setScaleY(newScale);
    cropper.scaleY(newScale);
  };

  return (
    <Styled.Actions>
      <Button onlyIcon transparent type="button" onClick={zoomIn}>
        <SearchPlusIcon />
      </Button>
      <Button onlyIcon transparent type="button" onClick={zoomOut}>
        <SearchMinusIcon />
      </Button>
      <Button onlyIcon transparent type="button" onClick={rotateLeft}>
        <UndoIcon />
      </Button>
      <Styled.RedoButton onlyIcon transparent type="button" onClick={rotateRight}>
        <UndoIcon />
      </Styled.RedoButton>
      <Button onlyIcon transparent type="button" onClick={updateScaleX}>
        <ArrowHIcon />
      </Button>
      <Button onlyIcon transparent type="button" onClick={updateScaleY}>
        <ArrowVIcon />
      </Button>
    </Styled.Actions>
  );
};

export default FileCropperModalActions;
