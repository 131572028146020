import React from 'react';
import map from 'lodash/map';
import slice from 'lodash/slice';
import ReactNumericInput from 'react-numeric-input';
import Styled from './styles';

interface SelectorProps {
  row: any;
  handleStandingChange: (data: any, value: boolean, mappedRows: any) => any;
}

const PreorderModalStandingSelector: React.FC<SelectorProps> = ({ handleStandingChange, row }) => {
  const withAllTickets = (num) => `${num} / ${row.length}`;

  const handleOnChange = (count) => {
    handleStandingChange(
      map(slice(row, 0, count), (ticket: any) => ticket.id),
      false,
      map(row, (ticket) => ticket.id),
    );

    return null;
  };

  return (
    <Styled.NumericInput>
      <ReactNumericInput
        mobile
        strict
        min={0}
        noStyle
        precision={0}
        max={row.length}
        onChange={handleOnChange}
        format={withAllTickets}
        defaultValue={0}
      />
    </Styled.NumericInput>
  );
};

export default PreorderModalStandingSelector;
