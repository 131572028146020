import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import COLORS from 'shared/constants/COLORS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';
import StyledTabs from '../../Tabs/styles';

interface HeaderProps {
  tablet?: boolean;
  withMobileSteps?: boolean;
}

interface TitleProps {
  tablet?: boolean;
}

interface FooterProps {
  padding?: string;
  mobileOrder?: string | number;
}

interface WithLanguageProps {
  column?: boolean;
}

export default {
  PopoverGlobalStyles: createGlobalStyle`
    .modal__overlay {
      overflow: hidden;
    }
  `,

  GlobalStyles: createGlobalStyle`
    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__body {
        padding: 0;
      }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,

  SpinnerWrapper: styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  ButtonLabel: styled.span`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  DeleteButton: styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
    color: ${COLORS.RED};

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: absolute;
      top: 50%;
      left: 16px;
      margin: 0;
      transform: translateY(-50%);

      & svg {
        margin: 0;
      }
    }
  `,

  CloseButton: styled.div`
    display: flex;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  `,

  Popover: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 4;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.MD}) {
      height: 100vh;
    }
  `,

  Header: styled.div<HeaderProps>`
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    min-height: 72px;
    padding: 16px 40px;
    text-align: center;
    border-bottom: 1px solid #e1e1e1;

    @media (max-width: ${({ tablet }) => (tablet ? BREAKPOINTS.XL : BREAKPOINTS.MD)}) {
      flex-direction: column;
      justify-content: center;
      padding: ${({ withMobileSteps }) => (withMobileSteps ? '8px 16px 32px' : '8px 16px')};
      min-height: 56px;
    }
  `,

  WithLanguage: styled.div<WithLanguageProps>`
    display: flex;
    align-items: ${({ column }) => !column && 'center'};
    flex-direction: ${({ column }) => column && 'column'};

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      margin: 0 -16px;
      flex-direction: row;
      align-self: flex-start;
    }
  `,

  ProgressBar: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      margin: 10px 0 4px;
    }
  `,

  Title: styled.div<TitleProps>`
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: #000;

    @media (max-width: ${({ tablet }) => (tablet ? BREAKPOINTS.XL : BREAKPOINTS.SM)}) {
      width: 100%;
      padding: 0 16px;
      font-size: 20px;
      line-height: 26px;
      color: #000;
      text-align: left;
    }
  `,

  SubTitle: styled.span`
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.64);

    @media (max-width: ${BREAKPOINTS.SM}) {
      font-size: 12px;
      line-height: 16px;
    }
  `,

  Back: styled.button`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 48px;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.4;
    outline: none;
    padding: 0;
    border: 0;
    background: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    svg {
      margin: 0 8px 1px 0;

      @media (max-width: ${BREAKPOINTS.SM}) {
        height: 14px;
        margin-right: 0;
      }
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      left: 16px;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  `,

  BackTitle: styled.span`
    @media (max-width: ${BREAKPOINTS.SM}) {
      display: none;
    }
  `,

  DesktopIcon: styled.span`
    @media (max-width: ${BREAKPOINTS.SM}) {
      display: none;
    }
  `,

  MobileIcon: styled.span`
    display: none;

    @media (max-width: ${BREAKPOINTS.SM}) {
      display: flex;
      align-items: center;
    }
  `,

  Footer: styled.div<FooterProps>`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: ${({ padding }) => padding};
    margin: auto -4px 0;

    @media (max-width: ${BREAKPOINTS.MD}) {
      order: ${({ mobileOrder }) => mobileOrder};
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      flex-direction: column;
    }
  `,

  FooterButton: styled.div`
    box-sizing: border-box;
    display: flex;
    padding: 0 4px;

    @media (max-width: ${BREAKPOINTS.SM}) {
      &:not(:last-child) {
        padding-bottom: 12px;
      }

      & > button {
        width: 100%;
      }
    }
  `,

  Row: styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.SM}) {
      flex-direction: column;
      align-items: stretch;
    }
  `,

  ButtonWrapper: styled.div`
    display: flex;
    margin: auto auto 32px;
    padding-top: 32px;

    button {
      margin: 0 8px;
    }
  `,

  Tabs: styled.div`
    ${StyledTabs.HorizontalTabs} {
      position: absolute;
      top: 31px;
      left: 0;
      background: transparent;
      z-index: 1;

      @media (min-width: ${BREAKPOINTS.XL_LANDING_EVENTS}) {
        left: 135px;
      }

      .menu-wrapper {
        padding: 0;
      }
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      height: 40px;
      margin: 0 -16px -6px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      margin: 0 -16px -11px;
    }
  `,
};
