import React, { Component } from 'react';
import get from 'lodash/get';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import Placeholder from '../../Placeholder';
import Styled from './styles';
import Modal from '../../Modal';
import Button from '../../Button';
import Form from '../../Form';
import { Textarea } from '../../FormControls';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  loadAnnouncement: (id: number | string) => any;
  editAnnouncement: (id: number | string, data: any) => any;
}

interface ModalState {
  data: any;
  isLoading: boolean;
}

class AnnouncementCommentModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const {
      loadAnnouncement,
      data: { announcementId },
    } = this.props;

    try {
      const {
        payload: { data },
      } = await loadAnnouncement(announcementId);

      this.setState({
        data,
        isLoading: false,
      });
    } catch {
      NotificationManager.error('Ошибка');
    }
  }

  editComment = (data) => {
    const { id } = this.state.data;
    const { editAnnouncement, forceCloseModal } = this.props;

    editAnnouncement(id, { comment: data.comment }).then((response) => {
      if (response.error) {
        return NotificationManager.error('Ошибка');
      }

      return forceCloseModal();
    });
  };

  removeComment = () => {
    this.editComment({ comment: '' });
  };

  render() {
    const { isLoading, data } = this.state;
    const initialValues = {
      comment: get(data, 'comment'),
    };

    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Modal.Title>Комментарий к объявлению</Modal.Title>
        <Form
          onSubmit={this.editComment}
          initialValues={initialValues}
          render={({ handleSubmit, values, pristine }) => (
            <form onSubmit={handleSubmit}>
              {isLoading ? (
                <Styled.Placeholder>
                  <Placeholder />
                </Styled.Placeholder>
              ) : (
                <Field name="comment">
                  {({ input, meta }) => <Textarea label="Комментарий" autoFocus {...input} meta={meta} />}
                </Field>
              )}
              <Modal.Footer>
                {initialValues.comment && (
                  <Button type="button" dangerTransparent onClick={this.removeComment} isLoading={isLoading}>
                    Удалить
                  </Button>
                )}
                <Button type="button" transparent onClick={this.props.closeModal} isLoading={isLoading}>
                  Отмена
                </Button>
                <Button isLoading={isLoading} disabled={!values.comment || pristine}>
                  {initialValues.comment ? 'Изменить' : 'Добавить'}
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Styled.Container>
    );
  }
}

export default AnnouncementCommentModal;
