const PAGE_CONSTRUCTOR_TYPES = {
  HTML: 'HTML',
  EVENTS: 'EVENTS',
  GALLERY: 'GALLERY',
  ISSUE: 'ISSUE',
  SALE_ISSUE: 'SALE_ISSUE',
  CERTIFICATES: 'CERTIFICATES',
  QNA: 'QNA',
};

const PAGE_CONSTRUCTOR_NAMES = {
  HTML: 'Визивик',
  EVENTS: 'Афиша',
  GALLERY: 'Галерея',
  ISSUE: 'Форма заявок',
  SALE_ISSUE: 'Форма продажи',
  CERTIFICATES: 'Сертификат',
  QNA: 'QnA',
};

const PAGE_CONSTRUCTOR_KEYS = {
  HTML: 'html',
  EVENTS: 'events',
  GALLERY: 'gallery',
  ISSUE: 'issue',
  SALE_ISSUE: 'sale_issue',
  QNA: 'qna',
};

const PAGE_CONSTRUCTOR_BLOCKS_WITHOUT_SETTINGS = ['ISSUE', 'SALE_ISSUE', 'CERTIFICATES'];

const PAGE_CONSTRUCTOR_OPTIONS = Object.keys(PAGE_CONSTRUCTOR_TYPES).map((item) => ({
  value: item,
  label: PAGE_CONSTRUCTOR_NAMES[item],
}));

export {
  PAGE_CONSTRUCTOR_TYPES,
  PAGE_CONSTRUCTOR_NAMES,
  PAGE_CONSTRUCTOR_OPTIONS,
  PAGE_CONSTRUCTOR_KEYS,
  PAGE_CONSTRUCTOR_BLOCKS_WITHOUT_SETTINGS,
};
