import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Form: styled.div<any>`
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 24px 24px 16px;
    background-color: #fff;
    cursor: default;
    transition: all 0.2s ease-in-out;

    @media (max-width: ${BREAKPOINTS.MD}) {
      border-radius: 0px 0px 8px 8px;
    }
  `,

  Top: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  `,

  Bottom: styled.div`
    margin: 0 -24px;
    padding: 16px 16px 0;
    border-top: 1px solid #e1e1e1;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;

      & > button:first-child {
        margin-right: 8px;
      }
    }

    @media (max-width: ${BREAKPOINTS.XXS}) {
      & button {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  `,

  Title: styled.div`
    font-size: 20px;
    color: #000;
  `,

  Close: styled.button`
    border: 0;
    padding: 0;
    margin: 0;
    background: 0;
    opacity: 0.4;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 1;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      & svg {
        height: 10px;
        stroke: #fff;
      }
    }
  `,

  FixedError: styled.div`
    & label {
      display: block;
    }
  `,
};
