import React from 'react';
import RangeDateTimePicker from 'components/FormControls/RangeDateTimePicker';
import { PickerProps } from 'components/FormControls/RangeDateTimePicker/RangeDateTimePicker';

interface ContainerProps extends PickerProps {
  isShortUnderline?: boolean;
  name?: string;
}

const RangeDateTimePickerContainer: React.FC<ContainerProps> = ({ name, isShortUnderline, ...rest }) => (
  <RangeDateTimePicker
    queryName={{
      gte: `${name}_${isShortUnderline ? '' : '_'}gte`,
      lte: `${name}_${isShortUnderline ? '' : '_'}lte`,
    }}
    {...rest}
  />
);

export default RangeDateTimePickerContainer;
