import React from 'react';
import Form from '../../../Form';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';

const ITEMS = Array.from({ length: 11 }, (v, i) => i + 1);

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const FooterStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack }) => (
  <Styled.Container withTitle>
    <Styled.Header>
      <Styled.Title>Подвал</Styled.Title>
    </Styled.Header>

    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.List>
              {ITEMS.map((i) => (
                <Styled.Item key={i} data-selenium={`variant-${i}`}>
                  <Variant name="ui_config_footer" value={i}>
                    <img src={`/static/constructor/footer/${i}.jpg`} alt={`Подвал ${i}`} />
                  </Variant>
                </Styled.Item>
              ))}
            </Styled.List>
            <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
          </form>
        )}
      />
    </Styled.Content>
  </Styled.Container>
);

export default FooterStep;
