import React from 'react';
import { FormSpy } from 'react-final-form';
import diff from 'object-diff';

interface Props {
  id: number;
  setValues: (id: number, difference: any, isCategories: boolean) => void;
  values: any;
  isCategories: boolean;
}

interface State {
  values: any;
}

class OrderNewTicketsFormSpy extends React.Component<Props, State> {
  timeout: any;

  promise: any;

  constructor(props) {
    super(props);
    this.state = { values: props.values };
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.timeout = setTimeout(this.save);
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { values, setValues, id, isCategories } = this.props;

    const difference = diff(this.state.values, values);
    if (Object.keys(difference).length) {
      this.promise = setValues(id, difference, isCategories);
      await this.promise;
      delete this.promise;
    }
  };

  render() {
    return null;
  }
}

export default (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={OrderNewTicketsFormSpy} />
);
