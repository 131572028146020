import React from 'react';
import { Field } from 'react-final-form';
import Styled from './styles';
import Button from '../../../Button';
import Modal from '../../../Modal';
import Portal from '../../../Portal';
import Form from '../../../Form';
import { Input } from '../..';
import { required } from 'shared/helpers/validations';

interface ModalProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  data: {
    title: string;
    alt: string;
  };
}

const FileAttributesModal: React.FC<ModalProps> = ({ data, onSubmit, onClose }) => {
  return (
    <Portal>
      <>
        <Styled.Overlay onClick={onClose} />
        <Styled.Container>
          <Form
            onSubmit={onSubmit}
            initialValues={data}
            render={({ handleSubmit }) => (
              <Styled.Form onSubmit={handleSubmit}>
                <Styled.Field>
                  <Field name="alt" validate={required}>
                    {({ input, meta }) => <Input label="alt" {...input} meta={meta} />}
                  </Field>
                </Styled.Field>
                <Styled.Field>
                  <Field name="title" validate={required}>
                    {({ input, meta }) => <Input label="title" {...input} meta={meta} />}
                  </Field>
                </Styled.Field>
                <Modal.Footer>
                  <Button type="button" transparent onClick={onClose}>
                    Отмена
                  </Button>
                  <Button type="submit">Сохранить</Button>
                </Modal.Footer>
              </Styled.Form>
            )}
          />
        </Styled.Container>
      </>
    </Portal>
  );
};

export default FileAttributesModal;
