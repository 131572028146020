import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Item: styled.li`
    margin-bottom: 4px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 0 -16px;
    }
  `,
};
