import React, { useState } from 'react';
import Styled from './styled';
import Button from '../Button';

interface EventPricesDatesProps {
  eventDates: any[];
  selectedDates: {
    [key: string]: boolean;
  };
}

const MAX_VISIBLE_DATES = 15;

const EventPricesDates: React.FC<EventPricesDatesProps> = (props) => {
  const { eventDates, selectedDates } = props;
  const selectedEventDates = eventDates.filter((date) => selectedDates[date.value]);
  const count = selectedEventDates.length;
  const [isHidden, toggleVisibility] = useState(count > MAX_VISIBLE_DATES);
  const visibleEventDates = isHidden ? selectedEventDates.slice(0, MAX_VISIBLE_DATES) : selectedEventDates;

  const handleClick = () => {
    toggleVisibility((prevState) => !prevState);
  };

  return (
    <>
      <Styled.Dates>
        {visibleEventDates.map((date) => {
          return <Styled.SelectedDate key={date.value}>{date.label}</Styled.SelectedDate>;
        })}
      </Styled.Dates>
      {count > MAX_VISIBLE_DATES && (
        <Button type="button" transparentBlue onClick={handleClick}>
          {isHidden ? 'Показать еще' : 'Скрыть'}
        </Button>
      )}
    </>
  );
};

export default EventPricesDates;
