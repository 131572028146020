import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

const Radio = styled.div<any>`
  box-sizing: border-box;
  position: relative;
  height: 16px;
  width: 16px;
  border: ${({ isChecked }) => (isChecked ? '5px' : '2px')} solid ${({ color }) => color};
  border-radius: 50%;
  margin-right: 12px;
  background: transparent;
  transition: 0.2s ease-in-out;
`;

export default {
  GlobalStyles: createGlobalStyle`
    .modal {
      &__wrapper {
        max-width: 432px;
      }
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      .modal {
      &__table-wrapper {
        vertical-align: bottom;
      }

      &__wrapper {
        max-width: 100%;
      }

      &__body {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        max-height: 100vh;
        padding: 16px 16px 32px;
        border-radius: 8px 8px 0 0;
        padding-bottom: 8px;
        box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
      }

      &__title {
        margin-bottom: 16px;
      }

      &__footer {
        margin-bottom: -8px;
      }
    }
    }
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Placeholder: styled.div`
    height: 80px;
  `,

  OptionsList: styled.div``,

  Option: styled.label`
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
    background-color: #fff;
    margin: 0 -24px;
    padding: 0 14px 0 24px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #f4f4f4;
    }
  `,

  Input: styled.input`
    display: none;
  `,

  Radio,

  Info: styled.div`
    margin-left: auto;

    svg {
      opacity: 0.4;
      transition: 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      svg {
        opacity: 1;
      }
    }
  `,
};
