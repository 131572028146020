import React from 'react';
import Error from '../Error';
import Label from '../LabelNew';
import Styled from './styles';

interface InputProps {
  icon?: any;
  label?: string;
  disabled?: boolean;
  fixedError?: boolean;
  postfix?: any;
  badge?: string | null;
  meta?: any;
  value?: any;
  isSmall?: boolean;
  autoFocus?: boolean;
  type?: string;
  placeholder?: string;
  onChange?: any;
  readOnly?: boolean;
  name?: string;
  maxValue?: number;
}

const Input = ({
  label = '',
  meta = {},
  icon = null,
  postfix = null,
  badge = null,
  fixedError = false,
  disabled = false,
  maxValue,
  ...rest
}: InputProps) => (
  <Styled.Container>
    {label && (
      <Label value={rest.value} meta={meta} disabled={disabled} withIcon={Boolean(icon)}>
        {label}
      </Label>
    )}
    {icon && <Styled.Icon isSmall={rest.isSmall}>{icon}</Styled.Icon>}
    <Styled.Field disabled={disabled} withIcon={icon} postfix={Boolean(postfix)} {...rest} meta={meta} />
    {postfix && <Styled.Postfix isSmall={rest.isSmall}>{postfix}</Styled.Postfix>}
    {badge && <Styled.Badge isSmall={rest.isSmall}>{badge}</Styled.Badge>}
    {maxValue && (
      <Styled.Count>
        {rest.value.length} / {maxValue}
      </Styled.Count>
    )}
    <Styled.Error shifted={Boolean(maxValue)}>
      <Error meta={meta} fixedError={fixedError} />
    </Styled.Error>
  </Styled.Container>
);

export default Input;
