import React from 'react';
import map from 'lodash/map';
import head from 'lodash/head';
import { getSeatsFromTickets, convertSeats } from 'shared/helpers/seats';
import { getTotalPrice } from 'shared/helpers/tickets';
import Tooltip from '../../Tooltip';
import Styled from './styles';

interface Props {
  groupedTickets: any;
  withoutPrice?: boolean;
  withoutTickets: boolean;
  sidebarIsOpen?: boolean;
}

const GroupedTickets = ({ groupedTickets, withoutTickets, withoutPrice, sidebarIsOpen }: Props) => {
  if (withoutTickets) return <Styled.WithoutTickets>Билеты отсутствуют</Styled.WithoutTickets>;

  return map(groupedTickets, (rows, sector) => (
    <Styled.Wrapper key={sector}>
      {map(rows, (tickets, row) => {
        const seats = getSeatsFromTickets(tickets);
        const price = getTotalPrice(tickets);
        const rowName = head(row.split('_'));
        const formattedSeats = convertSeats(seats);
        const isStanding = rowName === 'count';
        let string = `${sector}`;

        if (!isStanding) {
          string += ` • Ряд ${head<any>(tickets).row}`;
        }

        if (formattedSeats) {
          string += ` • Места ${formattedSeats}`;
        }

        if (isStanding) {
          string += ` • ${tickets.length} шт`;
        }

        if (!withoutPrice) {
          string += ` • ${price}`;
        }

        return (
          <Styled.Container sidebarIsOpen={sidebarIsOpen} key={string}>
            <Tooltip text={string}>
              <Styled.Text>{string}</Styled.Text>
            </Tooltip>
          </Styled.Container>
        );
      })}
    </Styled.Wrapper>
  ));
};

export default GroupedTickets;
