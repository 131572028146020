import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  withDates?: any;
}

export default {
  Container: styled.div<CSSProps>`
    display: flex;
    flex: 1 1 auto;
    margin-left: 16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: 100%;
      margin: 24px 0 0;
      flex-wrap: ${({ withDates }) => withDates && 'wrap'};
    }
  `,

  Buttons: styled.div`
    display: flex;
    margin-left: auto;
  `,

  InputWrapper: styled.div<CSSProps>`
    width: 140px;
    font-size: 16px;
    font-weight: normal;

    @media (max-width: ${BREAKPOINTS.MD}) {
      width: ${({ withDates }) => (withDates ? '50%' : '100%')};
    }
  `,

  DateSelect: styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 40px;

    & > div {
      margin-left: 16px;
      min-width: 250px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      order: 1;
      margin: 24px 0 0;
      width: 100%;

      & > div {
        min-width: 0;
      }
    }
  `,
};
