import React from 'react';
import styled, { css } from 'styled-components';

interface StyledErrorProps {
  fixedError?: boolean;
  position?: 'right';
}

const StyledError = styled.div<StyledErrorProps>`
  display: flex;
  margin-top: 3px;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  color: #eb5757;

  position: ${({ fixedError }) => fixedError && 'absolute'};
  white-space: ${({ fixedError }) => fixedError && 'nowrap'};

  ${({ position }) =>
    position === 'right' &&
    css`
      top: 50%;
      left: 100%;
      margin-top: 0;
      margin-left: 16px;
      transform: translateY(-50%);
    `}
`;

interface Meta {
  error?: string;
  touched?: boolean;
  submitError?: any;
}

interface ErrorProps extends StyledErrorProps {
  meta: Meta;
}

const Error: React.FC<ErrorProps> = ({ meta, fixedError, position }) => {
  if (meta.error || meta.submitError) {
    const errors = meta.error || meta.submitError;
    return meta.touched ? (
      <StyledError fixedError={fixedError} position={position}>
        {Array.isArray(errors) ? errors.map((error) => <span key={error}>{error}</span>) : errors}
      </StyledError>
    ) : null;
  }

  return null;
};

export default Error;

export { StyledError };
