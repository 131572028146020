import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import head from 'lodash/head';
import { NotificationManager } from 'react-notifications';
import Styled from './styles';
import Modal from '../../Modal/Modal';
import Button from '../../Button';
import Form from '../../Form';
import { NumberInput } from '../../FormControls';
import Spinner from '../../Spinner';
import Tooltip from '../../Tooltip';
import CloseIcon from '../../../../static/icons/close.svg';
import { ModalFunctions } from '../../../../interfaces/modal';

let finalForm;

const OPEX_CASH_DEFAULT = 0;
const OPEX_CASHLESS_DEFAULT = 10;

interface ModalProps {
  data: any;
  editOpex: (id: number, data: any) => any;
  loadOpex: () => any;
  closeModal: ModalFunctions['closeModal'];
}

interface IState {
  data: any;
  state: {
    isLoading: boolean;
    isFail: boolean;
  };
}

const ReportsConfigModal: React.FC<ModalProps> = (props) => {
  const { closeModal } = props;

  const [data, setData] = useState<IState>({
    data: {},
    state: {
      isLoading: true,
      isFail: false,
    },
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await props.loadOpex();

        setData({
          data: head(response.payload.data.data.results),
          state: {
            isLoading: false,
            isFail: false,
          },
        });
      } catch {
        setData({
          data: {},
          state: {
            isLoading: false,
            isFail: true,
          },
        });
      }
    };

    loadData().catch((err) => new Error(err));
  }, []);

  const {
    data: { id, opex_cash: opexCash, opex_cashless: opexCashless },
    state: { isLoading },
  } = data;

  const onSubmit = (values) => {
    props
      .editOpex(id, {
        opex_cash: opexCash,
        opex_cashless: opexCashless,
        ...values,
      })
      .then((response) => {
        if (response.error) {
          NotificationManager.error('Ошибка');
        } else {
          NotificationManager.success('Успешно');

          window.location.reload();
        }
      });
  };

  const resetOpexCash = () => finalForm.change('opex_cash', 0);
  const resetOpexCashless = () => finalForm.change('opex_cashless', 10);

  if (isLoading) {
    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Styled.Spinner>
          <Spinner />
        </Styled.Spinner>
      </Styled.Container>
    );
  }

  const isDefaultOpexCash = opexCash === OPEX_CASH_DEFAULT;
  const isDefaultOpexCashless = opexCashless === OPEX_CASHLESS_DEFAULT;

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title>Операционные расходы</Modal.Title>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...(!isDefaultOpexCash && { opex_cash: opexCash }),
          ...(!isDefaultOpexCashless && { opex_cashless: opexCashless }),
        }}
        render={({ handleSubmit, form }) => {
          finalForm = form;

          return (
            <form onSubmit={handleSubmit}>
              <Styled.Fields>
                <Styled.Field>
                  <Field name="opex_cash">
                    {({ input, meta }) => (
                      <NumberInput
                        fixLabel={isDefaultOpexCash}
                        placeholder={isDefaultOpexCash && `${OPEX_CASH_DEFAULT}`}
                        label="Операционные расходы нал"
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                  <Styled.Percent>%</Styled.Percent>
                  <Tooltip text="Сбросить до стандартного значения">
                    <Styled.ResetToDefault onClick={resetOpexCash}>
                      <CloseIcon />
                    </Styled.ResetToDefault>
                  </Tooltip>
                </Styled.Field>
                <Styled.Field>
                  <Field name="opex_cashless">
                    {({ input, meta }) => (
                      <NumberInput
                        fixLabel={isDefaultOpexCashless}
                        placeholder={isDefaultOpexCashless && `${OPEX_CASHLESS_DEFAULT}`}
                        label="Операционные расходы б/н"
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                  <Styled.Percent>%</Styled.Percent>
                  <Tooltip text="Сбросить до стандартного значения">
                    <Styled.ResetToDefault onClick={resetOpexCashless}>
                      <CloseIcon />
                    </Styled.ResetToDefault>
                  </Tooltip>
                </Styled.Field>
              </Styled.Fields>
              <Modal.Footer>
                <Button transparent type="button" onClick={closeModal}>
                  Отмена
                </Button>
                <Button>Обновить</Button>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Styled.Container>
  );
};

export default ReportsConfigModal;
