import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from '../../constants/BREAKPOINTS';
import MEDIA_QUERIES from '../../constants/MEDIA_QUERIES';

export default {
  NarrowModalStyles: createGlobalStyle`
  .modal {
    &__wrapper {
      max-width: 544px;
    }
  }
`,

  Wrapper: styled(Form)`
    @media (max-width: ${BREAKPOINTS.MD}) {
      height: 100%;
      margin: 0;

      & > form {
        flex-grow: 1;
      }
    }
  `,

  Header: styled(StepModal.Header)`
    padding: 12px 16px;
  `,

  Title: styled(StepModal.Title)`
    font-weight: 400;
  `,

  InputSubtitle: styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.64);
  `,

  Content: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 24px 16px 0;
    }
  `,

  FooterContainer: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      & > div {
        margin: 0 -16px;
      }
    }
  `,

  Row: styled.div`
    box-sizing: border-box;
    display: flex;
    margin: 0 -16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      flex-direction: column;
      margin: 0;
    }
  `,

  Col: styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    margin: 0 16px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: 0;
    }
  `,

  Editor: styled.div`
    box-sizing: border-box;
    display: flex;
    margin-bottom: 32px;
  `,

  Schema: styled.div`
    margin-bottom: 32px;
  `,

  SchemaImages: styled.p`
    margin-left: 48px;
    margin-bottom: 16px;
    font-weight: 700;
  `,

  HowToGet: styled.div`
    box-sizing: border-box;
    display: flex;
  `,

  Close: styled(Modal.SecondaryCloseButton)`
    @media (max-width: ${BREAKPOINTS.MD}) {
      top: 16px;
      height: 20px;

      & > img {
        height: 14px;
      }
    }
  `,

  Table: styled.table`
    border-collapse: separate;
    border-spacing: 0;

    & > tr:not(:last-child) > td {
      padding-bottom: 48px;

      @media ${MEDIA_QUERIES.MOBILE} {
        padding-bottom: 24px;
      }
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      & > tr:last-child > td:first-child {
        padding-bottom: 24px;
      }
    }
  `,

  TableRow: styled.tr`
    @media ${MEDIA_QUERIES.MOBILE} {
      display: flex;
      flex-direction: column;
    }
  `,

  TableCellTitle: styled.td`
    vertical-align: top;
  `,

  TableCell: styled.td`
    width: 100%;
    vertical-align: top;
  `,

  TableCellTitleSubText: styled.div`
    margin-top: 4px;
    min-width: 148px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.64);
    white-space: normal;
  `,
  Br: styled.br`
    @media ${MEDIA_QUERIES.MOBILE} {
      display: none;
    }
  `,

  ButtonWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,

  SaveButton: styled.div`
    display: flex;
  `,

  CancelButton: styled.div`
    margin-right: 10px;
  `,

  GenerateSlugButton: styled(Button)`
    margin-left: 16px;
    margin-bottom: 32px;
  `,
};
