import get from 'lodash/get';
import last from 'lodash/last';
import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { widgetSources } from 'entities/widget';
import { createWidget, editWidget } from 'entities/widget';
import { ERRORS } from 'shared/constants/ERRORS';
import api from 'shared/services/api';
import WidgetSecondStep from './WidgetSecondStep';

interface Props {
  onSubmit: (data?: any) => any;
  data?: any;
}

const SecondStepContainer: React.FC<Props> = (props) => {
  const { data, onSubmit } = props;
  const dispatch = useDispatch();
  const getEventDates = () => {
    if (
      data.eventDates &&
      data.eventDates.filter((item) => item.outdated).length === data.eventDates.length
    ) {
      return data.eventDates;
    }

    if (data.eventDates) {
      return data.eventDates.filter((item) => !item.outdated);
    }

    return null;
  };
  const eventDates = getEventDates();
  const [state, setState] = useState({
    isLoading: true,
    selectedDate: [],
    data,
  });

  const onUpdateParentWidget = (action) => {
    if (action.error) {
      NotificationManager.error(ERRORS.DEFAULT);
      return;
    }

    onSubmit(action.payload.data);
  };

  const submit = (submitData) => {
    const isPeriodical = get(data.event, 'is_periodical');

    if (isPeriodical && state.selectedDate) {
      if (!state.selectedDate.length) {
        return dispatch(
          editWidget(
            omit(
              {
                ...data,
                event_id: data.event.id,
                filters: submitData.filters,
              },
              ['markup'],
            ),
          ),
          // @ts-ignore
        ).then(onUpdateParentWidget);
      }

      const ids = state.selectedDate.map((item) => item.value);

      return Promise.all([
        ...ids.map((event) => {
          const childrenWidget = data.children.find((child) => child.event_id === event);

          if (childrenWidget) {
            return dispatch(
              editWidget(
                omit(
                  {
                    ...childrenWidget,
                    active: data.active,
                    filters: submitData.filters,
                  },
                  ['markup'],
                ),
              ),
            );
          }

          return dispatch(
            createWidget(
              omit(
                {
                  ...data,
                  event_id: event,
                  parent_id: data.id,
                  filters: submitData.filters,
                },
                ['markup', 'id'],
              ),
            ),
          );
        }),
      ]).then((results) => {
        const error = get(results, '[0].error');

        api.get(widgetSources.sync(data.id)).catch((err) => new Error(err));

        if (error) {
          NotificationManager.error(ERRORS.DEFAULT);
          return;
        }

        onSubmit();
      });
    }

    return dispatch(
      editWidget({
        id: data.id,
        filters: submitData.filters,
      }),
      // @ts-ignore
    ).then(onUpdateParentWidget);
  };

  const onChangeDate = (date) => {
    if (date.length === 1) {
      const { value: id } = last(date);
      const childWidget = data.children.find((child) => child.event_id === id);

      return setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          filters: childWidget ? childWidget.filters : data.filters,
        },
        isLoading: false,
        selectedDate: date,
      }));
    }

    return setState((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        filters: data.filters,
      },
      selectedDate: date,
      isLoading: false,
    }));
  };

  useEffect(() => {
    if (state.selectedDate) {
      onChangeDate(state.selectedDate);
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoading: !prevState.isLoading,
      }));
    }
  }, []);

  const { data: stateData, selectedDate, isLoading } = state;

  const componentProps = {
    ...props,
    onSubmit: submit,
    isLoading,
    data: stateData,
    eventDates:
      eventDates &&
      eventDates.map((item) => ({
        ...item,
        handleClick: onChangeDate,
      })),
    selectedDate,
    onChangeDate,
  };

  return <WidgetSecondStep {...componentProps} />;
};

export default SecondStepContainer;
