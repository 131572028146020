import React from 'react';
import get from 'lodash/get';
import Styled from './styles';
import AnnouncementHistoryModalName from './AnnouncementHistoryModalName';
import { IHistoryItem } from '../../../../interfaces/historyModal';

const AnnouncementHistoryModalProviderChange: React.FC<IHistoryItem> = ({ data, user }) => (
  <Styled.Info>
    <Styled.InfoTitle>Поставщик изменен</Styled.InfoTitle>
    <AnnouncementHistoryModalName data={user} />
    <Styled.InfoText>{get(data, 'new.provider.name')}</Styled.InfoText>
  </Styled.Info>
);

export default AnnouncementHistoryModalProviderChange;
