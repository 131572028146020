import { IEventAttachmentTypes } from 'shared/api/market/events';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import {
  createAttachments,
  deleteAttachments,
  filterCreateAttachments,
  filterDeleteAttachments,
  filterUpdateAttachments,
  updateAttachments,
} from 'shared/lib/attachments';
import { ILandingEventData, ILandingEventFormValue } from '../../types/Event';

export const getLandingEventAttachments = ({
  data,
  formValue,
  attachmentTypes,
}: {
  data: ILandingEventData;
  formValue: ILandingEventFormValue;
  attachmentTypes: IEventAttachmentTypes;
}) => {
  let createAttachment;
  let updateAttachment;
  let deleteAttachment;

  if (filterUpdateAttachments(data, formValue)) {
    updateAttachment = {
      id: data.id,
      attachments: updateAttachments(data, formValue),
    };
  }

  if (formValue.lng === LANGUAGES.RU) {
    if (filterCreateAttachments(data, formValue)) {
      createAttachment = {
        id: data.id,
        attachments: createAttachments(data, formValue, attachmentTypes!),
      };
    }

    if (filterDeleteAttachments(data, formValue)) {
      deleteAttachment = {
        id: data.id,
        attachments: deleteAttachments(data, formValue),
      };
    }
  }

  return { createAttachment, updateAttachment, deleteAttachment };
};
