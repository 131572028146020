import config from 'config/config';
import { createEffect } from 'effector';
import { Router } from 'next/router';
import { i18n } from 'next-i18next';
import api from 'services/api';
import COOKIES from '../../../constants/COOKIES';
import { getCookie } from '../../../lib/cookie';
import { ISetsTemplates, ISetTemplate, ISetTemplateChangeResponse, ISetTemplateChange } from './types';

const companyId = getCookie(COOKIES.COMPANY);

export const templateSetsRoute = `${config.COMMON_API_URL}/admin/v1/${companyId}/template_sets`;

export const fetchSetsTemplatesFx = createEffect(
  async (query: Router['query'] = {}): Promise<ISetsTemplates> => {
    const { page, search_string, ...queryParams } = query;

    const response = await api.get(`${templateSetsRoute}`, {
      params: {
        ...queryParams,
        search_string,
        language_code: i18n?.language,
        ...(!search_string && { page }),
      },
    });

    return response.data;
  },
);

export const fetchSetsTemplatesLoadMoreFx = createEffect(
  async ({ data }: { data: { query: Router['query']; redirects: ISetsTemplates } }) => {
    const response = await api.get(templateSetsRoute, {
      params: {
        ...data.query,
      },
    });

    return response.data;
  },
);

export const setTemplateCreateFx = createEffect(
  async ({ data }: { data: ISetTemplateChange }): Promise<ISetTemplateChangeResponse> => {
    const response = await api.post(`${templateSetsRoute}`, { ...data, company_id: Number(companyId) });

    return response.data;
  },
);

export const fetchSetTemplateFx = createEffect(async ({ id }: { id: number }): Promise<ISetTemplate> => {
  const response = await api.get(`${templateSetsRoute}/${id}`);

  return response.data;
});

export const updateSetTemplateFx = createEffect(
  async ({ id, data }: { id: number; data: ISetTemplateChange }): Promise<ISetTemplateChangeResponse> => {
    const response = await api.patch(`${templateSetsRoute}/${id}`, data);

    return response.data;
  },
);

export const deleteSetTemplateFx = createEffect(async (id: number) => {
  await api.delete(`${templateSetsRoute}/${id}`);
});
