import Button from 'components/Button';
import EventDates from 'components/EventPrices/EventPricesDates';
import { InputWithSelect } from 'components/FormControls';
import EventsLayout from 'components/Pages/Events/EventsLayout';
import Spinner from 'components/Spinner';
import Tabs from 'components/Tabs';
import { isEmpty } from 'lodash';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { placesFilterSources } from 'shared/sources/placesFilters';
import TrashIcon from '../../../static/icons/trash-alt.svg';
import Modal from '../Modal';
import Styled from './styled';

const sortEventDates = (eventDates: any) => {
  if (!eventDates) return eventDates;
  return sortBy(eventDates, (item) => moment(item.label, 'DD MMM YYYY, HH:mm', 'ru').toDate());
};

const NEW_TAB_ID = 'NEW_TAB';

const renderMarkup = (response: { data: { results: [] } }) => {
  return response.data.results.map((i: { id: number; title: string }) => ({
    value: i.id,
    id: i.id,
    label: i.title,
  }));
};

interface EventsPlacesFiltersProps {
  eventSourceId: number;
  eventDates: any[];
  goBack: () => void;
  goNext: () => void;
  marketPlacesFilterPosition?: boolean;
}

const EventsPlacesFilters: React.FC<EventsPlacesFiltersProps> = ({
  eventSourceId,
  eventDates,
  goBack,
  goNext,
  marketPlacesFilterPosition,
}) => {
  const [filterTemplates, setFilterTemplates] = useState({
    tabs: [],
    data: [],
    loading: true,
  });

  const [selectedTab, setSelectedTab] = useState<number | string | null>(null);
  const [selectedDates, setSelectedDates] = useState<any>({});
  const [datesLoading, setDatesLoading] = useState(true);
  const initialValues: any = useRef();
  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const loadDates = async (filterId: number | string, eventPlacesFilter: any = filterTemplates.data) => {
    setDatesLoading(true);

    const selectedFilter = eventPlacesFilter?.find((filter: { id: number }) => filter?.id === filterId);
    const dates = await placesFilterSources?.getEventFiltersDates(eventSourceId, selectedFilter?.id);

    if (!isEmpty(dates)) {
      setShowRemoveButton(true);
    } else {
      setShowRemoveButton(false);
    }

    const filterSelectedDates = dates?.results?.reduce(
      (acc: any, { event_date_id }: { event_date_id: number }) => {
        acc[event_date_id] = true;

        initialValues.current = {
          placesFilter: {
            value: selectedFilter.id,
            label: selectedFilter.title,
          },
        };
        return acc;
      },
      {},
    );

    setSelectedDates(filterSelectedDates);
    setDatesLoading(false);
  };

  const loadFilterTemplates = async () => {
    if (eventSourceId) {
      const response = await placesFilterSources.filterTemplates(eventSourceId);
      const placesFilter = response.data.results;

      if (isEmpty(placesFilter)) {
        setDatesLoading(false);
        setFilterTemplates(() => ({
          data: null,
          tabs: [],
          loading: false,
        }));
      }

      if (placesFilter.length) {
        const selectedFilter: any = head(placesFilter);
        await loadDates(selectedFilter?.id, placesFilter);

        setFilterTemplates(() => ({
          data: placesFilter,
          tabs: placesFilter.map((item: any) => ({
            ...item,
            key: item.id,
            text: item.title,
          })),
          loading: false,
        }));
        setSelectedTab(selectedFilter.id);
      }
    }
  };

  useEffect(() => {
    loadFilterTemplates();
  }, [eventSourceId]);

  const submit = async (formData: any) => {
    setDatesLoading(true);
    const submitedDates = Object.keys(selectedDates).map((dateId) => {
      return {
        event_date_id: Number(dateId),
      };
    });

    const foundFilterToRemove = filtersToRemove?.find(
      (filterItem: any) => filterItem.id === formData?.placesFilter?.value,
    );

    if (!isEmpty(filtersToRemove)) {
      await Promise.all(
        filtersToRemove.map(async (filterItem: any) => {
          for (const filterDate of filterItem.dates) {
            try {
              const result = await placesFilterSources.deleteEventFilter({
                eventSourceId,
                filterId: filterItem.id,
                event_date_id: filterDate.event_date_id,
              });
            } catch (error) {}
          }
        }),
      );
      setFiltersToRemove([]);
    }
    setDatesLoading(false);

    if (formData?.placesFilter && !foundFilterToRemove) {
      await placesFilterSources.bindFiltersToEvent(eventSourceId, formData.placesFilter.value, submitedDates);
    }

    if (selectedTab === NEW_TAB_ID || submitedDates.length === eventDates?.length) {
      loadFilterTemplates();
    }

    if (formData.submit_action === SUBMIT_ACTIONS.CONTINUE) {
      goNext();
    }
  };

  const addDate = (value: any) => {
    const { value: id } = value;

    setSelectedDates((prevState: any) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const selectAllDates = () => {
    const allEventDates = eventDates.reduce((acc, date) => {
      acc[date.value] = true;

      return acc;
    }, {});

    setSelectedDates(allEventDates);
  };

  const datesValue = eventDates?.reduce((acc: any, date: any) => {
    if (selectedDates[date.value]) {
      acc.push(date);
    }

    return acc;
  }, []);

  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab);
    if (tab === NEW_TAB_ID) setSelectedDates({});
    if (tab !== NEW_TAB_ID) loadDates(tab);
  };

  let formActions: any;

  const [filtersToRemove, setFiltersToRemove] = useState<any>([]);

  const deleteFilter = async (filterId: number) => {
    formActions.change('placesFilter', null);
    setFilterTemplates((prevState: any) => ({
      ...prevState,
      tabs: prevState.tabs.filter((tab: any) => tab.id !== filterId),
      data: prevState.data.filter((tab: any) => Number(tab.id) !== Number(filterId)),
    }));

    const selectedFilter = filterTemplates.data.find((filter: any) => filter.id === filterId);

    if (selectedFilter) {
      const dates = await placesFilterSources.getEventFiltersDates(eventSourceId, selectedFilter?.id);

      setFiltersToRemove((prevState: any) => [...prevState, { id: selectedFilter.id, dates: dates.results }]);
    }
  };

  useEffect(() => {
    const selectedFilter: any = head(filterTemplates?.tabs);
    if (selectedFilter && selectedFilter.key !== 'NEW_TAB' && selectedTab !== 'NEW_TAB') {
      handleSelectTab(selectedFilter?.id);
    }
  }, [filterTemplates.tabs.length]);

  const addTab = () => {
    const isExist = filterTemplates.tabs.find((tab: any) => tab.key === NEW_TAB_ID);
    setShowRemoveButton(false);

    if (isExist) {
      setSelectedTab(NEW_TAB_ID);
      setSelectedDates({});
      return;
    }

    setFilterTemplates((prevState: any) => ({
      ...prevState,
      tabs: [
        ...prevState.tabs,
        {
          text: 'Новый фильтр',
          key: NEW_TAB_ID,
        },
      ],
    }));
    setSelectedTab(NEW_TAB_ID);
    setSelectedDates({});
  };

  if (filterTemplates.loading) {
    return (
      <EventsLayout.Wrapper>
        <Styled.Container>
          <Spinner />
        </Styled.Container>
      </EventsLayout.Wrapper>
    );
  }

  return (
    <EventsLayout.Wrapper>
      <Styled.Container withDates={!!eventDates}>
        {eventDates && eventDates.length > 0 && (
          <Styled.AddFilter>
            <Button type="button" transparent onClick={addTab}>
              Добавить фильтр
            </Button>
          </Styled.AddFilter>
        )}

        <Tabs
          key={filterTemplates.data}
          tabs={filterTemplates.tabs}
          onSelect={handleSelectTab}
          selectedTab={selectedTab}
          highlighted
          hideBorder={isEmpty(filterTemplates.tabs) ? true : false}
        >
          {datesLoading ? (
            <Styled.SpinnerWrapper>
              <Spinner center />
            </Styled.SpinnerWrapper>
          ) : (
            <Form
              onSubmit={submit}
              initialValues={
                selectedTab === NEW_TAB_ID
                  ? {
                      placesFilter: null,
                    }
                  : initialValues.current
              }
              render={({ handleSubmit, form, values }) => {
                formActions = form;

                return (
                  <form onSubmit={handleSubmit}>
                    {filterTemplates.tabs.length ? (
                      <Styled.Row>
                        <Styled.Column>
                          <Styled.Select>
                            <div>
                              Места
                              <Field name="placesFilter">
                                {({ input, meta }) => (
                                  <InputWithSelect
                                    isClearable={false}
                                    isAsync
                                    route={placesFilterSources}
                                    optionsRenderer={renderMarkup}
                                    {...input}
                                    meta={meta}
                                    searchQueryName="search_string"
                                  />
                                )}
                              </Field>
                            </div>

                            {!isEmpty(selectedDates) && showRemoveButton && (
                              <Styled.DeleteFilter>
                                <Button
                                  type="button"
                                  dangerTransparent
                                  onlyIcon={true}
                                  onClick={() => deleteFilter(values?.placesFilter?.value)}
                                >
                                  <TrashIcon />
                                </Button>
                              </Styled.DeleteFilter>
                            )}
                          </Styled.Select>
                        </Styled.Column>

                        <Styled.Column>
                          {eventDates && (
                            <>
                              <Styled.DatesSelect>
                                <Styled.SelectWrapper>
                                  Выбор даты
                                  <InputWithSelect
                                    hideSelectedOptions
                                    closeMenuOnSelect={false}
                                    controlShouldRenderValue={false}
                                    isClearable={false}
                                    options={sortEventDates(eventDates)}
                                    onChange={addDate}
                                    value={datesValue}
                                    meta={{}}
                                  />
                                </Styled.SelectWrapper>
                                {eventDates.length !== 1 && (
                                  <Button type="button" transparent onClick={selectAllDates}>
                                    Выбрать все даты
                                  </Button>
                                )}
                              </Styled.DatesSelect>
                              <EventDates eventDates={eventDates} selectedDates={selectedDates} />
                            </>
                          )}
                        </Styled.Column>
                      </Styled.Row>
                    ) : (
                      <div>Фильтры на данное мероприятие не найдены</div>
                    )}

                    <Styled.FooterWrapper marketPlacesFilterPosition={marketPlacesFilterPosition}>
                      <Modal.Footer fullSize justify>
                        <Button transparent type="button" onClick={goBack} data-selenium="button-back">
                          Назад
                        </Button>
                        <Button
                          gray
                          type="submit"
                          onClick={() => form.change('submit_action', SUBMIT_ACTIONS.CREATE)}
                          data-selenium="button-save"
                        >
                          Сохранить
                        </Button>
                        <Button
                          type="submit"
                          onClick={() => form.change('submit_action', SUBMIT_ACTIONS.CONTINUE)}
                          data-selenium="button-continue"
                        >
                          Продолжить
                        </Button>
                      </Modal.Footer>
                    </Styled.FooterWrapper>
                  </form>
                );
              }}
            />
          )}
        </Tabs>
      </Styled.Container>
    </EventsLayout.Wrapper>
  );
};

export default EventsPlacesFilters;
