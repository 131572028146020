import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { LandingEvent } from 'pages/landings/types/Event';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import api from 'shared/services/api';
import landingsEventSources from 'shared/sources/landings/landingsEvents';
import { getTranslations } from '../../../../pages/landings/helpers/landingHelpers';
import Form from '../../../Form';
import { InputWithSelect } from '../../../FormControls';
import { LandingOptionWithEventInfo } from '../../../FormControls/InputWithSelect';
import Spinner from '../../../Spinner';
import PageConstructorFooter from './PageConstructorFooter';
import Styled from './styles';

let formValues;

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  step: number;
  data: any;
  blockData: any;
  steps: string[];
  showErrors: (data: any) => void;
  contentGroups: Array<{
    id: number;
    name: string;
  }>;
}

const renderCustomEvents = (response) => {
  return response.data.results.map((item) => {
    const info = getTranslations(item.info, LANGUAGES.RU);

    return {
      value: item.id,
      label: info?.affiche_title,
      data: item,
    };
  });
};

const prepareEvents = (events) => {
  return events.map((item) => {
    const info = getTranslations(item.info, LANGUAGES.RU);

    return {
      value: item.id,
      label: info?.affiche_title,
      data: item,
    };
  });
};

const AfishaStep: React.FC<StepProps> = ({
  onSubmit,
  updateData,
  data,
  step,
  goBack,
  steps,
  blockData,
  showErrors,
  contentGroups = [],
}) => {
  const [state, setState] = useState({
    events: [],
    loading: Boolean(blockData.events && blockData.events.length),
  });

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const promises = blockData.events.map(async (eventId: number) => {
          const response = await api.get(landingsEventSources.detail(eventId));

          return response.data;
        });

        const allEvents = (await Promise.allSettled(promises)).reduce((acc, promise) => {
          if (promise.status === 'fulfilled') {
            acc.push(promise.value as LandingEvent);
          }

          return acc;
        }, [] as LandingEvent[]);

        setState((prevState) => ({
          ...prevState,
          loading: false,
          events: prepareEvents(allEvents),
        }));
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    };

    if (blockData.events && blockData.events.length) {
      loadEvents();
    }
  }, []);

  const handleOnSubmit = (newData) => {
    if (newData.events && state.events.length !== newData.events.length) {
      setState((prevState) => ({
        ...prevState,
        events: prepareEvents(newData.events.map((event) => event.data)),
      }));
    }

    if (!get(newData, 'events.length')) {
      return showErrors({
        [steps[step]]: data.find((block) => block.uuid === steps[step]),
      });
    }

    const requestData = data.map((block) => {
      if (block.uuid === steps[step]) {
        return {
          ...block,
          events: newData.events
            ? newData.events.map((item) => ({
                id: item.value,
                name: item.label,
              }))
            : [],
        };
      }

      return block;
    });

    return onSubmit({ blocks: requestData, submit_action: newData.submit_action });
  };

  const handleUpdateData = () => {
    const requestData = data.blocks.map((block) => {
      if (block.uuid === steps[step]) {
        return {
          ...block,
          events: formValues.events
            ? formValues.events.map((item) => ({
                id: item.value,
                name: item.label,
              }))
            : [],
        };
      }

      return block;
    });

    return updateData({ ...data, blocks: requestData });
  };

  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка блока Афиша</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        {state.loading ? (
          <Spinner />
        ) : (
          <Form
            onSubmit={handleOnSubmit}
            initialValues={{
              events: state.events,
            }}
            render={({ handleSubmit, form, values }) => {
              formValues = values;
              return (
                <form onSubmit={handleSubmit}>
                  <Styled.FormRow>
                    <Styled.FormDescription>Добавление мероприятий</Styled.FormDescription>
                    <Styled.FormRow column>
                      <Styled.SectionsAfisha>
                        <Field name="events">
                          {({ input, meta }) => (
                            <InputWithSelect
                              menuPlacement="bottom"
                              isAsync
                              isMulti
                              route={landingsEventSources}
                              label="Мероприятия"
                              optionsRenderer={renderCustomEvents}
                              components={{ Option: LandingOptionWithEventInfo }}
                              searchQueryName="search_string"
                              query={
                                contentGroups.length > 0 && {
                                  content_group_id_in: contentGroups.map((i) => i.id).join(','),
                                  date_type: 'all',
                                }
                              }
                              {...input}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </Styled.SectionsAfisha>
                    </Styled.FormRow>
                  </Styled.FormRow>

                  <PageConstructorFooter
                    goBack={goBack}
                    form={form}
                    updateData={handleUpdateData}
                    withoutNextAction={steps.length - 1 === step}
                  />
                </form>
              );
            }}
          />
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AfishaStep;
