import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, withRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import { createWidgetPattern, editWidgetPattern } from 'entities/widget-patterns';
import api from 'shared/services/api';
import { widgetSources } from 'entities/widget';
import DataPreview from 'components/DataPreview';
import WidgetPatternsModal from 'components/Pages/Widget/WidgetPatternsModal';
import { ModalFunctions } from '../../../../../interfaces/modal';

interface ContainerProps {
  createWidgetPattern: (data: any) => any;
  editWidgetPattern: (data: any) => any;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  router: Router;
  data?: any;
}

interface ContainerState {
  state: {
    isLoading: boolean;
  };
  isEdit: boolean;
  data: any;
  id: string | number;
  isPreview: boolean;
}

class WidgetPatternsModalContainer extends Component<ContainerProps, ContainerState> {
  constructor(props) {
    super(props);

    const id = props.router.query.modalId || props.data.id;
    const { isPreview } = props.router.query;
    const isEdit = Boolean(id);

    this.state = {
      state: {
        isLoading: isEdit,
      },
      isEdit,
      data: null,
      id,
      isPreview: isPreview === 'true',
    };
  }

  componentDidMount() {
    const { id } = this.state;

    if (id) {
      api
        .get(widgetSources.patternDetail(id))
        .then((results) => {
          this.setState({
            data: {
              ...results.data,
              pattern_type: String(results.data.pattern_type),
            },
            state: {
              isLoading: false,
            },
          });
        })
        .catch(() => {
          this.setState({
            state: {
              isLoading: false,
            },
          });
        });
    }
  }

  render() {
    const { state, data, isEdit, isPreview } = this.state;

    return (
      <DataPreview state={state} isEmpty={isEdit && isEmpty(data)} centered>
        <WidgetPatternsModal
          data={data}
          isEdit={isEdit}
          isPreview={isPreview}
          editWidgetPattern={this.props.editWidgetPattern}
          createWidgetPattern={this.props.createWidgetPattern}
          closeModal={this.props.forceCloseModal}
        />
      </DataPreview>
    );
  }
}

const mapDispatchToProps = {
  createWidgetPattern,
  editWidgetPattern,
};

export default withRouter(connect(null, mapDispatchToProps)(WidgetPatternsModalContainer));
