import { createFactory } from '@withease/factories';
import { createEvent, createStore, sample } from 'effector';
import { createToggler } from '../lib/toggler';

export const createUpdateModalStates = createFactory(() => {
  const toggler = createToggler();

  const modalOpened = createEvent<{ id: number; type?: string }>();
  const modalClosed = createEvent();

  const $modalId = createStore<number | null>(null);
  const $loading = createStore<boolean>(true);

  sample({
    clock: toggler.open,
    target: $modalId,
  });

  return {
    toggler,
    modalOpened,
    modalClosed,
    $modalId,
    $loading,
  };
});
