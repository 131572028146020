import { css } from 'styled-components';
import map from 'lodash/map';
import differenceBy from 'lodash/differenceBy';
import COLORS from 'shared/constants/COLORS';
import api from 'shared/services/api';
import { getData } from './data';

const getBorderOnError = (props) => {
  const { meta } = props;

  if (meta && (meta.error || meta.submitError) && meta.touched) {
    return css`
      border-color: ${COLORS.RED};
    `;
  }

  return null;
};

const getColorOnError = (props) => {
  const { meta } = props;

  if (meta && (meta.error || meta.submitError) && meta.touched) {
    return css`
      color: ${COLORS.RED};
    `;
  }

  return null;
};

const getEntitiesForCreateAndDelete = (formData, initialFormData) => {
  const initialFormDataEntities = map(formData, (item) => ({
    id: item.value,
    name: item.name,
  }));

  const formDataEntities = map(initialFormData, (item) => ({
    id: item.id,
    name: item.name,
  }));

  const entitiesForCreate = differenceBy(initialFormDataEntities, formDataEntities, 'id').map(
    (item) => item.id,
  );
  const entitiesForDelete = differenceBy(formDataEntities, initialFormDataEntities, 'id').map(
    (item) => item.id,
  );

  return {
    entitiesForCreate,
    entitiesForDelete,
  };
};

const loadOptions = async ({
  query,
  value,
  route,
  routeName,
  currentPage,
  optionsRenderer,
  withoutPagination,
  searchQueryName = 'search',
  language,
}) => {
  try {
    let page;
    let lastPage;

    const response = await api.get(route[routeName], {
      params: {
        ...query,
        [searchQueryName]: value,
        ...(!withoutPagination && { page: currentPage }),
      },
      headers: {
        'Accept-Language': language,
      },
    });

    if (!withoutPagination) {
      const { page: resultPage, last_page: resultLastPage } = getData(response);
      page = resultPage;
      lastPage = resultLastPage;
    }

    if (optionsRenderer) {
      if (withoutPagination) {
        return optionsRenderer(response);
      }

      return {
        options: optionsRenderer(response),
        hasMore: currentPage < lastPage,
        additional: {
          page: Number(page) + 1,
        },
      };
    }

    const options = response.data.data.results.map((item) => ({
      value: item.id,
      label: item.name,
      data: item,
    }));

    if (withoutPagination) {
      return options;
    }

    return {
      options,
      hasMore: currentPage < lastPage,
      additional: {
        page: Number(page) + 1,
      },
    };
  } catch (e) {
    return withoutPagination
      ? []
      : {
          options: [],
          hasMore: false,
        };
  }
};

export { getBorderOnError, getEntitiesForCreateAndDelete, loadOptions, getColorOnError };
