import Button from 'components/Button';
import Form from 'components/Form';
import { Input, PhoneInput } from 'components/FormControls';
import Modal from 'components/Modal';
import isEmpty from 'lodash/isEmpty';
import { Router, withRouter } from 'next/router';
import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { ModalLayout } from 'widgets/layouts/modal-layout';
import { addProvider, editProvider, loadCompanyProviders } from 'entities/company/providers';
import { required, validPhone, validEmail, composeValidators } from 'shared/helpers/validations';
import { queryFilter } from 'shared/lib/queryFilter';
import { ModalFunctions } from '../../../../../../interfaces/modal';
import EmailIcon from '../../../../../../static/icons/email.svg';

interface Props {
  addProvider: (data: object) => void;
  editProvider: (id: string | number, data: object) =>void;
  closeModal: ModalFunctions['closeModal'];
  company: {id:number};
  router: Router;
  data: object;
  forceCloseModal: (data: object) => void;
  loadCompanyProviders: () => void;
}

const ProviderModal: React.FC<Props> = (props) => {
  const isEdit = !isEmpty(props.data);
  const onSubmit = (data:{id:number}) => {
    if (!isEdit) {
      return props.addProvider(data).then((response:{error:string}) => {
        if (response.error) {
          return response;
        }

        props.loadCompanyProviders();

        queryFilter({ search: '' });

        return response;
      });
    }

    return props.editProvider(data.id, data);
  };

  return (
    <>
      <Modal.Title>{isEdit ? 'Редактировать поставщика' : 'Добавить поставщика'}</Modal.Title>
      <Form
        onSubmit={onSubmit}
        onSuccess={props.forceCloseModal}
        initialValues={{
          company: props.company.id,
          ...props.data,
        }}
        render={({ handleSubmit, form }:{ handleSubmit:()=>void, form:object}) => (
          <form onSubmit={handleSubmit}>
            <div>
              <ModalLayout.Row>
                <ModalLayout.Sidebar>
                  <ModalLayout.RowTitle>Поставщик</ModalLayout.RowTitle>
                  <ModalLayout.Description>Основная информация</ModalLayout.Description>
                </ModalLayout.Sidebar>
                <ModalLayout.Content>
                  <ModalLayout.FormRow>
                    <ModalLayout.Field>
                      <Field name="name" validate={required}>
                        {({ input, meta }) => <Input label="Имя / Фирма" autoFocus {...input} meta={meta} />}
                      </Field>
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                  <ModalLayout.FormRow>
                    <ModalLayout.Field>
                      <Field name="address" validate={required}>
                        {({ input, meta }) => (
                          <Input label="Информация и координаты" {...input} meta={meta} />
                        )}
                      </Field>
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                  <ModalLayout.FormRow>
                    <ModalLayout.Field>
                      <Field name="email" validate={composeValidators(required, validEmail)}>
                        {({ input, meta }) => (
                          <Input
                            placeholder="Почта"
                            icon={<EmailIcon opacity={0.4} />}
                            {...input}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                  <ModalLayout.FormRow>
                    <ModalLayout.Field>
                      <Field name="phone" validate={composeValidators(required, validPhone)}>
                        {({ input, meta }) => <PhoneInput input={input} handleForm={form} meta={meta} />}
                      </Field>
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                </ModalLayout.Content>
              </ModalLayout.Row>
              <ModalLayout.Row>
                <ModalLayout.Sidebar>
                  <ModalLayout.RowTitle>Ответственное лицо</ModalLayout.RowTitle>
                </ModalLayout.Sidebar>
                <ModalLayout.Content>
                  <ModalLayout.FormRow>
                    <ModalLayout.Field>
                      <Field name="director" validate={required}>
                        {({ input, meta }) => <Input label="Директор" {...input} meta={meta} />}
                      </Field>
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                </ModalLayout.Content>
              </ModalLayout.Row>
              <ModalLayout.Row>
                <ModalLayout.Sidebar>
                  <ModalLayout.RowTitle>Документы</ModalLayout.RowTitle>
                  <ModalLayout.Description>Юридическая информация</ModalLayout.Description>
                </ModalLayout.Sidebar>
                <ModalLayout.Content>
                  <ModalLayout.FormRow>
                    <ModalLayout.Field>
                      <Field name="inn" validate={required}>
                        {({ input, meta }) => <Input label="ИНН" {...input} meta={meta} />}
                      </Field>
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                  <ModalLayout.FormRow>
                    <ModalLayout.Field>
                      <Field name="psrn" validate={required}>
                        {({ input, meta }) => <Input label="ОГРН" {...input} meta={meta} />}
                      </Field>
                    </ModalLayout.Field>
                  </ModalLayout.FormRow>
                </ModalLayout.Content>
              </ModalLayout.Row>
            </div>
            <Modal.Footer>
              <Button type="button" transparent onClick={props.closeModal}>
                Отмена
              </Button>
              <Button type="submit">{isEdit ? 'Сохранить' : 'Добавить'}</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  company: state.account.data.company,
});

const mapDispatchToProps = {
  addProvider,
  editProvider,
  loadCompanyProviders,
};

export const CompanyAddProviderModal =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProviderModal));
