export default {
  titles: {
    1: {
      value: 1,
      label: 'По центру',
    },
    2: {
      value: 2,
      label: 'Слева',
    },
  },
  layout: {
    1: {
      value: 1,
      label: 'С подложкой',
    },
    2: {
      value: 2,
      label: 'Без подложки',
    },
  },
};
