import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, required, minValue, validPhone, maxValue } from 'shared/helpers/validations';
import stopPropagation from 'shared/helpers/stopPropagation';
import Button from '../Button';
import { Input, FormRow, PhoneInput } from '../FormControls';
import Form from '../Form';
import Styled from './styles';
import CloseIcon from '../../../static/icons/close.svg';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';

let submit;

const onKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    submit(event);
  }
};

interface CallContextFormProps {
  isOpen?: boolean;
  onSubmit: (data: any) => void;
  close: () => void;
}

const CallContextForm: React.FC<CallContextFormProps> = ({ isOpen, onSubmit, close }) => (
  <Styled.Form isOpen={isOpen} onClick={stopPropagation}>
    <Styled.Top>
      <Styled.Title>Создание заказа</Styled.Title>
      <Styled.Close onClick={close}>
        <CloseIcon />
      </Styled.Close>
    </Styled.Top>
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => {
        submit = handleSubmit;

        return (
          <form onSubmit={handleSubmit} autoComplete="off" onKeyDown={onKeyDown} role="presentation">
            <FormRow>
              <Field name="phone" validate={composeValidators(required, validPhone)}>
                {({ input, meta }) => (
                  <Styled.FixedError>
                    <PhoneInput input={input} handleForm={form} meta={meta} />
                  </Styled.FixedError>
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field name="name" validate={composeValidators(required, minValue(3), maxValue(100))}>
                {({ input, meta }) => (
                  <Styled.FixedError>
                    <Input label="Имя" fixedError {...input} meta={meta} />
                  </Styled.FixedError>
                )}
              </Field>
            </FormRow>
            <Styled.Bottom>
              <Button type="button" transparent onClick={close} data-selenium="context-cancel-button">
                Отмена
              </Button>
              <div>
                <Button
                  gray
                  data-selenium="invoice-create-button"
                  onClick={() => {
                    form.change('submit_action', SUBMIT_ACTIONS.CREATE);
                  }}
                >
                  Заявка
                </Button>
                <Button
                  data-selenium="context-submit-button"
                  onClick={() => {
                    form.change('submit_action', SUBMIT_ACTIONS.CONTINUE);
                  }}
                >
                  Продолжить
                </Button>
              </div>
            </Styled.Bottom>
          </form>
        );
      }}
    />
  </Styled.Form>
);

export default CallContextForm;
